import { ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Badge, Col, ListGroupItem, ListGroupItemText, Row } from "reactstrap";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { PercentageProgressBar } from "../progress/PercentageProgressBar";
import "./dashboardListGroupItem.scss";

export interface QuestionnaireDashboardListGroupItemProps {
    children?: ReactNode,
    sessionsNotStarted: QuestionnaireRespondentSession[],
    sessionsStarted: QuestionnaireRespondentSession[],
    sessionsCompleted: QuestionnaireRespondentSession[],
}

/**
 * List group with items for each group.
 * @param props
 */
export const QuestionnaireDashboardListGroupItem = (props: QuestionnaireDashboardListGroupItemProps) => {
    const {
        children,
        sessionsNotStarted,
        sessionsStarted,
        sessionsCompleted,

    } = props;

    const { t } = useTranslation();

    /*Calculate the Total Ammount of Questionnaires */
    const numberOfSessions = Math.floor(sessionsNotStarted.length + sessionsStarted.length + sessionsCompleted.length);

    /*Calculate The Percentage of  completed or started Questionnaires*/
    const percentageQuestionnaires = useCallback((numberOfquestionnaires: number, numberofCompletedOrStarted: number) => {
        if (!numberOfquestionnaires || !numberofCompletedOrStarted) {
            return 0;
        }

        return Math.floor((numberofCompletedOrStarted / numberOfquestionnaires) * 100);
    }, []);


    return (
        <ListGroupItem tag="div">
            <Row>
                <Col xs={12} md="">
                    <div>
                        {children}
                    </div>

                    <ListGroupItemText tag="div">
                        <div>
                            <Row>
                                <Col>
                                    <Badge color="danger">
                                        {t('dashboardListGroup.notStartedCount', 'Not started')}
                                        <> </>
                                        <Badge pill style={{ fontSize: '100%' }}>
                                            {sessionsNotStarted.length}
                                        </Badge>
                                    </Badge>
                                </Col>
                                <Col>
                                    <Badge color="warning">
                                        {t('dashboardListGroup.startedCount', 'Started')}
                                        <> </>
                                        <Badge pill style={{ fontSize: '100%' }}>
                                            {sessionsStarted.length}
                                        </Badge>
                                    </Badge>
                                </Col>
                                <Col>
                                    <Badge color="success">
                                        {t('dashboardListGroup.completedCount', 'Completed')}
                                        <> </>
                                        <Badge pill style={{ fontSize: '100%' }}>
                                            {sessionsCompleted.length}
                                        </Badge>
                                    </Badge>
                                </Col>
                                {/*Progress Bar For Campaign Quetionnaires*/}
                                <Col className="progress-bar-container text-center">
                                    <PercentageProgressBar
                                        value={percentageQuestionnaires(numberOfSessions ?? 0, sessionsStarted.length)}
                                        questionnaireType={QuestionnaireType.Campaign}
                                        heading={t('dashboardListGroup.percentageBar.titlestarted', 'Started')} />
                                </Col>
                                <Col className="progress-bar-container text-center">
                                    <PercentageProgressBar
                                        value={percentageQuestionnaires(numberOfSessions ?? 0, sessionsCompleted.length)}
                                        questionnaireType={QuestionnaireType.Campaign}
                                        heading={t('dashboardListGroup.percentageBar.titleCompleted', 'Completed')} />
                                </Col>
                            </Row>
                        </div>
                    </ListGroupItemText>
                </Col>
            </Row>
        </ListGroupItem>
    );
};