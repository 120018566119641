import * as React from "react";
import { ValidationErrors, Validator, ValidationState } from "pojo-validator";

export type ValidateModelCallback<T> = (model: T, fieldsToCheck?: string | Array<string> | undefined) => boolean;
export type ValidationErrorsForModel = (id: string) => ValidationErrors;

/**
 * Hook that provides a validate method and its associated validationErrors as a state object managed by validation.
 * 
 * validation is a callback that is takes a model and stores the errors for all the models that have been passed in index by their id.
 * 
 * @param validating the validation method.
 */
    export function useValidatorArrayCallback<T = any>(validating: (model: T, validation: ValidationState, fieldsToCheck?: Array<string>) => void, deps: React.DependencyList)
        : [ValidateModelCallback<T>, ValidationErrorsForModel] {
    const [validationErrors, setValidationErrors] = React.useState<{ [key: string ]: ValidationErrors }>({});

    // Create the method in the right format for us to return it so we can call validate() directly.
    const validate = React.useCallback((model: T, fieldsToCheck?: string | Array<string>): boolean => {
        let validator = new Validator(validating);

        // Work out the fields to be checked as we support passing a single field for convenience as well as passing an array.
        let fields: Array<string> | undefined = undefined;
        if (fieldsToCheck) {
            if (fieldsToCheck as string) {
                fields = [(fieldsToCheck as string)];
            } else {
                fields = (fieldsToCheck as any) as Array<string>;
            }
        }
        
        const ok = validator.validate(model, fields);

        setValidationErrors(prevState => ({
            ...prevState,
            [(model as any).id]: validator.errors(), // NOTE this line is not typesafe as it assumes T has a propery id.
        }));

        return ok;

    },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...deps, validating, setValidationErrors]);

    const getErrors = React.useCallback((id: string) => {
        const ret = validationErrors[id];
        return ret ?? {} as ValidationErrors;
    }, [validationErrors]);

    return [validate, getErrors];
}
