// Red, Amber, Green chart colours (Red first).
export const ragChartColours = [
    '#f86c6b',
    '#ffc107',
    '#4dbd74',
];

// Green, Amber, Red chart colours (Green first).
export const garChartColours = [
    '#4dbd74',
    '#ffc107',
    '#f86c6b',
];


// Colours For Before and after Charts.
export const beforeAndAfterColours = [
    '#1188FF',
    '#b7297d',
];


// Colours For Single Scale charts.
export const singleScaleColours = [
    '#1188FF',
]
// Consistant colouring for campaigns and self-service,
export const campaignsChartColor = '#ff9700'; // campaign-color
export const selfServiceChartColor = "#1188FF"; // skillshub-blue