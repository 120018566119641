import React from "react";
import { SaveInStoreOptions, useSaveInStore } from "../../../shared/useSaveInStore";
import { QuestionnaireAnalysisTypeLinkCreateInput, QuestionnaireAnalysisTypeLinkUpdateInput } from "../generated/globalTypes";
import { QuestionnaireAnalysisTypeLink } from "../models/QuestionnaireAnalysisTypeLink";
import { useCreateQuestionnaireAnalysisTypeLinkCallback } from "./useCreateQuestionnaireAnalysisTypeLinkCallback";
import { useUpdateQuestionnaireAnalysisTypeLinkCallback } from "./useUpdateQuestionnaireAnalysisTypeLinkCallback";




export function useSaveQuestionnaireAnalysisTypeLinkCallback(options: SaveInStoreOptions<QuestionnaireAnalysisTypeLink, string> = {}) {
    const [_create, createStatus] = useCreateQuestionnaireAnalysisTypeLinkCallback();
    const create = React.useCallback((model: Partial<QuestionnaireAnalysisTypeLink>) => _create(model as QuestionnaireAnalysisTypeLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionnaireAnalysisTypeLinkCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionnaireAnalysisTypeLink>) => _update(id, changes as QuestionnaireAnalysisTypeLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}