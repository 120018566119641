import gql from "graphql-tag";
import { Guid } from "guid-string";
import { subscriptionFields } from "../generated/subscriptionFields";
import moment from "moment";

export const subscriptionFieldsFragment = gql`
    fragment subscriptionFields on Subscription {
        id
        name
        archived
        brandColor
        brandImageBlobReferenceId
        isLimitedSubscription
        endDate
        numberOfQuestionnaires
        currencyText
        currencySymbol
    }
`;


export type Subscription = Omit<subscriptionFields, '__typename'>;

export const subscriptionDefaultValues = (): Partial<Subscription> => ({
    id: Guid.newGuid(),
    name: '',
    archived: false,
    brandColor: '',
    brandImageBlobReferenceId: null,
    isLimitedSubscription: false,
    endDate: moment().add(1, "years"),
    numberOfQuestionnaires: 0,
    currencyText: 'GBP',
    currencySymbol: '£',
});