import './header.scss';

import * as React from 'react';
import { Nav, Container } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { Link } from 'react-router-dom';
import { Navigation } from '../home/Navigation';
//import { LanguageSelect } from './LanguageSelect';
import { AppNavbarBrand } from './appNavbarBrand/AppNavbarBrand';
import { useSubscriptionEmulation } from '../../globalState/subscriptions/useSubscriptionEmulation';
import { useSubscriptionStyleOverridesViewModel } from '../../api/main/subscriptions/viewModels/useSubscriptionStyleOverridesViewModel';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';

export const Header = () => {

    const [currentManagedSubscriptionId] = useSubscriptionEmulation();
    const { data: { model: subscription } } = useSubscriptionStyleOverridesViewModel(currentManagedSubscriptionId);
    const { t } = useTranslation();
    return (
        <AppHeader fixed className="header">
            <Container fluid>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />

                <Link to="/me">
                    <AppNavbarBrand />
                </Link>
                <AppSidebarToggler className="d-md-down-none flex-shrink" display="lg" />

                <Nav className="menu-nav d-none d-md-flex flex-grow-1">
                    <Navigation />
                </Nav>
                <Nav className="menu-nav-xs d-flex d-md-none flex-grow-1">        
                </Nav>

                <ConditionalFragment showIf={!!currentManagedSubscriptionId}>
                    <Nav className="menu-nav d-none d-lg-flex flex-grow-1">
                        {t('header.managingASubscription', 'Currently Managing - {{ name }}', { name: subscription?.name })}
                    </Nav>
                </ConditionalFragment>

                <Nav className="signin-nav">
                    <UserNav />
                </Nav>

                {/*<Nav className="language-nav">
                    <NavItem>
                        <LanguageSelect />
                    </NavItem>
                </Nav>*/}

                {/* Special copy of the search box for xs displays */}
                {/*<Nav className="menu-nav d-flex d-md-none flex-grow-1 mb-1" style={{ minWidth: '100%'}}>*/}
                {/*    <AuthorizeContainer>*/}
                {/*        <HeaderSearchInput />*/}
                {/*    </AuthorizeContainer>*/}
                {/*</Nav>*/}
            </Container>
        </AppHeader>
    );
};
