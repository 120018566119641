import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { QuestionnaireType } from '../../api/main/models/codeOnly/QuestionnaireType';

/**
 * Subscription Questionnaires navigation menu for the app.
 */
export const SubscriptionQuestionnairesNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/subscriptionQuestionnaires/${QuestionnaireType.Campaign}`}>
                        <FontAwesomeIcon icon="list-alt" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.campaigns', 'Campaigns')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/subscriptionQuestionnaires/${QuestionnaireType.SelfService}`}>
                        <FontAwesomeIcon icon="archive" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.selfsewrvices', 'Self-service')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/subscriptionQuestionnaires/Template'}>
                        <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.templates', 'Templates')}
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};