import * as React from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { useConfirmEmailChangeCallback } from "../../api/account";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { ConditionalFragment } from "react-conditionalfragment";
import './ConfirmEmailChange.scss';

/**
 * Confirm an email address change for an account using a unique link included in the email. 
 */
export const ConfirmEmailChange = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailChangeCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <Container fluid className="confirm-email-change-page">
            <Row className="confirm-email-change-row">
                <Col xs={12} sm={5} className="login-screen-target-column">
                    <div className="login-target-image"></div>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
                <Col xs={12} sm={5} className="login-screen-input-column">
                    <Row className="impacts-logo-login-row">
                        <div className="impacts-logo-login-image"></div>
                    </Row>
                    <Row>
                        <h1>
                            {
                                confirmEmailErrors ? t('confirmEmailChange.confirmEmailChangeFailureHeading', 'Sorry we cannot confirm your change of email using this code')
                                    : emailConfirmed ? t('confirmEmailChange.confirmEmailChangeSuccessHeading', 'Thank you for confirming your change of email.')
                                        : (<LoadingIndicator />)
                            }
                        </h1>
                    </Row>
                    <Row>
                        <Col>
                            <ConditionalFragment showIf={!!confirmEmailErrors}>
                                <Alert color="danger">
                                    {confirmEmailErrors}
                                </Alert>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!!emailConfirmed}>
                                {/*<Alert color="success">*/}
                                {/*    <>{t('confirmEmailChange.thankYouForConfirming', 'Thank you for confirming your change of email.  We\'ve swapped that over for you.')} </>*/}
                                {/*</Alert>*/}
                                <p className="confirm-text">
                                    {t('confirmEmailChange.thankYouForConfirming', 'Thank you for confirming your change of email.  We\'ve swapped that over for you.')}
                                </p>
                                <Row className="sign-in-class">
                                    <Row className="sign-in-button">
                                        <LinkContainer to="/authentication/login">
                                            <Button color="secondary">
                                                {t('confirmEmail.signInToContinue', 'Continue')}
                                            </Button>
                                        </LinkContainer>
                                    </Row>
                                </Row>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
            </Row>
        </Container>
    );
};
