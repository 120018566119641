import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { SubscriptionEditQuestionnaire } from "./SubscriptionEditQuestionnaire";
import { SubscriptionAddQuestionnaire } from "./SubscriptionAddQuestionnaire";
import { SubscriptionQuestionnairesList } from "./SubscriptionQuestionnairesList";
//import { DeleteQuestionnaire } from "../../questionnaires/DeleteQuestionnaire";
import { QuestionnaireUsersList } from "../users/QuestionnaireUsersList";
import { CloneQuestionnaire } from "../../questionnaires/CloneQuestionnaire";
import { PreviewQuestionnaire} from "../../me/questionnaires/CompleteQuestionnaire";
import { CreateQuestionnaire } from "../../questionnaires/EditQuestionnaireBase";
import { SubscriptionDepartmentCampaignsList } from "./SubscriptionDepartmentCampaignsList";
//import { EditQuestionnaireTimescales } from "../../questionnaires/edit/questionnaireTimescales/EditQuestionnaireTimescales";
import { ViewQuestionnaireSummary } from "../../questionnaires/ViewQuestionnaireSummary";
import { QuestionnaireOverview } from "../../questionnaires/questionniareOverview/QuestionnaireOverview";


export const subscriptionQuestionnaireRoutes: Array<RouteEntry> = [
    // subscription administrator level user actions at subscription level
    { path: '/subscriptionQuestionnaires/:type', exact: true, component: SubscriptionQuestionnairesList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/add/:type', exact: true, component: SubscriptionAddQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/create/:id', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/create/:id/:activeTab', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/edit/:id', exact: true, component: SubscriptionEditQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/edit/:id/:activeTab', exact: true, component: SubscriptionEditQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/:type/clone/:questionnaireId', exact: true, component: CloneQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/preview/:id/:activeTab', exact: true, component: PreviewQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/summary/:id', exact: true, component: ViewQuestionnaireSummary, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // subscription administrator level user actions at subscription department level
    { path: '/manage/questionnaires/list/:departmentId/:type', exact: true, component: SubscriptionQuestionnairesList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/add/:type', exact: true, component: SubscriptionAddQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/create/:id', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/create/:id/:activeTab', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/edit/:id/:activeTab', exact: true, component: SubscriptionEditQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/users/list/:id', component: QuestionnaireUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/preview/:id/:activeTab', exact: true, component: PreviewQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/questionnaires/summary/:id', exact: true, component: ViewQuestionnaireSummary, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // department administrator level user actions
    { path: '/departmentQuestionnaires/list/:departmentId/:type', exact: true, component: SubscriptionDepartmentCampaignsList, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/:departmentId/add/:type', exact: true, component: SubscriptionAddQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/:departmentId/create/:id', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/:departmentId/create/:id/:activeTab', exact: true, component: CreateQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },    
    { path: '/departmentQuestionnaires/edit/:id', exact: true, component: SubscriptionEditQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/edit/:id/:activeTab', exact: true, component: SubscriptionEditQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/users/:departmentId/:id', exact: true, component: QuestionnaireUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/:departmentId/preview/:id/:activeTab', exact: true, component: PreviewQuestionnaire, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/:departmentId/summary/:id', exact: true, component: ViewQuestionnaireSummary, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },

    { path: '/subscriptionQuestionnaires/overview/:id', component: QuestionnaireOverview, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

];
