import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonDropdown, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { RegisteredUsersListBase } from "../../users/RegisteredUsersListBase";

/**
 * List of users for a subscription department accessed at subscription administrator level
 */
export const SubscriptionDepartmentUsersList = () => {
    const { t } = useTranslation();
    const { departmentId } = useParams<{ departmentId: string | undefined }>();
    const [isAddMenuOpen, toggleAddMenuOpen] = useToggleState();

    const baseRoute = '/manage/subscriptionDepartments/users';
    return (
        <RegisteredUsersListBase
            title={t('subscriptionDepartmentUsersList.title', 'Department Users')}
            baseRoute={baseRoute}
            addButtons={
                <ButtonGroup>
                    <LinkContainer to={`${baseRoute}/${!!departmentId ? departmentId + '/' : ''}add`}>
                        <Button color="primary">
                            <FontAwesomeIcon icon="plus" /><> {t('registeredUserList.add', 'Add')}</>
                        </Button>
                    </LinkContainer>
                    <ButtonDropdown isOpen={isAddMenuOpen} toggle={() => toggleAddMenuOpen()}>
                        <DropdownToggle color="primary" outline caret>
                            <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <LinkContainer to={`${baseRoute}/${departmentId}/users/addList`}>
                                <DropdownItem className="text-danger">
                                    <FontAwesomeIcon icon="trash" />
                                    <> {t('common.delete', 'Add from list')}</>
                                </DropdownItem>
                            </LinkContainer>
                        </DropdownMenu>
                    </ButtonDropdown>
                </ButtonGroup>
            }
        />
    );
};