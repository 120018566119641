import { Redirect } from 'react-router';

/**
 * Default Subscription Navigation home page.
 */
export const SubscriptionQuestionnairesHome = () => {
    return (
        <>
            <Redirect to="/subscriptionQuestionnaires/Campaign" />
        </>
        );
};