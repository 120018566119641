import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../shared/abstractStore';
import { meDashboardViewModelQuery, meDashboardViewModelQueryVariables } from '../generated/meDashboardViewModelQuery';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { profileFieldsFragment } from '../models/Profile';
import { questionnaireFieldsFragment } from '../models/Questionnaire';
import { questionnaireRespondentSessionFieldsFragment } from '../models/QuestionnaireRespondentSession';
import { questionResponseFieldsFragment } from '../models/QuestionResponse';
import { questionnaireSectionFieldsFragment } from '../models/QuestionnaireSection';
import { questionFieldsFragment } from '../models/Question';

export interface MeDashboardViewModelOptions extends AsyncLoadOptions {
    isUserId?: boolean,
}


/**
 * Get all data for the Me view from the store for a profile.
 * @param id
 * @param options
 */
export function useMeDashboardViewModel(id: string | undefined | null, options: MeDashboardViewModelOptions = {}): AsyncLoadResult<meDashboardViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<meDashboardViewModelQuery, meDashboardViewModelQueryVariables>(
        gql`
        query meDashboardViewModelQuery ($profileId: ID, $userId: ID) {
            viewModel: meDashboardViewModel (profileId: $profileId, userId: $userId) {
                id
                questionnaires {
                    ...questionnaireFields
                }
                questionnaireSections {
                    ...questionnaireSectionFields
                }
                questions {
                    ...questionFields
                }
            }

            questionnaireRespondentSessions (userId: $userId) {
                ...questionnaireRespondentSessionFields
                responses {
                    ...questionResponseFields
                }
            }

            profile (id: $profileId, userId: $userId) {
                ...profileFields
            }
        }

        ${questionnaireFieldsFragment}
        ${questionnaireSectionFieldsFragment}
        ${questionFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${questionResponseFieldsFragment}
        ${profileFieldsFragment}
        `,
        {
            variables: {
                profileId: !options.isUserId ? id ?? Guid.empty : null,
                userId: options.isUserId ? id ?? Guid.empty : null,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
