import { Library, library } from '@fortawesome/fontawesome-svg-core';
import { faBell, faClone, faCube, faSpinner, faLock, faUser, faSearch, faPlus, faCogs, faSave, faEdit, faUsers, faTrash, faCaretUp, faCaretDown, faCaretLeft, faUndo, faMinus, faExclamationCircle, faExclamationTriangle, faCaretRight, faTimesCircle, faStopwatch, faUserClock, faTimes, faStop, faDesktop, faRuler, faFlagCheckered, faPlay, faUserCheck, faCreditCard, faPhone, faEnvelope, faSchool, faPoundSign, faVoteYea, faPersonBooth, faTrashAlt, faChevronLeft, faChevronRight, faDownload, faCross, faCheckCircle, faEye, faTrashRestore, faSync, faThumbsDown, faThumbsUp, faCloudDownloadAlt, faPhotoVideo, faTag, faUserTag, faLink, faFileAlt, faInfo, faUserGraduate, faIdCardAlt, faTachometerAlt, faUsersCog, faBuilding, faGraduationCap, faCarCrash, faChalkboardTeacher, faClipboardCheck, faTags, faSolarPanel, faVideo, faThLarge, faThList, faHome, faBriefcase, faPencilAlt, faPencilRuler, faArchive, faListAlt, faClipboard, faPercent, faMoneyBillAlt, faChartLine, faSortNumericDown, faSmile, faMoneyBillWave, faCalculator, faDollarSign, faHandshake, faQuestionCircle, faChartBar, faPollH, faUserMinus, faRedo, faArrowAltCircleRight, faChartArea, faArrowAltCircleLeft, faBalanceScale, faBalanceScaleLeft, faBalanceScaleRight, faMoneyBill, faDraftingCompass, faUpload, faUserTimes, faKeyboard, faGripHorizontal, faGripVertical, faPrint, faFolder, faInfoCircle, faPaperPlane, faSortAmountUp, faBan, faChartPie, faAlignLeft,  } from '@fortawesome/free-solid-svg-icons';
import { faFrown as farFrown, faFrownOpen as farFrownOpen, faCircle as farCircle, faClock as farClock, faCalendarAlt as farCalendarAlt, faListAlt as farListAlt, faCalendarTimes as farCalendarTimes, } from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faArchive,
        faArrowAltCircleRight,
        faArrowAltCircleLeft,
        faBalanceScale,
        faBalanceScaleLeft,
        faBalanceScaleRight,
        faBan,
        faBriefcase,
        faBuilding,
        faCarCrash,
        faCalculator,
        faCaretUp,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faChalkboardTeacher,
        faChartLine,
        faChartArea, 
        faChartBar,
        faChartPie,
        faAlignLeft,
        faCheckCircle,
        faChevronLeft,
        faChevronRight,
        faClipboard,
        faClipboardCheck,
        faClone,
        faCloudDownloadAlt,
        faCogs,
        faCreditCard,
        faCross,
        faCube,
        faDesktop,
        faDownload,
        faDollarSign,
        faDraftingCompass,
        faEdit,
        faExclamationCircle,
        faExclamationTriangle,
        faEye,
        faFileAlt,
        faFolder,
        faFlagCheckered,
        faGraduationCap,
        faGripHorizontal,
        faGripVertical,
        faHome,
        faHandshake,
        faIdCardAlt,
        faInfo,
        faInfoCircle,
        faKeyboard,
        faLink,
        faListAlt,
        faLock,
        faMinus,
        faMoneyBillAlt,
        faMoneyBill,
        faMoneyBillWave,
        faPaperPlane,
        faPencilAlt,
        faPencilRuler,
        faPersonBooth,
        faPercent,
        faPhotoVideo,
        faPlay,
        faPlus,
        faPollH,
        faPoundSign,
        faPrint,
        faQuestionCircle,
        faRuler,
        faRedo,
        faSave,
        faSchool,
        faSearch,
        faSmile,
        faSolarPanel,
        faSortNumericDown,
        faSortAmountUp,
        faSpinner,
        faStop,
        faStopwatch,
        faSync,
        faTachometerAlt,
        faTag,
        faTags,
        faThLarge,
        faThList,
        faThumbsDown,
        faThumbsUp,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashAlt,
        faTrashRestore,
        faUndo,
        faUser,
        faUserTimes,
        faUserCheck,
        faUpload,
        faUserClock,
        faUsersCog,
        faUserMinus,
        faUserGraduate,
        faUserTag,
        faUsers,
        faVideo,
        faVoteYea,

        // regular
        farCalendarAlt,
        farCalendarTimes,
        farCircle,
        farClock,
        farFrown,
        farFrownOpen,
        farListAlt,
        faPhone,
        faEnvelope,
        faBell,
    );
};
