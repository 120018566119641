import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { createQuestionnaireAnalysisTypeLinkMutation, createQuestionnaireAnalysisTypeLinkMutationVariables } from "../generated/createQuestionnaireAnalysisTypeLinkMutation";
import { QuestionnaireAnalysisTypeLinkCreateInput } from "../generated/globalTypes";
import { questionnaireAnalysisTypeLinkFieldsFragment } from "../models/QuestionnaireAnalysisTypeLink";



export function useCreateQuestionnaireAnalysisTypeLinkCallback(): [(model: QuestionnaireAnalysisTypeLinkCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createQuestionnaireAnalysisTypeLinkMutation, createQuestionnaireAnalysisTypeLinkMutationVariables>(
        gql`
            mutation createQuestionnaireAnalysisTypeLinkMutation ($model: QuestionnaireAnalysisTypeLinkCreateInput!) {
                createQuestionnaireAnalysisTypeLink(model: $model) {
                    ...questionnaireAnalysisTypeLinkFields
                }
            }

            ${questionnaireAnalysisTypeLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: QuestionnaireAnalysisTypeLinkCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}