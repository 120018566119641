import { Col, Row, } from "reactstrap";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import "./questionMultipleChoice.scss";
import "./questionPresenter.scss";
import { useTranslation } from "react-i18next";
import { ModelArrayChanges } from "../../../shared/useChanges";
import { ResponseMultipleChoice } from "./ResponseMultipleChoice";
import { Fragment } from "react";
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface QuestionMultipleChoiceProps {
    question: Question,
    sessionId?: string,
    responsesManager: ModelArrayChanges<QuestionResponse, string>,
    userInputDisabled?: boolean,
    orderedFilteredItems: QuestionResponse[],
}

// Displays multiple possible responses and the user is allowed to choose as many as they want.
// if no sessionId is passed in we are previewing.

export const QuestionMultipleChoice = (props: QuestionMultipleChoiceProps) => {
    const {
        question, sessionId, responsesManager,
        userInputDisabled, orderedFilteredItems
    } = props;
    const { t } = useTranslation();

    return (
        <div className="question-multiplechoice">
            <Row className="question-text">
                <Col xs={12}>
                    {HtmlDisplay({ html: question.questionText })}
                </Col>
                <Col className="question-sub-text text-muted" xs={12}>
                    {t("questionMultipleChoice.instructions", "Please select all the answers you think are appropriate")}
                </Col>
            </Row>
            <Row>
                {orderedFilteredItems.map((response, responseIndex) => {
                    return (
                        <Fragment key={responseIndex}>
                            <ResponseMultipleChoice
                                question={question}
                                sessionId={sessionId}
                                model={response}
                                change={changes => responsesManager.changeFor(response.id, changes)}
                                userInputDisabled={userInputDisabled}
                                responseIndex={responseIndex}
                            />
                        </Fragment>
                    )
                })
                }
            </Row>
        </div>
    );
};