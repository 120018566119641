import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { QuestionnaireSectionCreateInput } from '../generated/globalTypes';
import { createQuestionnaireSectionMutation, createQuestionnaireSectionMutationVariables } from '../generated/createQuestionnaireSectionMutation';
import { questionnaireSectionFieldsFragment } from '../models/QuestionnaireSection';

/**
 * Get a callback to create a QuestionnaireSection in the store.
 */
export function useCreateQuestionnaireSectionCallback(): [(model: QuestionnaireSectionCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createQuestionnaireSectionMutation, createQuestionnaireSectionMutationVariables>(
        gql`
            mutation createQuestionnaireSectionMutation ($model: QuestionnaireSectionCreateInput!) {
                createQuestionnaireSection(model: $model) {
                    ...questionnaireSectionFields
                }
            }

            ${questionnaireSectionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: QuestionnaireSectionCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
