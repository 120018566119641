import { ValidationErrors } from "pojo-validator";
import { useCallback, useMemo } from "react";
import { Col, Row, } from "reactstrap";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { ValidatedElasticInput } from "../../../shared/validatedInputExtended";
import { ValidateCallback } from "pojo-validator-react";
import { ConditionalFragment } from "react-conditionalfragment";
import "./questionTextBox.scss";
import "./questionPresenter.scss";
import { useTranslation } from "react-i18next";
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface QuestionTextBoxProps {
    question: Question,
    sessionId?: string,
    isFirstResponse: boolean,
    isLastResponse: boolean,
    model: QuestionResponse,
    change: (changes: Partial<QuestionResponse>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    addResponse: () => void;
    removeResponse: () => void;
    isMaxResponse?: boolean;
    userInputDisabled?: boolean;
    responseNumber: number;
}

// displays a question requiring a text box either for input or for preview.
// there may be multiple text boxes for 1 question - each is passed individual into its own QuestionTextBox
// with only the first one displaying the header detail
// if no sessionId is passed in we are previewing.

export const QuestionTextBox = (props: QuestionTextBoxProps) => {
    const {
        question, sessionId, isFirstResponse, isLastResponse, model, change, validate, validationErrors,
        addResponse, removeResponse, isMaxResponse = false, userInputDisabled, responseNumber,
    } = props;
    const { t } = useTranslation();

    // handle input in the text field
    const handleTextInput = useCallback((textInput: string) => {
        // Change the text on the response and check for an empty response.
        change({ textInput: textInput, isUserInput: !!textInput.trim().length });

        // see if we need another text box to complete and add a response if necessary to generate one
        if (isLastResponse && !isMaxResponse && !!textInput.trim().length) {
            addResponse();
        } else if (!textInput.trim().length) {
            removeResponse();
        }
    }, [change, isLastResponse, addResponse, isMaxResponse, removeResponse]);

    const questionInstructionText = useMemo(() => {
        if (question.minTextBoxes === 0) {
            return t("questionTextBox.responseText1", `This response is optional, You may leave it blank or enter a maximum of ${question.maxTextBoxes} responses`);
        }

        if (question.maxTextBoxes === question.minTextBoxes && question.minTextBoxes === 1) {
            return '';
        }

        if (question.maxTextBoxes === question.minTextBoxes) {
            return t("questionTextBox.responseText2", `Please give ${question.minTextBoxes} answers`);
        }

        if (question.maxTextBoxes !== question.minTextBoxes) {
            return t("questionTextBox.responseText3", `Please enter a minimum of ${question.minTextBoxes} responses with a maximum of ${question.maxTextBoxes}`);
        }

    }, [question, t,]);

    return (
        <div className="question-textbox">
            <ConditionalFragment showIf={isFirstResponse}>
                <Row className="question-text">
                    <Col xs={12}>
                        {HtmlDisplay({ html: question.questionText })}
                    </Col>
                    <Col className="question-sub-text text-muted" xs={12}>
                        {questionInstructionText}
                    </Col>
                </Row>
            </ConditionalFragment>
            <Row>
                <Col xs={12}>
                    <ConditionalFragment showIf={question.minTextBoxes > 1}>
                        <div className="text-box-number">{responseNumber}</div>
                    </ConditionalFragment>
                    <ValidatedElasticInput disabled={!sessionId || !!userInputDisabled} readOnly={!sessionId} type="textarea" name="textInput" value={model?.textInput}
                        onChange={e => handleTextInput(e.currentTarget.value)}
                        onBlur={e => validate()}
                        validationErrors={validationErrors['textInput']}
                    />
                </Col>
            </Row>
        </div>
    );
};