import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { LearningUnit } from "../../../api/main/models/LearningUnit";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { QuestionnaireSection } from "../../../api/main/models/QuestionnaireSection";
import { ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";
import { GeneratedIndex } from "../../shared/generatedIndex/GeneratedIndex";
import { ComparisonScaleBarChart } from "../charts/ComparisonScaleBarChart";
import { SingleScaleBarChart } from "../charts/SingleScaleBarChart";
import "./reportSummary.scss"

//* Summary section for questionnaire reports
interface ReportSummaryProps {
    learningUnit?: LearningUnit,
    questionnaire?: Questionnaire,
    isIncludeGraphs: boolean,
    isIncludeIndex: boolean,
    isIncludeLearningUnit: boolean,
    isIncludeDescription: boolean,
    orderedSections: Array<QuestionnaireSection>,
    scrollToSectionId: string,
    handleScrollRequest: (scrollDestination: any) => Promise<void>,
    beforeAndAfterNumericChartData?: Array<ChartDataSeries>,
    singleScaleNumericChartData?: Array<ChartDataSeries>,
    beforeAndAfterPercentageChartData?: Array<ChartDataSeries>,
    singleScalePercentageChartData?: Array<ChartDataSeries>,
}

export const ReportSummary = (props: ReportSummaryProps) => {
    const {
        learningUnit,
        questionnaire,
        isIncludeGraphs,
        isIncludeIndex,
        isIncludeLearningUnit,
        isIncludeDescription,
        orderedSections,
        scrollToSectionId,
        handleScrollRequest,
        beforeAndAfterNumericChartData,
        singleScaleNumericChartData,
        beforeAndAfterPercentageChartData,
        singleScalePercentageChartData,

    } = props;
    const { t } = useTranslation();

    return (
        <div className="report-summary-container">
            {/* summary information - dependant on selections made in the report builder */}
            <div className="report-summary-header">
                {t('companyQuestionnaireReport.summary.header', 'Summary')}
            </div>
            <ConditionalFragment showIf={isIncludeDescription}>
                <div className="report-summary-description">
                    <Label className="label-header">{t("companyQuestionnaireReport.summary.description", "Description")}</Label>
                    <span>
                        {questionnaire?.description ?? ''}
                    </span>
                </div>
            </ConditionalFragment>

            <ConditionalFragment showIf={!!learningUnit && isIncludeLearningUnit}>
                <div className="report-summary-learningUnit">
                    <Label className="label-header">{t('companyQuestionnaireReport.learningUnit-header', 'Learning Unit')}</Label>
                    <Row className="report-summary-learningUnit-row">
                        <Col xs={12} sm={6}>
                            <Label>{t('companyQuestionnaireReport.learningUnit.name', 'Learning')}</Label>
                            <span>{" " + learningUnit?.name}</span>
                        </Col>
                        <Col xs={12} sm={6}>
                            <Label>{t('companyQuestionnaireReport.learningUnit.learningUnitType', 'Type')}</Label>
                            <span>{learningUnit?.learningUnitType}</span>
                        </Col>
                    </Row>
                    <Row className="report-summary-learningUnit-row">
                        <Col xs={12}>
                            <Label>{t('companyQuestionnaireReport.learningUnit.provider', 'By')}</Label>
                            <span>{" " + learningUnit?.provider}</span>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={!!learningUnit?.startDate || !!learningUnit?.endDate}>
                        <Row className="report-summary-learningUnit-row">
                            <Col xs={12} sm={6}>
                                <Label>{t('companyQuestionnaireReport.learningUnitstartDate', 'Start Date')}</Label>
                                <span>{!!learningUnit?.startDate ? moment(learningUnit?.startDate).local().format("L") : t('companyQuestionnaireReport.learningUnitstartDate.noStartDate', ' Not set')}</span>
                            </Col>
                            <Col xs={12} sm={6}>
                                <Label>{t('companyQuestionnaireReport.learningUnitEndDate', 'End Date')}</Label>
                                <span>{!!learningUnit?.endDate ? moment(learningUnit?.endDate).local().format("L") : t('companyQuestionnaireReport.learningUnitEndDate.noEndDate', ' Not set')}</span>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </div>
            </ConditionalFragment>
            <ConditionalFragment showIf={
                isIncludeGraphs && (
                    (!!beforeAndAfterNumericChartData && !!beforeAndAfterNumericChartData[0].data.length) ||
                    (!!singleScaleNumericChartData && !!singleScaleNumericChartData[0].data.length) ||
                    (!!beforeAndAfterPercentageChartData && !!beforeAndAfterPercentageChartData[0].data.length) ||
                    (!!singleScalePercentageChartData && !!singleScalePercentageChartData[0].data.length)
                )}>
                <div className="report-summary-graphs">
                    {/* graphs and summary information */}
                    {/* if a graph relates to a particular question there will be a link to scroll to that question */}
                    <div className="report-dashboard-header">
                        {t('companyQuestionnaireReport.summary.dashboard', 'Dashboard')}
                    </div>
                    <Row>
                        <ConditionalFragment showIf={!!beforeAndAfterNumericChartData && !!beforeAndAfterNumericChartData[0].data.length}>
                            <Col xs={12} sm={6}>
                                <ComparisonScaleBarChart handleEventClick={handleScrollRequest} data={beforeAndAfterNumericChartData ?? []} propsTitle={"Average Score For Numeric Comparison Questions"} height={250} width={500} />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!singleScaleNumericChartData && !!singleScaleNumericChartData[0].data.length}>
                            <Col xs={12} sm={6}>
                                <SingleScaleBarChart handleEventClick={handleScrollRequest} data={singleScaleNumericChartData ?? []} propsTitle={"Average Score For Numeric Single Scale Questions"} height={250} width={500} />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!beforeAndAfterPercentageChartData && !!beforeAndAfterPercentageChartData[0].data.length}>
                            <Col xs={12} sm={6}>
                                <ComparisonScaleBarChart handleEventClick={handleScrollRequest}  data={beforeAndAfterPercentageChartData ?? []} propsTitle={"Average Score For Percentage Comparison Questions"} height={250} width={500} />
                            </Col>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!singleScalePercentageChartData && !!singleScalePercentageChartData[0].data.length}>
                            <Col xs={12} sm={6}>
                                <SingleScaleBarChart handleEventClick={handleScrollRequest} data={singleScalePercentageChartData ?? []} propsTitle={"Average Score For Percentage Single Scale Questions"} height={250} width={500} />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                </div>
            </ConditionalFragment>

            {/* if there's more than one section included we add an index to the sections */}
            <ConditionalFragment showIf={orderedSections.length > 1 && isIncludeIndex}>
                <GeneratedIndex
                    scrollToId={scrollToSectionId}
                    indexItems={orderedSections}
                    handleLinkClick={handleScrollRequest}
                    headerText={t('companyQuestionnaireReport.index.header', 'Index of Sections')}
                    isInitialled={true}
                />
            </ConditionalFragment>
        </div>
    );
};