import gql from "graphql-tag";
import { Guid } from "guid-string";
import { questionnaireAnalysisTypeLinkFields } from "../generated/questionnaireAnalysisTypeLinkFields";




export const questionnaireAnalysisTypeLinkFieldsFragment = gql`
    fragment questionnaireAnalysisTypeLinkFields on QuestionnaireAnalysisTypeLink {
        id
        analysisTypeId
        questionnaireId
        subscriptionId
        baseAmount
        archived
    }
`;

export type QuestionnaireAnalysisTypeLink = Omit<questionnaireAnalysisTypeLinkFields, '__typename'>;

export const questionnaireAnalysisTypeLinkDefaultValues = (): Partial<QuestionnaireAnalysisTypeLink> => ({
    id: Guid.newGuid(),
    analysisTypeId: undefined,
    questionnaireId: undefined,
    subscriptionId: undefined,
    baseAmount: 0,
    archived: false,
});