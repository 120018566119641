import * as React from 'react';
import { Layout } from './components/layout/Layout';
import { ServiceProviderContext } from 'inject-typesafe-react';
import { createServiceProvider } from './configure/configureServices';
import { AppRoutes } from './components/shared/AppRoutes';
import { appRoutes } from './configure/routeConfig';
import { initializeIcons } from './configure/iconsConfig';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/react-hooks';
import { apolloClient, apolloCachePersistor } from './configure/configureApollo';
import { configurei18n } from './configure/i18n';
import { LoadingIndicator } from './components/shared/LoadingIndicator';
import { I18nextProvider } from 'react-i18next';
import authService from './api/api-authorization/AuthorizeService';
import { configureErrorMessages } from './configure/errorMessageConfig';
import { RecoilRoot } from 'recoil';
import { purgeRecoilPersist } from './configure/recoilPersistConfig';
import { SubscriptionStyleOverrides } from './components/subscriptions/SubscriptionStyleOverrides';

// Configure services.
const serviceProvider = createServiceProvider();

// When the authorization state changes, clear the right parts of our caches.
authService.subscribe(() => {
    purgeRecoilPersist();
    apolloCachePersistor.purge();
});

// Configure icons.
initializeIcons();

// Configure i18n
const i18n = configurei18n();

// Configure server and 3rd party error messages to make them friendly and translatable.
configureErrorMessages();



// App component.
export const App = () => {
    return (
        <HelmetProvider>
            <I18nextProvider i18n={i18n}>
                <ServiceProviderContext.Provider value={serviceProvider}>
                    <RecoilRoot>
                        <ApolloProvider client={apolloClient}>
                            <React.Suspense fallback={<LoadingIndicator fullWidth={true} />}>
                                <SubscriptionStyleOverrides />
                                <Layout appRoutes={appRoutes}>
                                    <Helmet defaultTitle={i18n.t('Skillshub Impacts')} titleTemplate={i18n.t('%s | Skillshub Impacts')}>
                                    </Helmet>
                                    <AppRoutes routes={appRoutes} />
                                </Layout>
                            </React.Suspense>
                        </ApolloProvider>
                    </RecoilRoot>
                </ServiceProviderContext.Provider>
            </I18nextProvider>
        </HelmetProvider>
    );
};
