import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { questionnairesUsersListSupportingDataQuery } from "../../generated/questionnairesUsersListSupportingDataQuery";
import { questionFieldsFragment } from "../../models/Question";
import { questionResponseFieldsFragment } from "../../models/QuestionResponse";

export interface QuestionnaireUsersListSupportingDataAsyncLoadOptions extends AsyncLoadOptions {
    questionnaireId?: string | null | undefined,
}

/**
 * Get a list of QuestionnaireQuestions and QuestionnaireQuestion Responses from the store.
 *  @param options
 */

export function useQuestionnaireUserListSupportingData(options: QuestionnaireUsersListSupportingDataAsyncLoadOptions = {}): AsyncLoadResult<questionnairesUsersListSupportingDataQuery> {
    // Query the Data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<questionnairesUsersListSupportingDataQuery>(
        gql`
        query questionnairesUsersListSupportingDataQuery ($questionnaireId: ID) { 

            questionResponses (questionnaireId: $questionnaireId) {
                ...questionResponseFields
            }
            
            questions (questionnaireId: $questionnaireId) {
                ...questionFields
            }
        }

        ${questionResponseFieldsFragment}
        ${questionFieldsFragment}
        
        `,
        {
            variables: {
                questionnaireId: !!options.questionnaireId ? options.questionnaireId : null,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );
    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}