import { useMemo } from "react";
import { administrationDashboardViewModelQuery_questionnaireRespondentSessions } from "../../../api/main/generated/administrationDashboardViewModelQuery";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { Questionnaire } from "../../../api/main/models/Questionnaire";

/**
 * Return sessions for the type of questionnaire requested, filtered by department if requested.
 */
export function useSessionsForQuestionnaireType(
    allQuestionnaires: Array<Questionnaire>,
    allSessions: Array<administrationDashboardViewModelQuery_questionnaireRespondentSessions>,
    requiredType: QuestionnaireType,
    departmentId?: string) : administrationDashboardViewModelQuery_questionnaireRespondentSessions[]{
    const ret = useMemo(() => {
        if (!allQuestionnaires || !allSessions) {
            return [];
        }
        const typeQuestionnaires = allQuestionnaires?.filter(item => item.questionnaireType === requiredType) ?? [];
        return allSessions
            ?.filter((session) => {
                // if a department filter is needed, exclude if user was not in this department when the session was created.
                // note: the user may have moved to another department since but we will include if they were in this 
                // department when they completed the questionnaire.
                if (!!departmentId && session.subscriptionDepartmentId !== departmentId) {
                    return false;
                }

                // exclude if the session is not for a self service questionnaire
                if (!typeQuestionnaires.find(item => item.id === session.questionnaireId)) {
                    return false;
                }
                // we are left with self services for users in this department at the time the session was created
                return true;
            })

    }, [allQuestionnaires, allSessions, requiredType, departmentId]);

    return ret;
}