import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { Profile } from "../../../api/main/models/Profile";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse, questionResponseDefaultValues } from "../../../api/main/models/QuestionResponse";
import { ModelArrayChanges  } from "../../../shared/useChanges";
import { ValidateModelCallback, ValidationErrorsForModel } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { getDummyResponses } from "../utilities/getDummyResponse";
import { QuestionMeasure } from "./QuestionMeasure";
import "./questionMeasure.scss";
import { QuestionMultipleChoice } from "./QuestionMultipleChoice";
import { QuestionPickOne } from "./QuestionPickOne";
import "./questionPresenter.scss";
import { QuestionTextBox } from "./QuestionTextBox";

export interface QuestionPresenterProps {
    question: Question,
    sessionId?: string,
    responsesManager: ModelArrayChanges<QuestionResponse, string>,
    validateResponse: ValidateModelCallback<QuestionResponse>,
    responseValidationErrors: ValidationErrorsForModel,
    profile?: Profile,
    userInputDisabled?: boolean,
    currencySymbol: string,
}

// Displays a question either for input or for preview.
// If no sessionId is passed in we are previewing.

export const QuestionPresenter = (props: QuestionPresenterProps) => {
    const {
        question,
        sessionId,
        responsesManager,
        validateResponse,
        responseValidationErrors,
        profile,
        userInputDisabled,
        currencySymbol,
    } = props;

    const orderedFilteredItems = useMemo(() => {
        // Filter for the question
        let ret = responsesManager.model.filter(item => item.questionId === question.id);

        // Only show user's actual responses if input is disabled
        if (!!userInputDisabled) {
            ret = ret.filter(item => item.isUserInput);
        }

        // Deal with a preview where there is no real response
        if (!ret.length) {
            ret = getDummyResponses(question);
        }

        ret.sort((a, b) => {
            if (a.displayOrder === b.displayOrder) {
                return 0;
            } else if (a.displayOrder > b.displayOrder) {
                return 1;
            } else {
                return -1;
            }
        });

        // If we are viewing and we have created another text box response that has not been used - don't show it.
        if (!!userInputDisabled && question.responseType === QuestionResponseType.TextBoxes) {
            let firstResponseId = ret[0].id;
            ret = ret.filter(item => item.isUserInput || item.id === firstResponseId);
        }

        return ret;
    }, [responsesManager.model, question, userInputDisabled,]);

    const newResponse = {
        ...questionResponseDefaultValues(),
        questionId: question.id,
        userId: profile?.userId ?? '',
        questionnaireId: question.questionnaireId,
        questionnaireRespondentSessionId: sessionId,
    }

    //ui
    return (
        <>
            {
                question.responseType === QuestionResponseType.MultipleChoice ?
                    <>
                        {/* Special for multiple choice - We do all the responses together */}
                        <div className="question-presenter ">
                            <QuestionMultipleChoice
                                question={question}
                                sessionId={sessionId}
                                responsesManager={responsesManager}
                                userInputDisabled={userInputDisabled}
                                orderedFilteredItems={orderedFilteredItems}
                            />
                        </div>
                    </>
                    :
                    <>
                        {/* Every other type */}
                        {orderedFilteredItems.map((response, responseIndex) => {
                            return (
                                <div key={response.id}>
                                    <ConditionalFragment showIf={question.questionType === QuestionType.Measure}>
                                        <div className="question-presenter ">
                                            <QuestionMeasure
                                                question={question}
                                                sessionId={sessionId}
                                                model={response}
                                                change={changes => responsesManager.changeFor(response.id, changes)}
                                                validate={(fieldsToCheck) => validateResponse(response, fieldsToCheck)}
                                                validationErrors={responseValidationErrors(response.id)}
                                                userInputDisabled={userInputDisabled}
                                                isFirstResponse={responseIndex === 0}
                                                currencySymbol={currencySymbol}
                                            />
                                        </div>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={question.questionType !== QuestionType.Measure && question.responseType === QuestionResponseType.TextBoxes}>
                                        <div className="question-presenter ">
                                            <QuestionTextBox
                                                question={question}
                                                sessionId={sessionId}
                                                isFirstResponse={responseIndex === 0}
                                                isLastResponse={responseIndex === orderedFilteredItems.length - 1}
                                                model={response}
                                                change={changes => responsesManager.changeFor(response.id, changes)}
                                                validate={(fieldsToCheck) => validateResponse(response, fieldsToCheck)}
                                                validationErrors={responseValidationErrors(response.id)}
                                                addResponse={() => responsesManager.addFor(newResponse)}
                                                removeResponse={() => responsesManager.removeFor(response.id)}
                                                isMaxResponse={responseIndex >= question.maxTextBoxes - 1}
                                                userInputDisabled={userInputDisabled}
                                                responseNumber={responseIndex + 1}
                                            />
                                        </div>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={question.questionType !== QuestionType.Measure && (question.responseType === QuestionResponseType.PickOne)}>
                                        <div className="question-presenter ">
                                            <QuestionPickOne
                                                question={question}
                                                sessionId={sessionId}
                                                model={response}
                                                change={changes => responsesManager.changeFor(response.id, changes)}
                                                userInputDisabled={userInputDisabled}
                                            />
                                        </div>
                                    </ConditionalFragment>
                                </div>
                            );
                        })
                        }
                    </>
            }
        </>
    );
};