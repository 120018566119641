import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { TFunction } from "i18next";


/**
 * Question types
 */

/**
* Any New Question Types Must Also Be Manually Added to  \Services\QuestionnaireQuestionsImportService\QuestionnaireQuestionsImportService.cs
 * Line 96 - 102
*/

export enum QuestionType {
    Intent = "Intent",
    Measure = "Measure",
    Improvement = "Improvement",
    Commitment = "Commitment",
}

/**
 * Return all question type names.
 * @param t
 */
export function questionTypeDisplayName(type: QuestionType, t: TFunction): string {
    switch (type) {
        case QuestionType.Measure: return t('questionTypeText.Measure', 'Capture quantitative data');
        case QuestionType.Commitment: return t('questionTypeText.Commitment', 'Document an action');
        case QuestionType.Improvement: return t('questionTypeText.Improvement', 'Report an achievement');
        case QuestionType.Intent: return t('questionTypeText.Intent', 'Note a future aim');
    }
}

export function questionTypeIcon(type: QuestionType): IconProp {
    switch (type) {
        case QuestionType.Measure: return "ruler";
        case QuestionType.Commitment: return "handshake";
        case QuestionType.Improvement: return "chart-line";
        case QuestionType.Intent: return "pencil-alt";
    }
}