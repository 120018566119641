import { Button, ButtonGroup, Col } from 'reactstrap';
import "./pdfPrintButtons.scss";

export interface PdfPrintButtonsProps {
    onClick: () => void;
}

/**
 * Displays a PDF and Print button in a consistent way.
 */
export const PdfPrintButtons = (props: PdfPrintButtonsProps) => {
    const { onClick } = props;

    return (
        <>
            <Col xs="auto" className='pdf-print-button-container'>
                <ButtonGroup>
                    <Button className="analyser-imageButton d-flex justify-content-center align-items-center"
                        onClick={onClick}>
                        <img className="analyser-pdfImage" alt=''></img>
                    </Button>
                </ButtonGroup>
            </Col>
            <Col xs="auto" className='pdf-print-button-container'>
                <ButtonGroup>
                    <Button className="analyser-imageButton d-flex justify-content-center align-items-center"
                        onClick={onClick}>
                        <img className="analyser-printImage" alt=''></img>
                    </Button>
                </ButtonGroup>
            </Col>
        </>
    );
};
