import { ApexOptions }from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { useTranslation } from "react-i18next";
import "./comparisonScaleBarChart.scss";
import { beforeAndAfterColours } from "../../dashboard/charts/dashboardChartColors";
import { chartDataConverters } from "../../dashboard/chartDataUtilities/ChartDataConverter";
import { ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";

export interface ComparisonScaleBarChartProps {
    data: Array<ChartDataSeries>,

    width?: string | number | undefined,
    height?: string | number | undefined,

    handleEventClick: (eventId: string) => void,


    propsTitle?: string,
}

/**
 * Chart that shows average scores as a bar.
 */
export const ComparisonScaleBarChart = (props: ComparisonScaleBarChartProps) => {
    const {
        data,
        width,
        height,
        propsTitle,
        handleEventClick
    } = props;

    const { t } = useTranslation();

    // Convert data into format needed for this graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toColumn(data), [data]);

    // Compile all the options we want to use.
    const options = useMemo(() => (
        {
            toolbar: {
                show: false,
            },

            plotOptions: {
                bar: {
                    columnWidth: "35px",
                }
            },

            title: {
                text: t('beforeAndAfterChartData.title', `${propsTitle}`),
                align: 'center',
            },

            chart: {
                stacked: false,
                events: {
                    dataPointSelection(event, chartContext, config) {
                        handleEventClick(data[0].data[config.dataPointIndex].text2 ?? '');
                    }
                },
            },

            

            colors: beforeAndAfterColours,

            ...seriesOptions,
        } as ApexOptions
    ), [propsTitle, seriesOptions, data, handleEventClick, t,]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = useMemo(() => JSON.stringify(options), [options]);

    return (
        <Card body tag="div" className={"chartBoarder"}>
            <Chart key={key} options={options} series={series} type="bar" width={width} height={height} />
        </Card>
    );
};