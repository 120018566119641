import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment, { isMoment } from 'moment';
import numeral from 'numeral';
import { isNumber } from 'util';
import { availableLanguages } from './languageConfig';

// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init
export function configurei18n() {
    i18n
        // load translation using xhr -> see /public/locales
        // learn more: https://github.com/i18next/i18next-xhr-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(initReactI18next)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
            fallbackLng: 'en-GB',
            //debug: true,

            interpolation: {
                escapeValue: false, // not needed for react as it escapes by default

                format: function (value, format, lang) {
                    // Handle dates with moment.
                    if (value instanceof Date) {
                        return moment(value).format(format);
                    } else if (isMoment(value)) {
                        return moment(value).format(format);
                    }

                    // Handle numbers with numeral.
                    if (value instanceof Number) {
                        return numeral(value).format(format);
                    } else if (typeof value === 'number') {
                        return numeral(value).format(format);
                    } else if (isNumber(value)) {
                        return numeral(value).format(format);
                    }

                    return value;
                }
            }
        });

    // Keep the moment and numeral formats in sync with the i18n formats.
    i18n.on('languageChanged', (lng) => {
        // As we are not supporting internationalisation in this app:
        // Force all en * language to always be en - GB as this is only for UK use, and Chrome in the UK download defaults to the en/en-US language.
        if ((lng?.indexOf('en') ?? 0) !== -1) {
            lng = 'en-GB';
        }

        const thisLanguage = availableLanguages.find(item => item.code === lng);

        moment.locale(thisLanguage?.momentLocale ?? lng);
        numeral.locale(thisLanguage?.numeralLocale ?? lng);
    });

    return i18n;
}
