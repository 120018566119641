import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const QuestionResponsePDFStyles = StyleSheet.create({
    responseRow: {
        flexDirection: "row",
        fontSize: "3mm",
        fontWeight: "bold",
        border: "0.364583333mm",
        borderColor: "#ddeeff",
        minHeight: "31.5pt",
        marginBottom: "2.25pt",

    },
    response: {
        flexDirection: "row",
        paddingLeft: "7.5pt",
        minHeight: "31.5pt",
    },
    preTextScale: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 750,
        minWidth: "40pt",
        textAlign: "justify",
    },
    preTextTextBox: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 550,
    },
    displayText: {
        padding: "1%",
        marginTop: "auto",
        marginBottom: "auto",
        fontWeight: 550,
    },
});