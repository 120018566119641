import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalHeader, ModalProps, Row } from "reactstrap";
import { AnalysisType } from "../../api/main/models/AnalysisType";
import { FormButtons } from "../shared/FormButtons";
import { ValidatedNumberInput } from "../shared/numberInput/ValidatedNumberInput";


export interface CreateAnalysisTypeModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    model: AnalysisType,
    change: (changes: Partial<AnalysisType>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    removeAnalysisType: () => void,
}

/**
* Modal to create a new analysis type on the fly when creating/editig a question
* @param props
*/

export const CreateAnalysisTypeModal = (props: CreateAnalysisTypeModalProps) => {
    const {
        isOpen,
        toggle,
        model,
        change,
        validate,
        validationErrors,
        removeAnalysisType
    } = props;

    const { t } = useTranslation();



    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="select-question-modal">
            <ModalHeader toggle={() => toggle()} >
                <>{t('createAnalysisTypeModal.headerText', 'Create a new analysis type')}</>
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label htmlFor="name">{t('createAnalysisypeModal.name', 'Analysis Type Name')}</Label>
                            <ValidatedInput name="name" type="text" value={model.name ?? ''}
                                onChange={e => change({ name: e.currentTarget.value })}
                                onBlur={e => validate('analysisTypeName')}
                                validationErrors={validationErrors['analysisTypeName']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Label htmlFor="baseAmount">{t('editAnalysisType.baseAmount', 'Default Base Amount')}</Label>
                        <ValidatedNumberInput name="baseAmountDefault" value={model?.baseAmountDefault as number}
                            onChange={e => { change({ baseAmountDefault: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0 }); }}
                            onBlur={e => validate('baseAmountDefault')}
                            validationErrors={validationErrors['amountInput']}
                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                            currencySymbol={'£'}
                        />
                    </Col>
                </Row>
                <FormButtons>
                    <Button type="button" color="primary" onClick={() => toggle()}>
                        <FontAwesomeIcon icon="save" className="nav-icon" />
                        <> </>
                        {t('common.save', 'Save')}
                    </Button>
                    <Button type="button" color="primary" outline onClick={() => { removeAnalysisType(); toggle(); }}>
                        {t('common.cancel', 'Close')}
                    </Button>
                </FormButtons>
            </ModalBody>
        </Modal>
    )
};