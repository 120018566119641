import { useMemo } from "react"
import { Col, Input, Label, Row } from "reactstrap"
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SubscriptionDepartment } from "../../../api/main/models/SubscriptionDepartment"
import { UserData } from "../../questionnaires/EditQuestionnaireBase"
import { Profile } from "../../../api/main/models/Profile"

export interface QuestionnaireUserAddDetailsProps {
    subscriptionDepartments: SubscriptionDepartment[], // All departments in the subscription for selecting in dropdown
    thisUserProfile?: Profile, // If user already exists in the database pass the profile in
    thisUserData: UserData, // Initialised userdata for a new user or partially filled if we are in the process of entering one
    change: (changes: Partial<UserData>) => void, // Change the user data in the usersManager array
}

// Assign a questionnaire to a user - handles a single user as part of an interface containing multiple users
export const QuestionnaireUserAddDetails = (props: QuestionnaireUserAddDetailsProps) => {

    const {
        subscriptionDepartments,
        thisUserProfile,
        thisUserData,
        change,
    } = props;

    const { t } = useTranslation();

    // Split out the session from the user data to make it easier to work with
    const thisSession = useMemo(() => {
        if (!thisUserData) {
            return;
        }

        return thisUserData.session;
    }, [thisUserData]);

    return (
        <div className="add-user-details">
            {/* No validation of fields. If we decide to add this we'll need to change the "key" field on user data to "id" to make the standard validation work */}
            <Row>
                <Col>
                    <Label htmlFor="email" className={"detail-label"}>{t('questionnaireUsersAdd.email', 'Email')}</Label>
                    <Input name="email" className={"detail-input-box"} type="text"
                        value={thisUserData?.email ?? ''}
                        onChange={e => change({ email: e.currentTarget.value, session: !!thisSession ? { ...thisSession, email: e.currentTarget.value } : undefined })}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label htmlFor="firstName" className={"detail-label"}>{t('questionnaireUsersAdd.firstName', 'First Name')}</Label>
                    <Input className={"detail-input-box"} name="firstName" type="text"
                        value={thisUserProfile?.firstName ?? thisUserData?.firstName ?? ''}
                        onChange={e => change({ firstName: e.currentTarget.value })}
                    />
                </Col>
                <Col>
                    <Label htmlFor="lastName" className={"detail-label"}>{t('questionnaireUsersAdd.lastName', 'Last Name')}</Label>
                    <Input className={"detail-input-box"} name="lastName" type="text"
                        value={thisUserProfile?.lastName ?? thisUserData?.lastName ?? ''}
                        onChange={e => change({ lastName: e.currentTarget.value })}
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label htmlFor="subscriptionDepartmentId" className={"detail-label"}>{t('questionnaireUsersAdd.department', 'Department')}</Label>
                    <> </>
                    <span data-toggle="tooltip" title={t('questionnaireUsersAdd.department.tooltip', "If you want to add a department to the user select one, If not leave blank.")}><FontAwesomeIcon icon="info-circle" /></span>
                    <Input name="subscriptionDepartmentId" className={"detail-input-box"} disabled={!!thisUserProfile?.subscriptionDepartmentId} type="select"
                        value={thisUserProfile?.subscriptionDepartmentId ?? thisUserData?.departmentId ?? ''}
                        onChange={e => change({ departmentId: e.currentTarget.value })}>
                        <option key={null} value="">{t('questionnaireUsersAdd.departmentPlaceholder', '')}</option>
                        {
                            subscriptionDepartments?.map(item => (
                                <option key={item.id} value={item.id}>{item.name}</option>
                            ))
                        }
                    </Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Label htmlFor="lineManagerName" className={"detail-label"}>{t('questionnaireUsersAdd.lineManageName', 'Line Manager Name')}</Label>
                    <Input name="lineManagerName" type="text" className={"detail-input-box"} 
                        value={thisUserData?.lineManagerName ?? ''}
                        onChange={e => change({ lineManagerName: e.currentTarget.value })}
                    />
                </Col>
                <Col>
                    <Label htmlFor="lineManagerEmail" className={"detail-label"}>{t('questionnaireUsersAdd.lineManagerEmail', 'Line Manager Email')}</Label>
                    <Input name="lineManagerEmail" type="text" className={"detail-input-box"} 
                        value={thisUserData?.lineManagerEmail ?? ''}
                        onChange={e => change({ lineManagerEmail: e.currentTarget.value })}
                    />
                </Col>
            </Row>

        </div>
    )
};