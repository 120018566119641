import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { SubscriptionUsersList } from "./SubscriptionUsersList";
import { SubscriptionDepartmentUsersList } from "./SubscriptionDepartmentUsersList";
import { SubscriptionDepartmentAdminUsersList } from "./SubscriptionDepartmentAdminUsersList";

export const subscriptionUsersRoutes: Array<RouteEntry> = [
    { path: '/manage/users/', exact: true, component: SubscriptionUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/users/:departmentId/users', exact: true, component: SubscriptionDepartmentUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manageDepartment/users/:departmentId/users', exact: true, component: SubscriptionDepartmentAdminUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
];
