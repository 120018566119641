import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { LinkContainer } from "react-router-bootstrap";
import { Button, Col, FormText, Input, Label, Row } from "reactstrap";
import { useSubscriptionReportViewModel } from "../../api/main/questionnaires/viewModels/useSubscriptionReportViewModel";
import { useSubscriptionDepartments } from "../../api/main/subscriptionDepartments/useSubscriptionDepartments";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { Banner } from "../shared/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { FormButtons } from "../shared/FormButtons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { SearchInput } from '../shared/searchInput/SearchInput';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import "./reportPackageBuilder.scss";
import { IndividualQuestionnaireReport } from "./IndividualQuestionnaireReport";
import { CompanyQuestionnaireReport } from "./CompanyQuestionnaireReport";
import { useToggleState } from "use-toggle-state";
import { BulkReportGenerator } from "./bulkReportGenerator/BulkReportGenerator";
import { AutoRenderPdfProps, BulkReportGenerationRequest } from "./bulkReportGenerator/BulkReportGenerationRequest";
import { ReportCustomiser } from "./reportCustomiser/ReportCustomiser";
import { GetCustomiserChildrenNames, useGetReportCustomiserNames } from "./reportCustomiser/utilities/reportCustomiserUtilities";
/**
 * A view to choose a specific questionnaire Section for a report or view a full report
 */

export const ReportPackageBuilder = () => {

    //get the questionnaireId from params
    const { questionnaireId, departmentId } = useParams<{ questionnaireId: string | undefined, departmentId: string | undefined }>();

    // Get the Questionnaire, Respondent Sessions, and profiles for any user with a respondent session (if a department Id is passed in only get users in that department)
    const { data: {
        model: questionnaire, questionnaireRespondentSessions, profilesForQuestionnaires,
    }, isLoading, errors: loadErrors } = useSubscriptionReportViewModel(questionnaireId, { excludeIncomplete: true, subscriptionDepartmentId: departmentId });

    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // get the departments for this subscription
    const { data: { items: departments }, isLoading: isDepartmentsLoading, errors: loadDepartmentsErrors, } = useSubscriptionDepartments({ subscriptionId: currentUserSubscriptionId ?? null });


    const { t } = useTranslation();
    const history = useHistory();
    const { search: searchParam } = useSearchParams();
    const [userSearch, setUserSearch] = useState<string>(searchParam ?? '');
    const [departmentSearch, setDepartmentSearch] = useState<string>(searchParam ?? '');


    // get the sections
    const sections = questionnaire?.questionnaireSections ?? [];

    const [sectionId, setSectionId] = useState<string>();

    // handle Section change - Section can be changed or cleared completely
    const handleSectionChange = useCallback((inputValue: string) => {
        if (!inputValue) {
            setSectionId(undefined);
        }

        setSectionId(inputValue);

    }, [setSectionId]);


    //** settings for customizer component
    //**


    // array to hold the reportcustomisers
    const [reportCustomisers, setReportCustomisers] = useState<{ Name: string, Value: boolean }[]>([]);

    // get all the customiser names in display order
    const customiserNames = useGetReportCustomiserNames();

    // initialise the reportcustomiser names
    useEffect(() => {
        if (reportCustomisers.length > 0) {
            return;
        }

        let newCustomisers: { Name: string, Value: boolean }[] = [];

        customiserNames.forEach((customiserName) => {
            newCustomisers.push({ Name: customiserName, Value: true });
        });

        setReportCustomisers(newCustomisers);

    }, [reportCustomisers, customiserNames]);

    // return a string of 1s and 0s to represent the settings of the report customiser switches
    // switches must be in the correct order so they can be unbundled when consumed
    const reportCustomiserString = useMemo(() => {
        let ret = '';
        customiserNames.forEach((customiserName) => {
            ret += reportCustomisers.find(item => item.Name === customiserName)?.Value === true ? 1 : 0;
        })

        return ret;
    }, [reportCustomisers, customiserNames]);

    // update one of the reportCustomiser array values - also updates all child customiser values to match
    const setCustomiser = useCallback((customiser: string, setting: boolean) => {
        let newCustomisers = [...reportCustomisers];

        // set up an array with the requested customiser and all its children
        let customisersToBeSet: string[] = GetCustomiserChildrenNames(customiser);
        customisersToBeSet.push(customiser);

        customisersToBeSet.forEach((customiserToBeSet) => {
            // check the name exists
            let newCustomiser = newCustomisers.find(item => item.Name === customiserToBeSet);
            if (!newCustomiser) {
                // do nothing - the name doesn't exist in the array
                return;
            }
            //set the value
            newCustomiser.Value = setting;
        });

        // update the customisers array
        setReportCustomisers(newCustomisers);

    }, [setReportCustomisers, reportCustomisers,]);

    // return a reportcustomiser value and default to true if not found
    const isCustomiserSet = useCallback((customiser: string) => {
        let thisCustomiser = reportCustomisers.find(item => item.Name === customiser);

        return !!thisCustomiser ? thisCustomiser.Value : true;

    }, [reportCustomisers]);

    //**
    //** end of settings for customizer component

    const departmentsList = useMemo(() => {
        let ret = (departments ?? []);

        if (!departments || !departmentSearch) {
            return ret;
        }

        let lowerSearch = departmentSearch.toLocaleLowerCase();

        // Filter the departments being displayed.
        return ret.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0

        );
    }, [departmentSearch, departments]);

    // Get the ordered departments for this subscription.
    const orderedDepartments = useMemo(() => {
        let departments = [...departmentsList ?? []];

        // Sort into name order.
        if (departments.length) {
            return departments
                .sort((a, b) => {
                    if (a.name === b.name) {
                        if (a.name === b.name) {
                            return 0;
                        } else if (a.name > b.name) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else {
                        return -1;
                    }
                })
        }
        return departments;
    }, [departmentsList,]);

    useReplaceSearchParamsEffect({ search: departmentSearch });

    const [departmentIds, setDepartmentIds] = useState<string[]>([]);

    //If department is checked add thier departmentId to the departmentId array
    const handleDepartmentChecked = useCallback((departmentId: string, checked: boolean) => {
        let newDepartmentIds = [...departmentIds].filter(item => item.indexOf(departmentId));

        if (!!checked) {
            //if we do have a department and its not already in the array add it.

            newDepartmentIds.push(departmentId);
        }
        //set the set the departmentId array to be the new array with each new ID added.
        setDepartmentIds(newDepartmentIds);

    }, [departmentIds]);

    const [selectAllDepartments, setSelectAllDepartments] = useState<boolean>(false);

    //Add every departmentId from orderedDepartments to departmentIds,
    const allDepartmentsSelected = useCallback((checked: boolean) => {
        setSelectAllDepartments(checked);

        let newDepartmentIds = [...departmentIds];

        if (!!checked) {
            //if selectAllDepartments is true clear the array and add the department Id for ever department in order department to the array
            newDepartmentIds = [];

            for (let department of orderedDepartments) {
                newDepartmentIds.push(department.id);
            }
        } else if (!checked) {
            //if selectAllDepartments is false remove all department Ids from the array.
            newDepartmentIds = [];
        }

        setDepartmentIds(newDepartmentIds);
    }, [departmentIds, setSelectAllDepartments, orderedDepartments,]);

    // Filter by the user's search client side so it can work when offline as well as online.
    const participants = useMemo(() => {
        let ret = (profilesForQuestionnaires ?? []);

        if ((!profilesForQuestionnaires || !userSearch) && !departmentIds.length) {
            return ret;
        }

        //If we have a departmentId passed in Only show users from that department
        if (!!departmentId) {
            return ret.filter(item => item.subscriptionDepartmentId === departmentId);
        }

        let lowerSearch = userSearch.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.userId.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (!!item.subscriptionDepartmentId && (departments.find(it => it.id === item.subscriptionDepartmentId)?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? '') >= 0)
        );
    }, [profilesForQuestionnaires, userSearch, departments, departmentIds, departmentId]);

    // Get the ordered user profiles for any user with a completed respondent session for this questionnaire.
    const orderedUsers = useMemo(() => {
        let users = [...participants ?? []];

        // filter out users if they don't have a completed session.
        users = users.filter(user => {
            const responses = questionnaireRespondentSessions.filter(it => it.userId === user.userId);
            if (responses.length === 0) {
                return false;
            }
            return true;
        });

        // Sort into first name order.
        if (users.length) {
            return users
                .sort((a, b) => {
                    if (a.firstName === b.firstName) {
                        if (a.lastName === b.lastName) {
                            return 0;
                        } else if (a.lastName > b.lastName) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (a.firstName > b.firstName) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }
        return users;
    }, [participants, questionnaireRespondentSessions]);

    useReplaceSearchParamsEffect({ search: userSearch });

    const [userProfileIds, setUserProfileIds] = useState<string[]>([]);

    //If user is checked add thier userId to the UserProfileIds array
    const handleUserChecked = useCallback((userId: string, checked: boolean) => {
        let newUserProfileIds = [...userProfileIds].filter(item => item.indexOf(userId));

        if (!!checked) {
            //if we do have a userID and its not already in the array add it.

            newUserProfileIds.push(userId);
        }
        //set the set the userProfileIds array to be the new array with each new ID added.
        setUserProfileIds(newUserProfileIds);

    }, [userProfileIds]);

    const [selectAllParticipants, setSelectAllParticipants] = useState<boolean>(false);

    //Add every userId from OrderUsers to userProfileIds,
    const allParticipantsSelected = useCallback((checked: boolean) => {
        setSelectAllParticipants(checked);

        let newUserProfileIds = [...userProfileIds];

        if (!!checked) {
            //if selectAllPaticipants is true clear the array and add the user Id for ever user in order user to the array
            newUserProfileIds = [];

            for (let user of orderedUsers) {
                newUserProfileIds.push(user.userId);
            }
        } else if (!checked) {
            //if selectAllParticpants is false remove all user Ids from the array.
            newUserProfileIds = [];
        }

        setUserProfileIds(newUserProfileIds);
    }, [userProfileIds, setSelectAllParticipants, orderedUsers,]);

    const neededDepartment = useCallback((departmentId: string | null) => {
        let neededDepartments = departments ?? [];

        if (!departmentId) {
            return
        }
        return neededDepartments.find(item => item.id === departmentId);
    }, [departments]);

    //add full report to Download package 
    const [addFullReport, setAddFullReport] = useState<boolean>(false);

    //Add All Departments, Users, and Company report to Download Package
    const [addeverythingToPackage, setAddEverythingToPackage] = useState<boolean>(false);

    //Set check boxes for Departments, Users, and Company report
    const setFullPackageDownload = useCallback((checked: boolean) => {
        setAddEverythingToPackage(checked);
        setAddFullReport(checked);
        allParticipantsSelected(checked);
        allDepartmentsSelected(!departmentId ? checked : false);
    }, [setAddEverythingToPackage, setAddFullReport, allParticipantsSelected, allDepartmentsSelected, departmentId]);

    // Manage the bulk generation and download of reports via the modal.
    const [isGeneratingReports, toggleGeneratingReports] = useToggleState();
    const generateReportRequests = useMemo(() => {
        // If we're not trying to bulk generate reports, don't take the time to work out which reports to generate.
        if (!isGeneratingReports) {
            return [];
        }

        // Otherwise we are going to queue up all the required reports.
        let ret: Array<BulkReportGenerationRequest> = [];

        // If we want the full report adding, queue that first.
        if (addFullReport) {
            ret.push({
                id: 'full-report',
                renderComponent: (props: AutoRenderPdfProps) => (<CompanyQuestionnaireReport propsSectionId={sectionId} propsQuestionnaireId={questionnaireId} propsDepartmentId={undefined} propsReportCustomiser={reportCustomiserString} {...props} />),
            });
        }

        // Add each selected department to the generation list.
        for (const departmentId of departmentIds) {
            ret.push({
                id: departmentId,
                renderComponent: (props: AutoRenderPdfProps) => (<CompanyQuestionnaireReport propsSectionId={sectionId} propsQuestionnaireId={questionnaireId} propsDepartmentId={departmentId} propsReportCustomiser={reportCustomiserString} {...props} />),
            });
        }

        // Add each selected user profile to the generation list.
        for (const userId of userProfileIds) {
            ret.push({
                id: userId,
                renderComponent: (props: AutoRenderPdfProps) => (<IndividualQuestionnaireReport propsSectionId={sectionId} propsQuestionnaireId={questionnaireId} propsUserId={userId} propsReportCustomiser={reportCustomiserString} {...props} />),
            });
        }

        return ret;
    }, [isGeneratingReports, departmentIds, userProfileIds, sectionId, questionnaireId, addFullReport, reportCustomiserString,]);

    //Chose hwo to start a link depending on ho is viewing the report builder.
    const linkPrefix = useMemo(() => {
        let link = '';

        if (!!departmentId) {
            link = '/departmentQuestionnaires'
        } else {
            link = '/subscriptionQuestionnaires'
        }

        return link;
    }, [departmentId]);

    //Update the View Full Reprt Link Depending on who is viewing and if a section has been selected.
    const fullReportViewLink = useMemo(() => {
        let reportLink = `${linkPrefix}/view/report/${questionnaireId}/`;

        if (!!departmentId) {
            reportLink += `department/${departmentId}/`;
        }

        if (!!sectionId) {
            reportLink += `section/${sectionId}/`;
        }

        reportLink += `${reportCustomiserString}`;

        return reportLink;
    }, [departmentId, sectionId, questionnaireId, linkPrefix, reportCustomiserString,]);


    //Update the department report view link depending on if a section has been selected.
    const departmentReportLink = useCallback((departmentId: string) => {
        let reportLink = `${linkPrefix}/view/report/${questionnaireId}/department/${departmentId}/`;

        if (!!sectionId) {
            reportLink += `section/${sectionId}/`;
        }

        reportLink += `${reportCustomiserString}`;

        return reportLink;
    }, [sectionId, linkPrefix, questionnaireId, reportCustomiserString]);

    //Update the department report view link depending on if a section has been selected.
    const individualReportLink = useCallback((userId: string) => {
        let reportLink = `${linkPrefix}/view/report/${questionnaireId}/user/${userId}/`;

        if (!!sectionId) {
            reportLink += `section/${sectionId}/`;
        }

        reportLink += `${reportCustomiserString}`;

        return reportLink;
    }, [sectionId, linkPrefix, questionnaireId, reportCustomiserString]);

    return (
        <div className="report-builder">
            <Background>
                <Banner fluid>
                    {/*<Row>*/}
                    {/*    <Col>*/}
                    {/*        <PillsNavBar>*/}
                    {/*            <ManageQuestionnaireNavigation*/}
                    {/*                id={questionnaireId ?? ''}*/}
                    {/*            />*/}
                    {/*        </PillsNavBar>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row className={"bannerNoPills"}>
                        <Col>
                            <h1>
                                {t('reportSelector.title', `${questionnaire?.name} -  Report Builder`)}
                            </h1>
                            <ConditionalFragment showIf={isLoading && isDepartmentsLoading}>
                                <Col xs="auto">
                                    <LoadingIndicator size="sm" />
                                </Col>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                    <Row className={"report-builder-button"}>
                        <Col className="text-right">
                            <ConditionalFragment showIf={!!userProfileIds.length || !!departmentIds.length || !!addFullReport}>
                                <Button color="primary" outline onClick={() => toggleGeneratingReports()}>
                                    <FontAwesomeIcon icon="download" />
                                    <> </>
                                    {t('overallReport.companyReport', `Download Report Package`)}
                                </Button>
                            </ConditionalFragment>
                        </Col>
                    </Row>
                </Banner>
                <MainContainer>
                    <AlertOnErrors errors={[loadErrors && loadDepartmentsErrors]} />
                    {/*Only Show department list if user is a subscription admin*/}
                    <Row>
                        <Col>
                            <Button color="primary" outline
                                onClick={(e) => history.goBack() }                            >
                                <FontAwesomeIcon icon="caret-left" /><> {t('questionnaireUsersListBase.backToCampaign', 'Back To List')}</>
                            </Button>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} lg={8}>
                            <Row>
                                <Col xs={12} sm="">
                                    <h3>{t("reportPackageBuilder.selector.header", "Select or Build Your Report")}</h3>
                                </Col>
                                <Col xs={12} sm="auto">
                                    <Row>
                                        <FormText className={"add-everything-label"}>{t('reportPackageBuilder.addEverythingLable', 'Add Everything to Download Package')}</FormText>
                                        <Input name="addCompanyreportToPackage" className={"add-everything-to-package"} type="checkbox" checked={!!addeverythingToPackage} onChange={e => !!addeverythingToPackage ? setFullPackageDownload(false) : setFullPackageDownload(true)} />
                                    </Row>
                                </Col>
                            </Row>
                            <Row className={"select-company-report-row"}>
                                <Col>
                                    <Label className={"company-label"}>{t('reportPackageBuilder.companyReport', 'Company Report')}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={6} sm={9}>
                                    <Row>
                                        <FormText className={"company-report-select-label"}>{t('reportPackageBuilder.selectCompanyReport', 'Add To Package')}</FormText>
                                        <Input name="addCompanyreportToPackage" className={"select-company-report"} type="checkbox" checked={!!addFullReport} onChange={e => !!addFullReport ? setAddFullReport(false) : setAddFullReport(true)} />
                                    </Row>
                                </Col>
                                <Col xs={6} sm={3} className="text-right">
                                    <LinkContainer to={fullReportViewLink}>
                                        <Button color="primary" outline>
                                            {t('overallReport.companyReport', `View`)}
                                        </Button>
                                    </LinkContainer>
                                </Col>
                            </Row>
                            {/*Only Show department list if user is a subscription admin*/}
                            <ConditionalFragment showIf={!departmentId}>
                                <Row className={"report-selector-list"}>
                                    <Col xs={12} sm="">
                                        <Label className={"department-selector-label"}>{t('reportPackageBuilderDepartmentList.departmentListLabel', 'Select Departments')}</Label>
                                    </Col>
                                    <Col xs={12} sm="">
                                        <SearchInput value={departmentSearch} onChange={e => setDepartmentSearch(e.currentTarget.value)} />
                                        <Row>
                                            <FormText className={"select-all-label"}>{t('reportPackageBuilderDepartmentList.selectAllLabel', 'Select All Departments')}</FormText>
                                            <Input name="selectAllDepartments" className={"select-all-check"} type="checkbox" checked={!!selectAllDepartments} onChange={e => !!selectAllDepartments ? allDepartmentsSelected(false) : allDepartmentsSelected(true)} />
                                        </Row>
                                    </Col>
                                </Row>

                                <CardsOrTable
                                    viewMode={'table'}
                                    items={orderedDepartments}
                                    tableHeadings={[
                                        t('reportPackageBuilderDepartmentList.includeInReport', 'Add To Package'),
                                        t('reportPackageBuilderDepartmentList.department', 'Department'),
                                        t('reportPackageBuilderDepartmentList.view', '')
                                    ]}
                                    columns={[
                                        (item) => (<Input name="department" className={"single-select"} type="checkbox" value={item.id} checked={departmentIds.includes(item.id)} onChange={e => handleDepartmentChecked(e.currentTarget.value, e.currentTarget.checked)} />),
                                        (item) => item.name,
                                        (item) => (<LinkContainer to={departmentReportLink(item.id)}>
                                            <Button color="primary" outline>
                                                {t('overallReport.companyReport', `View`)}
                                            </Button>
                                        </LinkContainer>)
                                    ]}
                                />
                            </ConditionalFragment>


                            <Row className={"report-selector-list"}>
                                <Col xs={12} sm="">
                                    <Label className={"participant-selector-label"}>{t('reportPackageBuilderUserList.userListLabel', 'Select Participants')}</Label>
                                </Col>
                                <Col xs={12} sm="">
                                    <SearchInput value={userSearch} onChange={e => setUserSearch(e.currentTarget.value)} />
                                    <Row>
                                        <FormText className={"select-all-label"}>{t('reportPackageBuilderUserList.selectAllLabel', 'Select All Participants')}</FormText>
                                        <Input name="selectAllParticipants" className={"select-all-check"} type="checkbox" checked={!!selectAllParticipants} onChange={e => !!selectAllParticipants ? allParticipantsSelected(false) : allParticipantsSelected(true)} />
                                    </Row>
                                </Col>
                            </Row>

                            <CardsOrTable
                                viewMode={'table'}
                                items={orderedUsers}
                                tableHeadings={[
                                    t('reportPackageBuilderUserList.includeInReport', 'Add To Package'),
                                    t('reportPackageBuilderUserList.fullName', 'Full Name'),
                                    t('reportPackageBuilderUserList.email', 'Email'),
                                    t('reportPackageBuilderUserList.department', 'Department'),
                                    t('reportPackageBuilderUserList.viewReport', '')

                                ]}
                                columns={[
                                    (item) => (<Input name="user" type="checkbox" className={"single-select"} value={item.userId} checked={userProfileIds.includes(item.userId)} onChange={e => handleUserChecked(e.currentTarget.value, e.currentTarget.checked)} />),
                                    (item) => item.firstName + ' ' + item.lastName,
                                    (item) => item.user.email,
                                    (item) => !!item.subscriptionDepartmentId ? neededDepartment(item.subscriptionDepartmentId)?.name : '-',
                                    (item) => (<LinkContainer to={individualReportLink(item.userId)}>
                                        <Button color="primary" outline>
                                            {t('overallReport.companyReport', `View`)}
                                        </Button>
                                    </LinkContainer>)
                                ]}
                            />
                        </Col>
                        <Col xs={12} lg={4}>
                            <ReportCustomiser
                                sections={sections}
                                handleSectionChange={handleSectionChange}
                                setCustomiser={setCustomiser}
                                isCustomiserSet={isCustomiserSet}
                                customiserNames={customiserNames}
                            />
                        </Col>
                    </Row>

                    <FormButtons>
                        <Button color="primary" onClick={e => history.goBack()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </FormButtons>
                </MainContainer>
            </Background>
            <ConditionalFragment showIf={isGeneratingReports}>
                <BulkReportGenerator
                    isOpen={isGeneratingReports}
                    toggle={() => toggleGeneratingReports()}
                    requests={generateReportRequests}
                />
            </ConditionalFragment>
        </div>
    );
};