import { useMemo, useState } from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useProfiles } from '../../api/main/profiles/useProfiles';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { RoleGroup } from '../../api/main/models/codeOnly/RoleGroup';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { DeleteAnyModal } from '../shared/DeleteAnyModal';
import { useDeleteProfileCallback } from '../../api/main/profiles/useDeleteProfileCallback';
import { useLockoutCallback } from '../../api/account';
import { useProfile } from '../../api/main/profiles/useProfile';


/**
 * List of administrator users.
 */
export const AdministratorsUsersList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useProfiles({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {

        if (!allItems) {
            return [];
        }

         // Only want to show administrators.
        let ret = allItems.filter(item => item.user?.roleGroup?.id === RoleGroup.Administrator);

        if (!search) {
            return ret;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
            || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
            || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
        );
    }, [allItems, search]);

    const displayItems = useMemo(() => {
        //Sort items by First Name, then Last Name, Then email
        if (!items) {
            return [];
        }

        let ret = [...items];

        ret.sort((a, b) => (
            a.firstName.localeCompare(b.firstName) ||
            a.lastName.localeCompare(b.lastName) ||
            a.user.email.localeCompare(b.user.email)
        ));

        return ret
    }, [items]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();

    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteProfileCallback();
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();
    const [deletedItemId, setDeletedItemId] = useState<string>("");
    const { data: { model: deletedProfile }, isLoading: loadDeleteProfile, errors: loadErrors } = useProfile(deletedItemId);
    const deletedUserEmail = deletedProfile?.user.email ?? '';
    

    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerNoPills"}>
                    <Col>
                        <h1>{t('administratorsUserList.heading', 'Skillshub staff')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading || loadDeleteProfile}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row className={"searchBar"}>
                        <Col xs="">
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to="/administration/users/administrators/add">
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('administratorsUserList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col className="text-right" xs={12} sm="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, removeErrors, lockoutErrors, loadErrors ]} />
                <Row className="mb-2">
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>

                <CardsOrTable
                    viewMode={viewMode}
                    items={displayItems}
                    onItemClick={item => history.push(`/administration/users/administrators/edit/${item.id}`)}
                    tableHeadings={[
                        t('administratorsUserList.firstName', 'First name'),
                        t('administratorsUserList.lastName', 'Last name'),
                        t('administratorsUserList.email', 'Email'),
                        t('administratorsUserList.securityGroup', 'Security group'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.firstName} {item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'table' ? item.firstName : null,
                        (item, view) => view === 'table' ? item.lastName : null,
                        (item, view) => view === 'cards' ? (<CardSubtitle tag="h6">{item.user.email}</CardSubtitle>) : item.user.email,
                        (item) => item.user?.roleGroup?.name,
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`/administration/users/administrators/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); }}>
                                        <FontAwesomeIcon icon="trash" />
                                        <> {t('common.delete', 'Delete')}</>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={`${deletedProfile?.firstName} ${deletedProfile?.lastName}`}
                        remove={() => { remove(deletedItemId); lockout({ email: deletedUserEmail, archive: true }) }}
                    />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};