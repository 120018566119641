import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { useAsyncCallback } from 'react-use-async-callback';
import { Button, Col, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row, } from 'reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { ToggleStateAction } from 'use-toggle-state';
import { useUploadBlobFromBlobObjectCallback } from '../../api/main/blobReferences/useUploadBlobFromBlobObjectCallback';
import { Question } from '../../api/main/models/Question';
import { QuestionResponse } from '../../api/main/models/QuestionResponse';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { generatePdfAsBlob } from '../../utilities/generatePdfAsBlob';
import { imageUrlToDataUrl } from '../../utilities/imageUrlToDataUrl';
import { pngDataUrlFromSvg } from '../../utilities/pngFromSvg';
import { AnalyserInterrogationResult } from '../dashboard/analyser/AnalyserDataInterrogation';
import { FormButtons } from '../shared/FormButtons';
import { AnalyserReportIndividual } from './AnalyserReportIndividual';
import './analyserReportPrintModal.scss';
import {  AutoRenderPdfProps, BulkReportGenerationRequest } from './bulkReportGenerator/BulkReportGenerationRequest';
import { AnalyserReportPdf } from './pdfReports/AnalyserReportPDF';

/**
 * Modal to enter the name of the report and interface between the Analyser and the PDF report
 */

export interface AnalyserReportPrintModalProps {
    isOpen: boolean,
    toggle: () => void,
    currencySymbol: string,
    caller: string,
    questionnaireName?: string,
    analysisResults: AnalyserInterrogationResult | undefined,
    providerNames: string[] | undefined,
    courseNames: string[] | undefined,
    courseTypes: string[] | undefined,
    sectionNames: string[] | undefined,
    categoryNames: string[] | undefined,
    departmentNames: string[] | undefined,
    learners: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }> | undefined,
    actionQuestions: Question[] | undefined,
    actionResponses: QuestionResponse[] | undefined,
    transformationQuestions: Question[] | undefined,
    transformationResponses: QuestionResponse[] | undefined,
    isAllLearners: boolean,
    includeGraph: boolean,
    setBulkReportRequests?: React.Dispatch<React.SetStateAction<BulkReportGenerationRequest[]>>,
    toggleGeneratingReports?: ToggleStateAction,
    autoGeneratedZipFileName?: string,
}

export const AnalyserReportPrintModal = (props: AnalyserReportPrintModalProps) => {
    const {
        isOpen,
        toggle,
        caller,
        currencySymbol,
        questionnaireName,
        analysisResults,
        providerNames,
        courseNames,
        courseTypes,
        sectionNames,
        categoryNames,
        departmentNames,
        learners,
        actionQuestions,
        actionResponses,
        transformationQuestions,
        transformationResponses,
        isAllLearners,
        includeGraph,
        setBulkReportRequests,
        toggleGeneratingReports,
        autoGeneratedZipFileName,
    } = props;
    const { t } = useTranslation();

    // If a single learner is selected, the report is an individual report
    const isIndividualReport = learners?.length === 1;

    const isCampaignReport = caller === 'Overview';

    const { data: { model: currentProfile } } = useCurrentUserProfile();

    const [reportTitle, setReportTitle] = useState<string>(`${caller} Report - ${moment(new Date()).format("Do MMMM YYYY")}`);

    // Prepare the chart Images Into the Format Needed For the PDF Print Out.
    // We think there will be only 1 but this will allow for many
    const prepareChartImages = useCallback(async () => {
        const anyChartImages = document.getElementsByClassName('graph-results').length > 0;

        if (!anyChartImages || !includeGraph) {
            return [];
        }

        const element = document.getElementsByClassName('graph-results')[0];
        // Get an SVG that has the className 'apexcharts-svg' (only the charts will have this className)
        let chartSvgs = element.getElementsByClassName('apexcharts-svg');

        let ret: Array<string> = [];
        // For each chart conver the Svgs into pngDataUrls and push them to an array.
        for (let i = 0; i < chartSvgs.length; i++) {
            let svg = chartSvgs[i];
            let pngDataUrl = await pngDataUrlFromSvg(svg);
            ret.push(pngDataUrl);
        }

        return ret;
    }, [includeGraph]);

    // Get all the analysis type imageurls ready for the pdf
    const allIconUrls = useCallback(async (): Promise<Array<{ analysisTypeName: string, iconUrl: string }>> => {
        const iconUrls: Array<{ analysisTypeName: string, iconUrl: string }> = [];

        let urlToConvert = '/img/Campaign Icons/Accidents at work (blue).png';
        let iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Accidents At Work', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Accountability (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Accountability', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Actions (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Actions', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Coaching & Feedback (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Coaching & Feedback', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Communication (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Communication', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Confidence Levels (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Confidence', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Continuous Learning (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Continuous Learning', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Cost Saving (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Cost Savings', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Customer Experience (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Customer Experience', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Difficult_Honest Conversations (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Difficult/Honest Conversations', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Effective Communication (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Effective Communication', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Efficiency (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Efficiency', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Engagement (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Engagement', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Health & Safety (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Health & Safety', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Improved Rebate Management (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Improved Rebate Management', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Increase in Performance (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Increase in Performance', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Intentions (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Intentions', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Learning Points (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Learning Points', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Managing Change (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Managing Change', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Meeting Targets (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Meeting Targets', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Mental Health (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Mental Health', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Personal Activity (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Personal Activity', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Post-Programme Actions (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Post-Programme Actions', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Pre-activity Goals (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Pre-activity Goals', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Problem Solving & Decision Making (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Problem Solving & Decision Making', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Process Improvement  (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Process Improvement', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Profit (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Profit', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Resilience & Stress (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Resilience & Stress', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Results (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Results', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Revenue (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Revenue', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Staff Retention (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Staff Retention', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Time Saver (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Time Saver', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Wellbeing (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Wellbeing', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/General Blue.png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Default', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Up Arrow (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Up Arrow', iconUrl: iconUrl });

        urlToConvert = '/img/Campaign Icons/Down Arrow (blue).png';
        iconUrl = await imageUrlToDataUrl(urlToConvert);
        iconUrls.push({ analysisTypeName: 'Down Arrow', iconUrl: iconUrl });

        return iconUrls;

    }, []);

    // Generate a PDF file as a blob.
    const generateMainReportPdf = useCallback(async (): Promise<Blob> => {

        // get the chart images 
        const chartImages = await prepareChartImages();

        // Convert the images we need to data URLs so they can be embedded in the PDF.
        const pdfSplashPageImageUrl = await imageUrlToDataUrl('/img/ReportSplash24.png');
        const impactsLogoUrl = await imageUrlToDataUrl('/img/IMPACTS_LOGO_NEW2022.jpeg');

        const iconUrls = await allIconUrls();

        // Generate the PDF.
            let blob = await generatePdfAsBlob(AnalyserReportPdf({
                companyColor: "#1188FF",
                impactsLogoUrl: impactsLogoUrl,
                reportTitle: reportTitle,
                currencySymbol: currencySymbol,
                questionnaireName: questionnaireName,
                reporterName: !!currentProfile ? `${currentProfile.firstName} ${currentProfile.lastName}` : '',
                splashPageImage: pdfSplashPageImageUrl,
                analysisResults: analysisResults,
                providerNames: providerNames,
                courseNames: courseNames,
                courseTypes: courseTypes,
                sectionNames: sectionNames,
                categoryNames: categoryNames,
                departmentNames: departmentNames,
                actionsLearners: learners,
                actionQuestions: actionQuestions,
                actionResponses: actionResponses,
                transformationQuestions: transformationQuestions,
                transformationResponses: transformationResponses,
                analysisTypeIconUrls: iconUrls,
                chartImages: chartImages,
                isAllLearners: isAllLearners,
                isCampaignReport: isCampaignReport,
            }));
            return blob;

    }, [reportTitle, currentProfile, analysisResults, providerNames, courseNames, courseTypes, sectionNames, categoryNames,
        departmentNames, learners, actionQuestions, actionResponses, prepareChartImages,
        transformationQuestions, transformationResponses, allIconUrls, isAllLearners, isCampaignReport, questionnaireName, currencySymbol]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generateMainReportPdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
        toggle();
    }, [generateMainReportPdf]);

    const [upload] = useUploadBlobFromBlobObjectCallback();

    const generatedFullReportFileName = useMemo(() => {
        let ret = 'Full';

        // Request is coming from Campaign Overview as bulk report is not available from the analyser
        ret += '-' + questionnaireName?.substring(0, 25) ?? 'Campaign';

        ret += `-Report-${moment().format("YYYY-MM-DD")}.pdf`;

        ret = `${autoGeneratedZipFileName}:${ret}`;

        return ret;
    }, [questionnaireName, autoGeneratedZipFileName]);

    // Generate full PDF and save to blob ready for Zip file Download
    const [generateAndSaveFullPdfForZip] = useAsyncCallback(async () => {
        let blob = await generateMainReportPdf();

        //Upload the blob to our blobs table
        const blobReference = await upload(generatedFullReportFileName, blob);

        //Send Blob refernce Id back to report builder for Zip download.
        return blobReference?.id ?? '';
    }, [generateMainReportPdf, generatedFullReportFileName, upload]);

    const downloadOnClick = useCallback(async () => {
        if (!setBulkReportRequests || !toggleGeneratingReports) {
            return;
        }

        // Convert the images we need to data URLs so they can be embedded in the PDF.
        const pdfSplashPageImageUrl = await imageUrlToDataUrl('/img/ReportSplash24.png');
        const impactsLogoUrl = await imageUrlToDataUrl('/img/IMPACTS_LOGO_NEW2022.jpeg');
        const iconUrls = await allIconUrls();

        // Build a bulk schedule for the zip file
        // This will be the main report and one for every user requested user
        const requests: Array<BulkReportGenerationRequest> = [];

        // Generate the main report
        requests.push({
            id: 'full-report',
            renderToBlobReference: generateAndSaveFullPdfForZip,
        });

        // Generate each individual report
        if (!!learners && !!learners.length) {
            learners.forEach(learner => {
                requests.push({
                    id: learner.id,
                    renderComponent: (props: AutoRenderPdfProps) => (
                        <AnalyserReportIndividual
                            companyColor="#1188FF"
                            impactsLogoUrl={impactsLogoUrl}
                            splashPageImage={pdfSplashPageImageUrl}
                            reportTitle={reportTitle}
                            questionnaireName={questionnaireName}
                            reporterName={!!currentProfile ? `${currentProfile.firstName} ${currentProfile.lastName}` : ''}
                            analysisResults={analysisResults}
                            learner={learner}
                            analysisTypeIconUrls={iconUrls}
                            isCampaignReport={isCampaignReport}
                            currencySymbol={currencySymbol}
                            autoGeneratePdfZipFileName={autoGeneratedZipFileName}
                            {...props} />),

                });
            });
        }

        setBulkReportRequests(requests);

        // Close this modal
        toggle();

        // Call the bulk report modal with the list of files to be generated
        toggleGeneratingReports();

    }, [toggle, toggleGeneratingReports, setBulkReportRequests, learners, generateAndSaveFullPdfForZip,
        allIconUrls, analysisResults, currencySymbol, currentProfile, isCampaignReport,
        questionnaireName, reportTitle, autoGeneratedZipFileName]);

    return (
        <>
            <Modal isOpen={isOpen} className='report-name-modal'>
                <ModalHeader toggle={() => toggle()}>
                    {t('analyserReportNameModal.header', ' Please choose a Title for your report')}
                </ModalHeader>
                <ModalBody>
                    <Row className='input-group'>
                        <Col>
                            <FormGroup>
                                <Input type='text' name='report-name' id='report-name' placeholder={t('analyserReportNameModal.placeholder', 'Enter report title')}
                                    onChange={e => setReportTitle(e.currentTarget.value)} />
                            </FormGroup>
                        </Col>
                    </Row>


                    <FormButtons className='text-center'>
                        <Row>
                            <Col>
                                <Label>{t('analyserReportNameModal.label1', `Select to view your ${!isIndividualReport ? 'Analyser' : 'Individual'} PDF report`)}</Label>
                            </Col>
                            <ConditionalFragment showIf={isCampaignReport}>
                                <Col>
                                    <Label>{t('analyserReportNameModal.label2', 'Select to download your report package')}</Label>
                                </Col>
                            </ConditionalFragment>
                        </Row>
                        <Row>
                            <Col>
                                <ButtonAsync className='analyser-image-button' onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen} isExecuting={isGeneratingPdfForOpen}
                                    executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('analyserReportNameModal.print', 'Generating PDF...')}</>}>
                                    <> </>
                                </ButtonAsync>
                                <ButtonAsync className='analyser-image-button' onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen} isExecuting={isGeneratingPdfForOpen}>
                                    <ConditionalFragment showIf={!isGeneratingPdfForOpen}>
                                        <img className='analyser-pdfImage' alt='' />
                                        <> </>
                                    </ConditionalFragment>
                                </ButtonAsync>
                                <ButtonAsync className='analyser-image-button' onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen} isExecuting={isGeneratingPdfForOpen}>
                                    <ConditionalFragment showIf={!isGeneratingPdfForOpen}>
                                        <img className='analyser-printImage' alt='' />
                                        <> </>
                                    </ConditionalFragment>
                                </ButtonAsync>
                            </Col>
                            <ConditionalFragment showIf={isCampaignReport}>
                                <Col>
                                    <Button className='analyser-image-button' onClick={() => downloadOnClick()}>
                                        <FontAwesomeIcon icon="download" className="download-icon" />
                                    </Button>
                                </Col>
                            </ConditionalFragment>
                        </Row>
                        <Row>
                            <Col>
                            </Col>
                            <ConditionalFragment showIf={isCampaignReport}>
                                <Col className='help-text'>
                                    {t('analyserReportNameModal.downloadExplanation', 'The report package includes the Campaign and a report for each individual you have included.')}
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </FormButtons>
                </ModalBody>
            </Modal>
        </>
    )
};