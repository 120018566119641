import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../api/main/models/Question";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { useCompletionChartDataForQuestionnaires } from "../chartData/useCompletionChartDataForQuestionnaires";
import { useCurrencyAnalysisChartDataForQuestionnaires } from "../chartData/useCurrencyAnalysisChartDataForQuestionnaires";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { CurrencyAnalysisRadarChart } from "../charts/CurrencyAnalysisRadarChart";
import { QuestionnaireDashboardListGroupItem } from "../listGroups/QuestionnaireDashboardListGroupItem";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface SubscriptionQuestionnairesTabProps {
    questionnaires: Array<Questionnaire>,
    sessions: Array<QuestionnaireRespondentSession>,
    responses: Array<QuestionResponse> ,
    questions: Array<Question>,

}

/**
 * Subscriptions tab on the dashboard.
 * @param props
 */
export const SubscriptionQuestionnairesDashboardTab = (props: SubscriptionQuestionnairesTabProps) => {
    const {
        questionnaires,
        sessions,
        responses,
        questions,
    } = props;

    const questionnaireGroups = useMemo(() => [...(questionnaires?.map(item => ({ id: item.id, name: item.name })) ?? [])], [questionnaires,]);

    const allResponses = responses;

    const currencyResponses = useMemo(() => {
        if (!questions || !allResponses) {
            return [];
        }

        const currencyQuestionKeys = [...questions ?? []].filter(item => item.responseType === QuestionResponseType.CurrencyAmount).map(item => item.id);
        const neededResponses = [...allResponses ?? []].filter(item => currencyQuestionKeys.includes(item.questionId));
        return neededResponses;
    }, [questions, allResponses,]);

    // Get data for the various charts.
    const currencyAnalysisChartData = useCurrencyAnalysisChartDataForQuestionnaires(questionnaireGroups, sessions, currencyResponses, 'Subscription');
    const completionChartData = useCompletionChartDataForQuestionnaires(questionnaireGroups, sessions, 'Subscription');

    const baseRoute = useDashboardBaseRoute();
    return (
        <Row style={{ paddingTop: '15px' }}>
            <Col xs={12} xl="">
                <ListGroup className="mb-4">
                    {
                        questionnaires.map(questionnaire => (
                            <QuestionnaireDashboardListGroupItem
                                key={questionnaire.id}
                                sessionsNotStarted={!!sessions ? sessions.filter(item => item.questionnaireId === questionnaire.id && !item.startDate) : []}
                                sessionsStarted={!!sessions ? sessions.filter(item => item.questionnaireId === questionnaire.id && item.startDate && !item.endDate) : []}
                                sessionsCompleted={!!sessions ? sessions.filter(item => item.questionnaireId === questionnaire.id && item.endDate) : []}
                            >
                                <ListGroupItemHeading>
                                    <Link to={`${baseRoute}/questionnaire/${questionnaire.id}/`}>
                                    {questionnaire.name}
                                    </Link>
                                </ListGroupItemHeading>
                            </QuestionnaireDashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col xs={12} xl="">
                <CurrencyAnalysisRadarChart data={currencyAnalysisChartData} />
                <CompletionBarChart data={completionChartData} />
                {/*    <CompletionOverTimeAreaChart data={completionOverTimeChartData} assessmentType={activeAssessmentType} />*/}
            </Col>
        </Row>
    );
};