import { QuestionResponseType } from "../../api/main/models/codeOnly/QuestionResponseType";


// Component for displaying a question's responses on a report

export interface ReportQuestionResponseDisplayProps {
    displayText: string;
    preText: string;

    responseType: string;
}

export const ReportQuestionResponseDisplay = (props: ReportQuestionResponseDisplayProps) => {
    const {
        displayText,
        preText,
        responseType
    } = props;


    let preTextClass = 'd-none';
    if (responseType === QuestionResponseType.ComparisonScale) {
        preTextClass = 'fixed-width-20';
    } else if (preText.length > 0) {
        preTextClass = '';
    }

    return (
        <div className={"response-container"}>

            <span className={`response-pre-text ${preTextClass}`}>{preText}</span>
            {displayText}
        </div>
    )
};