import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { subscriptionAnalyserViewModelQuery, subscriptionAnalyserViewModelQueryVariables } from '../../generated/subscriptionAnalyserViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { Guid } from 'guid-string';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { subscriptionDepartmentFieldsFragment } from '../../models/SubscriptionDepartment';
import { questionnaireFieldsFragment } from '../../models/Questionnaire';
import { questionnaireRespondentSessionFieldsFragment } from '../../models/QuestionnaireRespondentSession';
import { learningUnitFieldsFragment } from '../../models/LearningUnit';
import { questionnaireAnalysisTypeLinkFieldsFragment } from '../../models/QuestionnaireAnalysisTypeLink';
import { analysisTypeFieldsFragment } from '../../models/AnalysisType';
import { questionnaireSectionFieldsFragment } from '../../models/QuestionnaireSection';



export interface SubscriptionAnalyserViewModelOptions extends AsyncLoadOptions {
    includeOngoing: boolean,
    subscriptionDepartmentId: string | null,
}
/**
 * View model for SubscriptionAnalyser to loads all the right related data.
 * @param options
 */
export function useSubscriptionAnalyserViewModel(id: string | undefined | null, options: SubscriptionAnalyserViewModelOptions = { includeOngoing: false, subscriptionDepartmentId: null }): AsyncLoadResult<subscriptionAnalyserViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<subscriptionAnalyserViewModelQuery, subscriptionAnalyserViewModelQueryVariables>(
        gql`
        query subscriptionAnalyserViewModelQuery ($id: ID!, $includeOngoing: Boolean!, $subscriptionDepartmentId: ID) {
            model: subscription(id: $id) {
                ...subscriptionFields
            }

            subscriptionDepartments(subscriptionId: $id) {
                ...subscriptionDepartmentFields
            }

            questionnaires (subscriptionId: $id, includeOngoing: $includeOngoing, subscriptionDepartmentId: $subscriptionDepartmentId) {
                ...questionnaireFields
                questionnaireSections {
                    ...questionnaireSectionFields
                }
            }

            questionnaireRespondentSessions (subscriptionId: $id, subscriptionDepartmentId: $subscriptionDepartmentId) {
                ...questionnaireRespondentSessionFields
            }

            learningUnits(subscriptionId: $id) {
                ...learningUnitFields
            }

            questionnaireAnalysisTypeLinks(subscriptionId: $id) {
                ...questionnaireAnalysisTypeLinkFields
            }

            analysisTypes (subscriptionId: $id){
                ...analysisTypeFields
            }
        }

        ${subscriptionFieldsFragment}
        ${subscriptionDepartmentFieldsFragment}
        ${questionnaireFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${learningUnitFieldsFragment}
        ${questionnaireAnalysisTypeLinkFieldsFragment}
        ${analysisTypeFieldsFragment}
        ${questionnaireSectionFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
                includeOngoing: options.includeOngoing,
                subscriptionDepartmentId: options.subscriptionDepartmentId,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}