import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Card, CardBody, Col, Collapse, FormGroup, FormText, Label, ListGroup, ListGroupItem, Row,} from "reactstrap";
import { Question, questionDefaultValues } from "../../../../api/main/models/Question";
import { FormButtons } from "../../../shared/FormButtons";
import { ValidationErrors } from "pojo-validator";
import { CardHeaderCollapse } from "../../../shared/cardHeaderCollapse/CardHeaderCollapse";
import { useToggleState } from "use-toggle-state";
import { ConditionalFragment } from "react-conditionalfragment";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { QuestionnaireSection } from "../../../../api/main/models/QuestionnaireSection";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ValidateCallback } from "pojo-validator-react";
import { hasAnyValidationErrors } from "../../../../utilities/hasAnyValidationErrors";
import { EditQuestionModal } from "../../../questions/editQuestionModal/EditQuestionModal";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { ValidateModelCallback, ValidationErrorsForModel } from "../../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { Guid } from "guid-string";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { ValidatedElasticInput } from "../../../../shared/validatedInputExtended";
import { QuestionResponseType, questionResponseTypeDisplayName } from "../../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { DeleteItemModal } from "./DeleteItemModal";
import "./sectionComponent.scss";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AnalysisType } from "../../../../api/main/models/AnalysisType";
import { SectionQuestionDisplay } from "./SectionQuestionDisplay";
import { QuestionnaireAnalysisTypeLink } from "../../../../api/main/models/QuestionnaireAnalysisTypeLink";

export interface SectionComponentClickEventArgs {
    /**
     * section id and whether open or not.
     * */
    sectionId: string,
    isComponentOpen: boolean,

}

export interface SectionComponentProps {
    model: QuestionnaireSection,
    change: (changes: Partial<QuestionnaireSection>) => void,
    remove: () => void,
    moveUp: () => void,
    canMoveUp: boolean,
    moveDown: () => void,
    canMoveDown: boolean,

    validate: ValidateCallback,
    validationErrors: ValidationErrors,

    isOpenInitially?: boolean,
    openSectionId: string,

    questionsManager: ModelArrayChanges<Question, string>,
    validateQuestion: ValidateModelCallback<Question>,
    questionValidationErrors: ValidationErrorsForModel,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>,

    onSectionToggle: (event: { sectionId: string, isComponentOpen: boolean, }) => void,

    sectionClass: string,

    analysisTypeManager: ModelArrayChanges<AnalysisType, string>,
    validateAnalysisType: ValidateModelCallback<AnalysisType>,
    analysisTypeValidationErrors: ValidationErrorsForModel,

    questionnaireAnalysisTypeLinkManager: ModelArrayChanges<QuestionnaireAnalysisTypeLink, string>,

    defaultSectionName: string,
}

/**
 * A question that forms part of the AssessmentItem.
 * @param props
 */
export const SectionComponent = (props: SectionComponentProps) => {
    const {
        model,
        change,
        remove,
        moveUp,
        canMoveUp,
        moveDown,
        canMoveDown,

        validate,
        validationErrors,

        isOpenInitially = false,
        openSectionId,

        questionsManager,
        validateQuestion,
        questionValidationErrors,
        saveAllDeBounce,
        onSectionToggle,

        sectionClass,

        analysisTypeManager,
        validateAnalysisType,
        analysisTypeValidationErrors,

        questionnaireAnalysisTypeLinkManager,

        defaultSectionName,
    } = props;

    const { t } = useTranslation();

    // Self management of if we are expanded or not.
    const [isOpen, toggleOpen] = useToggleState(isOpenInitially);

    // toggle the section to closed if we have asked for a different section to be open
    useEffect(() => {
        if (!model || !isOpen || isOpenInitially) {
            // not loaded or not open, or a new section so needs to stay open
            return;
        }

        if (!!openSectionId && openSectionId !== model.id) {
            // different section requested so close this one
            toggleOpen();
        }


    }, [isOpen, openSectionId, model, toggleOpen, isOpenInitially]);

    // use this to tell the edit question if we are editing or creating
    const [isQuestionCreate, setIsQuestionCreate] = useState<boolean>(true);

    // Order the questions so they show in and can be managed by displayOrder, Filtered the questions to be specific for each section.
    const [orderedQuestions, {
        canMoveUp: canMoveQuestionUp,
        moveUp: moveQuestionUp,
        canMoveDown: canMoveQuestionDown,
        moveDown: moveQuestionDown,
    }] = useDisplayOrder(questionsManager, item => item.questionnaireSectionId === model?.id);

    // Showing of the question modal.
    const [questionModalIsOpen, toggleQuestionModal] = useToggleState();

    // use these to set a model to go into the edit question for a new question
    const [questionId, setQuestionId] = useState<string>('');
    const question = questionsManager.model.find(item => item.id === questionId);

    // handle add question clicked
    const handleAddQuestion = useCallback(async () => {
        // put the new question into the array
        const newQuestionId = Guid.newGuid();

        questionsManager.addFor({
            ...questionDefaultValues(), id: newQuestionId, questionnaireId: model.questionnaireId, questionnaireSectionId: model.id,
        });

        setQuestionId(newQuestionId);
        setIsQuestionCreate(true);
    }, [model, questionsManager, setIsQuestionCreate, setQuestionId]);

    const removeSectionQuestions = useCallback(() => {
        // remove all questions from the section
        const questions = [...questionsManager.model].filter(item => item.questionnaireSectionId === model.id);

        for (let thisQuestion of questions) {
            questionsManager.removeFor(thisQuestion.id);
        }
    }, [questionsManager, model]);


    // Remove/Delete Section Modal
    const [deleteSectionModalIsOpen, toggleDeleteSectionModal] = useToggleState();

    // Remove/Delete Question Modal
    const [deleteQuestionModalIsOpen, toggleDeleteQuestionModal] = useToggleState();

    // Use Memo to Decide On what to show in Delete Item Modal
    const deleteItemModal = useMemo(() => {
        if (deleteSectionModalIsOpen) {
            return (
                <DeleteItemModal
                    isOpen={deleteSectionModalIsOpen}
                    toggle={() => toggleDeleteSectionModal()}
                    removeItem={() => { remove(); removeSectionQuestions(); saveAllDeBounce(); }}
                    itemName={model.name}
                />
            )
        } else if (deleteQuestionModalIsOpen) {
            return (
                <DeleteItemModal
                    isOpen={deleteQuestionModalIsOpen}
                    toggle={() => toggleDeleteQuestionModal()}
                    removeItem={() => { questionsManager.removeFor(questionId); saveAllDeBounce(); }}
                    itemName={question?.questionText ?? ''} />
            )
        }

    }, [deleteSectionModalIsOpen, toggleDeleteSectionModal, remove, removeSectionQuestions, saveAllDeBounce, deleteQuestionModalIsOpen,
        toggleDeleteQuestionModal, questionsManager, question, model.name, questionId]);

    const handleToggleSection = useCallback(() => {

        toggleOpen();

        const externalEvent: SectionComponentClickEventArgs = {

            sectionId: model.id,
            isComponentOpen: !isOpen,

        };
        onSectionToggle(externalEvent);


    }, [toggleOpen, isOpen, model.id, onSectionToggle]);

    const [holdSectionName, setHoldSectionName] = useState<string>(model?.name ?? '');

    const onSectionNameFocus = useCallback((name) => {
        if (name === defaultSectionName) {
            setHoldSectionName(name ?? '');
            change({ name: '' });
        }
    }, [defaultSectionName, change]);
    
    const onSectionNameBlur = useCallback((inputValue: string) => {
    if (!inputValue && holdSectionName === defaultSectionName) {
            model.name = holdSectionName;
            setHoldSectionName('');
        }
        validate('sectionName');
    }, [model, holdSectionName, validate, defaultSectionName]);

    return (
        <>
            <Card className={`questionnaire-section ${sectionClass}`} >
                <CardHeaderCollapse isOpen={isOpen} toggle={handleToggleSection}>
                    <Row>
                        <Col className="section-title">
                            <ConditionalFragment showIf={!!orderedQuestions.length}>
                                <FontAwesomeIcon icon="check-circle" className="section-header-check" /><> </>
                            </ConditionalFragment>
                            {model.name}
                        </Col>
                        <Col>
                            <FormButtons noPadding>
                                <ButtonGroup className="section-button-group">
                                    <Button color="primary" outline onClick={(e) => { e.stopPropagation(); moveUp();}} disabled={!canMoveUp}>
                                        <FontAwesomeIcon icon="caret-up" />
                                        <> </>
                                        {t('sectionComponent.up', 'Up')}
                                    </Button>
                                    <Button color="primary" outline onClick={(e) => { e.stopPropagation(); moveDown(); }} disabled={!canMoveDown}>
                                        <FontAwesomeIcon icon="caret-down" />
                                        <> </>
                                        {t('sectionComponent.down', 'Down')}
                                    </Button>
                                </ButtonGroup>
                            </FormButtons>
                        </Col>
                        <ConditionalFragment showIf={hasAnyValidationErrors([validationErrors['sectionName']])}>
                            <> </><FontAwesomeIcon icon="exclamation-triangle" className="text-danger" />
                        </ConditionalFragment>
                    </Row>
                </CardHeaderCollapse>

                <Collapse isOpen={isOpen}>
                    <CardBody>
                        <Col>
                            <FormButtons noPadding className="mb-1">
                                <ButtonGroup className="section-button-group">
                                    <Button color="danger" outline onClick={() => toggleDeleteSectionModal()}>
                                        <FontAwesomeIcon icon="trash-alt" />
                                        <> </>
                                        {t('sectionComponent.delete', 'Delete Section')}
                                    </Button>
                                </ButtonGroup>
                            </FormButtons>
                        </Col>
                        <Col>
                            <Label htmlFor="name">{t('sectionComponent.sectionName', 'Change section heading')}</Label>
                            <FormGroup>
                                <ValidatedInput name="name" type="text" value={model?.name}
                                    onFocus={() => { onSectionNameFocus(model?.name ?? ''); } }
                                    onChange={e => change({ name: e.currentTarget.value })}
                                    onBlur={(e) => onSectionNameBlur(e.currentTarget.value) }
                                    validationErrors={validationErrors['sectionName']} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <Label htmlFor="description">{t('sectionComponent.description', 'Section description')}</Label>
                            <FormGroup>
                                <ValidatedElasticInput name="description" type="textarea" value={model?.description} onChange={e => change({ description: e.currentTarget.value })} onBlur={() => validate('sectionDescription')} validationErrors={validationErrors['sectionDescription']} />
                                <FormText>{t('sectionComponent.formHelp.description', 'This is optional. You can either describe the section or use this to ask an overarching question for all the questions in the section. This will show at the top of the section under the section heading title. ')}</FormText>
                            </FormGroup>
                        </Col>

                        <Col>
                            <FormGroup>
                                <ListGroup className="mt-1">
                                    <Label>{t("sectionComponent.sectionQuestions", "Section questions")}</Label>
                                    <div>
                                        {
                                            //List of Questions For The Section
                                            orderedQuestions.map((question, questionIndex) => {
                                                let indexOddOrEven = questionIndex % 2 === 0 ? 'even' : 'odd';
                                                return (
                                                    <ListGroupItem className={`question-list-group-item-${indexOddOrEven}`} key={question.id} tag="div">
                                                        <div onDoubleClick={e => { setIsQuestionCreate(false); setQuestionId(question.id); toggleQuestionModal(true); }}>
                                                            <Row>
                                                                {/* Display Question Text */}    
                                                                <SectionQuestionDisplay
                                                                    questionIndex={questionIndex}
                                                                    questionText={question.questionText}
                                                                    />
                                                                <Col xs={12} sm={2} className="question-text">
                                                                    {/* Display Question type */}
                                                                    <>{question?.questionType === QuestionType.Measure ? questionResponseTypeDisplayName(question.responseType as QuestionResponseType, t) : question.questionType}</>
                                                                </Col>
                                                                <Col xs={12} sm={5}>
                                                                    <FormButtons noPadding className="mb-1">
                                                                        <ButtonGroup className="question-button-group">
                                                                            {/* Edit Button Opens Question Modal To Edit Question */}
                                                                            <Button color="primary" outline onClick={e => { setIsQuestionCreate(false); setQuestionId(question.id); toggleQuestionModal(true); }}>
                                                                                <FontAwesomeIcon icon="edit" />
                                                                                <> </>
                                                                                {t('questionList.edit', 'Edit')}
                                                                            </Button>
                                                                            {/* Move Question Up In The List Using Question Display Order */}
                                                                            <Button color="primary" outline onClick={() => moveQuestionUp(question.id)} disabled={!canMoveQuestionUp(question.id)}>
                                                                                <FontAwesomeIcon icon="caret-up" />
                                                                                <> </>
                                                                                {t('questionList.up', 'Up')}
                                                                            </Button>
                                                                            {/* Move Question Down In The List Using Question Display Order */}
                                                                            <Button color="primary" outline onClick={() => moveQuestionDown(question.id)} disabled={!canMoveQuestionDown(question.id)}>
                                                                                <FontAwesomeIcon icon="caret-down" />
                                                                                <> </>
                                                                                {t('questionList.down', 'Down')}
                                                                            </Button>
                                                                            {/* Remove Question From Section */}
                                                                            <Button color="danger" outline onClick={() => { toggleDeleteQuestionModal(); setQuestionId(question.id); }}>
                                                                                <FontAwesomeIcon icon="trash-alt" />
                                                                                <> </>
                                                                                {t('questionListt.delete', 'Delete Question')}
                                                                            </Button>
                                                                        </ButtonGroup>
                                                                    </FormButtons>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </ListGroupItem>
                                                );
                                            })
                                        }
                                        <ConditionalFragment showIf={!orderedQuestions.length}>
                                            <ListGroupItem>
                                                <Col xs={12} sm={12} className={"no-results"}>
                                                    {t('questionList.getStarted', `Get started by pressing the 'Add Question' button below.`)}
                                                </Col>
                                            </ListGroupItem>
                                        </ConditionalFragment>
                                    </div>
                                </ListGroup>
                            </FormGroup>
                        </Col>

                        <Col>
                            <ButtonGroup>
                                <Button color="primary" outline onClick={e => { toggleQuestionModal(); handleAddQuestion(); }}>
                                    {t('sectionComponent.addQuestion', 'Add Question')}
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </CardBody>
                </Collapse>
            </Card>

            <ConditionalFragment showIf={!!questionId && !!question}>
                <EditQuestionModal
                    isOpen={questionModalIsOpen}
                    toggle={toggleQuestionModal}
                    model={question as Question}
                    change={changes => questionsManager.changeFor(questionId, changes)}
                    remove={() => questionsManager.removeFor(questionId)}

                    isCreate={isQuestionCreate}
                    validate={(fieldsToCheck) => validateQuestion(question as Question, fieldsToCheck)}
                    validationErrors={questionValidationErrors(questionId)}
                    saveAllDeBounce={saveAllDeBounce}

                    analysisTypeManager={analysisTypeManager}
                    validateAnalysisType={validateAnalysisType}
                    analysisTypeValidationErrors={analysisTypeValidationErrors}

                    questionnaireAnalysisTypeLinkManager={questionnaireAnalysisTypeLinkManager}
                    questionsManager={questionsManager }
                />
            </ConditionalFragment>
            <ConditionalFragment showIf={deleteSectionModalIsOpen || deleteQuestionModalIsOpen}>
                {deleteItemModal}
            </ConditionalFragment>
        </>
    );
};