// Import the required localization files for momentjs.
//import 'moment/locale/en-us'; // Built in.
import 'moment/locale/en-gb';
//import 'moment/locale/fr';
//import 'moment/locale/pt';
//import 'moment/locale/es';

// Import the required localization files for numeraljs.
//import 'numeral/locale/en-us'; // Built in.
import 'numeral/locales/en-gb';
//import 'numeral/locales/fr';
//import 'numeral/locales/pt-pt';
//import 'numeral/locales/es';


/**
 * Languages available in the application. 
 */
export const availableLanguages = [
    { code: 'en-US', name: 'English (American)', flagCountryCode: 'us', momentLocale: 'en', numeralLocale: 'en' },
    { code: 'en-GB', name: 'English (British)', flagCountryCode: 'gb', momentLocale: 'en-gb', numeralLocale: 'en-gb' },
    //{ code: 'fr', name: 'Français', flagCountryCode: 'fr', momentLocale: 'fr', numeralLocale: 'fr' },
    //{ code: 'pt', name: 'Português', flagCountryCode: 'pt', momentLocale: 'pt', numeralLocale: 'pt-pt' },
    //{ code: 'es', name: 'Español', flagCountryCode: 'fr', momentLocale: 'fr', numeralLocale: 'fr' }
];
