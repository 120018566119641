import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Button, ButtonGroup, Col, Container, FormGroup, Input, Row } from "reactstrap";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import Chart from "react-apexcharts";
import "./questionnaireOverview.scss";
import { ApexOptions } from "apexcharts";
import { chartDataConverters } from "../../dashboard/chartDataUtilities/ChartDataConverter";
import { ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { useSendAllQuestionnaireReminderCallback } from "../../../api/main/questionnaires/useSendAllQuestionnaireReminderCallback";
import { TagLikeItem } from "../../../shared/tagSelector/TagSelector";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { DraggableSectionComponent } from "./DraggableSectionComponent";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { Guid } from "guid-string";
import { Question } from "../../../api/main/models/Question";
import { AnalyserCheckedListModal } from "../../dashboard/analyser/AnalyserCheckedListModal";
import { useQuestionnaireOverviewViewModel } from "../../../api/main/questionnaires/viewModels/useQuestionnaireOverviewViewModel";
import { ConditionalFragment } from "react-conditionalfragment";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { InformativeListModal } from "./InformativeListModal";
import { AnalyserInterrogationResult, InterrogateAnalyserData } from "../../dashboard/analyser/AnalyserDataInterrogation";
import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { isResponseTypeForMeasure, QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { AnalyserReportPrintModal } from "../../reporting/AnalyserReportPrintModal";
import { PdfPrintButtons } from "../../dashboard/analyser/PdfPrintButtons";
import { BulkReportGenerationRequest } from "../../reporting/bulkReportGenerator/BulkReportGenerationRequest";
import { BulkReportGenerator } from "../../reporting/bulkReportGenerator/BulkReportGenerator";
import moment from "moment";
import { Profile } from "../../../api/main/models/Profile";
import { questionnaireOverviewViewModelQuery_questions } from "../../../api/main/generated/questionnaireOverviewViewModelQuery";
import { AnalysisType } from "../../../api/main/models/AnalysisType";
import { AnalyserSelectGraphTypeModal } from "../../dashboard/analyser/AnalyserSelectGraphTypeModal";
import { useProfiles } from "../../../api/main/profiles/useProfiles";

export interface ResponsesForDragComponent {
    responseText: string;
    questionId: string;
    displayOrder: number;
    numberResponse?: number;
}

export interface LearnersForDragComponent {
    name: string;
    learnerResponses: Array<ResponsesForDragComponent>;
}

export interface SectionsListItem {
    id: Guid;
    name: string;
    questions: Array<Question>;
    learners: Array<LearnersForDragComponent>;

}

function useCompletionChartData(totalNotCompleted: number, totalCompleted: number): Array<ChartDataSeries> {

    const { t } = useTranslation();

    const generateSeries = useCallback(() => {
        // Generate chart data for each group.
        const ret = [
            {
                text: t('questionnaireOverview.completed', 'Completed'),
                value: totalCompleted
            },
            {
                text: t('questionnaireOverview.not-completed', 'Not Completed'),
                value: totalNotCompleted
            },
        ]

        return {
            name: "",
            data: ret
        } as ChartDataSeries;
    }, [totalNotCompleted, totalCompleted, t]);

    const ret = useMemo(() => {
        return [
            generateSeries(),
        ];
    }, [generateSeries]);

    return ret;

};

export const QuestionnaireOverview = () => {

    const { t } = useTranslation();

    const { id: campaignId } = useParams<{ id: string | undefined }>();

    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { data: {
        model: subscription,
        questionnaire,
        questionnaireSections,
        questions: questionnaireQuestions,
        questionnaireRespondentSessions,
        questionResponses: questionnaireResponses,
        analysisTypes,
        questionnaireAnalysisTypeLinks,
        learningUnit
    }, isLoading: campaignOverviewIsLoading, errors: campaignOverviewErrors
    } = useQuestionnaireOverviewViewModel({ subscriptionId: currentUserSubscriptionId, questionnaireId: campaignId, includeNonUser: false });


    const responseUserIds = useMemo(() => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        // get all the userids from the responses
        return questionnaireRespondentSessions.map(r => r.userId);
    }, [questionnaireRespondentSessions, ]);


    // Load profiles separately so we don't have to wait for them to begin making selections    
    const {
        data: {
            items: profiles,
        },
        errors: profilesErrors,
    } = useProfiles({ userIds: responseUserIds, lazy: false });

    const totalInvited = questionnaire?.questionnaireRespondentSessions.filter(item => !!(profiles?.find(profile => profile.userId === item.userId)))?.length ?? 0;

    // Can also use length of this for total completed.
    const completedProfilesAsTags = useMemo(() => {
        if (!profiles) {
            return;
        }

        const completedProfiles = profiles?.filter(profile => questionnaire?.questionnaireRespondentSessions.find(session => session.endDate !== null && profile.userId === session.userId) !== undefined);

        if (!!completedProfiles) {
            const ret: Array<TagLikeItem> = [];
            completedProfiles.forEach(item => {
                ret.push({ id: item.userId, name: item.firstName + " " + item.lastName });
            });
            return ret;
        }
    }, [profiles, questionnaire]);

    // total invited but not completed.
    const incompleteProfilesAsTags = useMemo(() => {
        const incompleteProfiles = profiles?.filter(profile => questionnaire?.questionnaireRespondentSessions.find(session => session.endDate === null && session.userId === profile.userId) !== undefined);
        if (!!incompleteProfiles) {
            const ret: Array<TagLikeItem> = [];
            incompleteProfiles.forEach(item => {
                ret.push({ id: item.userId, name: item.firstName + " " + item.lastName });
            });
            return ret;
        }
    }, [profiles, questionnaire]);

    const chartData = useCompletionChartData(incompleteProfilesAsTags?.length ?? 0, completedProfilesAsTags?.length ?? 0);

    // Convert data into format needed for our completions graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toPie(chartData), [chartData]);

    const [sendAllQuestionnaireReminder] = useSendAllQuestionnaireReminderCallback();

    const [selectedLearners, setSelectedLearners] = useToggleStateArray<string | null>();
    const [allLearners, setAllLearners] = useState<boolean>(true);

    const [useCategoriesList, setUseCategoriesList] = useState<boolean>(false);

    // Compile all the options we want to use for our graph.
    const options = useMemo(() => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: true,
                formatter: (val, options) => {
                    // Show value instead of percentage.
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
            },

            legend: {
                position: 'bottom',
            },

            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            total: {
                                show: true,
                                color: '#000000',
                            }
                        }
                    }
                }
            },

            colors: [
                '#43A0FC',
                '#e6e1e1',
            ],

            ...seriesOptions,
        } as ApexOptions;

    }, [seriesOptions]);

    const [showGraph, setShowGraph] = useState<boolean>(true);

    const [sectionsList, setSectionsList] = useState<Array<SectionsListItem>>([]);
    const [categoriesList, setCategoriesList] = useState<Array<SectionsListItem>>([]);

    // category graph selection
    const [categoryGraphMode, setCategoryGraphMode] = useState<boolean>(false);
    const [graphCategories, setGraphCategories] = useToggleStateArray<string | null>();
    const [categoryGraphFormat, setCategoryGraphFormat] = useState<'bar' | 'horizontalBar'>('bar');
    const [includeCategoryGraph, setIncludeCategoryGraph] = useState<boolean>(false);

    const [graphModalOpen, toggleGraphModalOpen] = useState<boolean>(false);

    // List of profiles for users selected from the select learners dropdown.
    const selectedLearnerProfiles = useMemo(() => {
        if (!profiles || !profiles.length || !completedProfilesAsTags || !completedProfilesAsTags.length) {
            return [];
        }

        return profiles.filter(profile => selectedLearners(completedProfilesAsTags?.find(tag => tag.name.includes(profile.firstName))?.id ?? ''))


    }, [completedProfilesAsTags, profiles, selectedLearners,]);

    // Only do this one time
    const [initialised, setInitialised] = useState<boolean>(false);
    const [initialSectionSort, setInitialSectionSort] = useState<boolean>(false);

    const getLearnerResponses = useCallback((learner: Profile, responses: Array<QuestionResponse>, currentAnalysisTypeQuestions: Array<questionnaireOverviewViewModelQuery_questions>) => {
        const currentUserResponses = responses.filter(response => response.userId === learner.userId);
        let learnerResponses: Array<ResponsesForDragComponent> = [];
        currentUserResponses.forEach(response => {
            const question = currentAnalysisTypeQuestions.find(question => question.id === response.questionId)
            if (question?.responseType === "YesNo") {

                learnerResponses.push({
                    responseText: response.yesNoInput ? "Yes" : "No",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder
                });

            } else if (question?.responseType === "TextBoxes") {
                if (response.textInput !== "") {

                    learnerResponses.push({
                        responseText: response.textInput,
                        questionId: response.questionId,
                        displayOrder: response.displayOrder
                    });
                }
            } else if (question?.responseType === "Percentage" || question?.isPercentage) {

                learnerResponses.push({
                    responseText: response.amountInput + "%",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder,
                    numberResponse: response.amountInput
                });

            } else if (question?.responseType === "PickOne" || question?.responseType === "MultipleChoice") {

                switch (response.amountInput) {
                    case 1: learnerResponses.push({ responseText: question.multipleChoiceResponse1, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 2: learnerResponses.push({ responseText: question.multipleChoiceResponse2, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 3: learnerResponses.push({ responseText: question.multipleChoiceResponse3, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 4: learnerResponses.push({ responseText: question.multipleChoiceResponse4, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 5: learnerResponses.push({ responseText: question.multipleChoiceResponse5, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 6: learnerResponses.push({ responseText: question.multipleChoiceResponse6, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 7: learnerResponses.push({ responseText: question.multipleChoiceResponse7, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 8: learnerResponses.push({ responseText: question.multipleChoiceResponse8, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                }
            } else {

                learnerResponses.push({
                    responseText: response.amountInput + "",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder,
                    numberResponse: response.amountInput
                });
            }
        })

        learnerResponses = learnerResponses.sort((a, b) => {

            if (a.displayOrder < b.displayOrder) {
                return -1;
            }
            if (a.displayOrder > b.displayOrder) {
                return 1;
            }

            return 0;
        });

        return learnerResponses;
    }, []);

    // List of data to use in the dragable categories
    useEffect(() => {

        const ret: Array<SectionsListItem> = [];

        if (!questionnaireSections || !profiles || !questionnaireResponses || !questionnaireQuestions || !questionnaire) {
            return;
        }

        // Get a list of all analysis types that have questions inside them (Analysis types fetched are just all for the subscription)
        const inUseAnalysisTypes = analysisTypes?.filter(analysisType => questionnaireQuestions.find(question => question.analysisTypeId === analysisType.id))

        inUseAnalysisTypes?.forEach(analysisType => {
            // All the questions that belong to the current analysis type.
            const currentAnalysisTypeQuestions = questionnaireQuestions.filter(question => question.analysisTypeId === analysisType.id);

            // For each of the questions get the learners that have answered that question.
            const analysisTypeLearners = selectedLearnerProfiles?.filter(profile => questionnaire?.questionnaireRespondentSessions.find(session => session.endDate !== null && profile.userId === session.userId) !== null);

            const responses: Array<QuestionResponse> = [];

            analysisTypeLearners.forEach(learner => {

                // Go through each learner and get the responses they have given to the current question.
                const learnerResponses = questionnaireResponses.filter(response => currentAnalysisTypeQuestions.find(question => question.id === response.questionId) !== null && response.userId === learner.userId);

                // Push each learners response into the responses array.
                learnerResponses.forEach(response => {
                    responses.push(response);
                })

            });

            // Push the data into the ret array.
            ret.push({
                id: analysisType.id,
                name: analysisType.name === "" ? "No Category" : analysisType.name,
                questions: currentAnalysisTypeQuestions,
                learners: analysisTypeLearners.map(learner => {
                    return {
                        name: learner.firstName + " " + learner.lastName,
                        learnerResponses: getLearnerResponses(learner, responses, currentAnalysisTypeQuestions)

                    }
                }),
            });
        });

        setCategoriesList(ret);

    }, [questionnaireSections, profiles, questionnaireResponses, questionnaireQuestions, questionnaire, analysisTypes, selectedLearnerProfiles, getLearnerResponses]);



    // List of data to use in the dragable sections
    useEffect(() => {
        const ret: Array<SectionsListItem> = [];

        if (!questionnaireSections || !profiles || !questionnaireResponses || !questionnaireQuestions || !questionnaire || !selectedLearners || !selectedLearners.length) {
            return;
        }

        questionnaireSections.forEach(section => {
            // For each of the sections get the questions that belong in that section.
            const currentSectionQuestions = questionnaireQuestions.filter(question => question.questionnaireSectionId === section.id);

            // For each of the questions get the learners that have answered that question.
            // We know that if the user has completed the questionnaire then they have answered all the questions.
            const sectionLearners = selectedLearnerProfiles.filter(profile => questionnaire?.questionnaireRespondentSessions.find(session => session.endDate !== null && profile.userId === session.userId) !== null);

            const responses: Array<QuestionResponse> = [];

            // For each of the learners get the answers they have given to that question.
            sectionLearners.forEach(learner => {

                const learnerResponses = questionnaireResponses.filter(response => currentSectionQuestions.find(question => question.id === response.questionId) !== null && response.userId === learner.userId);

                learnerResponses.forEach(response => {
                    responses.push(response);
                })

            })

            // Push the data into the ret array.
            ret.push({
                id: section.id,
                name: section.name,
                questions: currentSectionQuestions,
                learners: sectionLearners.map(learner => {
                    return {
                        name: learner.firstName + " " + learner.lastName,
                        learnerResponses: getLearnerResponses(learner, responses, currentSectionQuestions)
                    }
                }),
            });
        });

        setSectionsList(ret);

    }, [questionnaireSections, profiles, questionnaireResponses, questionnaireQuestions, questionnaire, selectedLearnerProfiles,
        initialSectionSort, setInitialSectionSort, selectedLearners, getLearnerResponses]);

    // On entry we need to set the selected learners to selected
    useEffect(() => {
        if (initialised) {
            // If this has been completed once then skip it
            return;
        }

        if (!completedProfilesAsTags) {
            return;
        }

        // Set all the learners to selected
        completedProfilesAsTags?.forEach(tag => setSelectedLearners(tag.id));

        setInitialised(true);
    }, [initialised, setInitialised, completedProfilesAsTags, setSelectedLearners, questionnaireSections, sectionsList, setSectionsList]);

    // Drag and drop support for sections
    const reorderSections = (e: DragEndEvent) => {
        if (!e.over) return;

        const activeSection = sectionsList.find(section => section.id === e.active.id);
        const overSection = sectionsList.find(section => section.id === e.over?.id);

        if (e.active.id !== e.over.id) {
            setSectionsList((sectionsList) => {
                const oldIdx = sectionsList.indexOf(activeSection!);
                const newIdx = sectionsList.indexOf(overSection!);
                return arrayMove(sectionsList, oldIdx, newIdx);
            });
        }
    };

    // Drag and drop support for sections
    const reorderCategories = (e: DragEndEvent) => {
        if (!e.over) return;

        const activeCategory = categoriesList.find(category => category.id === e.active.id);
        const overCategory = categoriesList.find(category => category.id === e.over?.id);

        if (e.active.id !== e.over.id) {
            setCategoriesList((categoriesList) => {
                const oldIdx = categoriesList.indexOf(activeCategory!);
                const newIdx = categoriesList.indexOf(overCategory!);
                return arrayMove(categoriesList, oldIdx, newIdx);
            });
        }
    };

    const chartKey = useMemo(() => JSON.stringify(options), [options]);

    // Get Measures from the QuestionResponseType enum
    const allQuestionResponseTypes = Object.values(QuestionResponseType);
    const measureQuestionResponseTypes = useMemo(() => {
        const ret: Array<QuestionResponseType> = [];
        allQuestionResponseTypes.forEach((type: QuestionResponseType) => {
            if (isResponseTypeForMeasure(type)) {
                ret.push(type);
            }
        });

        return ret;

    }, [allQuestionResponseTypes]);

    // Create an analysisResults object to be used by the pdf creator
    const analysisResults: AnalyserInterrogationResult | undefined = useMemo(() => {
        if (!questionnaire) {
            return;
        }

        // Filter the analysis types to only include those in the list as some may have been removed by the user
        const analysisTypesToUse = analysisTypes.filter(item => categoriesList.find(cl => item.id === cl.id));

        // Filter the sections to only include those in the list as some may have been removed by the user
        const sectionsToUse = questionnaireSections.filter(item => sectionsList.find(sl => sl.id === item.id));

        const result = InterrogateAnalyserData({
            questionnaires: [questionnaire],
            questions: questionnaireQuestions,
            questionResponses: questionnaireResponses,
            learners: selectedLearnerProfiles,
            questionnaireRespondantSessions: questionnaire?.questionnaireRespondentSessions,
            analysisTypes: analysisTypesToUse,
            allAnalysisTypes: analysisTypesToUse.length === analysisTypes.filter(item => questionnaireAnalysisTypeLinks.find(link => link.analysisTypeId === item.id)).length,
            questionnaireAnalysisTypeLinks: questionnaireAnalysisTypeLinks,
            impacts: Object.values(QuestionType),
            measures: measureQuestionResponseTypes.filter(item => questionnaireQuestions.find(q => q.responseType === item)),
            sections: sectionsToUse,
            learningUnits: []
        });

        // Put the analysis result categories into the same order as the selected categories
        result.categoryResults.sort((a, b) => categoriesList.findIndex(item => item.id === a.analysisType.id) - categoriesList.findIndex(item => item.id === b.analysisType.id));

        // Put the analysis result sections into the same order as the selected sections
        result.sectionResults.sort((a, b) => sectionsList.findIndex(item => item.name === a.sectionName) - sectionsList.findIndex(item => item.name === b.sectionName));

        // Clear the results if not needed
        if (!learningUnit) {
            result.learnerResults.costPerLearner = 0;
            result.learnerResults.totalCost = 0;
        }

        return result;
    }, [questionnaire, questionnaireQuestions, questionnaireResponses, selectedLearnerProfiles,
        analysisTypes, measureQuestionResponseTypes, questionnaireSections, questionnaireAnalysisTypeLinks, sectionsList, categoriesList, learningUnit]);

    // Category chart
    const categoryChartData = useCategoryChartData(!!analysisResults && analysisResults.categoryResults ? analysisResults?.categoryResults.filter(c => graphCategories(c.analysisType.id)) : []);
    const { series: categorySeries, options: categorySeriesOptions } = chartDataConverters.toColumn(categoryChartData);
    const categoryOptions = useMemo(() => {
        return {
            chart: {
                toolbar: {
                    show: false,
                },
                brush: {
                    autoScaleYaxis: true,
                },
                events: {

                },
                zoom: {
                    enabled: false,
                },

            },

            dataLabels: {
                enabled: false,
                formatter: (val, options) => {
                    // Show value instead of percentage
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
                offsetY: -20,
                textAnchor: 'middle',
            },

            legend: {
                position: 'bottom',

            },

            plotOptions: {
                bar: {
                    horizontal: categoryGraphFormat === 'bar' ? false : true,
                    colors: {
                    },
                },

            },

            colors: [
                '#1188FF',
                '#0055aa',
            ],


            yaxis: {
                decimalsInFloat: 0,
                forceNiceScale: true,
                title: {
                    text: categoryGraphFormat === 'bar' ? 'Percentage Change (%)' : '',

                }
            },

            xaxis: {
                decimalsInFloat: 0,
                forceNiceScale: true,
                title: {
                    text: categoryGraphFormat !== 'bar' ? 'Percentage Change (%)' : '',
                }
            },

            ...categorySeriesOptions,
        } as ApexOptions;
    }, [categorySeriesOptions, categoryGraphFormat]);
    const categoryChartKey = useMemo(() => JSON.stringify(categoryOptions), [categoryOptions])

    const [reportNameModalIsOpen, toggleReportNameModal] = useToggleState();

    const pdfPrintButtonOnClick = useCallback(() => {
        // Set up a zipfile name in case the user downloads a package.
        // This allows the Zip controller to find all the blobs to zip up
        setAutoGeneratedZipFileName(`Reports-${moment().format('YYYY-MM-DDTHHmmss')}.zip`);

        if (!!analysisResults) {
            toggleReportNameModal();
        }
    }, [toggleReportNameModal, analysisResults]);

    const selectedLearnerDetails = useMemo(() => {
        const ret: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }> = [];

        if (!selectedLearnerProfiles) {
            return ret;
        }

        selectedLearnerProfiles.forEach((learner) => {

            // get all sessions for this user that have a line manager on
            const allSessions =
                questionnaireRespondentSessions?.filter(qr => !!qr.lineManagerEmail && selectedLearnerProfiles
                    .find(u => u.userId === learner.userId))
                    .sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

            // if we have any sessions get the latest one
            const latestSession = !!allSessions ? allSessions[0] : undefined;

            ret.push({
                id: learner.userId,
                firstName: learner.firstName,
                lastName: learner.lastName,
                email: learner.user.email,
                lineManagerName: !!latestSession ? latestSession.lineManagerName : '',
                lineManagerEmail: !!latestSession ? latestSession.lineManagerEmail : ''
            });
        });

        return ret;


    }, [questionnaireRespondentSessions, selectedLearnerProfiles]);

    // Manage the bulk generation and download of reports via the modal.
    const [isGeneratingReports, toggleGeneratingReports] = useToggleState();

    const [bulkReportRequests, setBulkReportRequests] = useState<Array<BulkReportGenerationRequest>>([]);

    // Need a common zip file name in case the user downloads a report package
    const [autoGeneratedZipFileName, setAutoGeneratedZipFileName] = useState<string>('');

    const analyserNode = useMemo(() => {
        if (!subscription || !analysisResults || !selectedLearnerDetails.length) {
            return null;
        }

        // Get the names of the users that have action based responses for the Actions page.
        // Actions are responses to Commitment type questions.

        // Split out the questions and responses from the section results.
        // Actions
        const actionQuestions = analysisResults.sectionResults.flatMap(s => s.data.map(d => d.question)).filter(item => item.questionType === 'Commitment');
        const actionQuestionIds = actionQuestions.map(q => q.id);
        const actionResponses = analysisResults.sectionResults.flatMap(s => s.data.map(d => d.responses)).flat().filter(item => actionQuestionIds.find(q => q === item.questionId && item.isUserInput));
        // Transformations
        const transformationQuestions = analysisResults.sectionResults.flatMap(s => s.data.map(d => d.question)).filter(item => item.responseType === 'ComparisonScale');
        const transformationQuestionIds = transformationQuestions.map(q => q.id);
        const transformationResponses = analysisResults.sectionResults.flatMap(s => s.data.map(d => d.responses)).flat().filter(item => transformationQuestionIds.find(q => q === item.questionId && item.isUserInput));

        // Get the unique user ids of any user with one or more responses.
        const uniqueResponseUserIds = [...new Set(actionResponses.map(r => r.userId))];
        // Get the unique questionids of any question with one or more responses.
        const uniqueResponseQuestionIds = [...new Set(actionResponses.map(r => r.questionId))];
        const uniqueTransformationQuestionIds = [...new Set(transformationResponses.map(r => r.questionId))];

        // Create an array of names and ids for learners who have an action response
        const actionsLearners: Array<{ id: string, firstName: string, lastName: string }> = [];
        uniqueResponseUserIds.forEach(id => {
            const thisProfile = profiles?.find(p => p.userId === id);

            if (!!thisProfile) {
                actionsLearners.push({ id: thisProfile.userId, firstName: thisProfile.firstName, lastName: thisProfile.lastName });
            }
        });

        if (!!actionsLearners.length) {
            actionsLearners.sort((a, b) => {
                if (a.firstName === b.firstName) {
                    if (a.lastName === b.lastName) {
                        return 0;
                    } else if (a.lastName > b.lastName) {
                        return 1;
                    } else {
                        return -1;
                    }
                } else if (a.firstName > b.firstName) {
                    return 1;
                } else {
                    return -1;
                }
            })
        }

        const courseNames = !!learningUnit ? [learningUnit.name] : []

        return (
            <AnalyserReportPrintModal
                isOpen={reportNameModalIsOpen}
                toggle={toggleReportNameModal}
                caller={'Overview'}
                currencySymbol={subscription?.currencySymbol ?? '�'}
                questionnaireName={questionnaire?.name}
                analysisResults={analysisResults}
                providerNames={[]}
                courseNames={courseNames}
                courseTypes={[]}
                sectionNames={[]}
                categoryNames={[]}
                departmentNames={[]}
                learners={selectedLearnerDetails}
                actionQuestions={actionQuestions.filter(q => uniqueResponseQuestionIds.find(id => id === q.id))} // filter the questions to just those with responses
                actionResponses={actionResponses}
                transformationQuestions={transformationQuestions.filter(q => uniqueTransformationQuestionIds.find(id => id === q.id))} // filter the questions to just those with responses
                transformationResponses={transformationResponses}
                isAllLearners={allLearners}
                includeGraph={showGraph}
                setBulkReportRequests={setBulkReportRequests}
                toggleGeneratingReports={toggleGeneratingReports}
                autoGeneratedZipFileName={autoGeneratedZipFileName}
            />
        )
    }, [reportNameModalIsOpen, toggleReportNameModal, analysisResults,
        profiles, allLearners, showGraph, learningUnit, selectedLearnerDetails,
        setBulkReportRequests, toggleGeneratingReports, questionnaire?.name, subscription, autoGeneratedZipFileName]);

    const excludeSection = (sectionName: string, name: string) => {
        setSectionsList(sectionsList.filter(section => section.name !== name));
    }

    const excludeCategory = (sectionName: string, name: string) => {
        setCategoriesList(categoriesList.filter(category => category.name !== name));
    }

    const printAndGraphButtons = useMemo(() => {
        if (!analysisResults) {
            return null;
        }

        return (
            <>
                <Col xs="auto">
                    <ButtonGroup>
                        <PdfPrintButtons onClick={pdfPrintButtonOnClick} />
                        <Button className="imageButton d-flex justify-content-center align-items-center" onClick={e => { setCategoryGraphMode(!categoryGraphMode); setUseCategoriesList(true) }}>
                            <img className="graphImage" alt=''></img>
                        </Button>
                    </ButtonGroup>
                </Col>
            </>
        );
    }, [analysisResults, pdfPrintButtonOnClick, categoryGraphMode, setCategoryGraphMode,]);

    return (
        <Background>
            <Banner fluid className="analyser-header">
                <Row>
                    <Col>
                        <h1 className="analyser-heading">
                            {
                                t('questionnaireOverview.heading', 'Campaign')
                            }
                        </h1>
                        <ConditionalFragment showIf={campaignOverviewIsLoading}>
                            <LoadingIndicator size="lg" />
                        </ConditionalFragment>
                    </Col>

                </Row>
            </Banner>
            <Row className="main-questionnaire-overview-container">
                <AlertOnErrors errors={campaignOverviewErrors && profilesErrors} />
                <Col className={"d-none d-md-block"} lg={2}></Col>
                <Col xs={12} sm={6} lg={4} className="segment">
                    <div className="subSegment">
                        <h4 className="title analyser-heading" >
                            {questionnaire?.name}
                        </h4>
                        <FormGroup>
                            <Row>
                                <Col xs={6} md={4} >
                                    <b> {t('questionnaireOverview.learners-invited', 'Learners Invited')} </b>
                                </Col>
                                <Col xs={6} sm="auto">
                                    <Input className="border-grey" disabled value={totalInvited} />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col xs={6} md={4} >
                                    <b> {t('questionnaireOverview.learners-completed', 'Learners Completed')} </b>
                                </Col>
                                <Col xs={6} sm="auto">
                                    <Input className="border-grey" disabled value={completedProfilesAsTags?.length ?? 0} />
                                </Col>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button color="primary" onClick={() => sendAllQuestionnaireReminder(questionnaire?.id ?? "", questionnaire?.name ?? "", questionnaire?.numberOfDays ?? 0)}>
                                        {t('questionnaireOverview.send-reminder', 'Send Reminder')}
                                    </Button>
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <InformativeListModal
                                title="Completed Learners"
                                tags={completedProfilesAsTags ?? []}
                            />

                        </FormGroup>

                        <FormGroup>
                            <InformativeListModal
                                title="Non-Completed Learners"
                                tags={incompleteProfilesAsTags ?? []}
                            />
                        </FormGroup>
                    </div>
                    <div className="subSegment">
                        <h4 className="title analyser-heading">
                            {t('questionnaireOverview.results-criteria', 'Results Criteria')}
                        </h4>

                        <Row>
                            <Col xs={12} lg={6}>
                                <AnalyserCheckedListModal
                                    allSelected={allLearners}
                                    setAllSelected={setAllLearners}
                                    isSelected={selectedLearners}
                                    setSelected={setSelectedLearners}
                                    title={"Select Learners"}
                                    tags={completedProfilesAsTags ?? []}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <h4 className="title analyser-heading">
                            {t('questionnaireOverview.show-results-by', 'Show results by')}
                        </h4>

                        <Row>
                            <Col xs="auto">
                                {t('questionnaireOverview.category', 'Category')}
                            </Col>
                            <Col>
                                <Input type="checkbox" checked={useCategoriesList} onChange={() => setUseCategoriesList(!useCategoriesList)}></Input>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="text-center mt-2">
                                <Button color={"primary"} className="submit">
                                    <> {t('common.submit', 'Submit')}</>
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col xs={12} sm={6} lg={4} className="segment">
                    <div className="subSegment graph">
                        <Row>
                            <Col>
                                <h4 className="title analyser-heading">
                                    {t('questionnaireOverview.results', 'Results')}
                                </h4>
                            </Col>
                            {printAndGraphButtons}
                        </Row>

                        <ConditionalFragment showIf={showGraph}>
                            <Container className="resultsContainer">
                                <Row>
                                    <Col>
                                        <h4 className="title analyser-heading"> {t('questionnaireOverview.completions', 'Completions')} </h4>
                                    </Col>
                                    <Col xs={"auto"}>
                                        <Button className="analyser-imageButton-closeResults" onClick={() => setShowGraph(false)}>X</Button>
                                    </Col>
                                </Row>
                                <Row className="results-chart-container">
                                    <Col>
                                        <Chart key={chartKey} options={options} series={series} align="center" type="pie"></Chart>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                    <Col xs={7} md={4}>
                                        <b> {t('questionnaireOverview.total-invited', 'Total Invited')} </b>
                                    </Col>
                                    <Col>
                                        <Input className="border-grey" disabled value={totalInvited}></Input>
                                    </Col>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                </Row>
                                <Row>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                    <Col xs={7} md={4}>
                                        <b> {t('questionnaireOverview.total-completed', 'Total Completed')} </b>
                                    </Col>
                                    <Col>
                                        <Input className="border-grey" disabled value={completedProfilesAsTags?.length ?? 0}></Input>
                                    </Col>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                </Row>
                                <Row>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                    <Col xs={7} md={4}>
                                        <b> {t('questionnaireOverview.total-not-completed', 'Total Not Completed')} </b>
                                    </Col>
                                    <Col>
                                        <Input className="border-grey" disabled value={incompleteProfilesAsTags?.length ?? 0}></Input>
                                    </Col>
                                    <Col className="d-none d-lg-block" xs={2}></Col>
                                </Row>
                            </Container>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!showGraph}>
                            <Row>
                                <Col className="text-center mb-2">
                                    <Button color="primary" onClick={() => setShowGraph(true)}>
                                        <> {t('questionnaireOverview.show-graph', 'Show Graph')}</>
                                    </Button>
                                </Col>
                            </Row>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={graphModalOpen}>
                            <AnalyserSelectGraphTypeModal
                                isOpen={graphModalOpen}
                                toggle={toggleGraphModalOpen}
                                setGraphType={setCategoryGraphFormat}
                            />
                        </ConditionalFragment>
                    </div>
                    <div className="subSegment">
                        <Row>
                            <Col>
                                <h4 className="title analyser-heading">
                                    {t('questionnaireOverview.sections', 'Sections')}
                                </h4>
                            </Col>
                            {printAndGraphButtons}
                        </Row>
                        <DndContext onDragEnd={reorderSections}>
                            <ul>
                                <SortableContext items={sectionsList.map(section => section.id as string) ?? []}>
                                    {
                                        sectionsList.map(sectionListItem => (
                                            <DraggableSectionComponent
                                                key={sectionListItem.id as string}
                                                sectionListItem={sectionListItem}
                                                exclude={excludeSection}
                                                toggleIncludeInGraph={setGraphCategories} //Not applicable but required to keep the prop in a certain state: can't invoke methods that might be undefined
                                            />
                                        ))
                                    }
                                </SortableContext>
                            </ul>
                        </DndContext>
                    </div>
                    <ConditionalFragment showIf={useCategoriesList}>
                        <div className="subSegment">
                            <Row>
                                <Col>
                                    <h4 className="title analyser-heading">
                                        {t('questionnaireOverview.categories', 'Categories')}
                                    </h4>
                                </Col>
                                {printAndGraphButtons}
                            </Row>
                            <DndContext onDragEnd={reorderCategories}>
                                <ul>
                                    <SortableContext items={categoriesList.map(category => category.id as string) ?? []}>
                                        {
                                            categoriesList.map(sectionListItem => {
                                                const resultItem = analysisResults?.categoryResults?.find(it => it.analysisType.id === sectionListItem.id);
                                                return (
                                                    <DraggableSectionComponent
                                                        key={sectionListItem.id as string}
                                                        sectionListItem={sectionListItem}
                                                        exclude={excludeCategory}
                                                        graphMode={categoryGraphMode}
                                                        includeInGraph={graphCategories(sectionListItem.id as string)}
                                                        toggleIncludeInGraph={setGraphCategories}
                                                        change={resultItem !== undefined ? resultItem.data.arrow : undefined}
                                                        figure={resultItem !== undefined ? resultItem.data.result : 0}
                                                    />
                                                )
                                            })
                                        }
                                    </SortableContext>
                                </ul>
                            </DndContext>
                            <ConditionalFragment showIf={categoryGraphMode && !!categoryChartData[0].data.length}>
                                <Row>
                                    <Button color={"primary"} className="submit" onClick={e => { toggleGraphModalOpen(true); setIncludeCategoryGraph(true); setIncludeCategoryGraph(true); setCategoryGraphMode(!categoryGraphMode) }} disabled={!profiles}>
                                        {t('questionnaireOverview.createGraph', 'Create Graph')}
                                    </Button>
                                </Row>
                            </ConditionalFragment>
                        </div>

                        <ConditionalFragment showIf={includeCategoryGraph}>
                            <div className="subSegment">
                                <Row>
                                    <Col>
                                        <h4 className="analyser-heading">
                                            {t('analyser.rightSegment.heading.graphResults', 'Graph Results')}
                                        </h4>
                                    </Col>
                                    {printAndGraphButtons}
                                </Row>
                                <Row>
                                    <Container className="resultsContainer">
                                        <div>
                                            <b className="sub-title" onClick={() => setIncludeCategoryGraph(false)}>X</b>
                                        </div>
                                        <Chart key={categoryChartKey} options={categoryOptions} series={categorySeries} type={'bar'}></Chart>
                                    </Container>
                                </Row>
                            </div>
                        </ConditionalFragment>
                    </ConditionalFragment>
                </Col>
            </Row>

            {analyserNode}

            <ConditionalFragment showIf={isGeneratingReports}>
                <BulkReportGenerator
                    isOpen={isGeneratingReports}
                    toggle={() => toggleGeneratingReports()}
                    passedInAutoGeneratedZipFileName={autoGeneratedZipFileName}
                    requests={bulkReportRequests}
                />
            </ConditionalFragment>

        </Background>
    );
};

function useCategoryChartData(data: { analysisType: AnalysisType, data: { result: number, arrow?: 'up' | 'down' } }[]): Array<ChartDataSeries> {

    const { t } = useTranslation();

    const generateSeries = useCallback(() => {
        const ret = data.map((item) => {
            const arrow = item.data.arrow;
            return {
                text: t('analyser.selectionChart.key', item.analysisType.name),
                value: arrow === 'down' ? -item.data.result : item.data.result,
            }
        })

        return {
            name: "",
            data: ret,
        } as ChartDataSeries;

    }, [data, t])

    const ret = useMemo(() => {
        return [generateSeries()];
    }, [generateSeries])

    return ret;
}