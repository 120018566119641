import { useMemo } from "react";
import { Link } from "react-router-dom";
import { Button, Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { Subscription } from "../../../api/main/models/Subscription";
import { AdministrationDashboardTabs } from "../administrationDashboard/AdministrationDashboard";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { useCurrencyAnalysisChartData } from "../chartData/useCurrencyAnalysisChartData";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { CurrencyAnalysisRadarChart } from "../charts/CurrencyAnalysisRadarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface SubscriptionTabProps {
    subscriptions: Array<Subscription>,

    campaignSessions: Array<QuestionnaireRespondentSession>,
    selfServiceSessions: Array<QuestionnaireRespondentSession>,
    graphSessions: Array<QuestionnaireRespondentSession>,
    campaignResponses: Array<QuestionResponse>,
    selfServiceResponses: Array<QuestionResponse>,

    activeQuestionnaireType: string,

    changeTab: (tabName: AdministrationDashboardTabs) => void,

    questions: Array<Question>,

}

/**
 * Subscriptions tab on the dashboard.
 * @param props
 */
export const SubscriptionsDashboardTab = (props: SubscriptionTabProps) => {
    const {
        subscriptions,
        campaignSessions,
        selfServiceSessions,
        graphSessions,
        campaignResponses,
        selfServiceResponses,
        activeQuestionnaireType,
        changeTab,
        questions,
    } = props;

    // Method we use to filter sessions by subscription wherever we need it.
    //const filterBySubscription = useCallback((subscription: { id: string }, item: { subscriptionId: string | undefined | null }) => {
    //    if (!subscription.id) {
    //        return !item.subscriptionId;
    //    }

    //    return item.subscriptionId === subscription.id;
    //}, []);

    const subscriptionGroups = useMemo(() => [...(subscriptions?.map(item => ({ id: item.id, name: item.name })) ?? [])], [subscriptions,]);

    const allResponses = campaignResponses.concat(selfServiceResponses);

    const currencyResponses = useMemo(() => {
        if (!questions || !allResponses) {
            return [];
        }

        const currencyQuestionKeys = [...questions ?? []].filter(item => item.responseType === QuestionResponseType.CurrencyAmount).map(item => item.id);
        const neededResponses = [...allResponses ?? []].filter(item => currencyQuestionKeys.includes(item.questionId));
        return neededResponses;
    }, [ questions, allResponses,]);

    // Get data for the various charts.
    const currencyAnalysisChartData = useCurrencyAnalysisChartData(subscriptionGroups, graphSessions, currencyResponses, 'Administrator');
    const completionChartData = useCompletionChartData(subscriptionGroups, graphSessions, 'Administrator');
    
    const baseRoute = useDashboardBaseRoute();

    return (
        <Row style={{ paddingTop: '15px' }}>
            <Col xs={12} xl="" >
                <ListGroup className="mb-4">
                    {
                        subscriptions.map(subscription => (
                            <DashboardListGroupItem
                                key={subscription.id}
                                campaignsNotStarted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionId === subscription.id && !item.startDate) : []}
                                campaignsStarted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionId === subscription.id && item.startDate && !item.endDate) : []}
                                campaignsCompleted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionId === subscription.id && item.endDate) : []}
                                selfServicesNotStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionId === subscription.id && !item.startDate) : []}
                                selfServicesStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionId === subscription.id && item.startDate && !item.endDate) : []}
                                selfServicesCompleted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionId === subscription.id && item.endDate) : []}
                                activeQuestionnaireType={activeQuestionnaireType}
                                subscriptionId={subscription.id}
                            >
                                {
                                    subscription.id ? (
                                        <Link to={`${baseRoute}/subscription/${subscription.id}`}>
                                            <ListGroupItemHeading>
                                                {subscription.name}
                                            </ListGroupItemHeading>
                                        </Link>
                                    ) : (
                                        <Button color="link" onClick={() => changeTab('departments')} style={{ padding: '0px' }}>
                                            <ListGroupItemHeading>
                                                {subscription.name}
                                            </ListGroupItemHeading>
                                        </Button>
                                    )
                                }
                            </DashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col xs={12} xl="">
                <CurrencyAnalysisRadarChart data={currencyAnalysisChartData} />
                <CompletionBarChart data={completionChartData} />
            {/*    <CompletionOverTimeAreaChart data={completionOverTimeChartData} assessmentType={activeAssessmentType} />*/}
            </Col>
        </Row>
        );
};