import gql from "graphql-tag";
import { Guid } from "guid-string";
import { questionnaireFields } from "../generated/questionnaireFields";
import moment from "moment";

export const questionnaireFieldsFragment = gql`
    fragment questionnaireFields on Questionnaire {
        id
        name
        subscriptionId
        subscriptionDepartmentId
        isTemplate
        questionnaireType
        startDate
        endDate
        numberOfDays
        description
        templateDescription
        archived
        reminderType
        isPublished
    }
`;

export type Questionnaire = Omit<questionnaireFields, '__typename'>;

export const questionnaireDefaultValues = (): Partial<Questionnaire> => ({
    id: Guid.newGuid(),
    name: '',
    subscriptionId: null,
    subscriptionDepartmentId: null,
    isTemplate: false,
    questionnaireType: '',
    startDate: moment().add(1, "months"),
    endDate: null,
    numberOfDays: 0,
    description: '',
    templateDescription: '',
    archived: false,
    reminderType: 'Standard',
    isPublished: false
});