import { Profile } from "../../../api/main/models/Profile";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Col, ListGroup, ListGroupItemHeading, Row } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { CurrencyAnalysisRadarChart } from "../charts/CurrencyAnalysisRadarChart";
import { QuestionnaireDashboardListGroupItem } from "../listGroups/QuestionnaireDashboardListGroupItem";
import { useCompletionChartDataForQuestionnaires } from "../chartData/useCompletionChartDataForQuestionnaires";
import { useCurrencyAnalysisChartDataForQuestionnaires } from "../chartData/useCurrencyAnalysisChartDataForQuestionnaires";
import { Link } from "react-router-dom";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface QuestionnaireDashboardTabProps {
    profiles: Array<Profile>,
    sessions: Array<QuestionnaireRespondentSession>,
    responses: Array<QuestionResponse> ,
    questions: Array<Question>,

}

/**
 * Subscriptions tab on the dashboard.
 * @param props
 */
export const QuestionnaireDashboardTab = (props: QuestionnaireDashboardTabProps) => {
    const {
        profiles,
        sessions,
        responses,
        questions,
    } = props;

    const { t } = useTranslation();
    const users = useMemo(() => profiles.map(profile => ({
        id: profile.userId,
        name: t('common.fullName', '{{firstName}} {{lastName}}', profile),
        subscriptionId: profile.subscriptionId,
        subscriptionTeamId: profile.subscriptionDepartmentId,
    })), [profiles, t]);

    const allResponses = responses;

    const currencyResponses = useMemo(() => {
        if (!questions || !allResponses) {
            return [];
        }

        const currencyQuestionKeys = [...questions ?? []].filter(item => item.responseType === QuestionResponseType.CurrencyAmount).map(item => item.id);
        const neededResponses = [...allResponses ?? []].filter(item => currencyQuestionKeys.includes(item.questionId));
        return neededResponses;
    }, [questions, allResponses,]);

    // Get data for the various charts.
    const currencyAnalysisChartData = useCurrencyAnalysisChartDataForQuestionnaires(users, sessions, currencyResponses, 'Questionnaire');
    const completionChartData = useCompletionChartDataForQuestionnaires(users, sessions, 'Questionnaire');

    const baseRoute = useDashboardBaseRoute();

    return (
        <Row style={{ paddingTop: '15px' }}>
            <Col xs={12} lg="">
                <ListGroup className="mb-4">
                    {
                        users.map(user => (
                            <QuestionnaireDashboardListGroupItem
                                key={user.id}
                                sessionsNotStarted={!!sessions ? sessions.filter(item => item.userId === user.id && !item.startDate) : []}
                                sessionsStarted={!!sessions ? sessions.filter(item => item.userId === user.id && item.startDate && !item.endDate) : []}
                                sessionsCompleted={!!sessions ? sessions.filter(item => item.userId === user.id && item.endDate) : []}
                            >
                                <ListGroupItemHeading>
                                    <Link to={`${baseRoute}/department/user/${user.id}`}>
                                        {user.name}
                                    </Link>
                                </ListGroupItemHeading>
                            </QuestionnaireDashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col>
                <CurrencyAnalysisRadarChart data={currencyAnalysisChartData} />
                <CompletionBarChart data={completionChartData} />
                {/*    <CompletionOverTimeAreaChart data={completionOverTimeChartData} assessmentType={activeAssessmentType} />*/}
            </Col>
        </Row>
    );
};