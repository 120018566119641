import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { LinkContainer } from 'react-router-bootstrap';
import { Button, ButtonGroup, } from 'reactstrap';
import { useCurrentUserRoles } from '../../../api/account';
import { useSubscriptionDepartment } from '../../../api/main/subscriptionDepartments/useSubscriptionDepartment';
import { IdentityRoles } from '../../../configure/security/IdentityRoles';
import { RegisteredUsersListBase } from '../../users/RegisteredUsersListBase';

/**
 * Add users to a department from a list of all users not in this department - excludes anybody with a security level above this one.
 */
export const AddDepartmentUsersList = () => {
    const { t } = useTranslation();
    const { departmentId } = useParams<{ departmentId: string }>();
    const { data: { model } } = useSubscriptionDepartment(departmentId);
    const { data: { roles: userRoles } } = useCurrentUserRoles();

    const baseRoute = useMemo(() => {
        let userLevel = 'User';
        if (userRoles?.find(it => it === IdentityRoles.SubscriptionDepartmentAdministration)) {
            userLevel = 'DepartmentAdministrator';
        }
        if (userRoles?.find(it => it === IdentityRoles.SubscriptionAdministration)) {
            userLevel = 'SubscriptionAdministrator';
        }
        if (userRoles?.find(it => it === IdentityRoles.Administration)) {
            userLevel = 'Administrator';
        }

        switch (userLevel) {
            case 'DepartmentAdministrator':
                return '/manageDepartment/users';
            case 'SubscriptionAdministrator':
                return '/manage/subscriptionDepartments/users';
            case 'Administrator':
                return '/manage/subscriptionDepartments/users';
            default:
                return '';
        }
    }, [userRoles]);

    const customListItemButtons = useCallback((itemId: string, baseRoute: string, departmentId: string) => {
        return (
            <ButtonGroup>
                <LinkContainer to={`${baseRoute}/${departmentId}/users/addFromList/${itemId}`}>
                    <Button color="primary" outline>
                        <FontAwesomeIcon icon="edit" />
                        <> {t('common.edit', 'Add to this department')}</>
                    </Button>
                </LinkContainer>
            </ButtonGroup>
        )
    }, [t]);


    return (
        <RegisteredUsersListBase
            isExcludeDepartment={true}
            title={t('addDepartmentUsersList.title', `Add people to ${model?.name ?? 'this department'} from this list`)}
            baseRoute={baseRoute}
            addButtons={<></>}
            customListItemButtons={customListItemButtons}
        />
    );
};
