import './sortOptionRadioButton.scss';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface SortOptionRadioButtonProps {
    sortName: string,
    setSortOrder: React.Dispatch<React.SetStateAction<string>>,
    labelText: string,
    isChecked:boolean,
}

export const SortOptionRadioButton = (props: SortOptionRadioButtonProps) => {
    const {
        sortName, setSortOrder, labelText, isChecked,
    } = props;

    return (
        <label className={'sort-option'}>
            <input
                type="radio"
                value={sortName}
                name={'sort-' + sortName}
                checked={isChecked}
                onChange={e => { setSortOrder(sortName) }}
            /><> </>{labelText}
        </label>
        );
};

// set up a sort option radio button
export function GetSortOptionRadioButton(sortName: string, sortOrder: string | undefined, defaultSortOrder: string, setSortOrder: React.Dispatch<React.SetStateAction<string>>): React.ReactNode {
    const { t } = useTranslation();

    if (!sortName) {
        return (<></>);
    }

    // set up a label from the sortName by capitalising and adding spaces
    const labelTextWords = sortName.split(/(?=[A-Z])/);

    let labelText = '';

    labelTextWords.forEach((word, index) => {
        if (index === 0) {
            // first word will have a lower case start letter
            labelText += word.charAt(0).toUpperCase() + word.slice(1);
        } else {
            labelText += ' ' + word;
        }
    });

    // return a standard sort option button
    return (
        <SortOptionRadioButton
            sortName={sortName}
            setSortOrder={setSortOrder}
            labelText={t(`questionnaireUsersListBase.sortOption.${sortName}`, labelText)}
            isChecked={(!!sortOrder && sortOrder === sortName) || (!sortOrder && defaultSortOrder === sortName)}
        />
    )
};
