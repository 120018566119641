import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { RouteEntry } from "../../shared/routes";
import { AdministrationDashboard } from "./administrationDashboard/AdministrationDashboard";
import { StaffUserDashboard } from "./administrationDashboard/StaffUserDashboard";
import { Analyser } from "./analyser/Analyser";
import { QuestionnaireDashboard } from "./questionnaireDashboard/QuestionnaireDashboard";
import { SubscriptionDashboard } from "./subscriptionDashboard/SubscriptionDashboard";
import { SubscriptionDepartmentDashboard } from "./subscriptionDashboard/SubscriptionDepartmentDashboard";

export const dashboardRoutes: Array<RouteEntry> = [
    { path: '/analyser', exact: true, component: Analyser, authorize: true },
    { path: '/dashboard/department/:subscriptionDepartmentId', exact: true, component: SubscriptionDepartmentDashboard, authorize: true },
    { path: '/dashboard/department/user/:userId', exact: true, component: StaffUserDashboard, authorize: true },
    //{ path: '/dashboard/assessment/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true },
    //{ path: '/dashboard/training/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true },

    { path: '/administration/dashboard', exact: true, component: AdministrationDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId', exact: true, component: SubscriptionDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/department/:subscriptionDepartmentId', exact: true, component: SubscriptionDepartmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/user/:userId', exact: true, component: StaffUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
    { path: '/administration/dashboard/subscription/:subscriptionId/department/user/:userId', exact: true, component: StaffUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/subscription/:subscriptionId/team/:subscriptionTeamId', exact: true, component: SubscriptionTeamDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/subscription/:subscriptionId/user/:userId', exact: true, component: SubscriptionUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/staff/:userId', exact: true, component: StaffUserDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/assessment/:assessmentId', exact: true, component: AdministrationAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/training/:assessmentId', exact: true, component: AdministrationAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/subscription/:subscriptionId/assessment/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },
//    { path: '/administration/dashboard/subscription/:subscriptionId/training/:assessmentId', exact: true, component: SubscriptionAssessmentDashboard, authorize: true, requireRole: IdentityRoles.Administration, },

    { path: '/administration/dashboard/subscription/:subscriptionId/questionnaires/:type/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration, },
    { path: '/dashboard/subscription/:subscriptionId/questionnaires/:type/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration, },


    { path: '/administration/dashboard/subscription/:subscriptionId/department/:subscriptionDepartmentId/questionnaires/:type/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration, },
    { path: '/dashboard/subscription/:subscriptionId/department/:subscriptionDepartmentId/questionnaires/:type/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration, },

    { path: '/administration/dashboard/subscription/:subscriptionId/questionnaire/:questionnaireId/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration, },
    { path: '/dashboard/subscription/:subscriptionId/questionnaire/:questionnaireId/', exact: true, component: QuestionnaireDashboard, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration, },

];
