import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionResponseFields } from "../generated/questionResponseFields";

export const questionResponseFieldsFragment = gql`
    fragment questionResponseFields on QuestionResponse {
        id
        questionId
        userId
        questionnaireId
        questionnaireRespondentSessionId
        eventDate
        cancelEventDate
        reinforcementText
        archived
        amountInput
        yesNoInput
        textInput
        displayOrder
        isUserInput
    }
`;


export type QuestionResponse = Omit<questionResponseFields, '__typename'>;

export const questionResponseDefaultValues = (): Partial<QuestionResponse> => ({
    id: Guid.newGuid(),
    questionId: '',
    userId: '',
    questionnaireId: '',
    questionnaireRespondentSessionId: '',
    eventDate: moment(),
    cancelEventDate: null,
    reinforcementText: '',
    archived: false,
    amountInput: 0,
    yesNoInput: null,
    textInput: '',
    displayOrder: moment().unix(),
    isUserInput: false,
});