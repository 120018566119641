import { useState, } from "react";
import { Button, Row, Col, Spinner, ButtonGroup, } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useAsyncCallback } from 'react-use-async-callback';
import { Background } from '../../shared/background/Background';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { ConditionalFragment } from "react-conditionalfragment";
import { useUploadBlobCallback } from "../../../api/main/blobReferences/useUploadBlobCallback";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { useImportQuestionnairesUsersCallback } from "../../../api/main/users/useImportQuestionnairesUsersCallback";
import { importUsersMutation_importUsers } from "../../../api/main/generated/importUsersMutation";
import FileSaver from "file-saver";
import "./questionnaireUsersImport.scss";
import { useHistory } from "react-router";


export interface QuestionnairesUsersImportProps {
    questionnaireId: string,
    departmentId?: string,
    baseRoute?: string,
    isCreate: boolean,
}

/**
 * Add a participant to a questionnaire.
 */
export const QuestionnaireUsersImport = (props: QuestionnairesUsersImportProps) => {

    const {
        questionnaireId,
        departmentId,
        baseRoute,
        isCreate,
    } = props;


    const { t } = useTranslation();

    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const history = useHistory();


    // Uploading blobs.
    const [uploadBlob, { errors: uploadBlobErrors }] = useUploadBlobCallback();

    //Import users from a blob.
    const [importUsers, { errors: importUsersErrors }] = useImportQuestionnairesUsersCallback();

    //Results from an import.
    const [importResults, setImportResults] = useState<Array<importUsersMutation_importUsers> | undefined>(undefined);

    //Perform the actual import.
    const [performImport, { isExecuting: isImporting, errors: performImportErrors }] = useAsyncCallback(async (files: FileList | null) => {
        if (!files) {
            return;
        }

        // Upload the file.
        const blob = await uploadBlob(files);
        if (!blob) {
            return;
        }

        // Import from the upload.
        const results = await importUsers({ blobId: blob.id, subscriptionId: subscriptionId ?? '', subscriptionDepartmentId: departmentId ?? null, questionnaireId: questionnaireId ?? '' });
        setImportResults(results);

        if (!!isCreate) {
            window.location.replace(`${baseRoute}create/${questionnaireId}/people`)
        } else if (!isCreate) {
            window.location.replace(`${baseRoute}edit/${questionnaireId}/people`)
        }

    }, [uploadBlob, subscriptionId, setImportResults, departmentId, history]);

    const downloadSampleImportFile = () => {
        FileSaver.saveAs(
            process.env.PUBLIC_URL + "/SampleImportUsersFile.xlsx",
            "SampleImportUsersFile.xlsx"
        );
    };

    return (
        <Background>
            <MainContainer className="questionnaire-users-import-main-container">
                <AlertOnErrors errors={[uploadBlobErrors, importUsersErrors, performImportErrors]} />
                <ConditionalFragment showIf={!importResults?.length}>
                    <h5>
                        {t('questionnaireUsersImport.instructions.p1', 'Users can be imported from a file instead of being set up manually.')}
                    </h5>
                    <p>
                        {t('questionnaireUsersImport.instructions.p2', 'The file can be in Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) format and must contain the correct columns as outlined below.')}
                    </p>
                    <p>
                        {t('questionnaireUsersImport.instructions.p3', 'The file can contain the following columns (in any order).  Other columns will be ignored:')}
                    </p>
                    <ul>
                        <li>{t('questionnaireUsersImport.columns.email', 'Email (Required).')}</li>
                        <li>{t('questionnaireUsersImport.columns.firstName', 'First name (Optional).')}</li>
                        <li>{t('questionnaireUsersImport.columns.lastName', 'Last name (Optional).')}</li>
                        <li>{t('questionnaireUsersImport.columns.department', 'Department (Optional - If omitted any new users will not be added to a department).')}</li>
                        <li>{t('questionnaireUsersImport.columns.password', 'Password (Optional - If omitted user will be asked to enter their password when first accessing the system).')}</li>
                        <li>{t('questionnaireUsersImport.columns.lineManager.name', 'Line Manager Name (Optional).')}</li>
                        <li>{t('questionnaireUsersImport.columns.lineManager.email', 'Line Manager Email (Optional).')}</li>
                    </ul>

                </ConditionalFragment>

                <ConditionalFragment showIf={!!importResults?.length}>
                    <div className="import-results">
                        <h5>
                            {t('importUsersBase.results.summary', '{{count}} users have been imported for you.', { count: importResults?.filter(it => it.successful)?.length ?? 0 })}
                        </h5>
                        {
                            importResults?.map(item => (
                                <div key={item.rowNumber} className={item.successful ? 'text-success' : 'text-danger'}>
                                    <Row>
                                        <ConditionalFragment showIf={item.rowNumber !== -1 /* Whole file errors. */}>
                                            <Col xs={12} md="auto">
                                                {t('importUsersBase.results.rowNumber', 'Row {{rowNumber}}', { rowNumber: item.rowNumber })}
                                            </Col>
                                        </ConditionalFragment>
                                        <Col>
                                            <ConditionalFragment showIf={item.successful}>
                                                {t('importUsersBase.results.successful', 'User created for {{email}}', { email: item.profile?.user?.email })}
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={!!item.errors.length}>
                                                <ConditionalFragment showIf={item.errors.length === 1}>
                                                    {
                                                        item.errors.map((error, index) => (
                                                            <div key={index}>
                                                                {error}
                                                            </div>
                                                        ))
                                                    }
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={item.errors.length > 1}>
                                                    <ul>
                                                        {
                                                            item.errors.map((error, index) => (
                                                                <li key={index}>
                                                                    {error}
                                                                </li>
                                                            ))
                                                        }
                                                    </ul>
                                                </ConditionalFragment>
                                            </ConditionalFragment>
                                        </Col>
                                    </Row>
                                    <hr />
                                </div>
                            ))
                        }
                    </div>
                </ConditionalFragment>
                <div className="text-center">
                    <ButtonGroup>
                        <FileUploadButton onUpload={files => performImport(files)} isExecuting={isImporting} outline={false}
                            executingChildren={<><Spinner size="sm" /><> </>{t('questionnaireUsersImport.importing', 'Importing...')}</>}
                        >
                            <FontAwesomeIcon icon="upload" className="nav-icon" />
                            <> </>
                            {t('importUsersBase.import', 'Import file...')}
                        </FileUploadButton>
                        <Button onClick={downloadSampleImportFile} color="primary" outline>
                            <FontAwesomeIcon icon="download" className="nav-icon" />
                            <> </>
                            {t('importUsersBase.downloadSampleFile', 'Download sample file')}
                        </Button>
                    </ButtonGroup>
                </div>
            </MainContainer>
        </Background>
    );
};
