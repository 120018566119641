import { EditQuestionnaireBase } from "../../questionnaires/EditQuestionnaireBase";

/**
 * Edit a subscription questionnaire 
 */
export const SubscriptionEditQuestionnaire = () => {

    return (
        <EditQuestionnaireBase
            isSubscription={true}
        />
    );
};