import { useServices } from 'inject-typesafe-react';
import { AppServices } from '../../../configure/configureServices';
import { useAsyncCallback } from 'react-use-async-callback';
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { BlobUploadService } from './BlobUploadService';
import { mainApiConfig } from '../../../configure/mainApiConfig';
import { BlobReference } from '../models/BlobReference';

/**
 * Get a callback to upload a data: url into a new blob.
 */
export function useUploadBlobFromBlobObjectCallback(): [(filename: string, blob: globalThis.Blob) => Promise<BlobReference | null>, AsyncActionStatus] {
    const apiFetch = useServices((services: AppServices) => services.apiFetch());

    const [action, status] = useAsyncCallback(async (filename: string, blob: globalThis.Blob) => {
        const blobUploadService = new BlobUploadService(mainApiConfig.blobUploadEndpoint, apiFetch);

        // Upload the file into a blob.
        const ret = await blobUploadService.uploadBlobObject(filename, blob);
        return ret;
    }, [apiFetch]);

    return [action, status];
}
