import gql from "graphql-tag";
import { Guid } from "guid-string";
import { questionnaireRespondentSessionFields } from "../generated/questionnaireRespondentSessionFields";

export const questionnaireRespondentSessionFieldsFragment = gql`
    fragment questionnaireRespondentSessionFields on QuestionnaireRespondentSession {
        id
        questionnaireId
        userId
        subscriptionId
        subscriptionDepartmentId
        archived
        startDate
        endDate
        lineManagerName
        lineManagerEmail
        email
    }
`;


export type QuestionnaireRespondentSession = Omit<questionnaireRespondentSessionFields, '__typename'>;

export const questionnaireRespondentSessionDefaultValues = (): Partial<QuestionnaireRespondentSession> => ({
    id: Guid.newGuid(),
    questionnaireId: undefined,
    userId: '',
    subscriptionId: undefined,
    subscriptionDepartmentId: null,
    archived: false,
    startDate: null,
    endDate: null,
    lineManagerName: '',
    lineManagerEmail: '',
    email: '',
});