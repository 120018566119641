import { useEffect, } from "react";
import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col, FormText } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { useProfile } from '../../api/main/profiles/useProfile';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useChanges } from '../../shared/useChanges';
import { useSaveProfileCallback } from '../../api/main/profiles/useSaveProfileCallback';

/**
 * Add a user (profile) to a department from the list of users not in the department.
 */
export const AddUserFromList = () => {
    const { t } = useTranslation();
    const { id, departmentId } = useParams<{ departmentId: string, id: string }>();
    const { data: { model: storeModel }, isLoading, errors: loadErrors } = useProfile(id);
    const { model, change, changes } = useChanges(storeModel);
    const [save, { errors: saveErrors }] = useSaveProfileCallback();
    const history = useHistory();

    // make the change - it will not get saved if the user cancels
    useEffect(() => {
        change({ subscriptionDepartmentId: departmentId });
    }, [change, departmentId]);

    const [onUpdateFormSubmit, { isExecuting: isRemoving, errors: updateFormErrors }] = useAsyncCallback(async () => {
        if (!model || !model.user) {
            return;
        }

        await save(model.id, changes, false);

        history.goBack();
    }, [ model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('addUserFromList.heading', 'Are you sure you want to add this user to the department?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, updateFormErrors, saveErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onUpdateFormSubmit(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('addUserFromList.firstName', 'First name')}</Label>
                                <Input name="firstName" type="text" readOnly plaintext value={model?.firstName ?? ''} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('addUserFromList.lastName', 'Last name')}</Label>
                                <Input name="lastName" type="text" readOnly plaintext value={model?.lastName ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label htmlFor="email">{t('addUserFromList.email', 'Email')}</Label>
                        <Input name="lastName" type="text" readOnly plaintext value={model?.user?.email ?? ''} />
                    </FormGroup>
                    <Row>
                        <Col>
                            <FormText>
                                {t('addUserFromList.explanationText','This action will add the user to this department. If they are already in another department they will be moved out of it.')}
                            </FormText>
                        </Col>
                    </Row>

                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.adding', 'Adding...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.add', 'Add')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
