import { Col } from "reactstrap";

export interface SectionQuestionDisplayProps {
    questionIndex: number,
    questionText: string,
}

/**
 * A component to interpret the html in the questiontext field and return it for dis[play on a section].
 * @param props
 */
export const SectionQuestionDisplay = (props: SectionQuestionDisplayProps) => {
    const {
        questionIndex,
        questionText,
    } = props;


    return (
        <Col xs={12} sm={5} className="question-text question-text-bold">
            {/* Display Question Text */}
            {`${questionIndex + 1}. ${questionText}` }
        </Col>
    );
};