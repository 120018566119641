import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Text, View } from "@react-pdf/renderer";
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionResponsePDFStyles } from "./QuestionResponsePDFStyles";


// Component for displaying a question's responses on a PDF report

export interface QuestionResponsePDFProps {
    response: QuestionResponse,
    
    displayText: string;
    preText: string;

    responseType: string;
}

export const QuestionResponsePDF = (props: QuestionResponsePDFProps) => {
    const {
        response,
        displayText,
        preText,
        responseType
    } = props;

    return (
        <View style={QuestionResponsePDFStyles.responseRow} wrap={false}>
            <View style={QuestionResponsePDFStyles.response} key={response.id}>
                <ConditionalFragment showIf={!!preText.length}>
                    <Text style={responseType === QuestionResponseType.TextBoxes ? QuestionResponsePDFStyles.preTextTextBox : QuestionResponsePDFStyles.preTextScale}>{preText}</Text>
                </ConditionalFragment>
                <Text style={QuestionResponsePDFStyles.displayText}>{displayText}</Text>
            </View>
        </View>
    )
};