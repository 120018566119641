import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const AnalyserResponsesStyles = StyleSheet.create({
    responseContainer: {
        flexDirection: "row",
        marginBottom: "2mm",
    },
    responseText: {
        fontSize: 13,
        fontWeight: "normal",
    },
    responseTextBold: {
        fontWeight: "bold",
        paddingRight: "3mm",
    },
    pretext: {
        maxWidth: "25%",
    },
    responseWithPretext: {
        maxWidth: "75%",
    }
});

