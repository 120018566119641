import { useServices } from "inject-typesafe-react";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServices } from "../../../configure/configureServices";
import { AsyncActionStatus } from "../../../shared/abstractStore";

/*
 * Get a callback to send a questionnaire invite to every user on a questionnaire if the questionnaire is not published yet
 */
export function useSendAllQuestionnaireInviteCallback(): [(questionnaireId: string) => Promise<void>, AsyncActionStatus] {
    var api = useServices((services: AppServices) => services.apiFetch());

    // callback to get the questionnaireId
    const [callback, status] = useAsyncCallback(async (questionnaireId: string) => {
        await api.post(`/api/questionnaireInvite/sendAllInvite`, { questionnaireId: questionnaireId });
    }, [api]);

    return [callback, status];
}