import { Button, Col, Row, } from "reactstrap";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { ConditionalFragment } from "react-conditionalfragment";
import "./questionPickOne.scss";
import "./questionPresenter.scss";
import { useTranslation } from "react-i18next";
import { HtmlDisplay } from "../../../shared/htmlEditor";

export interface QuestionPickOneProps {
    question: Question,
    sessionId?: string,
    model: QuestionResponse,
    change: (changes: Partial<QuestionResponse>) => void,
    userInputDisabled?: boolean,
}

// Displays multiple possible responses and the user is allowed to choose only 1.
// if no sessionId is passed in we are previewing.

export const QuestionPickOne = (props: QuestionPickOneProps) => {
    const {
        question, sessionId, model, change, 
        userInputDisabled, 
    } = props;
    const { t } = useTranslation();

    return (
        <div className="question-pickone">
            <Row className="question-text">
                <Col xs={12}>
                    {HtmlDisplay({ html: question.questionText })}
                </Col>
                <Col className="question-sub-text text-muted" xs={12}>
                    {t("questionPickOne.instructions", "Please select one of the answers below")}
                </Col>
            </Row>
            <Row>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse1}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 1 ? 'active' : '')}
                            onClick={e => change({ amountInput: 1, isUserInput: true }) }>
                            {question.multipleChoiceResponse1}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse2}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 2 ? 'active' : '')}
                            onClick={e => change({ amountInput: 2, isUserInput: true })}>
                            {question.multipleChoiceResponse2}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse3}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 3 ? 'active' : '')}
                            onClick={e => change({ amountInput: 3, isUserInput: true })}>
                            {question.multipleChoiceResponse3}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse4}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 4 ? 'active' : '')}
                            onClick={e => change({ amountInput: 4, isUserInput: true })}>
                            {question.multipleChoiceResponse4}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse5}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 5 ? 'active' : '')}
                            onClick={e => change({ amountInput: 5, isUserInput: true })}>
                            {question.multipleChoiceResponse5}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse6}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 6 ? 'active' : '')}
                            onClick={e => change({ amountInput: 6, isUserInput: true })}>
                            {question.multipleChoiceResponse6}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse7}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 7 ? 'active' : '')}
                            onClick={e => change({ amountInput: 7, isUserInput: true })}>
                            {question.multipleChoiceResponse7}
                        </Button>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!question.multipleChoiceResponse8}>
                    <Col xs={12} sm={6} md={3}>
                        <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (model.amountInput === 8 ? 'active' : '')}
                            onClick={e => change({ amountInput: 8, isUserInput: true })}>
                            {question.multipleChoiceResponse8}
                        </Button>
                    </Col>
                </ConditionalFragment>
            </Row>
        </div>
    );
};