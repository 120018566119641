import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, CustomInput, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { QuestionnaireSection } from "../../../api/main/models/QuestionnaireSection";
import "./reportCustomiser.scss"
import { GetCustomiserLabelText, IsChildCustomiser } from "./utilities/reportCustomiserUtilities";

//* Customiser component for questionnaire reports
interface ReportCustomiserProps {
    sections: Array<QuestionnaireSection>
    handleSectionChange: (inputValue: string) => void
    setCustomiser: (customiser: string, setting: boolean) => void
    isCustomiserSet: (customiser: string) => boolean
    customiserNames: string[];
}

export const ReportCustomiser = (props: ReportCustomiserProps) => {
    const {
        sections,
        handleSectionChange,
        setCustomiser,
        isCustomiserSet,
        customiserNames,
    } = props;
    const { t } = useTranslation();

    return (
        <div className="customiser-container">
            <h3>{t("reportCustomiser.header", "Customise Your Report")}</h3>
            <FormGroup>
                <Label className={"section-selector-label"}>{t('reportSelector.section', 'Section')}</Label>
                <Input name="section" type="select" onChange={e => handleSectionChange(e.currentTarget.value)}>
                    <option key={null} value="">{t('reportSelector.sectionPlaceholder', 'All Sections')}</option>
                    {
                        sections?.map(item => (
                            <option key={item.id} value={item.id}>{item.name + t('reportPackageBuilder.sectionPlaceHolder', ' Report')}</option>
                        ))
                    }
                </Input>
                <FormText>
                    {t('reportCustomiser.sectionSelectorHelp', 'Select a report section for the report or leave as "All Sections" for the full report')}
                </FormText>
            </FormGroup>

            <div className="customiser-includes-container">
                <FormText className="mb-2">
                    {t('reportCustomiser.includesHelp', 'Choose the features to include in your report')}
                </FormText>
                {/* if the user deselects the summary we will hide all options relating to the summary */}
                {customiserNames.map((customiserName, index) => {
                    let isChildCustomiser = IsChildCustomiser(customiserName);
                    return (
                        <ConditionalFragment key={index} showIf={!isChildCustomiser.IsChild || isCustomiserSet(isChildCustomiser.ParentCustomiserName)}>
                            <Row>
                                <Col className={isChildCustomiser.IsChild ? "customiser-subsetting" : ""} xs={9} >
                                    <Label>{GetCustomiserLabelText(customiserName)}</Label>
                                </Col>
                                <Col xs="">
                                    <CustomInput type="switch" id={customiserName} label={isCustomiserSet(customiserName) ? t('common.yes', 'Yes') : t('common.no', 'No')}
                                        checked={isCustomiserSet(customiserName)} onChange={e => setCustomiser(customiserName, isCustomiserSet(customiserName) ? false : true)}
                                    />
                                </Col>
                            </Row>
                        </ConditionalFragment>
                    )
                })
                }
            </div>
        </div>
    );
};