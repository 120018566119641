import { Document, Page, View, Image, Text} from '@react-pdf/renderer';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Question } from '../../../api/main/models/Question';
import { QuestionResponse } from '../../../api/main/models/QuestionResponse';
import { AnalyserInterrogationResult } from '../../dashboard/analyser/AnalyserDataInterrogation';
import { ActionsPage } from './analyserReportComponents/ActionsPage';
import { AnalyserReportFooter } from './analyserReportComponents/AnalyserReportFooter';
import { AnalyserReportPage } from './analyserReportComponents/AnalyserReportPage';
import { AnalyserReportPageStyles } from './analyserReportComponents/analyserReportPageStyles';
import { DetailsPage } from './analyserReportComponents/DetailsPage';
import { PerformanceGraphPage } from './analyserReportComponents/PerformanceGraphPage';
import { PerformancePage } from './analyserReportComponents/PerformancePage';
import { TransformationPage } from './analyserReportComponents/TransformationPage';
import { AnalyserReportStyles } from './AnalyserReportPDFStyles';


interface AnalyserReportPDFProps {
    companyColor: string,
    currencySymbol: string,
    impactsLogoUrl: string,
    splashPageImage: string,
    reportTitle: string,
    questionnaireName?: string,
    reporterName: string,
    analysisResults: AnalyserInterrogationResult | undefined,
    providerNames: string[] | undefined,
    courseNames: string[] | undefined,
    courseTypes: string[] | undefined,
    sectionNames: string[] | undefined,
    categoryNames: string[] | undefined,
    departmentNames: string[] | undefined,
    actionsLearners: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }> | undefined,
    actionQuestions: Question[] | undefined,
    actionResponses: QuestionResponse[] | undefined,
    transformationQuestions?: Question[] | undefined,
    transformationResponses?: QuestionResponse[] | undefined,
    analysisTypeIconUrls?: { analysisTypeName: string, iconUrl: string }[] | undefined,
    chartImages?: string[],
    isAllLearners: boolean,
    isCampaignReport: boolean,
}

/* PDF report for the analyser */
export const AnalyserReportPdf = (props: AnalyserReportPDFProps) => {

    const { companyColor,
        currencySymbol,
        impactsLogoUrl, splashPageImage,
        reportTitle,
        questionnaireName = '',
        reporterName, analysisResults,
        providerNames = [],
        courseNames = [],
        courseTypes = [],
        sectionNames = [],
        categoryNames = [],
        departmentNames = [],
        actionsLearners = [],
        actionQuestions = [],
        actionResponses = [],
        transformationQuestions = [],
        transformationResponses = [],
        analysisTypeIconUrls = [],
        chartImages = [],
        isAllLearners,
        isCampaignReport,
    } = props;

    const formattedPrintDate = moment(new Date()).format("Do MMMM YYYY");

    const performancePageSubtitle = 'What results did you get and how did you know you were successful?';
    const transformationPageSubtitle = 'How have you improved before v after the programme?';

    return (
        <Document>
            {/*Splash Page*/}
            <Page size="A4" style={AnalyserReportPageStyles.reportPage} wrap={false}>
                <View style={AnalyserReportStyles.body}>
                    <View>
                        <Image style={AnalyserReportStyles.impactsImageMainPage} source={impactsLogoUrl} />
                    </View>

                    <View>
                        <Text style={AnalyserReportStyles.mainHeaderBlack}>{reporterName}</Text>
                    </View>

                    <View style={[AnalyserReportStyles.blueBlock,
                    {
                        backgroundColor: companyColor,
                    }]}>
                        <View style={AnalyserReportStyles.blueBlockTextContainer}>
                            <Text style={[AnalyserReportStyles.blueBlockText, AnalyserReportStyles.blueBlockTextLarge]}>{reportTitle}</Text>
                        </View>
                        <View style={[AnalyserReportStyles.blueBlockTextContainer, { marginTop: "10mm" }]}>
                            <Text style={[AnalyserReportStyles.blueBlockText, AnalyserReportStyles.blueBlockTextLarge]}>Analyser Report</Text>
                            <Text style={AnalyserReportStyles.blueBlockText}>{formattedPrintDate}</Text>
                        </View>
                    </View>

                    <View>
                        <Image style={AnalyserReportStyles.splashPageImage} source={splashPageImage} />
                    </View>

                </View>
                <AnalyserReportFooter impactsLogoUrl={impactsLogoUrl} isSplashPage={true} />
            </Page>

            {/*Report Page - Details*/}
            <AnalyserReportPage
                companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Details'} reportTitle={reportTitle}
                children={
                    <DetailsPage
                        reportTitle={reportTitle}
                        questionnaireName={questionnaireName}
                        numberOfLearners={analysisResults?.learnerResults.totalLearners ?? undefined}
                        totalCost={analysisResults?.learnerResults.totalCost ?? undefined}
                        costPerLearner={analysisResults?.learnerResults.costPerLearner ?? undefined}
                        providerNames={providerNames}
                        courseNames={courseNames}
                        courseTypes={courseTypes}
                        sectionNames={sectionNames}
                        categoryNames={categoryNames}
                        departmentNames={departmentNames}
                        learners={actionsLearners}
                        isAllLearners={isAllLearners}
                        isCampaignReport={isCampaignReport}
                        currencySymbol={currencySymbol}
                    />} />

            {/*Report Page - Performance */}
            <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Performance'} reportTitle={reportTitle} subTitle={performancePageSubtitle}
                children={
                    <PerformancePage
                        categoryResults={analysisResults?.categoryResults ?? []}
                        analysisTypeIconUrls={analysisTypeIconUrls}
                    />
                } />

            {/*Report Page - Performance Graph */}
            <ConditionalFragment showIf={chartImages.length > 0}>
                <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Performance'} reportTitle={reportTitle}
                    children={
                        <PerformanceGraphPage
                            chartImages={chartImages}
                        />
                    } />
            </ConditionalFragment>

            {/*Report Page - Actions */}
            <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Actions'} reportTitle={reportTitle}
                children={
                    <ActionsPage
                        companyColor={companyColor}
                        actionsLearners={actionsLearners}
                        actionQuestions={actionQuestions}
                        actionResponses={actionResponses}
                        currencySymbol={currencySymbol}
                    />
                } />

            {/*Report Page - Transformation */}
            <AnalyserReportPage companyColor={companyColor} impactsLogoUrl={impactsLogoUrl} pageHeader={'Transformation'} reportTitle={reportTitle} subTitle={transformationPageSubtitle}
                children={
                    <TransformationPage
                        companyColor={companyColor}
                        transformationQuestions={transformationQuestions}
                        transformationResponses={transformationResponses}
                    />
                } />
        </Document>
    );
};