import gql from "graphql-tag";
import { Guid } from "guid-string";
import { profileFields } from "../generated/profileFields";
import { userFieldsFragment } from "./User";

export const profileFieldsFragment = gql`
    fragment profileFields on Profile {
        id
        userId
        firstName
        lastName
        archived
        subscriptionId
        subscriptionDepartmentId

        user {
            ...userFields,
        }
    }
    ${userFieldsFragment}
`;


export type Profile = Omit<profileFields, '__typename' | 'user'>;

export const profileDefaultValues = (): Partial<Profile> => ({
    id: Guid.newGuid(),
    userId: '',
    firstName: '',
    lastName: '',
    archived: false,
    subscriptionId: null,
    subscriptionDepartmentId: null,
});