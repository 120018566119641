import * as React from 'react';
import { QuestionnaireCreateInput, QuestionnaireUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionnaireCallback } from './useCreateQuestionnaireCallback';
import { useUpdateQuestionnaireCallback } from './useUpdateQuestionnaireCallback';
import { Questionnaire } from '../models/Questionnaire';

/**
 * Get a callback to save a Questionnaire in the store using either a create or update.
 */
export function useSaveQuestionnaireCallback(options: SaveInStoreOptions<Questionnaire, string> = {}) {
    const [_create, createStatus] = useCreateQuestionnaireCallback();
    const create = React.useCallback((model: Partial<Questionnaire>) => _create(model as QuestionnaireCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionnaireCallback();
    const update = React.useCallback((id: string, changes: Partial<Questionnaire>) => _update(id, changes as QuestionnaireUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
