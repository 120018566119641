import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Profile } from "../../../api/main/models/Profile";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionDepartment } from "../../../api/main/models/SubscriptionDepartment";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { useCurrencyAnalysisChartData } from "../chartData/useCurrencyAnalysisChartData";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { CurrencyAnalysisRadarChart } from "../charts/CurrencyAnalysisRadarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface UsersDashboardTabProps {
    profiles: Array<Profile>,

    campaignSessions: Array<QuestionnaireRespondentSession>,
    selfServiceSessions: Array<QuestionnaireRespondentSession>,
    graphSessions: Array<QuestionnaireRespondentSession>,
    campaignResponses: Array<QuestionResponse>,
    selfServiceResponses: Array<QuestionResponse>,

    activeQuestionnaireType: string,

    showSubscriptionName?: boolean,
    subscriptions?: Array<Subscription>,
    subscriptionDepartments?: Array<SubscriptionDepartment>,

    questions: Array<Question>,
    linkToFolder?: string,
}

/**
 * User based tab on the dashboard.
 * @param props
 */
export const UsersDashboardTab = (props: UsersDashboardTabProps) => {
    const {
        profiles,
        campaignSessions,
        selfServiceSessions,
        graphSessions,
        campaignResponses,
        selfServiceResponses,
        activeQuestionnaireType,
        showSubscriptionName = false,
        subscriptions,
        subscriptionDepartments,
        linkToFolder = 'user',
        questions,
   } = props;

    const { t } = useTranslation();

    // Method we use to filter sessions by user wherever we need it.
    //const filterByUser = useCallback((user: { id: string }, item: { userId: string | undefined | null }) => {
    //    return item.userId === user.id;
    //}, []);

    // Profiles simplified to have a name and id (for which we use the userId).
    const users = useMemo(() => profiles.map(profile => ({
        id: profile.userId,
        name: t('common.fullName', '{{firstName}} {{lastName}}', profile),
        subscriptionId: profile.subscriptionId,
        subscriptionTeamId: profile.subscriptionDepartmentId,
    })), [profiles, t]);

    const allResponses = campaignResponses.concat(selfServiceResponses);

    const currencyResponses = useMemo(() => {
        if (!questions || !allResponses) {
            return [];
        }

        const currencyQuestionKeys = [...questions ?? []].filter(item => item.responseType === QuestionResponseType.CurrencyAmount).map(item => item.id);
        const neededResponses = [...allResponses ?? []].filter(item => currencyQuestionKeys.includes(item.questionId));
        return neededResponses;
    }, [questions, allResponses,]);

    const currencyAnalysisChartData = useCurrencyAnalysisChartData(users, graphSessions, currencyResponses, 'DepartmentAdministrator');
    const completionChartData = useCompletionChartData(users, graphSessions, 'DepartmentAdministrator');

    const baseRoute = useDashboardBaseRoute();

    return (
        <Row style={{ paddingTop: '15px' }}>
            <Col xs={12} xl="">
                <ListGroup className="mb-4">
                    {
                        users.map(user => {

                            // Also find the team and if available subscription for the profile.
                            const team = subscriptionDepartments?.find(it => it.id === user.subscriptionTeamId);
                            const subscription = subscriptions?.find(it => it.id === user.subscriptionId);

                            return (
                                <DashboardListGroupItem
                                    key={user.id}
                                    campaignsNotStarted={!!campaignSessions ? campaignSessions.filter(item => item.userId === user.id && !item.startDate) : []}
                                    campaignsStarted={!!campaignSessions ? campaignSessions.filter(item => item.userId === user.id && item.startDate && !item.endDate) : []}
                                    campaignsCompleted={!!campaignSessions ? campaignSessions.filter(item => item.userId === user.id && item.endDate) : []}
                                    selfServicesNotStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.userId === user.id && !item.startDate) : []}
                                    selfServicesStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.userId === user.id && item.startDate && !item.endDate) : []}
                                    selfServicesCompleted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.userId === user.id && item.endDate) : []}
                                    activeQuestionnaireType={activeQuestionnaireType}
                                    subscriptionId={user.subscriptionId ?? undefined}
                                    subscriptionDepartmentId={user.subscriptionTeamId ?? undefined}
                                    userId={user.id}

                                >
                                    <ListGroupItemHeading>
                                        <Link to={
                                            showSubscriptionName ? `${baseRoute}/subscription/${user.subscriptionId}/${linkToFolder}/${user.id}`
                                                : `${baseRoute}/department/${linkToFolder}/${user.id}`
                                        }>
                                            {user.name}
                                        </Link>
                                    </ListGroupItemHeading>
                                    <ListGroupItemText tag="div">
                                        <ConditionalFragment showIf={!!team}>
                                            <Link to={
                                                showSubscriptionName ? `${baseRoute}/subscription/${team?.subscriptionId}/department/${team?.id}`
                                                    : `${baseRoute}/department/${team?.id}`
                                            }>
                                                {team?.name}
                                            </Link>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={showSubscriptionName && !!subscription}>
                                            <> </>
                                            
                                            <Link to={`${baseRoute}/subscription/${subscription?.id}`}>
                                                {'(' /* Can/should this be made translatable? */}
                                                {subscription?.name}
                                                {')'}
                                            </Link>
                                            
                                        </ConditionalFragment>
                                    </ListGroupItemText>
                                </DashboardListGroupItem>
                            );
                        })
                    }
                </ListGroup>
            </Col>
            <Col xs={12} xl="">
                <CurrencyAnalysisRadarChart data={currencyAnalysisChartData} />
                <CompletionBarChart data={completionChartData} />
                {/*    <CompletionOverTimeAreaChart data={completionOverTimeChartData} assessmentType={activeAssessmentType} />*/}
            </Col>
       </Row>
    );
};