import { useCallback, useEffect, useMemo, useState} from "react";
import { Button, Row, Col, Label, FormGroup, Collapse, NavItem, NavLink, CardBody, FormText, Input, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { ModelArrayChanges, useChangesArray } from '../../shared/useChanges';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Question } from '../../api/main/models/Question';
import { QuestionType, questionTypeDisplayName, questionTypeIcon } from "../../api/main/models/codeOnly/QuestionType";
import { NavTiles } from "../shared/NavTiles";
import { QuestionResponseType, questionResponseTypeDescription, questionResponceTypeIcon, questionResponseTypeDisplayName, isResponseTypeForMeasure, isResponseTypeForText } from "../../api/main/models/codeOnly/QuestionResponseType";
import { useToggleState } from "use-toggle-state";
import { ValidationErrors } from "pojo-validator";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { useEditQuestionSupportingData } from "../../api/main/questions/viewModels/useEditQuestionSupportingData";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { QuestionPresenter } from "./questionPresenters/QuestionPresenter";
import { QuestionResponse } from "../../api/main/models/QuestionResponse";
import { useValidatorArrayCallback, ValidateModelCallback, ValidationErrorsForModel } from "../../shared/validator-react-contrib/useValidatorArrayCallback";
import "./editQuestion.scss";
import { ValidatedElasticInput } from "../../shared/validatedInputExtended";
import { CreateAnalysisTypeModal } from "../analysisTypes/CreateAnalysisTypeModal";
import { AnalysisType, analysisTypeDefaultValues } from "../../api/main/models/AnalysisType";
import { Guid } from "guid-string";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { QuestionnaireAnalysisTypeLink, questionnaireAnalysisTypeLinkDefaultValues } from "../../api/main/models/QuestionnaireAnalysisTypeLink";
import { ValidatedNumberInput } from "../shared/numberInput/ValidatedNumberInput";

interface EditQuestionProps {
    model: Question,
    change: (changes: Partial<Question>) => void,

    toggle: () => void,

    isCreate: boolean,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>,

    analysisTypeManager: ModelArrayChanges<AnalysisType, string>,
    validateAnalysisType: ValidateModelCallback<AnalysisType>,
    analysisTypeValidationErrors: ValidationErrorsForModel,

    questionnaireAnalysisTypeLinkManager: ModelArrayChanges<QuestionnaireAnalysisTypeLink, string>,
}

/**
 * Edit or create a question. This is wrapped in a modal - we never edit a question in isolation.
 * This is done within a questionnaire so we use a questionsManager
 */
export const EditQuestion = (props: EditQuestionProps) => {
    const {
        model,
        change,


        toggle,
        isCreate,
        validate,
        validationErrors,
        saveAllDeBounce,

        analysisTypeManager,
        validateAnalysisType,
        analysisTypeValidationErrors,

        questionnaireAnalysisTypeLinkManager,
    } = props;

    const { t } = useTranslation();

    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { subscription, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditQuestionSupportingData({ subscriptionId: currentUserSubscriptionId ?? undefined })
    const isLoading = isLoadingSupportingData;
    const questionTypes = Object.entries(QuestionType);
    const responseTypes = Object.entries(QuestionResponseType);

    const [questionTypeSelected, setQuestionTypeSelected] = useState<string>('');
    const [responseTypeSelected, setresponseTypeSelected] = useState<string>('');

    // filter the response types to only those that can be used for the question type selected
    const responseTypesToUse = useMemo(() => {
        if (isLoading || !questionTypeSelected || !responseTypes) {
            return;
        }

        if (questionTypeSelected === QuestionType.Measure) {
            return responseTypes.filter((responseType, index) => {

                // only use response types that can be used for measure questions
                if (isResponseTypeForMeasure(responseType[1])) {
                    return true;
                }
                return false;
            })
        }
        if (questionTypeSelected !== QuestionType.Measure) {
            return responseTypes.filter((responseType, index) => {

                // only use response types that can be used for text questions
                if (isResponseTypeForText(responseType[1])) {
                    return true;
                }
                return false;
            })
        }

    }, [responseTypes, questionTypeSelected, isLoading]);

    useEffect(() => {
        if (!isCreate && !!model) {
            setQuestionTypeSelected(model?.questionType);
            setresponseTypeSelected(model?.responseType);
        }
    }, [isCreate, model, setQuestionTypeSelected, setresponseTypeSelected,]);

    const [isMainEditOpen, toggleMainEditOpen] = useToggleState(!isCreate);

    // handle the inputs
    const handleTypeSelection = useCallback((questionType: string) => {
        change({ questionType: questionType });
        setQuestionTypeSelected(questionType);
        if (questionType !== QuestionType.Measure && (!model.responseType || model.responseType === QuestionResponseType.TextBoxes)) {
            // for text box questions default the min and max
            change({ minTextBoxes: 1, maxTextBoxes: 3 });
        } else {
            // set to 0 for all measures
            change({ minTextBoxes: 0, maxTextBoxes: 0 });
        }

        toggleMainEditOpen(true);
    }, [change, setQuestionTypeSelected, toggleMainEditOpen, model.responseType,]);

    const handleResponseTypeSelection = useCallback((item: string) => {
        change({ responseType: item });
        setresponseTypeSelected(item);
        //For multiple choice and pick one questions set min and max text boxes to 0
        if (item === QuestionResponseType.MultipleChoice || item === QuestionResponseType.PickOne) {
            change({ minTextBoxes: 0, maxTextBoxes: 0 });
        }
    }, [change, setresponseTypeSelected,]);

    //const handleReinforcement = useCallback((isReinforcement: boolean) => {
    //    change({ isReinforcementRequired: isReinforcement });
    //    if (!isReinforcement) {
    //        change({ reinforcementText: '' });
    //    }
    //}, [change]);

    // save everything and close the modal
    const saveQuestion = useCallback(() => {
        if (!validate()) {
            return;
        }

        saveAllDeBounce();
        toggle();

    }, [validate, saveAllDeBounce, toggle]);

    // empty manager for the question preview
    const dummyResponsesManager = useChangesArray<QuestionResponse, string>([], item => item.id);

    const [dummyValidateResponse, dummyResponseValidationErrors] = useValidatorArrayCallback<QuestionResponse>((myModel, validation, fieldsToCheck) => {
        const rules = {
        };

        validation.checkRules(rules, fieldsToCheck);

    }, []);


    const [analysisTypeModalIsOpen, toggleAnalysisTypeModalIsOpen] = useToggleState();

    // use these to set a modal to go into the create analysis type modal
    const [newAnalysisTypeId, setNewAnalysisTypeId] = useState<string>('');
    const analysisType = analysisTypeManager.model.find(it => it.id === newAnalysisTypeId);

    // handle add new analysis type
    const handleAddAnlysisType = useCallback(async () => {
        // put the new analysis type in the array
        const newAnalysisTypeId = Guid.newGuid();
        analysisTypeManager.addFor({
            ...analysisTypeDefaultValues(), id: newAnalysisTypeId, subscriptionId: currentUserSubscriptionId,
        });

        setNewAnalysisTypeId(newAnalysisTypeId);
        change({ analysisTypeId: newAnalysisTypeId });
    }, [analysisTypeManager, setNewAnalysisTypeId, change, currentUserSubscriptionId]);

    const handleRemoveAnalysisType = useCallback(async (analysisTypeId: string) => {
        change({ analysisTypeId: undefined });
        analysisTypeManager.removeFor(analysisTypeId);
    }, [analysisTypeManager, change]);

    const analysisTypeSelected = useMemo(() => { return analysisTypeManager.model.find(it => it.id === model.analysisTypeId) }, [analysisTypeManager.model, model.analysisTypeId]);
    const questionnaireAnalysisTypeLinkInUse = useMemo(() => { return questionnaireAnalysisTypeLinkManager.model.find(it => it.analysisTypeId === model.analysisTypeId) }, [questionnaireAnalysisTypeLinkManager.model, model.analysisTypeId]);

    const [isSetQuestionnaireAnalysisTypeLinkOpen, toggleSetQuestionnaireAnalysisTypeLinkOpen] = useState<boolean>(false);

    const handleAddQuestionnaireAnalysisTypeLink = useCallback(async (baseAmount: number) => {
        // put the new QuestionnaireAnalysisTypeLink in the array
        const newQuestionnaireAnalysisTypeLinkId = Guid.newGuid();
        questionnaireAnalysisTypeLinkManager.addFor({
            ...questionnaireAnalysisTypeLinkDefaultValues(), id: newQuestionnaireAnalysisTypeLinkId, subscriptionId: currentUserSubscriptionId ?? undefined,
            analysisTypeId: model.analysisTypeId, questionnaireId: model.questionnaireId, baseAmount: baseAmount, 
        });
        toggleSetQuestionnaireAnalysisTypeLinkOpen(false);
    }, [currentUserSubscriptionId, model.analysisTypeId, model.questionnaireId, questionnaireAnalysisTypeLinkManager]);

    const handleChangeQuestionnaireAnalysisTypeLink = useCallback(async (baseAmount: number) => {
        if (!!questionnaireAnalysisTypeLinkInUse) {
            questionnaireAnalysisTypeLinkManager.changeFor(questionnaireAnalysisTypeLinkInUse.id, { baseAmount: baseAmount });
        }
        toggleSetQuestionnaireAnalysisTypeLinkOpen(false);
    }, [questionnaireAnalysisTypeLinkManager, questionnaireAnalysisTypeLinkInUse]);

    const [newBaseAmount, setNewBaseAmount] = useState<number>(0);

    const handleRemoveAnalysisTypeLink = useCallback(async (id: string) => {
        questionnaireAnalysisTypeLinkManager.removeFor(id);
    }, [questionnaireAnalysisTypeLinkManager]);


    const [isIncreaseChecked, setIsIncreaseChecked] = useState<boolean>(model.isIncrease === true);
    const [isDecreaseChecked, setIsDecreaseChecked] = useState<boolean>(model.isIncrease === false);
    const handleIncreaseDecreaseCheckboxes = useCallback(async (action: string) => {

        var oldState = action === 'increase' ? isIncreaseChecked : isDecreaseChecked;

        if (action === 'increase') {
            setIsIncreaseChecked(!oldState);
            if (isDecreaseChecked) {
                setIsDecreaseChecked(!isDecreaseChecked);
            }
        }
        if (action === 'decrease') {
            setIsDecreaseChecked(!oldState);
            if (isIncreaseChecked) {
                setIsIncreaseChecked(!isIncreaseChecked);
            }
        }

        if (oldState === true) {
            change({ isIncrease: null });
        }
        else {
            change({ isIncrease: action === 'increase' ? !oldState : oldState });
        }
    }, [change, isDecreaseChecked, isIncreaseChecked]);
    console.log(`IncreaseCheck: ${isIncreaseChecked}, DecreaseCheck: ${isDecreaseChecked}, model: ${model.isIncrease}`);
    return (
        <MainContainer className="question-main-container">
            <AlertOnErrors errors={[loadSupportingDataErrors]} />
            <ConditionalFragment showIf={isLoading}>
                <Col xs="auto">
                    <LoadingIndicator size="sm" />
                </Col>
            </ConditionalFragment>

            <ConditionalFragment showIf={!isLoading && (!model.questionText || !model.questionType)}>
                <div className="introduction">
                    {
                        isCreate ? (
                            <>{t('editQuestionModal.createQuestion', 'As you build your question a preview matching your selections will appear.')}</>
                        ) : (
                            <>{t('editQuestionModal.editQuestion', 'As you edit your question the preview will change to match your selections.')}</>
                        )
                    }
                </div>
            </ConditionalFragment>
            <CardBody>
                <ConditionalFragment showIf={!!isCreate}>
                    <Row className="section-header">
                        <Col>
                            <>{t('editQuestion.writeQuestion', 'Write your question')}</>
                        </Col>
                    </Row>
                </ConditionalFragment>
                <Row>
                    <Col>
                        <ConditionalFragment showIf={!isCreate}>
                            <Label htmlFor="questionText">{t('editQuestion.questionText', 'Question Text')}</Label>
                        </ConditionalFragment>
                        <FormGroup>
                            <ValidatedElasticInput name="questionText" type="textarea" value={model?.questionText ?? ''}
                                onChange={e => change({ questionText: e.currentTarget.value })}
                                onBlur={e => validate('questionText')}
                                validationErrors={validationErrors['questionText']} />
                        </FormGroup>
                    </Col>
                </Row>
                <Collapse isOpen={!!model.questionText}>
                    <ConditionalFragment showIf={!!isCreate}>
                        <Row className="section-header">
                            <Col>
                                <>{t('editQuestion.chooseAnalysisType', 'Choose the category for analysis and reporting')}</>
                                <> </>
                                <ConditionalFragment showIf={!IdentityRoles.SubscriptionAdministration}>
                                    <span data-toggle="tooltip" title={t('editQuestion.analysisType.tooltip', "More Analysis Type Categories can be added by your Subscription Administrator")}><FontAwesomeIcon icon="info-circle" /></span>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!IdentityRoles.SubscriptionAdministration}>
                                    <>
                                        <Button className="btn-add-type" color="primary" outline onClick={() => { toggleAnalysisTypeModalIsOpen(); handleAddAnlysisType(); }}
                                            data-toggle="tooltip" title={t('editQuestion.addAnalysisType.tooltip', "Can't find the analysis type you're looking for? click here to add a new one")}>
                                            <FontAwesomeIcon icon="plus" />
                                        </Button>
                                    </>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </ConditionalFragment>

                    {/* get the reporting analysis type */}
                    <Row>
                        <Col>
                            <FormGroup>
                                <ConditionalFragment showIf={!isCreate}>
                                    <Label htmlFor="analysisType">{t('editQuestion.analysisType', 'Analysis Type')}</Label>
                                    <> </>
                                    <ConditionalFragment showIf={!IdentityRoles.SubscriptionAdministration}>
                                        <span data-toggle="tooltip" title={t('editQuestion.analysisType.tooltip', "More Analysis Type Categories can be added by your Subscription Administrator")}><FontAwesomeIcon icon="info-circle" /></span>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!IdentityRoles.SubscriptionAdministration}>
                                        <>
                                            <Button className="btn-add-type" color="primary" outline onClick={() => { toggleAnalysisTypeModalIsOpen(); handleAddAnlysisType(); }}
                                                data-toggle="tooltip" title={t('editQuestion.addAnalysisType.tooltip', "Can't find the analysis type you're looking for? click here to add a new one")}>
                                                <FontAwesomeIcon icon="plus" />
                                            </Button>
                                        </>
                                    </ConditionalFragment>
                                </ConditionalFragment>
                                <ValidatedInput name="analysisType" type="select" value={model?.analysisTypeId ?? ''} onChange={e => change({ analysisTypeId: e.currentTarget.value })} onBlur={e => validate('analysisType')} validationErrors={validationErrors['analysisType']}>
                                    <option key={null} value="">{t('editQuestion.analysisTypePlaceholder', '')}</option>
                                    {
                                        analysisTypeManager.model?.map(item => (
                                            <option key={item.id} value={item.id}>{item.name}</option>
                                        ))
                                    }
                                </ValidatedInput>
                                <FormText>{t('editQuestion.analysisTypeHelpText', 'This will help with automatic analysis and reporting of the information when the question is completed.')}
                                </FormText>
                            </FormGroup>
                        </Col>
                    </Row>
                </Collapse>
                {
                    // choose the type of question
                }
                <ConditionalFragment showIf={!isCreate}>
                    <Row>
                        <Col>
                            <Label>{t('editQuestion.questionType', 'Question type')}</Label>
                        </Col>
                    </Row>
                </ConditionalFragment>

                <Collapse isOpen={!!model.analysisTypeId}>
                    <ConditionalFragment showIf={!!isCreate}>
                        <Row className="section-header">
                            <Col>
                                <>{t('editQuestion.chooseQuestionType', 'Choose a question type')}</>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    <NavTiles>
                        {
                            questionTypes.map(([key, questionType]) => (
                                <NavItem key={questionType}>
                                    <NavLink className={questionTypeSelected === questionType ? 'active' : ''} to="/" onClick={e => { e.preventDefault(); handleTypeSelection(questionType) }}>
                                        <h2>{questionType}</h2>
                                        <FontAwesomeIcon icon={questionTypeIcon(questionType)} className="nav-icon" />
                                        <>{questionTypeDisplayName(questionType, t)}</>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </NavTiles>
                </Collapse>
            </CardBody>
            <Collapse isOpen={isMainEditOpen}>
                <CardBody>
                    {
                        // need the type of response 
                    }
                    <ConditionalFragment showIf={!!questionTypeSelected}>
                        <ConditionalFragment showIf={!isCreate}>
                            <Row>
                                <Col>
                                    <Label>{t('editQuestion.Response', 'Response type')}</Label>
                                </Col>
                            </Row>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!isCreate}>
                            <Row className="section-header">
                                <Col>
                                    <>{t('editQuestion.chooseResponseType', 'Choose the kind of response to be collected')}</>
                                </Col>
                            </Row>
                        </ConditionalFragment>
                        <Row className="response-type-selectors">
                            {
                                responseTypesToUse?.map(([key, responseType]) => (
                                    <Col key={responseType} >
                                        <button className={`response-type-selector btn btn-primary-outline ${responseTypeSelected === responseType ? 'active' : ''}`}
                                            onClick={e => { e.preventDefault(); handleResponseTypeSelection(responseType) }}>
                                            <div className="response-type-title">
                                                {questionResponseTypeDisplayName(responseType, t)}
                                            </div>
                                            <FontAwesomeIcon icon={questionResponceTypeIcon(responseType)} className="nav-icon" />
                                        </button>
                                        <FormText className="response-type-text">{questionResponseTypeDescription(responseType, t)}</FormText>
                                    </Col>
                                ))}
                        </Row>
                    </ConditionalFragment>
                    <Collapse isOpen={responseTypeSelected === QuestionResponseType.Scale || responseTypeSelected === QuestionResponseType.ComparisonScale}>
                        <Row>
                            <Col>
                                <Label htmlFor="scaleMax">{t('editQuestion.scaleMax', 'Max scale number')}</Label>
                                <ValidatedInput name="scaleMax" type="number" value={model?.scaleMax ?? 1}
                                    onChange={e => change({ scaleMax: parseInt(e.currentTarget.value, 0) })}
                                    onBlur={e => validate('scaleMax')}
                                    validationErrors={validationErrors['scaleMax']} />
                                <FormText>{t('editQuestion.scaleHelpText', 'What is the maximum number for this scale?')}</FormText>
                            </Col>
                        </Row>
                    </Collapse>
                    <Collapse isOpen={(responseTypeSelected === QuestionResponseType.Scale || responseTypeSelected === QuestionResponseType.ComparisonScale) && !!model.scaleMax}>
                        <Row>
                            <Col>
                                <Label htmlFor="naApplicable">{t('editQuestion.naApplicable', 'Is an N/A option required?')}</Label>
                            </Col>
                            <Col>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value={'No'}
                                            name={'naYesNo'}
                                            checked={(!model.isNARequired) ? true : false}
                                            onChange={e => change({ isNARequired: !e.currentTarget.checked })}
                                        />
                                        {t('common.no', 'No')}
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value={'Yes'}
                                            name={'naYesNo'}
                                            checked={(model.isNARequired) ? true : false}
                                            onChange={e => change({ isNARequired: e.currentTarget.checked })}
                                        />
                                        {t('common.yes', 'Yes')}
                                    </label>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Label htmlFor="isPercentage">{t('editQuestion.isPercentage', 'Numeric or percentage scale?')}</Label>
                            </Col>
                            <Col>
                                <div className="radio">
                                    <label>
                                        <input
                                            type="radio"
                                            value={'Numeric'}
                                            name={'isPercentage'}
                                            checked={(!model.isPercentage) ? true : false}
                                            onChange={e => change({ isPercentage: !e.currentTarget.checked })}
                                        />
                                        {t('common.numeric', 'Numeric')}
                                    </label>
                                    <label>
                                        <input
                                            type="radio"
                                            value={'Percentage'}
                                            name={'isPercentage'}
                                            checked={(model.isPercentage) ? true : false}
                                            onChange={e => change({ isPercentage: e.currentTarget.checked })}
                                        />
                                        {t('common.percentage', 'Percentage')}
                                    </label>
                                </div>
                            </Col>
                        </Row>
                    </Collapse>
                    {
                        // multiple choice
                    }
                    <Collapse isOpen={!!questionTypeSelected &&
                        questionTypeSelected !== QuestionType.Measure &&
                        (responseTypeSelected === QuestionResponseType.MultipleChoice || responseTypeSelected === QuestionResponseType.PickOne) &&
                        !!model?.questionText}>
                        <Row className="multiple-choice-possible-responses-container">
                            <Col xs={12}>
                                <Label htmlFor="multipleChoiceResponses">{t('editQuestion.multipleChoiceResponses', 'Possible responses')}</Label>
                            </Col>
                            {/* For multiple choice and pickone we disable the next possible answer until the previous one is filled. */}
                            {/* This is to stop people filling them in with blanks in between. */}
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>1</Label>
                                    <ValidatedInput name="multipleChoiceResponse1" type="text" value={model?.multipleChoiceResponse1 ?? ''}
                                        onChange={e => change({ multipleChoiceResponse1: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse1')}
                                        validationErrors={validationErrors['multipleChoiceResponse1']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>2</Label>
                                    <ValidatedInput name="multipleChoiceResponse2" type="text" value={model?.multipleChoiceResponse2 ?? ''}
                                        disabled={!model?.multipleChoiceResponse1}
                                        onChange={e => change({ multipleChoiceResponse2: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse2')}
                                        validationErrors={validationErrors['multipleChoiceResponse2']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>3</Label>
                                    <ValidatedInput name="multipleChoiceResponse3" type="text" value={model?.multipleChoiceResponse3 ?? ''}
                                        disabled={!model?.multipleChoiceResponse2}
                                        onChange={e => change({ multipleChoiceResponse3: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse3')}
                                        validationErrors={validationErrors['multipleChoiceResponse3']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>4</Label>
                                    <ValidatedInput name="multipleChoiceResponse4" type="text" value={model?.multipleChoiceResponse4 ?? ''}
                                        disabled={!model?.multipleChoiceResponse3}
                                        onChange={e => change({ multipleChoiceResponse4: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse4')}
                                        validationErrors={validationErrors['multipleChoiceResponse4']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>5</Label>
                                    <ValidatedInput name="multipleChoiceResponse5" type="text" value={model?.multipleChoiceResponse5 ?? ''}
                                        disabled={!model?.multipleChoiceResponse4}
                                        onChange={e => change({ multipleChoiceResponse5: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse5')}
                                        validationErrors={validationErrors['multipleChoiceResponse5']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>6</Label>
                                    <ValidatedInput name="multipleChoiceResponse6" type="text" value={model?.multipleChoiceResponse6 ?? ''}
                                        disabled={!model?.multipleChoiceResponse5}
                                        onChange={e => change({ multipleChoiceResponse6: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse6')}
                                        validationErrors={validationErrors['multipleChoiceResponse6']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>7</Label>
                                    <ValidatedInput name="multipleChoiceResponse7" type="text" value={model?.multipleChoiceResponse7 ?? ''}
                                        disabled={!model?.multipleChoiceResponse6}
                                        onChange={e => change({ multipleChoiceResponse7: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse7')}
                                        validationErrors={validationErrors['multipleChoiceResponse7']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label>8</Label>
                                    <ValidatedInput name="multipleChoiceResponse8" type="text" value={model?.multipleChoiceResponse8 ?? ''}
                                        disabled={!model?.multipleChoiceResponse7}
                                        onChange={e => change({ multipleChoiceResponse8: e.currentTarget.value })}
                                        onBlur={e => validate('multipleChoiceResponse8')}
                                        validationErrors={validationErrors['multipleChoiceResponse8']} />
                                </FormGroup>
                            </Col>
                            <Col xs={12}>
                                <FormText>{t('editQuestion.reinforcementHelpText', 'Enter up to 8 possible responses. If you want less than 8 just leave the remainder blank.')}
                                </FormText>
                            </Col>
                        </Row>
                    </Collapse>
                    {/*If we're recording numerical data, ask if it's an increase or decrease and set IsIncrease*/}
                    <Collapse isOpen={!!questionTypeSelected && (responseTypeSelected === QuestionResponseType.Percentage ||
                        responseTypeSelected === QuestionResponseType.CurrencyAmount || responseTypeSelected === QuestionResponseType.Number)}>
                        <FormGroup>
                            <Row xs="auto">
                                <Label style={{ marginLeft: '0.9rem' }}>{t('editQuestion.isIncrease', 'Does this question capture an increase or decrease? (Optional)')}</Label>
                            </Row>
                            <Row>
                                <Col style={{ maxWidth: '7vw' }}>
                                    <Label>{t('editQuestion.isIncrease', 'Increase')} </Label>
                                </Col>
                                <Col xs="auto">
                                    <Input type="checkbox" checked={isIncreaseChecked} onChange={e => { handleIncreaseDecreaseCheckboxes('increase'); }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col style={{ maxWidth: '7vw' }}>
                                    <Label>{t('editQuestion.isDecrease', 'Decrease')} </Label>
                                </Col>
                                <Col xs="auto">
                                    <Input type="checkbox" checked={isDecreaseChecked} onChange={e => { handleIncreaseDecreaseCheckboxes('decrease'); }} />
                                </Col>
                            </Row>
                        </FormGroup>
                    </Collapse>
                    {/*Handle setting and resetting the baseAmount*/}
                    <Collapse isOpen={isIncreaseChecked || isDecreaseChecked}>
                        <ConditionalFragment showIf={!questionnaireAnalysisTypeLinkInUse}>
                            <Row>
                                <Col xs="auto">
                                    <Label>{t('editQuestion.useDefaultBaseAmount', `Using default base amount for ${analysisTypeSelected?.name} of £${analysisTypeSelected?.baseAmountDefault} (This applies to all questions of this analysis type)`)}</Label>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: 0 }}>
                                    <Button style={{ marginLeft: '2px' }} className="btn-add-type" color="primary" outline onClick={() => { toggleSetQuestionnaireAnalysisTypeLinkOpen(!isSetQuestionnaireAnalysisTypeLinkOpen) }}
                                        data-toggle="tooltip" title={t('editQuestion.setNewBaseAmount.tooltip', "Base amount not right? click here to set a new one for this campaign")}>
                                        <FontAwesomeIcon icon={!isSetQuestionnaireAnalysisTypeLinkOpen ? "plus" : "minus"} />
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={isSetQuestionnaireAnalysisTypeLinkOpen}>
                                <Row>
                                    <Col>
                                        <ValidatedNumberInput name="baseAmountDefault" value={newBaseAmount}
                                            onChange={e => setNewBaseAmount(e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0)}
                                            onBlur={e => validate('baseAmountDefault')}
                                            validationErrors={validationErrors['amountInput']}
                                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                                            currencySymbol={'£'}
                                        />
                                    </Col>
                                    <Col>
                                        <Button color="primary" onClick={() => handleAddQuestionnaireAnalysisTypeLink(newBaseAmount)}>
                                            {t('editQuestion.save', 'Save')}
                                        </Button>
                                    </Col>
                                </Row>
                                <FormText>&nbsp;&nbsp;{t('editQuestion.setNewBaseAmount', `Set ${analysisTypeSelected?.name} a new base amount for this Campaign`)}</FormText>
                            </Collapse>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!questionnaireAnalysisTypeLinkInUse}>
                            <Row>
                                <Col xs="auto">
                                    <Label>{t('editQuestion.useCustomBaseAmount', `Reporting the percentage increase or decrease will use a base amount of ${subscription?.currencySymbol ?? '£'}${parseInt(questionnaireAnalysisTypeLinkInUse?.baseAmount).toLocaleString()} for ${analysisTypeSelected?.name}  (This applies to all questions of this analysis type)`)}</Label>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: 0 }}>
                                    <Button style={{ marginLeft: '2px' }} className="btn-add-type" color="primary" outline onClick={() => { toggleSetQuestionnaireAnalysisTypeLinkOpen(!isSetQuestionnaireAnalysisTypeLinkOpen) }}
                                        data-toggle="tooltip" title={t('editQuestion.setNewCustomBaseAmount.tooltip', "Base amount not right? click here to set a new one for this campaign")}>
                                        <FontAwesomeIcon icon={!isSetQuestionnaireAnalysisTypeLinkOpen ? "plus" : "minus"} />
                                    </Button>
                                </Col>
                                <Col xs="auto" style={{ paddingLeft: 0 }}>
                                    <Button style={{ marginLeft: '2px' }} className="btn-add-type" color="primary" outline onClick={() => { handleRemoveAnalysisTypeLink(questionnaireAnalysisTypeLinkInUse?.id ?? '') }}
                                        data-toggle="tooltip" title={t('editQuestion.revertBaseAmountToDefault.tooltip', `Revert to default base amount (£${analysisTypeSelected?.baseAmountDefault})`)}>
                                        <FontAwesomeIcon icon="undo" />
                                    </Button>
                                </Col>
                            </Row>
                            <Collapse isOpen={isSetQuestionnaireAnalysisTypeLinkOpen}>
                                <FormText>&nbsp;&nbsp;{t('editQuestion.setNewBaseAmount', `Set ${analysisTypeSelected?.name} a new base amount for this campaign`)}</FormText>
                                <Row>
                                    <Col>
                                        <ValidatedNumberInput name="baseAmountDefault" value={newBaseAmount}
                                            onChange={e => setNewBaseAmount(e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0)}
                                            onBlur={e => validate('baseAmountDefault')}
                                            validationErrors={validationErrors['amountInput']}
                                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                                            currencySymbol={'£'}
                                        />
                                    </Col>
                                    <Col>
                                        <Button color="primary" onClick={() => handleChangeQuestionnaireAnalysisTypeLink(newBaseAmount)}>
                                            {t('editQuestion.save', 'Save')}
                                        </Button>
                                    </Col>
                                </Row>
                            </Collapse>
                        </ConditionalFragment>
                    </Collapse>
                    {
                        // all other types need the text boxes
                    }
                    <Collapse isOpen={!!questionTypeSelected && questionTypeSelected !== QuestionType.Measure && responseTypeSelected === QuestionResponseType.TextBoxes && !!model?.questionText}>
                        <Row>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label htmlFor="minTextBoxes">{t('editQuestion.minTextBoxes', 'Minimum answers')}</Label>
                                    <ValidatedInput name="minTextBoxes" type="number" value={model?.minTextBoxes ?? 1}
                                        onChange={e => change({ minTextBoxes: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0 })}
                                        onBlur={e => validate('minTextBoxes')}
                                        validationErrors={validationErrors['minTextBoxes']} />
                                    <FormText>{t('editQuestion.reinforcementHelpText', 'What is the minimum number of answers a user may give to this question?')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                            <Col xs={12} sm={6}>
                                <FormGroup>
                                    <Label htmlFor="maxTextBoxes">{t('editQuestion.minTextBoxes', 'Maximum answers')}</Label>
                                    <ValidatedInput name="maxTextBoxes" type="number" value={model?.maxTextBoxes ?? 3}
                                        onChange={e => change({ maxTextBoxes: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0 })}
                                        onBlur={e => validate('maxTextBoxes')}
                                        validationErrors={validationErrors['maxTextBoxes']} />
                                    <FormText>{t('editQuestion.reinforcementHelpText', 'What is the maximum number of answers a user may give to this question?')}
                                    </FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Collapse>

                    <ConditionalFragment showIf={!isLoading && !!model.questionText && !!model.questionType}>
                        <div className="preview-container">
                            <h2>{t('editQuestion.previewHeading', 'Preview')}</h2>
                            <QuestionPresenter
                                question={model}
                                responsesManager={dummyResponsesManager}
                                validateResponse={dummyValidateResponse}
                                responseValidationErrors={dummyResponseValidationErrors}
                                currencySymbol={subscription?.currencySymbol ?? '£'}
                            />
                        </div>
                    </ConditionalFragment>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <Button type="button" color="primary" onClick={e => saveQuestion()}>
                                <FontAwesomeIcon icon="save" className="nav-icon" />
                                <> </>
                                {t('common.save', 'Save')}
                            </Button>
                        </ConditionalFragment>
                        {/* use a close button instead of cancel because things will have saved while the user enters them so they cannot cancel */}
                        <Button type="button" color="primary" outline onClick={e => toggle()}>
                            {t('common.cancel', 'Close')}
                        </Button>
                    </FormButtons>
                </CardBody>
            </Collapse>
            <ConditionalFragment showIf={!!analysisTypeModalIsOpen}>
                <CreateAnalysisTypeModal
                    isOpen={analysisTypeModalIsOpen}
                    toggle={toggleAnalysisTypeModalIsOpen}
                    model={analysisType as AnalysisType}
                    validate={(fieldsToCheck) => validateAnalysisType(analysisType as AnalysisType, fieldsToCheck)}
                    validationErrors={analysisTypeValidationErrors(newAnalysisTypeId)}
                    change={changes => analysisTypeManager.changeFor(newAnalysisTypeId, changes)}
                    removeAnalysisType={() => handleRemoveAnalysisType(newAnalysisTypeId)}
                />
            </ConditionalFragment>
        </MainContainer>
    );
};