import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, ModalProps, Row, Spinner, } from "reactstrap";
import { useAnalysisTypes } from "../../../../api/main/analysisTypes/useAnalysisTypes";
import { importQuestionnaireQuestionsMutation_importQuestionnaireQuestions } from "../../../../api/main/generated/importQuestionnaireQuestionsMutation";
import { QuestionResponseType } from "../../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionType } from "../../../../api/main/models/codeOnly/QuestionType";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { FileUploadButton } from "../../../shared/fileUploadButton/FileUploadButton";


export interface ImportExportQuestionnaireQuestionsModalProps extends ModalProps {
    isOpen: boolean,
    questionnaireId: string,
    performImport: (files: FileList | null) => void,
    isExecuting: boolean,
    importResults?: Array<importQuestionnaireQuestionsMutation_importQuestionnaireQuestions>,
    respondantSessionsHaveStartDate: boolean,
    closeImportExportModal: () => void,
    subscriptionId: string,
    ImportErrors: any,
}

/**
 * Modal For importing an exporting questionnaire questions
 * */
export const ImportExportQuestionnaireQuestionsModal = (props: ImportExportQuestionnaireQuestionsModalProps) => {
    const {
        isOpen,
        questionnaireId,
        performImport,
        isExecuting,
        importResults,
        respondantSessionsHaveStartDate,
        closeImportExportModal,
        subscriptionId,
        ImportErrors,
    } = props;

    const { t } = useTranslation();


    // Get all Analysis Types
    const { data: { items: analysisTypes } } = useAnalysisTypes({ subscriptionId: subscriptionId });
    const analysisTypesList = analysisTypes?.map(item => ` ${item.name}`);

    const questionTypes = Object.entries(QuestionType);
    const questionTypesList = questionTypes?.map(item => ` ${item[1]}`);

    const responseTypes = Object.entries(QuestionResponseType);
    const responseTypesList = responseTypes?.map(item => ` ${item[1]}`);


    return (
        <Modal isOpen={isOpen} size="xl" className="select-question-modal">
            <ModalHeader toggle={() => closeImportExportModal()}>
                {t('importExportQuestionsModal.title','Import/Export Questions')}
            </ModalHeader>
            <AlertOnErrors errors={[ImportErrors]}/>
            <ModalBody>
                <Row>
                    <Col xs={12} sm={6} className={"modal-input-col modal-input-col-left"}>
                        <ConditionalFragment showIf={!importResults?.length}>
                            <h5>
                                {t('importExportQuestionsModal.importInstructions.p1', 'Importing Questions')}
                            </h5>
                            <p>
                                {t('importExportQuestionsModal.importInstructions.p2', 'This action will replace all the existing sections and questions with the imported questions and sections.')}
                                <> </>
                            </p>
                            <p>
                                {t('importExportQuestionsModal.importInstructions.p3',
                                    'The most efficient way to use the import/export questions feature is to start by building your sections and questions in the questionnaire view.')}
                                <> </>
                                {t('importExportQuestionsModal.importInstructions.p4', 'Once you are happy with the starting point of your questions, you can export them to an Excel file')}
                                <> </>
                                {t('importExportQuestionsModal.importInstructions.p5', 'and make any changes necessary on the spreadsheet. Then import the updated questions.')}
                                <> </>
                                {t('importExportQuestionsModal.importInstructions.p6',
                                    'Remember that while you are making changes on the spreadsheet, you should not also make changes within Impacts until you have imported your revised questions.')}
                            </p>
                            <p>
                                {t('importExportQuestionsModal.importInstructions.p7', 'Although we recommend it, you do not have to start by exporting a file. You can build your own import from scratch.')}
                                <> </>
                                {t('importExportQuestionsModal.importInstructions.p8', 'The file you import can be in Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) format and must contain the correct columns.')}
                            </p>
                            <h5>
                                {t('importExportQuestionsModal.importInstructions.p1', 'Exporting Questions')}
                            </h5>
                            <p>
                                {t('importExportQuestionsModal.importInstructions.p2', 'This action will export an Excel format file containing your questions in the correct columns ready to change and then import.')}
                                <> </>
                            </p>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!importResults?.length}>
                            <h5>{t('importExportQuestionsModal.resultsTitle', 'Import Results')}</h5>
                            {
                                importResults?.map(item =>
                                    <div key={item.rowNumber} className={item.successful ? 'text-success' : 'text-danger'}>
                                        <Row>
                                            <ConditionalFragment showIf={item.rowNumber !== -1 /* Whole file errors. */}>
                                                <Col xs={12} md="auto">
                                                    {t('importUsersBase.results.rowNumber', 'Row {{rowNumber}}', { rowNumber: item.rowNumber })}
                                                </Col>
                                            </ConditionalFragment>
                                            <Col>
                                                <ConditionalFragment showIf={item.successful}>
                                                    {t('importUsersBase.results.successful', 'Question created for {{question}}', { question: item.question?.questionText })}
                                                </ConditionalFragment>
                                                <ConditionalFragment showIf={!!item.errors.length}>
                                                    <ConditionalFragment showIf={item.errors.length === 1}>
                                                        {
                                                            item.errors.map((error, index) => (
                                                                <div key={index}>
                                                                    {error}
                                                                </div>
                                                            ))
                                                        }
                                                    </ConditionalFragment>
                                                    <ConditionalFragment showIf={item.errors.length > 1}>
                                                        <ul>
                                                            {
                                                                item.errors.map((error, index) => (
                                                                    <li key={index}>
                                                                        {error}
                                                                    </li>
                                                                ))
                                                            }
                                                        </ul>
                                                    </ConditionalFragment>
                                                </ConditionalFragment>
                                            </Col>
                                        </Row>
                                        <hr />
                                    </div>
                                )
                            }
                        </ConditionalFragment>
                    </Col>
                    <Col xs={12} sm={6} className={"modal-input-col modal-input-col-right"}>
                        <h5>
                            {t('importExportquestionsModal.exportInstructions.p1', 'Columns')}
                        </h5>

                        <h6>Mandatory columns</h6>
                        <ul>
                            <li>{t('importExportQuestionsModal.columns.exportSectionName', 'Section Name')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportQuestionText', 'Question Text')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportAnalysisType', 'Analysis Type')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.analysisTypesToolTipText', `Analysis Types: ${analysisTypesList}`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                            <li>{t('importExportQuestionsModal.columns.exportQuestionType', 'Question Type')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.questionTypesToolTipText', `Question Types: ${questionTypesList}`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                            <li>{t('importExportQuestionsModal.columns.exportResponseType', 'Response Type')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.responseTypesToolTipText', `Response Types: ${responseTypesList}`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                        </ul>
                        <h6>For TextBoxes type questions</h6>
                        <ul>
                            <li>{t('importExportQuestionsModal.columns.exportMinimumTextBoxes', 'Minimum Text Boxes')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.minTextBoxTypesToolTipText', `Minimum text boxes must be between 0 - 10`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" /></span>
                            </li>
                            <li>{t('importExportQuestionsModal.columns.exportMaximumTextBoxes', 'Maximum Text Boxes')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.maxTextBoxTypesToolTipText', `Maximum Text Boxes Must Be Between 0 - 10`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                        </ul>
                        <h6>For Scale type questions</h6>
                        <ul>
                            <li>{t('importExportQuestionsModal.columns.exportMaximumScale', 'Maximum Scale')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.maxScaleToolTipText',`Maximum Scale Must be Between 0 - 10`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                            <li>{t('importExportQuestionsModal.columns.exportIsAPercentageScale', 'Is a Percentage Scale')}<> </>
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.percentageScaleToolTipText', `Do You Want Your Scale To Be In A Percentage Format? True or False`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                        </ul>
                        <h6>For MultipleChoice r PickOne type questions</h6>
                        <ul>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone1', 'Multiple Choice Response 1')}
                                <span data-toggle="tooltip"
                                    title={t('importExportQuestionsModal.multipleChoiceToolTipText',`You need only enter values for the number of multiple choice answers your question has. 8 is the maximum.`)}>
                                    <FontAwesomeIcon size="xs" icon="info-circle" />
                                </span>
                            </li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone2', 'Multiple Choice Response 2')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone3', 'Multiple Choice Response 3')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone4', 'Multiple Choice Response 4')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone5', 'Multiple Choice Response 5')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone6', 'Multiple Choice Response 6')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone7', 'Multiple Choice Response 7')}</li>
                            <li>{t('importExportQuestionsModal.columns.exportMultipleChoiceRespone8', 'Multiple Choice Response 8')}</li>
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col xs="auto">
                        <ButtonGroup xs={'auto'}>
                            <a className=" btn btn-primary" href={`/api/export/QuestionnaireQuestions?questionnaireId=${encodeURIComponent(questionnaireId ?? '')}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('importExportQuestionsModal.export', 'Export Questions')}
                            </a>
                            <ConditionalFragment showIf={!respondantSessionsHaveStartDate}>
                                <FileUploadButton onUpload={files => performImport(files)} isExecuting={isExecuting} outline={true}
                                    executingChildren={<><Spinner size="sm" /><> </>{t('importExportQuestionsModal.importing', 'Importing...')}</>}
                                >
                                    <FontAwesomeIcon icon="upload" className="nav-icon" />
                                    <> </>
                                    {t('importExportQuestionsModal.import', 'Import file...')}
                                </FileUploadButton>
                            </ConditionalFragment>
                            <Button onClick={() => closeImportExportModal()} color={"primary"} outline>
                                {t('importExportQuestionsModal.close', 'Close')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};