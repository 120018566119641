import { ApexOptions } from "apexcharts";
import moment, { unitOfTime } from "moment";
import { ChartDataSeries } from "./ChartData"

export type ChartDataConverterResults = { series: any[], options: Partial<ApexOptions> };
export type ChartDataConverter = (data: Array<ChartDataSeries>) => ChartDataConverterResults;

/**
 * Class with export functions for various conversions of ChartDataSeries.
 */
export class ChartDataConverters {
    /**
     * Convert data to be used in a donut chart.
     * @param series
     */
    public toDonut(data: Array<ChartDataSeries>): ChartDataConverterResults {
        const series = data.map(dataSeries => dataSeries.data.map(item => item.value)).flat(2);
        const labels = data.map(dataSeries => dataSeries.data.map(item => item.text)).flat(2);

        return {
            series: series,
            options: {
                labels: labels,
            } as ApexOptions,
        };
    }

    /**
     * Convert data to be used in a pie chart.
     * @param series
     */
    public toPie(data: Array<ChartDataSeries>): ChartDataConverterResults {
        return this.toDonut(data);
    }


    /**
     * Convert data to be used in a column chart.
     */
    public toColumn(data: Array<ChartDataSeries>): ChartDataConverterResults {
        const series = data.map(dataSeries => ({
            ...dataSeries,
            data: dataSeries.data.map(item => ({
                x: item.text,
                y: item.value,
            })),
        }));
        return {
            series: series,
            options: {
                xaxis: {
                    type: 'category',
                }
            } as ApexOptions,
        };
    }

    /**
     * Convert data to be used in a bar chart.
     */
    public toBar(data: Array<ChartDataSeries>): ChartDataConverterResults {
        const series = data.map(dataSeries => ({
            ...dataSeries,
            data: dataSeries.data.map(item => ({
                x: item.value,
                y: item.text,
            })),
        }));
        return {
            series: series,
            options: {
                yaxis: {
                    type: 'category',
                }
            } as ApexOptions,
        };
    }

    /**
     * Convert data to be used in a line chart.
     */
    public toLine(data: Array<ChartDataSeries>): ChartDataConverterResults {
        const series = data.map(dataSeries => ({
            ...dataSeries,
            data: dataSeries.data.map(item => ({
                x: item.text,
                y: item.value,
            })),
        }));
        return {
            series: series,
            options: {
                xaxis: {
                    type: 'category',
                }
            } as ApexOptions,
        };
    }

    /**
     * Convert data to be used in a area chart.
     * @param series
     */
    public toArea(data: Array<ChartDataSeries>): ChartDataConverterResults {
        return this.toLine(data);
    }

    /**
     * Convert data to be used in a line chart with the xaxis being dates.
     */
    public toDateLine(data: Array<ChartDataSeries>, unitOfTime: unitOfTime.StartOf = 'day'): ChartDataConverterResults {
        // Group by day/week/month etc, as plotting each event as value 1 doesn't work well in apexcharts so we have to do the grouping here.
        const groupByDate = (dataSeries: ChartDataSeries) => {
            let groupCounts: Array<{ id: string, total: number }> = [];
            for (const item of dataSeries.data) {
                const groupId = moment(item.text).local().startOf(unitOfTime).toISOString();
                let group = groupCounts.find(it => it.id === groupId);
                if (!group) {
                    group = { id: groupId, total: item.value };
                    groupCounts.push(group);
                } else {
                    group.total += item.value;
                }

            }

            return {
                ...dataSeries,
                data: groupCounts.map(item => ({
                    x: moment(item.id).toDate().getTime(),
                    y: item.total,
                }))
            }
        }

        return {
            series: data.map(dataSeries => groupByDate(dataSeries)),
            options: {
                xaxis: {
                    type: 'datetime',
                },
                markers: {
                    size: 5,
                }
            } as ApexOptions,
        };
    }

    /**
     * Convert data to be used in a area chart with the xaxis being dates.
     * @param series
     */
    public toDateArea(data: Array<ChartDataSeries>): ChartDataConverterResults {
        return this.toDateLine(data);
    }

    /**
     * Convert data to be used in a radar chart.
     * @param series
     */
    public toRadar(data: Array<ChartDataSeries>): ChartDataConverterResults {
        // All series need to have all data points, so extract all text data points to get started.
        let uniqueTexts: Array<string> = [];
        for (const dataSeries of data) {
            for (const item of dataSeries.data) {
                const existing = uniqueTexts.find(it => it === item.text);
                if (existing) {
                    continue;
                }

                uniqueTexts.push(item.text);
            }
        }

        // Go through data in a series and return a result for each required uniqueText.
        const generateSeriesData = (dataSeries: ChartDataSeries) => {
            let ret: Array<number> = [];
            for (const text of uniqueTexts) {
                const value = dataSeries.data.find(item => item.text === text);
                ret.push(value?.value ?? 0);
            }

            return ret;
        }

        const categories = data.map(dataSeries => dataSeries.data.map(item => item.text)).flat(2);
        const series = data.map(dataSeries => ({
            ...dataSeries,
            data: generateSeriesData(dataSeries),
        }));
        
        return {
            series: series,
            options: {
                xaxis: {
                    categories: categories,
                },
            } as ApexOptions,
        };
    }
}

/**
 * Conversions.
 */
export const chartDataConverters = new ChartDataConverters();