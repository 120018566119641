import moment from "moment";
import { useTranslation } from "react-i18next";
import { Col, Label, Row } from "reactstrap";
import { Questionnaire } from "../../../../api/main/models/Questionnaire";
import { useQuestionnaireSummaryViewModel } from "../../../../api/main/questionnaires/viewModels/useQuestionnaireSummaryViewModel";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { MainContainer } from "../../../shared/MainContainer";
import "./reviewTab.scss";

export interface ReviewTabProps {
    model: Questionnaire | undefined,
}

/**
 * view a summary of a questionnaire.
 */
export const ReviewTab = (props: ReviewTabProps) => {
    const {
        model,
    } = props;

    const { t } = useTranslation();

    // Get the Questionnaire and related information Using The ID passed in through params.
    const { data: {
        model: questionnaire, questionnaireRespondentSessions: sessions, learningUnit
    }, errors: loadErrors } = useQuestionnaireSummaryViewModel(model?.id);

    const sections = questionnaire?.questionnaireSections;
    const questions = questionnaire?.questions;

    return (
        <>
            <Row>
                <Col>
                    <h2> {t('reviewTab.heading', 'Review')}</h2>
                </Col>
            </Row>

            <MainContainer className={"review-tab-main-container"}>
                <AlertOnErrors errors={[loadErrors,]} />
                <Row>
                    <Col className="summary-details">
                        <h3>{t('viewQuestionnaireSummary.details.heading', 'Here\'s a summary of your campaign:')}</h3>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.name', 'Name:')}</Label>
                            <span className="summary-data">{questionnaire?.name}</span>
                            <span className="summary-data-subtext text-muted">
                                {t('viewQuestionnaireSummary.sectionNumbers', `with ${sections?.length ?? 0} sections`)}
                                &nbsp;
                                {t('viewQuestionnaireSummary.questionNumbers', `and ${questions?.length ?? 0} questions`)}
                            </span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.participants', 'Participants:')}</Label>
                            <span className="summary-data">{sessions?.length ?? '0'}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.startDate', 'Start Date:')}</Label>
                            <span className="summary-data">{moment(questionnaire?.startDate).local().format("L")}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.endDate', 'End Date:')}</Label>
                            <span className="summary-data">{moment(questionnaire?.endDate).local().format("L")}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.learningUnit', 'Learning Unit:')}</Label>
                            <span className="summary-data">{learningUnit?.learningUnitType ? learningUnit?.learningUnitType + ': ' + learningUnit?.name : t('viewQuestionnaireSummary.noLearningUnit', 'No associated Learning Unit')}</span>
                        </div>
                    </Col>
                </Row>
            </MainContainer>
        </>
    );
};