import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const ComparisonScaleResponsesStyles = StyleSheet.create({
    subHeadingContainer: {
        fontSize: 14,
        paddingTop: "4mm",
        paddingBottom: "4mm",
        flexDirection: "row",
        marginTop: "3mm",
    },
    subHeadingBold: {
        fontWeight: "bold",
    },
    subHeading: {
        fontWeight: "normal",
    },
    progressContainer: {
        marginTop: "3mm",
        flexDirection: "column",
        backgroundColor: "#d9e2e6",
        paddingTop: "3mm",
        paddingLeft: "5mm",
        paddingRight: "5mm",
    },
    progress: {
        flexDirection: "row",
        height: 25,
    },
    progressText: {
        fontSize: 12,
        width: 60,
    },
    progressBarContainer: {
        position: "relative",
        height: 15,
        width: "70%",
        backgroundColor: "#e8f0f4",
        borderRadius: 25,
    },
    progressBar: {
        left: 0,
        top: 0,
        height: 15,
        width: "50%",
        backgroundColor: "#737373",
        borderRadius: 25,
    },
    progressTextContainer: {
        textAlign: "right",
        widthL: "20%",
    },
    progressTextBold: {
        fontWeight: "bold",
    },
});

