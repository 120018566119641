import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const AnalyserReportPageStyles = StyleSheet.create({
    body: {
        fontFamily: 'Roboto',
    },
    reportPage: {
        padding: "10mm",
        paddingBottom: "25mm",
    },
    reportPageBody: {
        padding: "5mm",
        paddingTop: 0,
        minHeight: "237mm",
        borderRight: "1px solid black",
        borderLeft: "1px solid black",
    },
    pageHeaderBlueBackground: {
        borderRadius: "25px",
        paddingLeft: "10mm",
        paddingRight: "10mm",
        paddingTop: "3mm",
        marginBottom: "5mm",
        minHeight: "13mm",
    },
    pageHeaderBlueBackgroundText: {
        width: "100%",
        color: "white",
        fontSize: 16,
        fontWeight: "bold",
    },
    subTitle: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: "3mm",
    },
});

