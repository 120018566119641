import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Col, Label, Row } from "reactstrap";
import { useMeDashboardViewModel } from "../../api/main/meDashboardViewModels/useMeDashboardViewModel";
import { QuestionnaireType } from "../../api/main/models/codeOnly/QuestionnaireType";
import { QuestionnaireRespondentSession} from "../../api/main/models/QuestionnaireRespondentSession";
import { useCurrentUserProfile } from "../../api/main/profiles/useCurrentUserProfile";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { Banner } from "../shared/Banner";
import { MainContainer } from "../shared/MainContainer";
import { DashboardQuestionnaireCard } from "./DashboardQuestionnaireCard";
import "./meDashboard.scss";
import "../shared/cardsOrTable/cardsOrTable.scss";
import "../shared/cardsOrTable/cardsOrTableImpacts.scss";
import { useToggleState } from "use-toggle-state";
import { SelectSelfServiceModal } from "../subscriptionQuestionnaires/questionnaires/SelectSelfServiceModal";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useAddQuestionnaireUserCallback } from "../../api/main/questionnaireRespondentSessions/useAddQuestionnaireUserCallback";
import { useQuestionnaires } from "../../api/main/questionnaires/useQuestionnaires";
import { NoResultsFound } from "../shared/NoResultsFound";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


export const MeDashboard = () => {

    const { profileId: profileIdParam } = useParams<{ profileId: string }>();
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    const SubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const profileId = profileIdParam ?? currentUserProfile?.id;

    const { t } = useTranslation();

    // use the userId from the profile if possible to get the data so that we get sessions and responses too at the same time
    const { data: { viewModel, profile, questionnaireRespondentSessions }, errors: loadErrors, refresh } = useMeDashboardViewModel(currentUserProfile?.userId ?? profileId, !!currentUserProfile ? { isUserId: true } : { isUserId: false });
    const { questionnaires, questionnaireSections, questions } = viewModel ?? ({ questionnaires: [], questionnaireSections: [], questions: [] });

    const historyItems = useMemo(() => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        let ret = questionnaireRespondentSessions.filter(item => item.endDate);

        // filter by the subscription so that even if managing an admin will only see things within the subscription
        if (!!SubscriptionId) {
            ret = ret.filter(item => item.subscriptionId === SubscriptionId);
        }

        return ret.sort((a, b) => a.endDate < b.endDate ? 1 : -1);
    }, [questionnaireRespondentSessions, SubscriptionId]);

    const getTypeSessions = useCallback((type: string) => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        let requiredQuestionnaires = questionnaires.filter(item => item.questionnaireType === type);

        // filter by the subscription so that even if managing an admin will only see things within the subscription
        if (!!SubscriptionId) {
            requiredQuestionnaires = requiredQuestionnaires.filter(item => item.subscriptionId === SubscriptionId);
        }

        let returnItems: Array<QuestionnaireRespondentSession> = [];

        for (let questionnaire of requiredQuestionnaires) {
            let thisSession = questionnaireRespondentSessions.find(sess => sess.questionnaireId === questionnaire.id && !sess.endDate);
            if (!!thisSession && !thisSession?.endDate) {
                returnItems.push(thisSession);
            }
        }

        return returnItems;
    }, [questionnaireRespondentSessions, questionnaires, SubscriptionId]);

    const campaignItems = useMemo(() => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        return getTypeSessions(QuestionnaireType.Campaign);

    }, [getTypeSessions, questionnaireRespondentSessions]);

    // Find all the Campaign Sessions Where the Questionnaire is currently live.
    const liveCampaignItems = useMemo(() => {
        // Get all the Campaign Sessions.
        const sessions = campaignItems;

        // Get all the questionnaires that match the sessions
        const sessionQuestionnaires = questionnaires.filter(questionnaire => sessions.find(session => session.questionnaireId === questionnaire.id));

        // Find all Sessions where NowDate is between the questionnaire start and End Date.
        const liveCampaignSessions = sessions.filter(session => sessionQuestionnaires.find(questionnaire => moment().isBetween(questionnaire.startDate, questionnaire.endDate, 'day', '[]')));

        return liveCampaignSessions;
    }, [questionnaires, campaignItems]);

    // Find all the Campaign Sessions Where the Questionnaire is currently live.
    const upcomingCampaignItems = useMemo(() => {
        // Get all the Campaign Sessions.
        const sessions = campaignItems;

        // Now date
        const nowDate = moment();

        // Get all the questionnaires that match the sessions
        const sessionQuestionnaires = questionnaires.filter(questionnaire => sessions.find(session => session.questionnaireId === questionnaire.id));

        // Find all Sessions where NowDate is between the questionnaire start and End Date.
        const upcomingCampaignSessions = sessions.filter(session => sessionQuestionnaires.find(questionnaire => nowDate < moment(questionnaire.startDate) && session.questionnaireId === questionnaire.id));

        return upcomingCampaignSessions;
    }, [questionnaires, campaignItems]);

    const selfServiceItems = useMemo(() => {
        if (!questionnaireRespondentSessions) {
            return [];
        }

        return getTypeSessions(QuestionnaireType.SelfService);

    }, [getTypeSessions, questionnaireRespondentSessions]);

    // Generate the card components to show for the questionnaire,.
    const generateCards = useCallback((inputSessions: QuestionnaireRespondentSession[], options?: { questionnaireType?: QuestionnaireType, IsSessionEnded?: boolean, inline?: boolean, fullWidth?: boolean }) => (
        <>
            {
                inputSessions.map(respondentSession => {
                    // Lookup the questionnaire model.
                    const questionnaire = questionnaires.find(it => it.id === respondentSession.questionnaireId);

                    if (!questionnaire) {
                        return null;
                    }

                    // filter out wrong type of questionnaire
                    if (!!options?.questionnaireType && questionnaire.questionnaireType !== options?.questionnaireType) {
                        return null;
                    }

                    // filter out sessions that have not ended if we want finished sessions
                    if (!!options?.IsSessionEnded && !respondentSession.endDate) {
                        return null;
                    }

                    // if sessionended is not set assume we want open sessisons
                    if (!options?.IsSessionEnded && !!respondentSession.endDate) {
                        return null;
                    }

                    // find the sections and questions for the questionnaire
                    const sections = [...questionnaireSections].filter(item => item.questionnaireId === questionnaire.id);
                    const thisQuestions = [...questions].filter(item => item.questionnaireId === questionnaire.id);

                    // Find the user's responses that go with the session.
                    var originalSession = questionnaireRespondentSessions.find(item => item.id === respondentSession.id);
                    const responses = originalSession?.responses ?? [];

                    let cardLinkUrl = '';
                    if (!options?.IsSessionEnded) {
                        cardLinkUrl = `/home/questionnaire/start/${questionnaire.id}`;
                    } else {
                        cardLinkUrl = `/home/questionnaire/view/${questionnaire.id}`;
                    }

                    return (
                        <DashboardQuestionnaireCard key={respondentSession.id}
                            questionnaire={questionnaire}
                            questionnaireSections={sections}
                            questions={thisQuestions}
                            questionnaireRespondentSession={respondentSession}
                            questionResponses={responses}
                            cardClickLink={cardLinkUrl}
                        />
                    );
                })
            }
        </>
    ), [questionnaires, questions, questionnaireSections, questionnaireRespondentSessions]);

    //Toggle Self-Service Modal
    const [selfServiceModalIsOpen, toggleSelfServiceModal] = useToggleState();

    // Want to be able to save new response sessions.
    const [saveQuestionnaireUser, { errors: saveQuestionnaireUserErrors }] = useAddQuestionnaireUserCallback();

    // gets all questionnaires and templates for the subscription and all templates with no subscriptionId
    const { data: { items: allItems } } = useQuestionnaires({ pageSize: undefined, subscriptionId: SubscriptionId, includeAll: true, includeDefault: true });

    // get all  self service questionaires
    const allSelfService = useMemo(() => {
        if (!allItems) {
            return [];
        }
        let ret = allItems.filter(item => item.questionnaireType === QuestionnaireType.SelfService && !item.isTemplate && !item.archived).sort((a, b) => a.name > b.name ? 1 : -1);;
        return ret;
    }, [allItems]);

    //on Self-Service Modal Close create a respondant session for the current user for selectId questionnaire.
    const onSelfServiceModalClosed = useCallback(async (event: { selectedId: string | undefined, }) => {
        //if we have no selected ID do nothing
        if (!event?.selectedId) {
            return;
        }

        //create a respondant session for the selectedId questionnaire for the current user.
        await saveQuestionnaireUser({
            questionnaireId: event.selectedId,
            userId: profile?.userId ?? '',
            subscriptionId: SubscriptionId,
            subscriptionDepartmentId: profile?.subscriptionDepartmentId,
            lineManagerName: '',
            lineManagerEmail: '',
            email: profile?.user.email ?? '',
        },
            profile?.user.email ?? '',
        );

        refresh();
    }, [saveQuestionnaireUser, profile, SubscriptionId, refresh]);


    return (
        <Background>
            <Banner fluid>
                <Row className="bannerNoPills">
                    <Col xs={12}>
                        <h1>{t('meDashboard.heading.hi', 'Hi')}, {profile?.firstName ?? ''}</h1>
                    </Col>
                    <Col xs={12}>
                        <h2 className="sub-title">{
                            liveCampaignItems.length === 1 ?
                            t('meDashboard.subHeading.plural', `You currently have ${liveCampaignItems.length} questionnaire to complete`)
                                :
                                t('meDashboard.subHeading.plural', `You currently have ${liveCampaignItems.length} questionnaires to complete`)
                        }</h2>
                    </Col>
                </Row>
                <Row>
                </Row>
            </Banner>
            <MainContainer className="dashboard" fluid>
                <AlertOnErrors errors={[loadErrors, saveQuestionnaireUserErrors]} />

                <ConditionalFragment showIf={!!liveCampaignItems.length}>
                    {/* cards-or-table class is used here to get a consistent look and feel with real tables in card mode */}
                    <div className="cards-or-table cards-or-table-cards">
                        <h2 className="dashboard-section-header">
                            {t('meDashboard.heading.live', 'Live Questionnaires')}
                        </h2>

                        <Row className="dashboard-card-container">
                            {
                                generateCards(liveCampaignItems ?? [])
                            }
                        </Row>
                    </div>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!upcomingCampaignItems.length}>
                    {/* cards-or-table class is used here to get a consistent look and feel with real tables in card mode */}
                    <div className="cards-or-table cards-or-table-cards">
                        <h2 className="dashboard-section-header">
                            {t('meDashboard.heading.upcoming', 'Upcoming Questionnaires')}
                        </h2>

                        <Row className="dashboard-card-container">
                            {
                                generateCards(upcomingCampaignItems ?? [])
                            }
                        </Row>
                    </div>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!selfServiceItems.length}>
                    <div className="cards-or-table cards-or-table-cards">
                        <Row>
                            <Col xs={'auto'}>
                                <h2 className="dashboard-section-header">
                                    {t('meDashboard.heading.reflections', 'Personal Reflections')}
                                </h2>
                            </Col>
                            <Col xs={'auto'} className={'col-inline-heading-button'}>
                                <span data-toggle="tooltip" title={t('meDashboard.personal.tooltip', "You can initiate your own questionnaire to record your personal learning impacts from self driven learning.")}><FontAwesomeIcon icon="info-circle" /></span>
                            </Col>
                            <Col xs={'auto'} className={'col-inline-heading-button'}>
                                <ConditionalFragment showIf={!!allSelfService.length}>
                                    <Button color="primary" onClick={e => toggleSelfServiceModal(true)}>
                                        {t('common.startNewSelfService', 'Start A Personal Reflection')}
                                    </Button>
                                </ConditionalFragment>
                            </Col>

                        </Row>
                        <Row className="dashboard-card-container">
                            {
                                generateCards(selfServiceItems ?? [])
                            }
                        </Row>
                    </div>
                </ConditionalFragment>
                <ConditionalFragment showIf={!!historyItems?.length}>
                    <div className="cards-or-table cards-or-table-cards">
                        <h2 className="dashboard-section-header">
                            {t('meDashboard.history.heading', 'Completed')}
                        </h2>
                        <Row className="dashboard-card-container">
                            {
                                generateCards(historyItems ?? [], { IsSessionEnded: true, inline: true, fullWidth: true })
                            }
                        </Row>
                    </div>
                </ConditionalFragment>

                {/* if nothing to show then display a message */}
                <ConditionalFragment showIf={!historyItems.length && !selfServiceItems.length && !campaignItems.length}>
                    <Row>
                        <h2 className="dashboard-section-header">
                            {t('meDashboard.assessments.noContentHeading', 'Your Questionnaires')}
                        </h2>
                    </Row>
                    <Row className="mt-4">
                        <NoResultsFound />
                    </Row>
                    <ConditionalFragment showIf={!!allSelfService.length}>
                        <Row className="mt-2">
                            <Col xs="auto">
                                <Label>{t('meDashboard.nothingToShow.selfServiceLink', 'Why not begin your first personal reflection to get you started?')}</Label>
                            </Col>
                            <Col xs="auto">
                                <Button color="primary" onClick={e => toggleSelfServiceModal(true)}>
                                    {t('common.startNewSelfService', 'Start A Personal Reflection')}
                                </Button>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </ConditionalFragment>

                {/*Select SelfService Modal*/}
                <SelectSelfServiceModal
                    isOpen={selfServiceModalIsOpen}
                    toggle={toggleSelfServiceModal}
                    onClose={onSelfServiceModalClosed}
                />
            </MainContainer>
        </Background>
    );
};
