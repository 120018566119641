import { useState, useMemo, useCallback } from "react";
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { useSubscriptions } from '../../api/main/subscriptions/useSubscriptions';
import { useSubscriptionEmulation } from "../../globalState/subscriptions/useSubscriptionEmulation";
import moment from 'moment';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useDeleteSubscriptionCallback } from "../../api/main/subscriptions/useDeleteSubscriptionCallback";
import { DeleteAnyModal } from "../shared/DeleteAnyModal";

/**
 * List of subscriptions.
 */
export const SubscriptionsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSubscriptions({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    const [currentManagedSubscriptionId, manageSubscription] = useSubscriptionEmulation();

    const handleManage = useCallback((id) => {
        manageSubscription(id);
        history.push(`/manage`);
    }, [manageSubscription, history,]);

    const handleManageStop = useCallback((id) => {
        manageSubscription(null);
    }, [manageSubscription]);

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return [];
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    const displayItems = useMemo(() => {
        //Sort items by Name

        let ret = [...items];
        ret.sort((a, b) => {
            if (a.name === b.name) {
                return 0;
            } else if (a.name < b.name) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret
    }, [items]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();

    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteSubscriptionCallback();
    const [deletedItemName, setDeletedItemName] = useState<string>("");
    const [deletedItemId, setDeletedItemId] = useState<string>("");


    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerNoPills"}>
                    <Col>
                        <h1>{t('subscriptionsList.heading', 'Impacts Companies')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row className={"searchBar"}>
                        <Col xs="">
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to="/administration/subscriptions/add">
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('subscriptionsList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col className="text-right" xs={12} sm="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, removeErrors]} />
                <Row className="mb-2">
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>

                <CardsOrTable
                    viewMode={viewMode}
                    items={displayItems}
                    onItemClick={item => history.push(`/administration/subscriptions/edit/branding/${item.id}`)}
                    tableHeadings={[
                        t('subscriptionsList.name', 'Name'),
                        t('subscriptionsList.name', 'Limited'),
                        t('subscriptionsList.name', 'End date'),
                        t('subscriptionsList.name', 'Number of campaigns'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.name}</CardTitle>) : item.name,
                        (item, view) => (view === 'cards' ? (t('subscriptionList.limitedCard', 'Limited') + ' - ') : '') + (item.isLimitedSubscription ? 'Yes' : 'No'),
                        (item, view) => view === 'table' ? (item.isLimitedSubscription && !!item.endDate ? moment(item.endDate).format("L") : '-') : null,
                        (item, view) => (view === 'cards' ? (t('subscriptionList.numberOfCampaignsCard', 'Number Of Campaigns') + ' - ') : '') + (item.isLimitedSubscription ? item.numberOfQuestionnaires : '0'),
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`/administration/subscriptions/edit/branding/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ConditionalFragment showIf={currentManagedSubscriptionId !== item.id}>
                                <Button color="primary" outline onClick={() => handleManage(item.id)}>
                                    <FontAwesomeIcon icon="clone" />
                                    <> {t('administration.manage', 'Manage')}</>
                                </Button>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={currentManagedSubscriptionId === item.id}>
                                <Button color="primary" onClick={() => handleManageStop(item.id)}>
                                    <FontAwesomeIcon icon="clone" />
                                    <> {t('administration.manageStop', 'Stop managing')}</>
                                </Button>
                            </ConditionalFragment>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); setDeletedItemName(item.name); }}>
                                        <FontAwesomeIcon icon="trash" />
                                        <> {t('common.delete', 'Delete')}</>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>


                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={deletedItemName}
                        remove={() => remove(deletedItemId)}
                    />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
