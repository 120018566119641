import { View, Image, Text } from "@react-pdf/renderer";
import { AnalyserReportFooterStyles } from "./analyserReportFooterStyles";

//* Common Footer for Analyser reports
interface AnalyserReportFooterProps {
    impactsLogoUrl: string,
    isSplashPage?: boolean,
}

export const AnalyserReportFooter = (props: AnalyserReportFooterProps) => {
    const {
        impactsLogoUrl, isSplashPage = false,
    } = props;


    return (
        <View fixed style={[AnalyserReportFooterStyles.footer, { borderColor: isSplashPage ? 'white' : 'black' }]}>
            <View style={AnalyserReportFooterStyles.footerContainer}>
                <View style={AnalyserReportFooterStyles.footerPageNumberContainer} render={({ pageNumber }) => (
                    pageNumber !== 1 && (
                        <View >
                            <Text style={AnalyserReportFooterStyles.footerPageNumber}>{pageNumber - 1}</Text>
                        </View>
                    )
                )} />
                <Image style={AnalyserReportFooterStyles.impactsImageFooter} source={impactsLogoUrl} />
            </View>
        </View>
    );
};