import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { QuestionnaireAnalysisTypeLinkUpdateInput } from "../generated/globalTypes";
import { updateQuestionnaireAnalysisTypeLinkMutation, updateQuestionnaireAnalysisTypeLinkMutationVariables } from "../generated/updateQuestionnaireAnalysisTypeLinkMutation";
import { questionnaireAnalysisTypeLinkFieldsFragment } from "../models/QuestionnaireAnalysisTypeLink";


export function useUpdateQuestionnaireAnalysisTypeLinkCallback(): [(id: string, model: QuestionnaireAnalysisTypeLinkUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateQuestionnaireAnalysisTypeLinkMutation, updateQuestionnaireAnalysisTypeLinkMutationVariables>(
        gql`
            mutation updateQuestionnaireAnalysisTypeLinkMutation ($model: QuestionnaireAnalysisTypeLinkUpdateInput!) {
                updateQuestionnaireAnalysisTypeLink(model: $model) {
                    ...questionnaireAnalysisTypeLinkFields
                }
            }

            ${questionnaireAnalysisTypeLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: QuestionnaireAnalysisTypeLinkUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}