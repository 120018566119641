import * as React from 'react';
import { SubscriptionTemplateLinkCreateInput, SubscriptionTemplateLinkUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSubscriptionTemplateLinkCallback } from './useCreateSubscriptionTemplateLinkCallback';
import { useUpdateSubscriptionTemplateLinkCallback } from './useUpdateSubscriptionTemplateLinkCallback';
import { SubscriptionTemplateLink } from '../models/SubscriptionTemplateLink';

/**
 * Get a callback to save a SubscriptionTemplateLink in the store using either a create or update.
 */
export function useSaveSubscriptionTemplateLinkCallback(options: SaveInStoreOptions<SubscriptionTemplateLink, string> = {}) {
    const [_create, createStatus] = useCreateSubscriptionTemplateLinkCallback();
    const create = React.useCallback((model: Partial<SubscriptionTemplateLink>) => _create(model as SubscriptionTemplateLinkCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSubscriptionTemplateLinkCallback();
    const update = React.useCallback((id: string, changes: Partial<SubscriptionTemplateLink>) => _update(id, changes as SubscriptionTemplateLinkUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
