import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { CompanyQuestionnaireReport } from "./CompanyQuestionnaireReport";
import { IndividualQuestionnaireReport } from "./IndividualQuestionnaireReport";
import { ReportPackageBuilder } from "./ReportPackageBuilder";


export const reportingRoutes: Array<RouteEntry> = [

    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/section/:sectionId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/department/:departmentId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/department/:departmentId/section/:sectionId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/user/:userId/:reportCustomiser', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/view/report/:questionnaireId/user/:userId/section/:sectionId/:reportCustomiser', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/quick/view/report/:questionnaireId/user/:userId', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/quick/view/report/:questionnaireId', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration }, 
    { path: '/subscriptionQuestionnaires/build/report/:questionnaireId', exact: true, component: ReportPackageBuilder, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    //Path for User Level
    { path: '/me/view/report/:questionnaireId/user/:userId', exact: true, component: IndividualQuestionnaireReport, authorize: true, },

    //Path for department admins to view a questionnaire
    { path: '/departmentQuestionnaires/view/report/:questionnaireId/department/:departmentId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/view/report/:questionnaireId/department/:departmentId/section/:sectionId/:reportCustomiser', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/view/report/:questionnaireId/user/:userId/:reportCustomiser', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/view/report/:questionnaireId/user/:userId/section/:sectionId/:reportCustomiser', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/departmentQuestionnaires/quick/view/report/:questionnaireId/user/:userId', exact: true, component: IndividualQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },

    { path: '/departmentQuestionnaires/quick/view/report/:questionnaireId', exact: true, component: CompanyQuestionnaireReport, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },

    { path: '/manageDepartment/build/report/department/:departmentId/:questionnaireId', exact: true, component: ReportPackageBuilder, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
];