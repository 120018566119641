import { Col, Row } from "reactstrap";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { CompletionProgressBar } from "../../questionnaireRespondentSessions/progress/CompletionProgressBar";

export interface PercentageProgressBarProps {
    value: number,
    title?: string,
    footer?: string,
    questionnaireType: QuestionnaireType,
    heading?: string,
}

/**
 * Show an individual score as a progress bar, with supporting data around it.
 */
export const PercentageProgressBar = (props: PercentageProgressBarProps) => {
    const {
        value,
        footer,
        questionnaireType,
        heading,
    } = props;

    return (
        <div className="text-center" style={{ width: '100%' }}>
            <Row>
                <Col>
                </Col>
                <Col className="text-center" xs="auto">
                    <CompletionProgressBar
                        value={value}
                        questionnaireType={questionnaireType}
                        heading={heading}
                    />
                </Col>
                <Col>
                </Col>
            </Row>
            <div className="text-muted">
                <small>
                    {footer}
                </small>
            </div>
        </div>
        );
};