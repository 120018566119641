import { useState, useMemo, useEffect, useCallback } from "react";
import { Button, Row, Col, Form, Input, Spinner } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { useHistory, useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useSubscriptionTemplateLinks } from '../../api/main/subscriptionTemplateLinks/useSubscriptionTemplateLinks';
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { EditNavigation } from "../subscriptions/EditNavigation";
import { useQuestionnaires } from "../../api/main/questionnaires/useQuestionnaires";
import "./subscriptionTemplateLinksList.scss";
import { useAsyncCallback } from "react-use-async-callback";
import { SubscriptionTemplateLink, subscriptionTemplateLinkDefaultValues } from "../../api/main/models/SubscriptionTemplateLink";
import { useSaveSubscriptionTemplateLinkCallback } from "../../api/main/subscriptionTemplateLinks/useSaveSubscriptionTemplateLinkCallback";
import { useDeleteSubscriptionTemplateLinkCallback } from "../../api/main/subscriptionTemplateLinks/useDeleteSubscriptionTemplateLinkCallback";
import { FormButtons } from "../shared/FormButtons";
import { ButtonAsync } from "reactstrap-buttonasync";
import { ManageNavigation } from "../manage/ManageNavigation";
import { useSubscription } from "../../api/main/subscriptions/useSubscription";
import { defaultTemplateId } from "../../api/main/models/codeOnly/QuestionnaireType";

/**
 * List of subscriptionTemplateLinks for a subscription.
 */
export const SubscriptionTemplateLinksList = () => {
    const { t } = useTranslation();

    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const history = useHistory();

    const { id, } = useParams<{ id: string | undefined }>(); // subscription id - this should only be present for skillshub admins not subscription admins

    const isCompanyAdminAccess = useMemo(() => { return !id }, [id]);

    const { data: { items: allItems }, isLoading: _isLoading, errors: loadingErrors, fetchMore, hasMore } = useSubscriptionTemplateLinks({ pageSize: undefined, subscriptionId: !isCompanyAdminAccess ? id : currentUserSubscriptionId });

    const { data: { model: subscription } } = useSubscription(!isCompanyAdminAccess ? id : currentUserSubscriptionId);

    const [save, { errors: saveErrors }] = useSaveSubscriptionTemplateLinkCallback();
    const [remove] = useDeleteSubscriptionTemplateLinkCallback();

    // get all the top level templates
    const { data: { items: templates }, isLoading: isLoadingTemplates } = useQuestionnaires({ pageSize: undefined, isTemplate: true, includeDefault: true });
    const topLevelTemplates = useMemo(() => {
        if (!templates) {
            return;
        }

        // we want all the templates that do not belong to a subscription
        // ordered by name with the default template pushed down to the bottom
        let fullRet = templates.filter(item => !item.subscriptionId).sort((a, b) => {
            let aName = a.name;
            let bName = b.name;

            if (a.id.toUpperCase() === defaultTemplateId) {
                aName = `zzzzzz${aName}` // always to the bottom
            }
            if (b.id.toUpperCase() === defaultTemplateId) {
                bName = `zzzzzz${bName}` // always to the bottom
            }
            if (aName === bName) {
                return 0;
            } else if (aName < bName) {
                return -1;
            } else {
                return 1;
            }
        });

        //if we are working inside a subscription we just want to see templates linked to this subscription
        if (isCompanyAdminAccess) {
            return fullRet.filter(item => {
                if (!!allItems?.find(ai => ai.questionnaireId === item.id)) {
                    return true;
                }
                return false;
            })
        } else {
            return fullRet;
        }

    }, [templates, isCompanyAdminAccess, allItems]);

    const isLoading = _isLoading || isLoadingTemplates;

    // At skillshub admin level these settings show if a template is linked to the subscription or not.
    // At a subscription administrator level these settings show whether linked templates are hidden within the company 
    const [linkSettings, setLinkSettings] = useState<{ key: string, linkedValue: boolean, hiddenValue: boolean }[]>([]);
    useEffect(() => {
        if (!allItems || !topLevelTemplates) {
            return;
        }

        // only do this once
        if (linkSettings.length > 0) {
            return;
        }

        // set up an empty array
        let newSettings: { key: string, linkedValue: boolean, hiddenValue: boolean }[] = [];

        // add an entry to the array for each template
        topLevelTemplates.forEach((template) => {
            // default to not linked
            let thisItem = { key: template.id, linkedValue: false, hiddenValue: false }
            // see if we have a link for this subscription and if the link is hidden by the subscription admin
            if (!!allItems.find(ai => ai.questionnaireId === template.id)) {
                thisItem.linkedValue = true;
                thisItem.hiddenValue = allItems.find(ai => ai.questionnaireId === template.id)?.isHiddenBySubscriptionAdministrator ?? false;
            }
            newSettings.push(thisItem);
        })

        // set up the array
        setLinkSettings(newSettings);

    }, [allItems, topLevelTemplates, linkSettings, setLinkSettings]);

    // at the skillshub admin level we are dealing with linking subscriptions to templates
    // so each time a check box is changed we need to update the linkSettings
    const handleLinkChange = useCallback((templateKey: string) => {
        let settings = [...linkSettings];

        const itemIndex = settings.findIndex(item => item.key === templateKey);

        // toggle value
        if (!!isCompanyAdminAccess) {
            settings[itemIndex].hiddenValue = !settings[itemIndex].hiddenValue;
        } else {
            settings[itemIndex].linkedValue = !settings[itemIndex].linkedValue;
        }

        // update the link settings
        setLinkSettings(settings);

    }, [setLinkSettings, linkSettings, isCompanyAdminAccess]);

    // the route to the required view on leaving this one
    const backRoute = !isCompanyAdminAccess ? '/administration/subscriptions' : '/manage/subscriptionDepartments';

    // save of link settings
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {

        // deal with each potential template link
        linkSettings.forEach((link) => {

            // look for an existing link
            let thisLink: Partial<SubscriptionTemplateLink> = allItems.find(item => item.questionnaireId === link.key) as Partial<SubscriptionTemplateLink>;

            if (!isCompanyAdminAccess) {
                // working on kinks at skillshub admin level


                if (!thisLink && link.linkedValue) {
                    // we want a link and we do not have one
                    save('', { ...subscriptionTemplateLinkDefaultValues(), subscriptionId: !isCompanyAdminAccess ? id : currentUserSubscriptionId ?? '', questionnaireId: link.key }, true);
                } else if (!!thisLink && !link.linkedValue) {
                    // we have a link but we don't want one
                    remove(thisLink.id ?? '');
                }
                // everything else is as it should be
            } else {
                // working on hidden settings at a sunbscription admin level
                if (thisLink.isHiddenBySubscriptionAdministrator !== link.hiddenValue) {
                    // hidden setting is changing
                    save(thisLink.id ?? '', { isHiddenBySubscriptionAdministrator: link.hiddenValue }, false);
                }
            }

        });

        // go back to the Companies list
        history.push(backRoute);

    }, [linkSettings, save]);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12}>
                        {
                            !!isCompanyAdminAccess ?
                                <PillsNavBar>
                                    <ManageNavigation
                                    />
                                </PillsNavBar>
                                :
                                <PillsNavBar>
                                    <EditNavigation
                                        subscriptionId={!isCompanyAdminAccess ? id ?? '' : currentUserSubscriptionId ?? ''}
                                    />
                                </PillsNavBar>
                        }
                    </Col>
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col xs={12} sm="">
                        <h1>
                            {
                                !isCompanyAdminAccess ?
                                    t('subscriptionTemplateLinksList.heading.skillshubadmin', `Manage Standard Templates For ${subscription?.name ?? 'Subscription'}`)
                                    :
                                    t('subscriptionTemplateLinksList.heading.subscriptionadmin', 'Manage Standard Templates')
                            }
                        </h1>
                    </Col>

                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer fluid className="subscription-template-list-main-container">
                <AlertOnErrors errors={[loadingErrors, saveErrors, saveFormErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row className="mt-3 mb-3">
                        <Col xs={12} className="mb-2">
                            {isCompanyAdminAccess ?
                                <span className="text-muted">
                                    {t('subscriptionTemplateLinksList.helpText.companyAdmin', 'These are the fixed templates that come with your subscription. You cannot change these, however you may decide to hide some or all of them within your subscription. Select the check box to hide any templates you do not want your people to use. Remember to choose Save Settings when you are finished.')}
                                </span>
                                :
                                <span className="text-muted">
                                    {t('subscriptionTemplateLinksList.helpText.skillshubAdmin', `These are the standard fixed templates that can be provided with each company. Select the check box to include any templates you want to provide for ${subscription?.name ?? 'this company'}. Remember to choose Save Settings when you are finished.`)}
                                </span>
                            }
                        </Col>
                    </Row>
                    <CardsOrTable
                        // always show as list as the cards look wrong when processing the check box
                        viewMode={'table'}
                        items={topLevelTemplates ?? []}
                        tableHeadings={[
                            t('subscriptionTemplateLinksList.name', 'Name'),
                            t('subscriptionTemplateLinksList.templateDescription', 'Description'),
                            isCompanyAdminAccess ? t('subscriptionTemplateLinksList.hide', 'Hide') : t('subscriptionTemplateLinksList.include', 'Include'),
                        ]}
                        columns={[
                            (item) => item.id.toUpperCase() === defaultTemplateId ? <span className="highlighted-name">{item.name}</span> : item.name,
                            (item) => item.id.toUpperCase() === defaultTemplateId ? <span className="highlighted-name">{item.templateDescription}</span> : item.templateDescription,
                            (item) =>
                                <Input
                                    checked={!isCompanyAdminAccess ? linkSettings.find(ls => ls.key === item.id)?.linkedValue ?? false : linkSettings.find(ls => ls.key === item.id)?.hiddenValue ?? false}
                                    type="checkbox"
                                    onChange={() => handleLinkChange(item.id)}
                                />
                        ]}
                    />
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                onClick={async () => { await saveForm(); }}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('subscriptionTemplateLinksList.saveSettings', `Save Settings`)}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.push(backRoute)}>
                            {t('common.close', 'Close')}
                        </Button>
                    </FormButtons>
                </Form>
                <ConditionalFragment showIf={isLoading && !topLevelTemplates?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !topLevelTemplates?.length}>
                    <NoResultsFound search={''} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={topLevelTemplates?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};