import { useState, useMemo, } from "react";
import { Button, Row, Col, ButtonGroup, } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import moment from "moment";
import { QuestionnaireType, questionnaireTypeDisplayName } from "../../api/main/models/codeOnly/QuestionnaireType";
import { useParams } from 'react-router';
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useQuestionnaireListViewModel } from "../../api/main/questionnaires/viewModels/useQuestionnaireListViewModel";
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { SubscriptionQuestionnairesNavigation } from "../subscriptionQuestionnaires/SubscriptionQuestionnairesNavigation";
import "./questionnairesListBase.scss";
import { TwoValueSwitch } from '../shared/TwoValueSwitch';
import { GetSortOptionRadioButton } from "../shared/SortOptionRadioButton";
import { QuestionnaireTableComponent } from "./QuestionnaireTableComponent";
import { useQuestionnaireListViewModelQuery_items } from "../../api/main/generated/useQuestionnaireListViewModelQuery";
import { DepartmentQuestionnairesNavigation } from "../departmentQuestionnaires/DepartmentQuestionnairesNavigation";


export interface QuestionnairesListBaseProps {
    isDepartment?: boolean,
    isSubscription?: boolean,
}

/**
 * Base List of questionnaires.
 */
export const QuestionnairesListBase = (props: QuestionnairesListBaseProps) => {
    const {
        isDepartment = false,
        isSubscription = false,
    } = props;

    const { departmentId, type } = useParams<{ departmentId: string | undefined, type: string | undefined }>();
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const baseRoute = useMemo(() => {
        if (!isSubscription) {
            return '/administration/questionnaires/Template/';
        }
        if (!!departmentId) {
            return '/departmentQuestionnaires/';
        }
        if (!!isSubscription) {
            return '/subscriptionQuestionnaires/'
        }
        //default to subscription level
        return '/manage/questionnaires/';
    }, [isSubscription, departmentId]);

    const { t } = useTranslation();

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useQuestionnaireListViewModel({ pageSize: undefined, subscriptionId: !!isSubscription && !!currentUserSubscriptionId ? currentUserSubscriptionId : null, subscriptionDepartmentId: departmentId ?? undefined, isTemplate: !!type && type === 'Template' ? true : false, questionnaireType: type === 'Template' ? undefined : type });

    const defaultEmptyNameText = t("questionnaireListBase.defaultEmptyNameText", "This item has no name");

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return [];
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );

    }, [allItems, search]);

    //Set Sort Order
    const [sortOrder, setSortOrder] = useState<string>("");

    //Default Sort Order
    const defaultSortOrder = useMemo(() => {
        let ret = 'name';
        if (!!type && type === QuestionnaireType.Campaign) {
            ret = 'startDate';
        }
        setSortOrder(ret);

        return ret;
    }, [type, setSortOrder]);


    // filter if required
    const displayItems = useMemo(() => {
        let ret = [...items];

        const useSortOrder = !!sortOrder ? sortOrder : defaultSortOrder;

        switch (useSortOrder) {
            case 'name':
                // sort by name Asc
                ret.sort((a, b) => {
                    if (a.name === b.name) {
                        return 0;
                    } else if (a.name < b.name) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
            case 'endDate':
                // sort by end date Desc
                ret.sort((a, b) => +new Date(b.endDate) - +new Date(a.endDate));
                break;
            case 'department':
                // sort by department Asc
                ret.sort((a, b) => (a.subscriptionDepartment?.name ?? '').localeCompare(b.subscriptionDepartment?.name ?? '') ||
                    a.name.localeCompare(b.name));
                break;
            case 'type':
                // sort by type Asc
                ret.sort((a, b) => (a.questionnaireType.localeCompare(b.questionnaireType)));
                break;
            case 'startDate':
                // sort by startDate Desc
                ret.sort((a, b) => +new Date(a.startDate) - +new Date(b.startDate));
                break;
            default:
                // sort by startDate Desc
                ret.sort((a, b) => +new Date(b.startDate) - +new Date(a.startDate));
                break;
        }
        return ret
    }, [items, sortOrder, defaultSortOrder]);

    const displayItemsGroups: {
        unpublishedCampaignsDisplayItems: useQuestionnaireListViewModelQuery_items[] | undefined,
        liveCampaignsDisplayItems: useQuestionnaireListViewModelQuery_items[] | undefined,
        upcomingCampaignsDisplayItems: useQuestionnaireListViewModelQuery_items[] | undefined,
        finishedCampaignsDisplayItems: useQuestionnaireListViewModelQuery_items[] | undefined,
    } | undefined = useMemo(() => {
        if (!displayItems) {
            return;
        }

        return {
            unpublishedCampaignsDisplayItems: displayItems.filter(item => !item.isPublished),
            liveCampaignsDisplayItems: displayItems.filter(item => !!item.isPublished && moment().isBetween(item.startDate, item.endDate, 'day', '[]')),
            upcomingCampaignsDisplayItems: displayItems.filter(item => !!item.isPublished && moment().isBefore(item.startDate, 'day')),
            finishedCampaignsDisplayItems: displayItems.filter(item => !!item.isPublished && moment().isAfter(item.endDate,'day'))
        }

    }, [displayItems]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();

    // Show Finished Campaigns 
    const [showFinishedItems, setShowFinishedItems] = useState<boolean>(false);

    // banner class
    const bannerClass = useMemo(() => {
        return isSubscription || isDepartment ? 'bannerWithPills' : 'bannerNoPills';
    }, [isSubscription, isDepartment,]);

    return (
        <Background>
            <Banner fluid>

                <Row>
                    <Col xs="">
                        <ConditionalFragment showIf={!isDepartment && !!isSubscription}>
                            <PillsNavBar>
                                <SubscriptionQuestionnairesNavigation />
                            </PillsNavBar>
                        </ConditionalFragment>
                        <PillsNavBar>
                            <DepartmentQuestionnairesNavigation
                                id={departmentId ?? ''}
                            />
                        </PillsNavBar>
                    </Col>
                </Row>
                <Row className={bannerClass}>
                    <Col>
                        {
                            !!type && type === QuestionnaireType.SelfService ?
                                <>
                                    <h1>{t('editQuestionnaireBase.Title', `Get Started Creating A ${questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)} Campaign`)}</h1>
                                    <h2 className="sub-title">{t('questionnairesListBase.subTitle', 'In 5 easy steps')}</h2>
                                </>
                                : !type || (!!type && type === QuestionnaireType.Campaign) ?
                                    <>
                                        <h1>{t('editQuestionnaireBase.Title', `Get Started Creating A Campaign`)}</h1>
                                        <h2 className="sub-title">{t('questionnairesListBase.subTitle', 'In 5 easy steps')}</h2>
                                    </>
                                    :
                                    <>
                                        <h1>{t('editQuestionnaireBase.Title', `Get Started Creating A Template`)}</h1>
                                        <h2 className="sub-title">&nbsp;</h2> {/* this added to make spacing standard on banner */}
                                    </>
                        }
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row>
                    <Col>
                        <LinkContainer to={`${baseRoute}${!!departmentId ? departmentId + "/" : ""}add/${!!type ? type : "Template"}`}>
                            <Button color="primary">
                                {!type || type === 'Template' ? <> {t('questionnairesListBase.create.template', 'Create Template')}</>
                                    : <>{t('questionnairesListBase.create.campaign', 'Create Campaign')}</>}

                            </Button>
                        </LinkContainer>
                    </Col>
                </Row>
                <StickyToolbar>
                    <Row className="searchBar with-sub-title">
                        <Col xs=""></Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors,]} />
                <Row>
                    <Col xs={12} sm={4}>
                        <ConditionalFragment showIf={!!displayItemsGroups?.finishedCampaignsDisplayItems && !!displayItemsGroups.finishedCampaignsDisplayItems.length && type === QuestionnaireType.Campaign}>
                            <div className="mt-2 mb-2">
                                <TwoValueSwitch
                                    leftLabel={t('questionnairesListBase.switchvalue1', '')}
                                    rightLabel={t('questionnairesListBase.switchvalue2', 'Show Finished Campaigns')}
                                    checked={showFinishedItems}
                                    onChange={checked => setShowFinishedItems(checked)}
                                />
                            </div>
                        </ConditionalFragment>
                    </Col>

                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>


                    {/* Radio Buttons for sorting*/}
                    <Col className="sort-fields-column" xs={12} lg="">
                        <div className="mt-2 mb-2">
                            <span className={"sortOptions"}>{t('questionnairesListBase.sortOptionsLabel', 'Sort:')}</span>
                            <> </>
                            <ConditionalFragment showIf={!!type && type === QuestionnaireType.Campaign}>
                                {GetSortOptionRadioButton('startDate', sortOrder, defaultSortOrder, setSortOrder)}
                                {GetSortOptionRadioButton('endDate', sortOrder, defaultSortOrder, setSortOrder)}
                            </ConditionalFragment>
                            {GetSortOptionRadioButton('name', sortOrder, defaultSortOrder, setSortOrder)}
                            <ConditionalFragment showIf={!!isSubscription && !departmentId}>
                                {GetSortOptionRadioButton('department', sortOrder, defaultSortOrder, setSortOrder)}
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!!type && type === 'Template'}>
                                {GetSortOptionRadioButton('type', sortOrder, defaultSortOrder, setSortOrder)}
                            </ConditionalFragment>
                        </div>
                    </Col>
                </Row>

                {/**********   Self Service and Templates   **********/}

                <ConditionalFragment showIf={!!type && type !== QuestionnaireType.Campaign}>
                    <QuestionnaireTableComponent
                        items={displayItems}
                        cardClassName={`card-${type?.toLowerCase()}`} // there will always be a type or we wouldn't be showing this
                        viewMode={viewMode}
                        baseRoute={baseRoute}
                        type={type}
                        isDepartment={isDepartment}
                        isSubscription={isSubscription}
                        departmentId={departmentId}
                        defaultEmptyNameText={defaultEmptyNameText}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={!!type && type === QuestionnaireType.Campaign}>

                    {/**********   Campaigns   **********/}

                    <QuestionnaireTableComponent
                        items={displayItemsGroups?.liveCampaignsDisplayItems ??[]}
                        title={"Live Campaigns"}
                        cardClassName={`card-${type?.toLowerCase()}`} // there will always be a type or we wouldn't be showing this
                        viewMode={viewMode}
                        baseRoute={baseRoute}
                        type={type}
                        isDepartment={isDepartment}
                        isSubscription={isSubscription}
                        departmentId={departmentId}
                        defaultEmptyNameText={defaultEmptyNameText}
                    />
                    <QuestionnaireTableComponent
                        items={displayItemsGroups?.upcomingCampaignsDisplayItems ?? []}
                        title={"Upcoming Campaigns"}
                        cardClassName={`card-${type?.toLowerCase()}`} // there will always be a type or we wouldn't be showing this
                        viewMode={viewMode}
                        baseRoute={baseRoute}
                        type={type}
                        isDepartment={isDepartment}
                        isSubscription={isSubscription}
                        departmentId={departmentId}
                        defaultEmptyNameText={defaultEmptyNameText}
                    />
                    <QuestionnaireTableComponent
                        items={displayItemsGroups?.unpublishedCampaignsDisplayItems ?? []}
                        title={"Campaigns Being Built"}
                        cardClassName={`card-${type?.toLowerCase()}`} // there will always be a type or we wouldn't be showing this
                        viewMode={viewMode}
                        baseRoute={baseRoute}
                        type={type}
                        isDepartment={isDepartment}
                        isSubscription={isSubscription}
                        departmentId={departmentId}
                        defaultEmptyNameText={defaultEmptyNameText}
                    />
                    <ConditionalFragment showIf={!!showFinishedItems}>
                        <QuestionnaireTableComponent
                            items={displayItemsGroups?.finishedCampaignsDisplayItems ?? []}
                            title={"Finished Campaigns"}
                            cardClassName={`card-${type?.toLowerCase()}`} // there will always be a type or we wouldn't be showing this
                            viewMode={viewMode}
                            baseRoute={baseRoute}
                            type={type}
                            isDepartment={isDepartment}
                            isSubscription={isSubscription}
                            departmentId={departmentId}
                            defaultEmptyNameText={defaultEmptyNameText}
                        />
                    </ConditionalFragment>
                </ConditionalFragment>
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading &&
                    (
                        !items?.length
                        ||
                    (!!type && type === QuestionnaireType.Campaign && !displayItemsGroups?.liveCampaignsDisplayItems?.length && !displayItemsGroups?.upcomingCampaignsDisplayItems?.length && !displayItemsGroups?.unpublishedCampaignsDisplayItems?.length && !showFinishedItems)
                        ||
                    (!!type && type === QuestionnaireType.Campaign && !displayItemsGroups?.liveCampaignsDisplayItems?.length && !displayItemsGroups?.upcomingCampaignsDisplayItems?.length && !displayItemsGroups?.unpublishedCampaignsDisplayItems?.length && !displayItemsGroups?.finishedCampaignsDisplayItems?.length && !!showFinishedItems)
                    )
                }>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};