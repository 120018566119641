import gql from "graphql-tag";
import { Guid } from "guid-string";
import { subscriptionDepartmentFields } from "../generated/subscriptionDepartmentFields";

export const subscriptionDepartmentFieldsFragment = gql`
    fragment subscriptionDepartmentFields on SubscriptionDepartment {
        id
        subscriptionId
        name
        archived
    }
`;


export type SubscriptionDepartment = Omit<subscriptionDepartmentFields, '__typename'>;

export const subscriptionDepartmentDefaultValues = (): Partial<SubscriptionDepartment> => ({
    id: Guid.newGuid(),
    subscriptionId: undefined,
    name: '',
    archived: false,
});