import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";


export type AdminGroupLevel = 'Questionnaire' | 'Department' | 'Subscription';
/**
 * Data for a chart that shows completion data broken down by groups.
 */
export function useCompletionChartDataForQuestionnaires(groups: Array<{ id: string, name: string }>, sessions: Array<QuestionnaireRespondentSession>, groupLevel: AdminGroupLevel): Array<ChartDataSeries> {
    const { t } = useTranslation();


    const generateSeries = useCallback((seriesName: string, getValue: (mySessions: Array<QuestionnaireRespondentSession>) => number) => {
        // Generate chart data for each group.
        const data = groups.map(group => {
            let mySessions: Array<QuestionnaireRespondentSession> = [];
            if (groupLevel === 'Questionnaire') {
                mySessions = sessions.filter(item => item.userId === group.id);
            } else if (groupLevel === 'Department') {
                mySessions = sessions.filter(item => item.questionnaireId === group.id);
            } else if (groupLevel === 'Subscription') {
                mySessions = sessions.filter(item => item.questionnaireId === group.id);
            }


            return {
                text: group.name,
                value: getValue(mySessions),
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [groups, sessions, groupLevel]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                t('useCompletioneChartData.complete.seriesName', 'Completed'),
                mySessions => mySessions.filter(session => !!session.endDate).length
            ),
            generateSeries(
                t('useCompletioneChartData.started.seriesName', 'Started'),
                mySessions => mySessions.filter(session => !!session.startDate && !session.endDate).length
            ),
            generateSeries(
                t('useCompletioneChartData.notStarted.seriesName', 'Not Started'),
                mySessions => mySessions.filter(session => !session.startDate && !session.endDate).length
            ),
        ];
    }, [generateSeries, t]);

    return ret;
}
