import { Text, View } from "@react-pdf/renderer";
import { Guid } from "guid-string";
import { ConditionalFragment } from "react-conditionalfragment";
import { Question } from "../../../../api/main/models/Question";
import { QuestionResponse } from "../../../../api/main/models/QuestionResponse";
import { ActionsPageStyles } from "./actionsPageStyles";
import { AnalyserResponse } from "./AnalyserResponses";

//* Common reporting page for analyser reports - Performance Page
interface ActionsPageProps {
    companyColor: string,
    actionsLearners: Array<{ id: string, firstName: string, lastName: string }>,
    actionQuestions: Question[],
    actionResponses: QuestionResponse[],
    currencySymbol: string,
}

export const ActionsPage = (props: ActionsPageProps) => {
    const {
        companyColor, actionsLearners, actionQuestions, actionResponses, currencySymbol,
    } = props;

    return (
        <>
            {
                actionQuestions.map((question, questionIndex) => {
                    const questionResponses = actionResponses.filter(response => response.questionId === question.id);
                    const questionResponseLearners = questionResponses.map(response => actionsLearners.find(learner => learner.id === response.userId));
                    // need unique learners
                    const uniqueResponseLearners = [...new Set(questionResponseLearners.map(r => r))];


                    return (
                        <View key={questionIndex}>
                            <View style={ActionsPageStyles.subHeadingContainer}>
                                <Text style={ActionsPageStyles.subHeadingBold} >{question.questionText}</Text>
                            </View>

                            <View>
                                {
                                    uniqueResponseLearners.map((learner, index) => {
                                        // Responses for this question from this learner
                                        const learnerQuestionResponses = questionResponses.filter(response => response.userId === learner?.id ?? Guid.empty.toString());

                                        return (
                                            <View key={index} style={ActionsPageStyles.learnerResponsesContainer}>
                                                <ConditionalFragment showIf={actionsLearners.length > 1}>
                                                    <Text style={ActionsPageStyles.learnerName}>{`${learner?.firstName ?? ''} ${learner?.lastName ?? ''} -`}</Text>
                                                </ConditionalFragment>
                                                <AnalyserResponse
                                                    companyColor={companyColor}
                                                    question={question}
                                                    responses={learnerQuestionResponses}
                                                    preText="Action"
                                                    currencySymbol={currencySymbol}
                                                    questionIndex={questionIndex}
                                                />
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    )
                })
            }
        </>
    );
};