import * as React from 'react';
import { Login } from './Login'
import { Logout } from './Logout'
import { RouteEntry } from '../../shared/routes';

export const apiAuthorizationRoutes: Array<RouteEntry> = [
    { path: '/authentication/login', render: () => loginAction('login') },
    { path: '/authentication/login-callback', render: () => loginAction('login-callback') },
    { path: '/authentication/login-failed', render: () => loginAction('login-failed') },
    { path: '/authentication/profile', render: () => loginAction('profile') },
    { path: '/authentication/register', render: () => loginAction('register') },

    { path: '/authentication/logout', render: () => logoutAction('logout') },
    { path: '/authentication/logout-callback', render: () => logoutAction('logout-callback') },
    { path: '/authentication/logged-out', render: () => logoutAction('logged-out') },
];

function loginAction(name: string){
    return (<Login action={name} />);
}

function logoutAction(name: string) {
    return (<Logout action={name} />);
}
