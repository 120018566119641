import { useTranslation } from "react-i18next";
import { RegisteredUsersListBase } from "../../users/RegisteredUsersListBase";
import { useParams } from 'react-router';
import { useSubscriptionDepartment } from "../../../api/main/subscriptionDepartments/useSubscriptionDepartment";


/**
 * List of users for a subscription department accessed at department administrator level
 */
export const SubscriptionDepartmentAdminUsersList = () => {
    const { t } = useTranslation();
    const { departmentId } = useParams<{ departmentId: string | undefined }>();
    const { data: { model } } = useSubscriptionDepartment(departmentId ?? undefined);

    return (
        <RegisteredUsersListBase
            title={t('subscriptionDepartmentAdminUsersList.title', `${model?.name} Users`)}
            baseRoute={'/manageDepartment/users'}
        />
    );
};