import * as React from 'react';
import { AnalysisTypeCreateInput, AnalysisTypeUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateAnalysisTypeCallback } from './useCreateAnalysisTypeCallback';
import { useUpdateAnalysisTypeCallback } from './useUpdateAnalysisTypeCallback';
import { AnalysisType } from '../models/AnalysisType';

/**
 * Get a callback to save an AnalysisType in the store using either a create or update.
 */
export function useSaveAnalysisTypeCallback(options: SaveInStoreOptions<AnalysisType, string> = {}) {
    const [_create, createStatus] = useCreateAnalysisTypeCallback();
    const create = React.useCallback((model: Partial<AnalysisType>) => _create(model as AnalysisTypeCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateAnalysisTypeCallback();
    const update = React.useCallback((id: string, changes: Partial<AnalysisType>) => _update(id, changes as AnalysisTypeUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
