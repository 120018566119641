import { useCallback, useMemo } from "react";
import { Background } from "../shared/background/Background";
import { generatePdfAsBlob } from "../../utilities/generatePdfAsBlob";
import { useAsyncCallback } from "react-use-async-callback";
import moment from "moment";
import { useUploadBlobFromBlobObjectCallback } from "../../api/main/blobReferences/useUploadBlobFromBlobObjectCallback";
import { useAutoGeneratePdf } from "./bulkReportGenerator/useAutoGeneratePdf";
import { AutoRenderPdfProps } from "./bulkReportGenerator/BulkReportGenerationRequest";
import { AnalyserInterrogationResult } from "../dashboard/analyser/AnalyserDataInterrogation";
import { AnalyserReportIndividualPdf } from "./pdfReports/AnalyserReportIndividualPDF";

interface AnalyserReportIndividualProps extends AutoRenderPdfProps {
    companyColor: string,
    impactsLogoUrl: string,
    splashPageImage: string,
    reportTitle: string,
    questionnaireName?: string,
    reporterName: string,
    analysisResults: AnalyserInterrogationResult | undefined,
    learner: { id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string },
    analysisTypeIconUrls?: { analysisTypeName: string, iconUrl: string }[] | undefined,
    isCampaignReport: boolean,
    currencySymbol: string,
}

//** Fake view for rendering individual analyser reports to pdf in bulk. This view is never displayed to the user. */

export const AnalyserReportIndividual = (props: AnalyserReportIndividualProps) => {

    const { companyColor, impactsLogoUrl, splashPageImage,
        reportTitle,
        questionnaireName = '',
        reporterName, analysisResults,
        learner,
        analysisTypeIconUrls = [],
        isCampaignReport,
        currencySymbol,
        autoGeneratePdf, autoGeneratePdfZipFileName, onPdfReady, } = props;

    /*All Code Below Is used for generating company Questionnaire Report as a PDF*/
    /* getting the current subscription has been moved up to use the currency sysmbol */


    // Generate a PDF file as a blob.
    const generatePdf = useCallback(async (): Promise<Blob> => {

        // Generate the PDF.
        let blob = await generatePdfAsBlob(AnalyserReportIndividualPdf({
            companyColor: companyColor,
            impactsLogoUrl: impactsLogoUrl,
            reportTitle: reportTitle,
            questionnaireName: questionnaireName,
            reporterName: reporterName,
            splashPageImage: splashPageImage,
            analysisResults: analysisResults,
            learner: {
                id: learner?.id ?? '',
                firstName: learner?.firstName ?? '',
                lastName: learner?.lastName ?? '',
                email: learner?.email ?? '',
                lineManagerName: learner.lineManagerName,
                lineManagerEmail: learner.lineManagerEmail,
            },
            analysisTypeIconUrls: analysisTypeIconUrls,
            isCampaignReport: isCampaignReport,
            currencySymbol: currencySymbol,
        }));
        return blob;


    }, [companyColor, reporterName, reportTitle, analysisResults,
        isCampaignReport, questionnaireName, currencySymbol, analysisTypeIconUrls, impactsLogoUrl, splashPageImage, learner]);

    const generatedReportFileName = useMemo(() => {
        let ret = 'Individual';

        // Request is coming from Campaign Overview as bulk report is not available from the analyser
        ret += '-' + questionnaireName?.substring(0, 25) ?? 'Campaign';

        ret += '-' + learner.firstName + '_' + learner.lastName;

        ret += `-Report-${moment().format("YYYY-MM-DD")}.pdf`;

        ret = `${autoGeneratePdfZipFileName}:${ret}`;

        return ret;
    }, [autoGeneratePdfZipFileName, learner, questionnaireName]);

    const [upload] = useUploadBlobFromBlobObjectCallback();

    // Generate PDF and save to blob ready for Zip file Download
    const [generateAndSavePdfForZip] = useAsyncCallback(async () => {
        let blob = await generatePdf();

        //Upload the blob to our blobs table
        const blobReference = await upload(generatedReportFileName, blob);

        //Send Blob refernce Id back to report builder for Zip download.
        return blobReference?.id;
    }, [generatePdf, generatedReportFileName, upload]);

    // If we are to auto generate a pdf (because we are being rendered in the background as part of report generation) do so as soon as all our
    // data is ready.
    useAutoGeneratePdf({
        readyToGenerate: !!autoGeneratePdf,
        generatePdfBlobReference: generateAndSavePdfForZip,
        onPdfReady: onPdfReady,
    });

    return (
        <Background>
            <div className="report-splashpage">
            </div>
        </Background>
    );
};