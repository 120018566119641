import { useCallback, useEffect, useState } from "react";

/**
 * Hook that will auto generate a PDF and call onPdfReady once the data is ready.
 */
export function useAutoGeneratePdf(options: {
    /**
     * Function to call to generate the pdf and get a blobReferenceId from it.
     */
    generatePdfBlobReference: () => Promise<string | undefined | null>,

    /**
     * When true we will attempt to generate the PDF on the assumption all data etc. is ready.
     */
    readyToGenerate: boolean,

    /**
     * When the pdf is generated, or an error occurs in the generation, this callback is called.
     */
    onPdfReady?: (pdfBlobReferenceId: string | undefined | null, errors: any) => void,

    /**
     * Any errors occured in loading data ready for generation.  If a value is passed here onPdfReady will be called passing the error.
     */
    loadErrors?: any,
}) {
    const {
        generatePdfBlobReference,
        readyToGenerate,
        onPdfReady: _onPdfReady,
        loadErrors,
    } = options;

    // Null safe version of onPdfReady.
    const onPdfReady = useCallback((pdfBlobReferenceId: string | undefined | null, errors: any) => {
        if (!_onPdfReady) {
            return;
        }

        _onPdfReady(pdfBlobReferenceId, errors);
    }, [_onPdfReady]);

    // Only let us start the pdf generation once.
    const [hasStartedPdfGeneration, setHasStartedPdfGeneration] = useState<boolean>(false);

    useEffect(() => {
        // If we had an error loading, then say the pdf is done with errors so we can move on to the next one.
        if (loadErrors) {
            onPdfReady(undefined, loadErrors);
        }

        // If we are not ready to generate then do nothing.
        if (!readyToGenerate) {
            return;
        }

        // If we get here we need to generate a pdf, and we have the data loaded.
        // NOTE if we have some charts etc. that are animated and captured incomplete we may want to you may want to delay this with a timer
        // rather than trigger it direct.
        if (hasStartedPdfGeneration) {
            return;
        }

        setHasStartedPdfGeneration(true);
        (async () => {
            try {
                const blobReferenceId = await generatePdfBlobReference();
                onPdfReady(blobReferenceId, undefined);
            } catch (error) {
                onPdfReady(undefined, error);
            }
        })();
    }, [readyToGenerate, onPdfReady, generatePdfBlobReference, loadErrors, hasStartedPdfGeneration, setHasStartedPdfGeneration]);
}