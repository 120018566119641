import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const PerformanceGraphPageStyles = StyleSheet.create({
    graphContainer: {
        width: "100%",
        padding: "2mm",
    },
    graphImage: {
        width: "100%",
        height: "auto",
    }
});

