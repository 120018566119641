import { useMemo } from "react";
import { UsersDashboardTab, UsersDashboardTabProps } from "./UsersDashboardTab";

/**
 * Subscription users tab on the dashboard.
 * @param props
 */
export const SubscriptionUsersDashboardTab = (props: UsersDashboardTabProps) => {
    const {
        profiles,
        ...rest
    } = props;

    const nonStaffProfiles = useMemo(() => !!profiles ? profiles.filter(item => !!item.subscriptionId) : [], [profiles]);

    return (
        <UsersDashboardTab
            profiles={nonStaffProfiles}
            {...rest}
        />
    );
};