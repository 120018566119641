import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { TFunction } from "i18next";

/**
 * Question Response types
 */


/**
 * All New Question Response Types Must Also Be Added To \Services\QuestionnaireQuestionsImportService\QuestionnaireQuestionsImportService.cs
 * Line 106 - 116
 */
export enum QuestionResponseType {
    Number = "Number",
    CurrencyAmount = "CurrencyAmount",
    Percentage = "Percentage",
    YesNo = "YesNo",
    Scale = "Scale",
    ComparisonScale = "ComparisonScale",
    TextBoxes = "TextBoxes",
    PickOne = "PickOne",
    MultipleChoice = "MultipleChoice",
}

/**
 * Return question Response type information.
 * @param t
 */
export function questionResponseTypeDisplayName(type: QuestionResponseType, t: TFunction): string {
    switch (type) {
        case QuestionResponseType.Number: return t('questionResponseTypeDisplayName.Number', 'Number');
        case QuestionResponseType.CurrencyAmount: return t('questionResponseTypeDisplayName.CurrencyAmount', 'Currency');
        case QuestionResponseType.Percentage: return t('questionResponseTypeDisplayName.Percentage', 'Percentage');
        case QuestionResponseType.YesNo: return t('questionResponseTypeDisplayName.YesNo', 'Yes/No');
        case QuestionResponseType.Scale: return t('questionResponseTypeDisplayName.Scale', 'Scale');
        case QuestionResponseType.ComparisonScale: return t('questionResponseTypeDisplayName.ComparisonScale', 'Comparison scale');
        case QuestionResponseType.PickOne: return t('questionResponseTypeDisplayName.PickOne', 'Pick one');
        case QuestionResponseType.MultipleChoice: return t('questionResponseTypeDisplayName.MultipleChoice', 'Multiple choice');
        case QuestionResponseType.TextBoxes: return t('questionResponseTypeDisplayName.TextBoxes', 'Text boxes');
        default: return t('questionResponseTypeDisplayName.Default', 'No response type');
    }
}

export function questionResponseTypeDescription(type: QuestionResponseType, t: TFunction): string {
    switch (type) {
        case QuestionResponseType.Number: return t('questionResponseTypeDescription.Number', 'Up to 2 decimal places');
        case QuestionResponseType.CurrencyAmount: return t('questionResponseTypeDescription.CurrencyAmount', 'Currency amount');
        case QuestionResponseType.Percentage: return t('questionResponseTypeDescription.Percentage', '% with decimals if required');
        case QuestionResponseType.YesNo: return t('questionResponseTypeDescription.YesNo', 'Choice of Yes or No');
        case QuestionResponseType.Scale: return t('questionResponseTypeDescription.Scale', 'Rate on a scale of 1 to any number up to 10');
        case QuestionResponseType.ComparisonScale: return t('questionResponseTypeDescription.ComparisonScale', 'Rate on a scale of 1 to any number up to 10 for before & after');
        case QuestionResponseType.PickOne: return t('questionResponseTypeDescription.PickOne', 'Give a choice of up to 8 responses - only 1 allowed');
        case QuestionResponseType.MultipleChoice: return t('questionResponseTypeDescription.MultipleChoice', 'Give a choice of up to 8 responses - more than 1 allowed');
        case QuestionResponseType.TextBoxes: return t('questionResponseTypeDescription.TextBoxes', 'Accept one or more text boxes');
        default: return t('questionResponseTypeDisplayName.Default', 'No response type');
    }
}

export function questionResponceTypeIcon(type: QuestionResponseType): IconProp {
    switch (type) {
        case QuestionResponseType.Number: return "calculator";
        case QuestionResponseType.CurrencyAmount: return "money-bill";
        case QuestionResponseType.Percentage: return "percent";
        case QuestionResponseType.YesNo: return "question-circle";
        case QuestionResponseType.Scale: return "balance-scale";
        case QuestionResponseType.ComparisonScale: return "drafting-compass";
        case QuestionResponseType.PickOne: return "grip-vertical";
        case QuestionResponseType.MultipleChoice: return "grip-horizontal";
        case QuestionResponseType.TextBoxes: return "keyboard";
       default: return 'cross';
    }
}

// can the responsetype be used for question type measure
export function isResponseTypeForMeasure(type: QuestionResponseType): boolean {
    switch (type) {
        case QuestionResponseType.Number: return true;
        case QuestionResponseType.CurrencyAmount: return true;
        case QuestionResponseType.Percentage: return true;
        case QuestionResponseType.YesNo: return true;
        case QuestionResponseType.Scale: return true;
        case QuestionResponseType.ComparisonScale: return true;
        case QuestionResponseType.PickOne: return false;
        case QuestionResponseType.MultipleChoice: return false;
        case QuestionResponseType.TextBoxes: return false;
        default: return true;
    }
}

// can the responsetype be used for question types that require text - intent, improvement, commitment
export function isResponseTypeForText(type: QuestionResponseType): boolean {
    switch (type) {
        case QuestionResponseType.Number: return false;
        case QuestionResponseType.CurrencyAmount: return false;
        case QuestionResponseType.Percentage: return false;
        case QuestionResponseType.YesNo: return false;
        case QuestionResponseType.Scale: return false;
        case QuestionResponseType.ComparisonScale: return false;
        case QuestionResponseType.PickOne: return true;
        case QuestionResponseType.MultipleChoice: return true;
        case QuestionResponseType.TextBoxes: return true;
        default: return false;
    }
}
