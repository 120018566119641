import { Document, Page, View, Image, Text} from '@react-pdf/renderer';
import {useSubscriptionReportViewModelQuery_model_questionnaireSections, useSubscriptionReportViewModelQuery_model_questions, useSubscriptionReportViewModelQuery_profilesForQuestionnaires, } from '../../../api/main/generated/useSubscriptionReportViewModelQuery';
import { Question } from '../../../api/main/models/Question';
import { QuestionResponse } from '../../../api/main/models/QuestionResponse';
import { CompanyQuestionnaireStyles } from './QuestionnaireReportPDFStyles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { LearningUnit } from '../../../api/main/models/LearningUnit';
import moment from 'moment';
import { QuestionPDF } from './QuestionPDF';


interface QuestionnaireReportPDFProps {
    companyColor?: string,
    companyLogoUrl?: string,
    splashPageImage: string,
    impactsLogoUrl?: string,
    questionnaireTitle?: string,
    sectionTitle?: string,

    chartImages?: Array<string>

    learningUnit?: LearningUnit | null,
    questionnaireEndDate?: string,
    questionnaireDiscription?: string,
    questionnaireSections: Array<useSubscriptionReportViewModelQuery_model_questionnaireSections>,
    orderedQuestions: (sectionId: string) => Array<useSubscriptionReportViewModelQuery_model_questions>,
    orderedUsers: (questionId: string) => Array<useSubscriptionReportViewModelQuery_profilesForQuestionnaires>,
    orderedResponses: (questionId: string) => Array<QuestionResponse>,
    displayPreText: (question: Question, response: QuestionResponse, index: number) => string,
    displayResponse: (question: Question, response: QuestionResponse, index: number) => string,
    isIncludeSummary: boolean,
    isIncludeGraphs: boolean,
    isIncludeLearningUnit: boolean,
    isIncludeDescription: boolean,
}

/* PDF report for Company Questionnaire Report */
export const QuestionnaireReportPdf = (props: QuestionnaireReportPDFProps) => {

    const { companyColor, companyLogoUrl, splashPageImage,
        questionnaireTitle,
        sectionTitle,
        questionnaireSections,
        learningUnit,
        questionnaireDiscription, orderedQuestions, orderedUsers,
        orderedResponses, displayPreText, displayResponse, impactsLogoUrl,
        questionnaireEndDate,
        isIncludeSummary,
        isIncludeGraphs,
        isIncludeLearningUnit,
        isIncludeDescription,
        chartImages,
    } = props;


    // Currently 4 Images For charts, so SPlit the Array into 4
    const chartImage1 = chartImages?.[0];
    const chartImage2 = chartImages?.[1];
    const chartImage3 = chartImages?.[2];
    const chartImage4 = chartImages?.[3];

    return (
        <Document>
            {/*Splash Page*/}
            <Page size="A4" style={CompanyQuestionnaireStyles.splashPage} wrap={false}>
                <View>
                    <Image style={CompanyQuestionnaireStyles.splashPageImage} source={splashPageImage} />
                </View>
                <View style={[CompanyQuestionnaireStyles.splashPageTitleView,
                {
                    borderRadius: 10,
                    border: "1mm",
                    borderStyle: "solid",
                    borderColor: `${companyColor}`,
                    display: "flex",
                    padding: "1mm",

                }]}>
                    <View style={[{
                        flexDirection: "column", width: "60%", alignItems: "flex-start", height: "60mm"
                    }]}>
                        <Text style={[CompanyQuestionnaireStyles.fakeNameTitle]}>{' '}</Text>
                        <Text style={[CompanyQuestionnaireStyles.splashPageTitle]}>{questionnaireTitle}</Text>
                        <ConditionalFragment showIf={!!sectionTitle}>
                            <Text style={[CompanyQuestionnaireStyles.splashPageEndOfProgrammeReview]}>{sectionTitle}</Text>
                        </ConditionalFragment>
                        <Text style={[CompanyQuestionnaireStyles.splashPageEndOfProgrammeReview]}>{"End of Programme Review"}</Text>
                        <Text style={[CompanyQuestionnaireStyles.splashPageEndDate]}>{questionnaireEndDate}</Text>
                    </View>
                    <View style={[{
                        flexDirection: "column", width: "40%", alignItems: "center", paddingTop: 35, paddingBottom: 5,
                    }]}>
                        <Text style={[CompanyQuestionnaireStyles.fakeNameTitle]}>{''}</Text>
                        <Image style={CompanyQuestionnaireStyles.companyLogo} source={companyLogoUrl} />
                    </View>
                </View>
            </Page>

            {/*Report Page*/}
            <Page size="A4" style={CompanyQuestionnaireStyles.reportPage} wrap={false}>
                {/*Report Title*/}
                <View>
                    <Image style={CompanyQuestionnaireStyles.imapctsImageMainPage} source={impactsLogoUrl} />
                    <Text style={CompanyQuestionnaireStyles.reportPageTitle}>{questionnaireTitle}</Text>
                </View>
                {/*Report Summary*/}
                <ConditionalFragment showIf={!!isIncludeSummary}>
                    <View style={CompanyQuestionnaireStyles.summaryView}>
                        <View style={CompanyQuestionnaireStyles.summaryTitleView}>
                            <Text style={CompanyQuestionnaireStyles.summaryTitle}>Summary</Text>
                        </View>
                        <ConditionalFragment showIf={!!isIncludeDescription}>
                            <View style={CompanyQuestionnaireStyles.questionnaireDescriptionView}>
                                <View>
                                    <Text style={CompanyQuestionnaireStyles.questionnaireDescriptionLabel}>Description</Text>
                                </View>
                                <View style={CompanyQuestionnaireStyles.questionnaireDescription}>
                                    <Text>{questionnaireDiscription ?? ''}</Text>
                                </View>
                            </View>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit && isIncludeLearningUnit}>
                            <View style={CompanyQuestionnaireStyles.learningUnitView}>
                                <View style={CompanyQuestionnaireStyles.learningUnitRow}>
                                    <Text style={CompanyQuestionnaireStyles.learningUnitTitle}>Learning Unit</Text>
                                </View>
                                <View style={CompanyQuestionnaireStyles.learningUnitRow}>
                                    <View style={CompanyQuestionnaireStyles.learningUnitColumn}>
                                        <Text style={CompanyQuestionnaireStyles.learningUnitNameLabel}>Learning</Text><Text style={CompanyQuestionnaireStyles.learningUnitName}>{learningUnit?.name}</Text>
                                    </View>
                                    <View style={CompanyQuestionnaireStyles.learningUnitColumn}>
                                        <Text style={CompanyQuestionnaireStyles.learningUnitTypeLabel}>Type</Text><Text style={CompanyQuestionnaireStyles.learningUnitType}>{learningUnit?.learningUnitType}</Text>
                                    </View>
                                </View>
                                <View style={CompanyQuestionnaireStyles.learningUnitRow}>
                                    <View style={CompanyQuestionnaireStyles.learningUnitColumn}>
                                        <Text style={CompanyQuestionnaireStyles.learningUnitByLabel}>By</Text><Text style={CompanyQuestionnaireStyles.learningUnitBy}>{learningUnit?.provider}</Text>
                                    </View>
                                </View>
                                <ConditionalFragment showIf={!!learningUnit?.startDate || !!learningUnit?.endDate}>
                                    <View style={CompanyQuestionnaireStyles.learningUnitRow}>
                                        <View style={CompanyQuestionnaireStyles.learningUnitColumn}>
                                            <Text style={CompanyQuestionnaireStyles.learningUnitStartDateLabel}>Start Date</Text><Text style={CompanyQuestionnaireStyles.learningUnitStartDate}>{!!learningUnit?.startDate ? moment(learningUnit?.startDate).local().format("L") : 'Not Set'}</Text>
                                        </View>
                                        <View style={CompanyQuestionnaireStyles.learningUnitColumn}>
                                            <Text style={CompanyQuestionnaireStyles.learningUnitEndDateLabel}>End Date</Text><Text style={CompanyQuestionnaireStyles.learningUnitEndDate}>{!!learningUnit?.endDate ? moment(learningUnit?.endDate).local().format("L") : 'Not Set'}</Text>
                                        </View>
                                    </View>
                                </ConditionalFragment>
                            </View>
                        </ConditionalFragment>
                    </View>
                    <ConditionalFragment showIf={!!isIncludeGraphs && !!chartImages?.length}>
                        <View style={CompanyQuestionnaireStyles.chartImagesOverallView}>
                            <View style={CompanyQuestionnaireStyles.dashboardTitleView}>
                                <Text style={CompanyQuestionnaireStyles.chartImageViewTitle}>Dashboard</Text>
                            </View>
                            <ConditionalFragment showIf={(!!chartImage1 && !!chartImage2) || (!!chartImage1 && !chartImage2) || (!chartImage1 && !!chartImage2)}>
                                <View style={CompanyQuestionnaireStyles.chartImageViewRow}>
                                    <View style={CompanyQuestionnaireStyles.chartImageViewColumn}>
                                        <View style={CompanyQuestionnaireStyles.chartImageView}>
                                            <ConditionalFragment showIf={!!chartImage1}>
                                                <Image style={CompanyQuestionnaireStyles.chartImage} source={chartImage1} />
                                            </ConditionalFragment>
                                        </View>
                                    </View>
                                    <View style={CompanyQuestionnaireStyles.chartImageViewColumn}>
                                        <View style={CompanyQuestionnaireStyles.chartImageView}>
                                            <ConditionalFragment showIf={!!chartImage2}>
                                                <Image style={CompanyQuestionnaireStyles.chartImage} source={chartImage2} />
                                            </ConditionalFragment>
                                        </View>
                                    </View>
                                </View>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={(!!chartImage3 && !!chartImage4) || (!!chartImage3 && !chartImage4) || (!chartImage3 && !!chartImage4)}>
                                <View style={CompanyQuestionnaireStyles.chartImageViewRow}>
                                    <View style={CompanyQuestionnaireStyles.chartImageViewColumn}>
                                        <View style={CompanyQuestionnaireStyles.chartImageView}>
                                            <ConditionalFragment showIf={!!chartImage3}>
                                                <Image style={CompanyQuestionnaireStyles.chartImage} source={chartImage3} />
                                            </ConditionalFragment>
                                        </View>
                                    </View>
                                    <View style={CompanyQuestionnaireStyles.chartImageViewColumn}>
                                        <View style={CompanyQuestionnaireStyles.chartImageView}>
                                            <ConditionalFragment showIf={!!chartImage4}>
                                                <Image style={CompanyQuestionnaireStyles.chartImage} source={chartImage4} />
                                            </ConditionalFragment>
                                        </View>
                                    </View>
                                </View>
                            </ConditionalFragment>
                        </View>
                    </ConditionalFragment>
                </ConditionalFragment>

                {/*Mapped Questionnaire Sections*/}
                <View break>
                    {
                        questionnaireSections?.map(section => {
                            return (
                                <View style={CompanyQuestionnaireStyles.questionnaireSection} key={section.id}>
                                    <View style={CompanyQuestionnaireStyles.SectionRow} key={section.id}>
                                        <Text style={CompanyQuestionnaireStyles.sectionInitial}>{section.name.substring(0, 1)}</Text><Text style={CompanyQuestionnaireStyles.sectionName}>{section.name}</Text>
                                    </View>
                                    <ConditionalFragment showIf={!!section.description}>
                                        <View>
                                            <Text style={CompanyQuestionnaireStyles.sectionDescription}>{section.description}</Text>
                                        </View>
                                    </ConditionalFragment>
                                    <View>
                                        {orderedQuestions(section.id).map(question => {
                                            return (
                                                <QuestionPDF
                                                    key={question.id}
                                                    question={question}
                                                    orderedResponses={orderedResponses(question.id)}
                                                    displayPreText={displayPreText}
                                                    displayResponse={displayResponse}
                                                    orderedUsers={orderedUsers(question.id)}
                                                />
                                            )
                                        })}
                                    </View>
                                </View>
                            )
                        })
                    }
                </View>
            </Page>
        </Document>
    );
};