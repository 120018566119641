import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { QuestionnaireRespondentSessionCreateInput } from '../generated/globalTypes';
import { addQuestionnaireUserMutation, addQuestionnaireUserMutationVariables } from '../generated/addQuestionnaireUserMutation';
import { questionnaireRespondentSessionFieldsFragment } from '../models/QuestionnaireRespondentSession';

/**
 * Get a callback to create a QuestionnaireRespondentSession along with the user and profile if necessary.
 */
export function useAddQuestionnaireUserCallback(): [(model: QuestionnaireRespondentSessionCreateInput, firstName?: string, lastName?: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<addQuestionnaireUserMutation, addQuestionnaireUserMutationVariables>(
        gql`
            mutation addQuestionnaireUserMutation ($model: QuestionnaireRespondentSessionCreateInput!, $firstName: String, $lastName: String) {
                addQuestionnaireUser(model: $model, firstName: $firstName, lastName: $lastName) {
                    ...questionnaireRespondentSessionFields
                }
            }

            ${questionnaireRespondentSessionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: QuestionnaireRespondentSessionCreateInput, firstName?: string, lastName?: string) => {
        const result = await mutationAction({ variables: { model: model, firstName: firstName, lastName: lastName } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
