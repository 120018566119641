import { useEffect,  } from 'react';
import { Button, Row, Col, Form, Label, FormGroup, Spinner, CustomInput, FormText, Collapse, Input, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useSaveSubscriptionCallback } from '../../api/main/subscriptions/useSaveSubscriptionCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useEditSubscriptionViewModel } from '../../api/main/subscriptions/viewModels/useEditSubscriptionViewModel';
import moment from 'moment';
import { useBlobReferenceState } from '../shared/useBlobReferenceState/useBlobReferenceState';
import { ImageTab } from './image/ImageTab';
import { subscriptionDefaultValues } from '../../api/main/models/Subscription';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { ManageNavigation } from '../manage/ManageNavigation';
import { ValidatedISODateTimeInput } from '../shared/ValidatedISODateTimeInput';
import { EditNavigation } from './EditNavigation';

interface EditSubscriptionProps {
    isCreate?: boolean,
    isCompanyAdminAccess?: boolean,
}

/**
 * Create a new subscription.
 */
export const CreateSubscription = () => (<EditSubscription isCreate={true} />);

/**
 * Edit branding as a company admin (won't give access to other subscription settings.
 */
export const EditBranding = () => (<EditSubscription isCompanyAdminAccess={true} />);

/**
 * Edit a subscription.
 */
export const EditSubscription = (props: EditSubscriptionProps) => {
    const isCreate = props.isCreate;
    const isCompanyAdminAccess = props.isCompanyAdminAccess;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();

    // if id is not passed in we must be at subscription admin level so use this
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    //isCompanyAdminAccess is being used to stop currentUserSubscriptionId being used when creating a new subscription
    const { data: {
        model: storeModel,
    }, isLoading, errors: loadErrors } = useEditSubscriptionViewModel(id ?? (isCompanyAdminAccess ? currentUserSubscriptionId : undefined));
    const { model, change, changes } = useChanges(storeModel, isCreate ? subscriptionDefaultValues() : undefined);
    const [save, { errors: saveErrors }] = useSaveSubscriptionCallback();
    const history = useHistory();

    const [imageBlob, setImageBlob, { upload: uploadImageBlob, isUploading: isUploadingImageBlob, uploadErrors: uploadImageBlobErrors, clearUpload: clearImageBlob }] = useBlobReferenceState(blob => {
        change({ brandImageBlobReferenceId: blob?.id ?? null });
    });
    useEffect(() => setImageBlob(storeModel?.brandImageBlobReference ?? null), [setImageBlob, storeModel]);

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSubscription.nameRequired', 'Company name is required') : '',

            numberOfQuestionnaires: () => model.isLimitedSubscription && model?.numberOfQuestionnaires < 1 && !model.endDate ? t('editSubscription.trialDetailsRequired', 'For a limited subscription you need to enter an end date or a number of campaigns, or both') : '',
            endDate: () => model.isLimitedSubscription && model?.numberOfQuestionnaires < 1 && !model.endDate ? t('editSubscription.trialDetailsRequired', 'For a limited subscription you need to enter an end date or a number of campaigns, or both') : '',

            brandColor: () => !!model.brandColor && (model?.brandColor.length > 7 || model.brandColor.indexOf('#') === -1) ? t('editSubscription.brandColorFormat', 'Brand color must be in the format #123456 representing your hex brand color code') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // the route to the required view on leaving this one
    const backRoute = '/administration/subscriptions';

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        // when the user switches the trial switch off, unset the date and number of trials
        if (!model.isLimitedSubscription) {
            changes.endDate = moment().add(1, "years");
            changes.numberOfQuestionnaires = 0;
        }

        await save(model.id, changes, !!isCreate);

        // Navigate to the next screen.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else {
            history.goBack();
        }
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12}>
                        {
                            !!isCompanyAdminAccess ?
                                <PillsNavBar>
                                    <ManageNavigation
                                    />
                                </PillsNavBar>
                                :
                                <PillsNavBar>
                                    <EditNavigation
                                        subscriptionId={!isCompanyAdminAccess ? id ?? '' : currentUserSubscriptionId ?? ''}
                                    />
                                </PillsNavBar>
                        }
                    </Col>
               </Row>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSubscription.createHeading', 'Add Company')}</>
                                ) : !isCompanyAdminAccess ? (
                                    <>{t('editSubscription.editHeading', `Company Branding for ${model.name}`)}</>
                                ) : (
                                    <>{t('editSubscription.brandingHeading', 'Company Branding')}</>
                                )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, uploadImageBlobErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm({ navigateTo: backRoute }); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editSubscription.name', 'Subscription Name')}</Label>
                                <ConditionalFragment showIf={!isCompanyAdminAccess}>
                                    <ValidatedInput name="name" type="text" value={model.name ?? ''}
                                        onChange={e => change({ name: e.currentTarget.value })}
                                        onBlur={e => validate('name')}
                                        validationErrors={validationErrors['name']} />
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!isCompanyAdminAccess}>
                                    <Input name="name" type="text" value={model.name ?? ''} readOnly />
                                </ConditionalFragment>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={6}>
                            <FormGroup>
                                <Label htmlFor="brandColor">{t('editSubscription.brandColor', 'Brand Colour')}</Label>
                                <ValidatedInput name="brandColor" type="color" value={model.brandColor ?? ''}
                                    onChange={e => change({ brandColor: e.currentTarget.value })}
                                    onBlur={e => validate('brandColor')}
                                    validationErrors={validationErrors['brandColor']} />
                            </FormGroup>
                        </Col>
                        <Col xs={12} sm={6}>
                            <ImageTab
                                model={model}
                                imageBlob={imageBlob} uploadImageBlob={uploadImageBlob} isUploadingImageBlob={isUploadingImageBlob} clearImageBlob={clearImageBlob}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="currencyText">{t('editSubscription.currencyText', 'Currency Text')}</Label>
                                <ValidatedInput name="currencyText" type="text" value={model.currencyText ?? ""}
                                    onChange={e => change({ currencyText: e.currentTarget.value })}
                                    onBlur={e => validate('currencyText')}
                                    validationErrors={validationErrors['currencyText']} />
                                <FormText>
                                    {t('editSubscription.currencyTextHelp', 'This will be used on screens and reports when required if a currency amount is present.')}
                                </FormText>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="currencySymbol">{t('editSubscription.currencySymbol', 'Currency Symbol')}</Label>
                                <ValidatedInput name="currencySymbol" type="text" value={model.currencySymbol ?? ""}
                                    onChange={e => change({ currencySymbol: e.currentTarget.value })}
                                    onBlur={e => validate('currencySymbol')}
                                    validationErrors={validationErrors['currencySymbol']} />
                            </FormGroup>
                        </Col>
                    </Row>

                    <ConditionalFragment showIf={!isCompanyAdminAccess}>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label htmlFor="isLimitedSubscription">{t('editSubscription.isLimitedSubscription', 'Is this a limited subscription?')}</Label>
                                    <CustomInput type="switch" id="isLimitedSubscription" label={model?.isLimitedSubscription ? t('common.yes', 'Yes') : t('common.no', 'No')}
                                        checked={!!model?.isLimitedSubscription ?? false} onChange={e => change({ isLimitedSubscription: e.currentTarget.checked })}
                                        onBlur={e => validate('isLimitedSubscription')}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Collapse isOpen={!!model?.isLimitedSubscription || !!changes.isLimitedSubscription}>
                            <Row>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="TrialEndDate">{t('editSubscription.endDate', 'End Date')}</Label>
                                        <ValidatedISODateTimeInput name="endDate" type="date" value={moment(model?.endDate).toISOString()}
                                            onChange={e => change({ endDate: moment(e.currentTarget.value) })}
                                            onBlur={e => validate('endDate')}
                                            validationErrors={validationErrors['endDate']} />
                                        <FormText>
                                            {t('editSubscription.endDateHelp', 'The subscription will be complete at the start of the day you enter here and will not include this day.')}
                                        </FormText>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label htmlFor="numberOfQuestionnaires">{t('editSubscription.numberOfQuestionnaires', 'Number Of Campaigns')}</Label>
                                        <ValidatedInput name="numberOfQuestionnaires"
                                            type="number" value={model.numberOfQuestionnaires ?? '0'}
                                            onChange={e => change({ numberOfQuestionnaires: e.currentTarget.valueAsNumber })}
                                            onBlur={e => validate('numberOfQuestionnaires')}
                                            validationErrors={validationErrors['numberOfQuestionnaires']} />
                                        <FormText>
                                            {t('editSubscription.numberOfQuestionnairesHelp', 'If you set this as well as an end date, the subscription will end as soon as the customer hits either one of the limits.')}
                                        </FormText>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </Collapse>
                    </ConditionalFragment>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.push(backRoute)}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};