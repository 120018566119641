import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { ChartData, ChartDataSeries } from "../chartDataUtilities/ChartData";

export type AdminGroupLevel = 'Questionnaire' | 'Department' | 'Subscription';

/**
 * Data for questions giving currency figure answers by groups.
 * Need to filter responses to only those of the required response type before passing into here
 */
export function useCurrencyAnalysisChartDataForQuestionnaires(groups: Array<{ id: string, name: string }>, sessions: Array<QuestionnaireRespondentSession>, responses: Array<QuestionResponse>, groupLevel: AdminGroupLevel): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string,) => {
        // Generate chart data for each group.
        const data = groups.map(group => {
            let mySessions: Array<QuestionnaireRespondentSession> = [];
            if (groupLevel === 'Questionnaire') {
                mySessions = sessions.filter(item => item.userId === group.id);
            } else if (groupLevel === 'Department') {
                mySessions = sessions.filter(item => item.questionnaireId === group.id);
            } else if (groupLevel === 'Subscription') {
                mySessions = sessions.filter(item => item.questionnaireId === group.id);
            }

            const myResponses = responses.filter(item => mySessions.find(it => it.id === item.questionnaireRespondentSessionId));

            const totalCurrencyAmount = myResponses.reduce((total, response) => total + response.amountInput, 0);

            return {
                text: group.name,
                value: totalCurrencyAmount,
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [groups, responses, sessions, groupLevel]);

    const ret = useMemo(() => {
        return [
            generateSeries(
                t('useCurrencyAnalysisChartData.seriesName', 'Amount'),
            ),
        ];
    }, [generateSeries, t,]);

    return ret;
}
