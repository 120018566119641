import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const PerformancePageStyles = StyleSheet.create({
    categoryIconImage: {
        height: "auto",
        width: "20mm",
        marginTop: "2mm",
        marginLeft: "3mm",
        marginRight: "10mm",
    },
    analysisTypeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: "7mm",
    },
    categoryDetail: {
        fontSize: 14,
        fontWeight: 'bold',
        paddingTop: "2mm",
    },
    categoryText: {
        width: "60%",
    },
    categoryScore: {
        textAlign: 'right',
    },
    analyserArrow: {
        width: "25px",
        height: "25px",
        marginTop: "2mm",
        marginRight: "3mm",
    },
});

