import * as React from 'react';
import { QuestionResponseCreateInput, QuestionResponseUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionResponseCallback } from './useCreateQuestionResponseCallback';
import { useUpdateQuestionResponseCallback } from './useUpdateQuestionResponseCallback';
import { QuestionResponse } from '../models/QuestionResponse';

/**
 * Get a callback to save a QuestionResponse in the store using either a create or update.
 */
export function useSaveQuestionResponseCallback(options: SaveInStoreOptions<QuestionResponse, string> = {}) {
    const [_create, createStatus] = useCreateQuestionResponseCallback();
    const create = React.useCallback((model: Partial<QuestionResponse>) => _create(model as QuestionResponseCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionResponseCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionResponse>) => _update(id, changes as QuestionResponseUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
