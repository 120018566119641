import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { useSubscriptionDashboardViewModel } from "../../../api/main/dashboard/viewModels/useSubscriptionDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { useSessionsForQuestionnaireType } from "../utilities/useSessionsForQuestionnaireType";
import { useCurrentUserProfile } from "../../../api/main/profiles/useCurrentUserProfile";
import { Question } from "../../../api/main/models/Question";
import { administrationDashboardViewModelQuery_questionnaireRespondentSessions } from "../../../api/main/generated/administrationDashboardViewModelQuery";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { TypeSelectionPills } from "../typeSelectionPills/TypeSelectionPills";

export type SubscriptionDepartmentDashboardTabs = 'people';
export type QuestionnaireTypeTabs = 'all' | 'campaigns' | 'selfServices';
/**
 * Dashboard for a subscription department.
 */
export const SubscriptionDepartmentDashboard = () => {
    // Work out the subscription to show for.
    const { subscriptionId: subscriptionIdParam, subscriptionDepartmentId: subscriptionDepartmentIdParam } = useParams<{ subscriptionId: string | undefined, subscriptionDepartmentId: string }>();

    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const subscriptionId = subscriptionIdParam ?? mySubscriptionId;

    const { data: { model: currentUserProfile } } = useCurrentUserProfile();
    const subscriptionDepartmentId = subscriptionDepartmentIdParam ?? currentUserProfile?.subscriptionDepartmentId;

    const { t } = useTranslation();

    const {
        data: {
            subscriptionDepartments: allSubscriptionDepartments,
            profiles: allProfiles,
            questionnaires,
            questionnaireRespondentSessions,
        },
        isLoading,
        errors: loadErrors
    } = useSubscriptionDashboardViewModel(subscriptionId);

    const subscriptionDepartment = useMemo(() => allSubscriptionDepartments?.find(it => it.id === subscriptionDepartmentId), [allSubscriptionDepartments, subscriptionDepartmentId]);

    // Filter the profiles down to only this department.
    const profiles = useMemo(() => allProfiles?.filter(item => item.subscriptionDepartmentId === subscriptionDepartmentId), [allProfiles, subscriptionDepartmentId]);

    // get the selfService sesssions for users in this department
    const selfServiceSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.SelfService, subscriptionDepartmentId);

    // get the campaign sesssions for users in this department
    const campaignSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.Campaign, subscriptionDepartmentId);

    //track active tabs
    const [activeQuestionnaireType, setActiveQuestionnaireType] = useActiveDashboardTab<QuestionnaireTypeTabs>('administrationDashboard2', 'all');

    // sort out which sessions to include in graphs
    const graphSessions = useMemo(() => {
        if (activeQuestionnaireType === 'campaigns') {
            return campaignSessions;
        } else if (activeQuestionnaireType === 'selfServices') {
            return selfServiceSessions;
        } else {
            return campaignSessions.concat(selfServiceSessions);
        }
    }, [activeQuestionnaireType, campaignSessions, selfServiceSessions]);

    // get the questions 
    const questions = useMemo(() => {
        if (!questionnaires) {
            return [];
        }

        let allQuestions: Array<Question> = [];

        for (let questionnaire of questionnaires) {
            for (let question of questionnaire.questions) {
                allQuestions.push(question);
            }
        }
        return allQuestions;
    }, [questionnaires,]);

    // get all responses
    const questionnaireTypeResponses = useCallback((requiredSesssions: administrationDashboardViewModelQuery_questionnaireRespondentSessions[]) => {
        if (!requiredSesssions.length) {
            return [];
        }

        let ret: Array<QuestionResponse> = [];
        for (let session of requiredSesssions) {
            for (let response of session.responses) {
                ret.push(response);
            }
        }

        return ret;
    }, []);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col>
                    </Col>
                    <Col xs={12} sm={6}>
                    </Col>
                    <Col xs={12} sm={3}>
                        <TypeSelectionPills
                            activeQuestionnaireType={activeQuestionnaireType}
                            setActiveQuestionnaireType={setActiveQuestionnaireType}
                        />
                    </Col>
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>
                            <DashboardBackButton />
                            {
                                t('subscriptionDepartmentDashboard.heading', '{{name}} Dashboard', { name: subscriptionDepartment?.name })
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid className="main-container">
                <AlertOnErrors errors={[loadErrors]} />
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        campaignSessions={campaignSessions}
                        selfServiceSessions={selfServiceSessions}
                        graphSessions={graphSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        showSubscriptionName={false}
                        questions={questions}
                    />
            </Container>
        </Background>
    );
};
