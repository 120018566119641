import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useCurrentUserRoles } from '../../api/account';
import { useCurrentUser } from '../../api/main/users/useCurrentUser';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { useSearchParams } from '../../shared/useURLSearchParams';
import { LoadingIndicator } from '../shared/LoadingIndicator';

/**
 * Default / home page.
 */
export const Home = () => {
    // If we have a redirect URL on the query string redirect there.
    var { redirectUrl } = useSearchParams();
    const currentUser = useCurrentUser();
    const { data: { roles }, refresh: loadRoles, isLoading: isLoadingRoles, } = useCurrentUserRoles({ lazy: true });

    // Load the roles once, and wait until we have done so before contuining.
    const [hasLoadedRoles, setHasLoadedRoles] = useState<boolean>(false);
    useEffect(() => {
        if (hasLoadedRoles) {
            return;
        }

        if (!currentUser) {
            return;
        }

        if (isLoadingRoles) {
            return;
        }

        (async () => {
            await loadRoles();
            setHasLoadedRoles(true);
        })();
    }, [setHasLoadedRoles, loadRoles, isLoadingRoles, currentUser, hasLoadedRoles]);

    // If we've not loaded yet show a waiting symbol.
    if (!hasLoadedRoles && !redirectUrl) {
        return (
            <LoadingIndicator fullWidth />
            );
    }

    const isAdministrator = !!roles?.find(it => it === IdentityRoles.Administration);
    const isSubscriptionAdmin = !!roles?.find(it => it === IdentityRoles.SubscriptionAdministration) && !isAdministrator;
    const defaultRedirectUrl = isAdministrator  ? '/administration' : isSubscriptionAdmin ? '/subscriptionQuestionnaires' : '/me';

    // Redirect to the right place.
    return (

        <>
            <Redirect to={redirectUrl ?? defaultRedirectUrl} />
        </>
    );
};