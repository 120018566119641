import { EditQuestionnaireBase } from "./EditQuestionnaireBase";

/**
 * Edit a top level template as an administrator.
 */
export const EditTemplate = () => {

    return (
        <EditQuestionnaireBase
            isSubscription={false}
        />
    );
};