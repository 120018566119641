import { useServices } from "inject-typesafe-react";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServices } from "../../../configure/configureServices";
import { AsyncActionStatus } from "../../../shared/abstractStore";

/*
 * Get a callback to send a reminder to a user to complete a questionnaire.
 */
export function useSendAllQuestionnaireReminderCallback(): [(questionnaireId: string, questionnaireName: string, daysLeft: number) => Promise<void>, AsyncActionStatus]
{
    var api = useServices((services: AppServices) => services.apiFetch());
    //callback to get the QuestionnaireId, DaysRemaining and Questionnaire Name. 
    const [callback, status] = useAsyncCallback(async (questionnaireId: string, questionnaireName: string, daysLeft: number) => {
        await api.post(`/api/reminders/sendAllReminder`, {questionnaireId: questionnaireId, questionnaireName: questionnaireName, daysLeft: daysLeft });
    }, [api]);

    return [callback, status];
}