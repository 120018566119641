import { Question } from "../../api/main/models/Question";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { Col, Row } from "reactstrap";
import { ReportQuestionResponseDisplay } from "./ReportQuestionResponseDisplay";
import { useSubscriptionReportViewModelQuery_profilesForQuestionnaires } from "../../api/main/generated/useSubscriptionReportViewModelQuery";
import { QuestionResponse } from "../../api/main/models/QuestionResponse";
import { ConditionalFragment } from "react-conditionalfragment";
import { ScrollTo } from "@scottbamford/react-scrollto";


// Component for displaying a question and its responses on a report

export interface ReportQuestionDisplayProps {
    question: Question,
    orderedResponses: QuestionResponse[],

    displayResponse: (question: Question, response: QuestionResponse, index: number) => string;
    displayPreText: (question: Question, response: QuestionResponse, index: number) => string;

    orderedUsers?: useSubscriptionReportViewModelQuery_profilesForQuestionnaires[];
    scrollToId?: string;
}

export const ReportQuestionDisplay = (props: ReportQuestionDisplayProps) => {
    const {
        question,
        orderedResponses,
        displayResponse,
        displayPreText,
        orderedUsers = [],
        scrollToId,
    } = props;


    return (
        <div className="question-container">
            <ConditionalFragment showIf={!!orderedUsers.length}>
                <ScrollTo shouldScrollTo={scrollToId === question.id} position="start" scrollMargin="70px">
                    <div className="question-text">
                        {HtmlDisplay({ html: question.questionText })}
                    </div>
                </ScrollTo>
                <div>
                    {/* display all users within each question */}
                    {orderedUsers.map(user => {
                        return (
                            <div key={user.id} className={"user-container"}>
                                <Row>
                                    <Col className="user-name">
                                        {user.firstName + ' ' + user.lastName} :
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {/*Display all responses for a user for this question */}
                                        {orderedResponses.filter(item => item.userId === user.userId).map((response, index) => {
                                            return (
                                                <ReportQuestionResponseDisplay
                                                    displayText={displayResponse(question, response, index)}
                                                    preText={displayPreText(question, response, index)}
                                                    responseType={question.responseType}
                                                />
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                    )}
                </div>
            </ConditionalFragment>
            <ConditionalFragment showIf={!orderedUsers.length}>
                <div className="question-text">
                    {HtmlDisplay({ html: question.questionText })}
                </div>
                <div className={"user-container"}>
                    <Row>
                        <Col>
                            {/*Display all responses for a user for this question */}
                            {orderedResponses.map((response, index) => {
                                return (
                                    <ReportQuestionResponseDisplay
                                        key={response.id}
                                        displayText={displayResponse(question, response, index)}
                                        preText={displayPreText(question, response, index)}
                                        responseType={question.responseType}
                                    />
                                )
                            })}
                        </Col>
                    </Row>
                </div>
            </ConditionalFragment>
        </div>
    );
};