import { useTranslation } from "react-i18next";
import { Modal, ModalBody, ModalHeader, ModalProps, } from "reactstrap";
import "./editQuestionModal.scss";
import { EditQuestion } from "../EditQuestion";
import { Question } from "../../../api/main/models/Question";
import { ValidationErrors } from "pojo-validator";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { ValidateCallback } from "pojo-validator-react";
import { ModelArrayChanges } from "../../../shared/useChanges";
import { ValidateModelCallback, ValidationErrorsForModel } from "../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { AnalysisType } from "../../../api/main/models/AnalysisType";
import { QuestionnaireAnalysisTypeLink } from "../../../api/main/models/QuestionnaireAnalysisTypeLink";

export interface EditQuestionModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    model: Question,
    change: (changes: Partial<Question>) => void,
    isCreate: boolean,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>,

    analysisTypeManager: ModelArrayChanges<AnalysisType, string>,
    validateAnalysisType: ValidateModelCallback<AnalysisType>,
    analysisTypeValidationErrors: ValidationErrorsForModel,

    questionnaireAnalysisTypeLinkManager: ModelArrayChanges<QuestionnaireAnalysisTypeLink, string>,
}

/**
 * Modal that allows the user to edit or create a question.
 * @param props
 */
export const EditQuestionModal = (props: EditQuestionModalProps) => {
    const {
        isOpen,
        toggle,
        model,
        change,
        isCreate,
        validate,
        validationErrors,
        saveAllDeBounce,

        analysisTypeManager,
        validateAnalysisType,
        analysisTypeValidationErrors,

        questionnaireAnalysisTypeLinkManager,
    } = props;

    const { t } = useTranslation();

    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="select-question-modal">
            <ModalHeader toggle={() => toggle()}>
                {
                    isCreate ? (
                        <>{t('editQuestionModal.createHeading', 'Add question')}</>
                    ) : (
                        <>{t('editQuestionModal.editHeading', 'Edit question')}</>
                    )
                }
            </ModalHeader>
            <ModalBody>
                <EditQuestion
                    toggle={toggle}
                    model={model}
                    change={change}

                    isCreate={isCreate}
                    validate={validate}
                    validationErrors={validationErrors}
                    saveAllDeBounce={saveAllDeBounce}
                    analysisTypeManager={analysisTypeManager}
                    validateAnalysisType={validateAnalysisType}
                    analysisTypeValidationErrors={analysisTypeValidationErrors}
                    questionnaireAnalysisTypeLinkManager={questionnaireAnalysisTypeLinkManager}
                />
            </ModalBody>
        </Modal>
    );
};