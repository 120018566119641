import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, Col, Input, Label, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { editQuestionnaireSupportingDataQuery_subscriptionDepartments } from "../../../api/main/generated/editQuestionnaireSupportingDataQuery";
import { useQuestionnaireUsersListViewModelQuery_items } from "../../../api/main/generated/useQuestionnaireUsersListViewModelQuery";
import { useSaveQuestionnaireRespondentSessionCallback } from "../../../api/main/questionnaireRespondentSessions/useSaveQuestionnaireRespondentSessionCallback";
import { useChanges } from "../../../shared/useChanges";
import { FormButtons } from "../../shared/FormButtons";
import "./editRespondantSessionModal.scss";

// Modal for editing the department on a questionnaire respondant session for a single paricipant.

export interface EditRespondantSessionModalProps {
    isOpen: boolean,
    toggle: () => void,
    userSessions: useQuestionnaireUsersListViewModelQuery_items[],
    questionnaireId: string,
    userId: string,
    departments: editQuestionnaireSupportingDataQuery_subscriptionDepartments[],

}

export const EditRespondantSessionModal = (props: EditRespondantSessionModalProps) => {

    const {
        isOpen,
        toggle,
        userSessions,
        questionnaireId,
        userId,
        departments,
    } = props;

    const { t } = useTranslation();

    const neededUserSession = userSessions.find(item => item.userId === userId && item.questionnaireId === questionnaireId);

    const { model: modelSession, change, changes } = useChanges(neededUserSession, undefined);

    const [saveSession] = useSaveQuestionnaireRespondentSessionCallback();
    return (
        <>
            <Modal isOpen={isOpen} className="select-question-modal">
                <ModalHeader toggle={() => toggle()}>
                    {t('EditRespondantSessionModal.header', 'Edit Partcipant Department')}
                </ModalHeader>

                <ModalBody>
                    <Label htmlFor="subscriptionDepartmentId" className={"header-label"}>{t('EditRespondantSessionModal.description', `Change the department for ${neededUserSession?.profile.firstName} ${neededUserSession?.profile.lastName}  by selecting from the dropdown below`)}</Label>
                    <Row>
                        <Col>
                            <Label htmlFor="subscriptionDepartmentId" className={"input-label"}>{t('EditRespondantSessionModal.department', 'Department')}</Label>
                            <> </>
                            <Input name="subscriptionDepartmentId" className={"detail-input-box"} type="select"
                                value={modelSession.subscriptionDepartmentId ?? ''}
                                onChange={e => { change({ subscriptionDepartmentId: e.currentTarget.value || null }) }}>
                                <option key={null} value=''>{t('EditRespondantSessionModal.departmentPlaceholder', 'None')}</option>
                                {
                                    departments?.map(item => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))
                                }
                            </Input>
                        </Col>
                    </Row>

                    <FormButtons>
                        <Button color="primary" onClick={async () => { await saveSession(modelSession.id, changes, !neededUserSession); toggle(); }}>
                            <FontAwesomeIcon icon="save" /> 
                            <> </>
                            {t('deleteSectionModal.delete', 'Save')}
                        </Button>
                        <Button color="primary" outline onClick={() => toggle()}>
                            {t('deleteSectionModal.no', 'Close')}
                        </Button>
                    </FormButtons>
                </ModalBody>
            </Modal>
        </>
    );
};

