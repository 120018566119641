import * as React from 'react';
import { Button, Form, Label, FormGroup, Spinner, Input, Row, Col } from 'reactstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { useDeleteQuestionnaireRespondentSessionCallback } from '../../../api/main/questionnaireRespondentSessions/useDeleteQuestionnaireRespondentSessionCallback';
import { useProfile } from '../../../api/main/profiles/useProfile';
import { useQuestionnaireRespondentSession } from '../../../api/main/questionnaireRespondentSessions/useQuestionnaireRespondentSession';
import { useUser } from '../../../api/main/users/useUser';


/**
 * Remove User From Questionnaire.
 */
export const QuestionnaireUserRemove = () => {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();

    //get respondant session for use for this questionnaire
    const { data: { model }, isLoading, errors: loadErrors } = useQuestionnaireRespondentSession(id);

    //get the user profile
    const userProfile = useProfile(model?.userId, { isUserId: true });

    //get the user
    const user = useUser(model?.userId);

    const [remove, { errors: removeErrors }] = useDeleteQuestionnaireRespondentSessionCallback();

    const history = useHistory();


    const [onRemoveFormSubmit, { isExecuting: isRemoving, errors: removeFormErrors }] = useAsyncCallback(async () => {
        if (!model) {
            return;
        }

        await remove(model.id);

        history.goBack();
    }, [model, history]);

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('questionnaireUsersDelete.heading', 'Are you sure you want to remove this user from this questionnaire?')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, removeFormErrors, removeErrors]} />

                <Form onSubmit={e => { e.preventDefault(); onRemoveFormSubmit(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="firstName">{t('questionnaireUsersDelete.firstName', 'First Name')}</Label>
                                <Input name="firstName" type="text" disabled plaintext value={userProfile.data.model?.firstName ?? ''} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="lastName">{t('questionnaireUsersDelete.lastName', 'LastName')}</Label>
                                <Input name="lastName" type="text" disabled plaintext value={userProfile.data.model?.lastName ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="Email">{t('questionnaireUsersDelete.email', 'Email')}</Label>
                                <Input name="email" type="text" disabled plaintext value={user.data.model?.email ?? ''} />
                            </FormGroup>
                        </Col>
                    </Row>
                    
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="danger" isExecuting={isRemoving}
                                executingChildren={<><Spinner size="sm" /> {t('common.deleting', 'Removing...')}</>}>
                                <FontAwesomeIcon icon="trash" />
                                <> {t('common.delete', 'Remove')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
