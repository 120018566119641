

export function getIconCssSuffixFromCategoryName(categoryName: string): string {
    switch (categoryName) {
        case 'accidentsatwork':
            return '-AccidentsAtWork';

        case 'accountability':
            return '-Accountability';

        case 'actions':
            return '-Actions';

        case 'coachingandfeedback':
        case 'coaching&feedback':
            return '-CoachingAndFeedback';

        case 'communication':
            return '-Communication';

        case 'confidencelevels':
            return '-ConfidenceLevels';

        case 'continuouslearning':
            return '-ContinuousLearning';

        case 'costsavings':
            return '-CostSaving';

        case 'customerexperience':
            return '-CustomerExperience';

        case 'difficult/honestconversations':
        case 'difficulthonestconversations':
            return '-DifficultHonestConversations';

        case 'effectivecommunication':
            return 'Effective Communication';

        case 'efficiency':
            return '-Efficiency';

        case 'engagement':
            return '-Engagement';

        case 'healthandsafety':
            return '-HealthAndSafety';

        case 'improvedrebatemanagement':
            return '-ImprovedRebateManagement';

        case 'increaseinperformance':
        case 'performance':
            return '-IncreaseInPerformance';

        case 'intentions':
            return '-Intentions';

        case 'learningpoints':
            return '-LearningPoints';

        case 'managingchange':
            return '-ManagingChange';

        case 'meetingtargets':
            return '-MeetingTargets';

        case 'mentalhealth':
            return '-MentalHealth';

        case 'personalactivity':
            return '-PersonalActivity';

        case 'postprogrammeactions':
            return '-PostProgrammeActions';

        case 'pre-activitygoals':
            return '-PreActivityGoals';

        case 'problemsolvinganddecisionmaking':
        case 'problemsolving&decisionmaking':
            return '-ProblemSolvingAndDecisionMaking';

        case 'processimprovement':
            return '-ProcessImprovement';

        case 'profit':
            return '-Profit';

        case 'resilienceandstress':
            return '-ResilienceAndStress';

        case 'results':
            return '-Results';

        case 'revenue':
            return '-Revenue';

        case 'staffretention':
            return '-StaffRetention';

        case 'timesaver':
            return '-TimeSaver';

        case 'wellbeing':
            return '-Wellbeing';

        default:
            return '-default';
    }
}