import { useEffect,} from "react";
import { Button, Row, Col, Form, Label, FormGroup, Spinner, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { subscriptionDepartmentDefaultValues } from '../../api/main/models/SubscriptionDepartment';
import { useSubscriptionDepartment } from '../../api/main/subscriptionDepartments/useSubscriptionDepartment';
import { useSaveSubscriptionDepartmentCallback } from '../../api/main/subscriptionDepartments/useSaveSubscriptionDepartmentCallback';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { ManageSubscriptionDepartmentNavigation } from "./ManageSubscriptionDepartmentNavigation";

interface EditSubscriptionDepartmentProps {
    isCreate?: boolean,
}

/**
 * Create a new subscriptionDepartment.
 */
export const CreateSubscriptionDepartment = () => (<EditSubscriptionDepartment isCreate={true} />);

/**
 * Edit a subscriptionDepartment.
 */
export const EditSubscriptionDepartment = (props: EditSubscriptionDepartmentProps) => {
    const { isCreate } = props;

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: {
        model: storeModel,
    }, isLoading: _isLoading, errors: loadErrors } = useSubscriptionDepartment(id);
    const isLoading = _isLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? subscriptionDepartmentDefaultValues() : undefined);
    const [save, { errors: saveErrors }] = useSaveSubscriptionDepartmentCallback();
    const history = useHistory();

    /* set up the subscriptionId for a new subscriptionDepartment */
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    useEffect(() => {
        if (isCreate) {
            change({ subscriptionId: currentUserSubscriptionId ?? undefined });
        }
    }, [isCreate, change, currentUserSubscriptionId]);
    
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editSubscriptionDepartment.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async (options?: { goBack?: boolean, navigateTo?: string, }) => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        // Navigate to the next screen.
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else if (options?.goBack ?? true) {
            history.goBack();
        }
        return true;
    }, [validate, save, model, changes, isCreate, history]);


    return (
        <Background>
            <Banner>
                <Row>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                    <Col>
                    </Col>
                    <Col>
                        <PillsNavBar>
                            <ManageSubscriptionDepartmentNavigation
                                id={model?.id ?? ''}
                                editLink={history.location.pathname}
                                onNavigate={link => saveForm({ navigateTo: link })}
                            />
                        </PillsNavBar>
                    </Col>
                    
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editSubscriptionDepartment.createHeading', 'Add Department')}</>
                                ) : (
                                    <>{t('editSubscriptionDepartment.editHeading', 'Edit {{name}} Department', { name: model?.name })}</>
                                )
                            }
                        </h1>
                    </Col>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, ]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editSubscriptionDepartment.name', 'Department Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model.name ?? ''}
                                    onChange={e => change({ name: e.currentTarget.value })}
                                    onBlur={e => validate('name')}
                                    validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
