import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { SubscriptionDepartmentCreateInput } from '../generated/globalTypes';
import { createSubscriptionDepartmentMutation, createSubscriptionDepartmentMutationVariables } from '../generated/createSubscriptionDepartmentMutation';
import { subscriptionDepartmentFieldsFragment } from '../models/SubscriptionDepartment';

/**
 * Get a callback to create a SubscriptionDepartment in the store.
 */
export function useCreateSubscriptionDepartmentCallback(): [(model: SubscriptionDepartmentCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createSubscriptionDepartmentMutation, createSubscriptionDepartmentMutationVariables>(
        gql`
            mutation createSubscriptionDepartmentMutation ($model: SubscriptionDepartmentCreateInput!) {
                createSubscriptionDepartment(model: $model) {
                    ...subscriptionDepartmentFields
                }
            }

            ${subscriptionDepartmentFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: SubscriptionDepartmentCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
