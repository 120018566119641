import { useState, useMemo } from "react";
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { useSubscriptionDepartments } from '../../api/main/subscriptionDepartments/useSubscriptionDepartments';
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { ManageNavigation } from "../manage/ManageNavigation";
import { useDeleteSubscriptionDepartmentCallback } from "../../api/main/subscriptionDepartments/useDeleteSubscriptionDepartmentCallback";
import { DeleteAnyModal } from "../shared/DeleteAnyModal";

/**
 * List of subscriptionDepartments.
 */
export const SubscriptionDepartmentsList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useSubscriptionDepartments({ pageSize: undefined, subscriptionId: currentUserSubscriptionId });

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return [];
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });


    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteSubscriptionDepartmentCallback();
    const [deletedItemName, setDeletedItemName] = useState<string>("");
    const [deletedItemId, setDeletedItemId] = useState<string>("");

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs={12}>
                        <PillsNavBar>
                            <ManageNavigation
                            />
                        </PillsNavBar>
                    </Col>
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col xs={12} sm="">
                        <h1>{t('subscriptionDepartmentsList.heading', 'Departments')}</h1>
                    </Col>
                   
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row className={"searchBar"}>
                        <Col xs="">
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to="/manage/subscriptionDepartments/add">
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('subscriptionDepartmentsList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, removeErrors, ]} />
                <Row className="mb-2">
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>

                <CardsOrTable
                    // always show as list as the cards look wrong with no information
                    viewMode={'table'}
                    items={items}
                    onItemClick={item => history.push(`/manage/subscriptionDepartments/edit/${item.id}`)}
                    tableHeadings={[
                        t('subscriptionDepartmentsList.name', 'Name'),

                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.name}</CardTitle>) : item.name,
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`/manage/subscriptionDepartments/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); setDeletedItemName(item.name); }}>
                                        <FontAwesomeIcon icon="trash" />
                                        <> {t('common.delete', 'Delete')}</>
                                    </DropdownItem>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={deletedItemName}
                        remove={() => remove(deletedItemId)}
                    />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
