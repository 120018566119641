import { useMemo } from "react";
import { useSubscriptionStyleOverridesViewModel } from "../../api/main/subscriptions/viewModels/useSubscriptionStyleOverridesViewModel";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";

/**
 * Component that renders some style overrides based on the theming of specific components.
 */
export const SubscriptionStyleOverrides = () => {
    const subscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { model: subscription } } = useSubscriptionStyleOverridesViewModel(subscriptionId);

    // Generate the styles.
    const style = useMemo(() => {
        
        const logoUrl = subscription?.brandImageBlobReference?.url;
        let brandColor = subscription?.brandColor;
        const whiteColor = '#fff';

        // Treat a fully black colour as a not being overrided colour.
        if (brandColor === '#000000') {
            brandColor = '';
        }

        let ret = '';

        // If a logo is uploaded against the subscription, swap our brand image for the logo.
        if (logoUrl) {
            ret += `
                .navbar-brand {
                    background-image: url("${logoUrl}") !important;
                }

                /*Report Logo*/
                .brand-logo {
                        background-image: url("${logoUrl}") !important;
                    }
            `;
        }

        // If a brand colour is specificed against the subscription, swap our primary colour for the colour.
        if (brandColor) {
            const borderColor = shadeBlend(-0.2, brandColor);
            const hoverColor = shadeBlend(-0.4, brandColor);
            const shadedColor = shadeBlend(0.6, brandColor);
            const darkBrandColor = shadeBlend(-0.5, brandColor);
            const lightBrandColor = shadeBlend(0.4, brandColor);
            const sideBarColor = shadeBlend(-0.3, brandColor);


            ret += `
                /*Report styles*/
                .grid-square {
                    background-color: ${brandColor} !important;
                }
                
                .splash-footer {
                    border-color: ${brandColor} !important;
                }

                .particpant-progress-bar-background {
                    background-color: ${brandColor} !important;
                }


                /* Text and backgrounds that are primary coloured should be switched to the brand colour. */
                .text-primary {
                    color: ${brandColor} !important;
                }

                .bg-primary {
                    background-color: ${brandColor} !important;
                }

                /* Primary buttons should use the brand colour. except where excluded by using not-brand*/
                .btn-primary:not(:hover):not(.not-brand) {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-primary:hover:not(.not-brand) {
                    background-color: ${hoverColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-outline-primary:focus, .btn-outline-primary.focus {
                    box-shadow: 0 0 0 0.1rem ${shadedColor};
                }

                .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.1rem ${shadedColor};
                }

                .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
                    border-color: ${brandColor};
                    background-color: ${hoverColor};
                }

                .btn-outline-primary:not(:hover):not(.dropdown-toggle[aria-expanded="true"]) {
                    color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-outline-primary:hover {
                    background-color: ${hoverColor} !important;
                    border-color: ${borderColor} !important;
                }

                .btn-primary:not(.not-brand):focus, .btn-primary.focus:not(.not-brand) {
                    box-shadow: 0 0 0 0.1rem ${hoverColor};
                }

                .btn-primary:not(:disabled):not(.disabled):not(.not-brand):active:focus, .btn-primary:not(:disabled):not(.disabled):not(.not-brand).active:focus, .show > .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.1rem rgb(194 73 145 / 50%);
                }

                .btn-primary:not(.not-brand).disabled:hover, .btn-outline-primary.disabled:hover {
                    background-color: transparent !important;
                    color: ${borderColor} !important;
                }

                .btn-primary:not(:disabled):not(.disabled):not(.not-brand):active:focus, .btn-primary:not(:disabled):not(.disabled):not(.not-brand).active:focus, .show > .btn-primary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.1rem ${shadedColor};
                }


                .custom-control-input:focus ~ .custom-control-label::before {
                    box-shadow: 0 0 0 0.2rem ${shadedColor};
                }

                .custom-control-input:checked ~ .custom-control-label::before {
                    border-color: ${brandColor};
                }
                /* Secondary Buttons should use a shaded version of the brandcolor */
                .btn-secondary {
                    background-color: ${darkBrandColor} !important;
                    border-color: ${darkBrandColor} !important;
                }

                .btn-outline-secondary {
                    color: ${darkBrandColor} !important;
                    border-color: ${darkBrandColor} !important;
                }

                .btn-secondary:focus, .btn-secondary.focus {
                    box-shadow: 0 0 0 0.05rem ${darkBrandColor};
                }

                .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
                    background-color: ${darkBrandColor}  !important;
                }

                .btn-outline-secondary:hover {
                    background-color: ${darkBrandColor}  !important;
                    border-color: ${darkBrandColor}  !important;
                }

                .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
                    box-shadow: ${darkBrandColor}  !important;
                }

                .btn-outline-secondary:hover {
                    color: ${whiteColor} !important;;
                }
                
                .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.05rem ${darkBrandColor};
                }


                /*SideBar Nav Needs Brand Colors*/
                .sidebar {
                background-color: ${sideBarColor};
                }

                .sidebar .nav-link.active {
                    color: #fff;
                    background: ${brandColor};
                }

                .sidebar .nav-link:hover {
                    color: #fff;
                    background: ${brandColor};
                }

                .sidebar .sidebar-minimizer:hover {
                    background-color: ${brandColor};
                }

                .sidebar-minimized .sidebar .nav-link:hover {
                    background-color: ${brandColor} !important;
                }

                /* Navigation pills should use the brand colour. */
                .pills-nav-bar .nav-item .nav-link:not(.active) {
                    color: ${brandColor};
                    border-color: ${borderColor} !important;
                }

                .pills-nav-bar .nav-item a:hover {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                    color: ${whiteColor} !important;
                }

                .pills-nav-bar .nav-item .nav-link.active {
                    background-color: ${brandColor} !important;
                    border-color: ${borderColor} !important;
                }

                /* Switch Buttons need to use the brand Colour */
                .custom-control-input:checked ~ .custom-control-label::before {
                background-color: ${brandColor} !important;
                }
                

                /*Card headers need to use the brand Colour */
                .cards-or-table.cards-or-table-cards .cards-or-table-card.card .card-body .card-title {
                    color: ${brandColor};
                }

                .cards-or-table.cards-or-table-cards .cards-or-table-card .card-footer .btn-outline-primary {
                    background-color: ${whiteColor};
                    color: ${brandColor};
                    border-color: ${brandColor};
                }

                .cards-or-table.cards-or-table-cards .cards-or-table-card .card-footer .btn-outline-primary:focus {
                    box-shadow: 0 0 0 0.05rem ${shadedColor};
                }

                .dropdown-item:active {
                    color: #fff;
                    text-decoration: none;
                    background-color: ${shadedColor};
                }

                .dropdown-item:focus {
                    background-color: ${brandColor};
                    color: #fff;
                }
            
                /* Question Modal needs to use the Brand Colour */
                .select-question-modal .introduction {
                    color: ${brandColor};
                    background-color: ${shadedColor};
                }

                .select-question-modal .section-header {
                    color: ${brandColor};
                }

                .select-question-modal .modal-header {
                    background-color: ${brandColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link.active {
                    background-color: ${brandColor};
                }

                .nav-tiles .nav-item .nav-link {
                    width: 100%;
                    height: 150px;
                    border: 1px solid ${brandColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link h2 {
                    color: ${brandColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link:hover h2 {
                    color: ${hoverColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link.active:hover h2 {
                    color: ${whiteColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link:hover {
                    color: ${hoverColor};
                }

                .select-question-modal .nav-tiles .nav-item .nav-link.active:hover {
                    color: ${whiteColor};
                }
                
                .nav-tiles .nav-item .nav-link {
                    color: ${brandColor};
                }

                .form-control:focus {
                    border-color: ${brandColor};
                    box-shadow: 0 0 0 0.2rem ${shadedColor};
                }

                .select-question-modal .preview-container {
                    border-top: 1px solid ${brandColor};
                    border-bottom: 1px solid ${brandColor};
                }

                /*Response type Questions*/
                .response-type-selectors .response-type-selector {
                    color: ${brandColor};
                    border: 1px solid ${brandColor};

                }

                .response-type-selectors .response-type-selector.active {
                    background-color: ${brandColor};
                }

                .response-type-selectors .response-type-selector:hover {
                    background-color: ${brandColor};
                }

                .multiple-choice-possible-responses-container input {
                    border-color: ${brandColor};
                }

                .btn:not(not-brand):focus, .btn.focus {
                    outline: 0;
                    box-shadow: 0 0 0 0.2rem ${shadedColor};
                }

                /* Top of Page Nav Bar Needs to Use BrandColor */
                .app-header .nav-item a.nav-link.active {
                    background-color: ${brandColor};
                }

                .app-header .nav-item a.nav-link.active:hover {
                    background-color: ${hoverColor};
                }

                .app-header .nav-item a.nav-link {
                    color: ${brandColor};
                }

                .app-header .nav-item a.nav-link:hover {
                    border-bottom-color: ${brandColor};
                }

                /* Dashboard text needs the brand color */
                a {
                    color: ${brandColor};
                }

                a:hover {
                    color:  ${hoverColor};
                }


                /*Questionnaire and Self-Service Modal needs Brand Colours */
                .select-questionnaire-modal .modal-header {
                    background-color: ${brandColor};
                    color: ${whiteColor};
                }

                /*Dasboard Back Button*/
                .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
                    box-shadow: 0 0 0 0.2rem ${shadedColor};
                }

                /* tooltips */
                [data-toggle="tooltip"]{
                    color: ${brandColor};
                }

                /* large nav tiles can be used anywhere but mainly on wizrd type views */
                .nav-tiles-large .nav-item .nav-link {
                }

                .nav-tiles-large .nav-item .nav-link.active {
                    background-color: ${brandColor} !important;
                    color: ${whiteColor};
                }

                .nav-tiles-large .nav-item .nav-link:hover {
                    background-color: ${hoverColor} !important;
                    color: ${whiteColor};
                }

                /* questionnaire navigation links */
                .questionnaire-navigation-container .nav-tiles-large .nav-item .nav-link.active {
                    background-color: ${brandColor} !important;
                }
                .questionnaire-navigation-container .nav-tiles-large .nav-item .nav-link.active:hover {
                    background-color: ${hoverColor} !important;
                }
                .questionnaire-navigation-container  .nav-tiles-large .nav-item .nav-link:hover {
                    background-color: ${brandColor} !important;
                }
                .questionnaire-navigation-container  .nav-tiles-large .nav-item .nav-link.completed-link {
                    box-shadow: 
                        5px 5px 10px ${lightBrandColor} inset,
                        -5px -5px 10px ${lightBrandColor} inset,
                        5px 5px 10px ${lightBrandColor},
                        -5px -5px 10px ${lightBrandColor};
                    border-color: ${brandColor} !important;
                }
                .subscription-template-list-main-container .highlighted-name {
                    color: ${brandColor} !important;
                }
                .app-header .sb-avatar__text {
                    background: ${brandColor} !important;
                }

                .questionnaire-main-container .questionnaire-name{
                    color: ${brandColor} !important;
                }

                /* people tab on edit questionnaire */
                .people-tab-main-container .add-user-details-container .add-user-details{
                    border-bottom-color: ${brandColor} !important;
                }

                /* questionnaire completion */
                .complete-questionnaire .questionnaire-container .section-header {
                    color: ${brandColor};
                }

                .complete-questionnaire .questionnaire-container .question-container .question-number .strong{
                    text-decoration-color: ${brandColor};
                }

                .question-multiplechoice .btn-response{
                    border-color: ${brandColor} !important;
                }

                .question-multiplechoice .btn-response:focus{
                    border-color: ${brandColor} !important;
                    background-color: ${whiteColor} !important;
                }

                .question-multiplechoice .btn-response.active{
                    background-color: ${brandColor} !important;
                }

                .question-multiplechoice .btn-response:focus.active{
                    background-color: ${brandColor} !important;
                }

                .question-pickone .btn-response{
                    border-color: ${brandColor} !important;
                }

                .question-pickone .btn-response:focus{
                    background-color: ${brandColor} !important;
                }

                .question-pickone .btn-response.active{
                    background-color: ${brandColor} !important;
                }

                .question-measure .yes-no-input .btn-response{
                    border-color: ${brandColor} !important;
                }

                .question-measure .yes-no-input .btn-response:hover{
                    background-color: ${brandColor} !important;
                }

                .question-measure .yes-no-input .btn-response:focus{
                    background-color: ${brandColor} !important;
                }

                .question-measure .yes-no-input .btn-response.active{
                    background-color: ${brandColor} !important;
                }

                input[type="radio"]{
                    accent-color: ${brandColor} !important;
                }

                .report-name-modal .modal-header {
                    background-color: ${brandColor};
                }
                .report-name-modal .btn {
                   border-color: transparent !important;
                }

                /*Analyser */

                .analyser-heading {
                    color: ${brandColor} !important;
                }

                .analyserchecklist-container .group-table-row {
                    background-color: ${brandColor} !important;
                }
            `;
        }

        return ret;
    }, [subscription]);

    return (
        <style type="text/css">
            {style}
        </style>
        );
};

/**
 * Shade a color.
 * 
 * Taken from v2 universal B from: https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)#stackoverflow-archive-begin
 * @param p
 * @param c0
 * @param c1
 */
function shadeBlend(p: number, c0: string, c1?: string) {
    var n = p < 0 ? p * -1 : p, u = Math.round, w = parseInt;
    if (c0.length > 7) {
        // eslint-disable-next-line no-mixed-operators
        const f = c0.split(","), t = (c1 ? c1 : p < 0 ? "rgb(0,0,0)" : "rgb(255,255,255)").split(","), R = w(f[0].slice(4)), G = w(f[1]), B = w(f[2]);
        // eslint-disable-next-line no-mixed-operators
        return "rgb(" + (u((w(t[0].slice(4)) - R) * n) + R) + "," + (u((w(t[1]) - G) * n) + G) + "," + (u((w(t[2]) - B) * n) + B) + ")"
    } else {
        // eslint-disable-next-line no-mixed-operators
        const f = w(c0.slice(1), 16), t = w((c1 ? c1 : p < 0 ? "#000000" : "#FFFFFF").slice(1), 16), R1 = f >> 16, G1 = f >> 8 & 0x00FF, B1 = f & 0x0000FF;
        // eslint-disable-next-line no-mixed-operators
        return "#" + (0x1000000 + (u(((t >> 16) - R1) * n) + R1) * 0x10000 + (u(((t >> 8 & 0x00FF) - G1) * n) + G1) * 0x100 + (u(((t & 0x0000FF) - B1) * n) + B1)).toString(16).slice(1)
    }
}
