import { TFunction } from "i18next";


/**
 * Role groups
 */
export enum RoleGroup {
    User = "User",
    SubscriptionDepartmentAdministrator = "SubscriptionDepartmentAdministrator",
    SubscriptionAdministrator = "SubscriptionAdministrator",
    Administrator = "Administrator",
}

/**
 * Return all Role group names.
 * @param t
 */
export function roleGroupDisplayName(type: RoleGroup, t: TFunction): string {
    switch (type) {
        case RoleGroup.User: return t('roleGroup.User', 'User');
        case RoleGroup.SubscriptionDepartmentAdministrator: return t('roleGroup.SubscriptionDepartmentAdministrator', 'Department administrator');
        case RoleGroup.SubscriptionAdministrator: return t('roleGroup.SubscriptionAdministrator', 'Subscription administrator');
        case RoleGroup.Administrator: return t('roleGroup.Administrator', 'Administrator');
    }
}
/**
 * Return all Role group names.
 * @param t
 */
export function roleGroupDisplayNameFromString(name: string, t: TFunction): string {
    switch (name) {
        case RoleGroup.User: return t('roleGroup.User', 'User');
        case RoleGroup.SubscriptionDepartmentAdministrator: return t('roleGroup.SubscriptionDepartmentAdministrator', 'Department administrator');
        case RoleGroup.SubscriptionAdministrator: return t('roleGroup.SubscriptionAdministrator', 'Subscription administrator');
        case RoleGroup.Administrator: return t('roleGroup.Administrator', 'Administrator');
        default: return t('roleGroup.User', 'User');
    }
}
