import { UserManager, WebStorageStateStore, User, Profile } from 'oidc-client';
import { apiAuthorizationConfig } from '../../configure/apiAuthorizationConfig';

export interface AuthenticationResult {
    status: string,
    message?: string,
    state?: any,
}

export class AuthorizeService {
    // By default pop ups are disabled because they don't work properly on Edge.
    // If you want to enable pop up authentication simply set this flag to false.
    _popUpDisabled = true;

    _callbacks: Array<{ callback: () => void, subscription: number }>;
    _nextSubscriptionId: number;
    _user: User | null;
    _isAuthenticated: boolean = false;
    _userManager: UserManager | undefined;



    constructor() {
        this._callbacks = [];
        this._nextSubscriptionId = 0;
        this._user = null;
        this._isAuthenticated = false;
        this._userManager = undefined;
    }

    async isAuthenticated(): Promise<boolean> {
        const user = await this.getUser();
        return !!user;
    }

    async getUser(): Promise<Profile | null> {
        let user = this._user;

        if (user && user.profile) {
            return user.profile;
        }

        const userManager = await this.ensureUserManagerInitialized();
        user = await userManager.getUser();
        return user?.profile ?? null;
    }

    async getAccessToken(): Promise<string | null> {
        const userManager = await this.ensureUserManagerInitialized();
        const user = await userManager.getUser();
        return user?.access_token ?? null;
    }

    // We try to authenticate the user in three different ways:
    // 1) We try to see if we can authenticate the user silently. This happens
    //    when the user is already logged in on the IdP and is done using a hidden iframe
    //    on the client.
    // 2) We try to authenticate the user using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 3) If the two methods above fail, we redirect the browser to the IdP to perform a traditional
    //    redirect flow.
    async signIn(state: any): Promise<AuthenticationResult> {
        const userManager = await this.ensureUserManagerInitialized();
        try {
            const silentUser = await userManager.signinSilent(this.createArguments());
            this.updateState(silentUser);
            return this.success(state);
        } catch (silentError) {
            // User might not be authenticated, fallback to popup authentication
            console.log("Silent authentication error: ", silentError);

            try {
                if (this._popUpDisabled) {
                    throw new Error('Popup disabled. Change \'AuthorizeService.js:AuthorizeService._popupDisabled\' to false to enable it.')
                }

                const popUpUser = await userManager.signinPopup(this.createArguments());
                this.updateState(popUpUser);
                return this.success(state);
            } catch (popUpError) {
                if (popUpError.message === "Popup window closed") {
                    // The user explicitly cancelled the login action by closing an opened popup.
                    return this.error("The user closed the window.");
                } else if (!this._popUpDisabled) {
                    console.log("Popup authentication error: ", popUpError);
                }

                // PopUps might be blocked by the user, fallback to redirect
                try {
                    await userManager.signinRedirect(this.createArguments(state));
                    return this.redirect();
                } catch (redirectError) {
                    console.log("Redirect authentication error: ", redirectError);
                    return this.error(redirectError);
                }
            }
        }
    }

    async completeSignIn(url: string): Promise<AuthenticationResult> {
        try {
            const userManager = await this.ensureUserManagerInitialized();
            const user = await userManager.signinCallback(url);
            this.updateState(user);
            return this.success(user && user.state);
        } catch (error) {
            console.log('There was an error signing in: ', error);
            return this.error('There was an error signing in.');
        }
    }

    // We try to sign out the user in two different ways:
    // 1) We try to do a sign-out using a PopUp Window. This might fail if there is a
    //    Pop-Up blocker or the user has disabled PopUps.
    // 2) If the method above fails, we redirect the browser to the IdP to perform a traditional
    //    post logout redirect flow.
    async signOut(state: any) {
        const userManager = await this.ensureUserManagerInitialized();
        try {
            if (this._popUpDisabled) {
                throw new Error('Popup disabled. Change \'AuthorizeService.ts:AuthorizeService._popupDisabled\' to false to enable it.')
            }

            await userManager.signoutPopup(this.createArguments());
            this.updateState(null);
            return this.success(state);
        } catch (popupSignOutError) {
            console.log("Popup signout error: ", popupSignOutError);
            try {
                await userManager.signoutRedirect(this.createArguments(state));
                return this.redirect();
            } catch (redirectSignOutError) {
                console.log("Redirect signout error: ", redirectSignOutError);
                return this.error(redirectSignOutError);
            }
        }
    }

    async completeSignOut(url: string) {
        const userManager = await this.ensureUserManagerInitialized();
        try {
            const response = await userManager.signoutCallback(url);
            this.updateState(null);
            return this.success(response && response.state);
        } catch (error) {
            console.log(`There was an error trying to log out '${error}'.`);
            return this.error(error);
        }
    }

    updateState(user: User | null) {
        this._user = user;
        this._isAuthenticated = !!this._user;
        this.notifySubscribers();
    }

    subscribe(callback: () => void): number {
        this._callbacks.push({ callback, subscription: this._nextSubscriptionId++ });
        return this._nextSubscriptionId - 1;
    }

    unsubscribe(subscriptionId: number) {
        const subscriptionIndex = this._callbacks
            .map((element, index) => element.subscription === subscriptionId ? { found: true, index } : { found: false })
            .filter(element => element.found === true);
        if (subscriptionIndex.length === 1) {
            this._callbacks = this._callbacks.splice(subscriptionIndex[0].index as number, 1);
        } else {
            //throw new Error(`Found an invalid number of subscriptions ${subscriptionIndex.length}`);
        }
    }

    notifySubscribers() {
        for (let i = 0; i < this._callbacks.length; i++) {
            const callback = this._callbacks[i].callback;
            callback();
        }
    }

    createArguments(state?: any) {
        return { useReplaceToNavigate: true, data: state };
    }

    error(message: string) : AuthenticationResult {
        return { status: AuthenticationResultStatus.Fail, message };
    }

    success(state: any): AuthenticationResult {
        return { status: AuthenticationResultStatus.Success, state };
    }

    redirect(): AuthenticationResult {
        return { status: AuthenticationResultStatus.Redirect };
    }

    async ensureUserManagerInitialized(): Promise<UserManager> {
        if (this._userManager !== undefined) {
            return this._userManager;
        }

        let response = await fetch(`${apiAuthorizationConfig.endpoint}_configuration/${apiAuthorizationConfig.applicationName}`);
        if (!response.ok) {
            throw new Error(`Could not load settings for '${apiAuthorizationConfig.applicationName}'`);
        }

        let settings = await response.json();
        settings.automaticSilentRenew = true;
        settings.includeIdTokenInSilentRenew = true;
        settings.userStore = new WebStorageStateStore({
            prefix: apiAuthorizationConfig.applicationName
        });

        this._userManager = new UserManager(settings);

        this._userManager.events.addUserSignedOut(async () => {
            await (this._userManager as UserManager).removeUser();
            this.updateState(null);
        });

        return this._userManager;
    }

    static get instance() { return authService }
}

const authService = new AuthorizeService();

export default authService;

export const AuthenticationResultStatus = {
    Redirect: 'redirect',
    Success: 'success',
    Fail: 'fail'
};
