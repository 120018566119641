import { Button, Row, Col, Form, Label, FormGroup, Spinner, } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useChanges } from '../../shared/useChanges';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { FormButtons } from '../shared/FormButtons';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { analysisTypeDefaultValues } from '../../api/main/models/AnalysisType';
import { useAnalysisType } from '../../api/main/analysisTypes/useAnalysisType';
import { useSaveAnalysisTypeCallback } from '../../api/main/analysisTypes/useSaveAnalysisTypeCallback';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { useSubscription } from '../../api/main/subscriptions/useSubscription';
import { ValidatedNumberInput } from '../shared/numberInput/ValidatedNumberInput';

interface EditAnalysisTypeProps {
    isCreate?: boolean,
    isAdmin?: boolean,
}

/**
 * Create a new analysisType.
 */
export const CreateAnalysisType = () => (<EditAnalysisType isCreate={true} />);
/**
 * Create a new admin analysisType.
 */
export const CreateAdminAnalysisType = () => (<EditAnalysisType isCreate={true} isAdmin={true}/>);

/**
 * Edit an analysisType.
 */
export const EditAnalysisType = (props: EditAnalysisTypeProps) => {
    const { isCreate, isAdmin } = props;

    /* set up the subscriptionId for a new analysisType */
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const {
        data: {
            model: subscription,
        }, isLoading: subscriptionIsLoading, errors: subscriptionErrors,
    } = useSubscription(currentUserSubscriptionId);

    const { t } = useTranslation();
    const { id } = useParams<{ id: string | undefined }>();
    const { data: {
        model: storeModel,
    }, isLoading: _isLoading, errors: loadErrors } = useAnalysisType(id);
    const isLoading = _isLoading || subscriptionIsLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? ({ ...analysisTypeDefaultValues(), isAdminOnly: !!isAdmin ? true : false, subscriptionId: !!isAdmin ? null : currentUserSubscriptionId }) : undefined);
    const [save, { errors: saveErrors }] = useSaveAnalysisTypeCallback();
    const history = useHistory();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editAnalysisType.nameRequired', 'Analysis Type Name is required') : '',
            baseAmountDefault: () => model?.baseAmountDefault === undefined || model?.baseAmountDefault === null ? t('editAnalysisType.baseAmountRequired', 'Default Base Amount is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.goBack();
    }, [validate, save, model, changes, isCreate, history]);


    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>
                            {
                                isCreate ? (
                                    <>{t('editAnalysisType.createHeading', 'Add Analysis Type')}</>
                                ) : (
                                    <>{t('editAnalysisType.editHeading', 'Edit Analysis Type')}</>
                                )
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, subscriptionErrors]} />

                <Form onSubmit={e => { e.preventDefault(); saveForm(); }}>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label htmlFor="name">{t('editAnalysisType.name', 'Analysis Type Name')}</Label>
                                <ValidatedInput name="name" type="text" value={model.name ?? ''}
                                    onChange={e => change({ name: e.currentTarget.value })}
                                    onBlur={e => validate('name')}
                                    validationErrors={validationErrors['name']} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Label htmlFor="baseAmount">{t('editAnalysisType.baseAmount', 'Default Base Amount')}</Label>
                            <ValidatedNumberInput name="baseAmountDefault" value={model?.baseAmountDefault as number}
                                onChange={e => { change({ baseAmountDefault: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0 });}}
                                onBlur={e => validate('baseAmountDefault')}
                                validationErrors={validationErrors['amountInput']}
                                onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                                currencySymbol={subscription?.currencySymbol ?? '£'}
                            />
                        </Col>
                    </Row>
                    <FormButtons>
                        <ConditionalFragment showIf={!isLoading}>
                            <ButtonAsync color="primary" isExecuting={isSaving}
                                executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                <FontAwesomeIcon icon="save" />
                                <> {t('common.save', 'Save')}</>
                            </ButtonAsync>
                        </ConditionalFragment>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.cancel', 'Cancel')}
                        </Button>
                    </FormButtons>
                </Form>
            </MainContainer>
        </Background>
    );
};
