import * as React from 'react';
import { QuestionnaireRespondentSessionCreateInput, QuestionnaireRespondentSessionUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateQuestionnaireRespondentSessionCallback } from './useCreateQuestionnaireRespondentSessionCallback';
import { useUpdateQuestionnaireRespondentSessionCallback } from './useUpdateQuestionnaireRespondentSessionCallback';
import { QuestionnaireRespondentSession } from '../models/QuestionnaireRespondentSession';

/**
 * Get a callback to save a QuestionnaireRespondentSession in the store using either a create or update.
 */
export function useSaveQuestionnaireRespondentSessionCallback(options: SaveInStoreOptions<QuestionnaireRespondentSession, string> = {}) {
    const [_create, createStatus] = useCreateQuestionnaireRespondentSessionCallback();
    const create = React.useCallback((model: Partial<QuestionnaireRespondentSession>) => _create(model as QuestionnaireRespondentSessionCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateQuestionnaireRespondentSessionCallback();
    const update = React.useCallback((id: string, changes: Partial<QuestionnaireRespondentSession>) => _update(id, changes as QuestionnaireRespondentSessionUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
