import { Text, View } from "@react-pdf/renderer";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { DetailsPageStyles } from "./detailsPageStyles";

//* Common reporting page for analyser reports - Criteria Selection Details & individual details if appropriate
interface DetailsPageProps {
    reportTitle: string,
    questionnaireName?: string,
    numberOfLearners: number | undefined,
    totalCost: number | undefined,
    costPerLearner: number | undefined,
    providerNames: string[] | undefined,
    courseNames: string[] | undefined,
    courseTypes: string[] | undefined,
    sectionNames: string[] | undefined,
    categoryNames: string[] | undefined,
    departmentNames: string[] | undefined,
    learners: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }> | undefined,
    isAllLearners: boolean,
    isCampaignReport: boolean,
    currencySymbol: string,
}

export const DetailsPage = (props: DetailsPageProps) => {
    const {
        reportTitle,
        questionnaireName = '',
        numberOfLearners = 0,
        totalCost,
        costPerLearner,
        providerNames = [],
        courseNames = [],
        courseTypes = [],
        sectionNames = [],
        categoryNames = [],
        departmentNames = [],
        learners = [],
        isAllLearners,
        isCampaignReport,
        currencySymbol,
    } = props;

    const isIndividualReport = numberOfLearners === 1;

    const formattedPrintDate = moment(new Date()).format("Do MMMM YYYY");

    // Learner Names for analyser report
    const learnerNames = isAllLearners ? ['All'] : learners.map(learner => `${learner.firstName} ${learner.lastName}`);

    const costPerLearnerDisplay = currencySymbol + (costPerLearner ?? 0).toLocaleString();
    const totalCostDisplay = currencySymbol + (totalCost ?? 0).toLocaleString();
    const providerNamesHeaderText = !!providerNames.length && (providerNames.length > 1 || providerNames[0] === 'All') ? "Providers" : "Provider";
    const courseNamesHeaderText = !!courseNames.length && (courseNames.length > 1 || courseNames[0] === 'All') ? "Courses" : "Course";
    const courseTypesHeaderText = !!courseTypes.length && (courseTypes.length > 1 || courseTypes[0] === 'All') ? "Course types" : "Course type";
    const sectionNamesHeaderText = !!sectionNames.length && (sectionNames.length > 1 || sectionNames[0] === 'All') ? "Sections" : "Section";
    const categoryNamesHeaderText = !!categoryNames.length && (categoryNames.length > 1 || categoryNames[0] === 'All') ? "Categories" : "Category";
    const departmentNamesHeaderText = !!departmentNames.length && (departmentNames.length > 1 || departmentNames[0] === 'All') ? "Departments" : "Department";
    const learnerNamesHeaderText = !!learners.length && (learners.length > 1 || isAllLearners) ? "Learners" : "Learner";

    return (
        <>
            <ConditionalFragment showIf={!isCampaignReport && !isIndividualReport && (!!numberOfLearners || !!totalCost || !!costPerLearner)}>
                <View style={DetailsPageStyles.detailsSection}>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Learners: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{numberOfLearners}</Text>
                    </View>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Cost: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{totalCostDisplay}</Text>
                    </View>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Cost per Learner: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{costPerLearnerDisplay}</Text>
                    </View>
                </View>
            </ConditionalFragment>
            <View style={DetailsPageStyles.detailsSection}>
                <View style={DetailsPageStyles.subHeadingContainer}>
                    <Text style={DetailsPageStyles.subHeadingBold}>{reportTitle}</Text>
                </View>
                <View style={DetailsPageStyles.subHeadingContainer}>
                    <Text style={DetailsPageStyles.subHeadingBold}>{formattedPrintDate}</Text>
                </View>

                {/* Individual report details */}
                <ConditionalFragment showIf={isIndividualReport}>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Name: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{`${learners[0].firstName} ${learners[0].lastName}`}</Text>
                    </View>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Email: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{learners[0].email ?? ''}</Text>
                    </View>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Line Manager's name: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{learners[0].lineManagerName ?? ''}</Text>
                    </View>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <Text style={DetailsPageStyles.subHeadingBold}>Line Manager's email: </Text>
                        <Text style={DetailsPageStyles.subHeading}>{learners[0].lineManagerEmail ?? ''}</Text>
                    </View>
                </ConditionalFragment>

                <ConditionalFragment showIf={isCampaignReport}>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <View style={DetailsPageStyles.criteriaListContainer}>
                            <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>Campaign:</Text>
                            <View style={DetailsPageStyles.criteriaList} wrap={true}>
                                <Text style={DetailsPageStyles.bullet}>•</Text>
                                <Text style={DetailsPageStyles.criteriaListText}>{questionnaireName}</Text>
                            </View>
                        </View>
                    </View>
                </ConditionalFragment>


                <ConditionalFragment showIf={!isCampaignReport}>
                    <ConditionalFragment showIf={!!providerNames.length && !isIndividualReport}>
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{providerNamesHeaderText}:</Text>
                                {providerNames.map((providerName, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText} key={index}>{providerName}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!courseNames.length && !isIndividualReport}>
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{courseNamesHeaderText}:</Text>
                                {courseNames.map((courseName, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText} key={index}>{courseName}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!courseTypes.length && !isIndividualReport}>
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{courseTypesHeaderText}:</Text>
                                {courseTypes.map((courseType, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText} key={index}>{courseType}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!sectionNames.length && !isIndividualReport} >
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{sectionNamesHeaderText}:</Text>
                                {sectionNames.map((sectionName, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText}>{sectionName}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!categoryNames.length && !isIndividualReport}>
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{categoryNamesHeaderText}:</Text>
                                {categoryNames.map((categoryName, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText}>{categoryName}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={!!departmentNames.length && !isIndividualReport}>
                        <View style={DetailsPageStyles.subHeadingContainer}>
                            <View style={DetailsPageStyles.criteriaListContainer}>
                                <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{departmentNamesHeaderText}:</Text>
                                {departmentNames.map((departmentName, index) => {
                                    return (
                                        <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                            <Text style={DetailsPageStyles.bullet}>•</Text>
                                            <Text style={DetailsPageStyles.criteriaListText} key={index}>{departmentName}</Text>
                                        </View>
                                    )
                                })
                                }
                            </View>
                        </View>
                    </ConditionalFragment>
                </ConditionalFragment>

                <ConditionalFragment showIf={!!learnerNames.length && !isIndividualReport}>
                    <View style={DetailsPageStyles.subHeadingContainer}>
                        <View style={DetailsPageStyles.criteriaListContainer}>
                            <Text style={[DetailsPageStyles.subHeadingBold, { marginBottom: "3mm" }]}>{learnerNamesHeaderText}:</Text>
                            {learnerNames.map((learnerName, index) => {
                                return (
                                    <View key={index} style={DetailsPageStyles.criteriaList} wrap={true}>
                                        <Text style={DetailsPageStyles.bullet}>•</Text>
                                        <Text style={DetailsPageStyles.criteriaListText} key={index}>{learnerName}</Text>
                                    </View>
                                )
                            })
                            }
                        </View>
                    </View>
                </ConditionalFragment>
            </View>
        </>
    );
};