import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { questionnaireFieldsFragment } from '../../models/Questionnaire';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { questionnaireSectionFieldsFragment } from '../../models/QuestionnaireSection';
import { questionFieldsFragment } from '../../models/Question';
import { learningUnitFieldsFragment } from '../../models/LearningUnit';
import { questionnaireRespondentSessionFieldsFragment } from '../../models/QuestionnaireRespondentSession';
import { questionResponseFieldsFragment } from '../../models/QuestionResponse';
import { useSubscriptionReportViewModelQuery, useSubscriptionReportViewModelQueryVariables } from '../../generated/useSubscriptionReportViewModelQuery';
import { profileFieldsFragment } from '../../models/Profile';

export interface SubscriptionReportViewModelAysncLoadOptions extends AsyncLoadOptions {
    excludeIncomplete?: boolean,
    subscriptionDepartmentId?: string,
}

/**
 * Get a Questionnaire from the store along with any response information.
 * @param options
 */
export function useSubscriptionReportViewModel(id: string | undefined | null, options: SubscriptionReportViewModelAysncLoadOptions = {}): AsyncLoadResult<useSubscriptionReportViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<useSubscriptionReportViewModelQuery, useSubscriptionReportViewModelQueryVariables>(
        gql`
        query useSubscriptionReportViewModelQuery ($id: ID!, $excludeIncomplete: Boolean, $subscriptionDepartmentId: ID) {
            model: questionnaire(id: $id) {
                ...questionnaireFields

                questionnaireSections {
                    ...questionnaireSectionFields
                }

                questions {
                    ...questionFields
                }
            }

            learningUnit: learningUnit (questionnaireId: $id) {
                ...learningUnitFields
            }

            questionnaireRespondentSessions (questionnaireId: $id, excludeIncomplete: $excludeIncomplete){
                ...questionnaireRespondentSessionFields

                responses: responses{
                    ...questionResponseFields
                }
            }

            profilesForQuestionnaires: profiles(hasSessionForQuestionnaireId: $id, subscriptionDepartmentId: $subscriptionDepartmentId) {
                ...profileFields
            }

            
        }

        ${questionnaireFieldsFragment}
        ${questionnaireSectionFieldsFragment}
        ${questionFieldsFragment}
        ${profileFieldsFragment}
        ${learningUnitFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${questionResponseFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty,
                excludeIncomplete: options.excludeIncomplete,
                subscriptionDepartmentId: options.subscriptionDepartmentId,
            },
            fetchPolicy: mainApiConfig.defaultFetchPolicy,
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}
