import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Guid } from "guid-string";
import React, { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Modal, ModalBody, ModalHeader, Row, Table } from "reactstrap";
import { useToggleStateArray } from "use-toggle-state";
import { TagLikeItem } from "../../../shared/tagSelector/TagSelector";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { MainContainer } from "../../shared/MainContainer";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./analyserCheckedListModal.scss";

interface AnalyserCheckedListModalProps {
    allSelected: boolean,
    setAllSelected: (allSelected: boolean) => void,
    isSelected: (id: string) => boolean,
    setSelected: (id: string) => void,
    title: string,
    tags: TagLikeItem[],
    groupTags?: TagLikeItem[],
    groupTagSelectClick?: (groupId: string, selecting: boolean) => void,
    setSelectedNames?: React.Dispatch<React.SetStateAction<string[]>>,
    getParentId?: (id: string) => string | undefined,
}

export const AnalyserCheckedListModal = (props: AnalyserCheckedListModalProps) => {
    const { allSelected, setAllSelected, isSelected, setSelected, title, tags, groupTags, groupTagSelectClick, setSelectedNames, getParentId } = props;
    const { t } = useTranslation();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const items = useMemo(() => {
        if (!tags) {
            return [];
        }
        if (!search) {
            return tags;
        }

        let lowerSearch = search.toLocaleLowerCase();

        return tags.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );

    }, [tags, search]);

    const selectedItems = useMemo(() => { return tags?.filter(tag => allSelected || isSelected(tag.id)) }, [isSelected, allSelected, tags]);

    const isGroupTag = useCallback((tagId: string) => { return groupTags?.find(g => g.id === tagId) }, [groupTags])

    // Handle change of allSelected checkbox
    const onAllSelectedChange = useCallback(() => {

        if (!tags.length) {
            // No tags so do nothing
            return;
        }

        const newSetting = !allSelected;

        // Use the current selection
        setAllSelected(newSetting);

        // Are we setting or unsetting all tags?
        if (!!tags && newSetting) {
            // Set all to selected
            tags?.filter(t => !isSelected(t.id)).forEach((tag) => {
                setSelected(tag.id);
            });

            if (!!setSelectedNames) {
                if (title === "Category") { //Exception for categories as we need to use the id, but still want this to work
                    setSelectedNames(tags.map(tag => tag.id));
                }
                else {
                    setSelectedNames(tags.map(tag => tag.name));
                }
            }
        }
        else {
            // Set all to not selected
            tags?.filter(t => isSelected(t.id)).forEach((tag) => {
                setSelected(tag.id);
            });

            if (!!setSelectedNames) {
                setSelectedNames([]);
            }
        }

    }, [setAllSelected, allSelected, isSelected, setSelected, tags, setSelectedNames, title]);


    const selectedText = selectedItems?.length > 0 ? `(${selectedItems?.length}/${tags.length})` : '';

    const ungroupedTags = useMemo(() => {
        if (!tags) {
            return [];
        }

        if (!groupTags || !groupTags.length || !getParentId) {
            return tags;
        }

        return tags.filter(tag => {
            const parentId = getParentId(tag.id);

            return (!parentId || !groupTags.find(g => g.id === parentId)) && !isGroupTag(tag.id);
        });

    }, [tags, groupTags, getParentId, isGroupTag]);

    const [allNoGroup, setAllNoGroup] = useState<boolean>(true);

    const onSelectItemChange = useCallback((id: string) => {
        const isNoGroup = id === Guid.empty;

        // Are we selecting the item or deselecting it?
        const isNowSelected = isNoGroup ? !allNoGroup : !isSelected(id);

        const currentlySelected = selectedItems.map(tag => tag.name);
        const currentlySelectedIds = selectedItems.map(tag => tag.id);
        const thisItemName = tags.find(tag => tag.id === id)?.name ?? '';
        if (!isNoGroup) {
            // If we are selecting the item, add it to the selected list
            if (isNowSelected && !!thisItemName) {
                if (!!setSelectedNames) {
                    if (title === "Category") {
                        setSelectedNames([...currentlySelectedIds, id]);
                    } else {
                        setSelectedNames([...currentlySelected, thisItemName]);
                    }
                }
            }
            else {
                // If we are deselecting the item, remove it from the selected list
                if (!!setSelectedNames) {
                    if (title === "Category") {
                        setSelectedNames(currentlySelectedIds.filter(it => it !== id));
                    }
                    else {
                        setSelectedNames(currentlySelected.filter(name => name !== thisItemName));
                    }
                }
            }
        } else {
            // Process the selection of No Group
            if (isNowSelected) {
                // If we are selecting the item, add all ungrouped items to the selected list
                for (let i = 0; i < ungroupedTags.length; i++) {
                    const ungroupedTag = ungroupedTags[i];
                    if (!isSelected(ungroupedTag.id)) {
                        setSelected(ungroupedTag.id);
                    }
                }
            } else {
                // If we are deselecting the item, remove all ungrouped items from the selected list
                for (let i = 0; i < ungroupedTags.length; i++) {
                    const ungroupedTag = ungroupedTags[i];
                    if (isSelected(ungroupedTag.id)) {
                        setSelected(ungroupedTag.id);
                    }
                }
            }
            setAllNoGroup(isNowSelected);
        }
        if (isGroupTag(id) && !!groupTagSelectClick) {
            groupTagSelectClick(id, isNowSelected);
        }

        if (!isNoGroup) {
            setSelected(id);
        }
        setAllSelected(false);

    }, [setAllSelected, isSelected, setSelected, tags, setSelectedNames, selectedItems, groupTagSelectClick, isGroupTag, title, allNoGroup, setAllNoGroup, ungroupedTags]);

    const getItemsInGroup = useCallback((groupId: string) => {
        if (!tags || !getParentId) {
            return [];
        }

        return tags.filter(tag => getParentId(tag.id) === groupId);

    }, [tags, getParentId]);

    // Manage the open state of the groups
    const [isGroupOpen, toggleGroupOpen] = useToggleStateArray<string>([]);

    return (
        <FormGroup className="analyser-checklist-modal">
            <Row>
                <Col xs={6}>
                    <h6>{title}</h6>
                    <Label className="selections-label">{selectedText}</Label>
                </Col>
                <Col xs={2} >
                    <img onClick={() => setIsMenuOpen(!isMenuOpen)} className="arrow-image" alt=''></img>
                </Col>
                <Col xs={3} >
                    <Label>{t('common.selectAll', 'All')}</Label>
                    &nbsp;
                    <input type="checkbox" name="allSelected" id="allSelected" checked={allSelected} onChange={(e) => onAllSelectedChange()} />
                </Col>
            </Row>
            <Modal className="report-name-modal analyserchecklist-container" size='md' isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)}>
                <ModalHeader>
                    <Row>
                        <Col>
                            <h3>
                                {t('analyserCheckedListModal.title', title)}
                            </h3>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <MainContainer fluid>
                        <Row className="mb-2">
                            <Col xs={9}>
                                <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                            </Col>
                            <Col xs={3} className="text-right pt-3">
                                <Label>{t('common.selectAll', 'All')}</Label>
                                &nbsp;
                                <input type="checkbox" name="allSelected" id="allSelected" checked={allSelected} onChange={(e) => onAllSelectedChange()} />
                            </Col>

                        </Row>

                        <div className={`cards-or-table cards-or-table-table`}>
                            <Table striped>
                                <thead>
                                    <tr>
                                        <th style={{width: "80%"} }>{t('analyserCheckedList.name', 'Name')}</th>
                                        <th></th>
                                        <th>{t('analyserCheckedList.check', 'Selected')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {/* If we have group tags display each one with its children collapsed */}
                                    {
                                        groupTags?.map((groupTag, groupTagIndex) => {
                                            const itemsInGroup = getItemsInGroup(groupTag.id);

                                            return (
                                                <ConditionalFragment key={groupTagIndex} showIf={!!itemsInGroup && !!itemsInGroup.length}>
                                                    <tr className="group-table-row">
                                                        <td>
                                                            {<b>{groupTag.name}</b>}
                                                        </td>
                                                        <td>
                                                            <div onClick={() => toggleGroupOpen(groupTag.id)}><FontAwesomeIcon icon={isGroupOpen(groupTag.id) ? 'caret-up' : 'caret-down'} /></div>
                                                        </td>
                                                        <td className={"text-right"}>
                                                            <>{t('common.selectAll', 'All')}</>
                                                            &nbsp;
                                                            <input type="checkbox" name={groupTag.id} id={groupTag.id} checked={isSelected(groupTag.id)} onChange={(e) => onSelectItemChange(groupTag.id)} />
                                                        </td>
                                                    </tr>
                                                    {
                                                        itemsInGroup.map((tag, tagIndex) => (
                                                            <ConditionalFragment key={tagIndex} showIf={isGroupOpen(groupTag.id)}>
                                                                <tr>
                                                                    <td>
                                                                        {tag.name}
                                                                    </td>
                                                                    <td></td>
                                                                    <td className={"text-right"}>
                                                                        <input type="checkbox" name={tag.id} id={tag.id} checked={isSelected(tag.id)} onChange={(e) => onSelectItemChange(tag.id)} />
                                                                    </td>
                                                                </tr>
                                                            </ConditionalFragment>
                                                        ))
                                                    }
                                                </ConditionalFragment>
                                            )
                                        })
                                    }
                                    {/* If we have any items not in groups display them - This will be all items if items are not grouped at all */}
                                    <ConditionalFragment showIf={!!groupTags && !!groupTags.length && !!ungroupedTags.length}>
                                        <tr className="group-table-row">
                                            <td style={{ fontStyle: "italic" }}>{t('analyserCheckedList.notGrouped', 'not grouped')}</td>
                                            <td>
                                                <div onClick={() => toggleGroupOpen(Guid.empty)}><FontAwesomeIcon icon={isGroupOpen(Guid.empty) ? 'caret-up' : 'caret-down'} /></div>
                                            </td>
                                            <td className={"text-right"}>
                                                <>{t('common.selectAll', 'All')}</>
                                                &nbsp;
                                                <input type="checkbox" name={Guid.empty} id={Guid.empty} checked={allNoGroup} onChange={(e) => onSelectItemChange(Guid.empty)} />
                                            </td>
                                        </tr>

                                    </ConditionalFragment>
                                    {
                                        ungroupedTags.map((tag, tagIndex) => (
                                            <ConditionalFragment key={tagIndex} showIf={isGroupOpen(Guid.empty) || !groupTags?.length}>
                                                <tr key={tagIndex}>
                                                    <td>
                                                        {tag.name}
                                                    </td>
                                                    <td></td>
                                                    <td className={"text-right"}>
                                                        <input type="checkbox" name={tag.id} id={tag.id} checked={isSelected(tag.id)} onChange={(e) => onSelectItemChange(tag.id)} />
                                                    </td>
                                                </tr>
                                            </ConditionalFragment>
                                        ))
                                    }
                                </tbody>
                            </Table>
                        </div>

                        <ConditionalFragment showIf={!items?.length}>
                            <NoResultsFound search={search} />
                        </ConditionalFragment>

                    </MainContainer>
                </ModalBody>
            </Modal>
        </FormGroup>
    )
};