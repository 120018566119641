import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader, ModalProps, Row } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./selectGraphTypeModal.scss";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";

export interface SelectGraphTypeModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    setGraphType: (graphType: string) => void,
}

export const SelectGraphTypeModal = (props: SelectGraphTypeModalProps) => {
    const {
        isOpen,
        toggle,
        setGraphType
    } = props;

    const { t } = useTranslation();

    const updateGraphType = useCallback((graphType: string) => {
        setGraphType(graphType);
        toggle();
    }, [setGraphType, toggle]);

    return (
        <Modal className="select-graphtype-modal report-name-modal" isOpen={isOpen} toggle={() => toggle()}>
            <ModalHeader toggle={() => toggle()}>
                {
                    t('selectGraphTypeModal.heading.please-choose-a-graph-type', 'Please choose a Graph type')
                }
            </ModalHeader>
            <ModalBody className ="graph-modal-body">
                <Row className="text-center">
                        <Button  color="primary" onClick={() => updateGraphType("Column Chart")}>
                            <Row>
                                <FontAwesomeIcon icon={faChartBar} />
                            </Row>
                            <> {t('selectGraphTypeModal.column-chart', 'Column Chart')}</>
                        </Button>
                        <Button color="primary" onClick={() => updateGraphType("Pie Chart")}>
                            <Row>
                                <FontAwesomeIcon icon="chart-pie" />
                            </Row>
                            <> {t('selectGraphTypeModal.pie-chart', 'Pie Chart')}</>
                        </Button>
                        <Button color="primary" onClick={() => updateGraphType("Bar Chart")}>
                            <Row>
                                 {/*div here is to add a bar next to the align left icon so we can get an icon that looks like a horizontal bar chart*/}
                                <div style={{borderLeft: '5px solid white', marginRight:'3px'}}></div>
                                <FontAwesomeIcon icon={faAlignLeft} />
                            </Row>
                            <> {t('selectGraphTypeModal.bar-chart', 'Bar Chart')}</>
                        </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
}