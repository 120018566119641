import { useCallback, useMemo, useState, } from "react";
import { Button, Row, Col, Form, NavItem, NavLink, } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { FormButtons } from '../shared/FormButtons';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { QuestionnaireType, questionnaireTypeDisplayName } from "../../api/main/models/codeOnly/QuestionnaireType";
import { SelectQuestionnaireModal } from "../subscriptionQuestionnaires/questionnaires/SelectQuestionnaireModal";
import { useToggleState } from "use-toggle-state";
import { useCloneQuestionnaireCallback } from "../../api/main/questionnaires/useCloneQuestionnaireCallback";
import { NavTiles } from "../shared/NavTiles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./addQuestionnaireBase.scss"
import { Guid } from "guid-string";
import { ConditionalFragment } from "react-conditionalfragment";

interface AddQuestionnaireBaseProps {
    isSubscription?: boolean,
}

/**
 * Base for Adding a questionnaire.
 */
export const AddQuestionnaireBase = (props: AddQuestionnaireBaseProps) => {
    const {
        isSubscription = true, // tells us if we are working within a subscription
    } = props;
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    const { t } = useTranslation();

    // if we find a department id in the params then we won't let the user change it on the questionnaire, we will default it
    const { departmentId, type } = useParams<{ departmentId: string | undefined, type: string | undefined }>();

    const history = useHistory();

    const isTemplate = useMemo(() => {
        return !!type && type === 'Template' ? true : false;
    }, [type,]);

    // duplicate a template or questionnaire to form a new one
    const [saveBaseQuestionnaire] = useCloneQuestionnaireCallback();

    // tells the modal what to display when selecting a template or questionnaire to clone from
    const [isCloningATemplate, setisCloningATemplate] = useState<boolean>(false);

    // allows us to set the correct active button for selections
    const [selectionType, setSelectionType] = useState<string>('');

    const [idToClone, setIdToClone] = useState<string>('');

    // when the modal closes set the selected id ready for the Next button
    const onQuestionnaireModalClosed = useCallback((event: { selectedId: string | undefined, }) => {
        setIdToClone(event.selectedId ?? '');
    }, [setIdToClone,]);

    // continue button will show if we have selected to use a template or clone a campaign
    // this process moves us to the create process with the correct new questinnaire.
    // This will also be triggered automatically without the continue button being shown 
    // if we choose to start from scratch with the default template id
    const handleContinue = useCallback(async (selectedId: string | undefined) => {
        if (!selectedId) {
            // there will always be a selectedid, either picked from the list or the default
            return
        }

        // use the selected id to duplicate the questionnaire, sections, and questions
        //if isSubscription = false dont set a subscriptionId for the questionnaire (used for top level questionnaires)
        let newQuestionnaire = await saveBaseQuestionnaire(selectedId ?? '', isTemplate, !!type && type === 'template' ? '' : (type ?? ''), !!currentUserSubscriptionId && !!isSubscription ? currentUserSubscriptionId : undefined, departmentId ?? undefined,);

        // throw the new questionnaire into create
        let replacementUrl = '';
        if (!isSubscription) {
            // we are in administration
            replacementUrl = `/administration/questionnaires/create/${newQuestionnaire?.id ?? ''}`;
        } else if (!!departmentId) {
            // we are in a department
            replacementUrl = `/departmentQuestionnaires/${departmentId}/create/${newQuestionnaire?.id ?? ''}`;
        } else {
            // must be at subscription level
            replacementUrl = `/subscriptionQuestionnaires/create/${newQuestionnaire?.id ?? ''}`;
        }
        history.replace(replacementUrl);

    }, [saveBaseQuestionnaire, isSubscription, currentUserSubscriptionId, departmentId, history, isTemplate, type]);

    const [questionnaireModalIsOpen, toggleQuestionnaireModal] = useToggleState();

    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerNoPills"}>
                    <Col className="text-center">
                        <h1>
                            {
                                !!type && type === QuestionnaireType.SelfService ? t('addQuestionnaireBase.Title1', `Create A ${questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)} Campaign`)
                                    :
                                    !!type && type === QuestionnaireType.Campaign ? t('addQuestionnaireBase.Title1', `Create A Campaign`)
                                        :
                                        t('addQuestionnaireBase.Title1', `Create A Template`)
                            }
                        </h1>
                    </Col>
                </Row>
            </Banner>

            <MainContainer className="add-questionnaire-main-container">
                <Form onSubmit={e => { e.preventDefault(); history.goBack(); }}>
                    <h2> {t('addQuestionnaireBase.start', 'Select an option to get started')}</h2>

                    <Row className="mb-2 mt-4">
                        <Col>
                            <NavTiles className="nav-tiles-large">
                                <NavItem key={'template'}>
                                    <NavLink className={selectionType === 'template' ? 'active' : ''} to="/" onClick={e => { setIdToClone(''); setSelectionType('template'); setisCloningATemplate(true); toggleQuestionnaireModal(true) }}>
                                        <div className="navitem-text">{t('addQuestionnaireBase.cloneTemplate', 'Select A Template')}</div>
                                        <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                                    </NavLink>
                                </NavItem>
                                <NavItem key={'questionnaire'}>
                                    <NavLink className={selectionType === 'questionnaire' ? 'active' : ''} to="/" onClick={e => { setIdToClone(''); setSelectionType('questionnaire'); setisCloningATemplate(false); toggleQuestionnaireModal(true) }}>
                                        <div className="navitem-text">{t('addQuestionnaireBase.duplicateCampaign', 'Duplicate An Existing Campaign')}</div>
                                        <FontAwesomeIcon icon="clone" className="nav-icon" />
                                    </NavLink>
                                </NavItem>
                                <NavItem key={'scratch'}>
                                    <NavLink className={selectionType === 'scratch' ? 'active' : ''} to="/"
                                        onClick={e => {
                                            setSelectionType('scratch');
                                            setIdToClone(Guid.empty.toString());
                                            handleContinue(Guid.empty.toString()); // automatically trigger the continue button
                                        }}>
                                        <div className="navitem-text">{t('addQuestionnaireBase.buildFromScratch', 'Build From Scratch')}</div>
                                        <FontAwesomeIcon icon="sort-amount-up" className="nav-icon" />
                                    </NavLink>
                                </NavItem>
                            </NavTiles>
                        </Col>
                    </Row>
                    <FormButtons>
                        <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </FormButtons>
                    <ConditionalFragment showIf={!!idToClone && idToClone !== Guid.empty.toString()}>
                        <Row>
                            <Col></Col>
                            <Col xs="auto">
                                <Button color="primary" onClick={e => {
                                    handleContinue(idToClone);
                                }}>
                                    {t("common.continue", "Continue")}
                                    <> </>
                                    <FontAwesomeIcon icon="caret-right" />
                                </Button>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </Form>

                <SelectQuestionnaireModal
                    isOpen={questionnaireModalIsOpen}
                    toggle={toggleQuestionnaireModal}
                    onClose={onQuestionnaireModalClosed}
                    isTemplate={isCloningATemplate}
                />

            </MainContainer>
        </Background>
    );
};