import { QuestionResponseType } from '../../../api/main/models/codeOnly/QuestionResponseType';
import { Question } from '../../../api/main/models/Question';

/**
 * Return the number of possible answers for a question.
 * Note: This may not be equal to the number of possible responses required in the database
 */
export function getNumberOfPossibleAnswers(question: Question): number {

    switch (question.responseType) {
        case QuestionResponseType.Scale:
            return question.scaleMax;
        case QuestionResponseType.ComparisonScale:
            return question.scaleMax;
        case QuestionResponseType.TextBoxes:
            return question.maxTextBoxes;
        case QuestionResponseType.PickOne:
            return getNumberOfMultipleChoiceAnswers(question);
        case QuestionResponseType.MultipleChoice:
            return getNumberOfMultipleChoiceAnswers(question);
        default:
            return 1;
    }
}

export function getNumberOfMultipleChoiceAnswers(question: Question): number {
    let numberOfResponses = 1;
    if (!!question.multipleChoiceResponse1) { numberOfResponses = 1; }
    if (!!question.multipleChoiceResponse2) { numberOfResponses = 2; }
    if (!!question.multipleChoiceResponse3) { numberOfResponses = 3; }
    if (!!question.multipleChoiceResponse4) { numberOfResponses = 4; }
    if (!!question.multipleChoiceResponse5) { numberOfResponses = 5; }
    if (!!question.multipleChoiceResponse6) { numberOfResponses = 6; }
    if (!!question.multipleChoiceResponse7) { numberOfResponses = 7; }
    if (!!question.multipleChoiceResponse8) { numberOfResponses = 8; }
    return numberOfResponses;
}