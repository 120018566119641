import './navTiles.scss';

import * as React from 'react';
import { NavProps, Nav } from 'reactstrap';

export interface NavTilesProps extends NavProps {
}

/**
 * Component that displays the passed in NavItems as tiles.
 * @param props
 */
export const NavTiles = (props: NavTilesProps) => {
    const { children, className } = props;


    return (
        <Nav className={`nav-tiles ${className ?? ''}`}>
            {children}
        </Nav>
        );
}