import { ReactNode } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import "./completionProgressBar.scss";

export interface CompletionProgressBarProps {
    value: number
    questionnaireType?: QuestionnaireType,
    title?: ReactNode,
    heading?: ReactNode,
}

/**
 * ProgressBar showing percentage completed.
 * @param props
 */
export const CompletionProgressBar = (props: CompletionProgressBarProps) => {
    const {
        value,
        questionnaireType = QuestionnaireType.Campaign,
        title,
        heading,
    } = props;

    const { t } = useTranslation();

    return (
        <div className={`completion-progress-bar completion-progress-bar-${questionnaireType.toLocaleLowerCase()}`}>
            <CircularProgressbarWithChildren value={value}>
                <ConditionalFragment showIf={!!heading}>
                <div className="completion-progress-bar-heading">
                    {title ?? heading ?? t('scoreProgressBar.heading', 'Progress')}
                    </div>
                </ConditionalFragment>
                <div className="completion-progress-bar-value">
                    {t('common.percent', '{{value}}%', { value })}
                </div>
            </CircularProgressbarWithChildren>
        </div>
        );
};