import './inPlaceMessage.scss';

export interface InPlaceMessageProps {
    messageText: string,
    isError?: boolean,
}

/**
 * Presents a message n place with consistent formatting.
 */
export const InPlaceMessage = (props: InPlaceMessageProps) => {
    const { messageText, isError = false } = props;

    return (
        <>
            <div className={`in-place-message ${!!isError ? 'text-danger' : ''}`}>
                {messageText}
            </div>
        </>
        );
};
