import { ConditionalFragment } from 'react-conditionalfragment';
import { Redirect } from 'react-router';
import { Col } from 'reactstrap';
import { QuestionnaireType } from '../../api/main/models/codeOnly/QuestionnaireType';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';
import { LoadingIndicator } from '../shared/LoadingIndicator';

/**
 * Default Manage department home page.
 */
export const DepartmentQuestionnairesHome = () => {
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();
    return (
        <>
            <ConditionalFragment showIf={!currentUserProfile}>
                <Col xs="auto">
                    <LoadingIndicator size="sm" />
                </Col>
            </ConditionalFragment>
            <ConditionalFragment showIf={!!currentUserProfile}>
                <Redirect to={`/departmentQuestionnaires/list/${currentUserProfile?.subscriptionDepartmentId}/${QuestionnaireType.Campaign}`} />
            </ConditionalFragment>

        </>
        );
};