import { useTranslation } from 'react-i18next';
import { MainContainer } from '../../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { Background } from '../../shared/background/Background';
import { useCurrentUserProfile } from '../../../api/main/profiles/useCurrentUserProfile';
import { useCompleteQuestionnaireViewModel } from '../../../api/main/questionnaires/viewModels/useCompleteQuestionnaireViewModel';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import "./completeQuestionnaire.scss";
import { ConditionalFragment } from 'react-conditionalfragment';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { QuestionPresenter } from '../../questions/questionPresenters/QuestionPresenter';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useChanges, useChangesArray } from '../../../shared/useChanges';
import { useSaveQuestionnaireRespondentSessionCallback } from '../../../api/main/questionnaireRespondentSessions/useSaveQuestionnaireRespondentSessionCallback';
import { QuestionResponse, questionResponseDefaultValues } from '../../../api/main/models/QuestionResponse';
import { useSaveQuestionResponseCallback } from '../../../api/main/questionResponses/useSaveQuestionResponseCallback';
import { useDeleteQuestionResponseCallback } from '../../../api/main/questionResponses/useDeleteQuestionResponseCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { useValidatorArrayCallback } from '../../../shared/validator-react-contrib/useValidatorArrayCallback';
import { useAsyncCallback } from 'react-use-async-callback';
import { QuestionType } from '../../../api/main/models/codeOnly/QuestionType';
import { Question } from '../../../api/main/models/Question';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormButtons } from '../../shared/FormButtons';
import { InPlaceMessage } from '../../shared/InPlaceMessage';
import { useQuestionnaireCompletionPercentage } from '../utilities/useQuestionnaireCompletionPercentage';
import { QuestionnaireRespondentSession } from '../../../api/main/models/QuestionnaireRespondentSession';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { QuestionResponseType } from '../../../api/main/models/codeOnly/QuestionResponseType';
import { Guid } from 'guid-string';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { useSubscription } from '../../../api/main/subscriptions/useSubscription';
import { getNumberOfPossibleAnswers } from '../../questions/utilities/getNumberOfPossibleAnswers';


interface ViewCompleteQuestionnaireProps {
    isViewMode?: boolean,
    isPreviewMode?: boolean,
}

/**
 * Preview A Questionnaire
 */
export const PreviewQuestionnaire = () => (<CompleteQuestionnaire isPreviewMode={true} isViewMode={true} />);

/**
 * View a Completed Questionnaire
 */
export const ViewCompletedQuestionnaire = () => (<CompleteQuestionnaire isViewMode={true} />);

/**
 * Complete a questionnaire.
 */
export const CompleteQuestionnaire = (props: ViewCompleteQuestionnaireProps) => {
    const { isViewMode = false, isPreviewMode = false } = props;
    const { t } = useTranslation();
    const { id, userId, activeTab, departmentId } = useParams<{ id: string | undefined, userId: string | undefined, activeTab: string | undefined, departmentId: string | undefined }>();
    const history = useHistory();
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    // get the questionnaire, sections, and questions, and the session for this user , any responses for this user,
    // there will always be a session waiting to start or carry on otherwise they couldn't be here
    // if there is a userId in the params then use that instead of the profile - this will be readonly view of another user's session
    const { data: {
        model: questionnaire, questionnaireRespondentSession: storeModelSession, learningUnit, profile: learnerProfile
    }, isLoading, errors: loadErrors } = useCompleteQuestionnaireViewModel(id, { userId: !!userId ? userId : (!!isPreviewMode ? Guid.empty : currentUserProfile?.userId) });

    // user name to display
    const userFullName = useMemo(() => {
        // in preview mode we don't show a name
        if (isPreviewMode) {
            return t('completeQuestionnaire.preview.userName', 'User\'s name here');
        }

        // in view mode but not preview mode we want to show the actual learner's name not the currently logged in user
        if (isViewMode && !isPreviewMode) {
            return (learnerProfile?.firstName ?? '') + (" ") + (!!learnerProfile?.firstName ? '' : ' ') + (learnerProfile?.lastName ?? '');
        }

        // otherwise show the current user if we have one
        if (!currentUserProfile) {
            return;
        }

        return (currentUserProfile.firstName ?? '') + (" ") + (!!currentUserProfile.firstName ? '' : ' ') + (currentUserProfile.lastName ?? '');
    }, [currentUserProfile, isPreviewMode, isViewMode, learnerProfile, t]);

    const { data: { model: subscription } } = useSubscription(questionnaire?.subscriptionId);
    const currencySymbol = subscription?.currencySymbol ?? '£';

    // these are used for questions with minTextBoxes set when we validate on Finish
    const [nextQuestionValidationMessages, setNextQuestionValidationMessages] = useState<{ questionId: string, messageText: string }[]>([]);

    // Get the ordered sections in Display Order
    const orderedSections = useMemo(() => {
        let sections = [...questionnaire?.questionnaireSections ?? []];

        if (sections.length) {
            return sections
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }

        return sections;
    }, [questionnaire?.questionnaireSections]);

    // Get the ordered questions in Section order then Display Order
    const orderedQuestions = useMemo(() => {
        let questions = [...questionnaire?.questions ?? []];

        if (!!orderedSections.length && questions.length) {
            return questions
                .sort((a, b) => {
                    // First sort on section
                    const aSectionArrayIndex = orderedSections.findIndex(item => item.id === a.questionnaireSectionId);
                    const bSectionArrayIndex = orderedSections.findIndex(item => item.id === b.questionnaireSectionId);
                    if (aSectionArrayIndex > bSectionArrayIndex) {
                        return 1;
                    } else if (bSectionArrayIndex > aSectionArrayIndex) {
                        return -1;
                    } else {
                        // Equal so check the displayOrder
                        if (a.displayOrder === b.displayOrder) {
                            return 0;
                        } else if (a.displayOrder > b.displayOrder) {
                            return 1;
                        } else {
                            return -1;
                        }
                    }
                });
        }

        return questions;
    }, [questionnaire?.questions, orderedSections]);

    const questions = questionnaire?.questions;

    // currentQuestionKey helps step through the questions rather than displaying them all together
    const [currentQuestionKey, setCurrentQuestionKey] = useState<string>('');

    // Thank you page is only shown at the end when we run out of sections with questions
    const [showThankYou, setShowThankYou] = useState<boolean>(false);

    // During completion we show the summary or the questions but not both
    const showSummary = useMemo(() => {
        // if we have a question to display don't display the summary
        if (!!currentQuestionKey || !!showThankYou) {
            return false;
        }
        return true;
    }, [currentQuestionKey, showThankYou]);

    // Only show questions if we have set the key
    const showQuestion = useMemo(() => {
        if (!currentQuestionKey) {
            // No current question to show
            return false;
        }

        return true;
    }, [currentQuestionKey]);

    // We will be saving the session details and the question responses all together as we go along

    // store changes for the session
    const { model, change, changes } = useChanges<QuestionnaireRespondentSession>(storeModelSession, !storeModelSession?.startDate ? ({ ...storeModelSession, startDate: moment().toISOString() }) : undefined);
    const [saveSession, { errors: saveErrorsSession }] = useSaveQuestionnaireRespondentSessionCallback();

    // responses - note - manager will be empty when previewing
    const responsesManager = useChangesArray<QuestionResponse, string>(storeModelSession?.responses, item => item.id);
    const [saveQuestionResponse] = useSaveQuestionResponseCallback();
    const [removeQuestionResponse] = useDeleteQuestionResponseCallback();

    const isMinimumNumberReached = useCallback((question: Question | undefined) => {
        let minimumNumberEntered = false;
        if (!question) {
            return minimumNumberEntered;
        }
        const numberOfResponsesEntered = [...responsesManager.model].filter(item => item.questionId === question.id && item.textInput.trim() !== '' && item.userId === currentUserProfile?.userId).length;
        // check for textbox questions that the minimum number have been completed
        if (!!question && question.minTextBoxes > 0) {
            if (numberOfResponsesEntered >= question.minTextBoxes) {
                minimumNumberEntered = true;
            }
        }

        return minimumNumberEntered;

    }, [responsesManager.model, currentUserProfile]);

    // validation
    const [validateResponse, responseValidationErrors] = useValidatorArrayCallback<QuestionResponse>((myModel, validation, fieldsToCheck) => {
        const rules = {
        };

        validation.checkRules(rules, fieldsToCheck);

    }, []);

    // call this once for each question to populate the responses array.
    // we want to create the right number of responses based on the the question type and question settings.
    // We also want to take into account pre-existing responses
    const populateArrayForQuestion = useCallback((question: Question) => {
        // Get all responses for this question.
        const myResponses = storeModelSession?.responses.filter(item => item.questionId === question.id && item.userId === currentUserProfile?.userId) ?? [];

        // Work out how many responses we want.
        const type = question.questionType;
        // default to 1

        let max = 1;

        if (type === QuestionType.Measure) {
            // for a measure we want 1 response or 2 if we have a before/after comparison scale
            max = question.responseType === QuestionResponseType.ComparisonScale ? 2 : 1;
        } else if (question.responseType === QuestionResponseType.MultipleChoice) {
            // for multiple choice we want a response for each possible answer
            max = getNumberOfPossibleAnswers(question);
        } else if (question.responseType === QuestionResponseType.PickOne) {
            // for Pick One we want 1 response
            max = 1;
        } else if (type === QuestionType.Commitment || type === QuestionType.Improvement || type === QuestionType.Intent) {
            // for other types we want to have enough for the maximum number of textboxes
            // this is done by questionType so we can add more types later if required
            max = question.maxTextBoxes;

            // adjust so that we show 1 more than the amount already entered or the minimum whichever is the most
            let currentNumber = myResponses.filter(item => item.isUserInput).length;
            if (currentNumber + 1 > question.minTextBoxes && currentNumber + 1 <= question.maxTextBoxes) {
                max = currentNumber + 1;
            } else if (question.minTextBoxes > currentNumber) {
                max = question.minTextBoxes;
            }
        }

        // Take the number of responses for this question up to the max we want.
        // Only if not in View Mode
        for (let i = myResponses.length; i < max; ++i) {
            // create a new entry with default values
            responsesManager.addFor({
                ...questionResponseDefaultValues(),
                questionId: question.id,
                userId: currentUserProfile?.userId,
                questionnaireId: question.questionnaireId,
                questionnaireRespondentSessionId: storeModelSession?.id ?? '',
                displayOrder: moment().unix() + i, // this is done here so that all the new ones have a different display order - only matters where a question has multiple answers
            })
        }
    }, [responsesManager, currentUserProfile, storeModelSession,]);


    // populate the responseArray with all potential responses for each question
    useEffect(() => {
        //make sure we only run this once
        if (!!responsesManager.model.length || !questions) {
            return;
        }

        for (let question of questions) {
            populateArrayForQuestion(question)
        }

    }, [responsesManager.model, questions, populateArrayForQuestion]);

    // Main model validation.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {

        const rules = {
            // session rules

            // Validate responses
            questionResponse: () => responsesManager.model.filter(it => !validateResponse(it)).length ? t('completeQuestionnaire.responsesInvalid', 'One or more of the responses are invalid') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

    }, [
        model,
        responsesManager, validateResponse,
    ]);

    const [finishError, setFinishError] = useState<string>('');

    // all saves of responses the session come back to here to save everything at once
    const [saveForm, { errors: saveFormErrors }] = useAsyncCallback(async (options?: { goBack?: boolean, navigateTo?: string, endSession?: boolean, }) => {
        if (isViewMode) {
            // Save happens when next button is selected - don't want a save in preview mode or view mode
            return false;
        }

        if (!validate()) {
            return false;
        }


        // Save the main model (questionnaireRespondentSession)
        //

        // If we have answered everything then we want to set the end date.
        let allChanges = changes;
        if (options?.endSession) {
            const newChanges = { endDate: moment().toISOString(), };
            change(newChanges);

            allChanges = { ...allChanges, ...newChanges };
        }

        // Save to the database.
        await saveSession(model.id, allChanges, false);

        // Save the responses (questionResponse)
        for (const item of responsesManager.added) { await saveQuestionResponse(item.id, responsesManager.changesFor(item.id), true); }
        for (const item of responsesManager.updated) { await saveQuestionResponse(item.id, responsesManager.changesFor(item.id), false); }
        for (const item of responsesManager.removed) { await removeQuestionResponse(item.id); }
        responsesManager.markAsSaved();

        // if goBack is false we are saving as we go along and we are not finished
        if (options?.navigateTo) {
            history.push(options.navigateTo);
        } else if (options?.goBack) {
            history.goBack();
        }
        return true;
    }, [
        validate, saveSession, model, changes, history,
        responsesManager, saveQuestionResponse, removeQuestionResponse,
    ]);

    const percentageComplete = useQuestionnaireCompletionPercentage(questions ?? [], model, responsesManager.model);

    // Next or Back selected in questions
    const setNextQuestion = useCallback((direction: string) => {
        if (!orderedQuestions[0]) {
            // No questions
            return;
        }

        // Reset the finish error in case there was one from user trying to finish early
        setFinishError('');

        // Find the index of the current question (-1 if we are just starting).
        let allQuestionsIndex = !!currentQuestionKey ? orderedQuestions.findIndex(item => item.id === currentQuestionKey) : -1;

        if (allQuestionsIndex > -1) {
            const thisQuestion = orderedQuestions[allQuestionsIndex];
            setNextQuestionValidationMessages([{ questionId: thisQuestion.id, messageText: '' }]);

            // Check first if we are moving next and this response is valid
            var numberOfResponses = responsesManager.model.filter(item => item.questionId === currentQuestionKey && item.isUserInput).length;
            if (direction === 'next' && !isViewMode) {
                // Add other validation here if necessary
                if (!isViewMode && !!thisQuestion && thisQuestion.minTextBoxes > 0 && !isMinimumNumberReached(thisQuestion)) {
                    // Text box check
                    // Not enough text boxes filled so show error and don't allow progression
                    const messageText = t('completeQuestionnaire.minimumResponsesNotEntered', `Please enter a minimum of ${thisQuestion?.minTextBoxes} responses.`);
                    setNextQuestionValidationMessages([{ questionId: thisQuestion.id, messageText: messageText }]);
                    return;
                } else if (thisQuestion.responseType === QuestionResponseType.ComparisonScale && numberOfResponses < 2) {
                    // Comparison Scale Check
                    // Both scales must have a response
                    const messageText = t('completeQuestionnaire.bothComparisonScalesNotEntered', `Please select from both scales.`);
                    setNextQuestionValidationMessages([{ questionId: thisQuestion.id, messageText: messageText }]);
                    return;
                } else if (numberOfResponses < 1) {
                    // for other types we must have at least one response input
                    const messageText = t('completeQuestionnaire.noAnswer', `Please provide a response.`);
                    setNextQuestionValidationMessages([{ questionId: thisQuestion.id, messageText: messageText }]);
                    return;
                }
            }

            // Do we have any more questions to move back to?
            if (direction === 'back' && allQuestionsIndex === 0) {
                // No more questions so go back to summary
                setCurrentQuestionKey('');
                return;
            }
        }

        // Move to the required question - we know there is one because we've checked for the last one and the first one above.
        allQuestionsIndex = direction === 'back' ? allQuestionsIndex - 1 : allQuestionsIndex + 1;
        setCurrentQuestionKey(orderedQuestions[allQuestionsIndex].id);

    }, [setCurrentQuestionKey, orderedQuestions, isMinimumNumberReached, t, currentQuestionKey, isViewMode, responsesManager.model, setNextQuestionValidationMessages,]);

    const handleFinishClick = useCallback(() => {
        if (percentageComplete < 100) {
            // less than 100% so check which questions with minTextBox set are not complete and set error messages for them
            let errorMessageTexts: { questionId: string, messageText: string }[] = [];
            if (!!questions) {
                for (let thisQuestion of [...questions].filter(item => item.minTextBoxes > 0)) {
                    if (!isMinimumNumberReached(thisQuestion)) {
                        const messageText = t('completeQuestionnaire.minimumResponsesNotEntered', `Please enter a minimum of ${thisQuestion?.minTextBoxes} responses.`);
                        errorMessageTexts.push({ questionId: thisQuestion.id, messageText: messageText });
                        setCurrentQuestionKey(thisQuestion.id);
                        setNextQuestionValidationMessages(errorMessageTexts);
                        return;
                    }
                }
            }
            setFinishError(t("completeQuestionnaireFinishError", "You have not yet completed all questions."));
        } else {
            saveForm({ goBack: false, endSession: true, });
            // We are 100% complete we don't need to check anything else 
            setCurrentQuestionKey('');
            setShowThankYou(true);
        }

    }, [questions, t, saveForm, isMinimumNumberReached, percentageComplete, setCurrentQuestionKey, setShowThankYou, setNextQuestionValidationMessages]);

    const handleCloseButton = useCallback(() => {
        // if we do not have an active tab go back to where we came from
        if (!activeTab) {
            history.goBack();
        }

        // for anything with an active tab we were editing at one level or another so go back to the edit (or create)

        if (!!window.location.pathname.includes('subscriptionQuestionnaires')) {
            if (!!window.location.pathname.includes('create')) {
                history.push(`/subscriptionQuestionnaires/create/${id}/${activeTab}`);
            } else {
                history.push(`/subscriptionQuestionnaires/edit/${id}/${activeTab}`);
            }
        }

        if (!!window.location.pathname.includes('manageDepartment')) {
            if (!!window.location.pathname.includes('create')) {
                history.push(`/departmentQuestionnaires/${departmentId}/create/${id}/${activeTab}`);
            } else {
                history.push(`/departmentQuestionnaires/edit/${id}/${activeTab}`);

            }
        }

        history.push(`/subscriptionQuestionnaires/edit/${id}/${activeTab}`);
    }, [departmentId, activeTab, history, id]);

    return (
        <Background>
            <MainContainer className="complete-questionnaire">
                <AlertOnErrors errors={[loadErrors, saveErrorsSession, saveFormErrors]} />

                <div className="common-header">
                    <Row>
                        <Col>
                            <h2>{userFullName}</h2>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <FormButtons noPadding>
                                <ConditionalFragment showIf={!isViewMode}>
                                    <Button color="primary" outline onClick={() => { saveForm({ endSession: false, goBack: true }); }}>
                                        <FontAwesomeIcon icon="save" />
                                        <> </>
                                        {t('completeQuestion.finish', 'Save For Later')}
                                    </Button>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!isViewMode}>
                                    <Button color="primary"
                                        onClick={() => handleCloseButton()}                                >
                                        <FontAwesomeIcon icon="arrow-alt-circle-left" />
                                        <> </>
                                        {t('completeQuestion.close', 'Close')}
                                    </Button>
                                </ConditionalFragment>
                            </FormButtons>
                        </Col>
                    </Row>
                </div>

                {/*Don't show summary if we are showing questions to input'*/}
                <ConditionalFragment showIf={!!showSummary}>
                    <div className="header-info-container">

                        <h2>{t("completeQuestionnaire.campaignInformation.title", "Campaign Information")}</h2>
                        <div>
                            <label>{t("completeQuestionnaire.name.label", "Campaign Title:")}</label>
                            {questionnaire?.name}
                        </div>
                        <ConditionalFragment showIf={!!questionnaire?.description}>
                            <div>
                                <label>{t("completeQuestionnaire.instructions.label", "Instructions:")}</label>
                                {questionnaire?.description}
                            </div>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit && !!learningUnit.name}>
                            <div>
                                <label htmlFor="learningUnitName">{t('completeQuestionnaire.learningUnitName', 'Learning')}</label>
                                <span>{learningUnit?.name}</span>
                            </div>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit && !!learningUnit.learningUnitType}>
                            <div>
                                <label htmlFor="learningUnitType">{t('completeQuestionnaire.learningUnitType', 'Type')}</label>
                                <span>{learningUnit?.learningUnitType}</span>
                            </div>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit && !!learningUnit.provider}>
                            <div>
                                <label>{t('companyQuestionnaireReport.learningUnit.provider', 'By')}</label>
                                <span>{" " + learningUnit?.provider}</span>
                            </div>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit?.startDate || !!learningUnit?.endDate}>
                            <div>
                                <Label htmlFor="startDate">{t('completeQuestionnaire.learningUnitStartDate', 'Start')}</Label>
                                <span>{moment(learningUnit?.startDate).local().format("L") ?? 'not set'}</span>
                                <Label htmlFor="endDate">{t('completeQuestionnaire.learningUnitEndDate', 'End')}</Label>
                                <span>{moment(learningUnit?.endDate).local().format("L") ?? 'not set'}</span>
                            </div>
                        </ConditionalFragment>

                        <div>
                            <ConditionalFragment showIf={!isViewMode}>
                                <Row>
                                    <Col>
                                        {!storeModelSession?.lineManagerName ?
                                            <FormGroup>
                                                <Label htmlFor="lineManagerName">{t('completeQuestionnaire.lineManagerName', 'Line Manager\'s Name')}</Label>
                                                <span>{model.lineManagerName}</span>

                                                <ValidatedInput name="lineManagerName" type="text" value={model.lineManagerName ?? ''}
                                                    onChange={e => change({ lineManagerName: e.currentTarget.value })}
                                                    onBlur={e => { validate('lineManagerName') }}
                                                    validationErrors={validationErrors['lineManagerName']}
                                                    disabled={isViewMode} />
                                            </FormGroup>
                                            :
                                            <>
                                                <label htmlFor="lineManagerName">{t('completeQuestionnaire.lineManagerName', 'Line Manager\'s Name')}</label>
                                                <span>{model.lineManagerName}</span>
                                            </>
                                        }
                                    </Col>
                                    <Col>
                                        {!storeModelSession?.lineManagerEmail ?
                                            <FormGroup>
                                                <Label htmlFor="lineManagerEmail">{t('completeQuestionnaire.lineManagerEmail', 'Line Manager\'s Email')}</Label>
                                                <span>{model.lineManagerEmail}</span>

                                                <ValidatedInput name="lineManagerEmail" type="text" value={model.lineManagerEmail ?? ''}
                                                    onChange={e => change({ lineManagerEmail: e.currentTarget.value })}
                                                    onBlur={e => { validate('lineManagerEmail') }}
                                                    validationErrors={validationErrors['lineManagerEmail']}
                                                    disabled={isViewMode} />
                                            </FormGroup>
                                            :
                                            <>
                                                <label htmlFor="lineManagerEmail">{t('completeQuestionnaire.lineManagerEmail', 'Line Manager\'s Email')}</label>
                                                <span>{model.lineManagerEmail}</span>
                                            </>
                                        }
                                    </Col>
                                </Row>
                            </ConditionalFragment>
                        </div>

                        <FormButtons>
                            <Button color="primary" onClick={() => { setNextQuestion('next'); }}>
                                {t('completeQuestionnaire.start', 'Start')}
                                <> </>
                                <FontAwesomeIcon icon="chevron-right" />
                            </Button>
                        </FormButtons>
                    </div>
                </ConditionalFragment>

                {/* display sections and questions */}
                <ConditionalFragment showIf={!!showQuestion}>
                    <div className="questionnaire-container">
                        {orderedSections.map((section) => {
                            const currentSectionKey = orderedQuestions.find(item => item.id === currentQuestionKey)?.questionnaireSectionId ?? '';
                            const sectionHideClass = section.id !== currentSectionKey ? 'd-none' : '';
                            const currentQuestionNumber = orderedQuestions.findIndex(item => item.id === currentQuestionKey) + 1;
                            return (
                                <div key={section.id} className={sectionHideClass}>
                                    {/*<div className="d-none d-md-inline-block section-header-initial">{section.name.substring(0, 1)}</div>*/}
                                    <div className="section-header">{t("completeQuestionnaire.section.label", "Section: ") + section.name}</div>
                                    <div className="section-description">{section.description}</div>
                                    <div>
                                        {orderedQuestions?.filter(item => item.questionnaireSectionId === section.id).map((question, questionIndex) => {
                                            const questionHideClass = question.id !== currentQuestionKey ? 'd-none' : '';
                                            const nextQuestionValidationMessage = nextQuestionValidationMessages.find(item => item.questionId === question.id && !!item.messageText);
                                            return (
                                                <div key={question.id} className={'question-container ' + questionHideClass}>
                                                    <div className="question-number">
                                                        <span className="strong">&nbsp;{currentQuestionNumber} </span>{t("completeQuestionnaire.questionNumber.of", " of ") + orderedQuestions.length}
                                                    </div>
                                                    <QuestionPresenter
                                                        question={question}
                                                        sessionId={storeModelSession?.id ?? undefined}
                                                        responsesManager={responsesManager}
                                                        validateResponse={validateResponse}
                                                        responseValidationErrors={responseValidationErrors}
                                                        profile={currentUserProfile ?? undefined}
                                                        userInputDisabled={isViewMode}
                                                        currencySymbol={currencySymbol}
                                                    />
                                                    <ConditionalFragment showIf={!!nextQuestionValidationMessage}>
                                                        <InPlaceMessage messageText={nextQuestionValidationMessage?.messageText ?? ''} isError={true} />
                                                    </ConditionalFragment>

                                                    <Row className="mt-2">
                                                        <Col>
                                                            <Button color="primary" onClick={() => { setNextQuestion('back') }}>
                                                                <FontAwesomeIcon icon="chevron-left" />
                                                                <> </>
                                                                {t('completeQuestionnaire.start', 'Back')}
                                                            </Button>
                                                        </Col>
                                                        <Col xs="auto">
                                                            <ConditionalFragment showIf={question.id !== orderedQuestions[orderedQuestions.length - 1].id}>
                                                                <Button color="primary" onClick={() => { saveForm({ goBack: false }); setNextQuestion('next') }}>
                                                                    {t('completeQuestionnaire.start', 'Next')}
                                                                    <> </>
                                                                    <FontAwesomeIcon icon="chevron-right" />
                                                                </Button>
                                                            </ConditionalFragment>
                                                            <ConditionalFragment showIf={!isViewMode && question.id === orderedQuestions[orderedQuestions.length - 1].id}>
                                                                <ConditionalFragment showIf={!!finishError}>
                                                                    <InPlaceMessage messageText={finishError} isError={true} />
                                                                </ConditionalFragment>
                                                                <Button color="primary" onClick={() => { handleFinishClick(); }}>
                                                                    <FontAwesomeIcon icon="save" />
                                                                    <> </>
                                                                    {t('completeQuestion.finish', 'Save & Finish')}
                                                                </Button>
                                                            </ConditionalFragment>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>
                            )
                        }
                        )}
                    </div>
                </ConditionalFragment>

                {/*Don't show Thank you page if we are showing summary or  questions to input'*/}
                <ConditionalFragment showIf={!!showThankYou}>
                    <div className="header-info-container text-center">

                        <h2>{t("completeQuestionnaire.thankyou.title", "Thank you for completing your IMPACTs report.")}</h2>
                        <span>{t("completeQuestionnaire.thankyou.text", "Your answers have been submitted.")}</span>

                        <div className="text-center mt-4">
                            <a className="btn btn-primary" href="/me">
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('completeQuestionnaire.goToWorkspace.btnText', 'Go To Workspace')}
                            </a>
                        </div>
                    </div>
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};