import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { profileFieldsFragment } from "../models/Profile";
import { ImportUsersInput } from '../generated/globalTypes';
import { importUsersMutationVariables, importUsersMutation, importUsersMutation_importUsers } from "../generated/importUsersMutation";

/*
* Get a call back for importing users and adding them to the questionnaires
*/

export function useImportQuestionnairesUsersCallback(): [(model: ImportUsersInput) => Promise<Array<importUsersMutation_importUsers>>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<importUsersMutation, importUsersMutationVariables>(
        gql`
            mutation importUsersMutation ($model: ImportUsersInput!) {
                importUsers(model: $model) {
                    id
                    rowNumber
                    successful
                    errors
                    profile {
                        ...profileFields
                    }
                }
            }

            ${profileFieldsFragment}
        `,
        {
        }
    );
    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ImportUsersInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.importUsers ?? [];
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}