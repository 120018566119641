import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { FormButtons } from "./FormButtons";
import "../questions/editQuestionModal/editQuestionModal.scss";


export interface DeleteAnyModalProps {
    isOpen: boolean,
    toggle: () => void,
    remove: () => void,
    itemName?: string,
    isDelete?: boolean, // just changes the wording in the display message to remove instead of delete
}

/**
 * Modal To confirm Deleting anything
 * */

export const DeleteAnyModal = (props: DeleteAnyModalProps) => {
    const {
        isOpen,
        toggle,
        remove,
        itemName,
        isDelete = true,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <Modal isOpen={isOpen} className="select-question-modal">
                <ModalHeader toggle={() => toggle()}>
                    {t('deleteAnyModal.Title', 'Confirm Delete')}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {t('deleteSectionModal.warningText', `This action will ${isDelete ? 'permanently delete' : 'remove'} ${itemName}. Are you sure?`)}
                        </Col>
                    </Row>
                    <FormButtons>
                        <ButtonGroup>
                            <Button color="primary" onClick={() => { remove(); toggle(); }}>
                                {t('deleteSectionModal.delete', 'Yes')}
                            </Button>
                            <Button color="primary" outline onClick={() => toggle()}>
                                {t('deleteSectionModal.no', 'No')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>
                </ModalBody>
            </Modal>
        </>
    );

};