import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Row } from "reactstrap";
import { useSubscriptionReportViewModel } from "../../api/main/questionnaires/viewModels/useSubscriptionReportViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import "./reportSplashPage.scss"

//* Title page for questionnaire reports
interface ReportSpashPageProps {
    profileFirstName?: string,
    profileLastName?: string,
    sectionId?: string,
    questionnaireId?: string,
    endDate?: string,
}

export const ReportSplashPage = (props: ReportSpashPageProps) => {
    const {
        profileFirstName,
        profileLastName,
        sectionId,
        questionnaireId,
        endDate,
    } = props;
    const { t } = useTranslation();

    //get the questionnaire and questionnaire Sections.
    const { data: {
        model: questionnaire,
    }, isLoading, errors: loadErrors } = useSubscriptionReportViewModel(questionnaireId);

    const section = questionnaire?.questionnaireSections.find(item => item.id === sectionId);

    return (
        <Background>
            <MainContainer fluid className="report-splash-container">
                <ConditionalFragment showIf={isLoading}>
                    <Col xs="auto">
                        <LoadingIndicator size="sm" />
                    </Col>
                </ConditionalFragment>
                <AlertOnErrors errors={[loadErrors]} />
                <div className="report-container">
                    <Row>
                        <Col className="report-image-container">
                        </Col>
                    </Row>
                </div>
                <Row className="splash-footer">
                    <Col xs="">
                        <ConditionalFragment showIf={!!profileFirstName && !!profileLastName}>
                            <h1 className="report-splash-user">{profileFirstName + " " + profileLastName}</h1>
                        </ConditionalFragment>
                        <h2 className="report-splash-title">{questionnaire?.name ?? ''}</h2>
                        <h4 className="report-splash-title">{!!sectionId ? t('reportSplashPage.title', `${section?.name} Report`) : t('reportSplashPage.title', '')}</h4>
                        <h4 className="mt-4 report-splash-title">{t('reportSplashPage.title', 'End Of Programme Review')}</h4>
                        <h4 className="report-splash-title">{t('reportSplashPage.title', `${endDate}`)}</h4>
                    </Col>
                    <Col xs="5" className="brand-logo">
                    </Col>
                </Row>

            </MainContainer>
        </Background>
    );
};