import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { QuestionnaireTypeTabs } from "../administrationDashboard/AdministrationDashboard";

export interface TypeSelectionPillsProps {
    activeQuestionnaireType: string,
    setActiveQuestionnaireType: (selection: QuestionnaireTypeTabs) => void,
}

/**
 * Pills to select questionnairetype for dashboards.
 */
export const TypeSelectionPills = (props: TypeSelectionPillsProps) => {
    const {
        activeQuestionnaireType,
        setActiveQuestionnaireType,
    } = props;

    const { t } = useTranslation();

    return (
        <PillsNavBar textColor="primary" className="mb-2">
            <NavItem>
                <NavLink active={activeQuestionnaireType === 'all'} onClick={() => setActiveQuestionnaireType('all')}>
                    {t('typeSelectionPills.all', 'All')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={activeQuestionnaireType === 'campaigns'} onClick={() => setActiveQuestionnaireType('campaigns')}>
                    {t('typeSelectionPills.campaigns', 'Campaigns')}
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink active={activeQuestionnaireType === 'selfServices'} onClick={() => setActiveQuestionnaireType('selfServices')}>
                    {t('typeSelectionPills.selfService', 'Self-Service')}
                </NavLink>
            </NavItem>
        </PillsNavBar>
        );
};