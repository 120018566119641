import * as React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Guid } from 'guid-string';
import { BlobReferenceUploadAdapterPlugin } from './BlobReferenceUploadAdapter';

export interface HtmlEditorProps {
    value?: string,
    onChange?: (value: string) => void,
    onBlur?: () => void,
}

/**
 * Html editor component that abstracts the editor actually being used from the code using it where its unimportant.
 * 
 * This version uses CKEditor 5 internally.
 * 
 * @param props
 */
export const HtmlEditor = (props: HtmlEditorProps) => {
    const { value, onChange, onBlur } = props;

    // Calculate a key that only changes when the value is changed external to the editor itself as we need to update the key
    // of the componentto force a re-render whenever that does happen as CKEditor doesn't cope with it otherwise which means
    // we would otherwise not update when we've loaded data from the database and would therefore keep showing as blank.
    const [key, setKey] = React.useState<string>(() => Guid.newGuid());
    const trackedValue = React.useRef<string>(value ?? '');
    React.useEffect(() => {
        if (value !== trackedValue.current) {
            // value has been changed external to the changes made by the user in the editor.
            setKey(Guid.newGuid());
        }
    }, [value, trackedValue, setKey]);

    return (
        <CKEditor
            key={key}
            editor={ClassicEditor}
            config={{ extraPlugins: [BlobReferenceUploadAdapterPlugin] }}
            data={value}
            onChange={(event: any, editor: any) => {
                const data = editor.getData();

                // Keep a track of changes that were performed by the editor itself (so we can check for external changes).
                trackedValue.current = data;

                if (onChange) {
                    onChange(data);
                }
            }}
            onBlur={(event: any, editor: any) => {
                if (onBlur) {
                    onBlur();
                }
            }}
        />
        );
};