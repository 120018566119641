import { RouteEntry } from "../../shared/routes";
import { AdministratorsUsersList } from './AdministratorsUserList';
import { EditUser, CreateUser, EditAdminUser, CreateAdminUser, } from "./EditUser";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteUser } from "./DeleteUser";
import { RemoveUser } from "./RemoveUser";


export const userRoutes: Array<RouteEntry> = [
    // administrator level user actions
    { path: '/administration/users/administrators', exact: true, component: AdministratorsUsersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/edit/:id', component: EditAdminUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/add', component: CreateAdminUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/users/administrators/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.Administration },

    // subscription administrator level user actions at subscription level
    { path: '/manage/users/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/users/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/users/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // subscription administrator level user actions at subscription department level
    { path: '/manage/subscriptionDepartments/users/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/users/:departmentId/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/users/remove/:id', component: RemoveUser, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/manage/subscriptionDepartments/users/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.Administration },

    // department administrator level user actions
    { path: '/manageDepartment/users/edit/:id', component: EditUser, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/manageDepartment/users/:departmentId/add', component: CreateUser, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/manageDepartment/users/delete/:id', component: DeleteUser, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },
    { path: '/manageDepartment/users/remove/:id', component: RemoveUser, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },

];
