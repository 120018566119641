import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { SubscriptionsDashboardTab } from "../tabs/SubscriptionsDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { SubscriptionDepartmentsDashboardTab } from "../tabs/SubscriptionDepartmentsDashboardTab";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { useSessionsForQuestionnaireType } from "../utilities/useSessionsForQuestionnaireType";
import { useCallback, useMemo } from "react";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { administrationDashboardViewModelQuery_questionnaireRespondentSessions } from "../../../api/main/generated/administrationDashboardViewModelQuery";
import { Question } from "../../../api/main/models/Question";
import { TypeSelectionPills } from "../typeSelectionPills/TypeSelectionPills";

export type AdministrationDashboardTabs = 'subscriptions' | 'departments' | 'subscriptionUsers';
export type QuestionnaireTypeTabs = 'all' | 'campaigns' | 'selfServices' ;
/**
 * Dashboard for a subscription.
 */
export const AdministrationDashboard = () => {
    const { t } = useTranslation();

    // get dashboard the data at admin level.
    // the questionnaires will also bring the questionnaireRespondentSessions and Responses with them
    const {
        data: {
            subscriptions,
            subscriptionDepartments,
            profiles,
            questionnaires,
            questionnaireRespondentSessions,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    // Track the active tab for pills.
    const [activeTab, setActiveTab] = useActiveDashboardTab<AdministrationDashboardTabs>('administrationDashboard', 'subscriptions');
    const [activeQuestionnaireType, setActiveQuestionnaireType] = useActiveDashboardTab<QuestionnaireTypeTabs>('administrationDashboard2', 'all');

    // get the selfService sesssions
    const selfServiceSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.SelfService);

    // get the campaign sesssions
    const campaignSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.Campaign);

    // sort out which sessions to include in graphs
    const graphSessions = useMemo(() => {
        if (activeQuestionnaireType === 'campaigns') {
            return campaignSessions;
        } else if (activeQuestionnaireType === 'selfServices') {
            return selfServiceSessions;
        } else {
            return campaignSessions.concat(selfServiceSessions);
        }
    }, [activeQuestionnaireType, campaignSessions, selfServiceSessions]);

    // get the questions 
    const questions = useMemo(() => {
        if (!questionnaires) {
            return [];
        }

        let allQuestions: Array<Question> = [];

        for (let questionnaire of questionnaires) {
            for (let question of questionnaire.questions) {
                allQuestions.push(question);
            }
        }
        return allQuestions;
    }, [questionnaires,]);
   
    // get all responses
    const questionnaireTypeResponses = useCallback((requiredSesssions: administrationDashboardViewModelQuery_questionnaireRespondentSessions[]) => {
        if (!requiredSesssions.length) {
            return [];
        }

        let ret: Array<QuestionResponse> = [];
        for (let session of requiredSesssions) {
            for (let response of session.responses) {
                ret.push(response);
            }
        }

        return ret;
    }, []);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs="d-none" sm={4} lg={3}>
                    </Col>
                    <Col xs={12} sm="">
                        <PillsNavBar textColor="primary" className="mb-2">
                            <NavItem>
                                <NavLink active={activeTab === 'subscriptions'} onClick={() => setActiveTab('subscriptions')}>
                                    <FontAwesomeIcon icon="building" />
                                    <> </>
                                    {t('administrationDashboard.tabs.subscriptions', 'Subscriptions')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'departments'} onClick={() => setActiveTab('departments')}>
                                    <FontAwesomeIcon icon="users" />
                                    <> </>
                                    {t('administrationDashboard.tabs.departments', 'Departments')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'subscriptionUsers'} onClick={() => setActiveTab('subscriptionUsers')}>
                                    <FontAwesomeIcon icon="user" />
                                    <> </>
                                    {t('administrationDashboard.tabs.subscriptionUsers', 'People')}
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <Col xs={12} sm={4} lg={3}>
                        <TypeSelectionPills
                            activeQuestionnaireType={activeQuestionnaireType}
                            setActiveQuestionnaireType={setActiveQuestionnaireType}
                        />
                    </Col>
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>
                            <DashboardBackButton />
                            {t('administrationDashboard.heading.default', 'Impacts Dashboard')}
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid className="main-container">
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={activeTab === 'subscriptions'}>
                    <SubscriptionsDashboardTab
                        subscriptions={subscriptions ?? []}
                        changeTab={tabName => setActiveTab(tabName)}
                        campaignSessions={campaignSessions}
                        graphSessions={graphSessions}
                        selfServiceSessions={selfServiceSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        questions={questions}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'departments'}>
                    <SubscriptionDepartmentsDashboardTab
                        subscriptionDepartments={subscriptionDepartments ?? []}
                        profiles={profiles ?? []}
                        campaignSessions={campaignSessions}
                        selfServiceSessions={selfServiceSessions}
                        graphSessions={graphSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        showSubscriptionName={true}
                        subscriptions={subscriptions ?? []}
                        questions={questions}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'subscriptionUsers'}>
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        campaignSessions={campaignSessions}
                        selfServiceSessions={selfServiceSessions}
                        graphSessions={graphSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        showSubscriptionName={true}
                        subscriptions={subscriptions ?? []}
                        subscriptionDepartments={subscriptionDepartments ?? []}
                        questions={questions}
                    />
                </ConditionalFragment>
            </Container>
        </Background>
    );
};
