/**
 * Constants for the available roles.
 */
export const IdentityRoles = {
    Administration: 'Administration',
    SubscriptionAdministration: 'SubscriptionAdministration',
    SubscriptionDepartmentAdministration: 'SubscriptionDepartmentAdministration',
    UserAdministration: 'UserAdministration',
};

///**
// * Constants (enum) for the available Roles.
// */
//export enum IdentityRoles {
//    Administration = 'Administration'
//}
