import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Col, Label, NavItem, NavLink, Row } from "reactstrap";
import { useQuestionnaireSummaryViewModel } from "../../api/main/questionnaires/viewModels/useQuestionnaireSummaryViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background"
import { Banner } from "../shared/Banner";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { NavTiles } from "../shared/NavTiles";
import { NextBackButtons } from "../shared/NextBackButtons";
import "./viewQuestionnaireSummary.scss";
/**
 * view a summary of a questionnaire.
 */
export const ViewQuestionnaireSummary = () => {

    const { t } = useTranslation();
    const history = useHistory();

    // QuestionnaireId From the Params.
    const { id } = useParams<{ id: string }>();

    // DepartmentId From Params.
    const { departmentId } = useParams<{ departmentId: string }>();

    // Get the Questionnaire and related information Using The ID passed in through params.
    const { data: {
        model: questionnaire, questionnaireRespondentSessions: sessions, learningUnit
    }, isLoading, errors: loadErrors } = useQuestionnaireSummaryViewModel(id);

    const sections = questionnaire?.questionnaireSections;
    const questions = questionnaire?.questions;

    const handleNextButton = useCallback(async (itemId: string | undefined) => {
        if (!!isLoading) {
            return;
        }

        if (!departmentId) {
            history.push(`/subscriptionQuestionnaires/${questionnaire?.questionnaireType}`);

        } else {
            history.push(`/departmentQuestionnaires/list/${departmentId}/${questionnaire?.questionnaireType}`);
        }



    }, [questionnaire, history, departmentId, isLoading]);


    const handlePreviewButton = useMemo(() => {
        if (!!isLoading) {
            return;
        }
        let ret = ''
        if (!departmentId) {
            ret = `/subscriptionQuestionnaires/questionnaires/preview/${id}`;

        } else {
            ret = `/departmentQuestionnaires/${departmentId}/preview/${id}`;
        }

        
        return ret;
    }, [isLoading, departmentId, id]);

    return (
        <Background>
            <Banner>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>{t('viewQuestionnaireSummary.header', 'Campaign Summary')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer className={"questionnaire-summary-main-container"}>
                <AlertOnErrors errors={[loadErrors,]} />
                <Row>
                    <Col className="summary-details">
                        <h3>{t('viewQuestionnaireSummary.details.heading', 'Here\'s a summary of your campaign:')}</h3>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.name', 'Name:')}</Label>
                            <span className="summary-data">{questionnaire?.name}</span>
                            <span className="summary-data-subtext text-muted">
                                {t('viewQuestionnaireSummary.sectionNumbers', `with ${sections?.length ?? 0} sections`)}
                                &nbsp;
                                {t('viewQuestionnaireSummary.questionNumbers', `and ${questions?.length ?? 0} questions`)}
                            </span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.participants', 'Participants:')}</Label>
                            <span className="summary-data">{sessions?.length ?? '0'}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.startDate', 'Start Date:')}</Label>
                            <span className="summary-data">{moment(questionnaire?.startDate).local().format("L")}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.endDate', 'End Date:')}</Label>
                            <span className="summary-data">{moment(questionnaire?.endDate).local().format("L")}</span>
                        </div>
                        <div className="summary-group">
                            <Label>{t('viewQuestionnaireSummary.details.learningUnit', 'Learning Unit:')}</Label>
                            <span className="summary-data">{learningUnit?.learningUnitType ? learningUnit?.learningUnitType + ': ' + learningUnit?.name : t('viewQuestionnaireSummary.noLearningUnit','No associated Learning Unit')}</span>
                        </div>
                    </Col>
                    <Col className="summary-preview">
                        <NavTiles className="nav-tiles-large">
                            <NavItem key={'template'}>
                                <NavLink to="/" href={handlePreviewButton}>
                                    <div className="navitem-text">{t('viewQuestionnaireSummary.preview', 'Preview Your Campaign')}</div>
                                    <FontAwesomeIcon icon="eye" className="nav-icon" />
                                </NavLink>
                            </NavItem>
                        </NavTiles>
                    </Col>
                </Row>
                <NextBackButtons
                    showBack={true}
                    showNext={true}
                    handleNextButton={handleNextButton}
                    isLastStep={true}
                    />
            </MainContainer>

        </Background>
    );
};