import { useServices } from "inject-typesafe-react";
import { useAsyncCallback } from "react-use-async-callback";
import { AppServices } from "../../../configure/configureServices";
import { AsyncActionStatus } from "../../../shared/abstractStore";

/*
 * Get a callback to resend a questionnaire Invite to a user to complete a questionnaire.
 */
export function useResendQuestionnaireInviteCallback(): [(userId: string, questionnaireId: string, questionnaireName: string) => Promise<void>, AsyncActionStatus] {
    var api = useServices((services: AppServices) => services.apiFetch());
    //callback to get the userId, QuestionnaireIdand Questionnaire Name. 
    const [callback, status] = useAsyncCallback(async (userId: string, questionnaireId: string, questionnaireName: string) => {
        await api.post(`/api/questionnaireInvite/resendQuestionnaireInvite`, { userId: userId, questionnaireId: questionnaireId, questionnaireName: questionnaireName});
    }, [api]);

    return [callback, status];
}