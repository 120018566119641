import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ChartData, ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";

/**
 * Data for a chart that shows before and after responses to a question.
 */
export function useBeforeAndAfterChartData(isPercentage: boolean, questions: Array<Question>, responses: Array<QuestionResponse>): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string, beforeOrAfter: 'before' | 'after') => {
        // Generate chart data for each group.
        const data = questions.map((question, index) => {
            // initialise
            let totalBeforeAmount = 0;
            let totalAfterAmount = 0;

            // if question is a comparison scale question and question.isPercentage
            // is the same as isPercentage that is passed through then calculate total amount
            // for before and after.
            if (question.responseType === QuestionResponseType.ComparisonScale && question.isPercentage === isPercentage) {

                // filter all the responses by question.
                const questionResponses = responses.filter(response => response.questionId === question.id);

                // get an array of all userIds included in the responses
                let includedUsers: (string)[] = [];
                for (let response of questionResponses) {
                    if (includedUsers.find(item => item === response.userId)) {
                        continue;
                    }

                    includedUsers.push(response.userId);
                }

                // loop round the users - for each get the before value and add it to the running total
                // and get the after value and add it to the running total
                for (let userId of includedUsers) {
                    const userResponses = questionResponses.filter(item => item.userId === userId);

                    // we have 2 responses now
                    // get the one with the highest display order and add the amount to the after
                    // add the other one to the before
                    let beforeResponse = userResponses[0];
                    let afterResponse = userResponses[1];
                    if (beforeResponse.displayOrder > afterResponse.displayOrder) {
                        const temp = beforeResponse;
                        beforeResponse = afterResponse;
                        afterResponse = temp;
                    }

                    totalBeforeAmount += beforeResponse.amountInput;
                    totalAfterAmount += afterResponse.amountInput;
                }

                // Both total Amounts need to be devided by how many users have given a response to get the average for both.
                totalBeforeAmount = (totalBeforeAmount / includedUsers.length);
                totalAfterAmount = (totalAfterAmount / includedUsers.length);
            }

            return {
                text: `Question ${(index + 1).toString()}`,
                text2: question.id,
                value: beforeOrAfter === 'before' ? Math.round(totalBeforeAmount * 2) / 2 : Math.round(totalAfterAmount * 2) / 2,
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [questions, responses, isPercentage]);

    const ret = useMemo(() => {

        let retSeries = [
            generateSeries(
                t('useBeforeAndAfterChartData.before.seriesName', 'Before'),
                'before'
            ),
            generateSeries(
                t('useBeforeAndAfterChartData.after.seriesName', 'After'),
                'after'
            ),
        ];

        // remove the ones we don't want
        retSeries[0].data = retSeries[0].data.filter((item, index) => questions[index].responseType === QuestionResponseType.ComparisonScale && questions[index].isPercentage === isPercentage);
        retSeries[1].data = retSeries[1].data.filter((item, index) => questions[index].responseType === QuestionResponseType.ComparisonScale && questions[index].isPercentage === isPercentage);

        return retSeries;

    }, [generateSeries, questions, isPercentage, t]);

    return ret;
}
