import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { TemplatesList, } from "./TemplatesList";
import { EditTemplate } from "./EditTemplate";
//import { DeleteQuestionnaire } from "./DeleteQuestionnaire";
import { CreateTemplate } from "./AddTemplate";
import { PreviewQuestionnaire } from "../me/questionnaires/CompleteQuestionnaire";

export const questionnaireRoutes: Array<RouteEntry> = [
    // administrator level questionnaire actions
    { path: '/administration/questionnaires/:type', exact: true, component: TemplatesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/Template/add/:type', component: CreateTemplate, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/create/:id', component: EditTemplate, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/edit/:id', component: EditTemplate, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/Template/edit/:id', component: EditTemplate, authorize: true, requireRole: IdentityRoles.Administration },
    //{ path: '/administration/questionnaires/delete/:id', component: DeleteQuestionnaire, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/questionnaires/:type/preview/:id', component: PreviewQuestionnaire, authorize: true, requireRole: IdentityRoles.Administration },
];
