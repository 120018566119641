/**
 * Convert an image URL into a data URL so the image data can be embedded (e.g. in a PDF).
 * @param url
 */
export function imageUrlToDataUrl(url: string): Promise<string>
{
    return new Promise<string>((resolve, reject) => {
        // Create a canvas and context.
        let canvas = document.createElement('canvas');
        let context = canvas.getContext('2d');

        // Create an Image that we will use to load the SVG image.
        let loader = new Image();

        // Once the image has loaded we want to draw to the canvas then return it's data URL to resolve the Promise.
        loader.onload = () => {
            try {
                if (!context) {
                    return;
                }

                // Match the size for drawing purposes.
                canvas.width = loader.width;
                canvas.height = loader.height;

                context.drawImage(loader, 0, 0, loader.width, loader.height);

                // Resolve the result once we have it.
                resolve(canvas.toDataURL());
            } catch {
                reject();
            }
        };

        // Set the loader's image to the image urlto trigger its loading.
        loader.src = url;
    });
}