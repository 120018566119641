import gql from "graphql-tag";
import { Guid } from "guid-string";
import { subscriptionTemplateLinkFields } from "../generated/subscriptionTemplateLinkFields";

export const subscriptionTemplateLinkFieldsFragment = gql`
    fragment subscriptionTemplateLinkFields on SubscriptionTemplateLink {
        id
        subscriptionId
        questionnaireId
        isHiddenBySubscriptionAdministrator
        archived
    }
`;


export type SubscriptionTemplateLink = Omit<subscriptionTemplateLinkFields, '__typename'>;

export const subscriptionTemplateLinkDefaultValues = (): Partial<SubscriptionTemplateLink> => ({
    id: Guid.newGuid(),
    subscriptionId: undefined,
    questionnaireId: undefined,
    isHiddenBySubscriptionAdministrator: false,
    archived: false,
});