import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

Font.register({
    family: 'Roboto', fonts: [
        { src: '/fonts/Roboto/TrueType/Roboto-Regular.ttf', format: 'truetype', },
        { fontStyle: 'italic', src: '/fonts/Roboto/TrueType/Roboto-Italic.ttf', format: 'truetype', },
        { fontWeight: '700', src: '/fonts/Roboto/TrueType/Roboto-Bold.ttf', format: 'truetype', },
        { fontStyle: 'italic', fontWeight: '700', src: '/fonts/Roboto/TrueType/Roboto-BoldItalic.ttf', format: 'truetype', },
    ]
});


export const AnalyserReportIndividualStyles = StyleSheet.create({
    body: {
        fontFamily: 'Roboto',
    },
    splashPageImage: {
        width: "190mm",
    },
    impactsImageMainPage: {
        marginTop: "15mm",
        width: "45mm",
    },
    mainHeaderBlack: {
        color: "black",
        fontSize: 20,
        fontWeight: "bold",
        marginTop: "10mm",
        marginBottom: "10mm",
    },
    blueBlock: {
        height: "60mm",
        marginBottom: "15mm",
        paddingTop: "5mm",
    },
    blueBlockTextContainer: {
        marginLeft: "10mm",
        marginRight: "10mm",
        fontWeight: 700,
    },
    blueBlockText: {
        color: "white",
        fontSize: 18,
    },
    blueBlockTextLarge: {
        paddingTop: "5mm",
        fontSize: 22,
    },
    subHeadingContainer: {
        fontSize: 14,
        paddingTop: "4mm",
        paddingBottom: "4mm",
        flexDirection: "row",
    },
    subHeadingBold: {
        fontSize: 14,
        fontWeight: "bold",
        lineHeight: 0.3,
    },
    subHeading: {
        fontSize: 14,
        fontWeight: "normal",
        lineHeight: 0.3,
    },
});

