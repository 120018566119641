import gql from "graphql-tag";
import { Guid } from "guid-string";
import { learningUnitFields } from "../generated/learningUnitFields";

export const learningUnitFieldsFragment = gql`
    fragment learningUnitFields on LearningUnit {
        id
        subscriptionId
        questionnaireId
        name
        learningUnitType
        cost
        costPerLearner
        provider
        isMTDSubscription
        startDate
        endDate
        archived
    }
`;


export type LearningUnit = Omit<learningUnitFields, '__typename'>;

export const learningUnitDefaultValues = (): Partial<LearningUnit> => ({
    id: Guid.newGuid(),
    subscriptionId: '',
    questionnaireId: null,
    name: '',
    learningUnitType: '',
    archived: false,
    cost: 0,
    costPerLearner: 0,
    provider: '',
    startDate: null,
    endDate: null,
    isMTDSubscription: false,
});