import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { QuestionResponseType } from "../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../api/main/models/Question";
import { QuestionResponse } from "../../api/main/models/QuestionResponse";
import { useSubscriptionReportViewModel } from "../../api/main/questionnaires/viewModels/useSubscriptionReportViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { FormButtons } from "../shared/FormButtons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { ReportSplashPage } from "./ReportSplashPage";
import "./companyQuestionnaireReport.scss";
import { generatePdfAsBlob } from "../../utilities/generatePdfAsBlob";
import { QuestionnaireReportPdf } from "./pdfReports/QuestionnaireReportPDF";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useSubscriptionStyleOverridesViewModel } from "../../api/main/subscriptions/viewModels/useSubscriptionStyleOverridesViewModel";
import { useAsyncCallback } from "react-use-async-callback";
import { ButtonAsync } from "reactstrap-buttonasync";
import { imageUrlToDataUrl } from "../../utilities/imageUrlToDataUrl";
import FileSaver from "file-saver";
import { ScrollTo } from "@scottbamford/react-scrollto";
import { useUploadBlobFromBlobObjectCallback } from "../../api/main/blobReferences/useUploadBlobFromBlobObjectCallback";
import { AutoRenderPdfProps } from "./bulkReportGenerator/BulkReportGenerationRequest";
import { useAutoGeneratePdf } from "./bulkReportGenerator/useAutoGeneratePdf";
import { useSubscriptionDepartment } from "../../api/main/subscriptionDepartments/useSubscriptionDepartment";
import { ReportSummary } from "./reportSummary/ReportSummary";
import { useGetReportCustomiserNames } from "./reportCustomiser/utilities/reportCustomiserUtilities";
import { useBeforeAndAfterChartData } from "./chartData/useBeforeAndAfterChartData";
import { useSingleScaleChartData } from "./chartData/useSingleScaleChartData";
import { pngDataUrlFromSvg } from "../../utilities/pngFromSvg";
import { getMultipleChoiceResponseText } from "../questions/utilities/getMultipleChoiceResponseText";
import { ReportQuestionDisplay } from "./ReportQuestionDisplay";

interface CompanyQuestionnaireReportProps extends AutoRenderPdfProps {
    propsQuestionnaireId?: string,
    propsSectionId?: string,
    propsDepartmentId?: string,
    propsReportCustomiser?: string,
}

//** view a subscription level report for a questionnaire for all users with completed sessions for this questionnaire

export const CompanyQuestionnaireReport = (props: CompanyQuestionnaireReportProps) => {

    const { propsQuestionnaireId, propsSectionId, propsDepartmentId, autoGeneratePdf, autoGeneratePdfZipFileName, onPdfReady, propsReportCustomiser, } = props;

    // get the parameters
    const { questionnaireId: paramQuestionnaireId, sectionId: sectionIdParam, departmentId: paramDepartmentId, reportCustomiser: paramReportCustomiser } = useParams<{ questionnaireId: string | undefined, sectionId: string | undefined, departmentId: string | undefined, reportCustomiser: string | undefined }>();

    const questionnaireId = !!propsQuestionnaireId ? propsQuestionnaireId : paramQuestionnaireId;
    const sectionId = !!propsSectionId ? propsSectionId : sectionIdParam;
    const departmentId = !!propsDepartmentId ? propsDepartmentId : paramDepartmentId;

    const { t } = useTranslation();
    const history = useHistory();

    const reportCustomiser = !!propsReportCustomiser ? propsReportCustomiser : paramReportCustomiser;

    const customiserNames = useGetReportCustomiserNames();

    // array to hold the reportcustomisers
    const reportCustomisers = useMemo(() => {
        // use props or params which sre in the reportCustomiser, or defaults

        let newCustomisers: { Name: string, Value: boolean }[] = [];

        // set the defaults
        customiserNames.forEach((customiserName) => {
            newCustomisers.push({ Name: customiserName, Value: true });
        });

        // overwrite with values if we have them from props or params
        if (!!reportCustomiser) {
            for (let i = 0; i < reportCustomiser.length; i++) {
                newCustomisers[i].Value = reportCustomiser.substring(i, i + 1) === '1' ? true : false;
            }
        }

        return newCustomisers;

    }, [customiserNames, reportCustomiser]);

    // return a reportcustomiser value and default to true if not found so we display the section by default
    const isCustomiserSet = useCallback((customiser: string) => {
        let thisCustomiser = reportCustomisers.find(item => item.Name === customiser);

        return !!thisCustomiser ? thisCustomiser.Value : true;

    }, [reportCustomisers]);

    // Get the Questionnaire, Sections, Questions, Respondent Sessions, and profiles for any user with a respondent session
    const { data: {
        model: questionnaire, questionnaireRespondentSessions, profilesForQuestionnaires, learningUnit
    }, isLoading, errors: loadErrors } = useSubscriptionReportViewModel(questionnaireId, { excludeIncomplete: true, subscriptionDepartmentId: departmentId });

    // get the ordered sections
    const orderedSections = useMemo(() => {
        let sections = [...questionnaire?.questionnaireSections ?? []];

        //if we have a sectionId in the params filter the sections for that ID.
        if (!!sectionId) {
            sections = [...questionnaire?.questionnaireSections.filter(item => item.id === sectionId) ?? []];
        }


        // sort
        if (sections.length) {
            return sections
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }

        return sections;
    }, [questionnaire?.questionnaireSections, sectionId]);

    // get the ordered questions for a section
    const orderedQuestions = useCallback((sectionId: string) => {
        let questions = questionnaire?.questions.filter(item => item.questionnaireSectionId === sectionId) ?? [];

        // sort
        if (questions.length) {
            return questions
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }

        return questions;
    }, [questionnaire?.questions]);

    // Get the ordered repsonses for a single question for all user sessions
    const orderedResponses = useCallback((questionId: string) => {
        // get the session we are interested in
        if (!questionnaireRespondentSessions) {
            return [];
        }

        const allResponses: QuestionResponse[] = [];

        questionnaireRespondentSessions.forEach(session => {
            // get the responses for this session and question
            const responses = session.responses.filter(item => item.questionId === questionId && item.isUserInput) ?? [];

            // push each response into allResponses
            responses.forEach(response => allResponses.push(response));
        });

        // sort
        if (allResponses.length) {
            return allResponses
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }
        return allResponses;
    }, [questionnaireRespondentSessions]);

    // Get the ordered user profiles for any user with a completed respondent session for this questionnaire.
    const orderedUsers = useCallback((questionId: string) => {
        let users = [...profilesForQuestionnaires ?? []];

        //if a departmentId is passed in limit the users on the report to only
        //be those who match the given departmentId.
        if (!!departmentId) {
            users = users.filter(item => item.subscriptionDepartmentId === departmentId);
        }

        // filter out users if they don't have a completed session.
        users = users.filter(user => {
            const responses = questionnaireRespondentSessions.filter(it => it.userId === user.userId);
            if (responses.length === 0) {
                return false;
            }
            return true;
        });

        users = users.filter(user => {
            const responses = questionnaireRespondentSessions.filter(it => it.userId === user.userId);
            const answeredResponses = responses.filter(it => it.responses.find(response => response.questionId === questionId && !!response.isUserInput));

            if (answeredResponses.length === 0) {
                return false;
            }
            return true;
        });

        // Sort into first name order.
        if (users.length) {
            return users
                .sort((a, b) => {
                    if (a.firstName === b.firstName) {
                        if (a.lastName === b.lastName) {
                            return 0;
                        } else if (a.lastName > b.lastName) {
                            return 1;
                        } else {
                            return -1;
                        }
                    } else if (a.firstName > b.firstName) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }
        return users;
    }, [profilesForQuestionnaires, questionnaireRespondentSessions, departmentId]);

    // work out the text to display before a response in a different color
    const displayPreText = useCallback((question: Question, response: QuestionResponse, index: number) => {
        let ret = '';

        // handle multiple text boxes
        if (question.maxTextBoxes > 1) {
            ret = (index + 1).toString();
        }

        // handle formatting for before and after comparison scale
        if (question.responseType === QuestionResponseType.ComparisonScale) {
            ret = (index === 0 ? t('ComparisonScaleDisplayResponse.before', 'Before') : t('ComparisonScaleDisplayResponse.after', 'After'));
        }
        return ret;
    }, [t,]);

    const currentSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { model: subscription } } = useSubscriptionStyleOverridesViewModel(!!currentSubscriptionId ? currentSubscriptionId : questionnaire?.subscriptionId);

    const currencySymbol = subscription?.currencySymbol;

    // present a formatted response depending on response type 
    const displayResponse = useCallback((question: Question, response: QuestionResponse, index: number) => {
        var ret = '';

        // have we actually had a response from the user?
        if (!response.isUserInput) {
            return ret;
        }

        // depending on the type of response - get the correct field for the display
        if (question.responseType === QuestionResponseType.TextBoxes) {
            // if there are more than 1 text box answers for the question then number them otherwise just show the answer
            ret = response.textInput;
        } else if (question.responseType === QuestionResponseType.YesNo) {
            ret = response.yesNoInput ? 'Yes' : 'No';
        } else if (question.responseType === QuestionResponseType.MultipleChoice || question.responseType === QuestionResponseType.PickOne) {
            // Amount input will contain the number of the response chosen starting at 1 so we need to subtract 1 to get the correct index
            ret = getMultipleChoiceResponseText(question, response.amountInput - 1) ?? '';
        } else {
            // default as all other response types are amount input
            ret = response.amountInput;
        }

        // handle formatting for currency and percentage
        if (question.isPercentage || question.responseType === QuestionResponseType.Percentage) {
            ret += '%';
        }
        if (question.responseType === QuestionResponseType.CurrencyAmount) {
            ret = currencySymbol + ret;
        }

        return ret;
    }, [currencySymbol]);

    const singleSection = questionnaire?.questionnaireSections.find(item => item.id === sectionId);


    // section to scroll to when a link is clicked - we use 'index' as a section id to go back to the index
    const [scrollToId, setScrollToId] = useState<string>('');
    const handleScrollRequest = useCallback(async (scrollDestination) => {
        setScrollToId(scrollDestination);

        // let the scroll happen then reset
        await new Promise(res => setTimeout(res, 2000));
        setScrollToId('');

    }, [setScrollToId]);

    /*All Code Below Is used for generating company Questionnaire Report as a PDF*/
    /* getting the current subscription has been moved up to use the currency sysmbol */

    // Prepare the chart Images Into the Format Needed For the PDF Print Out.
    const prepareChartImages = useCallback(async () => {
        // Get an SVG that has the className 'apexcharts-svg' (only the charts will have this className)
        let chartSvgs = document.getElementsByClassName('apexcharts-svg');

        let ret: Array<string> = [];
        // For each chart conver the Svgs into pngDataUrls and push them to an array.
        for (let i = 0; i < chartSvgs.length; i++) {
            let svg = chartSvgs[i];
            let pngDataUrl = await pngDataUrlFromSvg(svg);
            ret.push(pngDataUrl);
        }

        return ret;
    }, []);

    // Generate a PDF file as a blob.
    const generatePdf = useCallback(async (): Promise<Blob> => {
        // get the chart images 
        let chartImages = await prepareChartImages();

        // Convert the images we need to data URLs so they can be embedded in the PDF.
        const pdfSplashPageImageUrl = await imageUrlToDataUrl('/img/PDF-Report-Splash-Page-Image.jpg');
        const impactsLogoUrl = await imageUrlToDataUrl('/img/IMPACTS_LOGO_NEW2022.jpeg');


        // Generate the PDF.
        let blob = await generatePdfAsBlob(QuestionnaireReportPdf({
            chartImages: chartImages,
            companyColor: !!subscription?.brandColor ? subscription?.brandColor : "#1188FF",
            companyLogoUrl: !!subscription?.brandImageBlobReference?.url ? subscription?.brandImageBlobReference?.url : impactsLogoUrl,
            questionnaireTitle: (!!sectionId ? t('reportSplashPage.title', `${singleSection?.name} Report`) : t('questionnaireReport.title', `${questionnaire?.name}`)),
            sectionTitle: (!!sectionId ? t('reportSplashPage.title', `${singleSection?.name} Report`) : undefined),
            splashPageImage: pdfSplashPageImageUrl,
            questionnaireDiscription: questionnaire?.description,
            questionnaireSections: orderedSections,
            questionnaireEndDate: moment(questionnaire?.endDate).format('Do MMMM YYYY'),
            orderedQuestions: orderedQuestions,
            orderedUsers: orderedUsers,
            orderedResponses: orderedResponses,
            displayPreText: displayPreText,
            displayResponse: displayResponse,
            impactsLogoUrl: impactsLogoUrl,
            learningUnit: learningUnit,
            isIncludeSummary: isCustomiserSet('IncludeSummary'),
            isIncludeDescription: isCustomiserSet('IncludeDescription'),
            isIncludeGraphs: isCustomiserSet('IncludeGraphs'),
            isIncludeLearningUnit: isCustomiserSet('IncludeLearningUnit'),
        }));

        return blob;
    }, [prepareChartImages, subscription, orderedQuestions, sectionId, singleSection, t, questionnaire, learningUnit, orderedSections, orderedUsers, orderedResponses, displayPreText, displayResponse, isCustomiserSet,]);

    const { data: { model: department } } = useSubscriptionDepartment(departmentId ?? undefined);

    const generatedReportFileName = useMemo(() => {
        let ret = 'Company';
        if (!!department) {
            ret = department.name;
        } else if (!department) {
            ret = subscription?.name ?? 'Company';
        }

        ret += '-' + questionnaire?.name.substring(0, 25) ?? 'Questionnaire';

        ret += `-Report-${moment().format("YYYY-MM-DD")}.pdf`;

        // If we have a zip file name for, prefix the filename with that in the filename so we can find it in the blobs later to zip it.
        if (autoGeneratePdfZipFileName) {
            ret = `${autoGeneratePdfZipFileName}:${ret}`;
        }

        return ret;
    }, [questionnaire, department, subscription, autoGeneratePdfZipFileName]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    // Generate PDF and Download It
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        FileSaver.saveAs(blob, generatedReportFileName)
    }, [generatePdf, generatedReportFileName]);

    const [upload] = useUploadBlobFromBlobObjectCallback();

    // Generate PDF and save to blob ready for Zip file Download
    const [generateAndSavePdfForZip] = useAsyncCallback(async () => {
        let blob = await generatePdf();

        //Upload the blob to our blobs table
        const blobReference = await upload(generatedReportFileName, blob);

        //Send Blob refernce Id back to report builder for Zip download.
        return blobReference?.id;
    }, [generatePdf, generatedReportFileName, upload]);

    // If we are to auto generate a pdf (because we are being rendered in the background as part of report generation) do so as soon as all our
    // data is ready.
    useAutoGeneratePdf({
        readyToGenerate: !!autoGeneratePdf && !!subscription && (!departmentId || (!!departmentId && !!department)) && !!questionnaire,
        loadErrors: loadErrors,
        generatePdfBlobReference: generateAndSavePdfForZip,
        onPdfReady: onPdfReady,
    });

    const allQuestions: Array<Question> = useMemo(() => {
        if (!questionnaire) {
            return [];
        }

        return questionnaire?.questions ?? [];
    }, [questionnaire]);

    const allResponses: Array<QuestionResponse> = useMemo(() => {
        let ret: Array<QuestionResponse> = [];

        if (!questionnaireRespondentSessions) {
            return ret;
        }

        questionnaireRespondentSessions.forEach(session => {
            session.responses.forEach(response => ret.push(response));
        });

        return ret;

    }, [questionnaireRespondentSessions]);

    return (
        <Background>
            <div className="report-splashpage">
                {/*Title page for the report.*/}
                <ReportSplashPage
                    sectionId={sectionId}
                    questionnaireId={questionnaireId}
                    endDate={moment(questionnaire?.endDate).format('Do MMMM YYYY')}
                />
            </div>
            <MainContainer fluid className="subscription-report-container">
                <ConditionalFragment showIf={isLoading}>
                    <Row>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </Row>
                </ConditionalFragment>

                <AlertOnErrors errors={[loadErrors]} />
                <Row className="report-page-header">
                    <Col>
                        <img src="../../../img/IMPACTS_LOGO_NEW2022.png" alt="" />
                    </Col>
                    <Col>
                        <FormButtons>
                            <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen} isExecuting={isGeneratingPdfForOpen}
                                executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('companyQuestionnaireReport.print', 'Generating PDF...')}</>}>
                                <FontAwesomeIcon icon="print" />
                                <> </>
                                {t('companyQuestionnaireReport.print', 'Print')}
                            </ButtonAsync>
                            <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} disabled={!!isGeneratingPdfForDownload} isExecuting={isGeneratingPdfForDownload}
                                executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('companyQuestionnaireReport.download', 'Generating PDF...')}</>}>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('companyQuestionnaireReport.download', 'Download')}
                            </ButtonAsync>
                            <> </>
                            <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                {t('common.close', 'Close')}
                            </Button>
                        </FormButtons>
                    </Col>
                </Row>
                <h1 className="report-title"> {!!sectionId ? t('questionnaireReport.title', `${singleSection?.name}`) : t('questionnaireReport.title', `${questionnaire?.name}`)}</h1>
                <ConditionalFragment showIf={isCustomiserSet('IncludeSummary')}>
                    <ReportSummary
                        learningUnit={learningUnit ?? undefined}
                        questionnaire={questionnaire ?? undefined}
                        isIncludeGraphs={isCustomiserSet('IncludeGraphs')}
                        isIncludeIndex={isCustomiserSet('IncludeIndex')}
                        isIncludeLearningUnit={isCustomiserSet('IncludeLearningUnit')}
                        isIncludeDescription={isCustomiserSet('IncludeDescription')}
                        orderedSections={orderedSections}
                        scrollToSectionId={scrollToId}
                        handleScrollRequest={handleScrollRequest}
                        beforeAndAfterNumericChartData={useBeforeAndAfterChartData(false, allQuestions, allResponses)}
                        singleScaleNumericChartData={useSingleScaleChartData(false, allQuestions, allResponses)}
                        beforeAndAfterPercentageChartData={useBeforeAndAfterChartData(true, allQuestions, allResponses)}
                        singleScalePercentageChartData={useSingleScaleChartData(true, allQuestions, allResponses)}

                    />
                </ConditionalFragment>
                <div>
                    <Row>
                        {/* display sections */}
                        <Col xs={12}>
                            {orderedSections.map((section) => {
                                return (
                                    <div className="section-container" key={section.id}>
                                        <ScrollTo shouldScrollTo={scrollToId === section.id} position="start" scrollMargin="70px">
                                            <ConditionalFragment showIf={orderedSections.length > 1 && isCustomiserSet('IncludeIndex')}>
                                                <div className="text-right">
                                                    <Button type="button" color="primary" outline onClick={e => handleScrollRequest('index')}>
                                                        <FontAwesomeIcon icon="caret-up" />
                                                        <> </>
                                                        {t('companyQuestionnaireReport.index', 'Section Index')}
                                                    </Button>
                                                </div>
                                            </ConditionalFragment>
                                        </ScrollTo>
                                        <div className="section-header"><span className="section-initial">{section.name.substring(0, 1)}</span>&nbsp; &nbsp; {section.name}</div>
                                        <ConditionalFragment showIf={!!section.description}>
                                            <div className="section-description">{section.description}</div>
                                        </ConditionalFragment>
                                        <div>
                                            {/* display questions */}
                                            {orderedQuestions(section.id).map(question => {
                                                return (
                                                    <ReportQuestionDisplay
                                                        question={question}
                                                        orderedResponses={orderedResponses(question.id)}
                                                        displayResponse={displayResponse}
                                                        displayPreText={displayPreText}
                                                        orderedUsers={orderedUsers(question.id)}
                                                        scrollToId={scrollToId}
                                                    />
                                                )
                                            }
                                            )}
                                        </div>

                                    </div>
                                )
                            }
                            )}
                        </Col>
                    </Row>
                </div>
                <FormButtons>
                    <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen} isExecuting={isGeneratingPdfForOpen}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('companyQuestionnaireReport.print', 'Generating PDF...')}</>}>
                        <FontAwesomeIcon icon="print" />
                        <> </>
                        {t('companyQuestionnaireReport.download', 'Print')}
                    </ButtonAsync>
                    <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} disabled={!!isGeneratingPdfForDownload} isExecuting={isGeneratingPdfForDownload}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('companyQuestionnaireReport.print', 'Generating PDF...')}</>}>
                        <FontAwesomeIcon icon="download" />
                        <> </>
                        {t('companyQuestionnaireReport.download', 'Download')}
                    </ButtonAsync>
                    <> </>
                    <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                        {t('common.close', 'Close')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </Background>
    );
};