import { QuestionResponseType } from '../../../api/main/models/codeOnly/QuestionResponseType';
import { Question } from '../../../api/main/models/Question';
import { QuestionResponse, questionResponseDefaultValues } from '../../../api/main/models/QuestionResponse';
import { getNumberOfPossibleAnswers } from './getNumberOfPossibleAnswers';


/**
 * Return an array of responses for a question. These are dummy responses used for previewing a question when no questionnaireRespondentSession is available.
 */
export function getDummyResponses(question: Question): QuestionResponse[] {

    const dummyResponses: QuestionResponse[] = [];
    // set up the dummys for the preview
    if (!question) {
        return dummyResponses;
    }

    // For dummy responses 1 response is ok for most response types
    let dummyNumber = 1;
    if (question.responseType === QuestionResponseType.ComparisonScale) {
        dummyNumber = 2;
    } else if (question.minTextBoxes > 1) {
        // text boxes so use minimum
        dummyNumber = question.minTextBoxes;
    } else if (question.responseType === QuestionResponseType.MultipleChoice) {
        dummyNumber = getNumberOfPossibleAnswers(question);
    }

    // reset the array to no more than we need
    for (let i = dummyResponses.length; i > dummyNumber; i--) {
        dummyResponses.pop();
    }

    for (let i = dummyResponses.length + 1; i <= dummyNumber; i++) {
        let newItem = {
            ...questionResponseDefaultValues(),
            questionId: question.id,
            userId: '',
            questionnaireId: question.questionnaireId,
            questionnaireRespondentSessionId: '',
            displayOrder: 0,
        };
        dummyResponses.push(newItem as QuestionResponse);
    }

    return dummyResponses;
}