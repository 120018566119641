import { QuestionnairesListBase } from "./QuestionnairesListBase";

/**
 * List of templates for administrators at the top level
 */
export const TemplatesList = () => {

    return (
        <QuestionnairesListBase
            isSubscription={false}
        />
    );
};