import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { questionFieldsFragment } from "../models/Question";
import { ImportQuestionnaireQuestionsInput } from "../generated/globalTypes";
import { importQuestionnaireQuestionsMutation, importQuestionnaireQuestionsMutationVariables, importQuestionnaireQuestionsMutation_importQuestionnaireQuestions } from '../generated/importQuestionnaireQuestionsMutation';

/**
 * Get a callback for importing questionnaire questions
 * */

export function useImportQuestionnaireQuestionsCallback(): [(model: ImportQuestionnaireQuestionsInput) => Promise<Array<importQuestionnaireQuestionsMutation_importQuestionnaireQuestions>>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<importQuestionnaireQuestionsMutation, importQuestionnaireQuestionsMutationVariables>(
        gql`
            mutation importQuestionnaireQuestionsMutation ($model: ImportQuestionnaireQuestionsInput!) {
                importQuestionnaireQuestions(model: $model) {
                    rowNumber
                    successful
                    errors
                    question {
                        ...questionFields        
                    }
                }
            }

            ${questionFieldsFragment}
        `,

    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ImportQuestionnaireQuestionsInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }

        return result.data?.importQuestionnaireQuestions ?? [];
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];

}