import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Row, Table } from "reactstrap";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./selectQuestionnaireModal.scss";
//import { QuestionTagDisplay } from "../../questionTags/questionTagSelector/QuestionTagDisplay";
import { useQuestionnaires } from "../../../api/main/questionnaires/useQuestionnaires";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { useMeDashboardViewModel } from "../../../api/main/meDashboardViewModels/useMeDashboardViewModel";
import { useCurrentUserProfile } from "../../../api/main/profiles/useCurrentUserProfile";

export interface SelectSelfServiceModalCloseEventArgs {
    /**
     * Selected questionnaire id.
     * */
    selectedId: string | undefined,

}

export interface SelectSelfServiceModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    isLoadingQuestionnaires?: boolean,

    onClose: (event: { selectedId: string | undefined }) => void,
}

/**
 * Modal that allows the user to select from a list of templates and previous questionnaires.
 * @param props
 */
export const SelectSelfServiceModal = (props: SelectSelfServiceModalProps) => {
    const {
        isOpen,
        toggle,

        isLoadingQuestionnaires,
        onClose,

    } = props;

    const { t } = useTranslation();
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    // use the userId from the profile if possible to get the data so that we get sessions and responses too at the same time
    const { data: { questionnaireRespondentSessions } } = useMeDashboardViewModel(currentUserProfile?.userId, !!currentUserProfile ? { isUserId: true } : { isUserId: false });

    // gets all questionnaires and templates for the subscription and all templates with no subscriptionId
    const { data: { items: allItems } } = useQuestionnaires({ pageSize: undefined, subscriptionId: currentUserSubscriptionId, includeAll: true, includeDefault: true });

    // Close the modal and return the id of the selected template or questionnaire.
    const closeModal = useCallback((event?: { selectedId: string }) => {
        if (onClose) {
            // can be '' if closed without a selection
            const selectedId = event?.selectedId;

            const externalEvent: SelectSelfServiceModalCloseEventArgs = {
                ...(event ?? {}),

                selectedId: selectedId,

            };
            onClose(externalEvent);
        }

        // Close the modal.
        toggle();
    }, [toggle, onClose,]);

    // Filtering of self Serivce questionnaires.
    const [search, setSearch] = useState<string>('');

    // Filter by the self service questionnaire's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;
        // Filter by the user's search text.
        if (search) {
            let lowerSearch = search.toLocaleLowerCase();


            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );

        }

        return ret;

    }, [allItems, search,]);

    // get all  self service questionaires
    const allQuestionnaires = useMemo(() => {
        if (!items) {
            return [];
        }
        let ret = items.filter(item => item.questionnaireType === QuestionnaireType.SelfService && !item.isTemplate).sort((a, b) => a.name > b.name ? 1 : -1);;
        return ret;
    }, [items]);

    // generate a table with the correct sub-heading and questionnaire items
    const generateTable = useCallback((inputQuestionnaires: Questionnaire[], type: string) => {
        return (
            <>
                <h2>{type}</h2>
                <Table className="select-questionnaire-modal-table" responsive striped>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="d-table-cell d-lg-none">{t('selectSelfServiceModal.question', 'Self-Service Questionnaires')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectSelfServiceModal.name', 'Name')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inputQuestionnaires?.map(item => {
                                return (
                                    <tr key={item.id}>
                                        <td style={{ width: '1px' /* Sizes to fit contents */ }}>
                                            <ButtonGroup>
                                                <Button color="secondary" outline disabled={!!questionnaireRespondentSessions?.find(it => it.questionnaireId === item.id && !it.endDate)} onClick={e => closeModal({ selectedId: item.id })}>
                                                    {
                                                        t('selectSelfServiceModal.select', 'Select')
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                        <td className="d-table-cell d-lg-none">
                                            <div>{item.name}</div>
                                        </td>
                                        <td className="d-none d-lg-table-cell">{item.name}</td>
                                    </tr>
                                );
                            })
                        }
                        <ConditionalFragment showIf={!!isLoadingQuestionnaires && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoadingQuestionnaires && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </>
        )
    }, [closeModal, isLoadingQuestionnaires, items, search, t, questionnaireRespondentSessions]);

    // UI
    //
    const colCount = 2;

    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="select-questionnaire-modal">
            <ModalHeader toggle={() => toggle()}>
                {
                    t('selectSelfServiceModal.heading.default', 'Select a Self-Sevice Questionnaire')
                }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>
                {/* Show a List of all Self-Serivce Questionnaires in the users subscription*/}
                {generateTable(allQuestionnaires, t('selectSelfServiceModal.headerQuestionnaires', 'Self-Service Questionnaires'))}
            </ModalBody>
            <ModalFooter>
                <Button color="primary"  onClick={() => closeModal({ selectedId: '' })}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};