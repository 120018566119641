import { useCallback, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { ModelArrayChanges } from "../../../../shared/useChanges";
import { useDisplayOrder } from "../../../shared/useDisplayOrder/useDisplayOrder";
import { QuestionnaireSection, questionnaireSectionDefaultValues } from "../../../../api/main/models/QuestionnaireSection";
import { Questionnaire } from "../../../../api/main/models/Questionnaire";
import { Question } from "../../../../api/main/models/Question";
import { SectionComponent } from "./SectionComponent";
import { ConditionalFragment } from "react-conditionalfragment";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { ValidateModelCallback, ValidationErrorsForModel } from "../../../../shared/validator-react-contrib/useValidatorArrayCallback";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ValidateCallback } from "pojo-validator-react";
import { QuestionnaireType } from "../../../../api/main/models/codeOnly/QuestionnaireType";
import { Guid } from "guid-string";
import { AnalysisType } from "../../../../api/main/models/AnalysisType";
import { QuestionnaireAnalysisTypeLink } from "../../../../api/main/models/QuestionnaireAnalysisTypeLink";

export interface SectionsTabProps {
    model: Questionnaire | undefined,
    sectionsManager: ModelArrayChanges<QuestionnaireSection, string>,
    validateQuestionnaireSection: ValidateModelCallback<QuestionnaireSection>,
    questionnaireSectionValidationErrors: ValidationErrorsForModel,
    questionsManager: ModelArrayChanges<Question, string>,
    validateQuestion: ValidateModelCallback<Question>,
    questionValidationErrors: ValidationErrorsForModel,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>,
    isCreate: boolean,
    validate: ValidateCallback,
    setActiveStage: React.Dispatch<React.SetStateAction<string>>,
    completedStages: string[],
    isTemplate: boolean,

    analysisTypeManager: ModelArrayChanges<AnalysisType, string>,
    validateAnalysisType: ValidateModelCallback<AnalysisType>,
    analysisTypeValidationErrors: ValidationErrorsForModel,

    questionnaireAnalysisTypeLinkManager: ModelArrayChanges<QuestionnaireAnalysisTypeLink, string>,

    defaultSections: QuestionnaireSection[],
}

/**
 * Tab for a maintaining a number of sections and questions.
 * @param props
 */
export const SectionsTab = (props: SectionsTabProps) => {
    const {
        model,
        sectionsManager,
        validateQuestionnaireSection,
        questionnaireSectionValidationErrors,
        questionsManager,
        validateQuestion,
        questionValidationErrors,
        saveAllDeBounce,
        isCreate,
        validate,
        setActiveStage,
        completedStages,
        isTemplate,

        analysisTypeManager,
        validateAnalysisType,
        analysisTypeValidationErrors,

        questionnaireAnalysisTypeLinkManager,

        defaultSections,
    } = props;

    const { t } = useTranslation();

    // Order the items so they show in and can be managed by displayOrder.
    const [orderedItems, {
        canMoveUp: canMoveAnswerUp,
        moveUp: moveAnswerUp,
        canMoveDown: canMoveAnswerDown,
        moveDown: moveAnswerDown,
    }] = useDisplayOrder(sectionsManager);

    const orderedDefaultSections = useMemo(() => {
        if (!defaultSections) {
            return []
        };

        return defaultSections.sort((a, b) => a.displayOrder - b.displayOrder);
    }, [defaultSections]);

    // store the new section name here ready for saving
    const [newSection, setNewSection] = useState<string>('');

    // are we showing the input area for a new section - if not just a button shows to add a section
    const [showNewSection, setShowNewSection] = useState<boolean>(false);

    const [openSectionId, setOpenSectionId] = useState<string>('');

    const [newSectionId, setNewSectionId] = useState<string>('');

    // save the new section and close the new section area
    const handleNewSection = useCallback((sectionName: string, questionnaireId: string | undefined) => {
        if (!!sectionName) {
            // make sure no other section is open
            setOpenSectionId('');

            sectionsManager.addFor({
                ...questionnaireSectionDefaultValues(), id: newSectionId, questionnaireId: questionnaireId, name: sectionName,
            });

            saveAllDeBounce();
            setShowNewSection(false);
            setOpenSectionId(newSectionId);
        }

    }, [sectionsManager, saveAllDeBounce, newSectionId]);


    const handleAddSectionButton = useCallback(() => {
        const newSectionId = Guid.newGuid();
        setNewSectionId(newSectionId);
        setOpenSectionId(newSectionId);

    }, [setNewSectionId, setOpenSectionId]);

    const onSectionComponentToggle = useCallback((event: { sectionId: string, isComponentOpen: boolean, }) => {

        setOpenSectionId(event.isComponentOpen ? event.sectionId : '');

    }, [setOpenSectionId,]);

    return (
        <>
            <Row>
                <Col>
                    <h2> {t('sectionsTab.heading', 'Questionnaire Details')}</h2>
                </Col>
                <Col xs="auto">
                    {
                        // no continue button in edit, only during create
                        isCreate ?
                            <Button color="primary" disabled={!completedStages.find(item => item === 'questionnaire')} onClick={e => {
                                if (validate()) {
                                    saveAllDeBounce();
                                    setActiveStage(model?.questionnaireType === QuestionnaireType.Campaign && !isTemplate ? 'people' : 'schedule');
                                } 
                            }}>
                                {t("common.continue", "Continue")}
                                <> </>
                                <FontAwesomeIcon icon="caret-right" />
                            </Button>
                            :
                            <></>
                    }

                </Col>
            </Row>
            <FormGroup>
                <div>
                    <ConditionalFragment showIf={isCreate}>
                        <span className="text-muted">
                            {t('sectionsTab.sections.helpText', 'Click on a section to rename it, and to add or change questions.')}
                        </span>
                    </ConditionalFragment>
                    {
                        orderedItems.map((item, index) => {
                            return (
                                <SectionComponent key={item.id}
                                    model={item}
                                    change={changes => sectionsManager.changeFor(item.id, changes)}
                                    remove={() => sectionsManager.removeFor(item.id)}

                                    moveUp={() => moveAnswerUp(item.id)} canMoveUp={canMoveAnswerUp(item.id)}
                                    moveDown={() => moveAnswerDown(item.id)} canMoveDown={canMoveAnswerDown(item.id)}

                                    validate={(fieldsToCheck) => validateQuestionnaireSection(item, fieldsToCheck)}
                                    validationErrors={questionnaireSectionValidationErrors(item.id)}

                                    isOpenInitially={item.id === openSectionId || !!sectionsManager.added.find(it => it.id === item.id)}
                                    openSectionId={openSectionId}

                                    questionsManager={questionsManager}
                                    validateQuestion={validateQuestion}
                                    questionValidationErrors={questionValidationErrors}
                                    saveAllDeBounce={saveAllDeBounce}
                                    onSectionToggle={onSectionComponentToggle}

                                    sectionClass={index % 2 === 1 ? 'section-odd' : 'section-even'}

                                    analysisTypeManager={analysisTypeManager}
                                    validateAnalysisType={validateAnalysisType}
                                    analysisTypeValidationErrors={analysisTypeValidationErrors}

                                    questionnaireAnalysisTypeLinkManager={questionnaireAnalysisTypeLinkManager}

                                    defaultSectionName={orderedDefaultSections[index]?.name ?? ''}
                                />
                            );
                        })
                    }
                </div>

            </FormGroup>

            <ConditionalFragment showIf={!!showNewSection}>
                <FormGroup>
                    <Label htmlFor="name">{t('sectionsTab.newSectionName', 'New Section')}</Label>
                    <Input name="name" type="text" onChange={e => setNewSection(e.currentTarget.value)} />
                </FormGroup>
                <Button color="primary" onClick={e => handleNewSection(newSection, model?.id)}>
                    {t('sectionsTab.saveNewSection', 'Save New Section')}
                </Button>
            </ConditionalFragment>
            <ConditionalFragment showIf={!showNewSection}>
                <Button color="primary" outline onClick={e => { setShowNewSection(true); handleAddSectionButton(); }}>
                    {t('sectionsTab.addSection', 'Add A Section')}
                </Button>
            </ConditionalFragment>

            <Row>
                <Col></Col>
                <Col xs="auto">
                    {
                        // no continue button in edit, only during create
                        isCreate ?
                            <Button color="primary" disabled={!completedStages.find(item => item === 'questionnaire')} onClick={e => {
                                if (validate()) {
                                    saveAllDeBounce();
                                    setActiveStage(model?.questionnaireType === QuestionnaireType.Campaign && !isTemplate ? 'people' : 'schedule');
                                } 
                            }}>
                                {t("common.continue", "Continue")}
                                <> </>
                                <FontAwesomeIcon icon="caret-right" />
                            </Button>
                            :
                            <></>
                    }
                </Col>
            </Row>
        </>
    );
};