import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { QuestionnaireUsersList } from "./QuestionnaireUsersList";
import { QuestionnaireUsersAdd, QuestionnaireUsersCreate } from "./QuestionnaireUsersAdd";
import { QuestionnaireUsersImport } from "./QuestionnaireUsersImport";
import { QuestionnaireUserRemove } from "./QuestionnaireUsersRemove";

export const subscriptionQuestionnaireUsersRoutes: Array<RouteEntry> = [
    // subscription administrator level questionnaire user actions at subscription level
    { path: '/subscriptionQuestionnaires/users/details/:id', exact: true, component: QuestionnaireUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/users/details/:id/add', component: QuestionnaireUsersAdd, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/users/details/:id/remove', component: QuestionnaireUserRemove, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/users/list/:id', component: QuestionnaireUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/users/import/:id', exact: true, component: QuestionnaireUsersImport, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/subscriptionQuestionnaires/users/create/:id', exact: true, component: QuestionnaireUsersCreate, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    // Department Admin Level Questionnaire User Actions
    { path: '/departmentQuestionnaires/:departmentId/users/create/:id', exact: true, component: QuestionnaireUsersCreate, authorize: true, requireRole: IdentityRoles.SubscriptionDepartmentAdministration },

];
