import { AddQuestionnaireBase } from "../../questionnaires/AddQuestionnaireBase";

/**
 * Create a new campaign for a subscription.
 */
export const SubscriptionAddQuestionnaire = () => {

    return (
        <AddQuestionnaireBase
            isSubscription={true}
        />
    );
};