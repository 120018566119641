import * as React from "react";
import { Form, FormGroup, FormText, Spinner, Row, Col, Container } from "reactstrap";
import { useConfirmInviteCallback } from "../../api/account";
import { useTranslation } from "react-i18next";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useValidatorCallback } from "pojo-validator-react";
import { useChanges } from "../../shared/useChanges";
import { ConfirmInvite as ConfirmInviteModel } from "../../api/account/models/ConfirmInvite";
import { usePasswordValidation } from "../../shared/passwordValidation";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useHistory } from "react-router";
import { ConfirmInviteProfileDetails } from "../profiles/ConfirmInviteProfileDetails";
import './ConfirmInvite.scss';

/**
 * Confirm an email address and provide registration details as part of an invite.
 */
export const ConfirmInvite = () => {
    const { t } = useTranslation();
    const [confirmInvite, { isExecuting: isConfirmingInvite, errors: confirmInviteErrors }] = useConfirmInviteCallback();
    const { model, change } = useChanges<ConfirmInviteModel>({ password: '', confirmPassword: '' });
    const { checkPassword, passwordRequirements } = usePasswordValidation();
    const history = useHistory();

    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            password: () => !model.password ? t('confirmInvite.passwordRequired', 'Password is required')
                : checkPassword(model.password).errorDescription,
            confirmPassword: () => model.confirmPassword !== model.password ? t('confirmInvite.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    /**
     * Perform a login by and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performRegister = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        const result = await confirmInvite(model);

        if (result) {
            if (result.requiresTwoFactor) {
                history.push(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
            }

            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            if (result.succeeded) {
                // Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
                if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
                    setIsDoingFullPageRedirect(true);
                    window.location.href = result.returnUrl;
                }
            }
        }
    }, [confirmInvite, model, history, validate, setIsDoingFullPageRedirect]);


    // Render the UI.
    return (
        <Container fluid className="confirm-invite-page">
            <Row className="confirm-invite-row">
                <Col xs={12} sm={5} className="login-screen-target-column">
                    <div className="login-target-image"></div>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
                <Col xs={12} sm={5} className="login-screen-input-column">
                    <Row className="impacts-logo-login-row">
                        <div className="impacts-logo-login-image"></div>
                    </Row>
                    <Row>
                        <Col>
                            <h1>
                                {t('confirmInvite.finishRegistrationHeading', 'Register your account')}
                            </h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <p>
                                {t('confirmInvite.thanksForAccepting', 'Thanks for accepting the invite.  You can now set a password to finish your account creation.')}
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={async e => { e.preventDefault(); await performRegister(); }}>
                                <AlertOnErrors simple errors={[confirmInviteErrors]} />

                                <ConfirmInviteProfileDetails />

                                <FormGroup>
                                    <ValidatedInput placeholder={t('register.password', 'Password')} type="password" name="password" autoComplete="new-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
                                    <FormText color="black">
                                        {passwordRequirements}
                                    </FormText>
                                </FormGroup>
                                <FormGroup>
                                    <ValidatedInput placeholder={t('register.confirmPassword', 'Confirm password')} type="password" name="confirmPassword" autoComplete="new-password" value={model.confirmPassword} onChange={e => change({ confirmPassword: e.currentTarget.value })} onBlur={e => validate('confirmPassword')} validationErrors={validationErrors['confirmPassword']} />
                                </FormGroup>
                                <Row className="sign-in-class">
                                    <Row className="sign-in-button">
                                        <ButtonAsync type="submit" color="secondary" isExecuting={isConfirmingInvite || isDoingFullPageRedirect}
                                            executingChildren={<><Spinner size="sm" /> {t('confirmInvite.registering', 'Registering...')}</>}>
                                            {t('confirmInvite.register', 'Register')}
                                        </ButtonAsync>
                                    </Row>
                                </Row>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>

    );
};
