import { useCallback, useMemo } from "react";
import { ModelArrayChanges } from "../../../shared/useChanges";

export type DisplayOrderReturnType<T> = [
    Array<T>,
    {
        moveUp: (id: string) => void,
        canMoveUp: (id: string) => boolean,
        moveDown: (id: string) => void,
        canMoveDown: (id: string) => boolean,
    }
];

/**
 * Custom hook to provide an ordered result as well as methods to manage the order of models that use a displayOrder field.
 */
export function useDisplayOrder<T extends { id: string, displayOrder: number }>(managedItems: ModelArrayChanges<T, string>, filter?: (item: T) => boolean | undefined)
    : DisplayOrderReturnType<T>
{
    // Order the items so they show in display order.
    const orderedItems = useMemo(() => {
        let ret: Array<T> = [...managedItems.model];
        if (filter) {
            ret = ret.filter(filter);
        }
        ret.sort((a, b) => {
            if (a.displayOrder === b.displayOrder) {
                return 0;
            } else if (a.displayOrder > b.displayOrder) {
                return 1;
            } else {
                return -1;
            }
        });
        return ret;
    }, [managedItems.model, filter]);

    // Can we move this item up?
    const canMoveUp = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index <= 0) {
            return false;
        }

        return true;
    }, [orderedItems]);

    // Can we move this item down?
    const canMoveDown = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index >= orderedItems.length - 1) {
            return false;
        }

        return true;
    }, [orderedItems]);

    // Move an item up
    const moveUp = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index <= 0) {
            return false;
        }

        const otherModel = orderedItems[index - 1];
        managedItems.changeFor(item.id, { displayOrder: otherModel.displayOrder } as Partial<T>);
        managedItems.changeFor(otherModel.id, { displayOrder: item.displayOrder } as Partial<T>);
    }, [orderedItems, managedItems]);

    // Move an answer down in the order.
    const moveDown = useCallback((id: string) => {
        const item = orderedItems.find(it => it.id === id);
        if (!item) {
            return false;
        }

        const index = orderedItems.indexOf(item);
        if (index >= orderedItems.length - 1) {
            return false;
        }

        const otherModel = orderedItems[index + 1];
        managedItems.changeFor(item.id, { displayOrder: otherModel.displayOrder } as Partial<T>);
        managedItems.changeFor(otherModel.id, { displayOrder: item.displayOrder } as Partial<T>);
    }, [orderedItems, managedItems]);

    // Return everything in a format that is easy for people to grab the bits they want.
    const ret = useMemo((): DisplayOrderReturnType<T> => ([
        orderedItems,
        {
            canMoveUp,
            moveUp,
            canMoveDown,
            moveDown,
        }
    ]), [orderedItems, canMoveUp, moveUp, canMoveDown, moveDown]);

    return ret;
}