import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { Button, Col, Row } from "reactstrap";
import { useCurrentUserId } from "../../api/account";
import { QuestionResponseType } from "../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../api/main/models/Question";
import { QuestionResponse } from "../../api/main/models/QuestionResponse";
import { useIndividualReportViewModel } from "../../api/main/questionnaires/viewModels/useIndividualReportViewModel";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Background } from "../shared/background/Background";
import { FormButtons } from "../shared/FormButtons";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { ReportSplashPage } from "./ReportSplashPage";
import "./individualQuestionnaireReport.scss";
import { generatePdfAsBlob } from "../../utilities/generatePdfAsBlob";
import { imageUrlToDataUrl } from "../../utilities/imageUrlToDataUrl";
import { useSubscriptionStyleOverridesViewModel } from "../../api/main/subscriptions/viewModels/useSubscriptionStyleOverridesViewModel";
import { IndividualReportPDF } from "./pdfReports/IndividualReportPDF";
import { useAsyncCallback } from "react-use-async-callback";
import { ButtonAsync } from "reactstrap-buttonasync";
import FileSaver from "file-saver";
import moment from "moment";
import { ScrollTo } from "@scottbamford/react-scrollto";
import { useUploadBlobFromBlobObjectCallback } from "../../api/main/blobReferences/useUploadBlobFromBlobObjectCallback";
import { useAutoGeneratePdf } from "./bulkReportGenerator/useAutoGeneratePdf";
import { AutoRenderPdfProps } from "./bulkReportGenerator/BulkReportGenerationRequest";
import { ReportSummary } from "./reportSummary/ReportSummary";
import { useGetReportCustomiserNames } from "./reportCustomiser/utilities/reportCustomiserUtilities";
import { getMultipleChoiceResponseText } from "../questions/utilities/getMultipleChoiceResponseText";
import { ReportQuestionDisplay } from "./ReportQuestionDisplay";

interface IndividualQuestionnaireReportProps extends AutoRenderPdfProps {
    propsQuestionnaireId?: string,
    propsUserId?: string,
    propsSectionId?: string,
    propsReportCustomiser?: string, 
}

//** View a user level report for a questionnarire for a single user with a completed session for this questionnaire.

export const IndividualQuestionnaireReport = (props: IndividualQuestionnaireReportProps) => {

    const { propsQuestionnaireId, propsUserId, propsSectionId, autoGeneratePdf, autoGeneratePdfZipFileName, onPdfReady, propsReportCustomiser } = props;

    //get the userId and questionnaireId from params
    const { questionnaireId: paramQuestionnaireId, userId: paramUserId, sectionId: paramSectionId, reportCustomiser: paramReportCustomiser } = useParams<{ questionnaireId: string | undefined, userId: string | undefined, sectionId: string | undefined, reportCustomiser: string | undefined }>();
    //get the current userId incase one isnt passed in.
    const currentUserId = useCurrentUserId();

    const questionnaireId = !!propsQuestionnaireId ? propsQuestionnaireId : paramQuestionnaireId;
    const userId = !!propsUserId ? propsUserId : (!!paramUserId ? paramUserId : currentUserId);
    const sectionId = !!propsSectionId ? propsSectionId : paramSectionId;
   

    const { t } = useTranslation();
    const history = useHistory();

    // use either the params or the props for the report customer settings
    const reportCustomiser = !!paramReportCustomiser ? paramReportCustomiser : propsReportCustomiser;

    //Get the questionnaire, sections, questions, respondednt session, and profile for a single user
    //with a respondent session for this questionnaire
    const { data: {
        model: questionnaire, questionnaireRespondentSessions, profile, learningUnit
    }, isLoading, errors: loadErrors } = useIndividualReportViewModel(questionnaireId, { excludeIncomplete: true, userId: userId });

    //get the session for this user
    const userSession = useMemo(() => {
        if (!questionnaireRespondentSessions || !userId) {
            return;
        }

        return questionnaireRespondentSessions?.find(item => item.userId === userId);
    }, [questionnaireRespondentSessions, userId]);

    const customiserNames = useGetReportCustomiserNames();

    // array to hold the reportcustomisers
    const reportCustomisers = useMemo(() => {
        // use props or params which sre in the reportCustomiser, or defaults

        let newCustomisers: { Name: string, Value: boolean }[] = [];

        // set the defaults
        customiserNames.forEach((customiserName) => {
            newCustomisers.push({ Name: customiserName, Value: true });
        });

        // overwrite with values if we have them from props or params
        if (!!reportCustomiser) {
            for (let i = 0; i < reportCustomiser.length; i++) {
                newCustomisers[i].Value = reportCustomiser.substring(i, i + 1) === '1' ? true : false;
            }
        }

        return newCustomisers;

    }, [customiserNames, reportCustomiser]);

    // return a reportcustomiser value and default to true if not found
    const isCustomiserSet = useCallback((customiser: string) => {
        let thisCustomiser = reportCustomisers.find(item => item.Name === customiser);

        return !!thisCustomiser ? thisCustomiser.Value : true;

    }, [reportCustomisers]);


    //get the ordered sections
    const orderedSections = useMemo(() => {
        let sections = [...questionnaire?.questionnaireSections ?? []];

        if (!!sectionId) {
            return sections = [...questionnaire?.questionnaireSections.filter(item => item.id === sectionId) ?? []];
        }

        // sort
        if (sections.length) {
            return sections
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }

        return sections;
    }, [questionnaire?.questionnaireSections, sectionId]);

    //Get the ordered questions for a section
    const orderedQuestions = useCallback((sectionId: string) => {
        let questions = questionnaire?.questions.filter(item => item.questionnaireSectionId === sectionId) ?? [];

        // sort
        if (questions.length) {
            return questions
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }

        return questions;
    }, [questionnaire?.questions]);

    //Get the ordered user responses for a single question
    const orderedResponses = useCallback((questionId: string) => {
        // get the session we are interested in
        const session = questionnaireRespondentSessions.find(item => item.userId === userId);
        if (!session) {
            return [];
        }

        // get all the responses from the session for this question only
        let responses = session.responses.filter(item => item.questionId === questionId && item.isUserInput) ?? [];

        // sort
        if (responses.length) {
            return responses
                .sort((a, b) => {
                    if (a.displayOrder === b.displayOrder) {
                        return 0;
                    } else if (a.displayOrder > b.displayOrder) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
        }
        return responses;
    }, [questionnaireRespondentSessions, userId]);

    //Work out the text to display before a response in a different color.
    const displayPreText = useCallback((question: Question, response: QuestionResponse, index: number) => {
        let ret = '';

        // handle multiple text boxes
        if (question.maxTextBoxes > 1) {
            ret = (index + 1).toString();
        }

        // handle formatting for before and after comparison scale
        if (question.responseType === QuestionResponseType.ComparisonScale) {
            ret = (index === 0 ? t('ComparisonScaleDisplayResponse.before', 'Before') : t('ComparisonScaleDisplayResponse.after', 'After'));
        }
        return ret;
    }, [t,]);

    const { data: { model: subscription }, isLoading: subscriptionIsLoading, errors: subscriptionLoadingErrors } = useSubscriptionStyleOverridesViewModel(!!profile?.subscriptionId ? profile?.subscriptionId : questionnaire?.subscriptionId);
    const currencySymbol = subscription?.currencySymbol;

    //present a formatted response depending on the response type.
    const displayResponse = useCallback((question: Question, response: QuestionResponse, index: number) => {
        var ret = '';

        // have we actually had a response from the user?
        if (!response.isUserInput) {
            return ret;
        }

        // depending on the type of response - get the correct field for the display
        if (question.responseType === QuestionResponseType.TextBoxes) {
            // if there are more than 1 text box answers for the question then number them otherwise just show the answer
            ret = response.textInput;
        } else if (question.responseType === QuestionResponseType.YesNo) {
            ret = response.yesNoInput ? 'Yes' : 'No';
        } else if (question.responseType === QuestionResponseType.MultipleChoice || question.responseType === QuestionResponseType.PickOne) {
            // Amount input will contain the number of the response chosen starting at 1 so we need to subtract 1 to get the correct index
            ret = getMultipleChoiceResponseText(question, response.amountInput - 1) ?? '';
        } else {
            // default as all other response types are amount input
            ret = response.amountInput;
        }

        // handle formatting for currency and percentage
        if (question.isPercentage || question.responseType === QuestionResponseType.Percentage) {
            ret += '%';
        }
        if (question.responseType === QuestionResponseType.CurrencyAmount) {
            ret = currencySymbol + ret;
        }

        return ret;
    }, [currencySymbol]);


    const singleSection = useMemo(() => {
        if (!questionnaire || !sectionId) {
            return;
        }

        return questionnaire?.questionnaireSections.find(item => item.id === sectionId);
    }, [questionnaire, sectionId]);

    // section to scroll to when a link is clicked - we use 'index' as a section id to go back to the index
    const [scrollToSectionId, setScrollToSectionId] = useState<string>('');
    const handleScrollRequest = useCallback(async (scrollDestination) => {
        setScrollToSectionId(scrollDestination);

        // let the scroll happen then reset
        await new Promise(res => setTimeout(res, 2000));
        setScrollToSectionId('');

    }, [setScrollToSectionId]);

    /*All Code Below Is used for generating company Questionnaire Report as a PDF*/
    /* getting the current subscription has been moved up to use the currency sysmbol */


    // Generate a PDF file as a blob.
    const generatePdf = useCallback(async (): Promise<Blob> => {
        // Convert the images we need to data URLs so they can be embedded in the PDF.
        const pdfSplashPageImageUrl = await imageUrlToDataUrl('/img/PDF-Report-Splash-Page-Image.jpg');
        const impactsLogoUrl = await imageUrlToDataUrl('/img/IMPACTS_LOGO_NEW2022.jpeg');


        // Generate the PDF.
        let blob = await generatePdfAsBlob(IndividualReportPDF({
            companyColor: !!subscription?.brandColor ? subscription.brandColor : '#1188FF',
            companyLogoUrl: !!subscription?.brandImageBlobReference?.url ? subscription?.brandImageBlobReference?.url : impactsLogoUrl,
            splashPageImage: pdfSplashPageImageUrl,
            questionnaireTitle: (!!sectionId ? t('reportSplashPage.title', `${singleSection?.name} Report`) : t('questionnaireReport.title', `${questionnaire?.name}`)),
            sectionTitle: (!!sectionId ? t('reportSplashPage.title', `${singleSection?.name} Report`) : undefined),
            sessionEndDate: moment(userSession?.endDate).format('Do MMMM YYYY'),
            impactsLogoUrl: impactsLogoUrl,
            userFirstName: profile?.firstName,
            userLastName: profile?.lastName,
            userEmail: profile?.user.email,
            questionnaireDiscription: questionnaire?.description,
            lineManagerName: userSession?.lineManagerName,
            lineManagerEmail: userSession?.lineManagerEmail,
            questionnaireSections: orderedSections,
            orderedQuestions: orderedQuestions,
            orderedResponses: orderedResponses,
            displayPreText: displayPreText,
            displayResponse: displayResponse,
            learningUnit: learningUnit,
            isIncludeSummary: isCustomiserSet('IncludeSummary'),
            isIncludeDescription: isCustomiserSet('IncludeDescription'),
            isIncludeLearningUnit: isCustomiserSet('IncludeLearningUnit'),
        }));

        return blob;
    }, [subscription, sectionId, singleSection, t, profile, questionnaire, learningUnit, userSession, orderedSections, orderedQuestions, orderedResponses, displayPreText, displayResponse, isCustomiserSet, ]);

    const generatedReportFileName = useMemo(() => {
        let ret = 'Individual';
        if (!!profile) {
            ret = profile.firstName + ' ' + profile.lastName;
        } else {
            ret = subscription?.name ?? 'Individual'
        }

        ret += '-' + questionnaire?.name.substring(0, 25) ?? 'Questionnaire';

        ret += `-Report-${moment().format("YYYY-MM-DD")}.pdf`;


        // If we have a zip file name for, prefix the filename with that in the filename so we can find it in the blobs later to zip it.
        if (autoGeneratePdfZipFileName) {
            ret = `${autoGeneratePdfZipFileName}:${ret}`;
        }

        return ret;
    }, [profile, subscription, questionnaire, autoGeneratePdfZipFileName]);

    // Generate the PDF and open it in a new window for printing.
    const [generateAndOpenPdf, { isExecuting: isGeneratingPdfForOpen }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        let url = URL.createObjectURL(blob);
        window.open(url);
    }, [generatePdf]);

    /*Generate PDF and Download It*/
    const [generateAndDownloadPdf, { isExecuting: isGeneratingPdfForDownload }] = useAsyncCallback(async () => {
        let blob = await generatePdf();
        FileSaver.saveAs(blob, generatedReportFileName)
    }, [generatePdf, generatedReportFileName]);

    const [upload] = useUploadBlobFromBlobObjectCallback();

    // Generate PDF and save to blob ready for Zip file Download
    const [generateAndSavePdfForZip] = useAsyncCallback(async () => {
        let blob = await generatePdf();

        //Upload the blob to our blobs table
        const blobReference = await upload(generatedReportFileName, blob);

        //Send Blob refernce Id back to report builder for Zip download.
        return blobReference?.id;
    }, [generatePdf, generatedReportFileName, upload]);

    // If we are to auto generate a pdf (because we are being rendered in the background as part of report generation) do so as soon as all our
    // data is ready.
    useAutoGeneratePdf({
        readyToGenerate: !!autoGeneratePdf && !!questionnaire,
        loadErrors: loadErrors,
        generatePdfBlobReference: generateAndSavePdfForZip,
        onPdfReady: onPdfReady,
    });

    return (
        <Background>
            <div className="report-splashpage">
                {/*Title page for the report.*/}
                <ReportSplashPage
                    profileFirstName={profile?.firstName}
                    profileLastName={profile?.lastName}
                    questionnaireId={questionnaireId}
                    sectionId={sectionId}
                    endDate={moment(userSession?.endDate).format('Do MMMM YYYY')}
                />
            </div>
            <MainContainer fluid className="individual-report-container">
                <ConditionalFragment showIf={isLoading}>
                    <Row>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </Row>
                </ConditionalFragment>

                <AlertOnErrors errors={[loadErrors, subscriptionLoadingErrors]} />
                <Row className="report-page-header">
                    <Col>
                        <img src="../../../img/IMPACTS_LOGO_NEW2022.png" alt="" />
                    </Col>
                    <Col>
                        <FormButtons>
                            <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen || !!isLoading || !!subscriptionIsLoading} isExecuting={isGeneratingPdfForOpen}
                                executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('individualQuestionnaireReport.print', 'Generating PDF...')}</>}>
                                <FontAwesomeIcon icon="print" />
                                <> </>
                                {t('individualQuestionnaireReport.print', 'Print')}
                            </ButtonAsync>
                            <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} disabled={!!isGeneratingPdfForDownload || !!isLoading || !!subscriptionIsLoading} isExecuting={isGeneratingPdfForDownload}
                                executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('individualQuestionnaireReport.download', 'Generating PDF...')}</>}>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('individualQuestionnaireReport.download', 'Download')}
                            </ButtonAsync>
                            <> </>
                            <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                                {t('common.close', 'Close')}
                            </Button>
                        </FormButtons>
                    </Col>
                </Row>
                <h1 className="report-title"> {!!sectionId ? t('questionnaireReport.title', `${singleSection?.name}`) : t('questionnaireReport.title', `${questionnaire?.name}`)}</h1>
                <div className="report-session-details">
                    <h2>{t('individualQuestionnaireReport.session-details-header', 'Participant')}</h2>
                    <Row className="report-session-details-row">
                        <Col xs={12} sm={6}>
                            <label>{t('individualQuestionnaireReport.name', 'Name')}</label>
                            <span>{" " + profile?.firstName + " " + profile?.lastName}</span>
                        </Col>
                        <Col xs={12} sm={6}>
                            <label>{t('individualQuestionnaireReport.email', 'Email')}</label>
                            <span>{" " + profile?.user.email}</span>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={!!userSession?.lineManagerEmail}>
                        <Row className="report-session-details-row">
                            <Col xs={12} sm={6}>
                                <label>{t('individualQuestionnaireReport.lineManager.name', 'Line Manager:')}</label>
                                <span>{" " + userSession?.lineManagerName}</span>
                            </Col>
                            <Col xs={12} sm={6}>
                                <label>{t('individualQuestionnaireReport.lineManager.email', 'Email:')}</label>
                                <span>{" " + userSession?.lineManagerEmail}</span>
                            </Col>
                        </Row>
                    </ConditionalFragment>
                </div>
                <ConditionalFragment showIf={isCustomiserSet('IncludeSummary')}>
                    <ReportSummary
                        learningUnit={learningUnit ?? undefined}
                        questionnaire={questionnaire ?? undefined}
                        isIncludeGraphs={isCustomiserSet('IncludeGraphs')}
                        isIncludeIndex={isCustomiserSet('IncludeIndex')}
                        isIncludeLearningUnit={isCustomiserSet('IncludeLearningUnit')}
                        isIncludeDescription={isCustomiserSet('IncludeDescription')}
                        orderedSections={orderedSections}
                        scrollToSectionId={scrollToSectionId}
                        handleScrollRequest={handleScrollRequest}
                    />
                </ConditionalFragment>

                <div>
                    <Row>
                        {/* Display Sections*/}
                        <Col xs={12}>
                            {orderedSections.map(section => {
                                return (
                                    <div className="section-container" key={section.id}>
                                        <ScrollTo shouldScrollTo={scrollToSectionId === section.id} position="start" scrollMargin="70px">
                                            <ConditionalFragment showIf={orderedSections.length > 1}>
                                                <div className="text-right">
                                                    <Button type="button" color="primary" outline onClick={e => handleScrollRequest('index')}>
                                                        <FontAwesomeIcon icon="caret-up" />
                                                        <> </>
                                                        {t('companyQuestionnaireReport.index', 'Section Index')}
                                                    </Button>
                                                </div>
                                            </ConditionalFragment>
                                        </ScrollTo>
                                        <div className="section-header"><span className="section-initial">{section.name.substring(0, 1)}</span>&nbsp; &nbsp; {section.name}</div>
                                        <ConditionalFragment showIf={!!section.description}>
                                            <div className="section-description">{section.description}</div>
                                        </ConditionalFragment>
                                        <div>
                                            {/*display questions*/}
                                            {orderedQuestions(section.id).map(question => {
                                                return (
                                                    <ReportQuestionDisplay
                                                        key={question.id}
                                                        question={question}
                                                        orderedResponses={orderedResponses(question.id)}
                                                        displayResponse={displayResponse}
                                                        displayPreText={displayPreText}
                                                    />
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            })}
                        </Col>
                    </Row>
                </div>
                <FormButtons>
                    <ButtonAsync color="primary" outline onClick={generateAndOpenPdf} disabled={!!isGeneratingPdfForOpen || !!isLoading || !!subscriptionIsLoading} isExecuting={isGeneratingPdfForOpen}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('individualQuestionnaireRepot.print', 'Generating PDF...')}</>}>
                        <FontAwesomeIcon icon="print" />
                        <> </>
                        {t('individualQuestionnaireReport.print', 'Print')}
                    </ButtonAsync>
                    <ButtonAsync color="primary" outline onClick={generateAndDownloadPdf} disabled={!!isGeneratingPdfForDownload || !!isLoading || !!subscriptionIsLoading} isExecuting={isGeneratingPdfForDownload}
                        executingChildren={<><FontAwesomeIcon icon="spinner" spin /> {t('individualQuestionnaireReport.download', 'Generating PDF...')}</>}>
                        <FontAwesomeIcon icon="download" />
                        <> </>
                        {t('individualQuestionnaireReport.download', 'Download')}
                    </ButtonAsync>
                    <> </>
                    <Button type="button" color="primary" outline onClick={e => history.goBack()}>
                        {t('common.close', 'Close')}
                    </Button>
                </FormButtons>
            </MainContainer>
        </Background>
    );
};