import { ValidationErrors } from "pojo-validator";
import { ValidateCallback } from "pojo-validator-react";
import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Label, Row, } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { HtmlDisplay } from "../../../shared/htmlEditor";
import { ValidatedNumberInput } from "../../shared/numberInput/ValidatedNumberInput";
import { getNumberOfPossibleAnswers } from "../utilities/getNumberOfPossibleAnswers";
import "./questionMeasure.scss";
import "./questionPresenter.scss";

export interface QuestionMeasureProps {
    question: Question,
    sessionId?: string,
    model: QuestionResponse,
    change: (changes: Partial<QuestionResponse>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    userInputDisabled?: boolean,
    isFirstResponse: boolean,
    currencySymbol: string,
}

// displays a question either for input or for preview.
// if no sessionId is passed in we are previewing.

export const QuestionMeasure = (props: QuestionMeasureProps) => {
    const {
        question, sessionId, model, change, validate, validationErrors, userInputDisabled, isFirstResponse, currencySymbol,
    } = props;

    const handleAmountInputChange = useCallback(() => {
        if (!validate('amountInput')) {
            return;
        }

        // If we are validating we can assume the user has been into the field even if they have set it to 0 or left it set to 0
        change({ isUserInput: true });

    }, [validate, change]);

    const { t } = useTranslation();

    const instructionText = useMemo(() => {
        if (!question) {
            return;
        }

        let numberOfPossibleAnswers = getNumberOfPossibleAnswers(question);
        switch (question.responseType) {
            case QuestionResponseType.CurrencyAmount:
                return t("questionMeasure.instructions.currencyAmount", "Please enter the amount below.");
            case QuestionResponseType.Number:
                return t("questionMeasure.instructions.currencyAmount", "Please enter the number below.");
            case QuestionResponseType.Percentage:
                return t("questionMeasure.instructions.currencyAmount", "Please enter the percentage below.");
            case QuestionResponseType.YesNo:
                return t("questionMeasure.instructions.currencyAmount", "Please select your answer below.");
            case QuestionResponseType.Scale:
                if (question.isPercentage) {
                    return t("questionMeasure.instructions.scale", `Please select from the scale provided, lowest % being poor – 100% being excellent.`);
                } else {
                    return t("questionMeasure.instructions.scale", `Please select from the scale provided, 1 being poor – ${numberOfPossibleAnswers} being excellent.`);
                }
            case QuestionResponseType.ComparisonScale:
                if (question.isPercentage) {
                    return t("questionMeasure.instructions.comparisonScale", `Please select from the scale provided, lowest % being poor – 100% being excellent.`);
                } else {
                    return t("questionMeasure.instructions.comparisonScale", `Please select from the scale provided, 1 being poor – ${numberOfPossibleAnswers} being excellent.`);
                }
            default:
                return '';
        }


    }, [question, t]);


    // generate the correct number of radio buttons for a scale or comparison scale responseType
    const generateScaleRadioButtons = useCallback(() => {
        if (!question) {
            return null;
        }

        if (!question || question.scaleMax === 0) {
            return [];
        }
        let scaleArray: Array<number> = [];

        let multiplier = !!question.isPercentage ? Math.round((100 / question.scaleMax)) : 1;

        for (let i = 0; i < question.scaleMax; i++) {
            if ((i + 1) === question.scaleMax) {
                scaleArray.push(!!question.isPercentage ? 100 : question.scaleMax)
            } else {
                scaleArray.push((i + 1) * multiplier)
            }
        }

        return (
            <>
                <Row>
                    {question.isNARequired ?
                        <label className="text-center" key={"newbuttonlabel0"}>{t("questionMeasure.scale.notApplicable", "N/A")}</label>
                        : null
                    }
                    {
                        scaleArray.map(item => {
                            return (
                                <label className="text-center" key={`newbutton${item}`}>
                                    {!!question.isPercentage ? item + '%' : item}
                                </label>
                            )
                        })
                    }
                </Row>
                <Row>
                    {question.isNARequired ?
                        <label className="text-center" key={"newbutton0"}>
                            <input
                                type="radio"
                                value={0}
                                name={`amountInput${model.id}`}
                                checked={model?.amountInput === 0 && model?.isUserInput}
                                onChange={e => change({ amountInput: parseInt(e.currentTarget.value), isUserInput: true })}
                                disabled={!sessionId || !!userInputDisabled} />
                        </label>
                        : null
                    }

                    {
                        scaleArray.map(item => {
                            return (
                                <label className="text-center" key={`newbutton${item}`}>
                                    <input
                                        type="radio"
                                        value={item}
                                        name={`amountInput${model.id}`}
                                        checked={model?.amountInput === item}
                                        onChange={e => change({ amountInput: parseInt(e.currentTarget.value), isUserInput: true })}
                                        disabled={!sessionId || !!userInputDisabled} />
                                </label>
                            )
                        })
                    }
                </Row>
            </>
        )
    }, [change, model, question, userInputDisabled, sessionId, t]);

    // if the response length is zero set the isUserInput back to false
    // this allows a user to clear a previously given answer
    const checkForEmptyResponse = useCallback((responseLength: number) => {
        if (responseLength === 0) {
            change({amountInput: 0, isUserInput: false })
        }
    }, [change]);

    return (
        <div className="question-measure">
            <ConditionalFragment showIf={isFirstResponse}>
                <Row className="question-text">
                    <Col xs={12}>
                        {HtmlDisplay({ html: question.questionText })}
                    </Col>
                    <Col className="question-sub-text text-muted" xs={12}>
                        {instructionText ?? ''}
                    </Col>
                </Row>
            </ConditionalFragment>
            <Row>
                <Col xs={12} sm={6}>
                    <ConditionalFragment showIf={question.responseType === QuestionResponseType.CurrencyAmount}>
                        <ValidatedNumberInput disabled={!sessionId || !!userInputDisabled} readOnly={!sessionId || !!userInputDisabled} name="amountInput" value={model?.amountInput}
                            onChange={e => { change({ amountInput: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0, isUserInput: true }); checkForEmptyResponse(e.currentTarget.value.length); }}
                            onBlur={e => handleAmountInputChange()}
                            validationErrors={validationErrors['amountInput']}
                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                            currencySymbol={currencySymbol}
                        />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={question.responseType === QuestionResponseType.Number}>
                        <ValidatedNumberInput disabled={!sessionId || !!userInputDisabled} readOnly={!sessionId || !!userInputDisabled} name="amountInput" value={model?.amountInput}
                            onChange={e => { change({ amountInput: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0, isUserInput: true }); checkForEmptyResponse(e.currentTarget.value.length); }}
                            onBlur={e => handleAmountInputChange()}
                            validationErrors={validationErrors['amountInput']}
                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                        />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={question.responseType === QuestionResponseType.Percentage}>
                        <ValidatedNumberInput disabled={!sessionId || !!userInputDisabled} readOnly={!sessionId || !!userInputDisabled} name="amountInput" value={model?.amountInput}
                            onChange={e => { change({ amountInput: e.currentTarget.value.length > 0 ? e.currentTarget.valueAsNumber : 0, isUserInput: true }); checkForEmptyResponse(e.currentTarget.value.length); }}
                            onBlur={e => handleAmountInputChange()}
                            validationErrors={validationErrors['amountInput']}
                            onFormat={value => t('common.percentage', '{{value, #,0%}}', { value: value / 100 })}
                        />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={question.responseType === QuestionResponseType.YesNo}>
                        <div className="yes-no-input">
                            <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (!!model.yesNoInput ? 'active' : '')}
                                onClick={e =>  change({ yesNoInput: true, isUserInput: true })}>
                                {t('common.yes', 'Yes')}
                            </Button>
                            <Button disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (!model.yesNoInput && !!model.isUserInput ? 'active' : '')}
                                onClick={e => change({ yesNoInput: false, isUserInput: true })}>
                                {t('common.no', 'No')}
                            </Button>
                        </div>
                    </ConditionalFragment>
                </Col>
                <ConditionalFragment showIf={question.responseType === QuestionResponseType.Scale}>
                    <Col xs={12} className="scale-measure single-scale">
                        {/* get the scale max from the question and create a radio button for each possibility */}
                        <div className="radio">
                            {generateScaleRadioButtons()}
                        </div>
                    </Col>
                </ConditionalFragment>
                <ConditionalFragment showIf={question.responseType === QuestionResponseType.ComparisonScale}>
                    <Col xs={12} className="scale-measure">
                        <Label className="before-after-label">
                            {/* if we are on the first response use before and on the second use after */}
                            {
                                isFirstResponse
                                    ? <>{t("questionMeasure.comparisonScale.before", "Before:")}</>
                                    : <>{t("questionMeasure.comparisonScale.after", "After:")}</>
                            }
                        </Label>
                        {/* get the scale max from the question and create a radio button for each possibility */}
                        <div className="radio">
                            {generateScaleRadioButtons()}
                        </div>
                    </Col>
                </ConditionalFragment>
            </Row>
        </div>
    );
};