import { useState, useMemo } from "react";
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { useAnalysisTypes } from '../../api/main/analysisTypes/useAnalysisTypes';
import { useCurrentUserOrEmulatedSubscriptionId } from "../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { PillsNavBar } from "../shared/pillsNavBar/PillsNavBar";
import { ManageNavigation } from "../manage/ManageNavigation";
import { useDeleteAnalysisTypeCallback } from "../../api/main/analysisTypes/useDeleteAnalysisTypeCallback";
import { DeleteAnyModal } from "../shared/DeleteAnyModal";

interface AnalysisTypesListProps {
    isAdmin?: boolean,
}

/**
 * List of adminonly AnalysisTypes.
 */
export const AdminAnalysisTypesList = () => (<AnalysisTypesList isAdmin={true} />);

/**
 * List of analysisTypes.
 */
export const AnalysisTypesList = (props: AnalysisTypesListProps) => {
    const { isAdmin = false } = props;
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    const baseRoute = !!isAdmin ? '/administration/analysisTypes/' : '/manage/analysisTypes/';
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useAnalysisTypes({ pageSize: undefined, subscriptionId: !isAdmin ? currentUserSubscriptionId : undefined });

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return [];
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search,]);

    const displayItems = useMemo(() => {
        //Sort items by Name

        let ret = [...items];

        if (isAdmin) {
            ret = ret.filter(item => item.isAdminOnly)
        }

        ret.sort((a, b) => {
            if (a.isAdminOnly === b.isAdminOnly && a.name === b.name) {
                return 0;
            } else if (a.isAdminOnly < b.isAdminOnly || a.name < b.name) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret
    }, [items, isAdmin]);

    useReplaceSearchParamsEffect({ search: search });

    // need to know if there are any items for the no items message and the loading indicator
    const isAnyItems = useMemo(() => {
        if (isLoading || !displayItems) {
            return false;
        }

        if (isAdmin) {
            return displayItems.filter(it => it.isAdminOnly).length > 0;
        } else {
            return displayItems.length > 0
        }


    }, [displayItems, isLoading, isAdmin]);

    const bannerClassName = useMemo(() => {
        if (!isAdmin) {
            return "bannerWithPills"
        }
        return "bannerNoPills"
    }, [isAdmin]);


    // Delete Any Modal
    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteAnalysisTypeCallback();
    const [deletedItemName, setDeletedItemName] = useState<string>("");
    const [deletedItemId, setDeletedItemId] = useState<string>("");


    return (
        <Background>
            <Banner fluid>
                <Row>
                    <ConditionalFragment showIf={!isAdmin}>
                        <Col xs={12}>
                            <PillsNavBar>
                                <ManageNavigation
                                />
                            </PillsNavBar>
                        </Col>
                    </ConditionalFragment>
                </Row>
                <Row className={bannerClassName}>
                    <Col xs={12} sm="">
                        <h1>{t('analysisTypesList.heading', 'Analysis Types')}</h1>
                    </Col>

                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row className={"searchBar"}>
                        <Col xs="">
                        </Col>
                        <Col xs="auto">
                            <LinkContainer to={`${baseRoute}add`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" /><> {t('analysisTypesList.add', 'Add')}</>
                                </Button>
                            </LinkContainer>
                        </Col>
                        { /* don't want to allow cards or table view so hide buttons and default to table */}
                        {/*    <Col xs={12} md="auto">*/}
                        {/*        <ButtonGroup>*/}
                        {/*            <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>*/}
                        {/*                <FontAwesomeIcon icon="th-large" />*/}
                        {/*                <span className="sr-only">{t('common.cards', 'Cards')}</span>*/}
                        {/*            </Button>*/}
                        {/*            <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>*/}
                        {/*                <FontAwesomeIcon icon="th-list" />*/}
                        {/*                <span className="sr-only">{t('common.list', 'List')}</span>*/}
                        {/*            </Button>*/}
                        {/*        </ButtonGroup>*/}
                        {/*    </Col>*/}
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, removeErrors]} />
                <Row className="mb-2">
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>
                <CardsOrTable
                    // always show as list as the cards look wrong with no information
                    viewMode={'table'}
                    items={displayItems}
                    onItemClick={item => isAdmin || !item.isAdminOnly ? history.push(`${baseRoute}edit/${item.id}`) : undefined}
                    tableHeadings={[
                        t('analysisTypesList.name', 'Name'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.name}</CardTitle>) : item.name,
                    ]}
                    buttons={(item) => (
                        <>
                            <ConditionalFragment showIf={isAdmin || !item.isAdminOnly}>
                                <ButtonGroup>
                                    <LinkContainer to={`${baseRoute}edit/${item.id}`}>
                                        <Button color="primary" outline>
                                            <FontAwesomeIcon icon="edit" />
                                            <> {t('common.edit', 'Edit')}</>
                                        </Button>
                                    </LinkContainer>
                                    <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                        <DropdownToggle color="primary" outline caret>
                                            <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); setDeletedItemName(item.name); }}>
                                                <FontAwesomeIcon icon="trash" />
                                                <> {t('common.delete', 'Delete')}</>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </ButtonDropdown>
                                </ButtonGroup>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!isAdmin && item.isAdminOnly}>
                                <span className="text-muted">
                                    {t('common.noEdit', 'System setting - not editable')}
                                </span>
                            </ConditionalFragment>
                        </>
                    )}
                />
                <ConditionalFragment showIf={isLoading && !isAnyItems}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !isAnyItems}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>


                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={deletedItemName}
                        remove={() => remove(deletedItemId)}
                    />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
