import { gql, useLazyQuery } from "@apollo/client";
import React from "react";
import { mainApiConfig } from "../../../configure/mainApiConfig";
import { AsyncLoadPagedOptions, AsyncLoadPagedResult } from "../../../shared/abstractStore";
import { useFetchMoreCallback } from "../../../shared/useFetchMoreCallback";
import { questionnaireAnalysisTypeLinksQuery, questionnaireAnalysisTypeLinksQueryVariables } from "../generated/questionnaireAnalysisTypeLinksQuery";
import { questionnaireAnalysisTypeLinkFieldsFragment } from "../models/QuestionnaireAnalysisTypeLink";


export interface QuestionnaireAnalysisTypeLinksAsyncLoadPageOptions extends AsyncLoadPagedOptions {
    questionnaireId?: string | null | undefined,
    analysisTypeId?: string | null | undefined,
    subscriptionId?: string | null | undefined,
}



export function useQuestionnaireAnalysisTypeLinks(options: QuestionnaireAnalysisTypeLinksAsyncLoadPageOptions = {}): AsyncLoadPagedResult<questionnaireAnalysisTypeLinksQuery> {
    //Query data we need from the api.
    const [load, { data, loading, error, refetch, fetchMore: fetchMoreFromStore }] = useLazyQuery<questionnaireAnalysisTypeLinksQuery, questionnaireAnalysisTypeLinksQueryVariables>(
        gql`
        query questionnaireAnalysisTypeLinksQuery ($offset: Int, $first: Int, $questionnaireId: ID, $analysisTypeId: ID, $subscriptionId: ID) {
            items: questionnaireAnalysisTypeLinks (offset: $offset, first: $first, questionnaireId: $questionnaireId, analysisTypeId: $analysisTypeId, subscriptionId: $subscriptionId) {
                ...questionnaireAnalysisTypeLinkFields
            }
        }

        ${questionnaireAnalysisTypeLinkFieldsFragment}
        `,
        {

            variables: {
                offset: options.startOffset,
                first: options.pageSize,
                questionnaireId: options.questionnaireId,
                analysisTypeId: options.analysisTypeId,
                subscriptionId: options.subscriptionId,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Get the functions used to manage paging.
    const { fetchMore, hasMore: _hasMore } = useFetchMoreCallback<questionnaireAnalysisTypeLinksQuery>(
        fetchMoreFromStore,
        (options?.startOffset ?? 0) + (data?.items?.length ?? 0),
        (prevResults, newResults) => ({
            ...prevResults,
            items: [...(prevResults?.items ?? []), ...(newResults?.items ?? [])],
        }),
        (newResults) => newResults?.items?.length
    );
    const hasMore = React.useCallback(() => !options.pageSize ? false : _hasMore(), [options.pageSize, _hasMore]);

    //return the results
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
        fetchMore: fetchMore,
        hasMore: hasMore,
    }), [data, refresh, loading, error, fetchMore, hasMore]);
}