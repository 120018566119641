import { useTranslation } from "react-i18next";
import { RegisteredUsersListBase } from "../../users/RegisteredUsersListBase";

/**
 * List of users for a subscription accessed at subscription administrator level
 */
export const SubscriptionUsersList = () => {
    const { t } = useTranslation();
    return (
        <RegisteredUsersListBase
            title={t('subscriptionUsersList.title', 'People')}
            baseRoute={'/manage/users'}
        />
    );
};