import { useMemo } from "react";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { QuestionType } from "../../../api/main/models/codeOnly/QuestionType";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";

/**
 * Return the percentage completion number for a questionnaire.
 */


// Memoised if it's not going to change much
export function useQuestionnaireCompletionPercentage(
    questions: Array<Question>,
    session: QuestionnaireRespondentSession,
    responses: Array<QuestionResponse>,
): number {

    const ret = useMemo(() => {
        return getQuestionnairePercentageComplete(questions, session, responses );
    }, [questions, responses, session]);

    return ret;
}


// Immediate calculation version
export function getQuestionnairePercentageComplete(
    questions: Array<Question>,
    session: QuestionnaireRespondentSession,
    responses: Array<QuestionResponse>,
): number {

    let percentageComplete = 0;

    // Check that we have everything we need and return 0 if not
    if (!questions.length || !responses.length || !session.startDate) {
        return percentageComplete;
    }

    // Initialisations
    let numberOfQuestions = questions.length;
    let numberOfCompletions = 0;

    // Check the endDate on the session and return 100 if set
    if (!!session.endDate) {
        percentageComplete = 100;
        return percentageComplete;
    }

    // If we get here we need to check each question for completion

    // Completion checks
    for (let question of questions) {
        let completed = false;

        // Default requirement is just number of responses input by the user for this question
        let numberOfQuestionResponses = responses.filter(item => item.questionId === question.id && item.isUserInput).length;

        if (question.questionType !== QuestionType.Measure && question.minTextBoxes > 0) {
            // We need to count all none-blank responses not just user responses
            numberOfQuestionResponses = responses.filter(item => item.questionId === question.id && item.isUserInput && item.textInput.length > 0).length;

            // Make sure we have enough text boxes to satisfy the minimum number
            completed = numberOfQuestionResponses >= question.minTextBoxes;
        } else if (question.questionType !== QuestionType.Measure && question.responseType === QuestionResponseType.MultipleChoice) {
            // For multiple choice we need at least one answer
            completed = numberOfQuestionResponses > 0;

        } else if (question.questionType !== QuestionType.Measure && question.responseType === QuestionResponseType.PickOne) {
            // For pick one we need an answer
            completed = numberOfQuestionResponses > 0;

        } else {
            // we just need a single response for all measures except the comparisonScale type which needs 2
            if (question.responseType === QuestionResponseType.ComparisonScale) {
                completed = numberOfQuestionResponses === 2;
            } else {
                completed = numberOfQuestionResponses > 0;
            }
        }

        // total up the completions
        if (completed) {
            numberOfCompletions++
        }
    }


    // calculate the percentage to return
    percentageComplete = Math.floor(numberOfCompletions / numberOfQuestions * 100);

    return percentageComplete;
}