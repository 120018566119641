import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "reactstrap";
import { Question } from "../../../api/main/models/Question";
import { ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";
import { LearnersForDragComponent, SectionsListItem } from "./QuestionnaireOverview";
import { chartDataConverters } from "../../dashboard/chartDataUtilities/ChartDataConverter";
import { ApexOptions } from "apexcharts";
import { useToggleState } from "use-toggle-state";
import { SelectGraphTypeModal } from "./SelectGraphTypeModal";
import Chart from "react-apexcharts";
import { Guid } from "guid-string";

interface graphValues {
    text: string;
    value: number;
}

function useCompletionChartData(learners: Array<LearnersForDragComponent>, questionId: Guid): Array<ChartDataSeries> {


    const generateSeries = useCallback(() => {
        // Generate chart data for each group.
        const ret = new Array<graphValues>()

        learners.forEach(learner => {
            if (ret.find(r => r.text === learner.name) !== undefined) {
                return;
            }

            let responses = learner.learnerResponses.filter(item => item.questionId === questionId);

            responses.forEach(response => {
                if (response.numberResponse === undefined) { return; }
                if (responses.find(item => item.displayOrder > response.displayOrder)) { return;  }
                ret.push({
                    text: learner.name,
                    value: response.numberResponse
                })
            });
        });


        //responses.forEach(response => {
        //    if (ret.find(r => r.text === response.responseText) !== undefined) {
        //        return;
        //    }
        //    ret.push({
        //        text: response.responseText,
        //        value: responses.filter(r => r.responseText === response.responseText).length
        //    })
        //});

        return {
            name: "",
            data: ret
        } as ChartDataSeries;
    }, [learners, questionId]);

    const ret = useMemo(() => {
        return [
            generateSeries(),
        ];
    }, [generateSeries]);

    return ret;

};

export interface QuestionInSectionComponentProps {
    question: Question;
    sectionListItem: SectionsListItem;
};

export const QuestionInSectionComponent = (props: QuestionInSectionComponentProps) => {
    const {
        question,
        sectionListItem,
    } = props;

    const { t } = useTranslation();

    const [graphType, setGraphType] = useState<string>("");
    const [isGraphModalOpen, toggleIsGraphModalOpen] = useToggleState();

    //const allQuestionResponses = useMemo(() => {
    //    const learners = sectionListItem.learners
    //    let questionResponses: Array<ResponsesForDragComponent> = []
    //    learners.forEach(learner => {

    //        const currentQuestionResponses = learner.learnerResponses.filter(response => response.questionId === question.id ?? undefined);
    //        currentQuestionResponses.forEach(response => {
    //            questionResponses.push(response)
    //        })
    //    })

    //    return questionResponses;
    //}, [question, sectionListItem]);

    const chartData = useCompletionChartData(sectionListItem.learners, question.id);

    // Convert data into format needed for our completions graph.
    const { series: pieChartSeries, options: pieChartSeriesOptions } = useMemo(() => chartDataConverters.toPie(chartData), [chartData]);
    const { series: columnChartSeries, options: columnChartSeriesOptions } = useMemo(() => chartDataConverters.toColumn(chartData), [chartData]);
    const { series: barChartSeries, options: barChartSeriesOptions } = useMemo(() => chartDataConverters.toColumn(chartData), [chartData]);

    // Compile all the options we want to use for our graph.
    const pieChartOptions = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: true,
                formatter: (val, options) => {
                    // Show value instead of percentage.
                    return `${options.w.config.series[options.seriesIndex]}`;
                },
            },

            legend: {
                position: 'bottom',
            },

            plotOptions: {
                pie: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            color: '#000000',
                        }
                    }
                }
            },

            colors: [
                '#43A0FC',
                '#0055aa',
                '#6693f5',
                '#008ecc',
                '#95c8d8',
                '#0e4c92',
            ],

            ...pieChartSeriesOptions,
        } as ApexOptions
    ), [pieChartSeriesOptions]);

    // Compile all the options we want to use for our graph.
    const columnChartOptions = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: true,
            },

            plotOptions: {
                bar: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            color: '#000000',
                        }
                    }
                }
            },

            colors: [
                '#43A0FC',
                '#e6e1e1',
            ],

            ...columnChartSeriesOptions,
        } as ApexOptions
    ), [columnChartSeriesOptions]);

    // Compile all the options we want to use for our graph.
    const barChartOptions = useMemo(() => (
        {
            chart: {
                toolbar: {
                    show: false,
                },
            },

            dataLabels: {
                enabled: true,
            },

            plotOptions: {
                bar: {
                    labels: {
                        show: true,
                        total: {
                            show: true,
                            color: '#000000',
                        }
                    },
                    horizontal: true
                }
            },

            colors: [
                '#43A0FC',
                '#e6e1e1',
            ],

            ...barChartSeriesOptions,
        } as ApexOptions
    ), [barChartSeriesOptions]);

    const pieChartKey = useMemo(() => JSON.stringify(pieChartOptions), [pieChartOptions]);
    const columnChartKey = useMemo(() => JSON.stringify(columnChartOptions), [columnChartOptions]);
    const barChartKey = useMemo(() => JSON.stringify(barChartOptions), [barChartOptions]);


    return (
        <div className="question-area" style={{ marginBottom: 2 }}>
            <Col style={{ marginLeft: 5 }}>
                <Row>
                    <b> {t('draggableSectionComponent.question', 'Question')} </b>
                </Row>
                <Row style={{marginRight: "10px"}}>
                    {question.questionText}
                </Row>
                {
                    sectionListItem.learners.filter(learner => learner.learnerResponses.filter(response => response.questionId === question.id).length > 0).map((learner, learnerIndex) => (
                        <div key={learnerIndex}>
                            <Row>
                                <Col>
                                    <b>{t('draggableSectionComponent.name', 'Name: ')} </b>
                                    {learner.name}
                                </Col>
                            </Row>
                            {
                                learner.learnerResponses.filter(item => item.questionId === question.id).map((response, responseIndex) => (
                                    <div key={responseIndex}>
                                        <ConditionalFragment showIf={question.responseType !== "ComparisonScale"}>
                                            <Row>
                                                <Col>
                                                    <b>{t('draggableSectionComponent.response', 'Response: ')} </b>
                                                    {response.responseText}
                                                </Col>
                                            </Row>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={question.responseType === "ComparisonScale"}>
                                            <ConditionalFragment showIf={learner.learnerResponses.find(item => item.questionId === question.id && item.displayOrder > response.displayOrder) !== undefined }>
                                                <Row>
                                                    <Col>
                                                        <b>{t('draggableSectionComponent.before', 'Before: ')} </b>
                                                        {response.responseText}
                                                    </Col>
                                                </Row>
                                            </ConditionalFragment>
                                            <ConditionalFragment showIf={learner.learnerResponses.find(item => item.questionId === question.id && item.displayOrder < response.displayOrder) !== undefined}>
                                                <Row>
                                                    <Col>
                                                        <b>{t('draggableSectionComponent.after', 'After: ')} </b>
                                                        {response.responseText}
                                                    </Col>
                                                </Row>
                                            </ConditionalFragment>
                                        </ConditionalFragment>
                                    </div>
                                ))
                            }
                            <hr />
                        </div>
                    ))
                }
            </Col>
            <ConditionalFragment showIf={sectionListItem.learners.filter(item => item.learnerResponses.filter(it => it.questionId === question.id && it.numberResponse !== 0 && it.numberResponse !== undefined).length > 0 ).length > 0 && graphType === ""}>
                <Row style={{ justifyContent: "center", marginBottom: 5 }}>
                    <Button color="primary" onClick={() => toggleIsGraphModalOpen()}>
                        <> {t('draggableSectionComponent.create-graph', 'Create Graph')}</>
                    </Button>
                </Row>
            </ConditionalFragment>
            <ConditionalFragment showIf={graphType !== ""}>
                <Row style={{ marginRight: "5px "}}>
                    <Col style={{ textAlign: 'right' }}>
                        <b className="sub-title" style={{ cursor: "pointer"}} onClick={() => setGraphType("")}>X</b>
                    </Col>
                </Row>
            </ConditionalFragment>

            <ConditionalFragment showIf={graphType === "Pie Chart"}>
                <Chart key={pieChartKey} options={pieChartOptions} series={pieChartSeries} align="center" width="70%" type="pie"></Chart>
            </ConditionalFragment>

            <ConditionalFragment showIf={graphType === "Column Chart"}>
                <Chart key={columnChartKey} options={columnChartOptions} series={columnChartSeries} align="center" width="70%" type="bar"></Chart>
            </ConditionalFragment>

            <ConditionalFragment showIf={graphType === "Bar Chart"}>
                <Chart key={barChartKey} options={barChartOptions} series={barChartSeries} align="center" width="70%" type="bar"></Chart>
            </ConditionalFragment>

            <ConditionalFragment showIf={isGraphModalOpen}>
                <SelectGraphTypeModal
                    isOpen={isGraphModalOpen}
                    toggle={toggleIsGraphModalOpen}
                    setGraphType={setGraphType}
                />
            </ConditionalFragment>
        </div>
    );
}