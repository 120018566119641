import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { Button, ButtonGroup, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import { Question } from "../../api/main/models/Question";
import { Questionnaire } from "../../api/main/models/Questionnaire";
import { QuestionnaireRespondentSession } from "../../api/main/models/QuestionnaireRespondentSession";
import { QuestionnaireSection } from "../../api/main/models/QuestionnaireSection";
import { QuestionResponse } from "../../api/main/models/QuestionResponse";
import "./dashboardQuestionnaireCard.scss";
import { useQuestionnaireCompletionPercentage } from "./utilities/useQuestionnaireCompletionPercentage";
import "../shared/cardsOrTable/cardsOrTable.scss";
import "../shared/cardsOrTable/cardsOrTableImpacts.scss";
import { useHistory } from "react-router";
import { useMemo } from "react";
import "../../styles/_colors.scss";
//import { useCurrentUserId } from "../../api/account";
//import { useToggleStateArray } from "use-toggle-state";
import { QuestionnaireType } from "../../api/main/models/codeOnly/QuestionnaireType";

export interface DashboardQuestionnaireCardProps {
    questionnaire: Questionnaire,
    questionnaireSections: QuestionnaireSection[],
    questions: Question[],
    questionnaireRespondentSession: QuestionnaireRespondentSession,
    questionResponses: QuestionResponse[],
    cardClickLink: string,
}

/**
 * Card for launching a questionnaire.
 * @param props
 */
export const DashboardQuestionnaireCard = (props: DashboardQuestionnaireCardProps) => {
    const {
        questionnaire,
        questionnaireSections,
        questions,
        questionnaireRespondentSession,
        questionResponses,
        cardClickLink,
    } = props;
    const { t } = useTranslation();

    const history = useHistory();

    const percentageCompleted = useQuestionnaireCompletionPercentage(questions ?? [], questionnaireRespondentSession, questionResponses);

    //const currentUserId = useCurrentUserId();

    //if percentage completed is 0 add padding to progress bar.
    const isPaddingNeeded = useMemo(() => {
        if (percentageCompleted === 0) {
            return { paddingLeft: '5px' }
        }
        return;
    }, [percentageCompleted]);

    //change due by text colour depending on how long till due date
    const dueDateColor = useMemo(() => {

        const now = moment().toISOString();
        const dueDateIso = moment(questionnaire.endDate).toISOString();
        const dueInTwoDaysDateIso = moment(dueDateIso).add(-2, 'days').toISOString();
        const dueInAWeekDateIso = moment(dueDateIso).add(-7, 'days').toISOString();

        
        // If we will become due within the next two days (or are overdue) then red.
        if (now >= dueInTwoDaysDateIso) {
            return 'overdue';
        }

        // If we will become due within the next seven days then orange.
        if (now >= dueInAWeekDateIso) {
            return 'close-to-end-date';
        }

        // Otherwise return a netural colour
        return 'not-close-to-end-date';
    }, [questionnaire]);

    const questionnaireNotStarted = moment(questionnaire.startDate) > moment();

    //const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();

    return (
        <Col xs={12} sm={6} lg={3}>
            {/* cards-or-table classes are used here to get a consistent look and feel with real tables in card mode */}
            <Card className={`cards-or-table-card dashboard-questionnaire-card type-${questionnaire.questionnaireType}`}>
                <CardBody onClick={item => { if (!questionnaireNotStarted) { history.push(cardClickLink) } }} style={!questionnaireNotStarted ? { cursor: 'pointer' } : { cursor: 'unset' }}>
                    <h5 className="dashboard-questionnaire-card-title">
                        {questionnaire.name.substring(0, 70) + (questionnaire.name.length > 70 ? '...' : '')}
                    </h5>
                    <ConditionalFragment showIf={!questionnaireRespondentSession.endDate}>
                        <Row>
                            <Col className="card-questionnaire-description">
                                {questionnaire.description.substring(0, 150) + (questionnaire.description.length > 150 ? '...' : '')}
                            </Col>
                        </Row>
                    </ConditionalFragment>
                    <Row>
                        <Col>
                            <>{t('dashboardQuestionnaireCard.numberOfQuestions', `Sections: ${questionnaireSections.length} - Questions: ${questions.length}`)}</>
                        </Col>
                    </Row>
                    <ConditionalFragment showIf={!questionnaireRespondentSession.endDate}>
                        <div className="progress mt-2">
                            <div className="progress-bar" role="progressbar" aria-valuenow={percentageCompleted}
                                aria-valuemin={0} aria-valuemax={100} style={{ width: `${percentageCompleted}%` }}>
                                <span style={isPaddingNeeded}>{`${percentageCompleted}%`} {t('dashboardQuestionnaireCard.percentageComplete', 'Complete')}</span>
                            </div>
                        </div>
                    </ConditionalFragment>
                    <Row>
                        <Col className="mt-2">
                            {
                                !!questionnaireRespondentSession?.endDate ? (
                                    <span className={"completed"}>
                                        <FontAwesomeIcon icon="flag-checkered" />
                                        <> </>
                                        {t('dashboardQuestionnaireCard.finishedOn', 'Completed on: {{date, DD/MM/YYYY}}', { date: moment(questionnaireRespondentSession?.endDate).local() })}
                                    </span>
                                ) : (!questionnaireNotStarted && questionnaire.questionnaireType === QuestionnaireType.Campaign) ? (
                                    <span className={dueDateColor}>
                                        <FontAwesomeIcon icon="bell" />
                                        <> </>
                                            {/* subtract 1 from now to compare dates because it is not overdue if due today */}
                                        <ConditionalFragment showIf={moment().add(-1, 'days').toISOString() > moment(questionnaire.endDate).toISOString()}>
                                            {t('dashboardQuestionnaireCard.overDueLabel', 'Overdue :')}
                                        </ConditionalFragment>
                                        <> </>
                                        {t('dashboardQuestionnaireCard.dueBy', 'Due by: {{date, DD/MM/YYYY}}', { date: (moment(questionnaire.endDate).format("L")) })}
                                    </span>
                                    ) : (!!questionnaireNotStarted && questionnaire.questionnaireType === QuestionnaireType.Campaign)? (
                                        <span className={"overdue"}>
                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                        <> </>
                                        {t('dashboardQuestionnaireCard.CampaignNotStarted', 'This campaign has not started, start date: {{date, DD/MM/YYYY}}', { date: (moment(questionnaire.startDate).format("L")) })}
                                    </span>
                                ) : null
                            }
                        </Col>
                    </Row>
                </CardBody>
                <CardFooter>
                    <div className="dashboard-questionnaire-card-actions">
                        <Row noGutters>
                            <ConditionalFragment showIf={(!questionnaireNotStarted && questionnaire.questionnaireType === QuestionnaireType.Campaign) || (questionnaire.questionnaireType === QuestionnaireType.SelfService)}>
                                <ConditionalFragment showIf={!questionResponses.length}>
                                    <ButtonGroup>
                                        <LinkContainer to={`/home/questionnaire/start/${questionnaire.id}`}>
                                            <Button color="primary" className="btn-questionnaire">
                                                <FontAwesomeIcon icon="play" />
                                                <> </>
                                                {
                                                    t('dashboardQuestionnaireCard.begin', 'Begin')
                                                }
                                            </Button>
                                        </LinkContainer>
                                    </ButtonGroup>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!questionResponses.length && !questionnaireRespondentSession.endDate}>
                                    <ButtonGroup>
                                        <LinkContainer to={`/home/questionnaire/continue/${questionnaire.id}`}>
                                            <Button color="primary" className="btn-questionnaire">
                                                <FontAwesomeIcon icon="arrow-alt-circle-right" />
                                                <> </>
                                                {
                                                    t('dashboardQuestionnaireCard.continue', 'Continue')
                                                }
                                            </Button>
                                        </LinkContainer>
                                    </ButtonGroup>
                                </ConditionalFragment>
                            </ConditionalFragment>
                            <ConditionalFragment showIf={!!questionnaireRespondentSession.endDate}>
                                <ButtonGroup>
                                    <LinkContainer to={`/home/questionnaire/view/${questionnaire.id}`}>
                                        <Button color="primary" className="btn-questionnaire">
                                            <FontAwesomeIcon icon="eye" />
                                            <> </>
                                            {
                                                t('dashboardQuestionnaireCard.view', 'View')
                                            }
                                        </Button>
                                    </LinkContainer>
                                {/*    <ButtonDropdown isOpen={isMenuOpen(questionnaire.id)} toggle={() => toggleMenuOpen(questionnaire.id)}>*/}
                                {/*        <DropdownToggle className={"btn-outline-questionnaire"} outline caret>*/}
                                {/*            <span className="sr-only">{t('common.menuDropdown', 'More')}</span>*/}
                                {/*        </DropdownToggle>*/}
                                {/*        <DropdownMenu>*/}
                                {/*            <LinkContainer to={`/me/view/report/${questionnaire.id}/user/${currentUserId}`}>*/}
                                {/*                <DropdownItem className={`btn-outline-questionnaire`}>*/}
                                {/*                    <FontAwesomeIcon icon="file-alt" />*/}
                                {/*                    <> </>*/}
                                {/*                    {*/}
                                {/*                        t('dashboardQuestionnaireCard.report', 'View Report')*/}
                                {/*                    }*/}
                                {/*                </DropdownItem>*/}
                                {/*            </LinkContainer>*/}
                                {/*        </DropdownMenu>*/}
                                {/*    </ButtonDropdown>*/}
                                </ButtonGroup>
                            </ConditionalFragment>
                        </Row>
                    </div>
                </CardFooter>
            </Card>
        </Col>
    );
};