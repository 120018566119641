import { Button, ButtonGroup, Col, FormText, } from 'reactstrap';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useCallback, useMemo } from 'react';

export interface QuickDateButtonsProps {
    handleQuickDateButtonClick: (adjustmentMoment: Moment) => void,
    helpMessage?: string,
    includeButtons?: string[],
    compareDate?: Moment,
    handleQuickDateButtonBlur?: () => void,
}

/**
 * Quick date entry buttons
 * @param props
 */
export const QuickDateButtons = (props: QuickDateButtonsProps) => {
    const {
        handleQuickDateButtonClick,
        helpMessage,
        includeButtons = ['0Day', '1Day', 'NextMonday', '1Month', '2Months'],
        compareDate,
        handleQuickDateButtonBlur,
    } = props;

    const { t } = useTranslation();

    const workingDate = useMemo(() => {
        return !compareDate ? moment() : compareDate;
    }, [compareDate,]);

    const getButton = useCallback((buttonName: string) => {
        switch (buttonName) {
            case '0Day':
                return (
                    <Button key={buttonName} type="button" color="primary" outline
                        onClick={e => handleQuickDateButtonClick(moment(workingDate))}
                        onBlur={e => !!handleQuickDateButtonBlur ? handleQuickDateButtonBlur() : null}
                    >
                        {!compareDate ? t('quickDateButtons.today', 'Launch Now') : t('quickDateButtons.sameDay', 'Same day')}
                    </Button>
                )
            case '1Day':
                return (
                    <Button key={buttonName} type="button" color="primary" outline
                        onClick={e => handleQuickDateButtonClick(moment(workingDate).add(1, "day"))}
                        onBlur={e => !!handleQuickDateButtonBlur ? handleQuickDateButtonBlur() : null}
                    >
                        {!compareDate ? t('quickDateButtons.tomorrow', 'Tomorrow') : t('quickDateButtons.oneDay', '1 day')}
                    </Button>
                )
            case 'NextMonday':
                return (
                    <Button key={buttonName} type="button" color="primary" outline
                        onClick={e => handleQuickDateButtonClick(moment(workingDate).day(8))}
                        onBlur={e => !!handleQuickDateButtonBlur ? handleQuickDateButtonBlur() : null}
                    >
                        {!compareDate ? t('quickDateButtons.nextMonday', 'Next Monday') : t('quickDateButtons.followingMonday', 'Following Monday')}
                    </Button>
                )
            case '1Month':
                return (
                    <Button key={buttonName} type="button" color="primary" outline
                        onClick={e => handleQuickDateButtonClick(moment(workingDate).add(1, "months"))}
                        onBlur={e => !!handleQuickDateButtonBlur ? handleQuickDateButtonBlur() : null}
                    >
                        {!compareDate ? t('quickDateButtons.1MonthFromNow', '1 month from now') : t('quickDateButtons.oneMonth', '1 month')}
                    </Button>
                )
            case '2Months':
                return (
                    <Button key={buttonName} type="button" color="primary" outline
                        onClick={e => handleQuickDateButtonClick(moment(workingDate).add(2, "months"))}
                        onBlur={e => !!handleQuickDateButtonBlur ? handleQuickDateButtonBlur() : null}
                    >
                        {!compareDate ? t('quickDateButtons.2MonthsFromNow', '2 months from now') : t('quickDateButtons.twoMonths', '2 months')}
                    </Button>
                )
            default:
                return null;
        }
    }, [handleQuickDateButtonClick, compareDate, workingDate, handleQuickDateButtonBlur, t]);


    return (
        <>
            <Col xs="auto">
                <ButtonGroup className="quickdate-button-group">
                    {
                        includeButtons.map(button => (
                            getButton(button)
                        ))
                    }
                </ButtonGroup>
            </Col>
            <ConditionalFragment showIf={!!helpMessage}>
                <Col xs={12}>
                    <FormText>
                        {helpMessage}
                    </FormText>
                </Col>
            </ConditionalFragment>
        </>
    );
};