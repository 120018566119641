import { gql, useMutation } from "@apollo/client";
import { useAsyncCallback } from "react-use-async-callback";
import { AsyncActionStatus } from "../../../shared/abstractStore";
import { deleteQuestionnaireAnalysisTypeLinkMutation, deleteQuestionnaireAnalysisTypeLinkMutationVariables } from "../generated/deleteQuestionnaireAnalysisTypeLinkMutation";
import { questionnaireAnalysisTypeLinkFieldsFragment } from "../models/QuestionnaireAnalysisTypeLink";



export function useDeleteQuestionnaireAnalysisTypeLinkCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteQuestionnaireAnalysisTypeLinkMutation, deleteQuestionnaireAnalysisTypeLinkMutationVariables>(
        gql`
            mutation deleteQuestionnaireAnalysisTypeLinkMutation ($id: ID!) {
                deleteQuestionnaireAnalysisTypeLink(id: $id) {
                    ...questionnaireAnalysisTypeLinkFields
                }
            }

            ${questionnaireAnalysisTypeLinkFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}