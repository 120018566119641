import './layout.scss';

import * as React from 'react';
//import { AppBreadcrumb } from '@coreui/react';
import { Header } from './Header';

import { Sidebar } from './Sidebar';
import { LoadingIndicator } from '../../components/shared/LoadingIndicator';

import { RouteEntry } from '../../shared/routes';
import { ConditionalFragment } from 'react-conditionalfragment';

export interface LayoutProps {
    appRoutes: Array<RouteEntry>,
    children: React.ReactNode
}

export const Layout = (props: LayoutProps) => {

    const isAccountScreen = React.useMemo(() => {
        let pathName = window.location.pathname;
        if (pathName.toLowerCase().includes('/account')) {
            return true;
        }
        return false;

    }, []);

    return (
        <div className={`app ${isAccountScreen ? 'account-type-page': ''}`}>
            <ConditionalFragment showIf={!isAccountScreen}>
                <Header />
            </ConditionalFragment>
            <div className="app-body">
                <Sidebar />
                <main className="main">
                    {/*<AppBreadcrumb appRoutes={props.appRoutes} />*/}
                    <React.Suspense fallback={<LoadingIndicator />}>
                        {props.children}
                    </React.Suspense>
                </main>
            </div>
        </div>
    );
};
