import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

/**
 * Manage navigation menu for the app. 
 */
export const ManageNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/manage/subscriptionDepartments'}>
                        <FontAwesomeIcon icon="briefcase" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.departments', 'Departments')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/manage/users/'}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.people', 'People')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/manage/analysisTypes/'}>
                        <FontAwesomeIcon icon="chart-bar" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.analysis', 'Analysis')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/manage/subscriptions/edit/branding'}>
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.branding', 'Branding')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={'/manage/subscriptions/edit/templateLinks'}>
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <> </>
                        {t('manageNavigation.branding', 'Fixed Templates')}
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};