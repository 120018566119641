import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { questionnaireOverviewViewModelQuery } from '../../generated/questionnaireOverviewViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { analysisTypeFieldsFragment } from '../../models/AnalysisType';
import { questionnaireSectionFieldsFragment } from '../../models/QuestionnaireSection';
import { questionFieldsFragment } from '../../models/Question';
import { questionResponseFieldsFragment } from '../../models/QuestionResponse';
import { questionnaireFieldsFragment } from '../../models/Questionnaire';
import { questionnaireRespondentSessionFieldsFragment } from '../../models/QuestionnaireRespondentSession';
import { profileFieldsFragment } from '../../models/Profile';
import { questionnaireAnalysisTypeLinkFieldsFragment } from '../../models/QuestionnaireAnalysisTypeLink';
import { learningUnitFieldsFragment } from '../../models/LearningUnit';
import { subscriptionFieldsFragment } from '../../models/Subscription';

export interface QuestionnaireOverviewViewModelAsyncLoadOptions extends AsyncLoadOptions {
    subscriptionId?: string | null | undefined,
    questionnaireId?: string | null | undefined,
    includeNonUser?: boolean | null | undefined,
}

/**
 * Supporting data for the questionnaire overview screen.
 * @param options
 */
export function useQuestionnaireOverviewViewModel(options: QuestionnaireOverviewViewModelAsyncLoadOptions = {}): AsyncLoadResult<questionnaireOverviewViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<questionnaireOverviewViewModelQuery>(
        gql`
        query questionnaireOverviewViewModelQuery ($subscriptionId: ID!, $questionnaireId: ID!, $includeNonUser: Boolean) {

            model: subscription(id: $subscriptionId) {
                ...subscriptionFields
            }

            questionnaire: questionnaire(id: $questionnaireId) {
                ...questionnaireFields

                questionnaireRespondentSessions: questionnaireRespondentSessions(questionnaireId: $questionnaireId) {
                    ...questionnaireRespondentSessionFields
                }

            }

            questionnaireSections (questionnaireId: $questionnaireId) {
                ...questionnaireSectionFields
            }

            questions (questionnaireId: $questionnaireId) {
                ...questionFields
            }

            questionnaireRespondentSessions (subscriptionId: $subscriptionId, questionnaireId: $questionnaireId) {
                ...questionnaireRespondentSessionFields
            }


            questionResponses (questionnaireId: $questionnaireId, includeNonUser: $includeNonUser) {
                ...questionResponseFields
            }

            analysisTypes(subscriptionId: $subscriptionId) {
                ...analysisTypeFields
            }

            questionnaireAnalysisTypeLinks(subscriptionId: $subscriptionId, questionnaireId: $questionnaireId) {
                ...questionnaireAnalysisTypeLinkFields
            }

            learningUnit: learningUnit(questionnaireId: $questionnaireId){
                ...learningUnitFields
            }

        }

        ${subscriptionFieldsFragment}
        ${questionnaireFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${questionnaireSectionFieldsFragment}
        ${questionFieldsFragment}
        ${questionResponseFieldsFragment}
        ${analysisTypeFieldsFragment}
        ${questionnaireAnalysisTypeLinkFieldsFragment}
        ${learningUnitFieldsFragment}
        `,
        {
            variables: {
                subscriptionId: options.subscriptionId,
                questionnaireId: options.questionnaireId,
                includeNonUser: options.includeNonUser
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}