import './fileUploadButton.scss';

import * as React from 'react';
import { Label, Input } from 'reactstrap';

export interface FileUploadButtonProps {
    color?: string,
    outline?: boolean,
    onUpload?: (files: FileList | null) => void,

    isExecuting?: boolean;
    executingChildren?: React.ReactNode;

    className?: string,
    style?: React.CSSProperties,
    children?: React.ReactNode,
}

/**
 * Button for uploading files without showing the file input of the browser.
 */
export const FileUploadButton = (props: FileUploadButtonProps) => {
    const {
        color = 'primary',
        outline = true,
        className,
        style,
        children,
        onUpload,
        isExecuting,
        executingChildren,
    } = props;

    return (
        <Label className={`file-upload-button btn btn-${outline ? 'outline-' : ''}${color} ${className}`} style={style}>
            <div className="file-upload-button-children">
                {
                    isExecuting ? executingChildren
                        : children
                }
            </div>

            <Input className="file-upload-button-input" type="file" name="files"
                onChange={e => {
                    if (onUpload) {
                        onUpload(e.currentTarget.files);
                    }
                }} />
        </Label>
        );
};
