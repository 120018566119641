import { useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { useParams } from "react-router";
import { UserProgressDashboardTab } from "../tabs/UserProgressDashboardTab";
import { useAdministrationDashboardViewModel } from "../../../api/main/dashboard/viewModels/useAdministrationDashboardViewModel";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { useSessionsForQuestionnaireType } from "../utilities/useSessionsForQuestionnaireType";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";

export interface StaffUserDashboardProps {
    userId?: string,
}

/**
 * Dashboard for a skillshub staff member/administrator.
 */
export const StaffUserDashboard = (props: StaffUserDashboardProps) => {
    const {
        userId: userIdProp,
    } = props;

    // Work out the user to show for.
    const { userId: userIdParam } = useParams<{ userId: string | undefined }>();
    const userId = userIdProp ?? userIdParam;

    const { t } = useTranslation();

    const {
        data: {
            profiles: allProfiles,
            questionnaires,
            questionnaireRespondentSessions,
        },
        isLoading,
        errors: loadErrors
    } = useAdministrationDashboardViewModel();

    const profile = useMemo(() => allProfiles?.find(it => it.userId === userId), [allProfiles, userId]);

    // get the selfService sesssions filtered down to this user
    const selfServiceSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.SelfService).filter(item => item.userId === userId);

    // get the campaign sesssions filtered down to this user
    const campaignSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.Campaign).filter(item => item.userId === userId);

    // get questions for a questionnaire
    const getQuestions = useCallback((questionnaireId: string) => {
        const questionnaire = questionnaires.find(item => item.id === questionnaireId);
        return questionnaire?.questions ?? [];
    }, [questionnaires]);

    // get reponses for a questionnaire
    const getResponses = useCallback((questionnaireId: string) => {
        const session = questionnaireRespondentSessions.find(item => item.questionnaireId === questionnaireId && item.userId === userId);
        return session?.responses ?? [];
    }, [questionnaireRespondentSessions, userId]);

    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerNoPills"}>
                    <Col xs="">
                        <h1>
                            <DashboardBackButton />
                            {
                                t('staffUserDashboard.heading', '{{firstName}} {{lastName}} dashboard', profile)
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid>
                <AlertOnErrors errors={[loadErrors]} />

                <UserProgressDashboardTab
                    questionnaires={questionnaires ?? []}
                    getQuestions={getQuestions}
                    getResponses={getResponses}
                    campaignSessions={campaignSessions}
                    selfServiceSessions={selfServiceSessions}
                />
            </Container>
        </Background>
    );
};
