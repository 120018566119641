import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, NavItem, NavLink, Row } from "reactstrap";
import { useSubscriptionDashboardViewModel } from "../../../api/main/dashboard/viewModels/useSubscriptionDashboardViewModel";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { PillsNavBar } from "../../shared/pillsNavBar/PillsNavBar";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { useParams } from "react-router";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useActiveDashboardTab } from "../../../globalState/activeDashboardTab/useActiveDashboardTab";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { SubscriptionUsersDashboardTab } from "../tabs/SubscriptionUsersDashboardTab";
import { SubscriptionDepartmentsDashboardTab } from "../tabs/SubscriptionDepartmentsDashboardTab";
import { useSessionsForQuestionnaireType } from "../utilities/useSessionsForQuestionnaireType";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { useCallback, useMemo } from "react";
import { administrationDashboardViewModelQuery_questionnaireRespondentSessions } from "../../../api/main/generated/administrationDashboardViewModelQuery";
import { Question } from "../../../api/main/models/Question";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { TypeSelectionPills } from "../typeSelectionPills/TypeSelectionPills";

export type SubscriptionDashboardTabs = 'departments' | 'people';
export type SubscriptionQuestionnaireTypeTabs = 'all' | 'campaigns' | 'selfServices';

/**
 * Dashboard for a subscription.
 */
export const SubscriptionDashboard = () => {
    // Work out the subscription to show for.
    const { subscriptionId: subscriptionIdParam } = useParams<{ subscriptionId: string | undefined }>();
    const mySubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const subscriptionId = subscriptionIdParam ?? mySubscriptionId;

    const { t } = useTranslation();

    const {
        data: {
            model: subscription,
            subscriptionDepartments,
            profiles,
            questionnaires,
            questionnaireRespondentSessions,
        },
        isLoading,
        errors: loadErrors
    } = useSubscriptionDashboardViewModel(subscriptionId);


    // Track the active tab.
    const [activeTab, setActiveTab] = useActiveDashboardTab<SubscriptionDashboardTabs>('subscriptionDashboard', 'departments');
    const [activeQuestionnaireType, setActiveQuestionnaireType] = useActiveDashboardTab<SubscriptionQuestionnaireTypeTabs>('subscriptionDashboard2', 'all');
    // get the selfService sesssions
    const selfServiceSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.SelfService);

    // get the campaign sesssions
    const campaignSessions = useSessionsForQuestionnaireType(questionnaires, questionnaireRespondentSessions, QuestionnaireType.Campaign);

    // sort out which sessions to include in graphs
    const graphSessions = useMemo(() => {
        if (activeQuestionnaireType === 'campaigns') {
            return campaignSessions;
        } else if (activeQuestionnaireType === 'selfServices') {
            return selfServiceSessions;
        } else {
            return campaignSessions.concat(selfServiceSessions);
        }
    }, [activeQuestionnaireType, campaignSessions, selfServiceSessions]);

    // get all responses
    const questionnaireTypeResponses = useCallback((requiredSesssions: administrationDashboardViewModelQuery_questionnaireRespondentSessions[]) => {
        if (!requiredSesssions.length) {
            return [];
        }

        let ret: Array<QuestionResponse> = [];
        for (let session of requiredSesssions) {
            for (let response of session.responses) {
                ret.push(response);
            }
        }

        return ret;
    }, []);

    // get the questions 
    const questions = useMemo(() => {
        if (!questionnaires) {
            return [];
        }

        let allQuestions: Array<Question> = [];

        for (let questionnaire of questionnaires) {
            for (let question of questionnaire.questions) {
                allQuestions.push(question);
            }
        }
        return allQuestions;
    }, [questionnaires,]);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs="d-none" sm={4} lg={3}>
                    </Col>
                    <Col xs={12} sm={''}>
                        <PillsNavBar textColor="primary" className="mb-2">
                            <NavItem>
                                <NavLink active={activeTab === 'departments'} onClick={() => setActiveTab('departments')}>
                                    <FontAwesomeIcon icon="users" />
                                    <> </>
                                    {t('subscriptionDashboard.tabs.departments', 'Departments')}
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink active={activeTab === 'people'} onClick={() => setActiveTab('people')}>
                                    <FontAwesomeIcon icon="user" />
                                    <> </>
                                    {t('subscriptionDashboard.tabs.people', 'People')}
                                </NavLink>
                            </NavItem>
                        </PillsNavBar>
                    </Col>
                    <Col xs={12} sm={4} lg={3}>
                        <TypeSelectionPills
                            activeQuestionnaireType={activeQuestionnaireType}
                            setActiveQuestionnaireType={setActiveQuestionnaireType}
                        />
                    </Col>                 
                </Row>
                <Row className={"bannerWithPills"}>
                    <Col>
                        <h1>
                            <DashboardBackButton />
                            {
                                // If we are showing for our own (or emulated) subscription just show "Dashboard" as the name.
                                !!subscriptionIdParam ? t('subscriptionDashboard.heading.withSubscriptionName', '{{name}} dashboard', { name: subscription?.name })
                                    : t('subscriptionDashboard.heading.default', 'Dashboard')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid className="main-container">
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={activeTab === 'people' && profiles?.length > 0}>
                    <SubscriptionUsersDashboardTab
                        profiles={profiles ?? []}
                        campaignSessions={campaignSessions}
                        selfServiceSessions={selfServiceSessions}
                        graphSessions={graphSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        showSubscriptionName={false}
                        subscriptionDepartments={subscriptionDepartments ?? []}
                        questions={questions}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={activeTab === 'departments' && profiles?.length > 0}>
                    <SubscriptionDepartmentsDashboardTab
                        subscriptionDepartments={subscriptionDepartments ?? []}
                        profiles={profiles ?? []}
                        campaignSessions={campaignSessions}
                        selfServiceSessions={selfServiceSessions}
                        graphSessions={graphSessions}
                        campaignResponses={questionnaireTypeResponses(campaignSessions)}
                        selfServiceResponses={questionnaireTypeResponses(selfServiceSessions)}
                        activeQuestionnaireType={activeQuestionnaireType}
                        showSubscriptionName={false}
                        questions={questions}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={profiles?.length === 0}>
                    <NoResultsFound />
                </ConditionalFragment>

            </Container>
        </Background>
    );
};
