import './userNav.scss';

import * as React from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem, Dropdown, NavItem, Button } from 'reactstrap';
import Avatar from 'react-avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinkContainer } from 'react-router-bootstrap';
import { useAuthenticatedState } from '../../api/api-authorization/useAuthenticatedState';
import { useTranslation } from 'react-i18next';
import { useToggleState } from 'use-toggle-state';

/**
 * Navbar area that is for the current user. 
 */
export const UserNav = () => {
    const { isAuthenticated, user } = useAuthenticatedState({ includeUser: true });
    const [dropdownOpen, toggleDropdown] = useToggleState();

    const { t } = useTranslation();

    // Render the UI
    //

    // If the user is not logged in, then give them the login and register options.
    if (!isAuthenticated) {
        return (
            <>
                {/*<NavItem>
                    <LinkContainer className="user-nav-register-button" to="/account/register">
                        <Button color="primary">
                            {t('userNav.register', 'Sign up for free')}
                        </Button>
                    </LinkContainer>
                </NavItem>*/}
                <NavItem>
                    <LinkContainer to="/authentication/login">
                        <Button className="user-nav-login-button" color="primary">
                            {t('userNav.signIn', 'Sign in')}
                        </Button>
                    </LinkContainer>
                </NavItem>
            </>
        );
    }

    // User is logged in so show their options.
    return (
        <Dropdown direction="down" isOpen={dropdownOpen} toggle={() => toggleDropdown()} nav>
            <DropdownToggle className={"signin-nav"} nav>
                <Avatar size="32" round name={user?.name ?? ''} email={user?.email ?? user?.preferred_username ?? ''} />
                <span className="sr-only">
                    {user?.name ?? ''}
                </span>
            </DropdownToggle>
            <DropdownMenu right>
                <DropdownItem header tag="div" className="text-center"><strong>{t('userNav.account', 'Account')}</strong></DropdownItem>
                <LinkContainer to={'/profile'}>
                    <DropdownItem>
                        <FontAwesomeIcon icon="user" />
                        <> {t('userNav.profile', 'Profile')}</>
                    </DropdownItem>
                </LinkContainer>
                <DropdownItem divider />
                <LinkContainer to={{ pathname: '/authentication/logout', state: { local: true } } /* Pass extra state so we know the request has been requested properly by the page. */}>
                    <DropdownItem>
                        <FontAwesomeIcon icon="lock" />
                        <> {t('userNav.signOut', 'Sign out')}</>
                    </DropdownItem>
                </LinkContainer>
            </DropdownMenu>
        </Dropdown>
    );
};
