import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import {  ButtonGroup, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Spinner } from "reactstrap";
import { useToggleState } from "use-toggle-state";
import { BlobReference } from "../../../api/main/models/BlobReference";
import { Subscription } from "../../../api/main/models/Subscription";
import { FileUploadButton } from "../../shared/fileUploadButton/FileUploadButton";
import { UploadedImagePreview } from "../../shared/uploadedImagePreview/UploadedImagePreview";

export interface ImageTabProps {
    model: Subscription | undefined,

    imageBlob: BlobReference | undefined | null,
    uploadImageBlob: (files: FileList | null) => void,
    isUploadingImageBlob: boolean,
    clearImageBlob: () => void,

}

/**
 * Image upload tab for EditQuestion.
 * @param props
 */
export const ImageTab = (props: ImageTabProps) => {
    const {
        //model,
        imageBlob, uploadImageBlob, isUploadingImageBlob, clearImageBlob,
    } = props;

    const { t } = useTranslation();

    const [isImageDropdownOpen, toggleImageDropdown] = useToggleState();

    return (
        <>
            <FormGroup>
                <Label htmlFor="brandImageBlobReferenceId">{t('imageTab.brandImageBlobReferenceId', 'Logo images with a width of 748px and a height of 266px are best')}</Label>

                <UploadedImagePreview src={imageBlob?.url ?? ''} />

                <ButtonGroup>
                    <FileUploadButton onUpload={files => uploadImageBlob(files)} isExecuting={isUploadingImageBlob}
                        executingChildren={<><Spinner size="sm" /><> </>{t('common.uploading', 'Uploading...')}</>}
                    >
                        {t('imageTab.uploadImageImage', 'Upload logo...')}
                    </FileUploadButton>

                    <Dropdown isOpen={isImageDropdownOpen} toggle={() => toggleImageDropdown()}>
                        <DropdownToggle color="primary" outline caret>
                            <span className="sr-only">
                                {t('common.more', 'More')}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu right>
                            <DropdownItem className="text-danger" onClick={clearImageBlob}>
                                <FontAwesomeIcon icon="trash" />
                                <> </>
                                {t('imageTab.clearImage', 'Clear logo')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </ButtonGroup>
            </FormGroup>
        </>
        );
};