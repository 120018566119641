import { Col, CustomInput, FormGroup, FormText, Label, Row } from 'reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { useTranslation } from 'react-i18next';
import moment, { Moment } from 'moment';
import { QuickDateButtons } from '../shared/QuickDateButtons';
import { Questionnaire } from '../../../api/main/models/Questionnaire';
import { ValidatedISODateTimeInput } from '../../shared/ValidatedISODateTimeInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export interface questionnaireTimescalesDetailsProps {
    model?: Questionnaire,
    handleCampaignStartDateQuickDateButtonClick: (adjustmentMoment: Moment) => void,
    handleCampaignEndDateChange: (adjustmentMoment: Moment) => void,
    change: (changes: Partial<Questionnaire>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    handleCampaignStartDateQuickDateButtonBlur: () => void,
    handleCampaignEndDateQuickDateButtonBlur: () => void,
}

/**
 * learningUnit fields for a questionnaire (optional)
 * @param props
 */
export const QuestionnaireTimescalesDetails = (props: questionnaireTimescalesDetailsProps) => {
    const {
        model,
        handleCampaignStartDateQuickDateButtonClick,
        handleCampaignEndDateChange,
        change,
        validate,
        validationErrors,
        handleCampaignStartDateQuickDateButtonBlur,
        handleCampaignEndDateQuickDateButtonBlur,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label htmlFor="startDate">{t('questionnaireTimescalesDetails.startDate', 'Campaign start date')}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <ValidatedISODateTimeInput name="startDate" type="date" value={moment(model?.startDate ?? '').toISOString()}
                            onChange={e => change({ startDate: moment(e.currentTarget.value) })}
                            onBlur={e => validate('startDate')}
                            validationErrors={validationErrors['startDate']} />
                    </Col>
                    <QuickDateButtons
                        handleQuickDateButtonClick={handleCampaignStartDateQuickDateButtonClick}
                        helpMessage={t('questionnaireTimescalesDetails.startDateHelp', 'You can enter any date here, or select one of the quick entry buttons.')}
                        handleQuickDateButtonBlur={handleCampaignStartDateQuickDateButtonBlur}
                    />
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label htmlFor="endDate">{t('questionnaireTimescalesDetails.endDate', 'Campaign end date')}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto">
                        <ValidatedISODateTimeInput name="endDate" type="date" value={moment(model?.endDate ?? '').toISOString()}
                            onChange={e => handleCampaignEndDateChange(moment(e.currentTarget.value))}
                            onBlur={e => validate('endDate')}
                            validationErrors={validationErrors['endDate']} />
                    </Col>
                    <QuickDateButtons
                        handleQuickDateButtonClick={handleCampaignEndDateChange}
                        helpMessage={t('questionnaireTimescalesDetails.endDateHelp', 'You can enter any date here, or select one of the quick entry buttons.')}
                        includeButtons={["1Day", "NextMonday", "1Month", "2Months"]}
                        compareDate={model?.startDate ?? moment()}
                        handleQuickDateButtonBlur={handleCampaignEndDateQuickDateButtonBlur}
                    />
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label htmlFor="reminderType">{t('questionnaireTimescalesDetails.reminderType', 'Do you want to switch on automatic reminders for this campaign?')}</Label>
                        <></>
                        <span data-toggle="tooltip" title={t('questionnaireTimescalesDetails.reminderType.tooltip', "You can also send these manually for each participant or all participants from the Campaign Participants view.")}><FontAwesomeIcon icon="info-circle" /></span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomInput type="switch" id="reminderType" label={!!model?.reminderType ? t('common.yes', 'Yes') : t('common.no', 'No')}
                            checked={!!model?.reminderType ?? false}
                            onChange={e => change({ reminderType: e.currentTarget.checked ? 'Standard' : ''})}
                        />
                    </Col>
                </Row>
                <FormText>
                    {t('questionnaireTimescalesDetails.reminderTypeHelp', 'If you set this to yes, automatic reminders will be sent to anyone who has not yet completed. These will go weekly until the last week of the Campaign and then daily until the deadline.')}
                </FormText>
            </FormGroup>

        </>
    );
};