import * as React from "react";
import { Form, Alert, FormGroup, Spinner, Row, Col, Container } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useSendPasswordResetEmailCallback } from "../../api/account";
import { ForgotPassword as ForgotPasswordModel } from '../../api/account/models/ForgotPassword';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import './ForgotPassword.scss';

/**
 * Request a password reset emai because the user has forgotten their password.
 */
export const ForgotPassword = () => {
    const { t } = useTranslation();
    const [emailSent, setEmailSent] = React.useState<boolean>(false);
    const [sendResetEmail, { isExecuting: isSendingResetEmail, errors: sendResetEmailErrors }] = useSendPasswordResetEmailCallback();

    const { model, change } = useChanges<ForgotPasswordModel>({ email: '' });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            email: () => !model.email ? t('forgotPassword.emailRequired', 'Email is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Render the UI.
    // 
    return (
        <Container fluid className="forgot-password-page">
            <Row className="forgot-password-screen-row">
                <Col xs={12} sm={5} className="forgotPassword-screen-target-column">
                    <div className="forgotPassword-target-image"></div>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
                <Col xs={12} sm={5} className="forgotPassword-screen-input-column">
                    <Row className="impacts-logo-login-row">
                        <div className="impacts-logo-login-image"></div>
                    </Row>
                    <Row>
                        <Col>
                            <h6>
                                {t('forgotPassword.helpText', 'Enter your email and we\'ll send you a link you can use to recover your account and set a new password.')}
                            </h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form onSubmit={async e => {
                                e.preventDefault();
                                const ok = await sendResetEmail(model.email);
                                setEmailSent(ok);
                            }}>

                                <AlertOnErrors simple errors={sendResetEmailErrors} />
                                {
                                    emailSent ? (
                                        <Alert color="success">
                                            <Row>
                                                <Col>
                                                    {t('forgotPassword.emailHasBeenSent', 'Please check your email and follow the link to reset your password.')}
                                                </Col>
                                                <Col xs="auto">
                                                    <ButtonAsync type="submit" color="success" isExecuting={isSendingResetEmail}
                                                        executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                        {t('common.resendEmail', 'Resend email')}
                                                    </ButtonAsync>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    ) : null
                                }
                                <FormGroup>
                                    <ValidatedInput placeholder={t('forgotPassword.email', 'Email')} className="login-email-input" type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                                </FormGroup>
                                <Row className="forgot-password-class">
                                    <Row className="forgot-password-button">
                                        <ButtonAsync type="submit" color="secondary" isExecuting={isSendingResetEmail}
                                            executingChildren={<><Spinner size="sm" /> {t('common.sending', 'Sending...')}</>}>
                                            {t('forgottenPassword.send', 'Send password reset email')}
                                        </ButtonAsync>
                                    </Row>
                                </Row>
                                <Row>
                                    <Link className={"forgotPassword"} to={'/account/login'}>
                                        {t('forgottenPassord.forgotYourPassword', 'Remembered your password and want to login instead?')}
                                    </Link>
                                </Row>


                            </Form>
                        </Col>
                    </Row>                    
                </Col>
                <Col xs={12} sm={1}>
                </Col>
            </Row>
        </Container>
    );
};
