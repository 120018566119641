import { TFunction } from "i18next";

/**
 * Questionnaire types
 */
export enum QuestionnaireType {
    Campaign =  "Campaign",
    SelfService = "SelfService",
}

/**
 * Return all questionnaire type names.
 * @param t
 */
export function questionnaireTypeDisplayName(type: QuestionnaireType, t: TFunction): string {
    switch (type) {
        case QuestionnaireType.Campaign: return t('questionnaireTypeText.Campaign', 'Campaign');
        case QuestionnaireType.SelfService: return t('questionnaireTypeText.SelfService', 'Self-service');
    }
}

// this is the id of the default template used when starting a campaign or template from scratch
// if a skillshub level admin is editing the default template we behave a little differently
export const defaultTemplateId = "1F9D592A-EAA6-4E79-93B8-BD96F15E7423";
