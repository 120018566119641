import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { TagLikeItem } from "../../../shared/tagSelector/TagSelector";
import { useSearchParams } from "../../../shared/useURLSearchParams";
import { CardsOrTable } from "../../shared/cardsOrTable/CardsOrTable";
import { MainContainer } from "../../shared/MainContainer";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./informativeListModal.scss";

export interface InformativeListModalProps {
    title: string;
    tags: TagLikeItem[];
}

export const InformativeListModal = (props: InformativeListModalProps) => {
    const { title, tags } = props;
    const { t } = useTranslation();

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const items = useMemo(() => {
        if (!tags) {
            return [];
        }
        if (!search) {
            return tags;
        }

        let lowerSearch = search.toLocaleLowerCase();

        return tags.filter(item =>
            item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );

    }, [tags, search]);

    return (
        <FormGroup className="informative-list-modal">
            <Row>
                <Col xs={7}>
                    <h6>{t('InformativeListModal.outerTitle', title)}</h6>
                </Col>
                <Col xs={4}>
                    <img onClick={() => setIsMenuOpen(!isMenuOpen)} className="arrow-image" alt=''></img>
                </Col>
                <Col></Col>
            </Row>
            <Modal className="report-name-modal" size='md' isOpen={isMenuOpen} toggle={() => setIsMenuOpen(!isMenuOpen)}>
                <ModalHeader>
                    <Row>
                        <Col style={{ width: '54rem' }}>
                            <h3>
                                {t('InformativeListModal.title', title)}
                            </h3>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody>
                    <MainContainer fluid>
                        <Row className="mb-2">
                            <Col sm={4} className="d-none d-sm-block"></Col>
                            <Col xs={12}>
                                <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                            </Col>
                        </Row>

                        <CardsOrTable
                            viewMode="table"
                            items={items}
                            tableHeadings={[
                                t('InformativeListModal.name', 'Name')
                            ]}
                            columns={[
                                (item: TagLikeItem) => item.name,
                            ]}
                        />

                        <ConditionalFragment showIf={!items?.length}>
                            <NoResultsFound search={search} />
                        </ConditionalFragment>

                    </MainContainer>
                </ModalBody>
            </Modal>
        </FormGroup>
    )
};