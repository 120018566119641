import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { QuestionnaireType } from '../../api/main/models/codeOnly/QuestionnaireType';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';

export interface DepartmentQuestionnairesNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    editLink?: string,
}

/**
 * Navigation for managing a department's questionnaires in a subscription.
 */
export const DepartmentQuestionnairesNavigation = (props: DepartmentQuestionnairesNavigationProps) => {
    const { t } = useTranslation();

    const { data: { model: currentUserProfile } } = useCurrentUserProfile();
    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionDepartmentAdministration}>
                <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration} showIfNotAuthorized={true}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/departmentQuestionnaires/list/${currentUserProfile?.subscriptionDepartmentId}/${QuestionnaireType.Campaign}`} >
                            <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                            <> </>
                            {t('departmentQuestionnairesNavigation.campaigns', 'Campaigns')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/departmentQuestionnaires/list/${currentUserProfile?.subscriptionDepartmentId}/${QuestionnaireType.SelfService}`} >
                            <FontAwesomeIcon icon="graduation-cap" className="nav-icon" />
                            <> </>
                            {t('departmentQuestionnairesNavigation.campaigns', 'Self-Service')}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/departmentQuestionnaires/list/${currentUserProfile?.subscriptionDepartmentId}/Template`} >
                            <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                            <> </>
                            {t('departmentQuestionnairesNavigation.templates', 'Templates')}
                        </NavLink>
                    </NavItem>
                </AuthorizeContainer>
            </AuthorizeContainer>
        </>
    );
};
