import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { useQuestionnaireCompletionPercentage } from "../../me/utilities/useQuestionnaireCompletionPercentage";

export interface PercentageCompletionBarProps {
    questions: Question[],
    questionResponses: QuestionResponse[],
    questionnaireRespondentSession: QuestionnaireRespondentSession,
}

export const PercentageCompletionBar = (props: PercentageCompletionBarProps) => {
    const {
        questions,
        questionResponses,
        questionnaireRespondentSession,
    } = props;

    const { t } = useTranslation();

    const percentageCompleted = useQuestionnaireCompletionPercentage(questions ?? [], questionnaireRespondentSession, questionResponses);


    //if percentage completed is 0 add padding to progress bar.
    const isPaddingNeeded = useMemo(() => {
        if (percentageCompleted === 0) {
            return { paddingLeft: '5px' }
        }
        return;
    }, [percentageCompleted]);

    return ( 
        <div className="progress mt-2 particpant-progress-bar">
            <div className="progress-bar particpant-progress-bar-background" role="progressbar" aria-valuenow={percentageCompleted}
                aria-valuemin={0} aria-valuemax={100} style={{ width: `${percentageCompleted}%` }}>
                <span style={isPaddingNeeded}>{`${percentageCompleted}%`} {t('dashboardQuestionnaireCard.percentageComplete', 'Complete')}</span>
            </div>
        </div>
        )
}