import { useCallback, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col,  FormGroup, ListGroup, ListGroupItem, ListGroupItemHeading, NavItem, NavLink, Row, } from "reactstrap";
import { ModelArrayChanges, } from "../../../../shared/useChanges";
import { Questionnaire } from "../../../../api/main/models/Questionnaire";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuestionnaireUsersAddSupportingData } from "../../../../api/main/questionnaires/viewModels/useQuestionnaireUsersAddSupportingData";
import { MainContainer } from "../../../shared/MainContainer";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { NavTiles } from "../../../shared/NavTiles";
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionnaireUserAddDetails } from "../../../subscriptionQuestionnaires/users/QuestionnaireUserAddDetails";
import { QuestionnaireUsersImport } from "../../../subscriptionQuestionnaires/users/QuestionnaireUsersImport";
import "./peopleTab.scss";
import { LoadingIndicator } from "../../../shared/LoadingIndicator";
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { UserData } from "../../EditQuestionnaireBase";
import { QuestionnaireRespondentSession } from "../../../../api/main/models/QuestionnaireRespondentSession";
import { LinkContainer } from "react-router-bootstrap";

export interface PeopleTabProps {
    model: Questionnaire | undefined,
    usersManager: ModelArrayChanges<UserData, string>,
    setActiveStage: React.Dispatch<React.SetStateAction<string>>,
    isCreate: boolean,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>,
    existingSessions: QuestionnaireRespondentSession[]; 
    completedStages: string[],
    baseRoute: string,
}

/**
 * Tab for a maintaining people on a questionnaire.
 * @param props
 */
export const PeopleTab = (props: PeopleTabProps) => {
    const {
        model,
        usersManager,
        setActiveStage,
        isCreate,
        saveAllDeBounce,
        existingSessions,
        completedStages,
        baseRoute,
    } = props;

    const { t } = useTranslation();

    // need too know if we are in SubscriptionQuestionnaires so we can jump to the right route for ManageAllParticipants
    const isSubscriptionQuestionnaires = window.location.pathname.toLocaleLowerCase().indexOf('/subscriptionquestionnaires/') > -1;

    // Get all supporting data
    // departments for dropdown on each user
    // profiles to match email when entered - may need to rethink the profiles if it gets too slow as data builds up
    // questionnaire for details 
    // sessions so we can check if user is already on questionnaire

    const { data: { subscriptionDepartments, profiles: subscriptionProfiles }, isLoading, errors: loadErrors } = useQuestionnaireUsersAddSupportingData({ subscriptionId: model?.subscriptionId ?? undefined, questionnaireId: model?.id });

    // allows us to set the correct active button for selections
    const [selectionType, setSelectionType] = useState<string>('manual');

    // keep arrays so we can add messages for accepted and rejected users this time in
    const [justAddedUsers, setJustAddedUsers] = useState<UserData[]>([]);
    const [justRejectedUsers, setJustRejectedUsers] = useState<UserData[]>([]);

    // Get the user if the New email entered matches any of the profiles.
    const userFromEmail = useCallback((index: number) => {
        if (index >= usersManager.model.length) {
            // No item in the array so nothing to return
            return;
        }

        const thisEmail = usersManager.model[index].email ?? '';
        if (!!thisEmail) {
            // If we have an email - see if we can find it in the user profiles
            return subscriptionProfiles?.find(item => item.user.email === thisEmail);
        }

        return;
    }, [usersManager.model, subscriptionProfiles]);

    const saveSessions = useCallback(() => {

        saveAllDeBounce();

        // add users to array of saved users or rejected users
        let allNewUsers = [...justAddedUsers];
        let allRejectedUsers = [...justRejectedUsers];
        usersManager.model.forEach((item) => {
            // only check added and updated as everything else has been processed
            if (!!item.email && (!!usersManager.added.find(it => it.email === item.email) || !!usersManager.updated.find(it => it.email === item.email))) {

                // Check if the user is already on this questionnaire.
                // If they are they will have been rejected during the save but we need to reject them here.

                // get the respondent sessions for the users in the array and then match
                const session = existingSessions.find(sess => sess.email === item.email);

                // see if we have successfully added the user already but they were not there when we originally came into the view
                const isJustAdded = allNewUsers.find(us => us.email === item.email);

                if (!!session || isJustAdded) {
                    // reject anyone already on the questionnaire
                    allRejectedUsers.push(item);
                } else {
                    allNewUsers.push(item);
                }
            }
        });

        setJustAddedUsers(allNewUsers);
        setJustRejectedUsers(allRejectedUsers);

    }, [usersManager, justAddedUsers, setJustAddedUsers, justRejectedUsers, setJustRejectedUsers, existingSessions, saveAllDeBounce,]);

    return (
        <>
            <Row className="mt-2 people-tab-top">
                <Col xs="auto">
                    <h2> {t('peopleTab.heading', 'Add People')}</h2>
                </Col>
                <Col xs={'auto'} className={'col-inline-heading-button'}>
                    <span data-toggle="tooltip" title={t('peopleTab.tooltip', "Add or import people here, or select Manage All Participants to see a full list.")}><FontAwesomeIcon icon="info-circle" /></span>
                </Col>
                <Col>
                    <LinkContainer to={
                        !isSubscriptionQuestionnaires ?
                            `/departmentQuestionnaires/users/${model?.subscriptionDepartmentId ?? ''}/${model?.id ?? ''}`
                            :
                            `/subscriptionQuestionnaires/users/list/${model?.id ?? ''}`
                    }>
                        <Button color={"primary"} outline>
                            <FontAwesomeIcon icon="edit" />
                            <> {t('peopleTab.manageAllParticipants', 'Manage All Participants')}</>
                        </Button>
                    </LinkContainer>
                </Col>
                <ConditionalFragment showIf={isLoading}>
                    <Col xs="auto">
                        <LoadingIndicator size="sm" />
                    </Col>
                </ConditionalFragment>
                <Col xs="auto">
                    <ConditionalFragment showIf={selectionType === "manual" && isCreate}>
                        {/* need to save the form when we continue if we are dealing with manual addition of participants */}
                        <Button color="primary" disabled={!completedStages.find(item => item === 'people')}
                            onClick={() => { saveSessions(); setActiveStage('schedule'); }}>
                            <> {t('common.continue', 'Continue')}</>
                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </Button>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={selectionType !== "manual" && isCreate}>
                        {/* if we have imported participants from a file then they will have already been saved */}
                        <Button color="primary" disabled={!completedStages.find(item => item === 'people')}
                            onClick={e => setActiveStage('schedule')}>
                            {t("common.continue", "Continue")}
                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </Button>
                    </ConditionalFragment>
                </Col>
            </Row>

            <MainContainer className={"people-tab-main-container"}>
                <AlertOnErrors errors={[loadErrors]} />
                <Row className="mb-2">
                    <Col xs={12} className="mb-2">
                        <span className="text-muted">
                            {t('peopleTab.helpText', 'You can either manually add up to 5 people at a time and save them, or for larger numbers of people you might prefer to import them by uploading an Excel (.xlsx), CSV (.csv), or tab-delimited (.txt or .tsv) file.')}
                        </span>
                    </Col>
                    <Col xs={12} md="">
                        <Row>
                            <Col className={"tile-column"}>
                                <NavTiles className="nav-tiles-large">
                                    <NavItem key={'manualAdd'}>
                                        <NavLink className={selectionType === 'manual' ? 'active' : ''} to="/" onClick={e => { setSelectionType('manual'); }}>
                                            <div className="navitem-text">{t('peopleTab.manual', 'Add Participants')}</div>
                                            <FontAwesomeIcon icon="plus" className="nav-icon" />
                                        </NavLink>
                                    </NavItem>
                                    <NavItem key={'importAdd'}>
                                        <NavLink className={selectionType === 'import' ? 'active' : ''} to="/" onClick={e => { setSelectionType('import'); }}>
                                            <div className="navitem-text">{t('peopleTab.import', 'Import Participants')}</div>
                                            <FontAwesomeIcon icon="upload" className="nav-icon" />
                                        </NavLink>
                                    </NavItem>
                                </NavTiles>

                                <ConditionalFragment showIf={selectionType === "manual" && !!justAddedUsers.length}>
                                    <div className="current-user-list">
                                        <ListGroup>
                                            <ListGroupItemHeading>{t('peopleTab.addedUserList.header', 'Just Added')}</ListGroupItemHeading>
                                            <span className="text-muted">
                                                {t('peopleTab.addedUserList.help', 'These users have been added to this campaign manually during this session')}
                                            </span>
                                            {justAddedUsers.map((item, index) => {
                                                return (
                                                    <ListGroupItem key={index}>{item.email}</ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </div>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={selectionType === "manual" && !!justRejectedUsers.length}>
                                    <div className="current-user-list">
                                        <ListGroup>
                                            <ListGroupItemHeading>{t('peopleTab.rejectedUserList.header', 'Just Rejected')}</ListGroupItemHeading>
                                            <span className="text-muted">
                                                {t('peopleTab.rejectedUserList.help', 'You tried to enter these users manually and they have been rejected. This is usually because they are already participants on this campaign.')}
                                            </span>
                                            {justRejectedUsers.map((item, index) => {
                                                return (
                                                    <ListGroupItem key={index} className="rejected">{item.email}</ListGroupItem>
                                                );
                                            })}
                                        </ListGroup>
                                    </div>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={12} md="">
                        <ConditionalFragment showIf={selectionType === "manual"}>
                            {/*QuestionnaireUserAddDetails 5x to allow for 5 participants to be added to a questionnaire at once*/}
                            <FormGroup>
                                <Col className={"add-user-details-container"}>
                                    {
                                        [...Array(5)].map((value: undefined, index) => {
                                            return (
                                                <div key={index}>
                                                    <QuestionnaireUserAddDetails
                                                        subscriptionDepartments={subscriptionDepartments}
                                                        thisUserData={usersManager.model[index]}
                                                        thisUserProfile={userFromEmail(index)}
                                                        change={changes => usersManager.changeFor((index + 1).toString(), changes)}
                                                    />
                                                </div>
                                            );
                                        })
                                    }
                                </Col>
                            </FormGroup>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={selectionType === "import"}>
                            <QuestionnaireUsersImport
                                questionnaireId={model?.id ?? ''}
                                departmentId={model?.subscriptionDepartmentId ?? undefined}
                                baseRoute={baseRoute}
                                isCreate={isCreate}
                            />
                        </ConditionalFragment>
                        <div className="mt-2 text-left">
                            <ConditionalFragment showIf={selectionType === "manual"}>
                                <Button color="primary"
                                    onClick={() => saveSessions()}>
                                    <FontAwesomeIcon icon="save" />
                                    <> </>
                                    {t('common.next', 'Add These People')}
                                </Button>
                            </ConditionalFragment>
                        </div>
                    </Col>
                </Row>

            </MainContainer>
            <Row>
                <Col></Col>
                <Col xs="auto">
                    <ConditionalFragment showIf={selectionType === "manual" && isCreate}>
                        {/* need to save the form when we continue if we are dealing with manual addition of participants */}
                        <Button color="primary" disabled={!completedStages.find(item => item === 'people')}
                            onClick={() => { saveSessions(); setActiveStage('schedule'); }}>
                            <> {t('common.continue', 'Continue')}</>
                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </Button>
                    </ConditionalFragment>
                    <ConditionalFragment showIf={selectionType !== "manual" && isCreate}>
                        {/* if we have imported participants from a file then they will have already been saved */}
                        <Button color="primary" disabled={!completedStages.find(item => item === 'people')}
                            onClick={e => setActiveStage('schedule')}>
                            {t("common.continue", "Continue")}
                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </Button>
                    </ConditionalFragment>
                </Col>
            </Row>
        </>
    );
};