import { Button, Col, Collapse, Container, Row } from "reactstrap";
import { SectionsListItem } from "./QuestionnaireOverview";
import { useMemo, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { QuestionInSectionComponent } from "./QuestionInSectionComponent";
import { ConditionalFragment } from "react-conditionalfragment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export interface DraggableSectionComponentProps {
    sectionListItem: SectionsListItem;
    exclude: (id: string, name: string) => void;
    graphMode?: boolean;
    includeInGraph?: boolean;
    toggleIncludeInGraph: (id: string) => void;
    change?: 'up' | 'down' | 'none';
    figure?: number;
};


export const DraggableSectionComponent = (props: DraggableSectionComponentProps) => {
    const {
        sectionListItem,
        exclude,
        graphMode,
        includeInGraph,
        toggleIncludeInGraph,
        change,
        figure,
    } = props;

    const { t } = useTranslation();

    const [isSectionOpen, setIsSectionOpen] = useState(false);

    // Drag and drop support
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: sectionListItem.id as string });
    const style = useMemo(() => ({
        // Outputs `translate3d(x, y, 0)`
        transform: CSS.Transform.toString(transform),
        transition: transition,
        // Make sure we show ourselves on top of everything else when dragging.
        zIndex: isDragging ? 1000 : 0,
    }), [transform, transition, isDragging]);

    return (
        <div style={style}  {...attributes}>
            <Container className="analyser-dragComponent" >
                <Row>
                    <Col xs={1} >
                        <div ref={setNodeRef} {...listeners} className="analyser-imageButton d-flex justify-content-center align-items-center">
                            <img className="analyser-hamburger" alt=''></img>
                        </div>
                    </Col>
                    <Col xs={6} className="dragable-name-container">
                        <h5>{sectionListItem.name}</h5>
                    </Col>
                    <Col xs={2} className={"pl-0 pr-0"}>
                        <ConditionalFragment showIf={change === 'up'}>
                            <img className="analyser-upArrow" alt=''></img>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={change === 'down' && figure !== 0}>
                            <img className="analyser-downArrow" alt=''></img>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={change === 'down' && figure === 0}>
                            <FontAwesomeIcon icon="minus" />
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!change}>
                            <h6>{t('analyser.dragComponent.figure', `${figure?.toFixed(0)}%`)}</h6>
                        </ConditionalFragment>
                    </Col>
                    <Col xs={1}>
                        <Button onClick={() => setIsSectionOpen(!isSectionOpen)} className="analyser-imageButton d-flex justify-content-center align-items-center">
                            <img className="analyser-arrowImage" alt=''></img>
                        </Button>
                    </Col>
                    <ConditionalFragment showIf={!!graphMode && change !== undefined}>
                        <Col className="graphCheckCol">
                            <input type="checkbox" name="includeInGraph" id="includeInGraph" checked={includeInGraph} onChange={(e) => { toggleIncludeInGraph(sectionListItem.id as string); }} />
                        </Col>
                    </ConditionalFragment>
                    <Col className="text-right">
                        <h6 onClick={() => exclude(sectionListItem.id.toString(), sectionListItem.name)}>
                            {t('analyser.rightSegment.button.close', 'X')}
                        </h6>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Collapse isOpen={isSectionOpen}>
                            <div> { /*margin here is to stop the border for questions going outside of the component*/}
                                {
                                    sectionListItem.questions.map((question, questionIndex) => (
                                        <QuestionInSectionComponent
                                            key={questionIndex}
                                            question={question}
                                            sectionListItem={sectionListItem}
                                        />

                                    ))
                                }
                            </div>
                        </Collapse>
                    </Col>
                </Row>
            </Container>




        </div>
    );
};