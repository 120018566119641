import { View, Image, Text } from "@react-pdf/renderer";
import { AnalyserReportHeaderStyles } from "./analyserReportHeaderStyles";

//* Common Header for Analyser reports
interface AnalyserReportHeaderProps {
    impactsLogoUrl: string,
    companyColor: string,
    reportTitle: string,
}

export const AnalyserReportHeader = (props: AnalyserReportHeaderProps) => {
    const {
        impactsLogoUrl,
        companyColor,
        reportTitle,
    } = props;


    return (
        <View fixed style={AnalyserReportHeaderStyles.header}>
            <View style={[AnalyserReportHeaderStyles.headerContainer, { borderColor: companyColor }]}>
                <View style={AnalyserReportHeaderStyles.headerTextContainer}>
                    <Text style={AnalyserReportHeaderStyles.headerText}>{reportTitle}</Text>
                </View>
                <View style={AnalyserReportHeaderStyles.impactsImageHeaderContainer}>
                    <Image style={AnalyserReportHeaderStyles.impactsImageHeader} source={impactsLogoUrl} />
                </View>
            </View>
            <View style={AnalyserReportHeaderStyles.headerPagePadding}></View>
        </View>
    );
};