import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { ChartData, ChartDataSeries } from "../../dashboard/chartDataUtilities/ChartData";

/**
 * Data for a chart that shows before and after responses to a question.
 */
export function useSingleScaleChartData(isPercentage: boolean, questions: Array<Question>, responses: Array<QuestionResponse>): Array<ChartDataSeries> {
    const { t } = useTranslation();

    const generateSeries = useCallback((seriesName: string) => {
        // Generate chart data for each group.
        const data = questions.map((question, index) => {
            // initialise
            let totalAmount = 0;

            // If the question is a single scale question
            // and question.isPercentage === isPercentage that has been passed through
            // work out the total ammont
            if (question.responseType === QuestionResponseType.Scale && question.isPercentage === isPercentage) {

                // filter all the responses by question.
                const questionResponses = responses.filter(response => response.questionId === question.id);

                // loop round the users - for each get the value and add it to the running total
                for (let response of questionResponses) {

                    totalAmount += response.amountInput;

                }

                // Amount needs to be devided by how many users have given a response to get the average.
                totalAmount = (totalAmount / questionResponses.length);

            }


            return {
                text: `Question ${(index + 1)}`,
                text2: question.id,
                value: Math.round(totalAmount * 2) / 2,
            } as ChartData;
        });

        return {
            name: seriesName,
            data: data,
        } as ChartDataSeries;
    }, [questions, responses, isPercentage]);

    const ret = useMemo(() => {
        let retSeries = [
            generateSeries(
                t('useSingleScaleChartData.scale.seriesName', 'Scale'),
            ),
        ];

        // remove the ones we don't want
        retSeries[0].data = retSeries[0].data.filter((item, index) => questions[index].responseType === QuestionResponseType.Scale && questions[index].isPercentage === isPercentage);

        return retSeries;
    }, [generateSeries, questions, isPercentage, t]);

    return ret;
}
