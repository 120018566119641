import { Question } from '../../../api/main/models/Question';

/**
 * Return the correct Multiple Response text from the question
 */
export function getMultipleChoiceResponseText(question: Question, responseIndex: number): string | undefined {
    if (!question) {
        return;
    }

    switch (responseIndex) {
        case 0:
            return question.multipleChoiceResponse1;
        case 1:
            return question.multipleChoiceResponse2;
        case 2:
            return question.multipleChoiceResponse3;
        case 3:
            return question.multipleChoiceResponse4;
        case 4:
            return question.multipleChoiceResponse5;
        case 5:
            return question.multipleChoiceResponse6;
        case 6:
            return question.multipleChoiceResponse7;
        case 7:
            return question.multipleChoiceResponse8;
        default:
            return;
    }
}

