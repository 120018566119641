import './nextBackButtons.scss';

import { Button, Col, Row, Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonAsync } from 'reactstrap-buttonasync';

export interface nextBackButtonsProps {
    className?: string,
    showBack?: boolean,
    showNext?: boolean,
    handleNextButton: (itemId: string | undefined) => Promise<void>,
    itemId?: string,
    isSaveIncluded?: boolean,
    isSaving?: boolean,
    isLastStep?: boolean,
}

/**
 * Container for back and next buttons on a wizard type form. 
 */
export const NextBackButtons = (props: nextBackButtonsProps) => {
    const {
        className,
        showBack = true,
        showNext = true,
        handleNextButton,
        itemId,
        isSaveIncluded = false,
        isSaving = false,
        isLastStep = false,
    } = props;

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <Row className={`nextback-buttons ${className ?? ''}`}>
            <Col className="back-button-container" xs={6}>
                <Button className={`back-button not-brand ${showBack ? '' : 'hidden'}`} type="button" color="primary" onClick={e => history.goBack()}>
                    <FontAwesomeIcon icon="caret-left" />
                    <> </>
                    {t('common.back', 'Back')}
                </Button>
            </Col>
            <Col className="next-button-container" xs={6}>
                {
                    isSaveIncluded ?
                        <ButtonAsync className={`next-button not-brand ${showNext ? '' : 'hidden'}`} color="primary" isExecuting={isSaving}
                            onClick={async (e) => { await handleNextButton(itemId ?? undefined) }}
                            executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                            {t('common.next', 'Next')}
                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </ButtonAsync>
                        :
                        <Button className={`next-button not-brand ${showNext ? '' : 'hidden'}`} type="button" color="primary" onClick={e => !!handleNextButton ? handleNextButton(itemId ?? undefined) : ''}>
                            {
                                isLastStep ?
                                    t('common.next', 'Complete')
                                    :
                                    t('common.next', 'Next')
                            }

                            <> </>
                            <FontAwesomeIcon icon="caret-right" />
                        </Button>
                }
            </Col>
        </Row>
    );
};