import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const AnalyserReportFooterStyles = StyleSheet.create({
    footer: {
        position: "absolute",
        bottom: 0,
        left: "10mm",
        borderTop: "1px solid black",
        paddingTop: "3mm",
        height: "25mm",
    },
    footerContainer: {
        width: "190mm",
        flexDirection: "column",
    },
    impactsImageFooter: {
        marginLeft: "auto",
        marginRight: "auto",
        width: "25mm",
        height: "auto",
    },
    footerPageNumberContainer: {
        width: "100%",
        paddingBottom: "2mm",
    },
    footerPageNumber: {
        fontSize: 10,
        alignContent: "center",
        textAlign: "center",
    },
});

