import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const QuestionPDFStyles = StyleSheet.create({
    questionTextBox: {
        backgroundColor: "#f3f4f5",
        minHeight: "25.2pt",
        marginTop: "15pt",
        fontWeight: 550,
    },
    questionText: {
        fontSize: "4mm",
        marginTop: "3mm",
        marginLeft: "3mm",
        marginBottom: "3mm",
        marginRight: "3mm",
        fontWeight: 550,
    },
    userRow: {
        flexDirection: "row",
        fontSize: "3mm",
        padding: "14pt 7.5pt 7.5pt 7.5pt",
        fontWeight: 550,
    },
    userLastName: {
        paddingLeft: "1mm",
        paddingRight: "0.2mm"
    },

});