import { registerErrorMessageConverters } from "../shared/userFriendlyErrorMessage";
import i18next from "i18next";

// Register the converters to make friendly versions of error messages.
// This is primarily used to make friendly versions of message from 3rd party libraries, or the server.
// By convention tranlsation keys for returned errors should start "error." rather than "errorMessageConfig.".
export function configureErrorMessages() {
    registerErrorMessageConverters([
        // Convert the apollo graphql errors message to something more friendly.
        (error) => {
            let shouldHandle = false;
            let message = error?.message ?? error?.toString() ?? ''; /* Sometimes we get an Error sometimes we get a String */
            let graphQLErrors: Array<any> = [];
            switch (message) {
                // Network errors get a friendly message that is nicer given the use of cache for offline support.
                case 'Network error: Failed to fetch':
                    message = i18next.t('error.apollo.networkErrorFailedToFetch', 'Could not synchronize information with the cloud.');
                    shouldHandle = true;
                    break;
                default:
                    break;
            }

            // Handle graphQL errors as they all start with GraphQL error: in their message.
            if (message.indexOf('GraphQL error: ') === 0) {
                if (message.indexOf('GraphQL error: GraphQL.Validation.ValidationError: ') === 0) {
                    message = i18next.t('error.apollo.graphQLValidationError', 'GraphQL error: request is not not a valid GraphQL query or mutation.');
                } else if (message.indexOf('GraphQL error: GraphQL.ExecutionError: ') === 0) {
                    message = i18next.t('error.apollo.graphQLExecutionError', 'GraphQL error: query or mutation could not be executed.');
                }

                shouldHandle = true;
            }

            // If we have a graphQL errors, then lets handle those as seperate errors for the purpose of showing them.
            if (error?.graphQLErrors) {
                graphQLErrors = error?.graphQLErrors;
                shouldHandle = true;
            }

            // If we haven't decided we should handle this error by this point then just skip it.
            if (!shouldHandle) {
                console.log(error);
                return null;
            }

            // If we have multiple graphQLErrors in one Error object, lets return one error for each GraphQL error.
            if (graphQLErrors.length) {
                return graphQLErrors.map(item => ({
                    message: message,
                    details: i18next.t('errors.apollo.graphQLErrors.detail', '{{message}} location: {{location}}', { message: item.message, location: item.location }),
                    error: error,
                }));
            }

            // Return the error.
            return { message, error };
        },

        // Convert error messages from the Account REST API to make them translatable.
        // Note that the original messages are friendly enough so we just use those for English but pass them for translation.
        (error) => {
            switch (error?.message) {
                // General messages used by multiple actions in the API.
                case 'This email does not have an account here.':
                    return i18next.t('error.accountApi.common.NoAccountHere', 'This email does not have an account here.');
                case 'This account is not allowed to use this site.':
                    return i18next.t('error.accountApi.common.notAllowed', 'This account is not allowed to use this site.');
                case '"This account has been locked out, please try again later.':
                    return i18next.t('error.accountApi.common.lockedOut', '"This account has been locked out, please try again later.');
                case 'This email is already registered.':
                    return i18next.t('error.accountApi.common.alreadyRegistered', 'This email is already registered.');
                case 'Unknown user':
                    return i18next.t('error.accountApi.common.unknownUser', 'Unknown user');

                // Specific messages that are only used by individual actions.
                // Login
                case 'Invalid password':
                    return i18next.t('error.accountApi.login.invalidPassword', 'Invalid password');
                // CreateAccountForExternalLogin
                case 'Error loading external login information during confirmation.':
                    return i18next.t('error.accountApi.createAccountForExternalLogin', 'Error loading external login information during confirmation.');
                // CompleteExternalLogin
                case 'Error loading external login information.':
                    return i18next.t('error.accountApi.completeExternalLogin.errorLoadingExternalLoginInformation', 'Error loading external login information.');
                // SendPasswordResetModel
                case 'This email has not been confirmed, so we can\'t send a password reset until it has been confirmed.':
                    return i18next.t('error.accountApi.', 'This email has not been confirmed, so we can\'t send a password reset until it has been confirmed.');
                // ConfirmEmail
                case 'Error confirming your email.':
                    return i18next.t('error.accountApi.confirmEmail.errorConfirmingEmail', 'Error confirming your email.');
                // ConfirmEmailChange
                case 'Error changing email.':
                    return i18next.t('error.accountApi.errorChangingEmail', 'Error changing email.');
                // ConfirmInvite
                case 'This email has not been invited.':
                    return i18next.t('error.accountApi.confirmInvite.notInvited', 'This email has not been invited.');
                case 'This invite has already been accepted.':
                    return i18next.t('error.accountApi.confirmInvite.alreadyAccepted', 'This invite has already been accepted.');
                // Lockout
                case 'Error setting lockout for user':
                    return i18next.t('error.accountApi.lockout.errorSettingLockout', 'Error setting lockout for user');
                default:
                    break;
            }

            // Handle external authentication provider errors (from CompleteExternalLogin) by passing on the error with a chance to translate the prefix.
            if (error?.message?.indexOf('Error from external provider: ') === 0) {
                const externalProviderError = error.message.subStr(30);
                return i18next.t('error.accountApi.completeExternalLogin.externalProviderError', 'Error from external provider: {{error}}', { error: externalProviderError });
            }

            return null;
        },

        //(error) => {
        //    // If we are in debug mode, log any error messages we get that were not made friendly.
        //    console.debug(error);
        //}
    ]);
}
