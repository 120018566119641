import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment, { Moment } from "moment";
import { useValidatorCallback } from "pojo-validator-react";
import { useCallback,} from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, } from 'reactstrap';
import { DebouncedState } from "use-debounce/lib/useDebouncedCallback";
import { Questionnaire } from "../../../../api/main/models/Questionnaire";
import { MainContainer } from "../../../shared/MainContainer";
import { QuestionnaireTimescalesDetails } from "../../shared/QuestionnaireTimescalesDetails";

export interface ScheduleTabProps {
    model: Questionnaire | undefined,
    setActiveStage: React.Dispatch<React.SetStateAction<string>>,
    change: (changes: Partial<Questionnaire>) => void,
    saveAllDeBounce: DebouncedState<(options?: { goBack?: boolean, navigateTo?: string, }) => Promise<boolean>>, 
    isCreate: boolean,
    completedStages: string[]
}

/**
 * Edit the start and end dates on a questionnaire.
 * This is used as step 3 of the create questionnaire and not during the edit questionnaire
 */
export const ScheduleTab = (props: ScheduleTabProps) => {
    const {
        model,
        setActiveStage,
        change,
        saveAllDeBounce,
        isCreate,
        completedStages,
    } = props;

    const { t } = useTranslation();

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            endDate: () => model?.endDate < moment(model?.startDate).add(1, 'days') ? t('editQuestionnaireTimescales.validation.endDate', 'The end date must be after the start date') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // handle Campaign start date quick button changes - gets passed to component QuickDateButtons
    const handleCampaignStartDateQuickDateButtonClick = useCallback((adjustmentMoment: Moment) => {
        change({ startDate: adjustmentMoment });
    }, [change]);

    // handle Campaign start date quick button blurs - gets passed to component QuickDateButtons
    const handleCampaignStartDateQuickDateButtonBlur = useCallback(() => {
        validate('startDate');
    }, [validate]);

    // handle Campaign end date quick button changess - gets passed to component QuickDateButtons
    const handleCampaignEndDateChange = useCallback((adjustmentMoment: Moment) => {
        change({ endDate: adjustmentMoment });
        validate('endDate');

        let days = adjustmentMoment.diff(moment(model?.startDate), 'days');
        change({ numberOfDays: days });
    }, [change, model?.startDate, validate,]);

    // handle Campaign end date quick button blurs - gets passed to component QuickDateButtons
    const handleCampaignEndDateQuickDateButtonBlur = useCallback(() => {
        validate('endDate');
    }, [validate]);

    return (
        <>
            <Row>
                <Col>
                    <h2> {t('scheduleTab.heading', 'Campaign Schedule')}</h2>
                </Col>
                <Col xs="auto">
                    {
                        // no continue button in edit, only during create
                        isCreate ?
                            <Button color="primary" disabled={!completedStages.find(item => item === 'people')} onClick={e => {
                                if (validate()) {
                                    saveAllDeBounce();
                                    setActiveStage('review');
                                }
                            }}>
                                {t("common.continue", "Continue")}
                                <> </>
                                <FontAwesomeIcon icon="caret-right" />
                            </Button>
                            :
                            <></>
                    }
                </Col>
            </Row>


            <MainContainer>
                <QuestionnaireTimescalesDetails
                    model={model}
                    handleCampaignStartDateQuickDateButtonClick={handleCampaignStartDateQuickDateButtonClick}
                    handleCampaignEndDateChange={handleCampaignEndDateChange}
                    change={change}
                    validate={validate}
                    validationErrors={validationErrors}
                    handleCampaignStartDateQuickDateButtonBlur={handleCampaignStartDateQuickDateButtonBlur}
                    handleCampaignEndDateQuickDateButtonBlur={handleCampaignEndDateQuickDateButtonBlur}
                />
            </MainContainer>
            <Row>
                <Col>
                </Col>
                <Col xs="auto">
                    {
                        // no continue button in edit, only during create
                        isCreate ?
                            <Button color="primary" disabled={!completedStages.find(item => item === 'people')} onClick={e => {
                                if (validate()) {
                                    saveAllDeBounce();
                                    setActiveStage('review');
                                }
                            }}>
                                {t("common.continue", "Continue")}
                                <> </>
                                <FontAwesomeIcon icon="caret-right" />
                            </Button>
                            :
                            <></>
                    }
                </Col>
            </Row>
        </>
    );
};