import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const DetailsPageStyles = StyleSheet.create({
    detailsSection: {
        paddingBottom: "7mm",
        borderBottom: "1px solid #cecece",
    },
    criteriaListContainer: {
        flexDirection: "column",
        width: "100%",
    },
    criteriaList: {
        flexDirection: "row",
        paddingLeft: "5mm",
        paddingBottom: "1mm",
        paddingTop: "1mm",
    },
    bullet: {
        marginHorizontal: "4mm",
    },
    criteriaListText: {
        fontSize: 12,
        fontWeight: "normal",
    },
    subHeadingContainer: {
        fontSize: 14,
        paddingTop: "4mm",
        paddingBottom: "4mm",
        flexDirection: "row",
    },
    subHeadingBold: {
        fontSize: 14,
        fontWeight: "bold",
    },
    subHeading: {
        fontSize: 14,
        fontWeight: "normal",
    },

});

