import React, { useState, useMemo, useCallback } from "react";
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle, Label } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../../shared/alertOnErrors';
import { LoadingIndicator } from '../../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../../shared/MainContainer';
import { NoResultsFound } from '../../shared/NoResultsFound';
import { StickyToolbar } from '../../shared/StickyToolbar';
import { useHistory, useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../../shared/Banner';
import { Background } from '../../shared/background/Background';
import { CardsOrTable } from '../../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useQuestionnaireUsersListViewModel } from "../../../api/main/questionnaireRespondentSessions/viewModels/useQuestionnaireUsersListViewModel";
import { useEditQuestionnaireSupportingData } from "../../../api/main/questionnaires/viewModels/useEditQuestionnaireSupportingData";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { useSendAllQuestionnaireReminderCallback } from "../../../api/main/questionnaires/useSendAllQuestionnaireReminderCallback";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { useSendQuestionnaireReminderCallback } from "../../../api/main/questionnaires/useSendQuestionnaireReminderCallback";
import { useResendQuestionnaireInviteCallback } from "../../../api/main/questionnaires/useResendQuestionnaireInviteCallback";
import { useQuestionnaireUserListSupportingData } from "../../../api/main/questionnaireRespondentSessions/viewModels/useQuestionnaireUserListSupportingData";
import { PercentageCompletionBar } from "./PercentageCompletionBar";
import { EditRespondantSessionModal } from "./EditRespondantSessionModal";
import { useDeleteQuestionnaireRespondentSessionCallback } from "../../../api/main/questionnaireRespondentSessions/useDeleteQuestionnaireRespondentSessionCallback";
import { DeleteAnyModal } from "../../shared/DeleteAnyModal";
import { GetSortOptionRadioButton, } from "../../shared/SortOptionRadioButton";

/**
 * List of users for a questionnaire.
 * This is a list of QuestionnaireRespondentSessions and associated data
 */
export const QuestionnaireUsersList = () => {
    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // get the questionnaireId
    const { id, departmentId } = useParams<{ id: string | undefined, departmentId: string | undefined }>();

    const { data: { items: allItems, questionnaire }, isLoading: _isLoading, errors: loadingErrors, fetchMore, hasMore } = useQuestionnaireUsersListViewModel({ pageSize: undefined, questionnaireId: id });
    const { data: { subscriptionDepartments: departments, }, isLoading: isLoadingSupportingData, errors: loadSupportingDataErrors } = useEditQuestionnaireSupportingData({ subscriptionId: currentUserSubscriptionId ?? undefined });

    const { data: { questionResponses, questions } } = useQuestionnaireUserListSupportingData({ questionnaireId: id });

    const isLoading = _isLoading || isLoadingSupportingData;

    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    //Sends a reminder Email to all users who have not yet completed the questionnaire.
    const [remindAll] = useSendAllQuestionnaireReminderCallback();

    // Sends a Reminder Email to a single user who has not yet completed the questionnaire.
    const [singleReminder] = useSendQuestionnaireReminderCallback();

    const [resendQuestionnaireInvite] = useResendQuestionnaireInviteCallback();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return [];
        }

        if (!search) {
            return allItems;
        }

        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return allItems.filter(item =>
            item.userId.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.profile.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.profile.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.profile.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
        );
    }, [allItems, search]);

    //Set Sort Order
    const [sortOrder, setSortOrder] = useState<string>('firstName');
    const defaultSortOrder = 'firstName';

    const displayItems = useMemo(() => {
        let ret = [...items];

        const useSortOrder = !!sortOrder ? sortOrder : defaultSortOrder;

        switch (useSortOrder) {
            case 'lastName':
                // sort by last name
                ret.sort((a, b) => {
                    if (a.profile.lastName === b.profile.lastName) {
                        return 0;
                    } else if (a.profile.lastName < b.profile.lastName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
            case 'email':
                // sort by email
                ret.sort((a, b) => {
                    if (a.profile.user.email === b.profile.user.email) {
                        return 0;
                    } else if (a.profile.user.email < b.profile.user.email) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
            case 'department':
                // sort by department
                ret.sort((a, b) => {
                    const aDepartmentName = departments.find(item => item.id === a.subscriptionDepartmentId)?.name ?? '';
                    const bDepartmentName = departments.find(item => item.id === b.subscriptionDepartmentId)?.name ?? '';
                    if (aDepartmentName === bDepartmentName) {
                        return 0;
                    } else if (aDepartmentName < bDepartmentName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
            case 'fullName':
                // sort by full name
                ret.sort((a, b) => {
                    const aFullName = ((a.profile.firstName ?? '') + ' ' + (a.profile.lastName ?? '')).trim();
                    const bFullName = ((b.profile.firstName ?? '') + ' ' + (b.profile.lastName ?? '')).trim();
                    if (aFullName === bFullName) {
                        return 0;
                    } else if (aFullName < bFullName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
            default:
                // default to first name
                ret.sort((a, b) => {
                    if (a.profile.firstName === b.profile.firstName) {
                        return 0;
                    } else if (a.profile.firstName < b.profile.firstName) {
                        return -1;
                    } else {
                        return 1;
                    }
                });
                break;
        }

        return ret
    }, [items, sortOrder, defaultSortOrder, departments]);

    useReplaceSearchParamsEffect({ search: search });


    const [viewMode, setViewMode] = usePreferredListViewMode();

    const singleUserResponses = useCallback((userId: string) => {
        const allResponses = questionResponses ?? [];

        let neededResponses = [];

        //Get all the responses for a user for this questionnaire.
        const singleUserResponses = allResponses.filter(item => item.userId === userId);

        for (let response of singleUserResponses) {
            neededResponses.push(response)
        }

        return neededResponses;
    }, [questionResponses]);

    // Toggle Edit Repsondant Session Modal
    const [respondantSessionModalIsOpen, toggleRespodantSessionModal] = useToggleState();

    // Set userID for user whose respondant session we are changing the department on.
    const [userIdForDepartmentEdit, setUserIdForDepatmentEdit] = useState<string>('');

    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteQuestionnaireRespondentSessionCallback();
    const [deletedItemId, setDeletedItemId] = useState<string>("");

    return (
        <Background>
            <Banner fluid>
                <Row className={"bannerNoPills"}>
                    <Col>
                        <h1>{questionnaire?.name + ' ' + t('questionnaireUsersList.heading', 'Participants')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
                <StickyToolbar>
                    <Row className={"searchBar"}>
                        <Col xs="">
                        </Col>
                        <Col xs="auto">
                            <ButtonGroup>
                                <ConditionalFragment showIf={questionnaire?.questionnaireType === QuestionnaireType.Campaign}>
                                    {/* this only comes from the create/edit process so go back toakes you back to the correct place (subscription or department)*/}
                                    <Button color="primary" onClick={() => history.goBack() }>
                                        <FontAwesomeIcon icon="caret-left" /><> {t('questionnaireUsersListBase.backToCampaign', 'Back To Campaign')}</>
                                    </Button>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!allItems && !!allItems.length}>
                                    <Button color="primary" outline onClick={() => remindAll(questionnaire?.id ?? '', questionnaire?.name ?? '', questionnaire?.numberOfDays ?? 0)}>
                                        <FontAwesomeIcon icon="user-clock" />
                                        <> </>
                                        {t('questionnaireUserList.remind', 'Remind All')}
                                    </Button>
                                </ConditionalFragment>
                            </ButtonGroup>
                        </Col>
                        <Col className="text-right" xs={12} sm="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, loadSupportingDataErrors, removeErrors]} />
                <Row>
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>

                    {/* Radio Buttons for sorting*/}
                    <Col className="sort-fields-column" xs={12} lg="">
                        <div className="mt-2 mb-2">
                            <span className={"sortOptions"}>{t('questionnaireUsersListBase.sortOptionsLabel', 'Sort by:')}</span>
                            <> </>
                            {GetSortOptionRadioButton('firstName', sortOrder, defaultSortOrder, setSortOrder)}
                            {GetSortOptionRadioButton('lastName', sortOrder, defaultSortOrder, setSortOrder)}
                            {GetSortOptionRadioButton('fullName', sortOrder, defaultSortOrder, setSortOrder)}
                            {GetSortOptionRadioButton('email', sortOrder, defaultSortOrder, setSortOrder)}
                            {GetSortOptionRadioButton('department', sortOrder, defaultSortOrder, setSortOrder)}
                        </div>
                    </Col>
                </Row>
                <CardsOrTable
                    viewMode={viewMode}
                    items={displayItems}
                    onItemClick={item => history.push(`/home/questionnaire/user/${item.profile.userId}/view/${item.questionnaireId}/`)}
                    tableHeadings={[
                        t('questionnaireUsersList.firstName', 'First Name'),
                        t('questionnaireUsersList.lastName', 'Last Name'),
                        t('questionnaireUsersList.email', 'Email'),
                        t('questionnaireUsersList.department', 'Department'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{!item.profile.firstName && !item.profile.lastName ? item.profile.user.email : item.profile.firstName + " " + item.profile.lastName}</CardTitle>) : null,
                        (item, view) => view === 'table' ? item.profile.firstName : null,
                        (item, view) => view === 'table' ? item.profile.lastName : null,
                        (item, view) => view === 'cards' ? (<CardSubtitle tag="h6">{item.profile.user.email}</CardSubtitle>) : item.profile.user.email,
                        (item, view) => view === 'cards' ? (<><Label>{t('questionnaireUsersList.departmentCardLabel', 'Department')}</Label>{' - ' + (departments?.find(it => it.id === item.profile.subscriptionDepartmentId)?.name ?? 'none')}</>) : null,
                        (item, view) => view === 'cards' && item.startDate ? (
                            <PercentageCompletionBar
                                questions={questions}
                                questionResponses={singleUserResponses(item.userId)}
                                questionnaireRespondentSession={item}
                            />
                        ) : null,
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`/home/questionnaire/user/${item.profile.userId}/view/${item.questionnaireId}/`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="poll-h" />
                                    <> </>
                                    {t('common.edit', 'View Responses')}
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(item.id); }}>
                                        <FontAwesomeIcon icon="user-minus" />
                                        <> </>
                                        {t('common.delete', 'Remove Participant')}
                                    </DropdownItem>
                                    <ConditionalFragment showIf={!item.startDate && questionnaire?.questionnaireType === QuestionnaireType.Campaign}>
                                        <DropdownItem onClick={() => resendQuestionnaireInvite(item.userId, questionnaire?.id ?? '', questionnaire?.name ?? '')}>
                                            <FontAwesomeIcon icon="paper-plane" />
                                            <> </>
                                            {t('common.delete', 'Resend Questionnaire Invite')}
                                        </DropdownItem>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!item.endDate && questionnaire?.questionnaireType === QuestionnaireType.Campaign}>
                                        <DropdownItem onClick={() => singleReminder(item.userId, questionnaire?.id ?? '', questionnaire?.name ?? '', questionnaire?.numberOfDays ?? 0)}>
                                            <FontAwesomeIcon icon="user-clock" />
                                            <> </>
                                            {t('common.delete', 'Remind Participant')}
                                        </DropdownItem>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!item.endDate}>
                                        <ConditionalFragment showIf={!departmentId}>
                                            <LinkContainer to={`/subscriptionQuestionnaires/quick/view/report/${questionnaire?.id}/user/${item.profile.userId}`}>
                                                <DropdownItem className="text-danger">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                    <> </>
                                                    {t('questionnaireUserList.userReport', 'Report')}
                                                </DropdownItem>
                                            </LinkContainer>
                                        </ConditionalFragment>
                                        <ConditionalFragment showIf={!!departmentId}>
                                            <LinkContainer to={`/departmentQuestionnaires/quick/view/report/${questionnaire?.id}/department/${departmentId}/user/${item.profile.userId}`}>
                                                <DropdownItem className="text-danger">
                                                    <FontAwesomeIcon icon="file-alt" />
                                                    <> </>
                                                    {t('questionnaireUserList.userReport', 'Report')}
                                                </DropdownItem>
                                            </LinkContainer>
                                        </ConditionalFragment>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={questionnaire?.questionnaireType === QuestionnaireType.Campaign}>
                                        <DropdownItem onClick={() => { toggleRespodantSessionModal(); setUserIdForDepatmentEdit(item.userId); }}>
                                            <FontAwesomeIcon icon="edit" />
                                            <> </>
                                            {t('questionnaireUserList.userReport', 'Edit Participant Department')}
                                        </DropdownItem>
                                    </ConditionalFragment>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                />

                <ConditionalFragment showIf={respondantSessionModalIsOpen}>
                    <EditRespondantSessionModal
                        isOpen={respondantSessionModalIsOpen}
                        toggle={toggleRespodantSessionModal}
                        userSessions={items ?? []}
                        userId={userIdForDepartmentEdit}
                        questionnaireId={id ?? ''}
                        departments={departments}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>

                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={'this person'}
                        remove={() => remove(deletedItemId)}
                        isDelete={false}
                    />
                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};