import { useMemo } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useSubscription } from '../../api/main/subscriptions/useSubscription';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { useCurrentUserProfile } from '../../api/main/profiles/useCurrentUserProfile';

/**
 * Primary navigation menu for the app. 
 */
export const Navigation = () => {
    const { t } = useTranslation();
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { model: subscription }, } = useSubscription(currentUserSubscriptionId);
    const { data: { model: currentUserProfile } } = useCurrentUserProfile();

    const canShowManage = useMemo(() => {
        if (!!currentUserSubscriptionId && !!subscription) {
            return true;
        }
        return false;
    }, [currentUserSubscriptionId, subscription]);

    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/me">
                        <FontAwesomeIcon icon="home" className="nav-icon" />
                        <> {t('navigation.home', 'My Workspace')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
            <ConditionalFragment showIf={canShowManage}>
                <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to="/subscriptionQuestionnaires">
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manage', 'Campaigns')}</>
                        </NavLink>
                    </NavItem>
                </AuthorizeContainer>
            </ConditionalFragment>
            <ConditionalFragment showIf={canShowManage}>
                <AuthorizeContainer requireRole={[IdentityRoles.SubscriptionAdministration, IdentityRoles.Administration]}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to="/analyser">
                            <FontAwesomeIcon icon="tachometer-alt" className="nav-icon" />
                            <> {t('navigation.dashboard', 'Analyser')}</>
                        </NavLink>
                    </NavItem>
                </AuthorizeContainer>
            </ConditionalFragment>
            <ConditionalFragment showIf={canShowManage}>
                <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to="/manage">
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manage', 'Admin')}</>
                        </NavLink>
                    </NavItem>
                </AuthorizeContainer>
            </ConditionalFragment>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionDepartmentAdministration}>
                <AuthorizeContainer requireRole={IdentityRoles.SubscriptionAdministration} showIfNotAuthorized={true}>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/departmentQuestionnaires`}>
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manage', 'Campaigns')}</>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to="/analyser">
                            <FontAwesomeIcon icon="tachometer-alt" className="nav-icon" />
                            <> {t('navigation.dashboard', 'Analyser')}</>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={NavLinkTrackActive} to={`/manageDepartment/users/${currentUserProfile?.subscriptionDepartmentId}/users`}>
                            <FontAwesomeIcon icon="users-cog" className="nav-icon" />
                            <> {t('navigation.manage', 'People')}</>
                        </NavLink>
                    </NavItem>
                </AuthorizeContainer>
            </AuthorizeContainer>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <> {t('navigation.administration', 'Impacts')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
