import { Text, View } from "@react-pdf/renderer";
import { ConditionalFragment } from "react-conditionalfragment";
import { AnalysisType } from "../../../../api/main/models/AnalysisType";
import { Question } from "../../../../api/main/models/Question";
import { QuestionResponse } from "../../../../api/main/models/QuestionResponse";
import { ActionsPage } from "./ActionsPage";
import { AnalyserResponse } from "./AnalyserResponses";
import { PerformancePage } from "./PerformancePage";
import { SectionQuestionsAnswersPageStyles } from "./sectionQuestionsAnswersPageStyles";
import { TransformationPage } from "./TransformationPage";

//* Common reporting page for analyser reports - questions and answers for a section
interface SectionQuestionsAnswersPageProps {
    companyColor: string,
    learners: Array<{ id: string, firstName: string, lastName: string, email: string, lineManagerName: string, lineManagerEmail: string }>,
    questions: Question[],
    responses: QuestionResponse[],
    analysisTypeIconUrls?: { analysisTypeName: string, iconUrl: string }[] | undefined,
    categoryResults?: { analysisType: AnalysisType, data: { result: number, arrow?: 'up' | 'down' } }[],
    currencySymbol: string,
}

export const SectionQuestionsAnswersPage = (props: SectionQuestionsAnswersPageProps) => {
    const {
        companyColor, learners, questions, responses, analysisTypeIconUrls, categoryResults, currencySymbol,
    } = props;

    // Split the questions into types

    // Actions Questions
    const actionQuestions = questions.filter(item => item.questionType === 'Commitment');
    const actionQuestionIds = actionQuestions.map(q => q.id);
    const actionResponses = responses.flat().filter(item => learners.find(learner => learner.id === item.userId) && actionQuestionIds.find(q => q === item.questionId && item.isUserInput));

    // Transformation Questions
    const transformationQuestions = questions.filter(item => item.responseType === 'ComparisonScale');
    const transformationQuestionIds = transformationQuestions.map(q => q.id);
    const transformationResponses = responses.flat().filter(item => learners.find(learner => learner.id === item.userId) && transformationQuestionIds.find(q => q === item.questionId && item.isUserInput));

    // All other questions
    const otherQuestions = questions.filter(item => !actionQuestions.includes(item) && !transformationQuestions.includes(item));

    return (
        <>
            {/* Show category results if passed in - Usually only on Performance page of individual report */}

            <ConditionalFragment showIf={!!categoryResults}>
                <View style={SectionQuestionsAnswersPageStyles.individualReportSection}>
                    <PerformancePage
                        categoryResults={categoryResults ?? []}
                        analysisTypeIconUrls={analysisTypeIconUrls ?? []}
                    />
                </View>
            </ConditionalFragment>

            {/* Show Actions questions and responses */}
            <ConditionalFragment showIf={actionQuestions.length > 0}>
                <View style={SectionQuestionsAnswersPageStyles.individualReportSection}>
                    <ActionsPage
                        companyColor={companyColor}
                        actionsLearners={learners}
                        actionQuestions={actionQuestions}
                        actionResponses={actionResponses}
                        currencySymbol={currencySymbol}
                    />
                </View>
            </ConditionalFragment>

            {/* Show Transformation questions and responses */}
            <ConditionalFragment showIf={transformationQuestions.length > 0}>
                <View style={SectionQuestionsAnswersPageStyles.individualReportSection}>
                    <TransformationPage
                        companyColor={companyColor}
                        transformationQuestions={transformationQuestions}
                        transformationResponses={transformationResponses}
                    />
                </View>
            </ConditionalFragment>

            {
                otherQuestions.map((question, questionIndex) => {
                    const questionResponses = responses.filter(response => response.questionId === question.id);
                    const questionResponseLearners = questionResponses.map(response => learners.find(learner => learner.id === response.userId));
                    // need unique learners
                    const uniqueResponseLearners = [...new Set(questionResponseLearners.map(r => r))];


                    return (
                        <View key={questionIndex} style={SectionQuestionsAnswersPageStyles.individualReportSection}>
                            <View style={SectionQuestionsAnswersPageStyles.subHeadingContainer}>
                                <Text style={SectionQuestionsAnswersPageStyles.subHeadingBold} >{question.questionText}</Text>
                            </View>

                            <View>
                                {
                                    uniqueResponseLearners.map((learner, index) => {
                                        // Responses for this question from this learner
                                        const learnerQuestionResponses = questionResponses.filter(response => response.userId === learner?.id ?? '');

                                        return (
                                            <View key={index}>
                                                <View style={SectionQuestionsAnswersPageStyles.learnerResponsesContainer}>
                                                    <ConditionalFragment showIf={learners.length > 1}>
                                                        <Text style={SectionQuestionsAnswersPageStyles.learnerName}>{`${learner?.firstName ?? ''} ${learner?.lastName ?? ''} -`}</Text>
                                                    </ConditionalFragment>
                                                    <AnalyserResponse
                                                        companyColor={companyColor}
                                                        question={question}
                                                        responses={learnerQuestionResponses.sort((a, b) => a.displayOrder - b.displayOrder)}
                                                        preText={learnerQuestionResponses.length > 1 ? 'Response' : ''}
                                                        currencySymbol={currencySymbol}
                                                        questionIndex={questionIndex}
                                                    />
                                                </View>
                                            </View>
                                        )
                                    })
                                }
                            </View>
                        </View>
                    )
                })
            }
        </>
    );
};