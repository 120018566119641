import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Link } from "react-router-dom";
import { Col, ListGroup, ListGroupItemHeading, ListGroupItemText, Row } from "reactstrap";
import { QuestionResponseType } from "../../../api/main/models/codeOnly/QuestionResponseType";
import { Profile } from "../../../api/main/models/Profile";
import { Question } from "../../../api/main/models/Question";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { Subscription } from "../../../api/main/models/Subscription";
import { SubscriptionDepartment } from "../../../api/main/models/SubscriptionDepartment";
import { useCompletionChartData } from "../chartData/useCompletionChartData";
import { useCurrencyAnalysisChartData } from "../chartData/useCurrencyAnalysisChartData";
import { CompletionBarChart } from "../charts/CompletionBarChart";
import { CurrencyAnalysisRadarChart } from "../charts/CurrencyAnalysisRadarChart";
import { DashboardListGroupItem } from "../listGroups/DashboardListGroupItem";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";

export interface SubscriptionDepartmentsDashboardTabProps {
    subscriptionDepartments: Array<SubscriptionDepartment>,
    profiles: Array<Profile>,

    campaignSessions: Array<QuestionnaireRespondentSession>,
    selfServiceSessions: Array<QuestionnaireRespondentSession>,
    graphSessions: Array<QuestionnaireRespondentSession>,
    campaignResponses: Array<QuestionResponse>,
    selfServiceResponses: Array<QuestionResponse>,

    activeQuestionnaireType: string,

    showSubscriptionName?: boolean,
    subscriptions?: Array<Subscription>,
    questions: Array<Question>,
}

/**
 * User based tab on the dashboard.
 * @param props
 */
export const SubscriptionDepartmentsDashboardTab = (props: SubscriptionDepartmentsDashboardTabProps) => {
    const {
        subscriptionDepartments,
        campaignSessions,
        selfServiceSessions,
        graphSessions,
        campaignResponses,
        selfServiceResponses,
        activeQuestionnaireType,
        showSubscriptionName = false,
        subscriptions,
        questions,
    } = props;


    const subscriptionDepartmentGroups = useMemo(() => [...(subscriptionDepartments?.map(item => ({ id: item.id, name: item.name })) ?? [])], [subscriptionDepartments,]);

    const allResponses = campaignResponses.concat(selfServiceResponses);

    const currencyResponses = useMemo(() => {
        if (!questions || !allResponses) {
            return [];
        }

        const currencyQuestionKeys = [...questions ?? []].filter(item => item.responseType === QuestionResponseType.CurrencyAmount).map(item => item.id);
        const neededResponses = [...allResponses ?? []].filter(item => currencyQuestionKeys.includes(item.questionId));
        return neededResponses;
    }, [questions, allResponses,]);

    const currencyAnalysisChartData = useCurrencyAnalysisChartData(subscriptionDepartmentGroups, graphSessions, currencyResponses, 'SubscriptionAdministrator');
    const completionChartData = useCompletionChartData(subscriptionDepartmentGroups, graphSessions, 'SubscriptionAdministrator');

    const baseRoute = useDashboardBaseRoute();

    return (
        <Row style={{ paddingTop: '15px' }}>
            <Col xs={12} xl="">
                <ListGroup className="mb-4">
                    {
                        subscriptionDepartments.map(department => (
                            <DashboardListGroupItem
                                key={department.id}
                                campaignsNotStarted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionDepartmentId === department.id && !item.startDate) : []}
                                campaignsStarted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionDepartmentId === department.id && item.startDate && !item.endDate) : []}
                                campaignsCompleted={!!campaignSessions ? campaignSessions.filter(item => item.subscriptionDepartmentId === department.id && item.endDate) : []}
                                selfServicesNotStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionDepartmentId === department.id && !item.startDate) : []}
                                selfServicesStarted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionDepartmentId === department.id && item.startDate && !item.endDate) : []}
                                selfServicesCompleted={!!selfServiceSessions ? selfServiceSessions.filter(item => item.subscriptionDepartmentId === department.id && item.endDate) : []}
                                activeQuestionnaireType={activeQuestionnaireType}
                                subscriptionId={department.subscriptionId}
                                subscriptionDepartmentId={department.id}
                            >
                                <ListGroupItemHeading>
                                    <Link to={
                                        showSubscriptionName ? `${baseRoute}/subscription/${department.subscriptionId}/department/${department.id}`
                                            : `${baseRoute}/department/${department.id}`
                                    }>
                                        {department.name}
                                    </Link>
                                </ListGroupItemHeading>

                                <ListGroupItemText tag="div">
                                    <ConditionalFragment showIf={showSubscriptionName}>
                                        
                                        <Link to={`${baseRoute}/subscription/${department.subscriptionId}`}>
                                            {'(' /* Can/should this be made translatable? */}
                                            {subscriptions?.find(it => it.id === department.subscriptionId)?.name}
                                            {')'}
                                        </Link>
                                        
                                    </ConditionalFragment>
                                </ListGroupItemText>
                            </DashboardListGroupItem>
                        ))
                    }
                </ListGroup>
            </Col>
            <Col xs={12} xl="">
                <CurrencyAnalysisRadarChart data={currencyAnalysisChartData} />
                <CompletionBarChart data={completionChartData} />
                {/*    <CompletionOverTimeAreaChart data={completionOverTimeChartData} assessmentType={activeAssessmentType} />*/}
            </Col>
       </Row>
    );
};