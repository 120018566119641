import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap"
import { FormButtons } from "../../../shared/FormButtons";
import "./deleteItemModal.scss";

export interface DeleteItemModalProps {
    isOpen: boolean,
    toggle: () => void,
    removeItem: () => void,
    itemName: string,
}

/*
 * Modal to confirm if a user wants to delete a section/question. 
 */

export const DeleteItemModal = (props: DeleteItemModalProps) => {
    const {
        isOpen,
        toggle,
        removeItem,
        itemName,
    } = props;

    const { t } = useTranslation();


    return (
        <>
            <Modal isOpen={isOpen} className="select-question-modal">
                <ModalHeader toggle={() => toggle() }>
                    {t('deleteSectionModal.header', 'Delete Item')}
                </ModalHeader>
                <ModalBody>
                    {t('deleteSectionModal.deleteText', `Are you sure you wish to delete '${itemName}' from the questionnaire`)}
                    <FormButtons>
                        <Button color="primary" onClick={() => { removeItem(); toggle(); }}>
                            {t('deleteSectionModal.delete', 'Yes')}
                        </Button>
                        <Button color="primary" outline onClick={() => toggle()}>
                            {t('deleteSectionModal.no', 'No')}
                        </Button>
                    </FormButtons>
                </ModalBody>
            </Modal>
        </>
    );
};