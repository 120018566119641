import { RouteEntry } from "../../shared/routes";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AdminAnalysisTypesList, AnalysisTypesList } from "./AnalysisTypesList";
import { CreateAdminAnalysisType, CreateAnalysisType, EditAnalysisType } from "./EditAnalysisType";

export const analysisTypeRoutes: Array<RouteEntry> = [

    // Administrator level adin AnalysisTypes maintenance
    { path: '/administration/analysisTypes', exact: true, component: AdminAnalysisTypesList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/analysisTypes/add', exact: true, component: CreateAdminAnalysisType, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/analysisTypes/edit/:id', exact: true, component: EditAnalysisType, authorize: true, requireRole: IdentityRoles.Administration },

    // subscription administartor AnalysisTypes maintenance
    { path: '/manage/analysisTypes', exact: true, component: AnalysisTypesList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/analysisTypes/add', exact: true, component: CreateAnalysisType, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/analysisTypes/edit/:id', exact: true, component: EditAnalysisType, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
];
