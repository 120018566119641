import { RouteEntry } from "../../shared/routes";
import { SubscriptionDepartmentsList } from './SubscriptionDepartmentsList';
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { EditSubscriptionDepartment, CreateSubscriptionDepartment } from "./EditSubscriptionDepartment";

export const subscriptionDepartmentsRoutes: Array<RouteEntry> = [
    { path: '/manage/subscriptionDepartments', exact: true, component: SubscriptionDepartmentsList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/edit/:id', component: EditSubscriptionDepartment, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/add', component: CreateSubscriptionDepartment, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

];
