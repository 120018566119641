
/**
 * Learning Unit types
 */
export enum LearningUnitType {
    ActionLearning = "Action Learning",
    Assessment = "Assessment",
    Audio = "Audio/Podcast",
    Book = "Book",
    Blog = "Blog",
    Coaching = "Coaching",
    Conference = "Conference",
    Course = "Course",
    ELearning = "eLearning",
    OnGoingProgramme = "On-Going Programme",
    Shoadowing = "Shadowing",
    Video = "Video",
    Other = "Other"
}

