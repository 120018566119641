import gql from "graphql-tag";
import { Guid } from "guid-string";
import { analysisTypeFields } from "../generated/analysisTypeFields";

export const analysisTypeFieldsFragment = gql`
    fragment analysisTypeFields on AnalysisType {
        id
        subscriptionId
        name
        isAdminOnly
        baseAmountDefault
        archived
    }
`;


export type AnalysisType = Omit<analysisTypeFields, '__typename'>;

export const analysisTypeDefaultValues = (): Partial<AnalysisType> => ({
    id: Guid.newGuid(),
    subscriptionId: null,
    name: '',
    isAdminOnly: false,
    baseAmountDefault: 0,
    archived: false,
});