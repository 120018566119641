import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionnaireSectionFields } from "../generated/questionnaireSectionFields";

export const questionnaireSectionFieldsFragment = gql`
    fragment questionnaireSectionFields on QuestionnaireSection {
        id
        questionnaireId
        name
        description
        displayOrder
        archived
    }
`;


export type QuestionnaireSection = Omit<questionnaireSectionFields, '__typename'>;

export const questionnaireSectionDefaultValues = (): Partial<QuestionnaireSection> => ({
    id: Guid.newGuid(),
    questionnaireId: '',
    name: '',
    description: '',
    displayOrder: moment().unix(),
    archived: false,
});