import { Col, FormGroup, Label, Row } from 'reactstrap';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ValidateCallback } from 'pojo-validator-react';
import { ValidationErrors } from 'pojo-validator';
import { useTranslation } from 'react-i18next';
import { ValidatedNumberInput } from '../../shared/numberInput/ValidatedNumberInput';
import moment from 'moment';
import { ValidatedISODateTimeInput } from '../../shared/ValidatedISODateTimeInput';
import { LearningUnit } from '../../../api/main/models/LearningUnit';
import { LearningUnitType } from '../../../api/main/models/codeOnly/LearningUnitType';
import { useCallback, useState } from 'react';
import { ConditionalFragment } from 'react-conditionalfragment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



export interface learningUnitDetailsProps {
    model?: LearningUnit,
    change: (changes: Partial<LearningUnit>) => void,
    validate: ValidateCallback,
    validationErrors: ValidationErrors,
    currencySymbol: string,
}

/**
 * learningUnit fields for a questionnaire (optional)
 * @param props
 */
export const LearningUnitDetails = (props: learningUnitDetailsProps) => {
    const {
        model,
        change,
        validate,
        validationErrors,
        currencySymbol,
    } = props;

    const { t } = useTranslation();

    const learningUnitTypes = Object.values(LearningUnitType);

    const [showOtherInputText, setShowOtherInputText] = useState<boolean>();

    // change of questionnaireType
    const handleLearningUnitTypeTypeChange = useCallback((isChecked: boolean, selection: string) => {
        if (isChecked) {
            if (selection === "Other") {
                setShowOtherInputText(true);
                change({ learningUnitType: selection });
            }
            setShowOtherInputText(false);
            change({ learningUnitType: selection });
        } else {
            setShowOtherInputText(true)
            change({ learningUnitType: selection });
        }
        
    }, [change]);

    return (
        <>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="name">{t('learningUnitDetails.name', 'Name:')}</Label>
                        <span data-toggle="tooltip" title={t('learningUnitDetails.provider.tooltip', "(Name of the learning event  or course, title of a book)")}><FontAwesomeIcon icon="info-circle" /></span>
                       <ValidatedInput name="name" type="text" value={model?.name ?? ''}
                            onChange={e => change({ name: e.currentTarget.value })}
                            onBlur={e => validate('name')}
                            validationErrors={validationErrors['name']} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="learningUnitType">{t('learningUnitDetails.learningUnitType', 'Type')}</Label>
                        <div className="radio">
                            <Row>
                            {
                                learningUnitTypes.map(item => (
                                    <Col xs={12} sm={6}>
                                    <label key={item}>
                                        <input
                                            type="radio"
                                            value={item}
                                            name="learningUnitType"
                                            checked={model?.learningUnitType === item}
                                            onChange={e => handleLearningUnitTypeTypeChange(e.currentTarget.checked, e.currentTarget.value)}
                                        />{item}
                                        </label>
                                    </Col>
                                ))
                                }
                            </Row>
                        </div>
                        <ConditionalFragment showIf={!!showOtherInputText || model?.learningUnitType === LearningUnitType.Other}>
                            <div>
                                <input
                                    className="form-control"
                                    placeholder="Type"
                                    value={model?.learningUnitType === LearningUnitType.Other ? '' : model?.learningUnitType}
                                    type="text"
                                    name="otherTextBox"
                                    onChange={e => handleLearningUnitTypeTypeChange(false, e.currentTarget.value)}
                                />
                            </div>
                        </ConditionalFragment>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="provider">{t('learningUnitDetails.provider', 'Learning Provider:')}</Label>
                        <> </>
                        <span data-toggle="tooltip" title={t('learningUnitDetails.provider.tooltip', "Optional: the name of the person or company providing the learning.")}><FontAwesomeIcon icon="info-circle" /></span>
                        <ValidatedInput name="provider" type="text" value={model?.provider ?? ''}
                            onChange={e => change({ provider: e.currentTarget.value })}
                            onBlur={e => validate('provider')}
                            validationErrors={validationErrors['provider']} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="startDate">{t('learningUnitDetails.startDate', 'Learning Unit Start Date')}</Label>
                        <span data-toggle="tooltip" title={t('learningUnitDetails.startDate.tooltip', "The date the course or learning event starts. (This is not the Campaign start date)")}><FontAwesomeIcon icon="info-circle" /></span>
                        <ValidatedISODateTimeInput name="startDate" type="date" value={moment(model?.startDate).toISOString() ?? ''}
                            onChange={e => change({ startDate: moment(e.currentTarget.value) })}
                            onBlur={e => validate('endDate')}
                            validationErrors={validationErrors['startDate']} />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="endDate">{t('learningUnitDetails.endDate', 'Learning Unit End Date')}</Label>
                        <ValidatedISODateTimeInput name="endDate" type="date" value={moment(model?.endDate).toISOString() ?? ''}
                            onChange={e => change({ endDate: moment(e.currentTarget.value) })}
                            onBlur={e => validate('endDate')}
                            validationErrors={validationErrors['endDate']} />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormGroup>
                        <Label htmlFor="cost">{t('learningUnitDetails.cost', 'Learning Event Or Resource Cost:')}</Label>
                        <ValidatedNumberInput name="cost" value={model?.cost}
                            onChange={e => change({ cost: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            onBlur={e => validate('cost')}
                            validationErrors={validationErrors['cost']}
                            onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                            currencySymbol={currencySymbol}
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup>
                        <Label htmlFor="costPerLearner">{t('learningUnitDetails.costPerLearner', 'Cost Per Learner')}</Label>
                        <ValidatedNumberInput name="costPerLearner" value={model?.costPerLearner}
                            onChange={e => change({ costPerLearner: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            onBlur={e => validate('costPerLearner')}
                            validationErrors={validationErrors['costPerLearner']}
                            onFormat={value => t('common.number', '{{value, #,0.00}}', { value })}
                            currencySymbol={currencySymbol}
                        />
                    </FormGroup>
                </Col>
            </Row>
        </>
    );
};