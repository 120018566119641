import { useTranslation } from "react-i18next";

/**
 * utilities to keep report customisers consistent and easy to maintain.
 */

//data from which all other functions get their information
const orderedCustomisersWithParents: Array<{customiserName: string, parentCustomiserName: string, displayOrder: number}> =
    [
        { customiserName: 'IncludeSummary', parentCustomiserName: '', displayOrder: 1 },
        { customiserName: 'IncludeGraphs', parentCustomiserName: 'IncludeSummary', displayOrder: 2 },
        { customiserName: 'IncludeIndex', parentCustomiserName: 'IncludeSummary', displayOrder: 3 },
        { customiserName: 'IncludeLearningUnit', parentCustomiserName: 'IncludeSummary', displayOrder: 4 },
        { customiserName: 'IncludeDescription', parentCustomiserName: 'IncludeSummary', displayOrder: 5 },
    ];

// returns customiser data in correct display order
export function useGetOrderedReportCustomiserData(): Array<{ customiserName: string, parentCustomiserName: string, displayOrder: number }> {
    return orderedCustomisersWithParents.sort((a, b) => {
        if (a.displayOrder === b.displayOrder) {
            return 0;
        } else if (a.displayOrder > b.displayOrder) {
            return 1;
        } else {
            return -1;
        }
    });
}

// returns all customiser names in correct display order
export function useGetReportCustomiserNames(): string[] {
    let orderedNames: string[] = [];

    useGetOrderedReportCustomiserData().forEach((customiser) => {
        orderedNames.push(customiser.customiserName)
    });
        
    return orderedNames;
}

// returns all the customiser names of the children of this customiser
export function GetCustomiserChildrenNames(customiserName: string): Array<string> {
    let childCustomisers: string[] = [];

    useGetOrderedReportCustomiserData().filter(item => item.parentCustomiserName === customiserName).forEach((childCustomiser) => {
        childCustomisers.push(childCustomiser.customiserName);
    });

    return childCustomisers;
}


// returns true and the parent customiser name if this customiser is the child of another
export function IsChildCustomiser(customiserName: string): { IsChild: boolean, ParentCustomiserName: string } {
    const thisCustomiser = useGetOrderedReportCustomiserData().find(item => item.customiserName === customiserName && item.parentCustomiserName !== '');

    if (!!thisCustomiser) { return { IsChild: true, ParentCustomiserName: thisCustomiser.parentCustomiserName }; };

    
    return { IsChild: false, ParentCustomiserName: '' }
}

export function GetCustomiserLabelText(customiserName: string): string {
    const { t } = useTranslation();
    switch (customiserName) {
        case 'IncludeSummary':
            return t('reportCustomiser.includeSummary', `Summary?`);
        case 'IncludeGraphs':
            return t('reportCustomiser.includeGraphs', `Summary Dashboard?`);
        case 'IncludeIndex':
            return t('reportCustomiser.includeIndex', `Summary Section Index?`);
        case 'IncludeLearningUnit':
            return t('reportCustomiser.includeLearningUnit', `Summary Learning Unit?`);
        case 'IncludeDescription':
            return t('reportCustomiser.includeDescription', `Summary Description?`);
        default:
            return '';
    }
}