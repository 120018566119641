import { ReactNode, useCallback, useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Badge, Col, ListGroupItem, ListGroupItemText, Row } from "reactstrap";
import { QuestionnaireType, questionnaireTypeDisplayName } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { PercentageProgressBar } from "../progress/PercentageProgressBar";
import { useDashboardBaseRoute } from "../utilities/useDashboardBaseRoute";
import "./dashboardListGroupItem.scss";

export interface DashboardListGroupItemProps {
    children?: ReactNode,
    campaignsNotStarted: QuestionnaireRespondentSession[],
    campaignsStarted: QuestionnaireRespondentSession[],
    campaignsCompleted: QuestionnaireRespondentSession[],
    selfServicesNotStarted: QuestionnaireRespondentSession[],
    selfServicesStarted: QuestionnaireRespondentSession[],
    selfServicesCompleted: QuestionnaireRespondentSession[],

    activeQuestionnaireType: string,

    subscriptionId?: string,
    subscriptionDepartmentId?: string,
    userId?: string,
}

/**
 * List group with items for each group.
 * @param props
 */
export const DashboardListGroupItem = (props: DashboardListGroupItemProps) => {
    const {
        children,
        campaignsNotStarted,
        campaignsStarted,
        campaignsCompleted,
        selfServicesNotStarted,
        selfServicesStarted,
        selfServicesCompleted,

        activeQuestionnaireType,
        subscriptionId,
        subscriptionDepartmentId,
        userId,

    } = props;

    const { t } = useTranslation();

    /*Calculate the Total Ammount of Questionnaires */
    const numberOfCampaigns = Math.floor(campaignsNotStarted.length + campaignsStarted.length + campaignsCompleted.length);
    const numberOfSelfService = Math.floor(selfServicesNotStarted.length + selfServicesStarted.length + selfServicesCompleted.length);

    /*Calculate The Percentage of  completed or started Questionnaires*/
    const percentageQuestionnaires = useCallback((numberOfquestionnaires: number, numberofCompletedOrStarted: number) => {
        if (!numberOfquestionnaires || !numberofCompletedOrStarted) {
            return 0;
        }

        return Math.floor((numberofCompletedOrStarted / numberOfquestionnaires) * 100);
    }, []);

    const baseRoute = useDashboardBaseRoute();

    /* Campaign BaseRoute*/
    const linkBaseRoute = useMemo(() => {
        if (!!subscriptionId && !subscriptionDepartmentId && !userId) {
            return `${baseRoute}/subscription/${subscriptionId}/questionnaires/`;
        }
        if (baseRoute === `/administration/dashboard/subscription/${subscriptionId}`) {
            return `${baseRoute}/department/${subscriptionDepartmentId}/questionnaires/`
        }

        return `${baseRoute}/subscription/${subscriptionId}/department/${subscriptionDepartmentId}/questionnaires/`;



    }, [subscriptionId, subscriptionDepartmentId, userId, baseRoute]);

    return (
        <ListGroupItem tag="div">
            <Row>
                <Col xs={12} md="">
                    <div>
                        {children}
                    </div>

                    <ListGroupItemText tag="div">
                        <ConditionalFragment showIf={activeQuestionnaireType === 'campaigns' || activeQuestionnaireType === 'all'}>
                            <div>
                                <ConditionalFragment showIf={!userId}>
                                    <Link to={`${linkBaseRoute}${QuestionnaireType.Campaign}/`}>
                                        <strong>
                                            {t('dashboardListGroup.campaignsHeading', `${questionnaireTypeDisplayName(QuestionnaireType.Campaign, t)} Questionnaires`)}
                                        </strong>
                                    </Link>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!userId}>
                                    <strong>
                                        {t('dashboardListGroup.campaignsHeading', `${questionnaireTypeDisplayName(QuestionnaireType.Campaign, t)} Questionnaires`)}
                                    </strong>
                                </ConditionalFragment>
                                <div>
                                    {/*    {assessmentsSummary.assessmentsText}*/}
                                </div>
                                <Row>
                                    <Col>
                                        <Badge color="danger">
                                            {t('dashboardListGroup.notStartedCount', 'Not Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {campaignsNotStarted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="warning">
                                            {t('dashboardListGroup.startedCount', 'Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {campaignsStarted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="success">
                                            {t('dashboardListGroup.completedCount', 'Completed')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {campaignsCompleted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    {/*Progress Bar For Campaign Quetionnaires*/}
                                    <Col className="progress-bar-container text-center" >
                                        <PercentageProgressBar
                                            value={percentageQuestionnaires(numberOfCampaigns ?? 0, campaignsStarted.length)}
                                            heading={t('dashboardListGroup.percentageBar.title', 'Started')}
                                            questionnaireType={QuestionnaireType.Campaign} />
                                    </Col>
                                    <Col className="progress-bar-container text-center">
                                        <PercentageProgressBar
                                            value={percentageQuestionnaires(numberOfCampaigns ?? 0, campaignsCompleted.length)}
                                            heading={t('dashboardListGroup.percentageBar.title', 'Completed')}
                                            questionnaireType={QuestionnaireType.Campaign} />
                                    </Col>
                                </Row>
                            </div>
                        </ConditionalFragment>


                        <ConditionalFragment showIf={activeQuestionnaireType === 'all'}>
                            <hr />
                        </ConditionalFragment>

                        <ConditionalFragment showIf={activeQuestionnaireType === 'selfServices' || activeQuestionnaireType === 'all'}>
                            <div>
                                <ConditionalFragment showIf={!userId}>
                                    <Link to={`${linkBaseRoute}${QuestionnaireType.SelfService}/`}>
                                        <strong>
                                            {t('dashboardListGroup.campaignsHeading', `${questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)} Questionnaires`)}
                                        </strong>
                                    </Link>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!userId}>
                                    <strong>
                                        {t('dashboardListGroup.campaignsHeading', `${questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)} Questionnaires`)}
                                    </strong>
                                </ConditionalFragment>
                                <div>
                                    {/*    {trainingSummary.assessmentsText}*/}
                                </div>
                                <Row>
                                    <Col>
                                        <Badge color="danger">
                                            {t('dashboardListGroup.notStartedCount', 'Not Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {selfServicesNotStarted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="warning">
                                            {t('dashboardListGroup.startedCount', 'Started')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {selfServicesStarted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    <Col>
                                        <Badge color="success">
                                            {t('dashboardListGroup.completedCount', 'Completed')}
                                            <> </>
                                            <Badge pill style={{ fontSize: '100%' }}>
                                                {selfServicesCompleted.length}
                                            </Badge>
                                        </Badge>
                                    </Col>
                                    {/*Progress Bar For SelfService Quetionnaires*/}
                                    <Col className="progress-bar-container text-center">
                                        <PercentageProgressBar
                                            value={percentageQuestionnaires(numberOfSelfService ?? 0, selfServicesStarted.length)}
                                            title={t('dashboardListGroup.percentageBar.title', 'Started')}
                                            questionnaireType={QuestionnaireType.SelfService} />
                                    </Col>
                                    <Col className="progress-bar-container text-center">
                                        <PercentageProgressBar
                                            value={percentageQuestionnaires(numberOfSelfService ?? 0, selfServicesCompleted.length)}
                                            title={t('dashboardListGroup.percentageBar.title', 'Completed')}
                                            questionnaireType={QuestionnaireType.SelfService} />
                                    </Col>
                                </Row>
                            </div>
                        </ConditionalFragment>
                    </ListGroupItemText>
                </Col>
            </Row>
        </ListGroupItem>
    );
};