import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {  useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import "./analyserSelectGraphTypeModal.scss";
import { faChartBar } from "@fortawesome/free-regular-svg-icons";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";


export interface AnalyserSelectGraphTypeModalProps{
    isOpen: boolean,
    toggle: (isOpen: boolean) => void,
    setGraphType: (type: 'bar' | 'horizontalBar') => void,
}

export const AnalyserSelectGraphTypeModal = (props: AnalyserSelectGraphTypeModalProps) => {
    const {
        isOpen,
        toggle,
        setGraphType
    } = props;

    const { t } = useTranslation();

    const updateGraphType = useCallback((graphType: 'bar' | 'horizontalBar') => {
        setGraphType(graphType);
        toggle(!isOpen);
    }, [setGraphType, toggle, isOpen]);

    return (
        <Modal className="analyser-select-graphtype-modal report-name-modal" isOpen={isOpen} toggle={() => toggle(!isOpen)}>
            <ModalHeader toggle={() => toggle(!isOpen)}>
                {
                    t('analyserSelectGraphTypeModal.heading.please-choose-a-graph-type', 'Please choose a Graph type')
                }
            </ModalHeader>
            <ModalBody className="graph-modal-body">
                <Row className="text-center">
                        <Button color="primary" onClick={() => updateGraphType("bar")}>
                            <Row>
                                <FontAwesomeIcon icon={faChartBar} />
                            </Row>
                            <> {t('selectGraphTypeModal.column-chart', 'Column Chart')}</>
                        </Button>
                        <Button color="primary" onClick={() => updateGraphType("horizontalBar")}>
                            <Row>
                                {/*div here is to add a bar next to the align left icon so we can get an icon that looks like a horizontal bar chart*/}
                                <div style={{ borderLeft: '5px solid white', marginRight: '3px' }}></div>
                                <FontAwesomeIcon icon={faAlignLeft} />
                            </Row>
                            <> {t('selectGraphTypeModal.bar-chart', 'Bar Chart')}</>
                        </Button>
                </Row>
            </ModalBody>
        </Modal>
    )
};