import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap"
import { FormButtons } from "../shared/FormButtons";

export interface ConfirmCancelQuestionaireModalProps {
    isOpen: boolean,
    toggle: () => void,
    removeItem: () => void,
    saveItem: () => void,
}

/*
 * Modal to confirm the cancel of a questionnaire, that also has the option to stop the cancel and return to creating 
 * the questionnaire or save, or confirm the cancel
 */

export const ConfirmCancelQuestionnaireModal = (props: ConfirmCancelQuestionaireModalProps) => {
    const {
        isOpen,
        toggle,
        removeItem,
        saveItem,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <Modal isOpen={isOpen} className="select-question-modal">
                <ModalHeader toggle={() => toggle()}>
                    {t('confirmCancelQuestionaireModal.header', ' Are You Sure?')}
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            {t('confirmCancelQuestionaireModal.warningText', 'If you cancel now you will lose any progress you have made.')}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {t('confirmCancelQuestionaireModal.optionToSaveForLaterText', 'You could choose to save your progress and carry on later, or continue building now.')}
                        </Col>
                    </Row>
                    <FormButtons>
                        <ButtonGroup>
                            <Button color="danger" onClick={() => { removeItem(); toggle(); }}>
                                <FontAwesomeIcon icon="ban" />
                                <> </>
                                {t('confirmCancelQuestionaireModal.confirmCancel', 'Confirm Cancel')}
                            </Button>
                            <Button color="primary" outline onClick={() => { saveItem(); toggle(); }}>
                                <FontAwesomeIcon icon="save" />
                                <> </>
                                {t('confirmCancelQuestionaireModal.saveForLater', 'Save For Later')}
                            </Button>
                            <Button color="primary" onClick={() => toggle()}>
                                {t('confirmCancelQuestionaireModal.continue', 'Continue')}
                            </Button>
                        </ButtonGroup>
                    </FormButtons>
                </ModalBody>
            </Modal>
        </>
    );
};