import { Page, Text, View } from "@react-pdf/renderer";
import { ReactNode } from "react";
import { AnalyserReportPageStyles } from "./analyserReportPageStyles";
import { AnalyserReportFooter } from "./AnalyserReportFooter";
import { AnalyserReportHeader } from "./AnalyserReportHeader";
import { ConditionalFragment } from "react-conditionalfragment";

//* Common reporting page for analyser reports
interface AnalyserReportPageProps {
    companyColor: string,
    impactsLogoUrl: string,
    pageHeader: string,
    reportTitle: string,
    subTitle?: string | undefined,
    children?: ReactNode,
}

export const AnalyserReportPage = (props: AnalyserReportPageProps) => {
    const {
        companyColor,
        impactsLogoUrl,
        pageHeader,
        reportTitle,
        subTitle,
        children,
    } = props;

    return (
        <Page size="A4" style={[AnalyserReportPageStyles.reportPage, AnalyserReportPageStyles.body]} wrap>
            <AnalyserReportHeader impactsLogoUrl={impactsLogoUrl} companyColor={companyColor} reportTitle={reportTitle} />
            <View style={AnalyserReportPageStyles.reportPageBody} >
                <View style={[AnalyserReportPageStyles.pageHeaderBlueBackground, { backgroundColor: companyColor }]}>
                    <Text style={AnalyserReportPageStyles.pageHeaderBlueBackgroundText}>{pageHeader}</Text>
                </View>

                <ConditionalFragment showIf={!!subTitle}>
                    <View>
                        <Text style={AnalyserReportPageStyles.subTitle}>{subTitle}</Text>
                    </View>
                </ConditionalFragment>

                {children}
            </View>

            <AnalyserReportFooter impactsLogoUrl={impactsLogoUrl} />
        </Page>
    );
};