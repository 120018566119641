import { Image, View } from "@react-pdf/renderer";
import { PerformanceGraphPageStyles } from "./performanceGraphPageStyles";

//* Common reporting page for analyser reports - Performance Page
interface PerformanceGraphPageProps {
    chartImages: string[],
}

export const PerformanceGraphPage = (props: PerformanceGraphPageProps) => {
    const {
        chartImages,
    } = props;

    return (
        <View style={PerformanceGraphPageStyles.graphContainer}>
            <Image style={PerformanceGraphPageStyles.graphImage} source={chartImages[0]} />
        </View>
    );
};
