import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const IndivdualReportPdfStyles = StyleSheet.create({
    splashPage: {
        padding: "0mm",
    },
    splashPageImage: {
        width: "210mm",
        height: "100%",
    },
    splashPageTitleView: {
        margin: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flexDirection: "row",
    },
    splashPageEndDate: {
        paddingTop: 2,
        paddingBottom: 5,
        marginLeft: 10,
        fontSize: "3.466666667mm",
        fontWeight: 550,
    },
    splashPageEndOfProgrammeReview: {
        paddingTop: 5,
        paddingBottom: 2,
        marginLeft: 10,
        fontSize: "4.466666667mm",
        fontWeight: 550,
    },
    userName: {
        paddingTop: 10,
        paddingBottom: 2,
        marginLeft: 10,
        fontSize: "7.466666667mm",
        fontWeight: 550,
    },
    companyLogo: {
        objectFit: "fill",
        paddingHorizontal: "10%",
        
    },
    companyLogoView: {
        border: "1mm",
        borderStyle: "solid",
        borderColor: "#e4e7ea",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    splashPageTitle: {
        paddingTop: 5,
        paddingBottom: 5,
        marginLeft: 10,
        fontSize: "6.466666667mm",
        fontWeight: 550,
    },
    imapctsImage: {
        marginTop: "10mm",
        height: "37.5pt",
        width: "105.75pt",
        marginLeft: "auto",
        marginRight: "auto",
    },
    splashPageUserName: {
        paddingTop: 20,
        fontSize: "6.466666667mm",
        textAlign: "center",
        fontWeight: 550,
    },

    imapctsImageMainPage: {
        marginTop: "2mm",
        height: "10mm",
        width: "30mm",
        marginLeft: "2",
    },
    reportPage: {
        padding: "15",
        fontWeight: 550,
    },
    reportPageTitle: {
        paddingTop: "5mm",
        paddingLeft: "5mm",
        paddingRight: "5mm",
        paddingBottom: "5mm",
        fontSize: "7.466666667mm",
        textAlign: "center",
        fontWeight: 550,
        borderBottom: 5,
    },
    questionnaireSection: {
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#ddeeff",
        fontWeight: 550,
    },
    SectionRow: {
        flexDirection: "row",
    },
    sectionInitial: {
        fontSize: "6.4666666667mm",
        color: "#1188FF",
        fontWeight: 550,
    },
    sectionName: {
        fontSize: "6.4666666667mm",
        color: "#1188FF",
        paddingLeft: "10mm",
        fontWeight: 550,
    },
    sectionDescription: {
        fontSize: "4.4666666667mm",
        color: "#8f9ba6",
        paddingTop: "3mm",
    },
    questionTextBox: {
        backgroundColor: "#f3f4f5",
        minHeight: "25.2pt",
        marginTop: "15pt",
        fontWeight: 550,
    },
    questionText: {
        fontSize: "4mm",
        marginTop: "3mm",
        marginLeft: "3mm",
        marginBottom: "3mm",
        marginRight: "3mm",
        fontWeight: 550,
    },
    responseRow: {
        flexDirection: "row",
        fontSize: "3mm",
        fontWeight: "bold",
        border: "0.364583333mm",
        borderColor: "#ddeeff",
        minHeight: "31.5pt",
        marginBottom: "2.25pt",

    },
    response: {
        flexDirection: "row",
        paddingLeft: "7.5pt",
        minHeight: "31.5pt",
    },
    preTextScale: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 750,
        minWidth: "40pt",
        textAlign: "justify",
    },
    preTextTextBox: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 550,
    },
    displayText: {
        padding: "1%",
        marginTop: "auto",
        marginBottom: "auto",
        fontWeight: 550,
    },
    rowView: {
        flexDirection: "row"
    },
    columnView: {
        flexDirection: "column",
        width: "50%"
    },
    sessionDetailsView: {
        marginTop: "5mm",
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#c8ced3",
    },
    sessionTitle: {
        marginBottom: "2mm",
        fontWeight: "bold",
    },
    participantNameLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    participantFullName: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    participantEmailLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    participantEmail: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    lineManagerEmailLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    lineManagerEmail: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    lineManagerNameLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    lineManagerName: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    summaryView: {
        marginTop: "3mm",
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#f0bcdb",
    },
    summaryTitle: {
        marginBottom: "2mm",
        color: "#b7297d",
        fontWeight: "bold",
    },
    summaryTitleView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
    },
    questionnaireDescriptionView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
        marginTop: "3mm",
        marginBottom: "3mm"
    },
    questionnaireDescriptionLabel: {
        fontSize: "11.5pt",
    },
    questionnaireDescription: {
        fontSize: "10pt",
        marginTop: "3mm",
        marginBottom: "3mm"
    },
    learningUnitView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
        marginTop: "3mm",
    },
    learningUnitRow: {
        flexDirection: "row"
    },
    learningUnitColumn: {
        flexDirection: "column",
        width: "50%"
    },
    learningUnitTitle: {
        fontSize: "13pt",
    },
    learningUnitNameLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitName: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitTypeLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitType: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitByLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitBy: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitStartDateLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitStartDate: {
        fontSize: "10pt",
        marginBottom: "3mm",
        fontWeight: "normal",
    },
    learningUnitEndDateLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitEndDate: {
        fontSize: "10pt",
        marginBottom: "3mm",
        fontWeight: "normal",
    },

});