import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Row, Table } from "reactstrap";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./selectQuestionnaireModal.scss";
//import { QuestionTagDisplay } from "../../questionTags/questionTagSelector/QuestionTagDisplay";
import { useQuestionnaires } from "../../../api/main/questionnaires/useQuestionnaires";
import { useCurrentUserOrEmulatedSubscriptionId } from "../../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { useSubscriptionTemplateLinks } from "../../../api/main/subscriptionTemplateLinks/useSubscriptionTemplateLinks";

export interface SelectQuestionnaireModalCloseEventArgs {
    /**
     * Selected questionnaire id.
     * */
    selectedId: string | undefined,

}

export interface SelectQuestionnaireModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    isLoadingQuestionnaires?: boolean,

    onClose: (event: { selectedId: string | undefined }) => void,

    // tellls us whether to display templates first or questionnaires
    isTemplate?: boolean,
}

/**
 * Modal that allows the user to select from a list of templates and previous questionnaires.
 * @param props
 */
export const SelectQuestionnaireModal = (props: SelectQuestionnaireModalProps) => {
    const {
        isOpen,
        toggle,

        isLoadingQuestionnaires,
        onClose,

        isTemplate,
    } = props;

    const { t } = useTranslation();
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();

    // gets all questionnaires and templates for the subscription and all templates with no subscriptionId
    const { data: { items: allItems } } = useQuestionnaires({ pageSize: undefined, subscriptionId: currentUserSubscriptionId, includeAll: true, includeDefault: true });

    // get the subscriptionTemplateLinks for this subscription
    const { data: { items: subscriptionTemplateLinks } } = useSubscriptionTemplateLinks({ subscriptionId: currentUserSubscriptionId });

    // Close the modal and return the id of the selected template or questionnaire.
    const closeModal = useCallback((event?: { selectedId: string }) => {
        if (onClose) {
            // can be '' if closed without a selection
            const selectedId = event?.selectedId;

            const externalEvent: SelectQuestionnaireModalCloseEventArgs = {
                ...(event ?? {}),

                selectedId: selectedId,

            };
            onClose(externalEvent);
        }

        // Close the modal.
        toggle();
    }, [toggle, onClose,]);

    // Filtering of questionnaires.
    const [search, setSearch] = useState<string>('');

    // Filter by the questionnaire's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;
        // Filter by the user's search text.
        if (search) {
            let lowerSearch = search.toLocaleLowerCase();


            ret = ret.filter(item =>
                item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );

        }

        return ret;

    }, [allItems, search,]);

    // get all templates 
    const allTemplates = useMemo(() => {
        if (!items) {
            return [];
        }
        let ret = items
            .filter(item => {
                if (!item.isTemplate) {
                    // we don't want questionnaires
                    return false;
                } else if (!!item.subscriptionId) {
                    // this is a template belonging to this subscription
                    return true;
                } else {
                    // these are top level templates so check if we have a link
                    if (!subscriptionTemplateLinks) {
                        return false;
                    }
                    const thisLink = subscriptionTemplateLinks.find(link => link.questionnaireId === item.id);
                    return !!thisLink && !thisLink.isHiddenBySubscriptionAdministrator;
                }
            })
            .sort((a, b) => { 
                if (a.name === b.name) {
                    return 0;
                } else if (a.name > b.name) {
                    return 1;
                } else {
                    return -1
                }
            });
        return ret;
    }, [items, subscriptionTemplateLinks]);

    // get all questionaires (not templates)
    const allQuestionnaires = useMemo(() => {
        if (!items) {
            return [];
        }
        let ret = items.filter(item => !item.isTemplate).sort((a, b) => a.name > b.name ? 1 : -1);
        return ret;
    }, [items]);

    // generate a table with the c orrect sub-heading and questionnaire items
    const generateTable = useCallback((inputQuestionnaires: Questionnaire[], type: string) => {
        return (
            <>
                <h2>{type}</h2>
                <Table className="select-questionnaire-modal-table" responsive striped>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="d-table-cell d-lg-none">{t('selectQuestionnaireModal.question', 'Questionnaire')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionnaireModal.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionnaireModal.description', 'Description')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            inputQuestionnaires?.map(item => {
                                return (
                                    <tr key={item.id} onDoubleClick={e => closeModal({ selectedId: item.id })}>
                                        <td style={{ width: '1px' /* Sizes to fit contents */ }}>
                                            <ButtonGroup>
                                                <Button color="secondary" outline onClick={e => closeModal({ selectedId: item.id })}>
                                                    {
                                                        t('selectQuestionnaireModal.select', 'Select')
                                                    }
                                                </Button>
                                            </ButtonGroup>
                                        </td>
                                        <td className="d-table-cell d-lg-none">
                                            <div>{item.name}</div>
                                            <div>{item.isTemplate ? item.templateDescription : item.description}</div>
                                        </td>
                                        <td className="d-none d-lg-table-cell">{item.name}</td>
                                        <td className="d-none d-lg-table-cell">{item.isTemplate ? item.templateDescription : item.description}</td>
                                    </tr>
                                );
                            })
                        }
                        <ConditionalFragment showIf={!!isLoadingQuestionnaires && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoadingQuestionnaires && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </>
        )
    }, [closeModal, isLoadingQuestionnaires, items, search, t]);

    // UI
    //
    const colCount = 3;

    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="select-questionnaire-modal">
            <ModalHeader toggle={() => toggle()}>
                {
                    t('selectQuestionnaireModal.heading.default', 'Select a template or questionnaire to start you off')
                }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>
                {/* if the user chose to pick a template show templates */}
                <ConditionalFragment showIf={!!isTemplate}>
                    {generateTable(allTemplates, t('selectQuestionnaireModal.headerTemplates', 'Templates'))}

                    {/* Commented Out The option to show Questionnaires along with the Templates by Request but Left the code incase we want to revert back*/}
                    {/* {generateTable(allQuestionnaires, t('selectQuestionnaireModal.headerQuestionnaires', 'Questionnaires'))}*/}
                </ConditionalFragment>
                {/* if the user chose to pick a questionnaire show questionnaires */}
                <ConditionalFragment showIf={!isTemplate}>
                    {generateTable(allQuestionnaires, t('selectQuestionnaireModal.headerCampaigns', 'Campaigns'))}

                    {/* Commented Out The option to show Templates along with the Questionnaires by Request but Left the code incase we want to revert back*/}
                    {/* {generateTable(allTemplates, t('selectQuestionnaireModal.headerTemplates', 'Templates'))} */}
                </ConditionalFragment>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" outline onClick={() => closeModal({ selectedId: '' })}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};