import { AddQuestionnaireBase } from "./AddQuestionnaireBase";

/**
 * Create a new top level template as an administrator.
 */
export const CreateTemplate = () => {


    return (
        <AddQuestionnaireBase
            isSubscription={false}
        />
    );
};