import { ApexOptions }from "apexcharts";
import Chart from "react-apexcharts";
import { useMemo } from "react";
import { Card } from "reactstrap";
import { ChartDataSeries } from "../chartDataUtilities/ChartData";
import { chartDataConverters } from "../chartDataUtilities/ChartDataConverter";
import { useTranslation } from "react-i18next";
import { garChartColours} from "./dashboardChartColors";
import "./CompletionBarChart.scss";
export interface CompletionBarChartProps {
    data: Array<ChartDataSeries>,

    width?: string | number | undefined,
    height?: string | number | undefined,
}

/**
 * Chart that shows average scores as a radar.
 */
export const CompletionBarChart = (props: CompletionBarChartProps) => {
    const {
        data,
        width,
        height,
    } = props;

    const { t } = useTranslation();

    // Convert data into format needed for this graph.
    const { series, options: seriesOptions } = useMemo(() => chartDataConverters.toColumn(data), [data]);

    // Compile all the options we want to use.
    const options = useMemo(() => (
        {
            toolbar: {
                show: false,
            },

            chart: {
                stacked: true,
            },

            title: {
                text: t('CompletionChart.title', 'Completion Progress'),
                align: 'center',
            },

            colors: garChartColours,

            ...seriesOptions,
        } as ApexOptions
    ), [seriesOptions, t,]);

    // We need to force the refresh of the chart sometimes by updating its key as its too optomistic with its internal caching and
    // so doesn't end up changes top options very well at all if we dont.
    const key = useMemo(() => JSON.stringify(options), [options]);

    return (
        <Card body tag="div" className={"chartBoarder"}>
            <Chart key={key} options={options} series={series} type="bar" width={width} height={height} />
        </Card>
    );
};