import * as React from "react";
import { Alert, Button, Col, Container, Row } from "reactstrap";
import { useConfirmEmailCallback } from "../../api/account";
import { useTranslation } from "react-i18next";
import { LinkContainer } from "react-router-bootstrap";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { ConditionalFragment } from "react-conditionalfragment";
import './ConfirmEmail.scss';

/**
 * Confirm an email address for an account using a unique link included in the email. 
 */
export const ConfirmEmail = () => {
    const { t } = useTranslation();
    const [emailConfirmed, setEmailConfirmed] = React.useState<boolean>(false);
    const [confirmEmail, { errors: confirmEmailErrors }] = useConfirmEmailCallback();

    // Trigger the email confirmation on page load.
    React.useEffect(() => {
        (async () => {
            if (!emailConfirmed) {
                const ok = await confirmEmail();
                setEmailConfirmed(ok);
            }
        })();
    }, [confirmEmail, setEmailConfirmed, emailConfirmed]);

    // Render the UI.
    // 
    return (
        <Container fluid className="confirm-email-page">
            <Row className="confirm-email-screen-row">
                <Col xs={12} sm={5} className="login-screen-target-column">
                    <div className="login-target-image"></div>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
                <Col xs={12} sm={5} className="login-screen-input-column">
                    <Row className="impacts-logo-login-row">
                        <div className="impacts-logo-login-image"></div>
                    </Row>
                    <Row>
                        <Row>
                            <Col>
                                <h1>
                                    {
                                        confirmEmailErrors ? t('confirmEmail.confirmEmailFailureHeading', 'Sorry we cannot confirm your email using this code')
                                            : emailConfirmed ? t('confirmEmail.confirmEmailSuccessHeading', 'Thanks for confirming your email')
                                                : (<LoadingIndicator />)
                                    }
                                </h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ConditionalFragment showIf={!!confirmEmailErrors}>
                                    <Alert color="danger">
                                        {confirmEmailErrors}
                                    </Alert>
                                </ConditionalFragment>
                                <ConditionalFragment showIf={!!emailConfirmed}>
                                    {/*<Alert color="success">*/}
                                    {/*    {t('confirmEmail.thankYouForConfirming', 'Thank you for confirming your email.  You account setup is now complete.')}*/}
                                    {/*</Alert>*/}
                                    <p className="confirm-text">
                                        {t('confirmEmail.thankYouForConfirming', 'Thank you for confirming your email.  You account setup is now complete.')}
                                    </p>
                                    <Row className="sign-in-class" >
                                        <Row className="sign-in-button">
                                            <LinkContainer to="/authentication/login">
                                                <Button color="secondary">
                                                    {t('confirmEmail.signInToContinue', 'Please sign in to continue')}
                                                </Button>
                                            </LinkContainer>
                                        </Row>
                                    </Row>
                                </ConditionalFragment>
                            </Col>
                        </Row>
                    </Row>
                </Col>
                <Col xs={12} sm={1}>
                </Col>
            </Row>
        </Container>
    );
};
