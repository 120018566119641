import { Image, Text, View } from "@react-pdf/renderer";
import { AnalysisType } from "../../../../api/main/models/AnalysisType";
import { PerformancePageStyles } from "./performancePageStyles";

//* Common reporting page for analyser reports - Performance Page
interface PerformancePageProps {
    categoryResults: { analysisType: AnalysisType, data: { result: number, arrow?: 'up' | 'down' } }[],
    analysisTypeIconUrls: { analysisTypeName: string, iconUrl: string }[],
}

export const PerformancePage = (props: PerformancePageProps) => {
    const { categoryResults,
        analysisTypeIconUrls,
    } = props;

    return (
        <>
            {categoryResults.map((categoryResult, index) => {
                const url = analysisTypeIconUrls.find(item => item.analysisTypeName === categoryResult.analysisType.name)?.iconUrl ?? analysisTypeIconUrls.find(item => item.analysisTypeName === 'Default')?.iconUrl;
                const change = categoryResult.data.arrow ?? 'up';
                const arrowUrl = change === 'up' ? analysisTypeIconUrls.find(item => item.analysisTypeName === 'Up Arrow')?.iconUrl : analysisTypeIconUrls.find(item => item.analysisTypeName === 'Down Arrow')?.iconUrl;
                return (
                    <View key={index} style={PerformancePageStyles.analysisTypeContainer}>
                        <Image style={PerformancePageStyles.categoryIconImage} source={url} />
                        <Text style={[PerformancePageStyles.categoryDetail, PerformancePageStyles.categoryText]}>{categoryResult.analysisType.name}</Text>
                        <Image style={PerformancePageStyles.analyserArrow} source={arrowUrl} />
                        <Text style={[PerformancePageStyles.categoryDetail, PerformancePageStyles.categoryScore]}> {Math.floor(categoryResult.data.result)}%</Text>
                    </View>
                );
            })}
        </>
    );
};