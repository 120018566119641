import { RouteEntry } from "../../shared/routes";
import { SubscriptionsList } from './SubscriptionsList';
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { EditSubscription, CreateSubscription, EditBranding } from "./EditSubscription";

export const subscriptionRoutes: Array<RouteEntry> = [
    { path: '/manage/subscription/edit/:id', component: EditSubscription, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptions/edit/branding', component: EditBranding, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

    { path: '/administration/subscriptions', exact: true, component: SubscriptionsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/subscriptions/edit/branding/:id', exact: true, component: EditSubscription, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/subscriptions/add', component: CreateSubscription, authorize: true, requireRole: IdentityRoles.Administration },
];
