import { Question } from "../../../api/main/models/Question";
import { useSubscriptionReportViewModelQuery_profilesForQuestionnaires } from "../../../api/main/generated/useSubscriptionReportViewModelQuery";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { ConditionalFragment } from "react-conditionalfragment";
import { QuestionResponsePDF } from "./QuestionResponsePDF";
import { Text, View } from "@react-pdf/renderer";
import { QuestionPDFStyles } from "./QuestionPDFStyles";


// Component for displaying a question and its responses on a PDF

export interface QuestionPDFProps {
    question: Question,
    orderedResponses: QuestionResponse[],

    displayResponse: (question: Question, response: QuestionResponse, index: number) => string;
    displayPreText: (question: Question, response: QuestionResponse, index: number) => string;

    orderedUsers?: useSubscriptionReportViewModelQuery_profilesForQuestionnaires[];
}

export const QuestionPDF = (props: QuestionPDFProps) => {
    const {
        question,
        orderedResponses,
        displayResponse,
        displayPreText,
        orderedUsers = [],
    } = props;

    function decodeHtml(html: string): string {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.documentElement.textContent ?? '';
    }

    return (
        <View key={question.id}>
            <ConditionalFragment showIf={!!orderedUsers.length}>
                <View style={QuestionPDFStyles.questionTextBox} wrap={false}>
                    <Text style={QuestionPDFStyles.questionText}>{question.questionText}</Text>
                </View>
                <View>
                     {/*display all users within each question */}
                    {orderedUsers.map(user => {
                        return (
                            <View key={user.id}>
                                <View style={QuestionPDFStyles.userRow}>
                                    <Text>{user.firstName}</Text>
                                    <Text style={QuestionPDFStyles.userLastName}>{user.lastName}</Text><Text>:</Text>
                                </View>
                                <View>
                                        {/*Display all responses for a user for this question */}
                                        {orderedResponses.filter(item => item.userId === user.userId).map((response, index) => {
                                            return (
                                                <QuestionResponsePDF
                                                    key={response.id}
                                                    response={response}
                                                    displayText={displayResponse(question, response, index)}
                                                    preText={displayPreText(question, response, index)}
                                                    responseType={question.responseType}
                                                />
                                            )
                                        })}
                                </View>
                            </View>
                        )
                    }
                    )}
                </View>
            </ConditionalFragment>
            <ConditionalFragment showIf={!orderedUsers.length}>
                    <View>
                        <View style={QuestionPDFStyles.questionTextBox} wrap={false} key={question.id}>
                        <Text style={QuestionPDFStyles.questionText}>{decodeHtml(question.questionText)}</Text>
                        </View>
                        <View>
                            {orderedResponses.map((response, index) => {
                                return (
                                    <QuestionResponsePDF
                                        key={response.id}
                                        response={response}
                                        displayText={displayResponse(question, response, index)}
                                        preText={displayPreText(question, response, index)}
                                        responseType={question.responseType}
                                    />
                                )
                            })}
                        </View>
                    </View>
            </ConditionalFragment>
        </View>
    );
};