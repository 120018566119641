import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { questionnaireDashboardViewModelQuery, questionnaireDashboardViewModelQueryVariables } from '../../generated/questionnaireDashboardViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { Guid } from 'guid-string';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { profileFieldsFragment } from '../../models/Profile';
import { subscriptionDepartmentFieldsFragment } from '../../models/SubscriptionDepartment';
import { questionnaireFieldsFragment } from '../../models/Questionnaire';
import { questionnaireRespondentSessionFieldsFragment } from '../../models/QuestionnaireRespondentSession';
import { questionResponseFieldsFragment } from '../../models/QuestionResponse';
import { questionFieldsFragment } from '../../models/Question';

/**
 * View model for QuestionnaireDashboard to loads all the right related data.
 * @param options
 */
export function useQuestionnaireDashboardViewModel(id: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<questionnaireDashboardViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<questionnaireDashboardViewModelQuery, questionnaireDashboardViewModelQueryVariables>(
        gql`
        query questionnaireDashboardViewModelQuery ($id: ID!) {
            model: subscription(id: $id) {
                ...subscriptionFields
            }

            subscriptionDepartments(subscriptionId: $id) {
                ...subscriptionDepartmentFields
            }

            profilesForSessions: profiles(hasSessionForSubscriptionId: $id) {
                ...profileFields
            }

            questionnaires {
                ...questionnaireFields
                 questions {
                    ...questionFields
                }
            }

            questionnaireRespondentSessions (subscriptionId: $id) {
                ...questionnaireRespondentSessionFields
                responses {
                    ...questionResponseFields
                }
            }

        }

        ${subscriptionFieldsFragment}
        ${subscriptionDepartmentFieldsFragment}
        ${profileFieldsFragment}
        ${questionnaireFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${questionResponseFieldsFragment}
        ${questionFieldsFragment}
        `,
        {
            variables: {
                id: id ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}