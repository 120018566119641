import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory, useParams } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleState, useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { useCurrentUserOrEmulatedSubscriptionId } from '../../globalState/subscriptions/useCurrentUserOrEmulatedSubscriptionId';
import { RoleGroup, roleGroupDisplayNameFromString } from '../../api/main/models/codeOnly/RoleGroup';
import { useListProfilesViewModel } from '../../api/main/profiles/viewModels/useListProfilesViewModel';
import { CardsOrTable, CardsOrTableViewMode } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useDeleteProfileCallback } from '../../api/main/profiles/useDeleteProfileCallback';
import { useLockoutCallback } from '../../api/account';
import { useProfile } from '../../api/main/profiles/useProfile';
import { DeleteAnyModal } from '../shared/DeleteAnyModal';

export interface RegisteredUsersListBaseProps {
    title: string,
    baseRoute: string,
    isExcludeDepartment?: boolean,
    overrideViewMode?: CardsOrTableViewMode | undefined,
    suppressBanner?: boolean,
    addButtons?: React.ReactNode,
    customListItemButtons?: Function,
}

/**
 * Base list of registered users.
 */
export const RegisteredUsersListBase = (props: RegisteredUsersListBaseProps) => {
    const { title, baseRoute, isExcludeDepartment = false, overrideViewMode, suppressBanner = false, addButtons, customListItemButtons } = props;

    const { t } = useTranslation();
    const { departmentId } = useParams<{ departmentId: string | undefined }>();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');
    const currentUserSubscriptionId = useCurrentUserOrEmulatedSubscriptionId();
    const { data: { items: allItems, departments }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useListProfilesViewModel({ pageSize: undefined, subscriptionId: currentUserSubscriptionId, subscriptionDepartmentId: !!departmentId && !isExcludeDepartment ? departmentId : undefined, notSubscriptionDepartmentId: !!departmentId && !!isExcludeDepartment ? departmentId : undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();

    // Filter by the user's search client side so it can work when offline as well as online.
    const items = useMemo(() => {

        if (!allItems) {
            return [];
        }

         // Only want to show non-administrators.
        let ret = allItems.filter(item => item.user?.roleGroup?.id !== RoleGroup.Administrator);

       if (!search) {
            return ret;
        }


        let lowerSearch = search.toLocaleLowerCase();

        // Filter the items being displayed.
        return ret.filter(item =>
            item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
            || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
            || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
            || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
        );
    }, [allItems, search]);

    useReplaceSearchParamsEffect({ search: search });
    
    const displayItems = useMemo(() => {
        //Sort items by First Name, then Last Name, Then email
        if (!items) {
            return [];
        }

        let ret = [...items];

        ret.sort((a, b) => (
            a.firstName.localeCompare(b.firstName) ||
            a.lastName.localeCompare(b.lastName) ||
            a.user.email.localeCompare(b.user.email)
        ));

        return ret
    }, [items]);

    // override viewmode is used 
    const [viewMode, setViewMode] = usePreferredListViewMode();

    // show the buttons to switch viewmode unless we are overriding them in which case they are fixed
    const [showViewModeButtons, setShowViewModeButtons] = useState<boolean>(true);
    useEffect(() => {
        setShowViewModeButtons(!!overrideViewMode ? false : true)
    }, [setShowViewModeButtons, overrideViewMode]);


    // Delete Modal 
    const [deleteAnyItemModalIsOpen, toggleDeleteAnyItemModal] = useToggleState();
    const [remove, { errors: removeErrors }] = useDeleteProfileCallback();
    const [lockout, { errors: lockoutErrors }] = useLockoutCallback();
    const [deletedItemId, setDeletedItemId] = useState<string>("");
    const { data: { model: deletedProfile }, isLoading: loadDeleteProfile, errors: loadErrors } = useProfile(deletedItemId);
    const deletedUserEmail = deletedProfile?.user.email ?? '';

    const standardAddButtons = () => {
        return (
                <LinkContainer to={`${baseRoute}/${!!departmentId ? departmentId + '/' : ''}add`}>
                    <Button color="primary">
                        <FontAwesomeIcon icon="plus" /><> {t('registeredUserList.add', 'Add')}</>
                    </Button>
                </LinkContainer>
        )
    }

    const standardListItemButtons = useCallback((itemId: string) => {
        return (
            <ButtonGroup>
                <LinkContainer to={`${baseRoute}/edit/${itemId}`}>
                    <Button color="primary" outline>
                        <FontAwesomeIcon icon="edit" />
                        <> {t('common.edit', 'Edit')}</>
                    </Button>
                </LinkContainer>
                <ButtonDropdown isOpen={isMenuOpen(itemId)} toggle={() => toggleMenuOpen(itemId)}>
                    <DropdownToggle color="primary" outline caret>
                        <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                        <ConditionalFragment showIf={!!departmentId}>
                            <LinkContainer to={`${baseRoute}/remove/${itemId}`}>
                                <DropdownItem className="text-primary">
                                    <FontAwesomeIcon icon="trash" />
                                    <> {t('registeredUserList.remove', 'Remove from department')}</>
                                </DropdownItem>
                            </LinkContainer>
                        </ConditionalFragment>
                        <DropdownItem className="text-danger" onClick={() => { toggleDeleteAnyItemModal(); setDeletedItemId(itemId); }}>
                            <FontAwesomeIcon icon="trash" />
                            <> {t('common.delete', 'Delete')}</>
                        </DropdownItem>
                    </DropdownMenu>
                </ButtonDropdown>
            </ButtonGroup>
        )
    }, [baseRoute, departmentId, isMenuOpen, toggleMenuOpen, t, toggleDeleteAnyItemModal, setDeletedItemId]);



    return (
        <Background>
            <ConditionalFragment showIf={!suppressBanner}>
                <Banner fluid>
                    {/*<Row>*/}
                    {/*    <Col xs={12}>*/}
                    {/*        <PillsNavBar>*/}
                    {/*            {navigationPills}*/}
                    {/*        </PillsNavBar>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row className={"bannerNoPills"}>
                        <Col>
                            <h1>{title}</h1>
                        </Col>
                        
                        <ConditionalFragment showIf={isLoading || loadDeleteProfile}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>
                    <StickyToolbar>
                        <Row className={"searchBar"}>
                            <Col xs="">
                            </Col>
                            <Col xs="auto">
                                {/* show the custom add*/}
                                {!!addButtons ? addButtons : standardAddButtons()}
                            </Col>
                            <Col className="text-right" xs={12} sm="auto">
                                <ConditionalFragment showIf={showViewModeButtons}>
                                    <ButtonGroup>
                                        <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                            <FontAwesomeIcon icon="th-large" />
                                            <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                        </Button>
                                        <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                            <FontAwesomeIcon icon="th-list" />
                                            <span className="sr-only">{t('common.list', 'List')}</span>
                                        </Button>
                                    </ButtonGroup>
                                </ConditionalFragment>
                            </Col>

                        </Row>
                    </StickyToolbar>
                </Banner>
            </ConditionalFragment>

            <MainContainer fluid>
                <AlertOnErrors errors={[loadingErrors, removeErrors, lockoutErrors, loadErrors]} />
                <Row className="mb-2">
                    <Col sm={4} className="d-none d-sm-block"></Col>
                    <Col xs={12} sm={4}>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                    </Col>
                </Row>

                <CardsOrTable
                    viewMode={overrideViewMode ?? viewMode}
                    items={displayItems}
                    onItemClick={item => history.push(`${baseRoute}/edit/${item.id}`)}
                    tableHeadings={[
                        t('registeredUserList.firstName', 'First name'),
                        t('registeredUserList.lastName', 'Last name'),
                        t('registeredUserList.email', 'Email'),
                        //Used for if there is no departmentId then show department column in the list.
                        //Only used on Department user view where user would already know the department the users are in.
                        !departmentId ? t('registeredUserList.department', 'Department') : undefined,
                        t('registeredUserList.securityGroup', 'Security group'),
                    ].filter(item => item !== undefined)

                    }
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{!item.firstName && !item.lastName ? item.user.email : item.firstName + " " + item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'table' ? item.firstName : null,
                        (item, view) => view === 'table' ? item.lastName : null,
                        (item, view) => view === 'cards' ? (<CardSubtitle tag="h6">{item.user.email}</CardSubtitle>) : item.user.email,
                        (item) => {
                            if (!departmentId) {
                                return departments?.find(it => it.id === item.subscriptionDepartmentId)?.name ?? '-';
                            }
                        },
                        (item) => !!item.user && !!item.user.roleGroup ? roleGroupDisplayNameFromString(item.user?.roleGroup.name, t) : '',
                    ]}
                    buttons={(item) => (
                        (!!customListItemButtons ? customListItemButtons(item.id, baseRoute, departmentId) : standardListItemButtons(item.id))
                    )}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>


                {/** Delete Any Item Modal*/}
                <ConditionalFragment showIf={deleteAnyItemModalIsOpen}>
                    <DeleteAnyModal
                        isOpen={deleteAnyItemModalIsOpen}
                        toggle={toggleDeleteAnyItemModal}
                        itemName={`${deletedProfile?.firstName} ${deletedProfile?.lastName}`}
                        remove={() => { remove(deletedItemId); lockout({ email: deletedUserEmail, archive: true }) }}
                    />
                </ConditionalFragment>

            </MainContainer>
        </Background>
    );
};
