import { Document, Page, View, Image, Text } from '@react-pdf/renderer';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useIndividualReportViewModelQuery_model_questionnaireSections, useIndividualReportViewModelQuery_model_questions, useIndividualReportViewModelQuery_questionnaireRespondentSessions_responses } from '../../../api/main/generated/useIndividualReportViewModelQuery';
import { LearningUnit } from '../../../api/main/models/LearningUnit';
import { Question } from '../../../api/main/models/Question';
import { QuestionResponse } from '../../../api/main/models/QuestionResponse';
import { IndivdualReportPdfStyles } from './IndivdualReportPDFStyles';
import { QuestionPDF } from './QuestionPDF';


interface IndividualReportPDFProps {
    companyColor?: string,
    companyLogoUrl?: string,
    splashPageImage: string,
    impactsLogoUrl?: string,

    userFirstName?: string,
    userLastName?: string,
    userEmail?: string, 

    questionnaireTitle?: string,
    sectionTitle?: string,

    questionnaireDiscription?: string,

    learningUnit?: LearningUnit | null,
    lineManagerName?: string,
    lineManagerEmail?: string,

    sessionEndDate?: string,
    questionnaireSections?: Array<useIndividualReportViewModelQuery_model_questionnaireSections>,
    orderedQuestions: (sectionId: string) => Array<useIndividualReportViewModelQuery_model_questions>,
    orderedResponses: (questionId: string) => Array<useIndividualReportViewModelQuery_questionnaireRespondentSessions_responses>

    displayPreText: (question: Question, response: QuestionResponse, index: number) => string,
    displayResponse: (question: Question, response: QuestionResponse, index: number) => string,

    isIncludeSummary: boolean,
    isIncludeLearningUnit: boolean,
    isIncludeDescription: boolean,
}


export const IndividualReportPDF = (props: IndividualReportPDFProps) => {

    const {
        companyColor, companyLogoUrl, splashPageImage, impactsLogoUrl,
        userFirstName, userLastName, userEmail, questionnaireDiscription,
        lineManagerName, lineManagerEmail, questionnaireSections,
        orderedQuestions, orderedResponses, displayPreText, displayResponse,
        learningUnit, isIncludeSummary,
        isIncludeLearningUnit,
        isIncludeDescription,
        questionnaireTitle,
        sectionTitle,
        sessionEndDate,
    } = props;



    return (
        <Document>
            {/*Splash Page*/}
            <Page size="A4" style={IndivdualReportPdfStyles.splashPage} wrap={false}>
                <View>
                    <Image style={IndivdualReportPdfStyles.splashPageImage} source={splashPageImage} />
                </View>
                <View style={[IndivdualReportPdfStyles.splashPageTitleView,
                {
                    borderRadius: 10,
                    border: "1mm",
                    borderStyle: "solid",
                    borderColor: `${companyColor}`,
                    display: "flex",
                    padding: "1mm",

                }]}>
                    <View style={[{
                        flexDirection: "column", width: "60%", alignItems: "flex-start", height: "60mm"
                    }]}>
                        <Text style={[IndivdualReportPdfStyles.userName]}>{userFirstName + ' ' + userLastName}</Text>
                        <Text style={[IndivdualReportPdfStyles.splashPageTitle]}>{questionnaireTitle}</Text>
                        <ConditionalFragment showIf={!!sectionTitle}>
                            <Text style={[IndivdualReportPdfStyles.splashPageEndOfProgrammeReview]}>{sectionTitle}</Text>
                        </ConditionalFragment>
                        <Text style={[IndivdualReportPdfStyles.splashPageEndOfProgrammeReview]}>{"End of Programme Review"}</Text>
                        <Text style={[IndivdualReportPdfStyles.splashPageEndDate]}>{sessionEndDate}</Text>
                    </View>
                    <View style={[{
                        flexDirection: "column", width: "40%", alignItems: "center", paddingTop: 35, paddingBottom: 5,
                    }]}>
                        <Text style={[IndivdualReportPdfStyles.userName]}>{''}</Text>
                        <Image style={IndivdualReportPdfStyles.companyLogo} source={companyLogoUrl} />
                    </View>
                </View>
            </Page>

            {/*Report Page*/}
            <Page size="A4" style={IndivdualReportPdfStyles.reportPage} wrap={false}>
                {/*Report Title*/}
                <View>
                    <Image style={IndivdualReportPdfStyles.imapctsImageMainPage} source={impactsLogoUrl} />
                    <Text style={IndivdualReportPdfStyles.reportPageTitle}>{questionnaireTitle}</Text>
                </View>
                {/*Line Manager Details*/}
                <View style={IndivdualReportPdfStyles.sessionDetailsView}>
                    <View>
                        <Text style={IndivdualReportPdfStyles.sessionTitle}>Participant</Text>
                    </View>
                    <View style={IndivdualReportPdfStyles.rowView}>
                        <View style={IndivdualReportPdfStyles.columnView}>
                            <Text style={IndivdualReportPdfStyles.participantNameLabel}>Name</Text><Text style={IndivdualReportPdfStyles.participantFullName}>{userFirstName} {userLastName}</Text>
                        </View>
                        <View style={IndivdualReportPdfStyles.columnView}>
                            <Text style={IndivdualReportPdfStyles.participantEmailLabel}>Email</Text><Text style={IndivdualReportPdfStyles.participantEmail}>{userEmail}</Text>
                        </View>
                    </View>
                    <View style={IndivdualReportPdfStyles.rowView}>
                        <View style={IndivdualReportPdfStyles.columnView}>
                            <Text style={IndivdualReportPdfStyles.lineManagerNameLabel}>Line Manager:</Text><Text style={IndivdualReportPdfStyles.lineManagerName}>{lineManagerName}</Text>
                        </View>
                        <View style={IndivdualReportPdfStyles.columnView}>
                            <Text style={IndivdualReportPdfStyles.lineManagerEmailLabel}>Line Manager:</Text><Text style={IndivdualReportPdfStyles.lineManagerEmail}>{lineManagerEmail}</Text>
                        </View>
                    </View>
                </View>
                {/*Report Summary*/}
                <ConditionalFragment showIf={!!isIncludeSummary}>
                    <View style={IndivdualReportPdfStyles.summaryView}>
                        <View style={IndivdualReportPdfStyles.summaryTitleView}>
                            <Text style={IndivdualReportPdfStyles.summaryTitle}>Summary</Text>
                        </View>
                        <ConditionalFragment showIf={!!isIncludeDescription}>
                            <View style={IndivdualReportPdfStyles.questionnaireDescriptionView}>
                                <View>
                                    <Text style={IndivdualReportPdfStyles.questionnaireDescriptionLabel}>Description</Text>
                                </View>
                                <View style={IndivdualReportPdfStyles.questionnaireDescription}>
                                    <Text>{questionnaireDiscription ?? ''}</Text>
                                </View>
                            </View>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!learningUnit && isIncludeLearningUnit}>
                            <View style={IndivdualReportPdfStyles.learningUnitView}>
                                <View style={IndivdualReportPdfStyles.learningUnitRow}>
                                    <Text style={IndivdualReportPdfStyles.learningUnitTitle}>Learning Unit</Text>
                                </View>
                                <View style={IndivdualReportPdfStyles.learningUnitRow}>
                                    <View style={IndivdualReportPdfStyles.learningUnitColumn}>
                                        <Text style={IndivdualReportPdfStyles.learningUnitNameLabel}>Learning</Text><Text style={IndivdualReportPdfStyles.learningUnitName}>{learningUnit?.name}</Text>
                                    </View>
                                    <View style={IndivdualReportPdfStyles.learningUnitColumn}>
                                        <Text style={IndivdualReportPdfStyles.learningUnitTypeLabel}>Type</Text><Text style={IndivdualReportPdfStyles.learningUnitType}>{learningUnit?.learningUnitType}</Text>
                                    </View>
                                </View>
                                <View style={IndivdualReportPdfStyles.learningUnitRow}>
                                    <View style={IndivdualReportPdfStyles.learningUnitColumn}>
                                        <Text style={IndivdualReportPdfStyles.learningUnitByLabel}>By</Text><Text style={IndivdualReportPdfStyles.learningUnitBy}>{learningUnit?.provider}</Text>
                                    </View>
                                </View>
                                <ConditionalFragment showIf={!!learningUnit?.startDate || !!learningUnit?.endDate}>
                                    <View style={IndivdualReportPdfStyles.learningUnitRow}>
                                        <View style={IndivdualReportPdfStyles.learningUnitColumn}>
                                            <Text style={IndivdualReportPdfStyles.learningUnitStartDateLabel}>Start Date</Text><Text style={IndivdualReportPdfStyles.learningUnitStartDate}>{!!learningUnit?.startDate ? moment(learningUnit?.startDate).local().format("L") : 'Not Set'}</Text>
                                        </View>
                                        <View style={IndivdualReportPdfStyles.learningUnitColumn}>
                                            <Text style={IndivdualReportPdfStyles.learningUnitEndDateLabel}>End Date</Text><Text style={IndivdualReportPdfStyles.learningUnitEndDate}>{!!learningUnit?.endDate ? moment(learningUnit?.endDate).local().format("L") : 'Not Set'}</Text>
                                        </View>
                                    </View>
                                </ConditionalFragment>
                            </View>
                        </ConditionalFragment>
                    </View>
                </ConditionalFragment>
                {
                    questionnaireSections?.map(section => {
                        return (
                            <View style={IndivdualReportPdfStyles.questionnaireSection} key={section.id}>
                                <View style={IndivdualReportPdfStyles.SectionRow} wrap={false} key={section.id}>
                                    <Text style={IndivdualReportPdfStyles.sectionInitial}>{section.name.substring(0, 1)}</Text><Text style={IndivdualReportPdfStyles.sectionName}>{section.name}</Text>
                                </View>
                                <ConditionalFragment showIf={!!section.description}>
                                    <View>
                                        <Text style={IndivdualReportPdfStyles.sectionDescription}>{section.description}</Text>
                                    </View>
                                </ConditionalFragment>
                                <View>
                                    {orderedQuestions(section.id).map(question => {
                                        return (
                                            <QuestionPDF
                                                key={question.id}
                                                question={question}
                                                orderedResponses={orderedResponses(question.id)}
                                                displayResponse={displayResponse}
                                                displayPreText={displayPreText}
                                            />
                                        )
                                    })}
                                </View>
                            </View>
                        )
                    })
                }

            </Page>
        </Document>
    );
};