import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { administrationDashboardViewModelQuery } from '../../generated/administrationDashboardViewModelQuery';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { subscriptionFieldsFragment } from '../../models/Subscription';
import { profileFieldsFragment } from '../../models/Profile';
import { subscriptionDepartmentFieldsFragment } from '../../models/SubscriptionDepartment';
import { questionnaireFieldsFragment } from '../../models/Questionnaire';
import { questionnaireRespondentSessionFieldsFragment } from '../../models/QuestionnaireRespondentSession';
import { questionResponseFieldsFragment } from '../../models/QuestionResponse';
import { questionFieldsFragment } from '../../models/Question';

/**
 * View model for AdministrationDashboard to loads all the right related data.
 * @param options
 */
export function useAdministrationDashboardViewModel(options: AsyncLoadOptions = {}): AsyncLoadResult<administrationDashboardViewModelQuery> {
    // Query the data we need form the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<administrationDashboardViewModelQuery>(
        gql`
        query administrationDashboardViewModelQuery {
            subscriptions {
                ...subscriptionFields
            }

            subscriptionDepartments {
                ...subscriptionDepartmentFields
            }

            profiles {
                ...profileFields
            }

            questionnaires {
                ...questionnaireFields
                questions {
                    ...questionFields
                }
            }

            questionnaireRespondentSessions {
                ...questionnaireRespondentSessionFields
                responses {
                    ...questionResponseFields
                }
            }

        }

        ${subscriptionFieldsFragment}
        ${subscriptionDepartmentFieldsFragment}
        ${profileFieldsFragment}
        ${questionnaireFieldsFragment}
        ${questionnaireRespondentSessionFieldsFragment}
        ${questionResponseFieldsFragment}
        ${questionFieldsFragment}
        `,
        {
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load])

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error
    }), [data, refresh, loading, error]);
}