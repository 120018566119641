import { Button } from "reactstrap";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { getMultipleChoiceResponseText } from "../utilities/getMultipleChoiceResponseText";
import "./questionMultipleChoice.scss";
import "./questionPresenter.scss";

export interface ResponseMultipleChoiceProps {
    question: Question,
    sessionId?: string,
    model: QuestionResponse,
    change: (changes: Partial<QuestionResponse>) => void,
    userInputDisabled?: boolean,
    responseIndex: number,
}

// Displays and updates a single multiple choice response.

export const ResponseMultipleChoice = (props: ResponseMultipleChoiceProps) => {
    const {
        question, sessionId, model, change, 
        userInputDisabled, responseIndex,
    } = props;

    return (
        <Button key={responseIndex} disabled={!sessionId || !!userInputDisabled} type="button" className={"btn btn-response " + (!!model.isUserInput ? 'active' : '')}
            onClick={e =>  change({ amountInput: responseIndex + 1, isUserInput: !model.isUserInput })}>
            {getMultipleChoiceResponseText(question, responseIndex) ?? ''}
        </Button>
    );
};