import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteQuestionnaireSectionMutation, deleteQuestionnaireSectionMutationVariables } from '../generated/deleteQuestionnaireSectionMutation';
import { questionnaireSectionFieldsFragment } from '../models/QuestionnaireSection';

/**
 * Get a callback to delete a QuestionnaireSection in the store.
 */
export function useDeleteQuestionnaireSectionCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteQuestionnaireSectionMutation, deleteQuestionnaireSectionMutationVariables>(
        gql`
            mutation deleteQuestionnaireSectionMutation ($id: ID!) {
                deleteQuestionnaireSection(id: $id) {
                    ...questionnaireSectionFields
                }
            }

            ${questionnaireSectionFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
