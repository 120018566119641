import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
//Ledt Commented out as not being used but may be used in the future
//import { usePdfReportZipFileCallback } from "../../../api/main/questionnaires/usePdfReportZipFileCallback";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { FormButtons } from "../../shared/FormButtons";
import { BulkReportGenerationRequest } from "./BulkReportGenerationRequest";

export interface BulkReportGeneratorProps {
    isOpen: boolean,
    toggle: () => void,
    passedInAutoGeneratedZipFileName?: string,

    requests: Array<BulkReportGenerationRequest>,
}

/**
 * Component that will work through generating a set of requested reports to get a set of blob references (usually to PDFs) that can be
 * downloaded a single download zip.
 * 
 * While this is all going on, a UI is shown with progress so the user knows what is happening.
 * @param props
 */
export const BulkReportGenerator = (props: BulkReportGeneratorProps) => {
    const {
        isOpen,
        toggle,
        passedInAutoGeneratedZipFileName,

        requests,
    } = props;

    const { t } = useTranslation();

    // State of the reports we are generating.
    const [queue, setQueue] = useState<Array<ReportQueueItem>>([]);

    // Zip filename for all the reports to be added into.
    const [zipFileName] = useState<string>(() => !!passedInAutoGeneratedZipFileName ? passedInAutoGeneratedZipFileName : `Reports-${moment().format('YYYY-MM-DDTHHmmss')}.zip`);

    // Whenever the list of requests changes, update the queue to reflect the changes.  The id for each report is taken as normalised and so
    // we use that to decide if something is still in the queue or not rather than object equality.
    useEffect(() => {
        setQueue(prevState => [
            // Remove any requests where the id is no longer in the list of reports we want to generate.
            ...prevState.filter(item => !!requests.find(request => request.id === item.requestId)),

            // Add any new requests that were not already in the queue.
            ...requests.filter(request => !prevState.find(item => item.requestId === request.id))
                .map(request => ({
                    requestId: request.id,
                    status: 'pending',
                    blobReferenceId: undefined,
                    errors: undefined,
                } as ReportQueueItem)),
        ]);
    }, [requests, setQueue]);

    // Get some details we care about from the queue including stats, errors, and the current item to process.
    const {
        //pendingCount,
        //startedCount,
        completeCount,
        errorsCount,
        totalCount,

        allErrors,

        currentQueueItem,
    } = useMemo(() => {
        let pendingCount = 0;
        let startedCount = 0;
        let completeCount = 0;
        let errorsCount = 0;

        let allErrors: Array<any> = [];

        // Calculate the stats by status and combine all errors for display.
        for (const item of queue) {
            // Count the items in each status.
            switch (item.status) {
                case 'pending':
                    ++pendingCount;
                    break;
                case 'started':
                    ++startedCount;
                    break;
                case 'complete':
                    ++completeCount;
                    break;
                case 'error':
                    ++errorsCount;
                    break;
            }

            // If we have errors, add them to the queue.
            if (!!item.errors) {
                allErrors.push(item.errors);
            }
        }

        // Find the current item to be processed.  If we have a started item, we'll use that, otherwise we'll use a pending item.
        let currentQueueItem: ReportQueueItem | undefined = undefined;
        if (startedCount > 0) {
            currentQueueItem = queue.find(item => item.status === 'started');
        } else if (pendingCount > 0) {
            currentQueueItem = queue.find(item => item.status === 'pending');
        }


        // Return everything.
        return {
            pendingCount,
            startedCount,
            completeCount,
            errorsCount,
            totalCount: queue.length,

            allErrors,
            currentQueueItem,
        }
    }, [queue]);

    // Process the current item if we can generate it directly into a PDF.
    const {
        renderComponent,
        renderToBlobReference, 
    } = useMemo(() => {
        // If we are not processing an item, we have nothing to do.
        if (!currentQueueItem) {
            return { renderComponent: undefined, renderToBlobReference: undefined, };
        }

        // Find the request.
        const request = requests.find(request => request.id === currentQueueItem.requestId);
        if (!request) {
            return { renderComponent: undefined, renderToBlobReference: undefined, };
        }

        // If there is a method to render direct to pdf, we are going to use it.
        if (request.renderToBlobReference) {
            return { renderComponent: undefined, renderToBlobReference: request.renderToBlobReference, };
        }

        // If there is a component we need to render to render to pdf, we will render it hidden and let it give us a pdf.
        if (request.renderComponent) {
            return { renderComponent: request.renderComponent, renderToBlobReference: undefined, };
        }

        // If we get here we have no idea how to process this item, which means the caller gave us a bad request, so do nothing.
        return { renderComponent: undefined, renderPdf: undefined, };
    }, [currentQueueItem, requests]);

    // Callback that handles the current pdf becoming ready.
    // We either use this directly with renderToblobReference or we pass it to the component that will render the report and let us know when its complete.
    const onCurrentPdfReady = useCallback((pdfBlobReferenceId: string | undefined | null, errors: any) => {
        // Update this item in the queue.
        setQueue(prevState => {
            if (!currentQueueItem) {
                return prevState;
            }

            return [
                ...prevState.filter(item => item.requestId !== currentQueueItem?.requestId),
                {
                    ...currentQueueItem,
                    blobReferenceId: pdfBlobReferenceId,
                    errors: errors,
                    status: pdfBlobReferenceId ? 'complete' : 'error',
                },
            ];
        });
    }, [currentQueueItem, setQueue]);


    // If we have a renderPdf method, render it now.
    useEffect(() => {
        if (!renderToBlobReference) {
            return;
        }

        // Run the direct to blobReference method.
        (async () => {
            try {
                const blobReferenceId = await renderToBlobReference();
                onCurrentPdfReady(blobReferenceId, undefined);
            } catch (error) {
                onCurrentPdfReady(undefined, error);
            }
        })();
    }, [renderToBlobReference, onCurrentPdfReady, ]);

    // Generate and memo the component we want to render (if any).
    const HiddenComponent = useMemo(() => {
        if (!renderComponent) {
            return undefined;
        }

        return renderComponent({ autoGeneratePdf: true, autoGeneratePdfZipFileName: zipFileName, onPdfReady: onCurrentPdfReady });
    }, [renderComponent, onCurrentPdfReady, zipFileName]);

    //Left commented out as its not being used but may be needed in the future
    //const [pdfZipFile] = usePdfReportZipFileCallback();

    const isFinished = completeCount + errorsCount >= totalCount;

    // Render the progress as a modal, along with any hidden component.
    return (
        <>
            <Modal className={'bulk-report-modal report-name-modal'} isOpen={isOpen} >{/* NOT we've disabled toggle here on purpose so clicking on the background doesn't mean you have to generate everything all over again */}
                <ModalHeader toggle={() => toggle()}>
                    {t('bulkReportGenerator.heading', 'Generating Reports')}
                </ModalHeader>
                <ModalBody>
                    <AlertOnErrors errors={allErrors} />
                        
                    <ConditionalFragment showIf={!isFinished}>
                        {t('bulkReportGenerator.processingText', 'Processing report {{currentCount}} of {{totalCount}}', { currentCount: completeCount + 1, totalCount })}
                    </ConditionalFragment>

                    <ConditionalFragment showIf={isFinished}>
                        {t('bulkReportGenerator.readyToDownload', 'Your reports are ready to download.')}
                    </ConditionalFragment>

                    <FormButtons>
                        <ConditionalFragment showIf={isFinished}>
                            <a className="btn btn-primary" href={`/api/zip/download/${!!passedInAutoGeneratedZipFileName ? passedInAutoGeneratedZipFileName : zipFileName}`} download>
                                <FontAwesomeIcon icon="download" />
                                <> </>
                                {t('bulkReportGenerator.download', 'Download')}
                            </a>
                        </ConditionalFragment>
                        
                        <Button type="button" color="primary" outline onClick={() => toggle()}>
                            {
                                isFinished ? t('common.close', 'Close')
                                    : t('common.cancel', 'Cancel')
                            }
                        </Button>
                    </FormButtons>
                </ModalBody>
            </Modal>

            {/*
             * If we have to render a component to and let it generate the current PDF, do so now.
             * NOTE we have to set a unique key here in the hierarchy to make sure react doesn't treat rendering the same component as
             * a re-render which would mean some components won't render correctly as they have state that doesn't get resent when 
             * the network request is reset.
             */}
            {
                HiddenComponent ? (
                    <div key={currentQueueItem?.requestId || ''} style={{ display: 'none' }}>
                        {HiddenComponent}
                    </div>
                ) : null
            }
        </>
        );
};

/**
 * Status of an item in the queue.
 */
type ReportQueueItemStatus = 'pending' | 'started' | 'complete' | 'error';

/**
 * A report that has been processed (or needs to be processed).
 */
export interface ReportQueueItem {
    /**
     * Id of the request this relates to.
     */
    requestId: string,

    /**
     * Status of this item's processing.
     */
    status: ReportQueueItemStatus,

    /**
     * Blob reference id of the PDF that has been geneated.
     */
    blobReferenceId: string | undefined | null,

    /**
     * Any errors that occured during generation.
     */
    errors: any,
}