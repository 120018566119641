import * as React from 'react';
import { LearningUnitCreateInput, LearningUnitUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateLearningUnitCallback } from './useCreateLearningUnitCallback';
import { useUpdateLearningUnitCallback } from './useUpdateLearningUnitCallback';
import { LearningUnit } from '../models/LearningUnit';

/**
 * Get a callback to save a LearningUnit in the store using either a create or update.
 */
export function useSaveLearningUnitCallback(options: SaveInStoreOptions<LearningUnit, string> = {}) {
    const [_create, createStatus] = useCreateLearningUnitCallback();
    const create = React.useCallback((model: Partial<LearningUnit>) => _create(model as LearningUnitCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateLearningUnitCallback();
    const update = React.useCallback((id: string, changes: Partial<LearningUnit>) => _update(id, changes as LearningUnitUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
