import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, useCallback } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { QuestionnaireType } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { AuthorizeContainer } from "../../../shared/authorizeRoute";
import { NavTiles } from "../../shared/NavTiles";
import "./questionnaireNavigation.scss"

export interface QuestionnaireNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    activeLink?: string,
    isCreate: boolean, 
    completedStages: string[],
    isTemplate: boolean,
    questionnaireType: string,
}

/**
 * Navigation for a questionnaire.
 * For Create Questionnaire - the Questionnaire navigation just sets the active button which controls which tab is visible.
 */
export const QuestionnaireNavigation = (props: QuestionnaireNavigationProps) => {
    const {
        id,
        onNavigate,
        activeLink,
        isCreate,
        completedStages,
        isTemplate,
        questionnaireType,
    } = props;
    const { t } = useTranslation();

    // If we have an onNavigate event, we should call that instead of letting the links handle themselves.
    const onNavLinkClick = useCallback<MouseEventHandler>((e) => {
        if (onNavigate) {
            e.preventDefault();
            const link = e.currentTarget.getAttribute("name") ?? '';
            onNavigate(link);
        }
    }, [onNavigate]);

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionDepartmentAdministration} >
                <NavTiles className="nav-tiles-large">
                    <NavItem key={"campaign"}>
                        <NavLink name="campaign"
                            className={`campaign-navlink ${activeLink === 'campaign' ? 'active' : 'nav-link-notactive'}`}
                            to={`/subscriptionQuestionnaires/${isCreate ? 'create' : 'edit'}/${id}`} onClick={onNavLinkClick}>
                            <ConditionalFragment showIf={!!completedStages && !!completedStages.find(item => item === 'campaign')}>
                                <FontAwesomeIcon icon="check-circle" className="completed-icon" /><> </>
                            </ConditionalFragment>
                            {t('ManageQuestionnaireNavigation.campaign', 'Campaign')}
                        </NavLink>
                    </NavItem>
                    <NavItem key={"questionnaire"}>
                        <NavLink name="questionnaire" disabled={!completedStages.find(item => item === 'campaign')}
                            className={`questionnaire-navlink ${activeLink === 'questionnaire' ? 'active' : 'nav-link-notactive'}`}
                            to={`/subscriptionQuestionnaires/${isCreate ? 'create' : 'edit'}/${id}`} onClick={onNavLinkClick}>
                            <ConditionalFragment showIf={!!completedStages && !!completedStages.find(item => item === 'questionnaire')}>
                                <FontAwesomeIcon icon="check-circle" className="completed-icon" /><> </>
                            </ConditionalFragment>
                            {t('ManageQuestionnaireNavigation.questionnaire', 'Questionnaire')}
                        </NavLink>
                    </NavItem>
                    <ConditionalFragment showIf={questionnaireType === QuestionnaireType.Campaign && !isTemplate}>
                        <NavItem key={"people"}>
                            <NavLink name="people" disabled={!completedStages.find(item => item === 'campaign') || !completedStages.find(item => item === 'questionnaire')}
                                className={`people-navlink ${activeLink === 'people' ? 'active' : 'nav-link-notactive'}`}
                                to={`/subscriptionQuestionnaires/${isCreate ? 'create' : 'edit'}/${id}`} onClick={onNavLinkClick}>
                                <ConditionalFragment showIf={!!completedStages && !!completedStages.find(item => item === 'people')}>
                                    <FontAwesomeIcon icon="check-circle" className="completed-icon" /><> </>
                                </ConditionalFragment>
                                {t('ManageQuestionnaireNavigation.people', 'People')}
                            </NavLink>
                        </NavItem>
                    </ConditionalFragment>
                    <NavItem key={"schedule"}>
                        <NavLink name="schedule" disabled={!completedStages.find(item => item === 'campaign') || (!completedStages.find(item => item === 'people') && !isTemplate)}
                            className={`schedule-navlink ${activeLink === 'schedule' ? 'active' : 'nav-link-notactive'}`}
                            to={`/subscriptionQuestionnaires/${isCreate ? 'create' : 'edit'}/${id}`} onClick={onNavLinkClick}>
                            <ConditionalFragment showIf={!!completedStages && !!completedStages.find(item => item === 'schedule')}>
                                <FontAwesomeIcon icon="check-circle" className="completed-icon" /><> </>
                            </ConditionalFragment>
                            {t('ManageQuestionnaireNavigation.schedule', 'Schedule')}
                        </NavLink>
                    </NavItem>
                    <NavItem key={"review"}>
                        <NavLink name="review" disabled={!completedStages.find(item => item === 'campaign') || (!completedStages.find(item => item === 'schedule') && !isTemplate)}
                            className={`review-navlink ${activeLink === 'review' ? 'active' : 'nav-link-notactive'}`}
                            to={`/subscriptionQuestionnaires/${isCreate ? 'create' : 'edit'}/${id}`} onClick={onNavLinkClick}>
                            <ConditionalFragment showIf={!!completedStages && !!completedStages.find(item => item === 'review')}>
                                <FontAwesomeIcon icon="check-circle" className="completed-icon" /><> </>
                            </ConditionalFragment>
                            {isTemplate || questionnaireType === QuestionnaireType.SelfService ?
                                t('ManageQuestionnaireNavigation.review', 'Review')
                                :
                                t('ManageQuestionnaireNavigation.review', 'Review & Send')
                                }
                        </NavLink>
                    </NavItem>
                </NavTiles>
            </AuthorizeContainer>
        </>
    );
};
