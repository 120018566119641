import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { questionFields } from "../generated/questionFields";

export const questionFieldsFragment = gql`
    fragment questionFields on Question {
        id
        questionnaireId
        questionnaireSectionId
        displayOrder
        questionType
        analysisTypeId
        responseType
        reinforcementText
        minTextBoxes
        maxTextBoxes
        isReinforcementRequired
        archived
        questionText
        scaleMax
        isNARequired,
        multipleChoiceResponse1,
        multipleChoiceResponse2,
        multipleChoiceResponse3,
        multipleChoiceResponse4,
        multipleChoiceResponse5,
        multipleChoiceResponse6,
        multipleChoiceResponse7,
        multipleChoiceResponse8,
        isPercentage,
        isIncrease,
    }
`;


export type Question = Omit<questionFields, '__typename'>;

export const questionDefaultValues = (): Partial<Question> => ({
    id: Guid.newGuid(),
    questionnaireId: '',
    questionnaireSectionId: '',
    displayOrder: moment().unix(),
    questionType: '',
    analysisTypeId: '',
    responseType: '',
    reinforcementText: '',
    minTextBoxes: 1,
    maxTextBoxes: 3,
    isReinforcementRequired: false,
    archived: false,
    questionText: '',
    scaleMax: 0,
    isNARequired: false,
    multipleChoiceResponse1: '',
    multipleChoiceResponse2: '',
    multipleChoiceResponse3: '',
    multipleChoiceResponse4: '',
    multipleChoiceResponse5: '',
    multipleChoiceResponse6: '',
    multipleChoiceResponse7: '',
    multipleChoiceResponse8: '',
    isPercentage: false,
    isIncrease: null,
});