import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MouseEventHandler, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NavItem, NavLink } from "reactstrap";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { AuthorizeContainer } from "../../shared/authorizeRoute";
import { NavLinkTrackActive } from "../shared/NavLinkTrackActive";

export interface ManageSubscriptionDepartmentNavigationProps {
    id: string,
    onNavigate?: (link: string) => void,
    editLink?: string,
}

/**
 * Navigation for managing a subscriptionDepartment.
 */
export const ManageSubscriptionDepartmentNavigation = (props: ManageSubscriptionDepartmentNavigationProps) => {
    const { id, onNavigate, editLink, } = props;
    const { t } = useTranslation();


    // If we have an onNavigate event, we should call that instead of leting the links handle themselves.
    const onNavLinkClick = useCallback<MouseEventHandler>((e) => {
        if (onNavigate) {
            e.preventDefault();
            const link = e.currentTarget.getAttribute("href") ?? '';
            onNavigate(link);
        }
    }, [onNavigate]);

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.SubscriptionDepartmentAdministration} >
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={editLink ?? `/manage/subscriptionDepartments/edit/${id}`} onClick={onNavLinkClick}>
                        <FontAwesomeIcon icon="edit" />
                        <> </>
                        {t('ManageQuestionnaireNavigation.edit', 'Edit')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/manage/subscriptionDepartments/users/${id}/users`} onClick={onNavLinkClick}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> </>
                        {t('ManageQuestionnaireNavigation.users', 'People')}
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
