import * as React from 'react';
import { SubscriptionDepartmentCreateInput, SubscriptionDepartmentUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateSubscriptionDepartmentCallback } from './useCreateSubscriptionDepartmentCallback';
import { useUpdateSubscriptionDepartmentCallback } from './useUpdateSubscriptionDepartmentCallback';
import { SubscriptionDepartment } from '../models/SubscriptionDepartment';

/**
 * Get a callback to save a SubscriptionDepartment in the store using either a create or update.
 */
export function useSaveSubscriptionDepartmentCallback(options: SaveInStoreOptions<SubscriptionDepartment, string> = {}) {
    const [_create, createStatus] = useCreateSubscriptionDepartmentCallback();
    const create = React.useCallback((model: Partial<SubscriptionDepartment>) => _create(model as SubscriptionDepartmentCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateSubscriptionDepartmentCallback();
    const update = React.useCallback((id: string, changes: Partial<SubscriptionDepartment>) => _update(id, changes as SubscriptionDepartmentUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
