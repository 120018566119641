import { ScrollTo } from "@scottbamford/react-scrollto";
import { useTranslation } from "react-i18next";
import "./generatedIndex.scss";

export interface GeneratedIndexProps {
    scrollToId: string,
    indexItems: Array<any>,
    handleLinkClick: (linkId: string) => void,
    headerText?: string,
    isInitialled?: boolean, // puts the first initial of the array item before the item itself
    isNumbered?: boolean, // puts a sequential number  before the item itself
    textColor?: string,
}

/**
 * Generates an index from the array passed in.
 * Typically uses ScrollTo links controlled in handleLinkClick in the calling view
 * and can do anything else specified in handleLinkClick. 
 * To include buttons to scroll to this index, use a SCrollTo link to 'index'.
 */
export const GeneratedIndex = (props: GeneratedIndexProps) => {
    const { t } = useTranslation();
    const {
        scrollToId,
        indexItems,
        handleLinkClick,
        headerText = t('generatedIndex.defaultHeaderText', 'Index'),
        isInitialled = false,
        isNumbered = false,
    } = props;


    return (
        <div className="generated-index">
            <ScrollTo shouldScrollTo={scrollToId === 'index'} position="start" scrollMargin="55px" />
            <div className="generated-index-header">
                {headerText}
            </div>
            <div>
                <ul>
                    {indexItems.map((item, linkIndex) => {
                        return (
                            <li key={'index-link' + linkIndex} className="generated-index-link" onClick={() => handleLinkClick(item.id)}>
                                <span
                                    className="generated-index-link-prefix">{isInitialled ? item.name.substring(0, 1) : isNumbered ? (linkIndex + 1).toString() : ''}
                                </span>
                                {item.name}
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        </div>
        );
};