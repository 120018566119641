import { Font, StyleSheet } from '@react-pdf/renderer';

// Don't hyphenate anything.
const hyphenationCallback = (words: string) => {
    return [words];
}
Font.registerHyphenationCallback(hyphenationCallback);

export const CompanyQuestionnaireStyles = StyleSheet.create({
    splashPage: {
        padding: "0mm",
    },
    splashPageTitleView: {
        margin: 5,
        paddingTop: 20,
        paddingBottom: 20,
        flexDirection: "row",
    },
    splashPageTitle: {
        paddingTop: 5,
        paddingBottom: 5,
        marginLeft: 10,
        fontSize: "6.466666667mm",
        fontWeight: 550,
    },
    fakeNameTitle: {
        paddingTop: 10,
        paddingBottom: 2,
        marginLeft: 10,
        fontSize: "7.466666667mm",
        fontWeight: 550,
    },
    splashPageEndOfProgrammeReview: {
        paddingTop: 5,
        paddingBottom: 2,
        marginLeft: 10,
        fontSize: "4.466666667mm",
        fontWeight: 550,
    },
    splashPageEndDate: {
        paddingTop: 2,
        paddingBottom: 5,
        marginLeft: 10,
        fontSize: "3.466666667mm",
        fontWeight: 550,
    },
    splashPageImage: {
        width: "210mm",
        height: "100%",
    },
    companyLogo: {
        objectFit: "fill",
        paddingHorizontal: "10%",

    },
    companyLogoView: {
        border: "1mm",
        borderStyle: "solid",
        borderColor: "#e4e7ea",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    imapctsImage: {
        marginTop: "30mm",
        height: "37.5pt",
        width: "105.75pt",
        marginLeft: "auto",
        marginRight: "auto",
    },
    imapctsImageMainPage: {
        marginTop: "2mm",
        height: "10mm",
        width: "30mm",
        marginLeft: "2",
    },
    reportPage: {
        padding: "15",
        fontWeight: 550,
    },
    reportPageTitle: {
        padding: "5mm",
        borderBottom: 3,
        fontSize: "6.466666667mm",
        textAlign: "center",
        fontWeight: 550,
        marginBottom: "3mm",
    },
    questionnaireSection: {
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#ddeeff",
        fontWeight: 550,
    },
    SectionRow: {
        flexDirection: "row",
    },
    sectionInitial: {
        fontSize: "6.4666666667mm",
        color: "#1188FF",
        fontWeight: 550,
    },
    sectionName: {
        fontSize: "6.4666666667mm",
        color: "#1188FF",
        paddingLeft: "10mm",
        fontWeight: 550,
    },
    sectionDescription: {
        fontSize: "4.4666666667mm",
        color: "#8f9ba6",
        paddingTop: "3mm",
    },
    questionTextBox: {
        backgroundColor: "#f3f4f5",
        minHeight: "25.2pt",
        marginTop: "15pt",
        fontWeight: 550,
    },
    questionText: {
        fontSize: "4mm",
        marginTop: "3mm",
        marginLeft: "3mm",
        marginBottom: "3mm",
        marginRight: "3mm",
        fontWeight: 550,
    },
    userRow: {
        flexDirection: "row",
        fontSize: "3mm",
        padding: "14pt 7.5pt 7.5pt 7.5pt",
        fontWeight: 550,
    },
    userLastName: {
        paddingLeft: "1mm",
        paddingRight: "0.2mm"
    },
    responseRow: {
        flexDirection: "row",
        fontSize: "3mm",
        fontWeight: "bold",
        border: "0.364583333mm",
        borderColor: "#ddeeff",
        minHeight: "31.5pt",
        marginBottom: "2.25pt",

    },
    response: {
        flexDirection: "row",
        paddingLeft: "7.5pt",
        minHeight: "31.5pt",
    },
    preTextScale: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",  
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 750,
        minWidth: "40pt",
        textAlign: "justify",
    },
    preTextTextBox: {
        color: "white",
        marginTop: "auto",
        marginBottom: "auto",
        backgroundColor: "#acb4bc",
        border: "0.1",
        borderTopLeftRadius: "15",
        borderBottomLeftRadius: "15",
        borderTopRightRadius: "15",
        borderBottomRightRadius: "15",
        padding: "4.5pt 7.5pt 4.5pt 7.5pt",
        fontWeight: 550,
    },
    displayText: {
        padding: "1%",
        marginTop: "auto",
        marginBottom: "auto",
        fontWeight: 550,
    },
    summaryView: {
        marginTop: "3mm",
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#f0bcdb",
    },
    summaryTitle: {
        marginBottom: "2mm",
        color: "#b7297d",
        fontWeight: "bold",
    },
    summaryTitleView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
    },
    questionnaireDescriptionView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
        marginTop: "3mm",
        marginBottom: "3mm"
    },
    questionnaireDescriptionLabel: {
        fontSize: "11.5pt", 
    },
    questionnaireDescription: {
        fontSize: "10pt",
        marginTop: "3mm",
        marginBottom: "3mm"
    },
    learningUnitView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
        marginTop: "3mm",
        marginBottom: "3mm"
    },
    learningUnitRow: {
        flexDirection: "row"
    },
    learningUnitColumn: {
        flexDirection: "column",
        width: "50%"
    },
    learningUnitTitle: {
        fontSize: "13pt",
    },
    learningUnitNameLabel: {
        fontSize: "11pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitName: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitTypeLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitType: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitByLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    }, 
    learningUnitBy: {
        fontSize: "10pt",
        fontWeight: "normal",
    },
    learningUnitStartDateLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitStartDate: {
        fontSize: "10pt",
        marginBottom: "3mm",
        fontWeight: "normal",
    },
    learningUnitEndDateLabel: {
        fontSize: "11.5pt",
        marginTop: "3mm",
        marginBottom: "2mm"
    },
    learningUnitEndDate: {
        fontSize: "10pt",
        marginBottom: "3mm",
        fontWeight: "normal",
    },
    chartImagesOverallView: {
        marginTop: "3mm",
        marginBottom: "3mm",
        padding: "7.5pt",
        border: "0.264583333mm",
        borderColor: "#f0bcdb",
    },
    chartImageViewRow: {
        marginTop: "0.5mm",
        flexDirection: "row",
    },
    chartImageViewColumn: {
        flexDirection: "column",
        width: "50%",
        marginLeft: "0.5mm",
        marginRight: "0.5mm",
    },
    chartImageView: {
    },
    chartImage: {
        marginTop: "0.5mm",
        marginBottom: "0.5mm",
        border: "0.264583333mm",
        padding: "5mm",
        maxWidth: "100%"
    }, 
    chartImageViewTitle: {
        marginBottom: "2mm",
        color: "#b7297d",
        fontWeight: "bold",
    },
    dashboardTitleView: {
        borderBottom: "0.264583333mm",
        borderColor: "#f0bcdb",
        marginBottom: "2.5mm",
    },
});

