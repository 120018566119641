import * as React from "react";
import { AsyncLoadResult, AsyncLoadOptions } from "../../../shared/abstractStore";
import { isNullOrUndefined } from "util";
import { useCurrentUserProfilesCache } from "../../../globalState/currentUserDetails/useCurrentUserProfileCache";
import { useProfile } from "./useProfile";
import { useAuthenticatedState } from "../../api-authorization/useAuthenticatedState";
import { Guid } from "guid-string";
import { Profile } from "../models/Profile";

/**
 * Returns the (application level) Profile for the current user.
 */
export function useCurrentUserProfile(options: AsyncLoadOptions = {}): AsyncLoadResult<{ model: Profile | null }> {
    const { isAuthenticated, user } = useAuthenticatedState({ includeUser: true });
    const [profileCache, setProfileCache] = useCurrentUserProfilesCache();

    // Need the GraphQL api hook to let us load a profile if we need it.
    const { data: { model: storeProfile }, refresh: loadProfileFromStore, errors } = useProfile(user?.sub ?? Guid.empty, { lazy: true, isUserId: true });

    // Update the profile in the cache from the store if we need to.
    React.useEffect(() => {
        // Do nothing if we don't know our authentication state.
        if (isNullOrUndefined(isAuthenticated)) {
            return;
        }

        // If we are not authenticated then we have no profile.
        if (!isAuthenticated) {
            setProfileCache({ isAuthenticated: false, profile: null, isLoading: false });
            return;
        }

        // If we are authenticated and we already have our profile, then we dont need to do anything more.
        if (profileCache.profile) {
            return;
        }

        // If we're already trying to load the profile but haven't completed yet , we can give up and wait
        // this will either retrigger because the graphql api returns the data or because it was started at the sane time by
        // another component and then is available for us via a global state chane of the cached value.
        if (profileCache.isLoading && !storeProfile) {
            return;
        }

        // If we've received a store profile via graphql, we can update the profile in the cache.
        if (storeProfile && profileCache.isLoading && profileCache.profile !== storeProfile) {
            setProfileCache(prevState => ({ ...prevState, profile: storeProfile, isLoading: false, }));
            return;
        }

        // If we reach this point we're going to need to trigger the profile load from the database.  If we are being lazy this is where we stop.
        if (options.lazy) {
            return;
        }

        // OK we've tried everything else, lets trigger the profile load from the store.
        setProfileCache(prevState => ({ ...prevState, isLoading: true, }));
        loadProfileFromStore();
    }, [isAuthenticated, storeProfile, setProfileCache, profileCache, options.lazy, loadProfileFromStore]);
    

    return {
        data: { model: profileCache.profile ?? null },
        refresh: loadProfileFromStore,
        isLoading: profileCache.isLoading,
        errors: errors
    };
}

