import * as React from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { SidebarNavTitle } from '../../shared/sidebarNav';
import { SidebarRequired } from '../shared/SidebarRequired';
import { defaultTemplateId } from '../../api/main/models/codeOnly/QuestionnaireType';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();
    return (
        <>
            <SidebarRequired />
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle>
                    {t('administrationNavigation.CompaniesTitle', 'Companies')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/subscriptions">
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <> {t('administrationNavigation.subscriptions', 'Companies')}</>
                    </NavLink>
                </NavItem>

                {/*<SidebarNavTitle>*/}
                {/*    {t('administrationNavigation.dashboardTitle', 'Dashboard')}*/}
                {/*</SidebarNavTitle>*/}
                {/*<NavItem>*/}
                {/*    <NavLink tag={NavLinkTrackActive} to="/administration/dashboard">*/}
                {/*        <FontAwesomeIcon icon="tachometer-alt" className="nav-icon" />*/}
                {/*        <> {t('administrationNavigation.dashboard', 'Impacts Dashboard')}</>*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}

                <SidebarNavTitle>
                    {t('administrationNavigation.questionnairesTitle', 'Templates')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/questionnaires/Template">
                        <FontAwesomeIcon icon="folder" className="nav-icon" />
                        <> {t('administrationNavigation.standardTemplates', 'Standard Templates')}</>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/administration/questionnaires/edit/${defaultTemplateId}`}>
                        <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                        <> {t('administrationNavigation.defaultTemplates', 'Default Template')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.questionnairesTitle', 'Analysis Types')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/analysisTypes">
                        <FontAwesomeIcon icon="chart-area" className="nav-icon" />
                        <> {t('administrationNavigation.questionnaires', 'Standard Types')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.usersTitle', 'User Management')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink to="/administration/users/administrators" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="users" className="nav-icon" />
                        <> {t('administrationNavigation.administrators', 'Skillshub Staff')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
