import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { deleteAnalysisTypeMutation, deleteAnalysisTypeMutationVariables } from '../generated/deleteAnalysisTypeMutation';
import { analysisTypeFieldsFragment } from '../models/AnalysisType';

/**
 * Get a callback to delete an AnalysisType in the store.
 */
export function useDeleteAnalysisTypeCallback(): [(id: string) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<deleteAnalysisTypeMutation, deleteAnalysisTypeMutationVariables>(
        gql`
            mutation deleteAnalysisTypeMutation ($id: ID!) {
                deleteAnalysisType(id: $id) {
                    ...analysisTypeFields
                }
            }

            ${analysisTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string) => {
        const result = await mutationAction({ variables: { id: id } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
