import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { userRoutes } from "../components/users/routes";
import { NotFound } from "../components/shared/NotFound";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { manageRoutes } from "../components/manage/routes";
import { subscriptionRoutes } from "../components/subscriptions/routes";
import { questionnaireRoutes } from "../components/questionnaires/routes";
import { questionRoutes } from "../components/questions/routes";
import { subscriptionDepartmentsRoutes } from "../components/subscriptionDepartments/routes";
import { subscriptionQuestionnaireRoutes } from "../components/subscriptionQuestionnaires/questionnaires/routes";
import { subscriptionUsersRoutes } from "../components/subscriptionUsers/users/routes";
import { subscriptionQuestionnaireUsersRoutes } from "../components/subscriptionQuestionnaires/users/routes";
import { subscriptionQuestionnairesRoutes } from "../components/subscriptionQuestionnaires/routes";
import { subscriptionDepartmentUsersRoutes } from "../components/subscriptionDepartments/users/routes";
import { analysisTypeRoutes } from "../components/analysisTypes/routes";
import { meRoutes } from "../components/me/routes";
import { dashboardRoutes } from "../components/dashboard/routes";
import { reportingRoutes } from "../components/reporting/routes";
import { subscriptionTemplateLinksRoutes } from "../components/subscriptionTemplateLinks/routes";
import { departmentQuestionnairesRoutes } from "../components/departmentQuestionnaires/routes";

export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...analysisTypeRoutes,
    ...homeRoutes,
    ...profileRoutes,
    ...userRoutes,
    ...administrationRoutes,
    ...departmentQuestionnairesRoutes,
    ...manageRoutes,
    ...meRoutes,
    ...subscriptionRoutes,
    ...questionRoutes,
    ...questionnaireRoutes,
    ...subscriptionDepartmentsRoutes,
    ...subscriptionQuestionnaireRoutes,
    ...subscriptionUsersRoutes,
    ...subscriptionQuestionnaireUsersRoutes,
    ...subscriptionQuestionnairesRoutes,
    ...subscriptionDepartmentUsersRoutes,
    ...subscriptionTemplateLinksRoutes,
    ...dashboardRoutes,
    ...reportingRoutes,
    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
];