import { RouteEntry } from "../../shared/routes";
import { Login } from "./Login";
import { Register } from "./Register";
import { ExternalLoginCallback } from "./ExternalLoginCallback";
import { ConfirmEmail } from "./ConfirmEmail";
import { ForgotPassword } from './ForgotPassword';
import { ResetPassword } from "./ResetPassword";
import { ConfirmEmailChange } from "./ConfirmEmailChange";
import { ConfirmInvite } from "./ConfirmInvite";

export const accountRoutes: Array<RouteEntry> = [
    { path: '/account/login', component: Login },
    { path: '/account/register', component: Register },
    { path: '/account/externalLogin', component: ExternalLoginCallback },
    { path: '/account/forgotPassword', component: ForgotPassword },
    { path: '/account/resetPassword', component: ResetPassword },
    { path: '/account/confirmEmail', component: ConfirmEmail },
    { path: '/account/confirmEmailChange', component: ConfirmEmailChange },
    { path: '/account/confirmInvite', component: ConfirmInvite },
];
