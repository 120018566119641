import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { cloneQuestionnaireMutation, cloneQuestionnaireMutationVariables } from '../generated/cloneQuestionnaireMutation';
import { Questionnaire, questionnaireFieldsFragment } from '../models/Questionnaire';
/**
 * Get a callback to create a duplicate of a questionnaire as a Template.
  * @param id
  * @param isTemplate
*/
export function useCloneQuestionnaireCallback(): [(id: string, isTemplate: boolean, questionnaireType?: string, subscriptionId?: string, subscriptionDepartmentId?: string) => Promise<Questionnaire | undefined | null>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<cloneQuestionnaireMutation, cloneQuestionnaireMutationVariables>(
        gql`
            mutation cloneQuestionnaireMutation ($id: ID!, $isTemplate: Boolean!, $questionnaireType: String, $subscriptionId: ID,  $subscriptionDepartmentId: ID) {
                cloneQuestionnaire(id: $id, isTemplate: $isTemplate, questionnaireType: $questionnaireType, subscriptionId: $subscriptionId, subscriptionDepartmentId: $subscriptionDepartmentId) {
                    ...questionnaireFields
                }
            }

            ${questionnaireFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, isTemplate: boolean, questionnaireType?: string, subscriptionId?: string, subscriptionDepartmentId?: string) => {
        const result = await mutationAction({
            variables: {
                id: id,
                isTemplate: isTemplate,
                questionnaireType: questionnaireType,
                subscriptionId: subscriptionId,
                subscriptionDepartmentId: subscriptionDepartmentId,
            }
        });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
        return result.data?.cloneQuestionnaire;
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
