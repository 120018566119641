import { RouteEntry } from "../../../shared/routes";
import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { AddDepartmentUsersList } from "./AddDepartmentUsersList";
import { AddUserFromList } from "../../users/AddUserFromList";

export const subscriptionDepartmentUsersRoutes: Array<RouteEntry> = [
    { path: '/manage/subscriptionDepartments/users/:departmentId/users/addlist', exact: true, component: AddDepartmentUsersList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },
    { path: '/manage/subscriptionDepartments/users/:departmentId/users/addFromList/:id', exact: true, component: AddUserFromList, authorize: true, requireRole: IdentityRoles.SubscriptionAdministration },

];
