import { RouteEntry } from "../../shared/routes";
import { MeDashboard } from "./MeDashboard";
import { CompleteQuestionnaire, ViewCompletedQuestionnaire } from "./questionnaires/CompleteQuestionnaire";

export const meRoutes: Array<RouteEntry> = [
    // work on the current logged in user's questionnaire
    { path: '/me', exact: true, component: MeDashboard, authorize: true, },
    { path: '/home/questionnaire/start/:id', exact: true, component: CompleteQuestionnaire, authorize: true, },
    { path: '/home/questionnaire/continue/:id', exact: true, component: CompleteQuestionnaire, authorize: true },
    { path: '/home/questionnaire/view/:id', exact: true, component: ViewCompletedQuestionnaire, authorize: true },

    // read only view of a different user's completed questionnaire
    { path: '/home/questionnaire/user/:userId/view/:id/', exact: true, component: ViewCompletedQuestionnaire, authorize: true },
];
