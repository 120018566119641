import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { AnalysisTypeUpdateInput } from '../generated/globalTypes';
import { updateAnalysisTypeMutation, updateAnalysisTypeMutationVariables } from '../generated/updateAnalysisTypeMutation';
import { analysisTypeFieldsFragment } from '../models/AnalysisType';

/**
 * Get a callback to update an AnalysisType in the store.
 */
export function useUpdateAnalysisTypeCallback(): [(id: string, model: AnalysisTypeUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateAnalysisTypeMutation, updateAnalysisTypeMutationVariables>(
        gql`
            mutation updateAnalysisTypeMutation ($model: AnalysisTypeUpdateInput!) {
                updateAnalysisType(model: $model) {
                    ...analysisTypeFields
                }
            }

            ${analysisTypeFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: AnalysisTypeUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
