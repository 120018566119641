import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Col, ListGroup, ListGroupItem, ListGroupItemHeading, Row } from "reactstrap";
import { QuestionnaireType, questionnaireTypeDisplayName } from "../../../api/main/models/codeOnly/QuestionnaireType";
import { Question } from "../../../api/main/models/Question";
import { Questionnaire } from "../../../api/main/models/Questionnaire";
import { QuestionnaireRespondentSession } from "../../../api/main/models/QuestionnaireRespondentSession";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { DueDateLabel } from "../../me/DueDateLabel";
import { getQuestionnairePercentageComplete } from "../../me/utilities/useQuestionnaireCompletionPercentage";
import { PercentageProgressBar } from "../progress/PercentageProgressBar";
import "./userProgressDashboardTab.scss";

export interface UserProgressDashboardTabProps {
    questionnaires: Array<Questionnaire>,
    getQuestions: (questionnaireId: string) => Array<Question>,
    getResponses: (questionnaireId: string) => Array<QuestionResponse>,
    campaignSessions: Array<QuestionnaireRespondentSession>,
    selfServiceSessions: Array<QuestionnaireRespondentSession>,
}

/**
 * Tab for the dasboard that shows under a user to show their assigned assessments/training by status.
 * @param props
 */
export const UserProgressDashboardTab = (props: UserProgressDashboardTabProps) => {
    const {
        questionnaires,
        getQuestions,
        getResponses,
        campaignSessions,
        selfServiceSessions,
    } = props;

    const { t } = useTranslation();

    // Split the sessions up into groups based on the user's progress.
    const { campaignsNotStarted, campaignsStarted, campaignsCompleted, selfServicesNotStarted, selfServicesStarted, selfServicesCompleted, } = useMemo(() => {
        let campaignsNotStarted = !!campaignSessions ? campaignSessions.filter(item => !item.startDate) : [];
        let campaignsStarted = !!campaignSessions ? campaignSessions.filter(item => item.startDate && !item.endDate) : [];
        let campaignsCompleted = !!campaignSessions ? campaignSessions.filter(item => item.endDate) : [];
        let selfServicesNotStarted = !!selfServiceSessions ? selfServiceSessions.filter(item => !item.startDate) : [];
        let selfServicesStarted = !!selfServiceSessions ? selfServiceSessions.filter(item => item.startDate && !item.endDate) : [];
        let selfServicesCompleted = !!selfServiceSessions ? selfServiceSessions.filter(item => item.endDate) : [];

        return {
            campaignsNotStarted,
            campaignsStarted,
            campaignsCompleted,
            selfServicesNotStarted,
            selfServicesStarted,
            selfServicesCompleted,
        };
    }, [campaignSessions, selfServiceSessions]);

    // Generate the display items.
    const generateItems = (mySessions: Array<QuestionnaireRespondentSession>, status: string) => {
        return mySessions.map(session => {
            const thisQuestionnaire = questionnaires?.find(item => item.id === session.questionnaireId);
            const thisQuestions = getQuestions(session.questionnaireId);
            // only want to count 1 response per question max
            const thisQuestionResponses = getResponses(session.questionnaireId);
            return (
                <ListGroupItem key={session.id} className="user-progress-dashboard-tab-item">
                    <Row>
                        <Col xs={12} md="">
                            <ListGroupItemHeading>
                                {thisQuestionnaire?.name}
                            </ListGroupItemHeading>
                        </Col>

                        <Col xs={12} md="auto">
                            <div className="user-progress-dashboard-tab-item-right-side-score">
                                {
                                    status === 'started' ? (
                                        <PercentageProgressBar
                                            questionnaireType={thisQuestionnaire?.questionnaireType as QuestionnaireType}
                                            value={getQuestionnairePercentageComplete(thisQuestions ?? [], session, thisQuestionResponses)}
                                            title={t('userProgressDashboardTab.progress.title', 'Percentage complete')}
                                            heading={t('userProgressDashboardTab.progress.heading', 'Progress')}
                                        />
                                    ) : null
                                }
                            </div>
                            <div className="user-progress-dashboard-tab-item-right-side">
                                <div className="user-progress-dashboard-tab-item-right-side-date">
                                    <DueDateLabel dueDate={thisQuestionnaire?.endDate} completedDate={session.endDate} />
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ListGroupItem>
            );
        })
    };

    return (
        <div className="user-progress-dashboard-tab">
            <Row style={{ paddingTop: '15px' }}>
                <Col xs={12} lg="">
                    {/* Not started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="danger">
                                <h5>
                                    {t('userProgressDashboardTab.notStarted.heading', 'Not started')}
                                    <> </>
                                    <Badge pill>
                                        {campaignsNotStarted.length + selfServicesNotStarted.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            <ConditionalFragment showIf={!!campaignsNotStarted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.Campaign, t)}
                                    </h6>
                                </ListGroupItem>
                            </ConditionalFragment>
                            {
                                generateItems(campaignsNotStarted, 'notStarted')
                            }
                            <ConditionalFragment showIf={!!selfServicesNotStarted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)}
                                    </h6>
                                </ListGroupItem>
                            </ConditionalFragment>
                            {
                                generateItems(selfServicesNotStarted, 'notStarted')
                            }
                        </ListGroup>
                    </div>

                    {/* Started */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="warning">
                                <h5>
                                    {t('userProgressDashboardTab.started.heading', 'Started')}
                                    <> </>
                                    <Badge pill>
                                        {campaignsStarted.length + selfServicesStarted.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            <ConditionalFragment showIf={!!campaignsStarted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.Campaign, t)}
                                    </h6>
                                </ListGroupItem>
                            </ConditionalFragment>
                            {
                                generateItems(campaignsStarted, 'started')
                            }
                            <ConditionalFragment showIf={!!selfServicesStarted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)}
                                    </h6>
                                </ListGroupItem>
                                {
                                    generateItems(selfServicesStarted, 'started')
                                }
                            </ConditionalFragment>
                        </ListGroup>
                    </div>

                    {/* Complete */}
                    <div className="mb-2">
                        <ListGroup>
                            <ListGroupItem color="success">
                                <h5>
                                    {t('userProgressDashboardTab.complete.heading', 'Completed')}
                                    <> </>
                                    <Badge pill>
                                        {campaignsCompleted.length + selfServicesCompleted.length}
                                    </Badge>
                                </h5>
                            </ListGroupItem>
                            <ConditionalFragment showIf={!!campaignsCompleted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.Campaign, t)}
                                    </h6>
                                </ListGroupItem>
                            </ConditionalFragment>
                            {
                                generateItems(campaignsCompleted, 'completed')
                            }
                            <ConditionalFragment showIf={!!selfServicesCompleted.length}>
                                <ListGroupItem>
                                    <h6 className="user-progress-dashboard-tab-sub-heading">
                                        {questionnaireTypeDisplayName(QuestionnaireType.SelfService, t)}
                                    </h6>
                                </ListGroupItem>
                            </ConditionalFragment>
                            {
                                generateItems(selfServicesCompleted, 'completed')
                            }
                        </ListGroup>
                    </div>
                </Col>
            </Row>
        </div>
    );
};