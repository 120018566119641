import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, Col, Collapse, Container, Row } from "reactstrap";
import { Profile } from "../../../api/main/models/Profile";
import { Question } from "../../../api/main/models/Question";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { QuestionInSectionComponent } from "../../questionnaires/questionniareOverview/QuestionInSectionComponent";
import { ResponsesForDragComponent, SectionsListItem } from "../../questionnaires/questionniareOverview/QuestionnaireOverview";
import "./Analyser.scss";


export interface AnalyserDragComponentProps {
    id: string,
    name: string,
    exclude: (id: string, name: string)=> void,
    iconSuffix?: string,
    figure?: number,
    change?: 'up' | 'down' | 'none';
    sectionData?: { question: Question, responses: QuestionResponse[], include: boolean }[],
    respondants?: Profile[],
    graphMode?: boolean,
    includeInGraph?: boolean,
    toggleIncludeInGraph: (id: string) => void,
};

export const AnalyserDragComponent = (props: AnalyserDragComponentProps) => {
    const { id, name, iconSuffix, figure, change, exclude, sectionData, respondants, includeInGraph = false, toggleIncludeInGraph, graphMode } = props;
    const { t } = useTranslation();
    const [sectionQuestionDisplay, setSectionQuestionDisplay] = useState(false);
    // Drag and drop support
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: id });
    const style = useMemo(() => ({
        // Outputs `translate3d(x, y, 0)`
        transform: CSS.Transform.toString(transform),
        transition: transition,
        // Make sure we show ourselves on top of everything else when dragging.
        zIndex: isDragging ? 1000 : 0,
    }), [transform, transition, isDragging]);

    const getLearnerResponses = useCallback((learner: Profile, responses: Array<QuestionResponse>, currentAnalysisTypeQuestions: Array<Question>) => {
        const currentUserResponses = responses.filter(response => response.userId === learner.userId);
        let learnerResponses: Array<ResponsesForDragComponent> = [];
        currentUserResponses.forEach(response => {
            const question = currentAnalysisTypeQuestions.find(question => question.id === response.questionId)
            if (question?.responseType === "YesNo") {

                learnerResponses.push({
                    responseText: response.yesNoInput ? "Yes" : "No",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder
                });

            } else if (question?.responseType === "TextBoxes") {
                if (response.textInput !== "") {

                    learnerResponses.push({
                        responseText: response.textInput,
                        questionId: response.questionId,
                        displayOrder: response.displayOrder
                    });
                }
            } else if (question?.responseType === "Percentage" || question?.isPercentage) {

                learnerResponses.push({
                    responseText: response.amountInput + "%",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder,
                    numberResponse: response.amountInput
                });

            } else if (question?.responseType === "PickOne" || question?.responseType === "MultipleChoice") {

                switch (response.amountInput) {
                    case 1: learnerResponses.push({ responseText: question.multipleChoiceResponse1, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 2: learnerResponses.push({ responseText: question.multipleChoiceResponse2, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 3: learnerResponses.push({ responseText: question.multipleChoiceResponse3, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 4: learnerResponses.push({ responseText: question.multipleChoiceResponse4, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 5: learnerResponses.push({ responseText: question.multipleChoiceResponse5, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 6: learnerResponses.push({ responseText: question.multipleChoiceResponse6, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 7: learnerResponses.push({ responseText: question.multipleChoiceResponse7, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                    case 8: learnerResponses.push({ responseText: question.multipleChoiceResponse8, questionId: response.questionId, displayOrder: response.displayOrder }); break;
                }
            } else {

                learnerResponses.push({
                    responseText: response.amountInput + "",
                    questionId: response.questionId,
                    displayOrder: response.displayOrder,
                    numberResponse: response.amountInput
                });
            }
        })

        learnerResponses = learnerResponses.sort((a, b) => {

            if (a.displayOrder < b.displayOrder) {
                return -1;
            }
            if (a.displayOrder > b.displayOrder) {
                return 1;
            }

            return 0;
        });

        return learnerResponses;
    }, []);

    const QuestionInSectionData = useMemo(() => {

        const ret: SectionsListItem = {
            id: id,
            name: name,
            questions: sectionData?.flatMap(item => item.question) ?? [],
            learners: [],
        };

        if (!sectionData) {
            // Not a section
            return ret;
        }


        respondants?.forEach(respondant => {
            ret.learners.push({
                name: `${respondant.firstName} ${respondant.lastName}`,
                learnerResponses: getLearnerResponses(respondant, sectionData?.flatMap(item => item.responses) ?? [], ret.questions)

            })
        });

        return ret;

    }, [ respondants, id, name, getLearnerResponses, sectionData]);



    return (
        <div style={style}  {...attributes}>
            <Container className="analyser-dragComponent">
                <Row>
                    <Col xs={1}>
                        <div ref={setNodeRef} {...listeners} className="analyser-imageButton d-flex justify-content-center align-items-center">
                            <img className="analyser-hamburger" alt=''></img>
                        </div>
                    </Col>

                    <ConditionalFragment showIf={!!iconSuffix}>
                        <Col xs={1}>
                            <img className={`common-icon analyser-campaignIcon${iconSuffix}`} alt=''></img>
                        </Col>
                    </ConditionalFragment>

                    <Col xs={6} className="dragable-name-container">
                        <h5>{name}</h5>
                    </Col>

                    <Col xs={2} className={"pl-0 pr-0"}>
                        <ConditionalFragment showIf={change === 'up'}>
                            <img className="analyser-upArrow" alt=''></img>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={change === 'down' && !!figure && figure !== 0}>
                            <img className="analyser-downArrow" alt=''></img>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={change === 'down' && figure === 0}>
                            <FontAwesomeIcon icon="minus" />
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!!change && !!figure}>
                            <h6 className="pl-1">{t('analyser.dragComponent.figure', `${figure?.toFixed(0)}%`)}</h6>
                        </ConditionalFragment>
                    </Col>

                    <ConditionalFragment showIf={!!sectionData}>
                        <Col xs={1}>
                            <Button onClick={() => setSectionQuestionDisplay(!sectionQuestionDisplay)} className="analyser-imageButton d-flex justify-content-center align-items-center">
                                <img className="analyser-arrowImage" alt=''></img>
                            </Button>
                        </Col>
                    </ConditionalFragment>

                    <ConditionalFragment showIf={!!graphMode && graphMode && !!change && !!figure}>
                        <Col className="pt-1 pl-1 pr-1">
                            <input type="checkbox" name="includeInGraph" id="includeInGraph" checked={includeInGraph} onChange={(e) => { toggleIncludeInGraph(id ?? name); }} />
                        </Col>
                    </ConditionalFragment>

                    <Col className="text-right">
                        <h6 onClick={() => exclude(id ?? '', name)}>
                            {t('analyser.rightSegment.button.close', 'X')}
                        </h6>
                    </Col>
                </Row>
                <ConditionalFragment showIf={!!sectionData}>
                    <Collapse isOpen={sectionQuestionDisplay}>
                        {
                            sectionData?.map((data, index) => {
                                return (
                                    <QuestionInSectionComponent
                                        key={index}
                                        question={data.question}
                                        sectionListItem={QuestionInSectionData}
                                    />
                                )
                            })
                        }
                    </Collapse>

                </ConditionalFragment>
            </Container>
        </div>

    );
};