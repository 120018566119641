import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

interface EditNavigationProps {
    subscriptionId: string,
}


/**
 * Edit navigation menu for a subscription. 
 */
export const EditNavigation = (props: EditNavigationProps) => {
    const {
        subscriptionId,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/administration/subscriptions/edit/branding/${subscriptionId}`}>
                        <FontAwesomeIcon icon="building" className="nav-icon" />
                        <> </>
                        {t('editNavigation.branding', 'Branding')}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/administration/subscriptions/edit/templateLinks/${subscriptionId}`}>
                        <FontAwesomeIcon icon="clipboard" className="nav-icon" />
                        <> </>
                        {t('editNavigation.templates', 'Templates')}
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};