import { useEffect } from "react";
import { useHistory, useParams } from 'react-router';
import { useAsyncCallback } from "react-use-async-callback";
import { useCloneQuestionnaireCallback } from "../../api/main/questionnaires/useCloneQuestionnaireCallback";

/**
 * Create a questionnaire from an existing questionnaire and open the appropriate list.
 */
export const CloneQuestionnaire = () => {
    // get the questionnaire id
    const { questionnaireId, departmentId, type } = useParams<{ questionnaireId: string | undefined, departmentId: string | undefined, type: string | undefined }>();
    const history = useHistory();

    // use a new mutation to duplicate the questionnaire as a template
    const [save] = useCloneQuestionnaireCallback();

    const [saveTemplate, ] =  useAsyncCallback(async () => {
        await save(questionnaireId ?? '', true);
        if (!!departmentId) {
            history.push(`/departmentQuestionnaires/list/${departmentId}/${type}`);
        } else {
            history.push(`/subscriptionQuestionnaires/${type}`);
        }
    }, [questionnaireId, save, departmentId, history]);

    useEffect(() => {
        if (!!questionnaireId) {
            saveTemplate();
        }
    }, [saveTemplate, questionnaireId]);

    return null;
};