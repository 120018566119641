import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import { AlertOnErrors } from "../../../shared/alertOnErrors";
import { Background } from "../../shared/background/Background";
import { Banner } from "../../shared/Banner";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { DashboardBackButton } from "../backButton/DashboardBackButton";
import { QuestionResponse } from "../../../api/main/models/QuestionResponse";
import { useMemo } from "react";
import { Question } from "../../../api/main/models/Question";
import { useParams } from "react-router";
import { SubscriptionQuestionnairesDashboardTab } from "../tabs/SubscriptionQuestionnairesDashboardTab";
import { SubscriptionDepartmentQuestionnairesDashboardTab } from "../tabs/SubscriptionDepartmentQuestionnairesDashboardTab";
import { QuestionnaireDashboardTab } from "../tabs/QuestionnaireDashboardTab";
import { useQuestionnaireDashboardViewModel } from "../../../api/main/dashboard/viewModels/useQuestionnaireDashboardViewModel";

/**
 * Dashboard for questionnaires.
 */
export const QuestionnaireDashboard = () => {
    const { t } = useTranslation();
    // get subscriptionId and optionlly department id and questionnaire id from params and the type
    const { subscriptionId: subscriptionIdParam,
        subscriptionDepartmentId: depatmentIdParam,
        questionnaireId: questioannireIdParam,
        type: questionnaireTypeParam, } =
        useParams<{ subscriptionId: string | undefined, subscriptionDepartmentId: string | undefined, questionnaireId: string | undefined, type: string | undefined }>();
    const subscriptionId = subscriptionIdParam;
    const departmentId = depatmentIdParam;
    const questionnaireId = questioannireIdParam;
    const questionnaireType = questionnaireTypeParam;

    // we'll always have a subscription id - use that to call the subscription dashboard viewmodel
    const {
        data: {
            model: subscription,
            subscriptionDepartments,
            questionnaires,
            questionnaireRespondentSessions,
            profilesForSessions,
        },
        isLoading,
        errors: loadErrors
    } = useQuestionnaireDashboardViewModel(subscriptionId);

    //get the specfic Department
    const department = useMemo(() => {

        if (!departmentId) {
            return;
        }

        return subscriptionDepartments?.find(item => item.id === departmentId) ?? undefined;

    }, [departmentId, subscriptionDepartments]);

    //get the specific questionnaire
    const questionnaire = useMemo(() => {
        if (!questionnaireId) {
            return;
        }

        return questionnaires?.find(item => item.id === questionnaireId) ?? undefined;

    }, [questionnaireId, questionnaires]);


    const questionnairesToUse = useMemo(() => {

        // check if we have a view model yet
        if (!subscription) {
            return [];
        }

        let filteredQuestionnaires = [...questionnaires];

        // if we have a questionnaire - just use that
        if (!!questionnaireId) {
            return filteredQuestionnaires.filter(item => item.id === questionnaireId);
        }

        // filter the questionnaires by type
        filteredQuestionnaires = filteredQuestionnaires.filter(item => item.questionnaireType === questionnaireType);

        // if we have a departmentId use that with the type
        if (!!departmentId) {
            return filteredQuestionnaires.filter(item => item.subscriptionDepartmentId === departmentId);
        }

        // otherwise use the subscritionId with the type
        if (!!subscriptionId) {
            return filteredQuestionnaires.filter(item => item.subscriptionId === subscriptionId);
        }

    }, [subscription, questionnaires, questionnaireType, departmentId, subscriptionId, questionnaireId]);

    //get the sessions required for the questionnaires
    const sessionsToUse = useMemo(() => {

        if (!questionnairesToUse) {
            // no questionnaires so return empty array
            return [];
        }

        // return the sessions matching the questionnaires we are going to use
        return !!questionnaireRespondentSessions ? questionnaireRespondentSessions?.filter(session => !!questionnairesToUse.find(it => it.id === session.questionnaireId)) : [];

    }, [questionnairesToUse, questionnaireRespondentSessions]);


    // get all responses for the sessions
    const responsesToUse = useMemo(() => {
        if (!sessionsToUse.length) {
            return [];
        }

        let ret: Array<QuestionResponse> = [];
        for (let session of sessionsToUse) {
            for (let response of session.responses) {
                ret.push(response);
            }
        }

        return ret;
    }, [sessionsToUse]);

    // get the questions 
    const questions = useMemo(() => {
        if (!questionnairesToUse) {
            return [];
        }

        let allQuestions: Array<Question> = [];

        for (let questionnaire of questionnairesToUse) {
            for (let question of questionnaire.questions) {
                allQuestions.push(question);
            }
        }
        return allQuestions;
    }, [questionnairesToUse,]);

    return (
        <Background>
            <Banner fluid>
                <Row>
                    <Col xs="">
                        <h1>
                            <DashboardBackButton />
                            {
                                // If we are showing for our own (or emulated) subscription just show "Dashboard" as the name.
                                !!subscriptionId && !departmentId && !questionnaireId ? t('questionnaireDashboard.heading.withSubscriptionName', '{{name}} Questionnaires Dashboard', { name: subscription?.name })
                                    : !!subscriptionId && !!departmentId ? t('questionnaireDashboard.heading.withDepartmentName', '{{ name }} Questionnaires Dashboard', {name: department?.name })
                                        : !!subscriptionId && !departmentId && !!questionnaireId ? t('questionnaireDashboard.heading.withQuestionnaireName', '{{name}} Dashboard', { name: questionnaire?.name })
                                            : t('questionnaireDashboard.heading.withQuestionnaireName', 'Dashboard')
                            }
                        </h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>
            <Container fluid className="main-container">
                <AlertOnErrors errors={[loadErrors]} />

                <ConditionalFragment showIf={!!subscriptionId && !departmentId && !questionnaireId}>
                    <SubscriptionQuestionnairesDashboardTab
                        questionnaires={questionnairesToUse ?? []}
                        sessions={sessionsToUse}
                        responses={responsesToUse}
                        questions={questions}
                        />
                </ConditionalFragment>

                <ConditionalFragment showIf={!!subscriptionId && !!departmentId}>
                    <SubscriptionDepartmentQuestionnairesDashboardTab
                        questionnaires={questionnairesToUse ?? []}
                        sessions={sessionsToUse}
                        responses={responsesToUse}
                        questions={questions}
                    />
                </ConditionalFragment>

                <ConditionalFragment showIf={!!subscriptionId && !!questionnaireId}>
                    <QuestionnaireDashboardTab
                        profiles={profilesForSessions?.filter(session => sessionsToUse?.find(it => it.userId === session.userId)) ?? []}
                        sessions={sessionsToUse}
                        responses={responsesToUse}
                        questions={questions}
                    />
                </ConditionalFragment>
            </Container>
        </Background>
    );
};
