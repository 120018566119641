import { QuestionnairesListBase } from "../../questionnaires/QuestionnairesListBase";

/**
 * List of campaigns for a subscription department
 */
export const SubscriptionDepartmentCampaignsList = () => {

    return (
        <QuestionnairesListBase
            isSubscription={true}
            isDepartment={true}
        />
    );
};