import './noResultsFound.scss';

import * as React from 'react';
import { useTranslation } from 'react-i18next';

export interface NoResultsFoundProps {
    className?: string,
    search?: string,
    children?: React.ReactNode,
}

/**
 * Component to show when a search or query returned no results.
 */
export const NoResultsFound = (props: NoResultsFoundProps) => {
    const {
        className,
        search,
        children,
    } = props;

    const { t } = useTranslation();

    // Otherwise render th default contents.
    return (
        <div className={`no-results-found ${search ? 'no-results-found-search' : 'no-results-found-no-search'} ${className ?? ''}`}>
            {/* customer does not want to show frown for no items so this has been removed */}
            {/*<div className="no-results-found-icon">*/}
            {/*    <FontAwesomeIcon icon={icon ?? ['far', 'frown']} />*/}
            {/*</div>*/}
            {
                children ? children
                    : (
                        <p>
                            {
                                search ?
                                    t('notResultsFound.nothingMatchedSearch', 'Sorry there were no matches for "{{search}}."', { search: search })
                                    : t('notResultsFound.nothingFound', 'Sorry there is nothing to show you here yet.')
                            }
                        </p>
                    )
            }
        </div>
    );
};