import { Question } from "../../../../api/main/models/Question";
import { QuestionResponse } from "../../../../api/main/models/QuestionResponse";
import { ComparisonScaleResponses } from "./ComparisonScaleResponses";

//* Common reporting page for analyser reports - Transformation Page
interface TransformationPageProps {
    companyColor: string,
    transformationQuestions: Question[],
    transformationResponses: QuestionResponse[],
}

export const TransformationPage = (props: TransformationPageProps) => {
    const {
        companyColor, transformationQuestions, transformationResponses
    } = props;

    return (
        <>
            {
                transformationQuestions.map((question, questionIndex) => {
                    const questionResponses = transformationResponses.filter(response => response.questionId === question.id);

                    // get the ids of the learners who have given a response to this question
                    const uniqueResponseLearners = [...new Set(questionResponses.map(r => r.userId))];

                    // Each learner will have given an even number of responses to this question.
                    // The responses will be in pairs so the odd number will be before answers and the even number will be after answers.
                    // We need a total for each and a count for getting an average
                    let responsePairsCount = 0;
                    let beforeTotal = 0;
                    let afterTotal = 0;
                    uniqueResponseLearners.forEach(learnerId => {
                        const thisLearnerResponses = questionResponses.filter(r => r.userId === learnerId).sort((a, b) => a.displayOrder - b.displayOrder);

                        if (thisLearnerResponses.length % 2 === 0) {
                            for (let i = 0; (i + 1) < thisLearnerResponses.length; i += 2) {
                                beforeTotal += thisLearnerResponses[i].amountInput;
                                afterTotal += thisLearnerResponses[i + 1].amountInput;
                                responsePairsCount++;
                            }
                        }

                    });

                    if (responsePairsCount === 0) {
                        return null;
                    } else {
                        return (
                            <ComparisonScaleResponses
                                key={questionIndex}
                                companyColor={companyColor}
                                question={question}
                                questionIndex={questionIndex}
                                responsePairsCount={responsePairsCount}
                                beforeTotal={beforeTotal}
                                afterTotal={afterTotal}
                                />
                        )
                    }
                })
            }
        </>
    );
};
