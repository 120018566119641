import { QuestionnairesListBase } from "../../questionnaires/QuestionnairesListBase";

/**
 * List of questionnaires for a subscription
 */
export const SubscriptionQuestionnairesList = () => {

    return (
        <QuestionnairesListBase
            isSubscription={true}
        />
    );
};